import { AxiosResponse } from "axios";
import { httpBlog } from "config/http";

export interface LikePostRequest {
  slug?: string;
}

export interface PostLikes {
  likes: number;
}

const likePost = async ({ slug }: LikePostRequest): Promise<AxiosResponse<PostLikes>> => {
  return httpBlog.post<PostLikes>(`/posts/${slug}/like`, {});
};

export default likePost;
