import { AxiosResponse } from "axios";
import http from "config/http";
import { ResponseWithParams, ResponseStatus } from "core/api/definitions";
import { MaterialList, DeliveryWay } from "core/api/definitions";
import { Address } from "core/api/definitions";

export interface UpdateMaterialsRequest {
  action: string;
  materialId: string;

  title: string;
  delivery_date: string; //2020-06-20 00:00:00
  delivery_way: DeliveryWay;
  address?: Address;
  action_address?: boolean;
}

export type UpdateMaterialsResponseParams = MaterialList[];

export type UpdateMaterialsResponse = ResponseWithParams<ResponseStatus, UpdateMaterialsResponseParams>;

export default async ({
  action,
  materialId,
  ...data
}: UpdateMaterialsRequest): Promise<AxiosResponse<UpdateMaterialsResponse>> => {
  return http.put<UpdateMaterialsResponse>(`/actions/${action}/donors/materials/${materialId}`, data);
};
