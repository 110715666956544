import React, { Fragment } from "react";
import { ValidationError, ValidationErrorSet } from "core/api/definitions";
import { useTranslation } from "react-i18next";
import SupportFeedback from "components/common/SupportFeedback";
import { SupportTextWrapperProps } from "components/common/SupportText";
import i18next from "i18next";

interface WizardTransProps extends SupportTextWrapperProps {
  children?: string | ValidationError;
}

export const translateValidationError = (err: string | ValidationError) => {
  const tStr = Array.isArray(err) && err.length > 0 ? err[0] : err;
  const resultFormatted = (tStr as string)?.replace(/\./g, "-") || tStr;
  return i18next.t(`wizard.error.common.${resultFormatted}`, { defaultValue: resultFormatted });
};

const WizardTrans: React.FC<WizardTransProps> = ({ children, ...props }) => {
  const { t } = useTranslation();

  if (!children) return null;

  return (
    <Fragment>
      {children ? (
        <SupportFeedback {...props} data-cy="wizard-error">
          {translateValidationError(children)}
        </SupportFeedback>
      ) : null}
    </Fragment>
  );
};

export default WizardTrans;
