import React from "react";
import PropTypes from "prop-types";
import { SimpleContainer } from "../commonStyles";

const Member = ({ children }) => <SimpleContainer>{children}</SimpleContainer>;

Member.propTypes = {
  nextStep: PropTypes.func,
};

export default Member;
