//TODO(Jeconias): Move it to outside API

import { AxiosResponse } from "axios";
import http from "config/http";
import { AddressCoordinates, AddressWithCoordinates, Coordinate, Subscribe } from "core/api/definitions";
import { Action, CauseResponse } from "../definitions";
import { ResponseStatus, ResponseWithParams } from "../definitions";

export interface GetHomeStructureRequest {
  coordinate?: Coordinate;
}

export interface GetHomeStructureResponseParams {
  causes_section: CauseResponse[];
  featured_actions: {
    actions: Action[];
    opened_count: number;
    showcase: string;
  };
  highlight_actions: Action[];
  highlight_text: string;
  program_data: [];
  sponsored_by: {
    image_cover: string;
    url: string;
  };
  subscribes_section: {
    opened_count: number;
    subscribes: Subscribe[];
  };
  volunteer_section: {
    actions_created: number;
    count: number;
    description: string;
    subscribes_opened: number;
    title: string;
  };
  merchandising_section: {
    title: string;
    items: {
      logo: string;
      link?: string;
      name?: string;
    }[];
  };
  project_section?: {
    title?: string;
    description?: string;
  };
}

type GetHomeStructureResponse = ResponseWithParams<ResponseStatus, { home: GetHomeStructureResponseParams }>;

const getHomeStructure = ({
  coordinate: coordinates,
}: GetHomeStructureRequest): Promise<AxiosResponse<GetHomeStructureResponse>> => {
  const coords = coordinates
    ? `?address[coordinates][lat]=${coordinates?.lat}&address[coordinates][lng]=${coordinates?.lng}`
    : "";
  return http.get<GetHomeStructureResponse>(`/pages/home${coords}`);
};

export default getHomeStructure;
