import React from "react";
import { default as BaseSupportText, SupportTextWrapperProps } from "components/common/SupportText";
import styled from "styled-components/macro";

const SupportFeedback: React.FC<Pick<SupportTextWrapperProps, "color">> = ({ ...props }) => {
  const defaultProps: SupportTextWrapperProps = {
    color: "quaternary",
    tag: "span",
    size: "sm",
    textAlign: "center",
  };

  return <SupportText {...defaultProps} {...props} />;
};

const SupportText = styled(BaseSupportText)`
  display: block;
  width: 100%;
  margin-top: 10px;
`;

export default SupportFeedback;
