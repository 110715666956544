import http from "config/http";
import { Opportunity } from "core/api/definitions";
import { ActionData, ODSData } from "../definitions";
import { ProjectData } from "../definitions";

export type ShowActionRequest = string;

export interface ShowActionResponse {
  _id: string;
  action_data: ActionData;
  ods_data: ODSData[];
  opportunities_data: Opportunity[];
  project_data: ProjectData;
}

export default async (slug: ShowActionRequest) => http.get<ShowActionResponse>(`/v3/actions/${slug}/show`);
