import React from "react";
import LinkBase, { LinkBaseProps } from "./LinkBase";
import styled, { css } from "styled-components";
import { rem } from "polished";

const LinkComp: React.FC<LinkBaseProps> = (props) => <LinkBase {...props} />;

const Link = styled(LinkComp)`
  font-weight: 400;
  line-height: ${rem(20)};

  ${({ theme }) => css`
    :hover {
      text-decoration: underline;
    }

    :active {
      text-decoration: underline;
    }
  `}
`;

export default Link;
