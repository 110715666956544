import assign from "./assign";
import get from "./get";
import getActions from "./getActions";
import getOpportunities from "./getOpportunities";

export default {
  get,
  assign,
  getActions,
  getOpportunities,
};
