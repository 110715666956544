import { AxiosResponse } from "axios";
import http from "config/http";
import { ResponseStatus, ResponseWithParams } from "../definitions";

export interface MarkUnread {
  chatID: string;
}

export type MarkUnreadReponse = ResponseWithParams<ResponseStatus, []>;

export default async ({ chatID }: MarkUnread): Promise<AxiosResponse<MarkUnreadReponse>> =>
  http.get<MarkUnreadReponse>(`/chat/${chatID}/unread`);
