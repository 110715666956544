import { ValidationErrorSet, ValidationField } from "core/api/definitions";

const routeToError = (errors: ValidationErrorSet, routes: ValidationField[]) => {
  let d = Infinity;
  for (const field in errors) {
    const idx = routes.findIndex((r) => {
      if (typeof r.field === "string") return r.field === field;
      return r.field.some((fName) => fName === field);
    });
    if (idx >= 0 && idx < d) {
      d = idx;
    }
  }
  if (d !== Infinity) {
    return routes[d].route;
  }
  return undefined;
};

export default routeToError;
