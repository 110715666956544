import React from "react";
import styled from "styled-components/macro";
import { Row, Col } from "reactstrap";
import classname from "classnames";
import { transition } from "utils/animation";

export interface BaseCardProps extends React.HTMLAttributes<unknown> {
  tag?: string | React.ElementType<any>;
}

const BaseCard: React.FC<BaseCardProps> = ({ tag, children, ...props }) => {
  const Component = tag || "div";
  return <Component {...props}>{children}</Component>;
};

export interface CardProps {
  tag?: string | React.ElementType<any>;
  selected?: boolean;
  withCheckbox?: boolean;
  [key: string]: any;
}

const Card: React.FC<CardProps> = ({
  selected,
  className,
  children,
  withCheckbox,
  isSelected,
  isDualFace,
  disabled,
  ...props
}) => {
  return (
    <CardStyled
      className={classname(className, selected ? "card-selected" : undefined, disabled ? "disabled" : undefined)}
      {...props}
    >
      {withCheckbox && <Check checked={selected} />}
      {children}
    </CardStyled>
  );
};

export default Card;

const CardStyled = styled(BaseCard)`
  border: 1px solid ${({ theme }) => theme.v3.colors.neutralLightest};
  border-radius: 8px;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  transition: ${transition.normal("border-color", "box-shadow")};
  box-shadow: 0 10px 20px 0 rgba(13, 29, 51, 0);
  position: relative;
  background: #fff;

  &.card-selected,
  &:hover {
    border-color: ${({ theme }) => theme.v3.colors.feedbackSupport};
  }

  &.card-selected {
    box-shadow: 0 10px 20px 0 rgba(13, 29, 51, 0.07);
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.3;

    * {
      pointer-events: none;
    }
  }
`;

export const CardCol = styled(Col)`
  display: flex;
  height: 300px;

  ${CardStyled} {
    flex: 1;
  }
`;

export const CardContainer = styled(Row)``;

interface CheckProps {
  checked?: boolean;
}

const Check = ({ checked }: CheckProps) => {
  return (
    <CheckWrapper checked={checked}>
      {checked && (
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="check"
          className="svg-inline--fa fa-check fa-w-16"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            fill="currentColor"
            d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
          ></path>
        </svg>
      )}
    </CheckWrapper>
  );
};

const CheckWrapper = styled.span<CheckProps>`
  position: absolute;
  right: 20px;
  top: 20px;
  width: 21px;
  height: 21px;
  border: 1px solid #9facbd;
  border-radius: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 11px;

    path {
      fill: #fff;
    }
  }

  transition: ${transition.normal("background-color")};

  ${({ checked, theme: { colors } }) =>
    checked &&
    `
    background-color: ${colors.primary};
    border-width: 0;
  `}
`;
