import ButtonPrimary from "v3/components/Button/ButtonPrimary";
import { ellipsis } from "components/common/style";
import { rem } from "polished";
import React, { useCallback, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import styled, { css } from "styled-components";
import { transition } from "utils/animation";
import { getCurrency } from "utils/helpers";
import Text from "v3/components/common/Text";
import Badge from "../Badge/Badge";
import useTranslationV3 from "hooks/useTranslationV3";
import mqV3 from "utils/mediaQueriesV3";
import IconOpportunity, { IconOpportunityV2 } from "../Icons/IconOpportunity";
import { DeliveryWay } from "core/api/definitions";
import { Origin } from "core/api/definitions";
import { useReduxSelector } from "hooks/useReduxSelector";

export interface OpportunityCardInterface {
  title: string;
  project: {
    name: string;
    link?: string;
    target?: string;
  };
  location: string;
  type?: "volunteer" | "material" | "collection";
  available: number | string;
  total: number | string;
  img?: string;
  isOver?: boolean;
  isRemote: boolean;
  actionSlug: string;
  projectSlug: string;
  vacancySlug?: string;
  donationID?: string;
  deliveryWay?: DeliveryWay;
  onContainerClick?(): void;
}
interface OpportunityCardProps
  extends Omit<OpportunityCardInterface, "projectSlug" | "actionSlug" | "vacancySlug" | "donationID"> {
  className?: string;
  to: string | Object;
  tagContainer?: React.ElementType;
}

const OpportunityCardCompV2 = ({
  type,
  title,
  project,
  location,
  total,
  available,
  to,
  isOver,
  onContainerClick,
  tagContainer,
  className,
  isRemote,
  img,
}: OpportunityCardProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslationV3();
  const history = useHistory();

  const formatCountCallback = useCallback(
    (total: number | string, available: number | string) => {
      const countSTR = typeof total === "number" ? total.toString() : total;

      if (type === "volunteer" || type === undefined) return Math.floor(parseInt(countSTR || "0"));
      if (type === "collection")
        return total.toLocaleString(language, { style: "currency", currency: getCurrency(language) });
      if (type === "material") return available;
    },
    [type, language]
  );

  const calcPercentageMemorized = useMemo(() => {
    if (!available || !total) return undefined;
    const availableNumber = typeof available !== "number" ? parseInt(available) : available;
    const totalNumber = typeof total !== "number" ? parseInt(total) : total;
    const result = Math.floor(((availableNumber || 1) * 100) / (totalNumber || 1));
    return result > 100 ? 100 : Math.abs(100 - result);
  }, [available, total]);

  const containerClickCallback = useCallback(
    (e) => {
      if (Number(available) > 0) {
        const isTagA = e.target.tagName === "A";
        if (!isTagA && !isOver) {
          if (to) history.push(to);
          if (!to && onContainerClick) onContainerClick();
        }
      }
    },
    [to, onContainerClick, isOver, history, available]
  );

  const buttonPropsMemorized = useMemo(() => {
    const result = to ? { to, tag: Link } : { onClick: onContainerClick || (() => {}) };
    return isOver ? {} : result;
  }, [onContainerClick, isOver]);

  const { origin } = useReduxSelector((state) => ({
    origin: state.pagesNew.origin.params,
  }));

  const { logo } = origin as Origin;

  return (
    <Container onClick={containerClickCallback} tag={tagContainer} className={className}>
      <Main>
        <HeaderTitle>
          <ImgWrapper isNull={img === "" || img === null || img === undefined}>
            {img ? <Img src={img} /> : ""}
          </ImgWrapper>
          <HeaderTitleWrapper>
            <OpportunityName> {title}</OpportunityName>
            <OpportunityLocation>
              {isRemote ? t("plain:Remota") : location || t("plain:Sem endereço")}
            </OpportunityLocation>
          </HeaderTitleWrapper>
        </HeaderTitle>
        <OpportunityProject>{project.name}</OpportunityProject>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "18px",
          }}
        >
          <Info>
            {Number(available) > 0 ? (
              <Count>
                {type === "volunteer" ? (
                  <AvailableNumber>{Number(available) > 100 ? "100+" : available}</AvailableNumber>
                ) : (
                  <AvailableNumber>{available}</AvailableNumber>
                )}
                <Label>
                  {t([`cardOpportunity.type.${type}.label`, "cardOpportunity.type.volunteer.label"], {
                    ns: "components",
                  })}
                </Label>
                <Progress percentage={calcPercentageMemorized} />
              </Count>
            ) : (
              <Badge color="neutralLight">{t("plain:Encerrada")}</Badge>
            )}
          </Info>
          <IconOpportunityV2 icon={type} />
        </div>
        {Number(available) > 0 && (
          <OutlineButton {...buttonPropsMemorized}>
            {t([`cardOpportunity.type.${type}.action`, "cardOpportunity.type.volunteer.action"], {
              ns: "components",
            })}
          </OutlineButton>
        )}
      </Main>
      {
        // <Footer>
        //   <Title size="xs" color="neutralLight">
        //     {t([`cardOpportunity.type.${type}.title`, "cardOpportunity.type.volunteer.title"], { ns: "components" })}
        //   </Title>
        // </Footer>
      }
    </Container>
  );
};

const OpportunityCardV2 = styled(OpportunityCardCompV2)`
  list-style: none;
`;

export default OpportunityCardV2;

const HeaderTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

const ImgWrapper = styled.div<{ isNull: boolean }>`
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  ${({ isNull }) => (isNull ? "background-color: #D9D9D9" : " background-color: transparent;")}
  border-radius: 4px;
  overflow: hidden;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const AvailableNumber = styled.span`
  color: #2e4768;
  font-family: Rubik;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin-right: 5px;
`;
const OutlineButton = styled.button`
  border: 1px solid #2c45cc;
  background-color: transparent;
  color: #2c45cc;
  cursor: pointer;
  transition: background-color 0.42s ease;
  border-radius: 4px;
  height: 31px;
  min-width: 132px;
  margin-top: 20px;
  &:hover {
    background-color: #2c45cc;
    color: #fff;
  }
`;

const OpportunityProject = styled.p`
  color: #2c45cc;
  font-family: Rubik;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 250px;

  @media (max-width: 480px) {
    max-width: 250px;
  }
`;
const OpportunityName = styled.span`
  color: #2e4768;
  font-family: Rubik;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.0625rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;

  @media (max-width: 480px) {
    max-width: 155px;
  }
`;

const OpportunityLocation = styled.span`
  color: #2e4768;
  font-family: Rubik;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
`;
const Container = styled(({ tag: Tag, ...props }) => (Tag ? <Tag {...props} /> : <div {...props} />))`
  position: relative;
  flex: 1;
  min-width: 270px;
  max-width: 314px;
  min-height: 333px;
  border: 1px solid ${({ theme }) => theme.v3.colors.primary};
  border-radius: 6px;
  margin: 0 15px 40px;
  transition: ${transition.fast("box-shadow", "border-color")};
  background-color: ${({ theme }) => theme.v3.colors.neutralWhite};
  cursor: pointer;

  :hover {
    box-shadow: 0px 10px 30px rgba(13, 51, 32, 0.1);
    border-color: ${({ theme }) => theme.v3.colors.feedbackSupport};
  }

  ${mqV3.smDown} {
    max-width: 276px;
  }

  ${mqV3.xsDown} {
    width: 220px;
    height: 233px;
    margin-bottom: 10px;
  }
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.v3.spacing.sm} ${({ theme }) => theme.v3.spacing.md};
  border-bottom: 1px solid ${({ theme }) => theme.v3.colors.neutralLightest};
`;

const Main = styled.div`
  padding: ${({ theme }) => theme.v3.spacing.md};
`;

const Footer = styled.footer`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: ${({ theme }) => theme.v3.spacing.sm} ${({ theme }) => theme.v3.spacing.md};
  border-top: 1px solid ${({ theme }) => theme.v3.colors.primary};
`;

const Title = styled(Text)`
  color: #2e4768;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;

const Describe = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.v3.spacing.sm};

  & > p {
    ${ellipsis};
    max-width: 100%;
    -webkit-line-clamp: 3;
  }
`;

const HeaderTitle = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 2px;
  min-height: 70px;
  align-items: start;
  ${Text} {
    padding: 0;
  }

  svg {
    min-width: 48px;
    height: 48px;
  }
  @media (max-width: 480px) {
    max-width: 100%;
    overflow: hidden;
  }
`;

const Info = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Label = styled.p`
  font-size: ${rem(9)};
  line-height: ${rem(10)};
  text-transform: uppercase;
  color: ${({ theme }) => theme.v3.colors.neutralLight};
  margin: 0;
  max-width: 68px;
`;

const Count = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  ${Text} {
    margin-right: ${({ theme }) => theme.v3.spacing.xs};
  }
`;

const Progress = styled.div<{ percentage?: number }>`
  position: absolute;
  bottom: -10px;
  width: 80px;
  height: 2px;
  background-color: ${({ theme }) => theme.v3.colors.neutralLightest};
  border-radius: 5px;

  :before {
    content: "";
    position: absolute;
    height: 2px;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto 0;
    background-color: ${({ theme }) => theme.v3.colors.primary};
    ${({ percentage }) =>
      percentage !== undefined &&
      css`
        width: ${percentage}%;
      `}
  }
`;
