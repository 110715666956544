import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components/macro";
import { rem } from "polished";
import { redirectUrls } from "../../core/utils/operations";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { modalClose } from "core/pages/actions";
import BaseModal from "./BaseModal";
import { useHistory } from "react-router-dom";
import routes from "routes";

const FeedbackModal = ({
  success,
  title,
  message,
  redirectUrls,
  url,
  timeout,
  project,
  action,
  buttonDisabled,
  modalClose,
  onDismiss,
  onClose,
  theme,
  goBack,
}) => {
  const history = useHistory();
  const [timeoutId, setTimeoutId] = useState(false);

  const dismiss = () => {
    clearTimeout(timeoutId);
    if (success) {
      history.goBack();
      modalClose();
    } else if (buttonDisabled) {
      buttonDisabled();
    }
    if (!success) {
      modalClose();
    }
    if (onClose) onClose();
  };

  useEffect(() => {
    if (onDismiss && timeout) {
      setTimeoutId(setTimeout(dismiss, timeout));
    }
  }, [onDismiss]);

  return (
    <BaseModal
      //TODO(Jeconias) onDismiss must be renamed to samething isOpen
      isOpen={onDismiss}
      onClose={dismiss}
      icon={success ? "check" : "close"}
      color={success ? theme.v3.colors.feedbackSuccess : theme.v3.colors.feedbackError}
      size="sm"
    >
      <Title success={success}>{title}</Title>
      <Message success={success}>{message}</Message>
    </BaseModal>
  );
};

FeedbackModal.defaultProps = {
  success: false,
};

FeedbackModal.propTypes = {
  success: PropTypes.bool,
  title: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ redirectUrls, modalClose }, dispatch);

const mapStateToProps = (state) => ({
  redirect: state.redirect,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(FeedbackModal));

export const Title = styled.div`
  font-size: ${rem(36)};
  color: ${({ success, theme }) => (success ? theme.colors.panel.approved : theme.colors.panel.disapproved)};
  letter-spacing: ${rem(-0.37)};
  line-height: ${rem(50)};
  margin: 48px 0 10px;
`;

export const Message = styled.div`
  color: ${(props) => props.theme.colors.support_text};
  font-size: ${rem(18)};
  line-height: ${rem(22)};
`;
