import api from "core/api";
import { LoginRequest, LoginResponse } from "core/api/auth/login";
import { ResetPasswordRequest, ResetPasswordResponse } from "core/api/auth/resetPassword";
import asyncThunk from "core/decorators/toolkit";
import { LocationDescriptor } from "history";

interface LoginThunkRequest extends LoginRequest {
  redirect?: LocationDescriptor<any> | false;
}

export const login = asyncThunk<LoginResponse, LoginThunkRequest>("login", async (data: LoginThunkRequest) => {
  const result = await api.auth.login(data);
  if (result.data?.params) {
    result.data.params.email = data.email;
  }
  return result;
});

export const signInUpGmailToken = asyncThunk("login/gmail", api.auth.signInUpGmailToken);

export const resetPassword = asyncThunk<ResetPasswordResponse, ResetPasswordRequest>(
  "resetPassword",
  api.auth.resetPassword
);
