import { AxiosResponse } from "axios";
import http from "config/http";
import { ContactUs } from "core/api/definitions";

export interface ContactRequest extends ContactUs {}

export interface ContactResponse {
  [k: string]: any;
}

const contact = (data: ContactRequest): Promise<AxiosResponse<any>> => {
  return http.post<any>(`/communication/contactUs`, data);
};

export default contact;
