import React, { useEffect } from "react";
import { createUser as dispatchCreateUser } from "core/user/operations";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import useCountry from "hooks/useCountry";

/**
 * @deprecated
 */
export const passwordRules = [
  { description: "signUpModal.validation.minLength", validator: (value) => value.length >= 8 },
  {
    description: "signUpModal.validation.alphanumeric",
    validator: (value) => /[A-Za-z]/g.test(value) && /\d/g.test(value),
  },
  { description: "signUpModal.validation.capitalLetter", validator: (value) => /[A-Z]/g.test(value) },
  { description: "signUpModal.validation.specialCharacter", validator: (value) => /[^A-Za-z0-9]/g.test(value) },
];

export const PROFILE_OPTIONS = {
  volunteer: "volunteer",
  project: "project",
};

const generator = ({ name, email, email_confirmation, phone, password, password_confirmation }) => {
  return {
    name: validatorName(name),
    email: validatorEmail(email, email_confirmation),
    phone: validatorPhone(phone),
    password: validatorPassword(password, password_confirmation),
  };
};

const validatorName = (value) => {
  if (typeof value === "string") {
    const name = value.trim();
    if (name.length < 2)
      return {
        isInvalid: true,
        description: "nameInvalid",
      };

    if (name.length > 50)
      return {
        isInvalid: true,
        description: "nameLimit",
      };
    return {
      isInvalid: false,
      description: "",
    };
  }
  return {
    isInvalid: true,
    description: "",
  };
};

const validatorEmail = (email, email_confirmation) => {
  if (typeof email === "string" && email.trim() === email_confirmation && email.length !== 0)
    return {
      isInvalid: false,
      description: "",
    };
  return {
    isInvalid: true,
    description: "emailConfirm",
  };
};

//TODO(Jeconias) Add ability to receive an array of phones/contacts
const validatorPhone = (phone) => {
  const number = phone.replace(/\D/g, "");
  if (number.trim().length === 11) {
    return {
      isInvalid: false,
      description: "",
    };
  }
  return {
    isInvalid: true,
    description: "phoneInvalid",
  };
};

const validatorPassword = (password, password_confirmation) => {
  if (typeof password === "string" && password.trim() === password_confirmation && password.length !== 0) {
    return {
      isInvalid: false,
      description: "",
    };
  }
  return {
    isInvalid: true,
    description: "passwordConfirm",
  };
};

/**
 * @deprecated
 */
const SignUpFormBase = ({ children, dispatchRequest, dispatchResponse, redirectOnSuccess, ...props }) => {
  const { dispatchCreateUser } = props;

  const { country } = useCountry();

  useEffect(() => {
    if (isEmpty(dispatchRequest)) return;

    // Inputs validate
    const dispatchData = {
      name: dispatchRequest?.name || "",
      email: dispatchRequest?.email || "",
      email_confirmation: dispatchRequest?.email_confirmation || "",
      phone: dispatchRequest?.phone || "",
      password: dispatchRequest?.password || "",
      password_confirmation: dispatchRequest?.password_confirmation || "",
    };

    const response = generator(dispatchData);

    const inputs = {};
    let isInvalid = false;
    for (let input in response) {
      if (response[input].isInvalid === true) {
        inputs[input] = { name: input, ...response[input] };
        isInvalid = true;
      } else {
        inputs[input] = { name: input, isInvalid: false, description: "" };
      }
    }

    if (isInvalid) {
      dispatchResponse({ success: false, serverRequested: false, inputs });
    } else {
      dispatchCreateUser({
        ...dispatchRequest,
        name: dispatchData.name,
        email: dispatchData.email,
        email_confirmation: dispatchData.email_confirmation,
        contacts: [
          {
            value: dispatchData.phone,
            type: "phone",
            country,
          },
        ],
        password: dispatchData.password,
        password_confirmation: dispatchData.password_confirmation,
      })
        .then((response) => {
          const userCreated = response?.data;
          if (userCreated?.success) {
            dispatchResponse({
              success: true,
              serverRequested: true,
              params: {},
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            const userCreated = err.response.data;

            const inputsValidate = {};
            const hasValidateParams = !isEmpty(userCreated?.params);

            if (hasValidateParams) {
              for (let input in userCreated.params) {
                inputsValidate[input] = Array.isArray(userCreated.params[input])
                  ? { name: input, isInvalid: true, description: userCreated.params[input][0] }
                  : userCreated.params[input];
              }
            }

            dispatchResponse({
              success: false,
              serverRequested: true,
              response: userCreated?.response || {},
              inputs: { ...inputsValidate },
            });
          } else {
            dispatchResponse({
              success: false,
              serverRequested: false,
              params: { request: ["error"] },
            });
          }
        });
    }
  }, [dispatchRequest, dispatchResponse, redirectOnSuccess, dispatchCreateUser, country]);

  /*
  useEffect(() => {
    if (
      isEmpty(userCreated) ||
      (userCreated?.loading && userCreated?.status !== "received") ||
      flowRequestControl === false
    )
      return;

    if (userCreated?.success === true && !userCreated?.loading && userCreated?.status === "received") {
      dispatchResponse({
        success: true,
        serverRequested: true,
        params: {},
      });
    } else if (!userCreated?.loading && userCreated?.status === "failed") {
      const inputsValidate = {};
      const hasValidateParams = !isEmpty(userCreated?.params);

      if (hasValidateParams) {
        for (let input in userCreated.params) {
          inputsValidate[input] = Array.isArray(userCreated.params[input])
            ? { name: input, isInvalid: true, description: userCreated.params[input][0] }
            : userCreated.params[input];
        }
      }

      dispatchResponse({
        success: false,
        serverRequested: true,
        response: userCreated?.response || {},
        inputs: { ...inputsValidate },
      });
    }
    setFlowRequestControl(false);
  }, [userCreated, dispatchResponse, flowRequestControl]);
  */

  return <>{children}</>;
};

SignUpFormBase.defaultProps = {
  dispatchRequest: {},
  dispatchResponse: () => {},
  redirectOnSuccess: null,
};

SignUpFormBase.propTypes = {
  /** Data for request */
  dispatchRequest: PropTypes.object.isRequired,
  /** Response of server */
  dispatchResponse: PropTypes.func,
  /** Redirect to URl when success */
  redirectOnSuccess: PropTypes.string,
};

const mapStateToProps = (state) => ({
  userCreated: state?.user?.cvt || {},
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ dispatchCreateUser }, dispatch);

/**
 * @deprecated
 */
export default connect(mapStateToProps, mapDispatchToProps)(SignUpFormBase);
