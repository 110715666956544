import React from "react";
import { library, IconProp as FontAwesomeSVGIconProps } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import FalIcons from "./FalIcons";
import FabIcons from "./FabIcons";
import FarIcons from "./FarIcons";
import FasIcons from "./FasIcons";

library.add(...FalIcons, ...FabIcons, ...FarIcons, ...FasIcons);

export type IconType = FontAwesomeSVGIconProps;
export type IconProps = FontAwesomeIconProps;

const Icon = (props: IconProps): JSX.Element => {
  return <FontAwesomeIcon {...props} />;
};

export default Icon;
