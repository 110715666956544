import React from "react";
import styled from "styled-components/macro";
import Container from "components/register/Container";
import Icon from "components/Icon/Icon";
import { rem } from "polished";

type AlertType = "info" | "danger";

interface AlertProps {
  type: AlertType;
  message: string;
  onDismiss(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
}

const Alert: React.FC<AlertProps> = ({ type, message, onDismiss }) => (
  <Wrapper type={type}>
    <Container className="position-relative">
      {message}
      <CloseButton onClick={onDismiss}>
        <Icon type="close" />
      </CloseButton>
    </Container>
  </Wrapper>
);

export default Alert;

const Wrapper = styled.div<{ type: AlertType }>`
  color: #ffffff;
  font-size: ${rem(15)};
  line-height: ${rem(22)};
  padding: 15px 0;

  background: ${(props) => {
    switch (props.type) {
      case "danger":
        return "#F67467";
      case "info":
        return props.theme.colors.info;
      default:
        return props.theme.colors.info;
    }
  }};
`;

const CloseButton = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  height: 14px;
  width: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  opacity: 0.5;
  transition: opacity ease 0.2s;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  svg {
    fill: #ffffff;
    height: 100%;
  }
`;
