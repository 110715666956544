import http from "config/http";
import { Cause, Interest, Skill } from "pages/ListActions/DataOfFilters";
import { Address, Contact } from "../definitions";

interface UserUpdateRequest {
  contacts?: Contact[];
  locations?: Address;
  conditionals?: {
    causes?: Cause[];
    functions?: Skill[];
    interests?: Interest[];
  };
  profile_image?: string;
}

type UserUpdateResponse = {};

const update = async (data: UserUpdateRequest) => http.patch<UserUpdateResponse>(`/users`, data);

export default update;
