import useTranslationV3 from "hooks/useTranslationV3";
import { rem } from "polished";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled, { css } from "styled-components/macro";
import mqV3 from "utils/mediaQueriesV3";
import Checkbox, { CheckboxLabel } from "../Checkbox/Checkbox";
import Input from "../Form/Input/Input";
import Icon from "../Icons/Icon";
import { ModalHeaderOpportunity, SubjectInfo, SubscriptionContentWrapper, TitleSection } from "./ModalSubscriptionBase";
import usePlatformLanguage from "hooks/usePlatformLanguage";
import { matchPath, useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useReduxSelector } from "hooks/useReduxSelector";
import { MaterialItem, MaterialList } from "core/api/definitions";
import { donationAssign, updateActionMaterialsItems } from "core/api/site/actions";
import useMountedRef from "hooks/useMountedRef";
import { donationActions } from "core/api/site/donations/donations_actions";
import ModalBaseContainer, { ModalBody, ModalClose, ModalFooter } from "./ModalBase";
import { reverse, ReverseParams } from "named-urls";
import Button from "../Button/Button";
import routes from "routes";
import FinishedStep from "./ModalSignInUp/Finished";
import SignInSignUpSteps from "./ModalSignInUp/Steps";
import { useUser } from "hooks/useUser";
import { showAlert } from "core/alerts/actions";
import Loading from "components/Loading/Loading";
import { openInRoutes } from "utils/helpers";
interface QuantityProps {
  initialQuantity?: number;
  onQuantityChanged?: (quantity: number) => void;
  disabledInput?: boolean;
  maxValue: number;
  idvalue?: string | undefined;
  // setItemsList?: any;
  // itemsList?: any;
}

//CAIXA DE SELECIONAR A QUANTIDADE DESEJADA
const Quantity = ({ initialQuantity, onQuantityChanged, maxValue, idvalue, ...props }: QuantityProps) => {
  const [quantity, setQuantity] = useState<number | undefined>(initialQuantity);
  const [disabledInput, setDisabledInput] = useState(false);
  const [inputStates, setInputStates] = useState<{ [key: string]: boolean }>({});
  const [initialDesabled, setInitialDesabled] = useState(false);
  const [activeInputId, setActiveInputId] = useState<string | null>(null);

  const { language } = usePlatformLanguage();
  const onChangeHandle = useCallback((q: number) => {
    setQuantity(parseInt(q.toString()));
  }, []);

  useEffect(() => {
    if (!disabledInput) {
      setQuantity(0);
    }
    if (onQuantityChanged) {
      onQuantityChanged(quantity ?? 0);
    }

    if (quantity == 0) {
      setInitialDesabled(false);
    }
  }, [onQuantityChanged, quantity]);

  //  const onClickInput = (id: any) => {
  //   setItemsList(itemsList.length > 0 ? itemsList.map((i: any) => (i._id === id ? { ...i } : i)) : itemsList);
  //  };

  useEffect(() => {
    const isEnabled = Object.values(inputStates).some((value) => value === true);
    setDisabledInput(!isEnabled);
  }, []);

  return (
    <QuantityStyled>
      <ButtonQuantity disabled={!quantity || (!!quantity && quantity <= 0) || !disabledInput}>
        <Icon
          icon="minus"
          color={quantity && quantity > 0 && disabledInput ? "primary" : "neutralLightest"}
          onClick={() => setQuantity((prev) => (prev ? --prev : 0))}
        />
      </ButtonQuantity>

      <InputQuantity
        mask="999"
        value={quantity}
        active={quantity && quantity > 0}
        onChange={onChangeHandle}
        onClick={() => {
          if (!initialDesabled) {
            setInitialDesabled(true);
          }
        }}
        placeholder=""
      />
      <ButtonQuantity disabled={!initialDesabled || (quantity && quantity >= maxValue) ? true : false}>
        <Icon
          icon="plus"
          color={initialDesabled ? "primary" : "neutralLightest"}
          onClick={() => setQuantity((prev) => (prev ? ++prev : 1))}
        />
      </ButtonQuantity>
    </QuantityStyled>
  );
};

interface MaterialItemCheckable extends MaterialItem {
  checked: boolean;
}

interface ModalMaterialsListProps {
  isOpen: boolean;
  onClose(): void;
}

const openIn = openInRoutes(
  [routes.opportunities.donation, routes.project.donation, routes.blog.post.donation, routes.action.donation],
  [routes.opportunities.toString(), routes.project.toString(), routes.blog.post.toString(), routes.action.toString()]
);

const ModalMaterials = ({ isOpen, onClose }: ModalMaterialsListProps): JSX.Element => {
  const { t } = useTranslationV3();
  const dispatch = useDispatch();
  const mountRef = useMountedRef();
  const location = useLocation();
  const history = useHistory();

  const [step, setStep] = useState<"" | "success" | "login">("");

  const [itemsList, setItemsList] = useState<MaterialItemCheckable[]>([]);
  const [donating, setDonating] = useState(false);
  const [donationNumber, setDonationNumber] = useState(0);

  const params = useParams<{
    donationSlug: string;
    slug: string;
    donationID?: string;
    actionSlug?: string;
  }>();

  const modal = useReduxSelector((state) => state.pages.modal);

  const donationID = params.donationID ?? modal?.donationID;
  const donationSlug = params.donationSlug;
  const actionSlug = params?.actionSlug ?? params?.slug ?? modal?.actionSlug;

  const { materialList, action } = useReduxSelector((state) => {
    const material = state.pagesNew.cards.cache.materials[donationID || ""] || undefined;
    const data = material
      ? {
          action_data: material?.action_data,
          project_data: material?.project_data,
          delivery_date: material?.delivery_date,
          delivery_way: material?.delivery_way,
        }
      : undefined;

    const action = state.pagesNew?.actions?.actionBySlug[actionSlug] || data || null;

    const materialList =
      (action?.opportunities_data?.find((m) => m.level === "materials" && m.slug === donationSlug) as
        | MaterialList
        | undefined) ||
      material ||
      null;
    return {
      action,
      materialList,
    };
  });

  const loading = !materialList;

  const matchRoute = matchPath(location.pathname, openIn?.routes || "");
  const isOpenProxy = isOpen || !!matchRoute?.isExact;

  useEffect(() => {
    if (actionSlug && donationID) {
      dispatch(donationActions.showMaterials({ actionSlug: actionSlug, materialId: donationID }));
    }
  }, [actionSlug, donationID, action, materialList]);

  const [reload, setReload] = useState(0);

  useEffect(() => {
    if (materialList?.items && materialList?.items.length > 0) {
      const items: any[] = materialList?.items.reduce((acc: any[], item: any) => {
        return [...acc, { ...item, checked: false, percent: (100 * item.donated) / item.quantity }];
      }, []);
      setItemsList(items);
    }

    renderSlide();
  }, [materialList, reload]);

  useEffect(() => {
    if (!isOpenProxy) {
      setItemsList([]);
      setItemsList([]);
    }
    if (!isOpenProxy && step !== "") setStep("");
  }, [isOpenProxy, step]);

  // const onChangeChecbox = useCallback(
  //   (id: string, checked: boolean) => {
  //     setItemsList(itemsList.length > 0 ? itemsList.map((i) => (i._id === id ? { ...i, checked } : i)) : itemsList);
  //   },
  //   [itemsList]
  // );

  const { user } = useUser();

  const handleAction = useCallback(async () => {
    if (!user) {
      setStep("login");
      return;
    }

    setDonating(true);

    try {
      const checkedItems = itemsList.filter((item) => item.donated_current !== undefined && item.donated_current > 0);
      const request = {
        action_slug: action?.action_data.slug || "",
        material_id: materialList?._id || "",
        donated: checkedItems.map((i) => i.donated_current) || 0,
        material_list_items_id: checkedItems.map((i) => i._id) || [],
      };
      const response: any = await dispatch(donationAssign(request));

      if (!mountRef.current) return;

      if (donationAssign.fulfilled.match(response)) {
        const { payload } = response;
        dispatch(updateActionMaterialsItems(request));
        setStep("success");
      } else if (donationAssign.rejected.match(response)) {
        dispatch(
          showAlert(
            "danger",
            t([`errors.${(response as any)?.payload?.response?.message_translated}`, "errors.default"])
          )
        );
      }
    } finally {
      if (!mountRef.current) return;
      setDonating(false);

      setTimeout(() => {
        window.location.reload(); //timeout para recarregamento
      }, 3000);
    }
  }, [t, mountRef, dispatch, user, materialList, action, itemsList, actionSlug, donationSlug]);

  const handleActionRef = useRef<() => void>(handleAction);

  if (handleActionRef) {
    handleActionRef.current = handleAction;
  }

  const handleLogin = useCallback(() => {
    setStep("");
    setDonating(true);

    setTimeout(() => {
      handleActionRef.current!();
    }, 400);
  }, [handleActionRef]);

  const itemsAvailable = useMemo(() => materialList?.items?.filter((item) => item.status === "available"), [
    materialList,
  ]);

  const itemsChecked = useMemo(() => itemsList?.filter((item) => item.checked), [itemsList]);

  const onCloseProxy = useCallback(() => {
    if (params?.donationID && actionSlug) {
      const r = openIn?.getParent(matchRoute?.path || "");
      if (r) {
        history.push({ pathname: reverse(r, params as ReverseParams) });
      } else {
        history.goBack();
      }
    } else {
      onClose();
    }
  }, [onClose, history, matchRoute, params, actionSlug]);

  const changerValue = (id: string, donated: number, total: number) => {
    if (donated <= total) {
      for (let i = 0; i < itemsList.length; i++) {
        if (itemsList[i]._id === id) {
          if (donated.toString() != "") {
            itemsList[i].donated_current = donated;
          }
        }
      }
      let donatedSum: number = itemsList.reduce((accumulator, currentItem) => {
        return accumulator + (currentItem.donated_current ?? 0);
      }, 0);
      setDonationNumber(parseInt(donatedSum.toString()));
    } else {
      for (let i = 0; i < itemsList.length; i++) {
        if (itemsList[i]._id === id) {
          if (donated.toString() != "") {
            itemsList[i].donated_current = 0;
            itemsList[i].checked = false;
          }
        }
      }
      setLoadRenderW((oldKey) => oldKey + 1);
    }
  };

  const renderSlide = () => {
    return (
      <>
        {itemsList.map((item, key) => (
          <ListMaterialsItem key={key}>
            {item.donated < item.quantity ? (
              <ProgressBar>
                <Count>
                  <Progress percentage={item.percent} />
                </Count>
                <Label>{t(`components:entities.donations.status.remaining`, { count: item.total })}</Label>
              </ProgressBar>
            ) : (
              <>
                <ProgressBar>
                  <Count>
                    <Progress percentage={100} />
                  </Count>
                  <Label>{t(`components:entities.donations.status.remaining`, { count: 0 })}</Label>
                </ProgressBar>
              </>
            )}
          </ListMaterialsItem>
        ))}
      </>
    );
  };

  const [loadRenderW, setLoadRenderW] = useState(0);
  useEffect(() => {
    WrapperRender();
  }, [loadRenderW]);
  const WrapperRender = () => {
    return (
      <Wrapper>
        <SubscriptionContentWrapper>
          <TitleSection tag="h3" size="xs" color="neutralLight">
            {t("plain:Selecione seus itens")}
          </TitleSection>
          <ListMaterials>
            {itemsList.map((item, key) => (
              <ListMaterialItem key={key}>
                <ItemTitle dangerouslySetInnerHTML={{ __html: item.title }} />
              </ListMaterialItem>
            ))}
          </ListMaterials>
        </SubscriptionContentWrapper>

        <SubscriptionContentWrapper>
          <TitleCenter>
            <TitleSection tag="h3" size="xs" color="neutralLight">
              {t("plain:meta")}
            </TitleSection>
          </TitleCenter>
          <ListMaterials>{renderSlide()}</ListMaterials>
        </SubscriptionContentWrapper>

        <SubscriptionContentWrapper>
          <TitleCenter>
            <TitleSection tag="h3" size="xs" color="neutralLight">
              {t("plain:Quantidade")}
            </TitleSection>
          </TitleCenter>
          <ListMaterials>
            {itemsList.map((item, key) => (
              <ListMaterialsItem key={key}>
                {item.donated < item.quantity ? (
                  <>
                    <Quantity
                      maxValue={item.total}
                      disabledInput={false}
                      idvalue={item._id}
                      onQuantityChanged={(q) => {
                        changerValue(item._id, q, item.total);
                      }}
                    />
                  </>
                ) : (
                  <>
                    <Quantity maxValue={0} disabledInput={false} />
                  </>
                )}
              </ListMaterialsItem>
            ))}
          </ListMaterials>
        </SubscriptionContentWrapper>
      </Wrapper>
    );
  };
  return (
    <ModalBaseContainer modalSize={step !== "" ? "sm" : undefined} isOpen={isOpenProxy} toggle={onCloseProxy}>
      <ModalClose onClick={onCloseProxy} />
      {loading && <CustomLoading />}
      {!loading && (
        <>
          {step === "success" && (
            <FinishedStep title={t("plain:Sua doação foi submetida com sucesso")} url={location.pathname}>
              {t("plain:Em breve você receberá um retorno sobre sua doação. Fique atento ao seu e-mail")}
            </FinishedStep>
          )}
          {step === "login" && <SignInSignUpSteps onLogin={handleLogin} onSignUp={handleLogin} />}
          {step === "" && (
            <>
              <ModalHeaderOpportunity
                title={t("plain:Lista de Materiais")}
                shortTitle={t("plain:Lista de Materiais_short")}
                subTitle={
                  materialList && materialList.delivery_way
                    ? t(`components:entities.donations.delivery_way.${materialList.delivery_way}`)
                    : ""
                }
                subscriptionType="material"
                toggle={onCloseProxy}
              />
              <ModalBody>
                <SubjectInfo
                  subscriptionType="volunteer"
                  deliveryDate={materialList?.delivery_date}
                  deliveryWay={materialList?.delivery_way}
                  subjectTitle={materialList?.title}
                  subjectAddress={materialList?.address}
                  actionTitle={materialList?.action_data?.title || action?.action_data?.title}
                  actionLink={reverse(routes.action.toString(), {
                    slug: materialList?.action_data?.slug ?? action?.action_data?.slug ?? "",
                  })}
                  shareableUrl={reverse(routes.action.donation, {
                    slug: materialList?.action_data?.slug ?? action?.action_data?.slug ?? "",
                    donationID: materialList?._id ?? "",
                  })}
                />

                {WrapperRender()}
              </ModalBody>
              {itemsAvailable && itemsAvailable.length > 0 && (
                <ModalFooter>
                  <Button color="primary" block disabled={donationNumber === 0 ? true : false} onClick={handleAction}>
                    {t("actions.confirmDonation.label")}
                  </Button>
                </ModalFooter>
              )}
            </>
          )}
        </>
      )}
    </ModalBaseContainer>
  );
};

export default ModalMaterials;

const ProgressBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ListMaterials = styled.ul`
  min-height: 216px;
  padding: 0;
  margin: 0;
  border-top: 1px solid ${({ theme }) => theme.v3.colors.neutralLightest};
`;

const ListMaterialItem = styled.li`
  height: 72px;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.v3.colors.neutralLightest};
  && span {
    padding-left: 0px;
  }
`;

const TitleCenter = styled.div`
  display: flex;
  justify-content: center;
`;
const ListMaterialsItem = styled.li`
  display: flex;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.v3.colors.neutralLightest};
  padding: ${({ theme }) => theme.v3.spacing.sm} 0;

  ${mqV3.xsDown} {
    flex-direction: column;
    align-items: start;
  }
`;

const Wrapper = styled.div`
  display: flex;
`;

const ItemTitle = styled.span`
  font-size: ${({ theme }) => theme.v3.fontSize.sm};
  color: ${({ theme }) => theme.v3.colors.neutralBase};
  padding-left: ${({ theme }) => theme.v3.spacing.lg};

  ${mqV3.xsDown} {
    padding-left: 0;
    margin-bottom: ${({ theme }) => theme.v3.spacing.sm};
  }
`;

const CheckboxStyled = styled(Checkbox)`
  ${CheckboxLabel} {
    color: ${({ theme }) => theme.v3.colors.neutralBase};
  }

  input {
    margin-right: ${({ theme }) => theme.v3.spacing.sm};

    &:checked ~ ${CheckboxLabel} {
      font-weight: 500;
    }

    &:disabled ~ ${CheckboxLabel} {
      cursor: default;
    }
  }

  ${mqV3.xsDown} {
    margin-bottom: ${({ theme }) => theme.v3.spacing.sm};
  }
`;

const TiteItem = styled.span`
  color: ${({ theme }) => theme.v3.colors.neutralBase};
  }
`;

const QuantityStyled = styled.div`
  display: flex;
  align-items: center;
`;

const InputQuantity = styled(({ active, onClick, ...props }) => <Input {...props} onClick={onClick} />)<{
  active?: boolean;
  onClick?: () => void;
}>`
  padding: ${({ theme }) => `${theme.v3.spacing.xs} ${theme.v3.spacing.sm}`};
  color: ${({ theme }) => theme.v3.colors.neutralBase};
  background: ${({ theme }) => theme.v3.colors.neutralBackground};
  line-height: ${rem(20)};
  text-align: center;
  width: 62px;
  margin: 0 ${({ theme }) => `${theme.v3.spacing.xs}`};

  &:focus {
    background: ${({ theme }) => theme.v3.colors.neutralBackground};
  }

  ${({ active }) =>
    !active &&
    css`
      color: ${({ theme }) => theme.v3.colors.neutralLightness};
    `}
`;

const ButtonQuantity = styled.button`
  border-radius: 50%;
  border: 1px solid transparent;
  background: none;
  padding: 0;
  width: 26px;
  height: 26px;

  &:focus {
    border-color: ${({ theme }) => theme.v3.colors.feedbackSupport};
    box-shadow: none;
    outline: none;
  }

  :disabled {
    pointer-events: none;
  }
`;

const CustomLoading = styled(Loading)`
  margin: ${({ theme }) => theme.v3.spacing.huge} 0;
`;

const Label = styled.p`
  padding-top: 7px;
  font-size: ${rem(12)};
  line-height: ${rem(10)};
  color: ${({ theme }) => theme.v3.colors.neutralLight};
  margin: 0;
  min-width: 68px;
`;

const Count = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const Progress = styled.div<{ percentage?: number }>`
  //bottom: -10px;
  min-width: 140px !important;
  max-width: 140px !important;
  height: 5px;
  background-color: ${({ theme }) => theme.v3.colors.neutralLightest};
  border-radius: 5px;

  :before {
    content: "";
    position: absolute;
    height: 4px;
    max-width: 140px !important;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto 0;
    background-color: ${({ theme }) => theme.v3.colors.primary};
    ${({ percentage }) =>
      percentage !== undefined &&
      css`
        width: ${percentage}%;
      `}
  }
`;
