import http from "config/http";

import { ListResponse } from "core/api/definitions";

export interface ListVolunteersRequest {
  pg?: number;
  pp?: number;
  terms?: string;
}

export interface ListVolunteersRequest {
  projectSlug: string;
  action_id?: string;
  interest?: string;
  function?: string;
  skill?: string;
  text?: string;
}

interface VolunteerArray {
  description?: string;
  slug?: string;
}

export interface ProjectVolunteersResponse {
  _id: string;
  name: string;
  email: string;
  profile_image: string;
  phone: {
    country: string;
    value: string;
  };
  skills?: VolunteerArray[];
  interests?: VolunteerArray[];
  functions?: VolunteerArray[];
}

export type ListVolunteersResponse = ListResponse<ProjectVolunteersResponse>;

const listVolunteers = ({ projectSlug, ...data }: ListVolunteersRequest) => {
  return http.post<ListVolunteersResponse>(`/projects/${projectSlug}/list-all-volunteers`, data);
};

export default listVolunteers;
