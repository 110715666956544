import FeedBackModal from "components/FeedbackModal/FeedbackModal";
import { LoadingStatus } from "core/api/definitions";
import useTranslationV3 from "hooks/useTranslationV3";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { APIParamsToString } from "utils/helpers";
import Button from "../../Button/Button";
import InputController from "../../Form/Input/InputController";
import ModalBaseContainer, { ModalBody } from "../ModalBase";
import { IconType } from "icons";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "utils/yup";
import PasswordRules from "v3/components/common/PasswordRules";
import { Col, Row } from "../../Grid";
import { ModalHeader, Title, FormGroup } from "./common";
import { ResetPasswordRequest } from "core/api/auth/resetPassword";
import { resetPassword } from "core/auth/actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import routes from "routes";
import http from "config/http";

interface FormData {
  password: string;
  password_confirmation: string;
}

interface ModalResetPasswordProps {
  token: string;
  isOpen?: boolean;
  toggle(): void;
}

const schemaResetPassword = yup.object().shape({
  password: yup.string().required("errors.is-required"),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password")], "errors.password-does-not-match")
    .required("errors.is-required"),
});

const ModalResetPassword = ({ token, isOpen, toggle }: ModalResetPasswordProps) => {
  const { t } = useTranslationV3();

  const [feedbackModal, setFeedbackModal] = useState<{ status: "success" | "error"; message?: string } | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<LoadingStatus>("idle");
  const [passwordIcon, setPasswordIcon] = useState<IconType>("eyeClosed");
  const [passwordConfirmationIcon, setPasswordConfirmationIcon] = useState<IconType>("eyeClosed");
  const [validate, setValidate] = useState<boolean>(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const { handleSubmit, control, errors, watch, setError, setValue } = useForm<FormData>({
    resolver: yupResolver(schemaResetPassword),
    mode: "onTouched",
  });

  const watchForm = watch(["password", "password_confirmation"]);

  const { password, password_confirmation } = watchForm;

  const rulesMemorized = useMemo(
    () => ({
      minLength: PasswordRules.RulesTest.minLength(password),
      alphanumeric: PasswordRules.RulesTest.alphanumeric(password),
      capitalLetter: PasswordRules.RulesTest.capitalLetter(password),
      specialCharacters: PasswordRules.RulesTest.specialCharacters(password),
      isDiff: password !== password_confirmation,
    }),
    [password, password_confirmation]
  );

  const onCloseFeedbackCallback = useCallback(() => {
    if (!feedbackModal) return;

    if (feedbackModal.status === "success") {
      history.push({
        pathname: routes.home,
        search: "?open=signIn",
      });
    } else {
      history.push({
        pathname: routes.home,
        search: "?open=forgotPassword",
      });
    }

    setFeedbackModal(undefined);
  }, [feedbackModal, setFeedbackModal, history]);

  const onSubmitCallback = useCallback(
    async (data: FormData) => {
      const dataSubmit: ResetPasswordRequest = { ...data, token };

      setLoading("loading");
      const response: any = await dispatch(resetPassword(dataSubmit));

      if (resetPassword.fulfilled.match(response)) {
        toggle();
        setFeedbackModal({
          status: "success",
        });
        setLoading("ok");
      } else if (resetPassword.rejected.match(response)) {
        const { payload } = response as any;
        const { params } = payload;

        toggle();
        setFeedbackModal({
          status: "error",
          message: payload?.response?.message ?? APIParamsToString(params).toString(),
        });

        setLoading("error");
      }
    },
    [token, dispatch, setLoading, toggle]
  );

  useEffect(() => {
    const token = new URLSearchParams(window.location.search).get("token");
    const verifyToken = async () => {
      try {
        const response = await http.post("auth/validation_token", { token });

        if (response.data.success) {
          setValidate(true);
        } else {
          setErrorModalOpen(true);
        }
      } catch (error) {
        console.error(error);
        setLoading("error");
        setRedirect(true);
      }
    };
    const url = window.location.href;

    if (url.indexOf("password?token") !== -1) {
      verifyToken();
    }
  }, []);

  const [redirect, setRedirect] = useState<boolean>(false);

  useEffect(() => {
    if (redirect) {
      setTimeout(() => {
        setRedirect(false);
        history.push(routes.home);
      }, 4600);
    }
  }, [redirect]);

  useEffect(() => {
    if (!isOpen) {
      setLoading("idle");
    }
  }, [isOpen]);

  const togglePasswordIconCallback = useCallback(
    () => setPasswordIcon((prev: string) => (prev === "eye" ? "eyeClosed" : "eye")),
    [setPasswordIcon]
  );

  const togglePasswordConfirmationIconCallback = useCallback(
    () => setPasswordConfirmationIcon((prev: string) => (prev === "eye" ? "eyeClosed" : "eye")),
    [setPasswordConfirmationIcon]
  );

  const passwordIsValid =
    rulesMemorized.minLength &&
    rulesMemorized.alphanumeric &&
    rulesMemorized.capitalLetter &&
    rulesMemorized.specialCharacters &&
    !rulesMemorized.isDiff;

  return (
    <>
      {validate ? (
        <>
          <ModalBaseContainer isOpen={isOpen} toggle={toggle} centered={false} modalSize="sm">
            <ModalHeader toggle={toggle} />
            <ModalBody>
              <Title tag="h1" size="lg" color="neutralBase">
                {t("plain:Cadastrar nova senha")}
              </Title>
              <form onSubmit={handleSubmit(onSubmitCallback)}>
                <InputController
                  tag={FormGroup}
                  control={control}
                  type={passwordIcon === "eyeClosed" ? "password" : "text"}
                  name="password"
                  label={t("plain:Senha")}
                  placeholder={t("plain:Senha Placeholder")}
                  invalid={!!errors?.password}
                  message={t(errors?.password?.message || "")}
                  icon={passwordIcon}
                  onClickIcon={togglePasswordIconCallback}
                  defaultValue=""
                  disabled={loading === "loading"}
                />
                <InputController
                  tag={FormGroup}
                  control={control}
                  type={passwordConfirmationIcon === "eyeClosed" ? "password" : "text"}
                  name="password_confirmation"
                  label={t("plain:Repetir Senha")}
                  placeholder={t("plain:Senha Placeholder")}
                  invalid={!!errors?.password_confirmation}
                  message={t(errors?.password_confirmation?.message || "")}
                  icon={passwordConfirmationIcon}
                  onClickIcon={togglePasswordConfirmationIconCallback}
                  defaultValue=""
                  disabled={loading === "loading"}
                />
                <RowRules>
                  <Col xs={12} sm={6}>
                    <PasswordRules.Rules.MinLength isOk={rulesMemorized.minLength} t={t} />
                    <PasswordRules.Rules.Alphanumeric isOk={rulesMemorized.alphanumeric} t={t} />
                  </Col>
                  <Col xs={12} sm={6}>
                    <PasswordRules.Rules.CapitalLetter isOk={rulesMemorized.capitalLetter} t={t} />
                    <PasswordRules.Rules.SpecialCharacters isOk={rulesMemorized.specialCharacters} t={t} />
                  </Col>
                </RowRules>
                <Button
                  color="primary"
                  size="md"
                  block
                  disabled={
                    !password ||
                    !passwordIsValid ||
                    rulesMemorized.isDiff ||
                    !!Object.keys(errors).length ||
                    loading === "loading"
                  }
                >
                  {t("actions.resetPassword.label")}
                </Button>
              </form>
            </ModalBody>
          </ModalBaseContainer>
          {/** @ts-ignore */}
          <FeedBackModal
            title={feedbackModal && feedbackModal.status === "success" ? t("plain:Tudo certo") : t("plain:Ops!")}
            message={
              feedbackModal && feedbackModal.status === "success"
                ? t("plain:Senha atualizada com sucesso")
                : t([`v3:errors.reset-password.${feedbackModal?.message}`, "v3:errors.default"])
            }
            onDismiss={feedbackModal !== undefined}
            success={feedbackModal && feedbackModal.status === "success"}
            onClose={() => onCloseFeedbackCallback()}
          />
        </>
      ) : (
        <>
          <ModalBackground isOpen={redirect}>
            <Modal>
              <ModalHeaderStyled></ModalHeaderStyled>
              <ModalContent>
                <TitleModal>{t("plain:Ops!")}</TitleModal>
                <Warning>
                  {t(
                    "plain:Solicitação de redefinição de senha expirada. Realize o processo de redefinição novamente."
                  )}
                </Warning>
              </ModalContent>
            </Modal>
          </ModalBackground>
        </>
      )}
    </>
  );
};

export default ModalResetPassword;

const RowRules = styled(Row)(
  ({ theme }) => css`
    margin-bottom: ${theme.v3.spacing.md};
  `
);

const Modal = styled.div`
  width: auto;
  border-radius: 5px;
  background: #fff;
  border-radius: 8px;
  overflow: auto;
  padding: 3px;
  display: flex;
  flex-direction: column;

  & > *:last-child {
    margin-top: auto;
  }
`;

const ModalBackground = styled.div<{ isOpen: boolean }>`
  overflow: hidden;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;

  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
`;

const TitleModal = styled.span`
  font-family: "Rubik";
  font-size: 25px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #274264;
  margin-bottom: 20px;
  margin-top: 35px;
`;

const Warning = styled.span`
  font-family: "Rubik";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  width: 350px;
  color: #274264;
  margin-bottom: 35px;
`;

const ModalContent = styled.div`
  width: 393px;
  border-radius: 5px;
  background: #fff;
  border-radius: 8px;
  overflow: auto;
  padding: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ModalHeaderStyled = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
`;
