import { createSlice, SliceCaseReducers, PayloadAction } from "@reduxjs/toolkit";
import {
  RequestableState,
  ActionData,
  FunctionResponse,
  ProjectData,
  SubscribeData,
  Subscribe,
  Project,
} from "core/api/definitions";
import decoreThunk from "core/decorators/decorate";
import { listActionsValidation, listProjectsValidation } from "./actions";
import requestLoading from "core/decorators/requestLoading";
import { logout } from "core/auth";
import { ListTagResponse, ListTagActionInterface } from "core/api/admin/tags/listTag";
import { ListAllTagsInterface, ListAllTagsResponse } from "core/api/admin/tags/listAllTags";
import { SearchActionResponse, SearchActionResponseInterface } from "core/api/admin/tags/searchActions";
import { ListActionsValidationTagsResponse } from "core/api/admin/validation/listActionsValidation";
import { ListProjectsValidationTagsResponse } from "core/api/admin/validation/listProjectsValidation";

interface IStateListActionsValidation extends RequestableState {
  response: Subscribe[];
  total: number;
}

interface IStateListProjectsValidation extends RequestableState {
  response: Project[];
  total: number;
}

export const panelAdminListActionsValidation = createSlice<
  IStateListActionsValidation,
  SliceCaseReducers<IStateListActionsValidation>
>({
  name: "panel/beta/admin/listActionsValidation",
  initialState: {
    loading: "idle",
    response: [],
    total: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, listActionsValidation, [
      requestLoading(),
      {
        pending: (state: IStateListActionsValidation) => {
          state.loading = "loading";
        },

        fulfilled: (state: IStateListActionsValidation, action: PayloadAction<ListActionsValidationTagsResponse>) => {
          state.loading = "ok";
          if (action?.payload?.response) {
            state.response = action.payload.response;
            state.total = action.payload.total;
          }
        },
        rejected: (state: IStateListActionsValidation) => {
          state.loading = "error";
        },
      },
    ]);

    builder.addCase(logout.toString(), (state) => {
      state.loading = "idle";
    });
  },
});

export const panelAdminListProjectsValidation = createSlice<
  IStateListProjectsValidation,
  SliceCaseReducers<IStateListProjectsValidation>
>({
  name: "panel/beta/admin/listProjectsValidations",
  initialState: {
    loading: "idle",
    response: [],
    total: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, listProjectsValidation, [
      requestLoading(),
      {
        pending: (state: IStateListProjectsValidation) => {
          state.loading = "loading";
        },

        fulfilled: (state: IStateListProjectsValidation, action: PayloadAction<ListProjectsValidationTagsResponse>) => {
          state.loading = "ok";
          if (action?.payload?.response) {
            state.response = action.payload.response;
            state.total = action.payload.total;
          }
        },

        rejected: (state: IStateListProjectsValidation) => {
          state.loading = "error";
        },
      },
    ]);

    builder.addCase(logout.toString(), (state) => {
      state.loading = "idle";
    });
  },
});
