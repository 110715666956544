import http from "config/http";
import { ResponseWithParams, ResponseStatus } from "core/api/definitions";
import { PlatformColors } from "./update";
import { ProcessPublish } from "../list";

export interface PlatformCustomizationShowRequest {
  platformId: string;
}

export interface PlatformCustomizationShowResponseData {
  logo?: string;
  favicon?: string;
  colors?: PlatformColors;
  process_publish: ProcessPublish;
}

export type PlatformCustomizationShowResponse = ResponseWithParams<
  ResponseStatus,
  PlatformCustomizationShowResponseData
>;

const show = ({ platformId }: PlatformCustomizationShowRequest) =>
  http.get<PlatformCustomizationShowResponse>(`/platform-launcher/${platformId}/customization`);

export default show;
