//TODO(Jeconias): Move it to outside API
import api from "core/api";
import asyncThunk from "core/decorators/toolkit";

const showMaterials = asyncThunk("site/donations/materials/show", api.actions.donation.showMaterials, {
  condition: [({ isForce, materialId }, state) => !state.pagesNew.donations.list[materialId] || !!isForce],
});

export const donationActions = {
  showMaterials,
};
