import { AxiosResponse } from "axios";
import http from "config/http";
import { ActionResponse } from "core/api/definitions";

export interface ValidateRequest {
  project: string;
  status: "active" | "reject"; // TODO(Jota): Make it an enum;
  message?: string;
}

export type ValidateResponse = ActionResponse;

export default ({ project, status, message }: ValidateRequest): Promise<AxiosResponse<ValidateResponse>> =>
  http.post<ValidateResponse>(`admin/projects/validate/${project}`, {
    status,
    message: status === "reject" ? message : "default",
  });
