import { Modal as CModal, ModalBody as CModalBody } from "components/Modal";
import { Toggler } from "components/Modal/ModalHeader";
import { ModalFooter as RSModalFooter } from "reactstrap";
import styled from "styled-components";
import { rem, transparentize } from "polished";
import { mq } from "utils/mediaQueries";

export const Modal = styled(CModal)``;

export const ModalBody = styled(CModalBody)`
  padding: 0 30px;
`;

export const ModalHeader = styled.header`
  display: block;
  margin-bottom: 16px;
  padding: 20px 30px 0 30px;
  width: 100%;
`;

export const ModalActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ModalTitle = styled.h1`
  color: #18235e;
  font-size: ${rem(25)};
  font-weight: 300;
  letter-spacing: 0;
  line-height: ${rem(35)};
  margin: 0;
`;

export const ModalToggler = styled(Toggler)`
  transform: none;
  position: static;
`;

export const ModalFooter = styled(RSModalFooter)`
  padding: 20px 30px 35px 30px;
  border: none;
  justify-content: flex-start;
`;

export const ModalButtonClose = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  border: none;
  border-radius: 3px;
  background-color: transparent;
  height: 24px;
  width: 24px;
  transition: 0.4s ease-in-out;

  :after,
  :before {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    content: "";
    box-sizing: border-box;
    height: 1px;
    width: 16.97px;
    background-color: #363837;
  }

  :after {
    transform: rotate(-45deg);
  }
  :before {
    transform: rotate(45deg);
  }

  :hover {
    background-color: ${transparentize(0.7, "#ccc")};
  }

  ${mq.mdDown} {
    top: 10px;
    right: 10px;
  }
`;
