import queryString from "query-string";
import http from "config/http";
import { ProjectStatus, ListResponse, Subscribe } from "core/api/definitions";
import { AxiosResponse } from "axios";

export interface AllRequest {
  term?: string;
  status?: string;
  pg?: number;
  pp?: number;
}

export type ListActionsValidationTagsResponse = ListResponse<Subscribe>;

export default async ({ term, status, pg, pp }: AllRequest = {}): Promise<
  AxiosResponse<ListActionsValidationTagsResponse>
> => {
  const fs: any = {};
  if (status) fs.status = status;
  if (term) fs.term = term;
  if (pg) fs.pg = pg;
  if (pp) fs.pp = Math.max(10, pp);

  return http.get<ListActionsValidationTagsResponse>(`admin/actions/all?${queryString.stringify(fs)}`);
};
