import React, { ElementType } from "react";
import styled, { css } from "styled-components/macro";
import { rem } from "polished";
import { Size } from "utils/types";
import * as H from "history";

interface TitleProps {
  tag?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "a" | "strong" | ElementType;
  size?: Size | "xmd" | "xlg";
  strong?: boolean | number;
  href?: string;
  to?: H.LocationDescriptor<any>;
  textAlign?: "center" | "right" | "left";
}

const Title: React.FunctionComponent<TitleProps & React.HTMLAttributes<HTMLOrSVGElement>> = ({
  tag: Wrapper = "h1",
  children,
  ...props
}) => <Wrapper {...props}>{children}</Wrapper>;

export const BaseTitle = styled(Title)`
  /* color: ${({ theme }) => theme.colors.secondary_dark}; */
  color: #2e4768;

  font-size: ${rem(15)};
  line-height: ${rem(22)};
  letter-spacing: 0;
  margin: 0;
  font-weight: 500;

  ${({ size }) =>
    size === "xs" &&
    css`
      font-size: ${rem(12)};
      line-height: ${rem(14)};
      letter-spacing: 0;
      font-weight: 500;
    `}

  ${({ size }) =>
    size === "sm" &&
    css`
      font-size: ${rem(15)};
      letter-spacing: 0;
      line-height: ${rem(22)};
      font-weight: 500;
    `}

  ${({ size }) =>
    size === "xmd" &&
    css`
      font-size: ${rem(18)};
      letter-spacing: ${rem(-0.18)};
      line-height: ${rem(22)};
    `}

  ${({ size }) =>
    size === "md" &&
    css`
      font-size: ${rem(26)};
      letter-spacing: ${rem(-0.27)};
      line-height: ${rem(40)};
    `}

  ${({ size }) =>
    size === "lg" &&
    css`
      font-size: ${rem(36)};
      line-height: ${rem(50)};
      letter-spacing: ${rem(-0.37)};
    `}

  ${({ size }) =>
    size === "xlg" &&
    css`
      font-size: ${rem(40)};
      line-height: ${rem(48)};
      letter-spacing: ${rem(-0.37)};
    `}

    ${({ textAlign }) =>
    textAlign &&
    css`
      text-align: ${textAlign};
    `}

    ${({ strong }) =>
    typeof strong === "number"
      ? css`
          font-weight: ${strong};
        `
      : strong
      ? css`
          font-weight: 500;
        `
      : null}

@media (max-width: 480px) {
    font-size: 14px;
  }
`;
