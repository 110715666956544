import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit";
import api from "core/api";
import { AllRequest, AllResponse } from "core/api/commonUser/program/all";
import { RequestableState, ListState, ListAction, LoadingStatus } from "core/api/definitions";
import { ErrorResponse } from "core/api/definitions";
import list from "core/decorators/list";
import decoreThunk from "core/decorators/decorate";
import { Program } from "core/api/definitions";
import asyncThunk from "core/decorators/toolkit";

export type GetAllResponse = (ListAction & AllResponse) | ErrorResponse;

export const getAll = asyncThunk<GetAllResponse, AllRequest>("page/program/all", api.commonUser.program.all, {
  withReturn: ["pg"],
});

type IState = RequestableState & ListState<Program & { validatingStatus: LoadingStatus }>;

const programSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "listProgram",
  initialState: {
    loading: "idle",
    list: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, getAll, [list()]);
  },
});

export default programSlice;
