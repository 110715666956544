import { AxiosResponse } from "axios";
import http from "config/http";
import { Origin } from "core/api/definitions";
import { ActionResponse } from "./definitions";

export interface OriginRequest {}

export type OriginResponse = ActionResponse<Origin>;

export default (_: OriginRequest = {}): Promise<AxiosResponse<OriginResponse>> => http.get<OriginResponse>(`origin`);
