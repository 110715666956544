import { rem } from "polished";
import React from "react";
import styled, { css } from "styled-components/macro";
import ButtonBase, { ButtonBaseProps } from "./ButtonBase";

interface ButtonPrimaryProps extends ButtonBaseProps {}

/**
 * @deprecated
 */
const ButtonPrimary: React.FC<ButtonPrimaryProps> = ({ children, ...props }) => {
  return <ButtonStyled {...props}>{children}</ButtonStyled>;
};

export default ButtonPrimary;

const ButtonStyled = styled(ButtonBase)`
  background: ${({ theme }) => theme.v3.colors.primary};
  color: ${({ theme }) => theme.color ?? "#fff"};
  white-space: nowrap;
  margin-left: 3px;

  ${({ size = "md" }) =>
    size === "md" &&
    css`
      font-weight: 500;
    `}

  &:focus {
    border: ${rem(2)} solid ${({ theme }) => theme.v3.colors.feedbackSupport};
    outline: none;
  }

  &:hover {
    color: ${({ theme }) => theme.v3.colors.secondaryDark};
    background: ${({ theme }) => theme.v3.colors.primaryLight};
  }

  &:active {
    background: ${({ theme }) => theme.v3.colors.primaryDark};
    border-color: transparent;
  }

  &:disabled {
    background: ${({ theme }) => theme.v3.colors.neutralBackground};
    color: ${({ theme }) => theme.v3.colors.neutralLightness};
  }
`;
