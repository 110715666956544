import { AxiosResponse } from "axios";
import http from "config/http";
import { Response, ResponseStatus, ResponseWithParams } from "core/api/definitions";

export type ValidateMultipleVolunteerRequest = {
  status: "present" | "absent";
  registration_id: string[];
};

export type ValidateMultipleVolunteersResponse = ResponseWithParams<ResponseStatus, ValidateMultipleVolunteerRequest>;

export default async ({
  registration_id,
  status,
}: ValidateMultipleVolunteerRequest): Promise<AxiosResponse<ValidateMultipleVolunteersResponse>> => {
  return http.post<ValidateMultipleVolunteersResponse>(`/volunteer/multiple-checkout`, {
    registration_id,
    status,
  });
};
