import React from "react";
import styled from "styled-components";

type EllipsisSize = "sm" | "md" | "lg";

const containerSize = {
  sm: "20px",
  md: "70px",
  lg: "150px",
};

const ellipsisSize = {
  sm: "4px",
  md: "10px",
  lg: "20px",
};

interface EllipsisProps {
  size?: EllipsisSize;
}

const Ellipsis = (props: EllipsisProps): JSX.Element => (
  <ElipsisStyled {...props}>
    <div></div>
    <div></div>
    <div></div>
  </ElipsisStyled>
);

/**
 * Thanks to Tobias Ahlin https://tobiasahlin.com/spinkit/
 */
const ElipsisStyled = styled.div<EllipsisProps>`
  width: ${({ size }) => containerSize[size || "md"]};
  display: flex;
  justify-content: space-evenly;

  > div {
    width: ${({ size }) => ellipsisSize[size || "md"]};
    height: ${({ size }) => ellipsisSize[size || "md"]};
    background-color: ${({ color, theme }) => color || theme.colors?.secondary || "#dfdfdf"};

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: ellipsis-bouncedelay 2.1s infinite ease-in-out both;
    animation: ellipsis-bouncedelay 2.1s infinite ease-in-out both;

    &:first-child {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;

      & + div {
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
      }
    }
  }

  @-webkit-keyframes ellipsis-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes ellipsis-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0.5);
      opacity: 0;
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }
  }
`;

export default Ellipsis;
