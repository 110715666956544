import http from "config/http";
import { FilterBy, MaterialStatus } from "../definitions";
import { ListWithFilterResponse } from "../definitions";
import queryString from "query-string";
import { AxiosResponse } from "axios";

export interface ListToCheckoutRequest {
  status?: FilterBy;
  pg?: number;
  pp?: number;
}

// Filter data key into response
export interface ListToCheckoutResponseFilter {
  _id: string;
  title: string;
  slug: string;
}

interface UserData {
  _id: string;
  name: string;
  profile_image: string;
}

interface MaterialList {
  _id: string;
  title: string;
  slug: string;
}

// Result key into response
export interface ListToCheckout {
  _id: string;
  status: MaterialStatus;
  user_data: UserData;
  material_list: MaterialList;
}

export type ListToCheckoutResponse = ListWithFilterResponse<ListToCheckout, ListToCheckoutResponseFilter[]>;

export default async (props: ListToCheckoutRequest): Promise<AxiosResponse<ListToCheckoutResponse>> => {
  if (!props.status) props.status = "all";

  return http.get<ListToCheckoutResponse>(`/donors/materials/listToCheckout?` + queryString.stringify(props));
};
