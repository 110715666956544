import { BaseTitle } from "components/common/BaseTitle";
import SupportText from "components/common/SupportText";
import { MaterialList } from "core/api/definitions";
import { ValidationErrorSet } from "core/api/definitions";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import ActionsFooter from "wizard/v3/components/Layout/ActionsFooter";
import { TitleContainer } from "wizard/v3/components/Layout/Content";
import { WizardContainer } from "wizard/v3/components/Layout/Wizard";
import FormWizard from "../common/FormWizard";
import { PageEvents } from "../definitions/commonTypes";
import DonationListInfo from "./DonationListInfo";
import { Helmet } from "react-helmet";
import styled from "styled-components/macro";

export type MaterialListInfo = Partial<MaterialList>;

interface DonationListDataProps extends PageEvents<MaterialListInfo> {
  info?: MaterialListInfo;
  onTouched?(key: string): void;
}

const DonationListInfoPage: React.FC<DonationListDataProps> = ({
  info: donation,
  onContinue,
  onChange,
  onTouched,
  onBack,
  error,
}) => {
  const { t } = useTranslation();

  const onContinueCallback = useCallback(() => {
    if (onContinue) onContinue(donation!);
  }, [onContinue, donation]);

  const addressError =
    (error as ValidationErrorSet)?.["locations.city"] ||
    (error as ValidationErrorSet)?.["locations.coordinates"] ||
    (error as ValidationErrorSet)?.["locations.state"] ||
    (error as ValidationErrorSet)?.["locations.country"];

  const addressIsNotRequired = donation?.action_address || donation?.delivery_way === "to_combine";
  const addressData = addressIsNotRequired ? undefined : addressError;
  const baseAddress = !!(donation?.address?.city && donation?.address?.state && donation?.address?.country);

  return (
    <FormWizard onContinue={onContinueCallback}>
      <WizardContainer>
        <TitleContainer>
          <Helmet title={t("wizard.pages.donationListData.title")} />
          <BaseTitle size="md">{t("wizard.pages.donationListData.title")}</BaseTitle>
          <SupportText size="md">{t("wizard.pages.donationListData.subtitle")}</SupportText>
        </TitleContainer>
        <CustomContainer>
          <DonationListInfo
            info={donation}
            hasError={{
              title: (error as ValidationErrorSet)?.title,
              delivery_date: (error as ValidationErrorSet)?.delivery_date,
              delivery_way: (error as ValidationErrorSet)?.delivery_way,
              address: addressData,
            }}
            onChange={onChange}
            onTouched={onTouched}
          />
        </CustomContainer>
      </WizardContainer>
      <ActionsFooter
        onBack={onBack}
        continueDisabled={
          !!(
            (error as ValidationErrorSet)?.title ||
            (error as ValidationErrorSet)?.delivery_date ||
            (error as ValidationErrorSet)?.delivery_way ||
            addressData ||
            !donation?.delivery_way ||
            (!baseAddress && !addressIsNotRequired)
          )
        }
        onContinue={onContinueCallback}
      />
    </FormWizard>
  );
};

export default DonationListInfoPage;

const CustomContainer = styled.div`
  max-width: 450px;
  margin: 0 auto;
`;
