import React, { useCallback } from "react";
import styled, { css } from "styled-components/macro";
import { InputProps, Label as BaseLabel, Input as BaseInput } from "reactstrap";
import { transition } from "utils/animation";

export interface ToggleBaseProps extends Omit<InputProps, "value"> {
  id: string;
  checked?: boolean;
  disabled?: boolean;
  onValueChange?(value: boolean): void;
}

const ToggleBaseComp: React.FC<ToggleBaseProps> = ({
  id,
  checked,
  onChange,
  onValueChange,
  className,
  children,
  disabled,
  ...props
}): JSX.Element => {
  const onChangeCallback = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (disabled) return;
      if (onChange) onChange(e);
      if (onValueChange) onValueChange(e?.target?.checked);
    },
    [onChange, onValueChange, disabled]
  );

  return (
    <Wrapper className={className} disabled={disabled}>
      {children}
      <BaseInputStyled
        id={id}
        checked={checked}
        disabled={disabled}
        {...props}
        type={"checkbox"}
        onChange={onChangeCallback}
      />
      <ToggleStyled />
    </Wrapper>
  );
};

const ToggleBase = styled(ToggleBaseComp)``;

export default ToggleBase;

const Wrapper = styled(BaseLabel)<{ disabled?: boolean }>`
  font-size: ${({ theme }) => theme.v3.fontSize.sm};
  color: ${({ theme }) => theme.v3.colors.neutralLight};
  margin: 0;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.65;
    `};
`;

const ToggleStyled = styled.span(
  ({ theme }) => css`
    display: block;
    position: relative;
    width: 32px;
    height: 20px;
    margin: 0;
    margin-left: ${theme.v3.spacing.xs};
    cursor: pointer;

    &:after,
    &:before {
      display: block;
      position: absolute;
      content: "";
    }

    &:before {
      width: 32px;
      height: 18px;
      top: 2px;
      right: 0;
      bottom: 2px;
      background-color: ${theme.v3.colors.neutralLightest};
      border: 1px solid transparent;
      border-radius: 60px;
      transition: ${transition.normal("background-color")};
    }

    &:after {
      top: 2px;
      right: 15px;
      bottom: 0;
      margin: auto 0;
      height: 14px;
      width: 14px;
      background-color: ${theme.v3.colors.neutralWhite};
      border-radius: 50%;
      transition: ${transition.normal("right", "background-color")};
    }
  `
);

const BaseInputStyled = styled(BaseInput)`
  opacity: 0;
  position: absolute;

  ${({ theme }) => css`
    &:checked + ${ToggleStyled} {
      &:before {
        background-color: ${theme.v3.colors.primary};
      }

      &:after {
        right: 3px;
        background-color: ${theme.v3.colors.neutralWhite};
      }
    }
    :focus + ${ToggleStyled}:before {
      border-color: ${theme.v3.colors.feedbackSupport};
    }
  `}
`;
