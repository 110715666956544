import http from "config/http";
import { ResponseWithParams, ResponseStatus } from "core/api/definitions";
import { AxiosResponse } from "axios";

interface response {
  platforms: {};
}

export type listFiltersReportRankingResponse = ResponseWithParams<ResponseStatus, response>;

export default async (): Promise<AxiosResponse<listFiltersReportRankingResponse>> => {
  return http.get<listFiltersReportRankingResponse>("admin/dashboard/get-filters-ranking-volunteer-report");
};
