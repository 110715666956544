import { AxiosResponse } from "axios";
import http from "config/http";
import { ActionResponse } from "core/api/definitions";

export interface exportReportEngagementRequest {
  date_start?: string;
  date_end?: string;
}

export type exportReportEngagementResponse = ActionResponse;

const exportReportEngagement = async ({
  date_start,
  date_end,
}: exportReportEngagementRequest): Promise<AxiosResponse<exportReportEngagementResponse>> => {
  return http.post<exportReportEngagementResponse>(`admin/dashboard/report-engagement-admin`, {
    date_start,
    date_end,
  });
};

export default exportReportEngagement;
