import { createSlice, SliceCaseReducers, PayloadAction } from "@reduxjs/toolkit";
import api from "core/api";
import decoreThunk from "core/decorators/decorate";
import requestLoading from "core/decorators/requestLoading";
import asyncThunk from "core/decorators/toolkit";
import { LoadingStatus, Meta, MetaArg, OpportunitiesData, WithCurrentPage } from "core/api/definitions";
import { ListOpportunityRequest, ListOpportunityResponse } from "core/api/opportunities/list";
import { ListOpportunitiesByProjectResponse } from "core/api/opportunities/listByProject";
import { updateCoordinates } from "core/geolocation";
import { ListOpportunityMatchmakerResponse } from "core/api/opportunities/matchmaker";

interface OpportunityBlogRequest extends ListOpportunityRequest {
  postSlug: string;
}

export const listOpportunity = asyncThunk("site/opportunities/list", api.opportunities.list, {
  condition: [
    ({ isForce }, state) => !state.pagesNew.opportunities.list?.response?.opportunities_data?.length || !!isForce,
  ],
});

export const listOpportunityMatchmaker = asyncThunk("site/opportunities/matchmaker/list", api.opportunities.matchmaker);

export const listOpportunityBlog = asyncThunk(
  "site/opportunities/blog/list",
  ({ postSlug, ...data }: OpportunityBlogRequest) => api.opportunities.list(data),
  {
    condition: [
      ({ isForce, postSlug }, state) => !state.pagesNew.opportunities.listBlog[postSlug]?.list?.length || !!isForce,
    ],
  }
);

export const listOpportunitiesByProject = asyncThunk(
  "site/opportunities/listByProject",
  api.opportunities.listByProject
);

interface IState {
  loading: LoadingStatus;
  list?: WithCurrentPage<ListOpportunityResponse>;
  listMatchmaker?: WithCurrentPage<ListOpportunityMatchmakerResponse>;
  listByProject: {
    [k: string]: {
      loading: LoadingStatus;
      list?: OpportunitiesData[];
    };
  };
  listBlog: {
    [k: string]: {
      loading: LoadingStatus;
      list?: OpportunitiesData[];
    };
  };
}

const opportunitiesSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "siteOpportunities",
  initialState: {
    loading: "idle",
    listByProject: {},
    listBlog: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, listOpportunity, [
      requestLoading(),
      {
        fulfilled: (state, action: PayloadAction<ListOpportunityResponse, string, MetaArg<ListOpportunityRequest>>) => {
          if (
            (action as any).meta.arg?.pg <= 1 ||
            !(action as any).meta.arg?.pg ||
            action.payload?.response?.opportunities_data?.length === 0
          ) {
            state.list = action.payload;
          } else {
            state.list!.hasMore = action.payload.hasMore;
            state.list!.response.opportunities_data.push(...action.payload.response.opportunities_data);
            state.list!.total = action.payload.total;
            state.list!.currentPage = action.meta.arg.pg;

            /* state.list = {
              ...state.list,
              ...action.payload,
              response: {
                opportunities_data: [
                  ...(state.list?.response.opportunities_data || []),
                  ...(action.payload?.response.opportunities_data || []),
                ],
              },
            }; */
          }
        },
      },
    ]);
    decoreThunk(builder, listOpportunitiesByProject, [
      requestLoading(),
      {
        pending: (state, action: PayloadAction<ListOpportunitiesByProjectResponse>) => {
          if (!state.listByProject[(action as any).meta.arg.projectSlug]) {
            state.listByProject[(action as any).meta.arg.projectSlug] = {
              loading: "loading",
            };
          } else {
            state.listByProject[(action as any).meta.arg.projectSlug].loading = "loading";
          }
        },
        fulfilled: (state, action: PayloadAction<ListOpportunitiesByProjectResponse>) => {
          state.listByProject[(action as any).meta.arg.projectSlug].loading = "ok";
          state.listByProject[(action as any).meta.arg.projectSlug].list =
            action.payload?.response.opportunities_data || [];
        },
        rejected: (state, action: PayloadAction<ListOpportunitiesByProjectResponse>) => {
          state.listByProject[(action as any).meta.arg.projectSlug || ""].loading = "error";
        },
      },
    ]);
    decoreThunk(builder, listOpportunityMatchmaker, [
      requestLoading(),
      {
        fulfilled: (state, action: PayloadAction<ListOpportunityMatchmakerResponse>) => {
          state.listMatchmaker = action.payload;
        },
      },
    ]);

    decoreThunk(builder, listOpportunityBlog, [
      {
        pending: (state, action: PayloadAction<ListOpportunityResponse>) => {
          if (!state.listBlog[(action as any).meta.arg.postSlug]) {
            state.listBlog[(action as any).meta.arg.postSlug] = {
              loading: "loading",
            };
          } else {
            state.listBlog[(action as any).meta.arg.postSlug].loading = "loading";
          }
        },
        fulfilled: (state, action: PayloadAction<ListOpportunityResponse>) => {
          state.listBlog[(action as any).meta.arg.postSlug] = {
            loading: "ok",
            list: action.payload?.response.opportunities_data || [],
          };
        },
        rejected: (state, action: PayloadAction<ListOpportunityResponse>) => {
          state.listBlog[(action as any).meta.arg.postSlug].loading = "error";
        },
      },
    ]);

    builder.addCase(updateCoordinates.toString(), (state) => {
      state.list = undefined;
    });
  },
});

export default opportunitiesSlice;
