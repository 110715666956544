import api from "core/api";
import asyncThunk from "core/decorators/toolkit";

export const ListAppointmentsByMonth = asyncThunk(
  "panel/beta/listAppointmentsByMonth",
  api.project.listAppointmentsByMonth
);
export const ListAppointmentsByDate = asyncThunk(
  "panel/beta/listAppointmentsByDate",
  api.project.listAppointmentsByDate
);
