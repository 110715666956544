import React from "react";
import styled, { css } from "styled-components/macro";
import Photo from "wizard/v3/components/Photo/Photo";
import { BaseTitle } from "components/common/BaseTitle";
import SupportText from "components/common/SupportText";
import { rem } from "polished";
import { Image } from "utils/types";
import { Circle } from "wizard/v3/components/CardSelectable/style";
import { BorderFocus } from "components/common/style";
import CardIcon, { CardIconType } from "../Card/CardIcon";
import { mq } from "utils/mediaQueries";
import { transition } from "utils/animation";
import Helper from "utils/helper";

interface CardItem {
  title: string;
  subTitle?: string;
  image?: Image;
  icon?: CardIconType;
  isSelectable?: boolean;
  isSelected?: boolean;
  hiddenPhoto?: boolean;
  tag?: string | React.ReactType;
  onClick?: () => void;
}

const CardItemBase: React.FunctionComponent<CardItem> = ({
  title,
  subTitle,
  image,
  icon,
  hiddenPhoto,
  tag: Wrapper = "div",
  children,
  ...props
}) => {
  return (
    <Wrapper {...props}>
      <Flex>
        {!icon && (
          <Photo hiddenSm={hiddenPhoto} size="md">
            {!!image && <img src={Helper.validatingFields(image?.src, true)} alt={image?.alt} />}
          </Photo>
        )}
        {!!icon && <CardIcon hiddenSm={hiddenPhoto} size="md" icon={icon} />}
        <div>
          <Title size="sm">{title}</Title>
          {!!subTitle && <SupportText size="sm">{subTitle}</SupportText>}
        </div>
      </Flex>
      {children}
    </Wrapper>
  );
};

const Flex = styled.div`
  display: flex;
  align-items: center;

  div:first-child {
    margin: 0;
    margin-right: 30px;
  }
`;

const CardItem = styled(({ isSelectable, isSelected, ...props }) => <CardItemBase {...props} />)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #cdd6e2;
  border-radius: 6px;
  background-color: #ffffff;
  min-height: 80px;
  min-width: 60%;
  margin: 10px 0;
  padding: 15px;
  transition: ${transition.fast("box-shadow")};

  > :last-child {
    padding-left: 20px;
  }

  &:hover {
    box-shadow: 0 10px 20px 0 rgba(13, 29, 51, 0.07);
  }

  ${({ isSelectable }) =>
    isSelectable &&
    css`
      padding-right: 60px;
      cursor: pointer;

      :before {
        ${Circle};
        top: 0;
        bottom: 0;
        margin: auto;
      }
      :after {
        ${Circle};
        top: 0;
        bottom: 3px;
        right: 27px;
        margin: auto;
        border: none;
      }
    `}

  ${({ isSelectable, isSelected, theme }) =>
    isSelectable &&
    isSelected &&
    css`
      ${BorderFocus};
      :before {
        background-color: ${theme.colors.primary};
      }
      :after {
        height: 8px;
        width: 6px;
        border-style: solid;
        border-color: #fff;
        border-width: 0px 2px 2px 0px;
        transform: rotate(45deg);
      }
    `}

    ${({ isSelectable, isSelected }) =>
    isSelectable &&
    !isSelected &&
    css`
      transition: 0.4s;
      :focus,
      :hover {
        ${BorderFocus};
      }
    `}

      ${mq.xsDown} {
    flex-direction: column;
    align-items: flex-end;
    ${Flex} {
      align-self: flex-start;
    }
  }
`;

export default CardItem;

const Title = styled(BaseTitle)`
  line-height: ${rem(14)};
`;
