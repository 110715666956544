import { AxiosResponse } from "axios";
import http from "config/http";
import { ResponseWithParams, ResponseStatus } from "core/api/definitions";
import { SubscribeDataShort } from "core/api/definitions";
import { UserDataFull } from "core/api/definitions";

interface GetVolunteerprofileCommonRequest {
  subscribeSlug: string;
  volunteerId: string;
}

interface GetVolunteerprofileV1Request extends GetVolunteerprofileCommonRequest {
  registration_id: string[];
}

interface GetVolunteerprofileV2Request extends GetVolunteerprofileCommonRequest {
  slot_id: string[];
}

export type GetVolunteerProfileRequest = GetVolunteerprofileV1Request | GetVolunteerprofileV2Request;

export interface VolunteerProfile {
  _id: string;
  registration_data: {
    action_slug: string;
    action_title: string;
    registration_data: {
      day: string;
      slot_id: string;
      time_start: string;
      time_end: string;
      time_in_seconds: number;
    };
    subscribe_data: SubscribeDataShort;
  }[];
  user_data: UserDataFull;
}

export type GetVolunteerProfileResponse = ResponseWithParams<ResponseStatus, VolunteerProfile>;

export default async ({
  subscribeSlug,
  volunteerId,
  ...props
}: GetVolunteerProfileRequest): Promise<AxiosResponse<GetVolunteerProfileResponse>> => {
  return http.post<GetVolunteerProfileResponse>(`/subscribe/${subscribeSlug}/volunteer/${volunteerId}/profile`, props);
};
