import { createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import api from "core/api";
import { ConditionalDescription, LoadingStatus, Login, Meta, ProgramDataShare } from "core/api/definitions";
import {
  GetAccountDetailsRequest,
  GetAccountDetailsResponse,
  GetAccountDetailsResponseDate,
} from "core/api/users/getAccountDetails";
import {
  GetAllPreferecesResponseDate,
  GetAllPreferencesRequest,
  GetAllPreferencesResponse,
} from "core/api/users/getAllPreferences";
import {
  GetGeneralDataRequest,
  GetGeneralDataResponse,
  GetGeneralDataResponseData,
} from "core/api/users/getGeneralData";
import { GetAllPrivacyResponseData } from "core/api/users/privacy/getAllPrivacy";
import { Activity, MyActivitiesRequest, MyActivitiesResponse } from "core/api/users/security/myActivities";
import { UpdateDocumentDataRequest, UpdateDocumentDataResponse } from "core/api/users/updateDocument";
import { UpdateGeneralDataRequest, UpdateGeneralDataResponse } from "core/api/users/updateGeneralData";
import { UpdatePreferencesResponse, UpdateUserPreferencesRequest } from "core/api/users/updatePreferences";
import { loginUpdate, logout } from "core/auth";
import decoreThunk from "core/decorators/decorate";
import requestLoading from "core/decorators/requestLoading";
import asyncThunk from "core/decorators/toolkit";
import { createStoreNewUser } from "core/wizard/create/user";
import {
  abilities as initialInterests,
  Cause,
  causes as initialCauses,
  functions as initialSkills,
  Interest,
  Skill,
} from "pages/ListActions/DataOfFilters";

export const getGeneralData = asyncThunk<GetGeneralDataResponse, GetGeneralDataRequest>(
  "panel/user/settings/general",
  api.users.getGeneralData,
  {
    condition: [(_, state) => !state.panelUserSettings.general],
  }
);

export const updateGeneralData = asyncThunk<UpdateGeneralDataResponse, UpdateGeneralDataRequest>(
  "panel/user/settings/updateGeneral",
  api.users.updateGeneralData
);

export const updateUniqueDocument = asyncThunk<UpdateDocumentDataResponse, UpdateDocumentDataRequest>(
  "panel/user/updateDocument",
  api.users.updateDocument
);

export const getAllPreferences = asyncThunk<GetAllPreferencesResponse, GetAllPreferencesRequest>(
  "panel/user/settings/preferences",
  api.users.getAllPreferences,
  {
    condition: [(_, state) => !state.panelUserSettings.preferences?._id],
  }
);

export const updatePreferences = asyncThunk<UpdatePreferencesResponse, UpdateUserPreferencesRequest>(
  "panel/user/settings/updatePreferences",
  api.users.updatePreferences
);

export const getAllPrivacy = asyncThunk("panel/user/settings/privacy/getAllPrivacy", api.users.privacy.getAllPrivacy, {
  condition: [(_, state) => !state.panelUserSettings.privacy?.length],
});

export const myActivities = asyncThunk<MyActivitiesRequest, MyActivitiesResponse>(
  "panel/user/settings/security/myActivities",
  api.users.security.myActivities
);

export const getAccountDetails = asyncThunk<GetAccountDetailsResponse, GetAccountDetailsRequest>(
  "panel/user/settings/account",
  api.users.getAccountDetails,
  {
    condition: [
      (_, state) => {
        return !state.panelUserSettings.account;
      },
    ],
  }
);

export const updateEmail = asyncThunk("panel/user/settings/general/updateEmail", api.users.general.updateEmail);

interface IState {
  loading: LoadingStatus;
  general?: GetGeneralDataResponseData;
  preferences?: GetAllPreferecesResponseDate;
  privacy: Partial<GetAllPrivacyResponseData>[];
  security: {
    activities?: Activity[];
  };
  account?: GetAccountDetailsResponseDate;
}

const settingsSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "panelUserSettings",
  initialState: {
    loading: "idle",
    preferences: {},
    privacy: [],
    security: {},
  },
  reducers: {
    updatePrivacy(state, action: PayloadAction<Partial<GetAllPrivacyResponseData>>) {
      const privacy = state.privacy.find((item) => item._id === action.payload._id);
      if (!privacy) return;

      const { intern_search_data } = action.payload;
      const privacyData = state.privacy.filter((item) => item._id !== action.payload._id);
      state.privacy = [...privacyData, { ...privacy, intern_search_data }];
    },
    updatePrivacyProgram(state, action: PayloadAction<{ _id: string; program_data_share: ProgramDataShare }>) {
      const { program_data_share } = action.payload || { program_data_share: [] };

      const privacy = state.privacy.find((item) => item._id === action.payload._id);
      const privacyData = state.privacy.filter((item) => item._id !== action.payload._id);
      if (!privacy) return;

      const programs = privacy.program_data_share?.filter((p) => p._id !== program_data_share._id);
      const program = privacy.program_data_share?.find((p) => p._id === program_data_share._id);
      if (!program) return;

      state.privacy = [
        ...privacyData,
        {
          ...privacy,
          program_data_share: [...(programs || []), { ...program, ...program_data_share }],
        },
      ];
    },
    updatePreferecensAction(state, action: PayloadAction<UpdateUserPreferencesRequest>) {
      state.preferences!._id = action.payload.id;

      state.preferences!.use_geolocation = action.payload.use_geolocation;

      state.preferences!.conditionals!.causes = initialCauses
        .filter((c) => action.payload.conditionals?.causes?.includes(c.value))
        .map((c) => ({
          description: c.label,
          slug: c.value,
        })) as ConditionalDescription<Cause>[];

      state.preferences!.conditionals!.interests = initialInterests
        .filter((c) => action.payload.conditionals?.interests?.includes(c.value))
        .map((c) => ({
          description: c.label,
          slug: c.value,
        })) as ConditionalDescription<Interest>[];

      state.preferences!.conditionals!.skills = initialSkills
        .filter((c) => action.payload.conditionals?.skills?.includes(c.value))
        .map((c) => ({
          description: c.label,
          slug: c.value,
        })) as ConditionalDescription<Skill>[];
    },
    setExportDataScheduled(state, action: PayloadAction<boolean>) {
      state.account!.exportDataScheduled = "pending";
    },
  },
  extraReducers: (builder) => {
    decoreThunk(builder, getGeneralData, [
      requestLoading(),
      {
        fulfilled: (state, action) => {
          state.general = action.payload.params;
        },
      },
    ]);
    decoreThunk(builder, getAllPreferences, [
      requestLoading(),
      {
        fulfilled: (state, action) => {
          const params = action?.payload?.params;

          state.preferences!._id = params?._id;
          state.preferences!.use_geolocation = params?.use_geolocation;
          state.preferences!.conditionals = params?.conditionals;
        },
      },
    ]);
    decoreThunk(builder, getAllPrivacy, [
      requestLoading(),
      {
        fulfilled: (state, action) => {
          state.privacy = action.payload.params;
        },
      },
    ]);
    decoreThunk(builder, myActivities, [
      requestLoading(),
      {
        fulfilled: (state, action: PayloadAction<MyActivitiesResponse>) => {
          state.security.activities = Array.isArray(action.payload?.response) ? action.payload?.response : [];
        },
      },
    ]);
    decoreThunk(builder, getAccountDetails, [
      requestLoading(),
      {
        fulfilled: (state, action) => {
          state!.account = action?.payload?.params;
        },
      },
    ]);
    decoreThunk(builder, updateGeneralData, [
      {
        fulfilled: (state, action: PayloadAction<UpdateGeneralDataResponse> & Meta<UpdateGeneralDataRequest>) => {
          if (!state.general) {
            return;
          }

          if (action.meta.arg.name) state.general!.name = action.meta.arg.name;

          if (action.meta.arg.locations) state.general!.locations = action.meta.arg.locations;

          if (action.meta.arg.contacts) state.general!.contacts = action.meta.arg.contacts;

          if (action.meta.arg.unique_document) state.general!.unique_document = action.meta.arg.unique_document;

          if (action.meta.arg.profile_image) state.general!.profile_image = action.meta.arg.profile_image;
        },
      },
    ]);
    builder.addCase(createStoreNewUser.fulfilled.toString(), (state) => {
      if (state?.general) state.general!.profile_image = undefined;
    });
    builder.addCase(loginUpdate.toString(), (state, action: PayloadAction<Partial<Login>>) => {
      if (action.payload?.user_picture && state.general) state.general!.profile_image = action.payload?.user_picture;
    });

    builder.addCase(logout.toString(), (state) => {
      state.general = undefined;
    });
  },
});

export default settingsSlice;

export const {
  updatePreferecensAction,
  updatePrivacy,
  updatePrivacyProgram,
  updateGeneralAction,
  setExportDataScheduled,
} = settingsSlice.actions;
