import Loading from "components/Loading/Loading";
import { modalOpenDonation, modalOpenVacancy } from "core/pages/actions";
import useAnimation from "hooks/useAnimation";
import useTheme from "hooks/useTheme";
import useTranslationV3 from "hooks/useTranslationV3";
import { useUser } from "hooks/useUser";
import React, { useEffect, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import routes from "routes";
import styled, { css } from "styled-components";
import { transition } from "utils/animation";
import mqV3 from "utils/mediaQueriesV3";
import { ArrowButtons } from "v3/components/ArrowButons/ArrowButtons";
import Button from "v3/components/Button/Button";
import OpportunityCard from "v3/components/Cards/OpportunityCardV2";
import Text from "v3/components/common/Text";
import Container from "v3/components/Grid/Container";
import { OpportunityCardInterface } from "../Cards/OpportunityCard";

const DEFAULT_WIDTHS = {
  md: 314,
  sm: 276,
  xs: 312,
};

interface MatchmakerPreferencesOpportunitiesSlideProps {
  listItems: OpportunityCardInterface[];
}

const MatchmakerPreferencesOpportunitiesSlide = ({ listItems }: MatchmakerPreferencesOpportunitiesSlideProps) => {
  const opportunitiesListRef = useRef<HTMLUListElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);
  const { user } = useUser();

  const dispatch = useDispatch();

  const { t } = useTranslationV3();

  const {
    backDisabled,
    forwardDisabled,
    handleArrowsBack,
    handleArrowsForward,
    displayTotal,
    currentPage,
  } = useAnimation({
    parentRef,
    wrapperListRef: opportunitiesListRef,
    itemsCount: listItems?.length ?? 0,
    gaps: {
      md: 30,
      sm: 30,
      xs: 30,
    },
    widths: DEFAULT_WIDTHS,
  });

  return (
    <ContainerStyled ref={parentRef}>
      <Flex>
        <Salutation>
          <Text tag="h2" size="xl" color="neutralWhite">
            {t("plain:Olá, x", { name: user?.user_name })}
          </Text>
          <Text tag="p" size="sm" color="neutralWhite">
            {t("plain:Selecionamos X oportunidades que tem a ver com você", { number: listItems.length })}
          </Text>
        </Salutation>
        <CustomArrowButtons
          backDisabled={backDisabled}
          forwardDisabled={forwardDisabled}
          onBack={handleArrowsBack}
          onForward={handleArrowsForward}
          color="neutralWhite"
        />
      </Flex>

      <List ref={opportunitiesListRef}>
        {listItems?.map((opportunity, k) => (
          <OpportunityCardStyled
            key={k}
            tagContainer="li"
            img={opportunity.imgOpportunity ?? opportunity.imgAction}
            {...opportunity}
            to=""
            onContainerClick={() => {
              if (opportunity.type === "volunteer") dispatch(modalOpenVacancy({ slug: opportunity.vacancySlug }));
              if (opportunity.type === "material")
                dispatch(modalOpenDonation({ actionSlug: opportunity.actionSlug, donationID: opportunity.donationID }));
            }}
          />
        ))}
      </List>

      <Flex>
        <Button tag={Link} to={routes.panel.settings.preferences} color="outline-white">
          {t("plain:Editar Preferências")}
        </Button>
        <Text tag="span" size="sm" color="neutralWhite">
          {t("plain:X de X", { start: ((currentPage ?? 0) + 1) * displayTotal, end: listItems.length })}
        </Text>
      </Flex>
    </ContainerStyled>
  );
};

export default MatchmakerPreferencesOpportunitiesSlide;

const List = styled.ul`
  padding: 0;
  list-style: none;
  position: relative;
  display: flex;
  margin: 0 -15px;
  transition: ${transition.slow("left")};
  min-height: 365px;
`;

const OpportunityCardStyled = styled(OpportunityCard)`
  opacity: 0;
  background-color: #fff;
`;

const Salutation = styled.div(
  ({ theme }) => css`
    margin-bottom: ${theme.v3.spacing.xl};

    h2 {
      margin-bottom: ${theme.v3.spacing.xs};
    }
  `
);

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CustomArrowButtons = styled(ArrowButtons)`
  ${mqV3.xs} {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 275px;
    left: -30px;
    right: -30px;
  }
`;

const ContainerStyled = styled(Container)`
  ${OpportunityCard} {
    min-width: ${DEFAULT_WIDTHS.md}px;
    ${mqV3.sm} {
      min-width: ${DEFAULT_WIDTHS.sm}px;
    }

    ${mqV3.xs} {
      min-width: ${DEFAULT_WIDTHS.xs}px;
    }
  }

  ${mqV3.xs} {
    position: relative;

    ${Text} {
      text-align: center;
    }

    ${Flex}:last-child {
      flex-direction: column-reverse;

      ${Text} {
        margin-bottom: ${({ theme }) => theme.v3.spacing.md};
      }
    }
    max-width: ${DEFAULT_WIDTHS.xs}px;
    padding-left: 0;
    padding-right: 0;
  }
`;
