import { createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import { RequestableState } from "core/api/definitions";
import decoreThunk from "core/decorators/decorate";

import { getlistProjectVolunteerAppointmentsByProject } from "./action";

import requestLoading from "core/decorators/requestLoading";
import { ListAppointment } from "core/api/users/listVolunteersNextAppointments";
import { logout } from "core/auth";
import {
  ListAppointmentResponse,
  ListVolunteerAppointmentResponse,
} from "core/api/projects/listProjectVolunteerAppointmentsByProject";

interface IState extends RequestableState, ListAppointmentResponse {}

const panelBetaListDetailProjectVolunteersAppointmentModal = createSlice<IState, SliceCaseReducers<IState>>({
  name: "panelBetaListDetailProjectVolunteersAppointmentModal",
  initialState: {
    loading: "idle",
    volunteer: {
      general: {
        _id: "",
        name: "",
        email: "",
        profile_image: "",
        locations: {},
        primary: "",
        phone: {
          _id: "",
          type: "phone",
          description: "",
          created_at: "",
          country: "",
          value: "",
        },
      },
      preferences: {
        _id: "",
        conditionals: {
          causes: [],
          functions: [],
          interests: [],
          skills: [],
        },
      },
      counters: {
        rating: 0,
        actions_count: 0,
        evaluation_rating_total: 0,
        subscribe_count_total: 0,
        volunteer_count: 0,
      },
    },
    subscribe_registration: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, getlistProjectVolunteerAppointmentsByProject, [
      requestLoading(),
      {
        pending: (state: IState) => {
          state.loading = "loading";
        },
        fulfilled: (state: IState, action: PayloadAction<ListVolunteerAppointmentResponse>) => {
          state.loading = "ok";
          if (action?.payload?.params?.volunteer) {
            state.volunteer = action.payload.params.volunteer;
          }
          if (action?.payload.params?.subscribe_registration) {
            state.subscribe_registration = action.payload.params.subscribe_registration;
          }
        },
        rejected: (state: IState) => {
          state.loading = "error";
        },
      },
    ]);
    builder.addCase(logout.toString(), (state) => {
      state.loading = "idle";
      //state.opportunities_data = {};
    });
  },
});

export default panelBetaListDetailProjectVolunteersAppointmentModal;
