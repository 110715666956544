const SELECT_TEAM = "SELECT_TEAM";
const SUMMARY = "SUMMARY";
const MEMBER = "MEMBER";
const LOGIN = "LOGIN";
const REGISTER = "REGISTER";
const POPUP = "POPUP";

export default {
  SELECT_TEAM,
  SUMMARY,
  MEMBER,
  LOGIN,
  REGISTER,
  POPUP,
};
