import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit";
import api from "core/api";
import { RequestableState } from "core/api/definitions";
import { ErrorResponse } from "core/api/definitions";
import decoreThunk from "core/decorators/decorate";
import requestLoading from "core/decorators/requestLoading";
import { socialCurriculumResponse, socialCurriculumRequest } from "core/api/users/socialCurriculum";
import asyncThunk from "core/decorators/toolkit";
import { SocialCurriculum } from "core/api/definitions";
import { getValidateCurriculum } from "./validate";

type IState = RequestableState & GetSocialCurriculumResponse;

export type GetSocialCurriculumResponse = socialCurriculumResponse | ErrorResponse;

export const getSocialCurriculum = asyncThunk<GetSocialCurriculumResponse, socialCurriculumRequest>(
  "panel/curriculum/list",
  api.users.socialCurriculum,
  {
    condition: [
      (_, thunkAPI) => {
        const params = thunkAPI.panelSocialCurriculum.params as SocialCurriculum;
        return (
          !params?.curriculum_data ||
          params?.curriculum_data?.length === 0 ||
          !params?.user_data ||
          !params?.qrcode_image ||
          !params?.code
        );
      },
    ],
  }
);

const panelSocialCurriculumSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "panelSocialCurriculum",
  initialState: {
    loading: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, getSocialCurriculum, [
      requestLoading(),
      {
        fulfilled: (state, action) => {
          state.params = action.payload?.params;
          state.success = action.payload?.success;
          state.response = action.payload?.response;
        },
        rejected: (state, action) => {
          state.response = action.payload?.response;
          state.success = action.payload?.success;
        },
      },
    ]);
    decoreThunk(builder, getValidateCurriculum, [
      requestLoading(),
      {
        fulfilled: (state, action) => {
          state.params = {
            ...action.payload?.params,
            user_data: {
              ...(action.payload?.params?.user_data || {}),
              member_since: action.payload?.params?.user_data?.member_since?.date || "",
            },
          };
          state.success = action.payload?.success;
          state.response = action.payload?.response;
        },
        rejected: (state, action) => {
          state.response = action.payload?.response;
          state.success = action.payload?.success;
        },
      },
    ]);
  },
});

export default panelSocialCurriculumSlice;
