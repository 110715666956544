import { createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import api from "core/api";
import { BlogPost, LoadingStatus, RequestableState } from "core/api/definitions";
import { decoreThunk2 } from "core/decorators/decorate";
import requestLoading from "core/decorators/requestLoading";
import asyncThunk from "core/decorators/toolkit";

export const blogPostItem = asyncThunk("blog/posts/item", api.blog.post, {
  condition: [({ slug, post_id }, state) => !state.blog.post.items.list[post_id || slug || ""]],
});

export const blogPostLike = asyncThunk("blog/posts/like", api.blog.likePost);

type IState = {
  items: {
    loading: LoadingStatus;
    list: { [k: string]: BlogPost & { likeLoading: LoadingStatus } };
  };
} & RequestableState;

const postsSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "blogPosts",
  initialState: {
    loading: "idle",
    items: {
      loading: "idle",
      list: {},
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk2(builder, blogPostItem, [
      requestLoading(),
      {
        pending: (state, _) => {
          state.items.loading = "loading";
        },
        fulfilled: (state, action) => {
          const { slug, post_id } = action.meta?.arg;
          const payload = action.payload?.data;

          state.items.list![slug || post_id || ""] = payload;

          state.items.loading = "ok";
        },
        rejected: (state, _) => {
          state.items.loading = "error";
        },
      },
    ]);
    decoreThunk2(builder, blogPostLike, [
      requestLoading(),
      {
        pending: (state, action) => {
          const { slug } = action.meta?.arg;

          state.items.list![slug].likeLoading = "loading";
        },
        fulfilled: (state, action) => {
          const { slug } = action.meta?.arg;
          const payload = action.payload?.data;

          state.items.list![slug].likes = payload?.likes || state.items.list![slug].likes + 1;
          state.items.list![slug].likeLoading = "ok";
        },
        rejected: (state, action) => {
          const { slug } = action.meta?.arg;

          state.items.list![slug].likeLoading = "error";
        },
      },
    ]);
  },
});

export default postsSlice;
