import React, { useCallback, useState } from "react";
import Base from "wizard/components/Layout/Base";
import Header, { HeaderTitle, HeaderProps } from "wizard/components/Layout/Header";
import styled from "styled-components/macro";
import { ContentContainer } from "./Content";
import Icon from "v3/components/Icons/Icon";
import routes from "routes";
import { transition } from "utils/animation";
import { transparentize } from "polished";
import ModalConfirmation from "panel/components/Modal/ModalConfirmation";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Img from "v3/components/common/Img";
import { useReduxSelector } from "hooks/useReduxSelector";
import { Origin } from "core/api/definitions";
import { mq } from "utils/mediaQueries";

interface Header extends HeaderProps {
  title: string;
}

interface BodyProps {
  header: Header;
}

const Wizard: React.FunctionComponent<BodyProps> = ({ header, children }) => {
  const { t } = useTranslation("components");
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);

  const { logo, title } = useReduxSelector((state) => ({
    logo: (state.pagesNew?.origin?.params as Origin)?.logoIcon,
    title: (state.pagesNew?.origin?.params as Origin)?.title,
  }));

  const onExitCallback = useCallback(() => setIsOpen(true), []);

  return (
    <Base>
      <ModalConfirmation
        isOpen={isOpen}
        title={t("modalConfirmationWizardFlow.title")}
        message={t("modalConfirmationWizardFlow.message")}
        color="quaternary"
        onCancel={() => setIsOpen(false)}
        onConfirm={() => {
          setIsOpen(false);
          history.push(routes.home);
        }}
      />
      <Header stepMax={header.stepMax} stepCurrent={header.stepCurrent}>
        <LeftContainer>
          <Logo onClick={onExitCallback}>
            <Img src={logo} alt={title} />
          </Logo>
          <HeaderTitle tag="h1" size="md" color="neutralBase">
            {header.title}
          </HeaderTitle>
        </LeftContainer>
      </Header>
      <ButtonClose onClick={onExitCallback}>
        <Icon icon="close" />
      </ButtonClose>
      <Container>{children}</Container>
    </Base>
  );
};

export default Wizard;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const WizardContainer: React.FC<{ className?: string }> = ({ children, ...props }) => (
  <WizardContainerWrapper {...props}>
    <ContentContainer>{children}</ContentContainer>
  </WizardContainerWrapper>
);

const WizardContainerWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 30px 0;
`;

const ButtonClose = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 35px;
  height: 35px;
  border: 0;
  right: 10px;
  top: 20px;
  border-radius: 50%;
  background-color: transparent;
  ${transition.normal("transition transform")};

  :hover {
    transform: rotate(90deg);
  }
`;

const Logo = styled.div`
  max-width: 42px;
  height: 48px;
  padding: 3px;
  cursor: pointer;

  img {
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  ${mq.xsDown} {
    position: absolute;
    left: 0;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;

  ${Logo} {
    margin-right: ${({ theme }) => theme.v3.spacing.xl};
  }
`;
