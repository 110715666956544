import styled from "styled-components";
import Link from "v3/components/Link/Link";
import { default as BaseFormGroup } from "../../Form/FormGroup";

export const Info = styled.div<{ marginBottom?: "md" }>`
  text-align: center;
  margin-bottom: ${({ theme, marginBottom }) => theme.v3.spacing[marginBottom || "xl"]};

  & > h1 {
    margin: 0;
    font-weight: 400;
  }
  & > p {
    padding-top: ${({ theme }) => theme.v3.spacing.sm};
    margin: 0;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const FormGroup = styled(BaseFormGroup)`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.v3.spacing.md};
`;

export const CustomLink = styled(Link)`
  padding: 0;
`;
