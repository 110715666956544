import http from "config/http";
import { ResponseStatus, ResponseWithParams } from "../definitions";
import { AxiosResponse } from "axios";

export interface StartNewChatRequest {
  receiver: string;
  project_id: string;
  panel: string;
}

export type StartNewChatResponse = ResponseWithParams<ResponseStatus, { chat_id: string }>;

const StartNewChat = async (data: StartNewChatRequest): Promise<AxiosResponse<StartNewChatResponse>> => {
  return http.post<StartNewChatResponse>(`/chat/start-chat`, data);
};

export default StartNewChat;
