import http from "config/http";
import { ResponseWithParams, ResponseStatus } from "../definitions";
import { SocialCurriculum } from "core/api/definitions";
import { AxiosResponse } from "axios";
import { UniqueDocument } from "./getGeneralData";

export interface socialCurriculumRequest {
  unique_document?: UniqueDocument;
}

export type socialCurriculumResponse = ResponseWithParams<ResponseStatus, SocialCurriculum[]>;

const socialCurriculum = async ({
  unique_document,
}: socialCurriculumRequest): Promise<AxiosResponse<socialCurriculumResponse>> => {
  return http.get<socialCurriculumResponse>(
    `/v3/users/socialCurriculum?unique_document[type]=${unique_document?.type || ""}&unique_document[value]=${
      unique_document?.value || ""
    }`
  );
};

export default socialCurriculum;
