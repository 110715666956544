import styled from "styled-components/macro";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc(100vh - 150px);
  padding: 0 15px;
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 80px;
  border-top: 1px solid #e2e6f4;

  img {
    height: 40.47px;
    width: 120px;
  }
`;
