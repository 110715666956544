import urlMiddleware from "./middlewares/urlMiddleware";
import handleDashProfileChange from "./middlewares/handleDashProfileChange";
import { getDefaultMiddleware } from "@reduxjs/toolkit";

import action from "./actions/reducer";
import contact from "./contact/reducer";
// Reducers
import pages from "./pages/reducer";
import program from "./programs/reducer";
import project from "./project/reducer";
import search from "./search/reducer";
import subscribe from "./subscribe/reducer";
import user from "./user/reducer";
import utils from "./utils/reducer";
import teams from "./teams";

import panelValidationProjectListSlice from "./panel/validation";
import listProgram from "./programs/service";
import pagesReducer from "./pages";
import initOrigin from "./middlewares/initOrigin";
import panelCertificatesSlice from "./panel/certificates/list";
import panelSocialCurriculumSlice from "./panel/socialCurriculum/get";
import wizardReducer from "./wizard";
import handleLogin from "./middlewares/handleLogin";
import handleCookie from "./middlewares/handleCookie";
import loginSlice from "./auth";
import panelValidationActionListSlice from "./panel/validation/actions";
import newFlowCreateHandle from "./middlewares/newFlowCreateHandle";
import supportDataSlice from "./supportData";
import panelDonors from "./panel/donors";
import settingsSlice from "./panel/user/settings";
import panelReducer from "./panel";
import googleMaps from "./middlewares/googleMaps";
import userWay from "./middlewares/userWay";
import handTalk from "./middlewares/handtalk";
import subscribeSlice from "./subscribe";
import geolocationSlice from "./geolocation";
import blogReducer from "./blog";
import panelBetaReducer from "./panel/beta";
import alertsSlice from "./alerts";

const store = {
  reducer: {
    pagesNew: pagesReducer,
    subscribeNew: subscribeSlice.reducer,
    wizard: wizardReducer,
    login: loginSlice.reducer,
    supportData: supportDataSlice.reducer,
    geolocation: geolocationSlice.reducer,

    // Old reducers
    pages,
    user,
    project,
    utils,
    action,
    subscribe,
    search,
    program,
    contact,
    teams,

    // Panel
    listProgram: listProgram.reducer,
    panelValidationProjectList: panelValidationProjectListSlice.reducer,
    panelValidationActionList: panelValidationActionListSlice.reducer,
    panelCertificates: panelCertificatesSlice.reducer,
    panelSocialCurriculum: panelSocialCurriculumSlice.reducer,
    panelDonors: panelDonors.reducer,
    panelUserSettings: settingsSlice.reducer,

    // New strucuture
    panel: panelReducer,
    alerts: alertsSlice.reducer,

    panelBeta: panelBetaReducer,

    // Blog
    blog: blogReducer,
  },
  middleware: getDefaultMiddleware().concat(
    urlMiddleware,
    handleCookie,
    handleDashProfileChange,
    initOrigin,
    newFlowCreateHandle,
    handleLogin,
    googleMaps,
    userWay,
    handTalk
  ),
};

export default store;
