import React from "react";
import styled, { css } from "styled-components/macro";
import Icon from "../Icons/Icon";
import { IconType } from "icons";

type ODSIcons = Extract<
  IconType,
  | "ODS01"
  | "ODS02"
  | "ODS03"
  | "ODS04"
  | "ODS05"
  | "ODS06"
  | "ODS07"
  | "ODS08"
  | "ODS09"
  | "ODS10"
  | "ODS11"
  | "ODS12"
  | "ODS13"
  | "ODS14"
  | "ODS15"
  | "ODS16"
  | "ODS17"
  | "ODSONU"
>;

const odsSizes = {
  sm: 32,
  md: 48,
  lg: 64,
};

type ODSIconSize = keyof typeof odsSizes;

interface ODSIconProps {
  icon: ODSIcons;
  size?: ODSIconSize;
  tag?: React.ElementType;
  className?: string;
}

const odsColors: {
  [key in ODSIcons]: string;
} = {
  ODS01: "#D32B3B",
  ODS02: "#D4A03D",
  ODS03: "#4c9e38",
  ODS04: "#c51a2d",
  ODS05: "#ff3a20",
  ODS06: "#27bde2",
  ODS07: "#fdc30a",
  ODS08: "#a21a42",
  ODS09: "#fe6925",
  ODS10: "#de1768",
  ODS11: "#fd9d24",
  ODS12: "#c08b2f",
  ODS13: "#3f7e45",
  ODS14: "#0997d9",
  ODS15: "#56c02a",
  ODS16: "#00689d",
  ODS17: "#1a486a",
  ODSONU: "#ffffff",
};

const ODSIconComp = ({ icon, className, tag: Tag = "div" }: ODSIconProps) => {
  return (
    <Tag className={className}>
      <Icon icon={icon} />
    </Tag>
  );
};

const ODSIcon = styled(ODSIconComp)(
  ({ icon, size = "sm" }) => css`
    width: 48px;
    height: 48px;
    border-radius: 8px;
    background: ${odsColors[icon]};

    &:hover {
      box-shadow: 4px 4px 3px #c4c4c4;
      transition: box-shadow 0.1s ease-in-out;
    }
  `
);

export default ODSIcon;
