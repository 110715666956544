import { AxiosResponse } from "axios";
import http from "config/http";
import { Response, ResponseStatus } from "core/api/definitions";

export type ValidateSelectedVolunteerRequest = {
  status: "approved" | "reject";
  registration_id: string[];
};

export type ValidateVolunteerResponse = Response<ResponseStatus>;

export default async ({
  registration_id,
  status,
}: ValidateSelectedVolunteerRequest): Promise<AxiosResponse<ValidateVolunteerResponse>> => {
  return http.post<ValidateVolunteerResponse>(`/volunteer/multiple-validation`, {
    registration_id,
    status,
  });
};
