import http from "config/http";
import { ResponseWithParams, ResponseStatus } from "../definitions";
import { ValidateCurriculum } from "core/api/definitions";
import { AxiosResponse } from "axios";

export type ValidateCurriculumRequest = string;

export type ValidateCurriculumResponse = ResponseWithParams<ResponseStatus, ValidateCurriculum>;

const validateCurriculum = async (
  code: ValidateCurriculumRequest
): Promise<AxiosResponse<ValidateCurriculumResponse>> => {
  return http.get<ValidateCurriculumResponse>(`/validation/curriculum/${code}`);
};

export default validateCurriculum;
