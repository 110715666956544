import asyncThunk from "core/decorators/toolkit";
import { ValidateCurriculum } from "core/api/definitions";
import { ValidateCurriculumRequest } from "core/api/users/validateCurriculum";
import { ErrorResponse } from "core/api/definitions";
import api from "core/api";

export type GetValidateCurriculumResponse = ValidateCurriculum | ErrorResponse;

export const getValidateCurriculum = asyncThunk<GetValidateCurriculumResponse, ValidateCurriculumRequest>(
  "panel/curriculum/validate",
  api.users.validateCurriculum
);
