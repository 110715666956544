import React, { useState, useCallback } from "react";
import CardIcon, { CardIconType } from "./CardIcon";
import Card, { CardProps } from ".";
import styled from "styled-components/macro";
import { Size } from "utils/types";
import { Tooltip } from "reactstrap";
import { Placement } from "popper.js";
import Text from "v3/components/common/Text";
import { transition } from "utils/animation";

export interface CardItemProps extends CardProps {
  icon?: CardIconType;
  title: string;
  content?: string;
  size?: Size;
  onDisabledHoverTitle?: string;
  id?: string;
  placement?: Placement;
  hint?: string;
}

const CardItem = ({
  id,
  hint,
  icon,
  title,
  content,
  placement,
  size,
  selected,
  onDisabledHoverTitle,
  ...props
}: CardItemProps): JSX.Element => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const tooltipToggle = useCallback(() => setIsTooltipOpen((prev) => !prev), []);

  return (
    <>
      <CustomCard id={id} selected={selected} {...props} title={onDisabledHoverTitle}>
        <CardIcon invertedColor={selected} icon={icon} />
        <CustomBaseTitle tag="strong" size={size || "sm"}>
          {title}
        </CustomBaseTitle>
        <Text tag="span" size="sm" color="neutralLight">
          {content}
        </Text>
      </CustomCard>
      {id && hint && (
        <Tooltip isOpen={isTooltipOpen} toggle={tooltipToggle} placement={placement || "auto"} target={id}>
          {hint}
        </Tooltip>
      )}
    </>
  );
};

export default CardItem;

const CustomCard = styled(Card)`
  flex-direction: column;

  & > span {
    text-align: center;
  }
`;

const CustomBaseTitle = styled(Text)`
  margin-bottom: ${({ theme }) => theme.v3.spacing.xs};
  transition: ${transition.normal("color")};

  .card-selected & {
    color: ${({ theme: { v3 } }) => v3.colors.secondary};
  }
`;
