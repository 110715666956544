import http from "config/http";
import { ResponseWithParams, ResponseStatus } from "../definitions";
import queryString from "query-string";
import { AxiosResponse } from "axios";

interface getListAppointmentsByMonthInterface {
  year: string;
  month: string;
}

export interface AppointmentsByMonth {
  appointments: {
    _id: string;
    registrations: {
      registration_data: {
        day: string;
        status: string;
        slot_id: string;
        _id: string;
        time_end: string;
        time_start: string;
      };
      subscribe_data: {
        _id: string;
        action_id: string;
        slug: string;
        title: string;
        time_accept_remote: boolean;
      };
      user_data: {
        _id: string;
        name: string;
        email: string;
        thumbnail: string;
      };
      action_data: {
        _id: string;
        slug: string;
        title: string;
      };
    }[];
  }[];
  all_appointments_of_month: {
    _id: string;
    day: string;
  }[];
}

export type getListAppointmentsByMonthReponse = ResponseWithParams<ResponseStatus, AppointmentsByMonth>;

export default async ({
  slugProject,
  args,
}: {
  slugProject: string;
  args: getListAppointmentsByMonthInterface;
}): Promise<AxiosResponse<getListAppointmentsByMonthReponse>> => {
  const qs = queryString.stringify(args);
  return await http.get(`/projects/${slugProject}/next-appointments-by-month?${qs}`);
};
