// Language init
import { merge } from "lodash";
import { DEFAULT_THEME } from "utils/constants";
import { axiosDefaultError } from "../../utils/helper";
import createReducer from "../createReducer";
import pageTypes from "./types";

const INITIAL_STATE = {
  home: {
    highlight_text: "",
    causes_section: [],
    featured_actions: {},
    highlight_actions: [],
    sponsored_by: {
      image_cover: "",
      url: "",
    },
    subscribes_section: {
      opened_count: 0,
      subscribes: [],
    },
    volunteer_section: {
      count: 0,
      subscribes_opened: 0,
      actions_performed: 0,
      title: "",
      description: "",
    },
    program_data: [],
    loading: true,
  },
  odsDetails: {
    loading: true,
    ods_data: {
      reference: "",
      description: "",
      slug: "",
      page_title: "",
      page_description: "",
    },
    causes_data: [],
    actions_data: [],
    subscribe_data: [],
  },
  odsMain: {
    title1: "",
    title2: "",
    title3: "",
    description: "",
    image_cover: "",
    ods_data: [],
  },
  origin: {
    language: "",
    loading: true,
    timezone: "",
    title: "TransformaBrasil",
    logo: "https://via.placeholder.com/170x60.png",
    logoIcon: "https://via.placeholder.com/50x50.png",
  },
  about: {
    loading: true,
    title: "",
    description: "",
    image_cover: "",
    body: {
      text: "",
      subtitle: "",
      images: [],
    },
    volunteer_section: {
      count: 0,
      subscribes_opened: 0,
      actions_performed: 0,
    },
  },
  actionPage: {
    loading: true,
    action_data: {
      title: "",
      description: "",
      images: {
        logo: "",
        cover: "",
        gallery: [],
      },
      causes: [],
      address: {
        district: "",
        state: "",
      },
      ods_data: [],
      created_at: "",
    },
    subscribe_data: [],
    project_data: [],
  },
  userPage: {
    loading: true,
    project_data: {
      name: "",
      resume: "",
      images: {
        cover: "",
      },
      conditionals: {
        causes: [],
        interests: [],
        skills: [],
      },
      created_at: 0,
      locations: {
        city: "",
        state: "",
      },
    },
    action_data: [],
    user_data: {
      counters: [],
      conditionals: {
        functions: [],
        causes: [],
      },
    },
    ods_data: [],
  },
  programPage: {
    loading: true,
    program_data: {
      name: "",
      images: {
        logo: "",
        cover: "",
      },
      url_site: "",
      conditionals: {
        causes: [],
        objectives: [],
      },
    },
    ods_data: [],
    action_data: {
      total: 0,
      response: [],
    },
    subscribe_data: {
      total: 0,
      response: [],
    },
    volunteer_count: 0,
    volunteer_ranking: [],
  },
  listProjectPage: {},
  gdo: {
    laoding: true,
    general_count: {
      total_projects: 1,
      total_projects_percentage: 1,
      total_projects_active: 1,
      total_projects_active_percentage: 1,
      total_actions: 1,
      total_actions_percentage: 1,
      total_actions_opened: 1,
      total_actions_active_percentage: 1,
      total_subscribes: 1,
      total_subscribes_percentage: 1,
      total_subscribes_opened: 1,
      total_subscribes_active_percentage: 1,
      total_volunteers_active: 1,
      total_volunteers_active_percentage: 1,
      total_volunteer_hours: 1,
      total_volunteer_hours_percentage: 1,
    },
  },
  tag: {
    create: {
      loading: false,
      status: "",
    },
  },
  modal: {
    type: "",
  },
  gat: {
    loading: true,
  },
  sat: {
    loading: true,
  },
  lma: {
    laoding: false,
  },
};

const pagesReducer = createReducer(INITIAL_STATE, {
  [pageTypes.ORIGIN_REQUESTED]: (state) => ({
    ...state,
    origin: {
      loading: true,
      status: "waiting",
    },
  }),
  [pageTypes.ORIGIN_RECEIVED]: (state, action) => {
    if (process.env.REACT_APP_PROGRAM) {
      action.payload.program = true;
      action.payload.program_slug = process.env.REACT_APP_PROGRAM;
    }

    if (process.env.REACT_APP_LANDING) {
      action.payload.landing_page_info = {
        landing_page: true,
        slug: process.env.REACT_APP_LANDING,
      };
    }

    return {
      ...state,
      origin: {
        ...action.payload,
        theme: merge(action.payload.theme, DEFAULT_THEME),
        loading: false,
        status: "received",
      },
    };
  },
  [pageTypes.ORIGIN_FAILED]: (state, action) => {
    return {
      ...state,
      origin: {
        ...INITIAL_STATE.origin,
        loading: false,
        status: "failed",
        error: {
          ...axiosDefaultError,
          ...action.payload,
        },
      },
    };
  },
  [pageTypes.HOME_REQUESTED]: ({ home, ...state }) => ({
    ...state,
    home: {
      ...home,
      loading: home.loading,
      status: "waiting",
      loadingActionsVacancies: true,
    },
  }),
  [pageTypes.HOME_RECEIVED]: ({ home, ...state }, { payload }) => ({
    ...state,
    home: {
      ...payload.home,
      loading: false,
      status: "received",
      loadingActionsVacancies: false,
    },
  }),
  [pageTypes.HOME_FAILED]: (state, action) => ({
    ...state,
    home: {
      ...INITIAL_STATE.home,
      loading: false,
      status: "failed",
      loadingActionsVacancies: false,
      error: {
        ...axiosDefaultError,
        ...action.payload,
      },
    },
  }),
  /////////////////////////////////////////////////////////
  [pageTypes.MODAL_OPEN]: (state, action) => ({
    ...state,
    modal: {
      ...INITIAL_STATE.modal,
      ...action.payload,
    },
  }),
  [pageTypes.MODAL_CLOSE]: (state, action) => ({
    ...state,
    modal: {
      ...INITIAL_STATE.modal,
    },
  }),
  /////////////////////////////////////////////////////////
  [pageTypes.ABOUT_REQUESTED]: (state) => ({
    ...state,
    about: {
      loading: true,
      status: "waiting",
    },
  }),
  [pageTypes.ABOUT_RECEIVED]: (state, action) => ({
    ...state,
    about: {
      ...action.payload,
      loading: false,
      status: "received",
    },
  }),
  [pageTypes.ABOUT_FAILED]: (state, action) => ({
    ...state,
    about: {
      ...INITIAL_STATE.about,
      loading: false,
    },
  }),
  /////////////////////////////////////////////////////////
  [pageTypes.ODS_DETAILS_REQUESTED]: (state) => ({
    ...state,
    odsDetails: {
      loading: true,
      status: "waiting",
    },
  }),
  [pageTypes.ODS_DETAILS_RECEIVED]: (state, action) => ({
    ...state,
    odsDetails: {
      ...action.payload,
      loading: false,
      status: "received",
    },
  }),
  [pageTypes.ODS_DETAILS_FAILED]: (state, action) => ({
    ...state,
    odsDetails: {
      ...INITIAL_STATE.odsDetails,
      loading: false,
      status: "failed",
      error: {
        ...axiosDefaultError,
        ...action.payload,
      },
    },
  }),
  /////////////////////////////////////////////////////////
  [pageTypes.ODS_MAIN_REQUESTED]: (state) => ({
    ...state,
    odsMain: {
      loading: true,
      status: "waiting",
    },
  }),
  [pageTypes.ODS_MAIN_RECEIVED]: (state, action) => ({
    ...state,
    odsMain: {
      ...action.payload,
      loading: false,
      status: "received",
    },
  }),
  [pageTypes.ODS_MAIN_FAILED]: (state, action) => ({
    ...state,
    odsMain: {
      ...INITIAL_STATE.odsMain,
      loading: false,
      status: "failed",
      error: {
        ...axiosDefaultError,
        ...action.payload,
      },
    },
  }),
  /////////////////////////////////////////////////////////
  [pageTypes.ACTION_REQUESTED]: (state) => ({
    ...state,
    actionPage: {
      loading: true,
      status: "wating",
    },
  }),
  [pageTypes.ACTION_RECEIVED]: (state, action) => ({
    ...state,
    actionPage: {
      ...action.payload,
      loading: false,
      status: "received",
    },
  }),
  [pageTypes.ACTION_FAILED]: (state, action) => ({
    ...state,
    actionPage: {
      ...INITIAL_STATE.actionPage,
      loading: false,
      status: "failed",
      error: {
        ...axiosDefaultError,
        ...action.payload,
      },
    },
  }),
  /////////////////////////////////////////////////////////
  [pageTypes.USER_REQUESTED]: (state) => ({
    ...state,
    userPage: {
      loading: true,
      status: "waiting",
    },
  }),
  [pageTypes.USER_RECEIVED]: (state, action) => ({
    ...state,
    userPage: {
      ...action.payload,
      loading: false,
      status: "received",
    },
  }),
  [pageTypes.USER_FAILED]: (state, action) => ({
    ...state,
    userPage: {
      ...INITIAL_STATE.userPage,
      loading: false,
      status: "failed",
      error: {
        ...axiosDefaultError,
        ...action.payload,
      },
    },
  }),
  /////////////////////////////////////////////////////////
  [pageTypes.PROGRAM_REQUESTED]: (state) => ({
    ...state,
    programPage: {
      loading: true,
      status: "waiting",
    },
  }),
  [pageTypes.PROGRAM_RECEIVED]: (
    state,
    {
      payload: {
        action_data: { response, ...action_data },
        ...payload
      },
      slug,
    }
  ) => ({
    ...state,
    programPage: {
      ...payload,
      action_data: {
        ...action_data,
        highlighted: response?.slice(0, 2),
        response: response?.slice(2),
      },
      loading: false,
      status: "received",
      primary: payload?.program_data?.color || "#004983",
      support_text: "#FFFFFF",
      slug: slug,
    },
  }),
  [pageTypes.PROGRAM_FAILED]: (state, action) => ({
    ...state,
    programPage: {
      ...INITIAL_STATE.programPage,
      loading: false,
      status: "failed",
      primary: action.payload.program_data?.name === "Neoenergia" ? "#5a8b18" : "#004983",
      slug: action.payload.program_data?.name === "Neoenergia" ? "neoenergia" : "globalSummit",
      error: {
        ...axiosDefaultError,
        ...action.payload,
      },
    },
  }),

  /*
  [pageTypes.LOAD_MORE_SUBSCRIBE_PROGRAMS_REQUESTED]: (state) => ({
    ...state,
  }),
  */
  [pageTypes.LOAD_MORE_SUBSCRIBE_PROGRAMS_RECEIVED]: (
    { programPage: { subscribe_data, ...programPage }, ...state },
    { payload, pg }
  ) => ({
    ...state,
    programPage: {
      ...programPage,
      subscribe_data: {
        ...subscribe_data,
        pg,
        total: payload.total,
        hasMore: payload.hasMore,
        response: pg !== 1 ? [...subscribe_data.response, ...(payload.response || [])] : payload.response || [],
      },
    },
  }),
  /*
  [pageTypes.LOAD_MORE_SUBSCRIBE_PROGRAMS_FAILED]: (state, action) => ({
    ...state,
  }),
  */

  /*
  [pageTypes.LOAD_MORE_ACTION_PROGRAMS_REQUESTED]: (state) => ({
    ...state,
  }),
  */
  [pageTypes.LOAD_MORE_ACTION_PROGRAMS_RECEIVED]: (
    { programPage: { action_data, ...programPage }, ...state },
    { payload, pg }
  ) => ({
    ...state,
    programPage: {
      ...programPage,
      action_data: {
        ...action_data,
        pg,
        total: payload.total,
        hasMore: payload.hasMore,
        response: pg !== 1 ? [...action_data.response, ...(payload.response || [])] : payload.response || [],
      },
    },
  }),
  /*
  [pageTypes.LOAD_MORE_ACTION_PROGRAMS_FAILED]: (state, action) => ({
    ...state,
  }),
  */

  /////////////////////////////////////////////////////////
  [pageTypes.LIST_PROJECTS_REQUESTED]: (state) => ({
    ...state,
    listProjectPage: {
      loading: true,
      status: "waiting",
    },
  }),
  [pageTypes.LIST_PROJECTS_RECEIVED]: (state, action) => ({
    ...state,
    listProjectPage: {
      ...action.payload,
      loading: false,
      status: "received",
    },
  }),
  [pageTypes.LIST_PROJECTS_FAILED]: (state, action) => ({
    ...state,
    listProjectPage: {
      ...INITIAL_STATE.listProjectPage,
      loading: false,
      status: "failed",
      error: {
        ...axiosDefaultError,
        ...action.payload,
      },
    },
  }),

  [pageTypes.GDO_REQUESTED]: (state) => ({
    ...state,
    gdo: {
      loading: true,
      status: "waiting",
    },
  }),
  [pageTypes.GDO_RECEIVED]: (state, action) => ({
    ...state,
    gdo: {
      ...action.payload,
      loading: false,
      status: "received",
    },
  }),
  [pageTypes.GDO_FAILED]: (state, action) => ({
    ...state,
    gdo: {
      ...INITIAL_STATE.gdo,
      loading: false,
      status: "failed",
      error: {
        ...axiosDefaultError,
        ...action.payload,
      },
    },
  }),
  [pageTypes.TAG_REQUESTED]: (state, action) => ({
    ...state,
    tag: {
      ...state.tag,
      ...action.payload,
    },
  }),
  [pageTypes.TAG_RECEIVED]: (state, action) => ({
    ...state,
    tag: {
      ...state.tag,
      ...action.payload,
    },
  }),
  [pageTypes.TAG_FAILED]: (state, action) => ({
    ...state,
    tag: {
      ...state.tag,
      ...action.payload,
    },
  }),
  [pageTypes.GAT_REQUESTED]: (state) => ({
    ...state,
    gat: {
      loading: true,
      status: "waiting",
    },
  }),
  [pageTypes.GAT_RECEIVED]: (state, action) => ({
    ...state,
    gat: {
      ...action.payload,
      loading: false,
      status: "received",
    },
  }),
  [pageTypes.GAT_FAILED]: (state, action) => ({
    ...state,
    gat: {
      ...INITIAL_STATE.gat,
      loading: false,
      status: "failed",
      error: {
        ...axiosDefaultError,
        ...action.payload,
      },
    },
  }),

  [pageTypes.SAT_REQUESTED]: (state) => ({
    ...state,
    sat: {
      loading: true,
      status: "waiting",
    },
  }),
  [pageTypes.SAT_RECEIVED]: (state, action) => ({
    ...state,
    sat: {
      ...action.payload,
      loading: false,
      status: "received",
      success: true,
    },
  }),
  [pageTypes.SAT_FAILED]: (state, action) => ({
    ...state,
    sat: {
      ...INITIAL_STATE.sat,
      loading: false,
      status: "failed",
      error: {
        ...axiosDefaultError,
        ...action.payload,
      },
    },
  }),

  [pageTypes.SAT_MORE_REQUESTED]: ({ sat, ...state }) => ({
    ...state,
    sat: {
      ...sat,
      loadingMore: true,
      statusMore: "waiting",
    },
  }),
  [pageTypes.SAT_MORE_RECEIVED]: ({ sat, ...state }, { pg, payload: { response, ...payload } }) => ({
    ...state,
    sat: {
      ...sat,
      ...payload,
      pg,
      response: (sat?.response || []).concat(response),
      loadingMore: false,
      statusMore: "received",
      success: true,
    },
  }),
  [pageTypes.SAT_MORE_FAILED]: ({ sat, ...state }, action) => ({
    ...state,
    sat: {
      ...sat,
      loadingMore: false,
      statusMore: "failed",
      errorMore: {
        ...axiosDefaultError,
        ...action.payload,
      },
    },
  }),

  [pageTypes.LMA_REQUESTED]: (state) => ({
    ...state,
    lma: {
      loading: true,
      status: "waiting",
    },
  }),
  [pageTypes.LMA_RECEIVED]: (state, { payload }) => ({
    ...state,
    lma: {
      loading: false,
      status: "received",
    },
    gat: {
      total: payload.total,
      hasMore: payload.hasMore,
      response: {
        ...(state.pages?.gat?.response || []),
        actions_data: [...state?.gat?.response?.actions_data, ...(payload?.response?.actions_data || [])],
      },
    },
  }),
  [pageTypes.LMA_FAILED]: (state, action) => ({
    ...state,
    lma: {
      ...INITIAL_STATE.lma,
      loading: false,
      status: "failed",
      error: {
        ...axiosDefaultError,
        ...action.payload,
      },
    },
  }),

  [pageTypes.RAT_REQUESTED]: (state) => ({
    ...state,
    rat: {
      loading: true,
      status: "waiting",
    },
  }),
  [pageTypes.RAT_RECEIVED]: ({ gat, ...state }, { payload }) => ({
    ...state,
    rat: {
      loading: false,
      status: "received",
      ...payload,
    },
    gat: {
      ...gat,
      response: {
        ...gat.response,
        actions_data: gat.response.actions_data.filter((item) => item._id !== payload.action._id),
      },
    },
  }),
  [pageTypes.RAT_FAILED]: ({ gat, ...state }, { payload }) => ({
    ...state,
    rat: {
      ...INITIAL_STATE.rat,
      loading: false,
      status: "failed",
      error: {
        ...axiosDefaultError,
        ...payload,
      },
    },
    gat: {
      ...gat,
      response: {
        ...gat.response,
        actions_data: gat.response.actions_data.map((item) => ({ ...item, ...(item.action_data.disabled = false) })),
      },
    },
  }),

  [pageTypes.ADDAT_REQUESTED]: (state) => ({
    ...state,
    addat: {
      loading: true,
      status: "waiting",
    },
  }),
  [pageTypes.ADDAT_RECEIVED]: ({ gat, sat, ...state }, { payload }) => ({
    ...state,
    addat: {
      loading: false,
      status: "received",
      ...payload,
    },
    gat: {
      ...gat,
      response: { ...gat.response, ...gat.response.actions_data.unshift(payload.action) },
    },
    sat: {
      ...sat,
      response: sat.response.filter((item) => item._id !== payload.action._id),
    },
  }),
  [pageTypes.ADDAT_FAILED]: ({ sat, ...state }, { payload }) => ({
    ...state,
    addat: {
      ...INITIAL_STATE.addat,
      loading: false,
      status: "failed",
      error: {
        ...axiosDefaultError,
        ...payload.error,
      },
    },
    sat: {
      ...sat,
      response: sat.response.map((item) => ({ ...item, ...(item.action_data.disabled = false) })),
    },
  }),
  [pageTypes.GRT_RECEIVED]: (state, action) => ({
    ...state,
    grt: {
      ...action.payload,
      loading: false,
      status: "received",
    },
  }),
  [pageTypes.GRT_FAILED]: (state, action) => ({
    ...state,
    grt: {
      ...INITIAL_STATE.grt,
      loading: false,
      status: "failed",
      error: {
        ...axiosDefaultError,
        ...action.payload,
      },
    },
  }),
});

export default pagesReducer;
