import queryString from "query-string";
import http from "config/http";
import { ActionData, ProjectData, ResponseWithAdditionals, Subscribe } from "core/api/definitions";
import { AxiosResponse } from "axios";

export interface ListTagActionInterface {
  action_data: ActionData;
  created_at: string;
  level: string;
  project_data: ProjectData;
  subscribe: Subscribe;
  updated_at: string;
  visible: boolean;
}

export interface ListTagInterface {
  actions_data: ListTagActionInterface[];
  tag_data: {
    created_at: string;
    description: string;
    slug: string;
    status: string;
    updated_at: string;
    _id: string;
  };
}

export type ListTagResponse = ResponseWithAdditionals<ListTagInterface>;

export default async (slugTag: string): Promise<AxiosResponse<ListTagResponse>> => {
  return http.get<ListTagResponse>(`/admin/tags/${slugTag}/actions/tagged?pp=8`);
};
