const PROJECT_REQUESTED = "project/createProject/REQUESTED";
const PROJECT_RECEIVED = "project/createProject/RECEIVED";
const PROJECT_FAILED = "project/createProject/FAILED";

const GET_PROJECT_REQUESTED = "project/get/REQUESTED";
const GET_PROJECT_RECEIVED = "project/get/RECEIVED";
const GET_PROJECT_FAILED = "project/get/FAILED";

const GPS_REQUESTED = "project/get/slug/REQUESTED";
const GPS_RECEIVED = "project/get/slug/RECEIVED";
const GPS_FAILED = "project/get/slug/FAILED";

const PE_REQUESTED = "project/edit/REQUESTED";
const PE_RECEIVED = "project/edit/RECEIVED";
const PE_FAILED = "project/edit/FAILED";
const PEA_REQUESTED = "project/byOwner/get/REQUESTED";
const PEA_RECEIVED = "project/byOwner/get/RECEIVED";
const PEA_FAILED = "project/byOwner/get/FAILED";

const NPA_REQUESTED = "project/byOwner/byPage/get/REQUESTED";
const NPA_RECEIVED = "project/byOwner/byPage/get/RECEIVED";
const NPA_FAILED = "project/byOwner/byPage/get/FAILED";

const IPPA_UPDATE_STATUS = "project/admin/all/UPDATE_STATUS";

const IPPA_REQUESTED = "project/admin/all/get/REQUESTED";
const IPPA_RECEIVED = "project/admin/all/get/RECEIVED";
const IPPA_FAILED = "project/admin/all/get/FAILED";

const NPPA_REQUESTED = "project/admin/all/byPage/get/REQUESTED";
const NPPA_RECEIVED = "project/admin/all/byPage/get/RECEIVED";
const NPPA_FAILED = "project/admin/all/byPage/get/FAILED";

const VPA_REQUESTED = "project/admin/validate/REQUESTED";
const VPA_RECEIVED = "project/admin/validate/RECEIVED";
const VPA_FAILED = "project/admin/validate/FAILED";

const PROFILE_REQUESTED = "project/profile/REQUESTED";
const PROFILE_RECEIVED = "project/profile/RECEIVED";
const PROFILE_FAILED = "project/profile/FAILED";

const TOGGLE_PROJECT_ACTIVATION_REQUESTED = "project/toggleActivation/REQUESTED";
const TOGGLE_PROJECT_ACTIVATION_RECEIVED = "project/toggleActivation/RECEIVED";
const TOGGLE_PROJECT_ACTIVATION_FAILED = "project/toggleActivation/FAILED";

const CLEAN_PROJECT_DATA = "project/clear";

export default {
  PROJECT_REQUESTED,
  PROJECT_RECEIVED,
  PROJECT_FAILED,
  GET_PROJECT_REQUESTED,
  GET_PROJECT_RECEIVED,
  GET_PROJECT_FAILED,
  GPS_REQUESTED,
  GPS_RECEIVED,
  GPS_FAILED,
  PE_REQUESTED,
  PE_RECEIVED,
  PE_FAILED,
  CLEAN_PROJECT_DATA,
  PEA_REQUESTED,
  PEA_RECEIVED,
  PEA_FAILED,
  NPA_REQUESTED,
  NPA_RECEIVED,
  NPA_FAILED,

  // Panel
  // =====

  IPPA_UPDATE_STATUS,

  // Fetch projects from admin
  IPPA_REQUESTED,
  IPPA_RECEIVED,
  IPPA_FAILED,

  // Fetch next page projects from admin
  NPPA_REQUESTED,
  NPPA_RECEIVED,
  NPPA_FAILED,

  // Validate projects from admin
  VPA_REQUESTED,
  VPA_RECEIVED,
  VPA_FAILED,

  // Fetches project profile
  PROFILE_REQUESTED,
  PROFILE_RECEIVED,
  PROFILE_FAILED,

  // Activating/Deativating projects
  TOGGLE_PROJECT_ACTIVATION_REQUESTED,
  TOGGLE_PROJECT_ACTIVATION_RECEIVED,
  TOGGLE_PROJECT_ACTIVATION_FAILED,
};
