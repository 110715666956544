import { useEffect, useCallback, useState } from "react";
import { isXS, isSM, isMD, isLG, isXL } from "utils/mediaQueries";

const getScreenSizesArray = () => ({
  isXS: isXS(),
  isSM: isSM(),
  isMD: isMD(),
  isLG: isLG(),
  isXL: isXL(),
});

export const useMediaQueries = () => {
  const size = window?.innerWidth || 0;
  const [mode, setMode] = useState({
    ...getScreenSizesArray(),
    isBetween: (start, end) => start <= size && end >= size,
    size,
  });

  const handleScreenResize = useCallback((e) => {
    const windowSize = e?.currentTarget?.innerWidth || 0;
    const isBetween = (start, end) => start <= windowSize && end >= windowSize;

    setMode({ ...getScreenSizesArray(), isBetween, size: windowSize });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleScreenResize);

    return () => {
      window.removeEventListener("resize", handleScreenResize);
    };
  }, [handleScreenResize]);

  return mode;
};
