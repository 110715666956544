import listMaterials from "./listMaterials";
import showMaterials from "./showMaterial";
import updateMaterial from "./updateMaterial";
import storeNewListMaterial from "./storeListMaterial";
import addMaterialItem from "./addMaterialItem";
import updateMaterialItem from "./updateMaterialItem";
import removeMaterialItem from "./removeMaterialItem";

export default {
  listMaterials,
  showMaterials,
  updateMaterial,
  storeNewListMaterial,
  addMaterialItem,
  updateMaterialItem,
  removeMaterialItem,
};
