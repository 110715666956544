import { AxiosResponse } from "axios";
import http from "config/http";
import { ResponseWithParams, ResponseStatus } from "core/api/definitions";
import { MaterialList } from "core/api/definitions";

export interface ListMaterialsRequest {
  action: string;
}

export type ListMaterialsResponseParams = MaterialList[];

export type ListMaterialsResponse = ResponseWithParams<ResponseStatus, ListMaterialsResponseParams>;

export default async ({ action }: ListMaterialsRequest): Promise<AxiosResponse<ListMaterialsResponse>> => {
  return http.get<ListMaterialsResponse>(`/actions/${action}/donors/materials`);
};
