import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { PageEvents } from "../definitions/commonTypes";
import { BaseTitle } from "components/common/BaseTitle";
import CardItem from "wizard/v3/components/CardItem/CardItem";
import SupportText from "components/common/SupportText";
import ActionsFooter from "wizard/v3/components/Layout/ActionsFooter";
import { WizardContainer } from "wizard/v3/components/Layout/Wizard";
import { TitleContainer } from "wizard/v3/components/Layout/Content";
import WizardTrans from "wizard/v3/components/WizardTrans/WizardTrans";
import { ValidationError } from "core/api/definitions";
import { Helmet } from "react-helmet";

export interface Features {
  volunteer: boolean;
  materials: boolean;
}

interface SelectFeaturesProps extends PageEvents<Features> {
  features?: Features;
}

const initialFeatures: Features = {
  volunteer: false,
  materials: false,
};

const SelectFeaturesPage: React.FC<SelectFeaturesProps> = ({ features, onContinue, onChange, onBack, error }) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <WizardContainer>
        <TitleContainer>
          <Helmet title={t("wizard.pages.selectFeatures.title")} />
          <BaseTitle size="md">{t("wizard.pages.selectFeatures.title")}</BaseTitle>
          <SupportText size="md">{t("wizard.pages.selectFeatures.description")}</SupportText>
        </TitleContainer>
        <CustomCardItemContainer data-cy="action-feature">
          <CardItem
            tag="li"
            icon="smile"
            tabIndex={0}
            title={t("wizard.pages.selectFeatures.cards.volunteer.title")}
            subTitle={t("wizard.pages.selectFeatures.cards.volunteer.subtitle")}
            hiddenPhoto={true}
            isSelectable={true}
            isSelected={features?.volunteer}
            onClick={() => {
              if (onChange) onChange({ ...(features || initialFeatures), volunteer: !features?.volunteer });
            }}
            onKeyDown={() => {
              // keyDownConfirm(e, () => handleSelect("volunteer"));
            }}
          >
            <SupportText>
              {features?.volunteer
                ? t("wizard.pages.selectFeatures.buttons.selected")
                : t("wizard.pages.selectFeatures.buttons.select")}
            </SupportText>
          </CardItem>
          <CardItem
            tag="li"
            icon="box-full"
            tabIndex={0}
            title={t("wizard.pages.selectFeatures.cards.materials.title")}
            subTitle={t("wizard.pages.selectFeatures.cards.materials.subtitle")}
            hiddenPhoto={true}
            isSelectable={true}
            isSelected={!!features?.materials}
            onClick={() => {
              if (onChange) onChange({ ...(features || initialFeatures), materials: !features?.materials });
            }}
            onKeyDown={() => {
              // keyDownConfirm(e, () => handleSelect("materials"));
            }}
          >
            <SupportText>
              {features?.materials
                ? t("wizard.pages.selectFeatures.buttons.selected")
                : t("wizard.pages.selectFeatures.buttons.select")}
            </SupportText>
          </CardItem>
        </CustomCardItemContainer>
        {error && <WizardTrans>{error as ValidationError}</WizardTrans>}
      </WizardContainer>
      <ActionsFooter
        continueDisabled={!(features?.volunteer || features?.materials)}
        onBack={onBack}
        onContinue={() => {
          if (onContinue && features) onContinue(features);
        }}
      />
    </Fragment>
  );
};
export default SelectFeaturesPage;

const CustomCardItemContainer = styled.ul`
  padding: 0;
  margin: 0;
`;
