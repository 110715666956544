import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit";
import { LoadingStatus, RequestableState } from "core/api/definitions";
import asyncThunk from "core/decorators/toolkit";
import api from "core/api";
import decoreThunk from "core/decorators/decorate";
import list from "core/decorators/list";
import { ListMaterialsRegistrationsResponseData } from "core/api/users/listMaterialsRegistrations";
import { ListState } from "core/api/definitions";
import { ListMaterialsRegistrationsByUserDetailResponseData } from "core/api/users/listMaterialsRegistrationsByUserDetail";

type IState = RequestableState &
  ListState<ListMaterialsRegistrationsResponseData> & {
    details: {
      [key: string]: Partial<ListMaterialsRegistrationsByUserDetailResponseData> & { loading: LoadingStatus };
    };
  };

export const ListMaterialsRegistrations = asyncThunk(
  "panelDonors/list/materialsRegistrations",
  api.users.listMaterialsRegistrations
);

export const listMaterialsRegistrationsByUserDetail = asyncThunk(
  "panelDonors/details/materialsRegistrationsByUserDetail",
  api.users.listMaterialsRegistrationsByUserDetail,
  {
    condition: [(props, state) => !(props.materialId && state.panelDonors.details[props.materialId])],
  }
);

const panelDonorsSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "panelDonors",
  initialState: {
    loading: "idle",
    list: [],
    details: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, ListMaterialsRegistrations, [list()]);
    decoreThunk(builder, listMaterialsRegistrationsByUserDetail, [
      {
        pending: (state, action) => {
          state.details[action.meta.arg.materialId] = { loading: "loading" };
        },
        fulfilled: (state, action) => {
          state.details[action.meta.arg.materialId] = {
            loading: "ok",
            ...(action?.payload?.params as ListMaterialsRegistrationsByUserDetailResponseData),
          };
        },
        rejected: (state, action) => {
          state.details[action.meta.arg.materialId] = {
            loading: "error",
          };
        },
      },
    ]);
  },
});

export default panelDonorsSlice;
