import { css } from "styled-components/macro";

interface SizesIndexInterface {
  [index: string]: number;
}

const sizesMax: SizesIndexInterface = {
  xs: 575.98,
  sm: 767.98,
  md: 991.98,
  lg: 1199.98,
};

const sizesMin: SizesIndexInterface = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

/**
 * @see: https://getbootstrap.com/docs/4.1/layout/overview/
 */
const mediaQuery = (size: string, only = false, r: any) => {
  if (only) {
    if (size === "xs") {
      return css`
        @media (max-width: ${sizesMax[size]}px) {
          ${r};
        }
      `;
    } else if (size === "xl") {
      return css`
        @media (min-width: ${sizesMin[size]}px) {
          ${r};
        }
      `;
    }

    return css`
      @media (min-width: ${sizesMin[size]}px) and (max-width: ${sizesMax[size]}px) {
        ${r};
      }
    `;
  }

  // Breakpoint down
  return css`
    @media (max-width: ${sizesMax[size]}px) {
      ${r};
    }
  `;
};

const rules = Object.keys(sizesMax).reduce((acc: any, size: string) => {
  return {
    ...acc,
    [size.toString()]: (css: any, only?: boolean) => mediaQuery(size, only, css),
  };
}, {});

for (const size in sizesMin) {
  rules[size + "Up"] = (r: any) => css`
    @media (min-width: ${sizesMin[size]}px) {
      ${r};
    }
  `;
}

for (const size in sizesMax) {
  rules[size + "Down"] = (r: any) => css`
    @media (max-width: ${sizesMax[size]}px) {
      ${r};
    }
  `;
}

export default rules;

export const mq = Object.keys(sizesMax).reduce((acc: any, size: string) => {
  return {
    ...acc,
    [size.toString()]: `@media (min-width: ${sizesMin[size]}px) and (max-width: ${sizesMax[size]}px)`,
  };
}, {});

for (const size in sizesMin) {
  mq[size + "Up"] = `@media (min-width: ${sizesMin[size]}px)`;
}

for (const size in sizesMax) {
  mq[size + "Down"] = `@media (max-width: ${sizesMax[size]}px)`;
}

export const isXS = (): boolean => window.matchMedia(`(max-width: ${sizesMax.xs}px)`).matches;
export const isSM = (): boolean =>
  window.matchMedia(`(min-width: ${sizesMin.sm}px) and (max-width: ${sizesMax.sm}px)`).matches;
export const isMD = (): boolean =>
  window.matchMedia(`(min-width: ${sizesMin.md}px) and (max-width: ${sizesMax.md}px)`).matches;
export const isLG = (): boolean =>
  window.matchMedia(`(min-width: ${sizesMin.lg}px) and (max-width: ${sizesMax.lg}px)`).matches;
export const isXL = (): boolean => window.matchMedia(`(min-width: ${sizesMin.xl}px)`).matches;
