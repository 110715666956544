import queryString from "query-string";
import http from "config/http";
import { Program } from "core/api/definitions";
import { ListResponse } from "core/api/definitions";
import { AxiosResponse } from "axios";

export interface AllRequest {
  term?: string;
  pg?: number;
  causes?: string[];
}

export type AllResponse = ListResponse<Program>;

export default async ({ term, pg, causes }: AllRequest = {}): Promise<AxiosResponse<AllResponse>> => {
  const fs: any = {};
  if (term) fs.term = term;
  if (pg) fs.pg = pg;
  if (causes && causes!.length > 0) fs["cc[]"] = causes;

  return http.get<AllResponse>(`/program?${queryString.stringify(fs)}`);
};
