import React from "react";
import ModalRequestValue, { ModalContainer } from "./ModalRequestValue";
import { useTranslation } from "react-i18next";
import { Input } from "components/register/Form";
import { UniqueDocument } from "core/api/users/getGeneralData";
import useCountry from "hooks/useCountry";
import useMask from "hooks/useMask";
import styled, { css } from "styled-components";

export type ModalRequestDocumentError = "document-required" | "document-format-invalid" | "no-curriculum-data";

interface ModalRequestCPFProps {
  isOpen?: boolean;
  document?: UniqueDocument;
  error?: string;
  disabled?: boolean;
  onChange(document: UniqueDocument): void;
  onClose(): void;
  onSubmit(): void;
}

const ModalRequestUniqueDocument = ({
  isOpen,
  document,
  error,
  onChange,
  onClose,
  onSubmit,
  disabled,
}: ModalRequestCPFProps): JSX.Element => {
  const { t } = useTranslation("components");

  const { personalDocumentType } = useCountry();
  const { personalDocument } = useMask();

  return (
    <ModalRequestValue
      modalInfos={{
        title: t("modalRequestValue.cpf.title"),
        description: t("modalRequestValue.cpf.description"),
      }}
      isOpen={isOpen}
      onClose={onClose}
      disabled={disabled || !document}
      formID="requestCPFForm"
      size="sm"
    >
      <CustomModalContainer>
        <form
          id="requestCPFForm"
          onSubmit={(e: React.FormEvent) => {
            e.preventDefault();
            onSubmit();
          }}
        >
          {/**@ts-ignore */}
          <Input
            type="tel"
            autoFocus
            mask={personalDocument}
            maskChar={null}
            label={personalDocumentType.toUpperCase()}
            placeholder={t("plain:Documento pessoal placeholder")}
            error={error ?? undefined}
            required
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const { value } = e?.target || { value: "" };
              onChange({ type: personalDocumentType, value: value });
            }}
          />
        </form>
      </CustomModalContainer>
    </ModalRequestValue>
  );
};

export default ModalRequestUniqueDocument;

const CustomModalContainer = styled(ModalContainer)(
  () => css`
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 0;
  `
);
