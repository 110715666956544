import React from "react";
import styled, { css } from "styled-components/macro";
import mediaQueries from "utils/mediaQueries";

const Base: React.FunctionComponent = ({ ...props }) => (
  <>
    <Main {...props} id="page-content" />
  </>
);

export default Base;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  min-height: 100%;

  ${mediaQueries.md(css`
    padding-left: 30px;
    padding-right: 30px;
  `)}
`;
