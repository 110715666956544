import http from "config/http";
import { PlatformBasicInformationCreateRequest } from "./create";

export interface PlatformBasicInformationUpdateRequest extends PlatformBasicInformationCreateRequest {
  _id: string;
}

export interface PlataformBasicInformationCreateResponse {
  _id: string;
}

const update = ({ _id, ...data }: PlatformBasicInformationUpdateRequest) =>
  http.patch<PlataformBasicInformationCreateResponse>(`/platform-launcher/${_id}/basic-information`, data);

export default update;
