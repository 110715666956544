import http from "config/http";
import { ResponseStatus, ResponseWithParams } from "../definitions";
import { AxiosResponse } from "axios";

export interface UpdateDocumentDataRequest {
  document_number?: string;
}

export type UpdateDocumentDataResponse = ResponseWithParams<ResponseStatus, any>;

const updateDocument = async (data: UpdateDocumentDataRequest): Promise<AxiosResponse<UpdateDocumentDataResponse>> => {
  return http.post<UpdateDocumentDataResponse>(`/users/store-document`, data);
};

export default updateDocument;
