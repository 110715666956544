import routes from "routes";
import { ValidationFieldSet } from "core/api/definitions";

const simpleRules = {
  name: "required|string|min:2|max:50",
  email: "required|email",
  password:
    "required|confirmed|min:8|max:20|regex:/^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\\s).{8,20}$/",
  contacts: "required|array",

  // Front-end only
  "contacts.*.ddd": "required_without:contacts.0.country|regex:/^[0-9]{2}$/",
  "contacts.*.number": "required|regex:/^[0-9]+$/",
};

const fullRules = {
  ...simpleRules,
  project_type: "required|projectType",
  accept_terms: "required|accepted",
  // document_id: "nullable|x`people_document|unique",
  // gender: "nullable|in:female,male,undefined",
  // birth_date: "nullable|date|date_format:Y-m-d",
  // status: "in:active,pending,suspended,excluded,pending_email_validation",
  // type: "in:volunteer",
  // receive_emails: "required",
  //code: "required|min:4|max:4|string",

  gt_18_years: "required|accepted",
};

const createRoutes: ValidationFieldSet<typeof fullRules> = [
  {
    field: "name",
    route: routes.wizard.newProject.inputName,
  },
  {
    field: "project_type",
    route: routes.wizard.newProject.selectType,
  },
  {
    field: "accept_terms",
    route: routes.wizard.newProject.acceptTerms,
  },
  {
    field: ["email", "password", "contacts", "contacts.*.ddd", "contacts.*.number"],
    route: routes.wizard.newProject.inputAccessInfo,
  },
];

const createRoutesSimple: ValidationFieldSet<typeof simpleRules> = [
  {
    field: ["name", "email", "password", "contacts", "contacts.*.ddd", "contacts.*.number"],
    route: routes.wizard.volunteer.inputAccessInfo,
  },
];

const userValidation = {
  rules: {
    full: fullRules,
    simple: simpleRules,
  },
  routes: {
    create: createRoutes,
    createSimple: createRoutesSimple,
    overrideRouteByIndex: (newRoutes: string[]) =>
      createRoutesSimple.map((r, k) => ({ ...r, route: newRoutes[k] ? newRoutes[k] : r.route })),
  },
};

export default userValidation;
