import { AxiosResponse } from "axios";
import http from "config/http";
import { Response } from "../../definitions";

export interface ChangePasswordRequest {
  password: string;
  new_password: string;
  new_password_confirmation: string;
}

export type ChangePasswordResponse = Response;

const changePassword = async (data: ChangePasswordRequest): Promise<AxiosResponse<ChangePasswordResponse>> =>
  await http.put<ChangePasswordResponse>(`/users/password`, data);

export default changePassword;
