import http from "config/http";
import { ActionResponse } from "core/api/definitions";
import { AxiosResponse } from "axios";

export interface AllRequest {
  slugTag?: string;
  timeRange?: string;
}
export type ListTagResponse = ActionResponse;

export default async ({ slugTag, timeRange }: AllRequest = {}): Promise<AxiosResponse<ListTagResponse>> => {
  return http.get<ListTagResponse>(`/admin/dashboard/reportActionsTags?tag=${slugTag}&time_range=${timeRange}`);
};
