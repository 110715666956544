import React from "react";
import styled, { css } from "styled-components/macro";
import { CONTAINER_BASE } from "utils/constants";
import { mq } from "utils/mediaQueries";
import { ContentContainer } from "./Content";
import { useMediaQueries } from "utils/hooks/useMediaQueries";
import Text from "v3/components/common/Text";

export interface HeaderProps {
  stepCurrent?: number;
  stepMax?: number;
}

const Header: React.FunctionComponent<HeaderProps> = ({ stepCurrent, stepMax, children }) => {
  const steps = new Array(stepMax).fill(null);
  const { isXS } = useMediaQueries();

  return (
    <Container>
      <HeaderContainerContent>
        {children}
        {stepCurrent && stepMax && stepCurrent <= stepMax && !isXS ? (
          <Steps>
            {steps.map((_, k: number) => (
              <Bar key={k} draw={k + 1 <= stepCurrent} />
            ))}
          </Steps>
        ) : null}
      </HeaderContainerContent>
    </Container>
  );
};

export default Header;

const Bar = styled.span<{ draw: boolean }>`
  background-color: ${({ theme, draw }) => (draw ? theme.v3.colors.primary : theme.v3.colors.neutralLightest)};
`;

const Steps = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  ${Bar} {
    display: block;
    height: 3px;
    width: 50px;
  }

  span:not(:last-child) {
    margin-right: 5px;
  }

  ${mq.xsDown} {
    flex-wrap: wrap;
    ${Bar} {
      margin-bottom: 5px;
    }
  }
`;

const Container = styled.header`
  position: relative;
  flex: 0 0 60px;
  display: flex;
  box-shadow: ${({ theme }) => theme.v3.boxShadow.l1};
  padding: ${({ theme }) => theme.v3.spacing.md} 0;
  min-height: 80px;

  ${mq.mdDown} {
    flex-basis: auto;
    margin: 0 -30px;
    padding-left: 30px;
    padding-right: 30px;
  }
`;

const HeaderContainerContent = styled(ContentContainer)`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 1 ${CONTAINER_BASE};

  ${mq.mdDown} {
    display: flex;
    flex-direction: column;
    height: auto;

    ${Steps} {
      padding-top: 15px;
    }
  }

  ${mq.mdDown} {
    flex-direction: column;
    align-items: flex-start;
  }

  ${mq.xsDown} {
    align-items: center;
    justify-content: center;
  }
`;

export const HeaderTitle = styled(Text)`
  font-weight: 400;

  ${mq.xsDown} {
    ${({ theme }) => css`
      font-size: ${theme.v3.fontSize.sm};
      font-weight: 500;
    `}
  }
`;
