import React from "react";
import { default as ReactDatePicker } from "react-datepicker";
import styled, { css } from "styled-components/macro";
import { transparentize } from "polished";
import PropTypes from "prop-types";
import { useReduxSelector } from "hooks/useReduxSelector";
import usePlatformLanguage from "hooks/usePlatformLanguage";

const getSize = (props, sizes, def) => sizes[props.calendarSize || props.size] ?? def;

export const datePickerMixin = css`
  .react-datepicker {
    &__month {
      margin: 0;
    }

    &__day {
      &,
      &-name {
        margin: 0;
        width: ${(props) => getSize(props, { lg: 80, md: 60, sm: 40 }, 60)}px;
      }

      &-name {
        color: #8798ad;
        text-transform: uppercase;
        font-size: 12px;
      }

      line-height: ${(props) => getSize(props, { lg: 78, md: 58, sm: 38 }, 58)}px;
      font-size: ${(props) => getSize(props, { lg: 20, md: 14, sm: 12 }, 14)}px;
      color: ${(props) => props.theme.colors.secondary_700};
      border: 1px solid #e2e6f4;

      &:hover {
        border-radius: 0;
        border: 1px solid ${({ theme }) => theme.colors.secondary};
        background: initial;
      }

      &--outside-month,
      &--disabled {
        color: #8798ad;
        background-color: #f9fafc;
        font-weight: 300;
      }

      &--disabled {
        pointer-events: none;
      }

      &--in-selecting-range {
        &,
        &:hover {
          border-radius: 0;
          font-weight: 500;
          background-color: ${({ theme }) => transparentize(0.5, theme.colors.secondary)};
          border: 1px solid ${({ theme }) => transparentize(0.5, theme.colors.secondary)};
        }
      }

      &--selected,
      &--keyboard-selected,
      &--in-range {
        &,
        &:hover {
          color: #ffffff;
          border-radius: 0;
          font-weight: 500;
          background-color: ${({ theme }) => theme.colors.secondary};
          border: 1px solid ${({ theme }) => theme.colors.secondary};
        }
      }
    }

    &__week:last-child {
      > :first-child {
        border-radius: 0px 0px 0px 10px;
      }

      > :last-child {
        border-radius: 0px 0px 10px 0px;
      }
    }

    &__header {
      border-radius: 10px 10px 0 0;
      border: none;
      background-color: #f9fafc;
    }

    &__current-month {
      font-size: ${(props) => getSize(props, { lg: 25, md: 20, sm: 16 }, 20)}px;
      font-weight: normal;
      color: ${(props) => props.theme.colors.secondary_700};
      line-height: ${(props) => getSize(props, { lg: 32, md: 28, sm: 24 }, 28)}px;
      margin: ${(props) => getSize(props, { lg: 12, md: 10, sm: 8 }, 10)}px 0
        ${(props) => getSize(props, { lg: 20, md: 16, sm: 12 }, 16)}px;
      text-transform: capitalize;
    }

    &__navigation {
      top: 30px;
    }

    &__day-names {
      border-top: 1px solid #e2e6f4;
    }

    &__day-name {
      padding-top: ${(props) => getSize(props, { lg: 30, md: 26, sm: 20 }, 26)}px;
      padding-bottom: ${(props) => getSize(props, { lg: 20, md: 16, sm: 12 }, 16)}px;
      line-height: ${(props) => getSize(props, { lg: 14, md: 10, sm: 8 }, 10)}px;
    }
  }
`;

const DatePickerWrapper = ({ className, ...props }) => {
  const { language } = usePlatformLanguage();

  return <ReactDatePicker inline calendarClassName={className} locale={language || "pt-BR"} {...props} />;
};

const DatePicker = styled(DatePickerWrapper)`
  font-family: inherit;
  border: none;

  ${datePickerMixin}

  .react-datepicker {
    &__month-container {
      background: #f9fafc;
    }

    &__day-name {
      padding-bottom: 0;
    }

    &__header {
      margin-bottom: ${(props) => getSize(props, { lg: 20, md: 16, sm: 12 }, 16)}px;
    }

    &__month {
      border-radius: ${(props) => getSize(props, { lg: 10, md: 10, sm: 8 }, 10)}px;
      box-shadow: 0 0 5px 0 rgba(45, 44, 54, 0.3);
    }

    &__week:first-child {
      > :first-child {
        border-radius: ${(props) => getSize(props, { lg: 10, md: 10, sm: 8 }, 10)}px 0px 0px 0px;
      }

      > :last-child {
        border-radius: 0px ${(props) => getSize(props, { lg: 10, md: 10, sm: 8 }, 10)}px 0px 0px;
      }
    }
  }
`;

DatePicker.defaultProps = {
  size: "md",
};

DatePicker.propTypes = {
  size: PropTypes.oneOf(["sm", "md", "lg"]),
};

export default DatePicker;
