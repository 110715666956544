import { rem } from "polished";
import React from "react";
import { Link, LinkProps } from "react-router-dom";
import { Button as RSButton } from "reactstrap";
import styled, { css, ThemeConsumer } from "styled-components";
import { transition } from "utils/animation";
import { LocationDescriptor, LocationState } from "history";
import useEntity from "hooks/useEntity";
export interface LinkBaseProps<S = LocationState> extends Omit<LinkProps, "to"> {
  to?: LocationDescriptor<S>;
  tag?: React.ElementType;
  href?: string;
  target?: string;
  disabled?: boolean;
}
let entityCurrent = "";
const LinkBase: React.FC<LinkBaseProps> = ({ tag: Tag, ...props }) => {
  const to = props.to || "";
  const { entity } = useEntity();
  entityCurrent = entity;

  if (Tag) return <LinkStyled tag={Tag} {...props} />;
  return <LinkStyled tag={Link} to={to} {...props} />;
};

const LinkStyled = styled(RSButton)`
  border: 0;
  border-radius: 0;
  transition: ${transition.normal("color")};

  ${({ theme }) => css`
    padding: 0 ${theme.v3.spacing.xxs};
    font-size: ${theme.v3.fontSize.sm};
    color: ${entityCurrent === "betterfly" ? "#0f1c14" : theme.v3.colors.primary};

    :focus {
      outline: 1px solid ${theme.v3.colors.feedbackSupport};
      box-shadow: none;
    }

    :hover {
      color: ${theme.v3.colors.primaryLight};
    }

    :active {
      color: ${theme.v3.colors.primaryDark};
    }
  `}
`;

export default LinkBase;
