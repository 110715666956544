import { ErrorResponse, ListResponse } from "core/api/definitions";
import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { ListState, ActionHandler, ListAction, RequestableState } from "core/api/definitions";

// list implements handling listing by using.
const list = <
  T,
  State extends ListState<T> & RequestableState,
  Action extends PayloadAction<(ListAction & ListResponse<T>) | ErrorResponse> & { meta: { arg: any } }
>(): ActionHandler<State, Action> => ({
  pending: (state: Draft<State>, action: Action) => {
    if (action.meta.arg.pg) {
      state.hasMoreLoading = "loading";
      return;
    }
    state.loading = "loading";
  },
  fulfilled: (state: Draft<State>, action: Action) => {
    state.pg = (action.payload as ListAction).pg;
    state.hasMore = (action.payload as ListResponse<T>).hasMore;
    state.total = (action.payload as ListResponse<T>).total;

    if (action.meta.arg.pg > 0) {
      state.hasMoreLoading = "ok";
      state.list.push(...(action.payload.response as Draft<T>[]));
    } else {
      state.loading = "ok";
      state.hasMoreLoading = "ok";
      state.list = action.payload.response as Draft<T>[];
    }
  },
  rejected: (state: Draft<State>, action: Action) => {
    if (action.meta.arg.pg) {
      state.hasMoreLoading = "error";
    } else {
      state.loading = "error";
    }
  },
});

export default list;
