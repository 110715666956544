import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit";
import decoreThunk from "core/decorators/decorate";
import { validateSelectedVolunteer } from "./actions";

type IState = {
  loading: "idle";
};

const panelBetaValidateSelectedVolunteersSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "panel/validation/volunteers",
  initialState: {
    loading: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, validateSelectedVolunteer, [
      {
        pending: (state: any) => {
          state.loading = "loading";
        },
        fulfilled: (state: any) => {
          state.loading = "ok";
        },
        rejected: (state: any) => {
          state.loading = "error";
        },
      },
    ]);
  },
});

export default panelBetaValidateSelectedVolunteersSlice;
