import queryString from "query-string";
import http from "config/http";
import { ProjectStatus, ListResponse } from "core/api/definitions";
import { AxiosResponse } from "axios";

export interface AllRequest {
  term?: string;
  status?: ProjectStatus;
  pg?: number;
  pp?: number;
}

export interface ListAllTagsInterface {
  description: string;
  slug: string;
  status: string;
  id: string;
}

export type ListAllTagsResponse = ListResponse<ListAllTagsInterface>;

export default async ({ term, status, pg, pp }: AllRequest = {}): Promise<AxiosResponse<ListAllTagsResponse>> => {
  // Compose filters
  const fs: any = {};
  if (status) fs.status = status;
  if (term) fs.term = term;
  if (pg) fs.pg = pg;
  if (pp) fs.pp = Math.max(10, pp); // 5 minimum by page.

  return http.get<ListAllTagsResponse>(`/admin/tags?${queryString.stringify(fs)}`);
};
