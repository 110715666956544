import { createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import { listFiltersReportProjectsResponse } from "core/api/admin/subPlataform/listFiltersReportProjects";
import { listFiltersReportRankingResponse } from "core/api/admin/subPlataform/listFiltersReportRanking";
import { listFiltersReportVolunteersResponse } from "core/api/admin/subPlataform/listFiltersReportVolunteers";
import { ProgramImages, RequestableState } from "core/api/definitions";
import decoreThunk from "core/decorators/decorate";

import requestLoading from "core/decorators/requestLoading";

import { listFiltersReportVolunteers, listFiltersReportRanking, listFiltersReportProjects } from "./actions";

interface IState extends RequestableState {
  params: {
    platforms: {};
    report_requisites: {};
  };
}

interface IStateProjects extends RequestableState {
  params: {
    platforms: {};
    report_requisites: {};
    causes: {};
    opportunity_types: {};
  };
}

interface IStateRanking extends RequestableState {
  params: {
    platforms: {};
  };
}

export const panelBetaSubPlatformListFiltersReportVolunteers = createSlice<IState, SliceCaseReducers<IState>>({
  name: "panel/beta/subPlatform/administrators/listFiltersReportVolunteers",
  initialState: {
    loading: "idle",
    params: {
      platforms: {},
      report_requisites: {},
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, listFiltersReportVolunteers, [
      requestLoading(),
      {
        pending: (state: IState) => {
          state.loading = "loading";
        },
        fulfilled: (state: IState, action: PayloadAction<listFiltersReportVolunteersResponse>) => {
          state.loading = "ok";
          if (action?.payload?.params) {
            state.params = action.payload.params;
          }
        },
        rejected: (state: IState) => {
          state.loading = "error";
        },
      },
    ]);
  },
});

export const panelBetaSubPlatformListFiltersReportProjects = createSlice<
  IStateProjects,
  SliceCaseReducers<IStateProjects>
>({
  name: "panel/beta/subPlatform/administrators/listFiltersReportProjects",
  initialState: {
    loading: "idle",
    params: {
      platforms: {},
      report_requisites: {},
      causes: {},
      opportunity_types: {},
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, listFiltersReportProjects, [
      requestLoading(),
      {
        pending: (state: IStateProjects) => {
          state.loading = "loading";
        },
        fulfilled: (state: IStateProjects, action: PayloadAction<listFiltersReportProjectsResponse>) => {
          state.loading = "ok";
          if (action?.payload?.params) {
            state.params = action.payload.params;
          }
        },
        rejected: (state: IStateProjects) => {
          state.loading = "error";
        },
      },
    ]);
  },
});

export const panelBetaSubPlatformListFiltersReportRanking = createSlice<
  IStateRanking,
  SliceCaseReducers<IStateRanking>
>({
  name: "panel/beta/subPlatform/administrators/listFiltersReportRanking",
  initialState: {
    loading: "idle",
    params: {
      platforms: {},
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, listFiltersReportRanking, [
      requestLoading(),
      {
        pending: (state: IStateRanking) => {
          state.loading = "loading";
        },
        fulfilled: (state: IStateRanking, action: PayloadAction<listFiltersReportRankingResponse>) => {
          state.loading = "ok";
          if (action?.payload?.params) {
            state.params = action.payload.params;
          }
        },
        rejected: (state: IStateRanking) => {
          state.loading = "error";
        },
      },
    ]);
  },
});
