import home from "./home";
import posts from "./posts";
import post from "./post";
import likePost from "./likePost";

export default {
  home,
  posts,
  post,
  likePost,
};
