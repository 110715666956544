import http from "config/http";
import {
  AddressCoordinates,
  Coordinate,
  ListResponse,
  AddressWithCoordinates,
  OpportunitiesData,
  RequestWithForce,
} from "../definitions";
import queryString from "query-string";
import { Cause, Interest, Skill, State } from "pages/ListActions/DataOfFilters";
import { convertLatLngToString } from "utils/helpers";

export type ListOpportunityResponse = Omit<ListResponse<unknown>, "response"> & {
  response: {
    opportunities_data: OpportunitiesData[];
  };
};

export interface ListOpportunityRequest extends RequestWithForce {
  term?: string;
  cc?: Cause[];
  fu?: Skill[];
  ci?: Interest[];
  rm?: string;
  uf?: State[];
  wk?: string;
  pg?: number;
  pp?: number;
  filter_subscribes?: boolean;
  filter_materials?: boolean;
  address?: AddressWithCoordinates;
}

const list = ({ address, isForce, ...data }: ListOpportunityRequest) => {
  const dataFormatted: any = { ...data };

  if (Array.isArray(address)) {
    dataFormatted["address[coordinates]"] = convertLatLngToString(address.coordinates as AddressCoordinates);
  } else if (typeof address === "object") {
    dataFormatted["address[coordinates][lat]"] = (address.coordinates as Coordinate).lat;
    dataFormatted["address[coordinates][lng]"] = (address.coordinates as Coordinate).lng;
  }

  const qs = queryString.stringify(dataFormatted, { arrayFormat: "bracket" });
  return http.get<ListOpportunityResponse>(`/search/opportunities${qs ? `?${qs}` : ""}`);
};

export default list;
