import { AxiosResponse } from "axios";
import http from "config/http";
import { ResponseStatus, ResponseWithParams } from "../definitions";
import { ProjectData, ODSData, ActionDataProjectShow } from "../definitions";

export interface ShowProjectRequest {
  projectSlug: string;
}

export interface ShowProjectResponseData {
  id: string;
  project_data: ProjectData;
  ods_data: ODSData[];
  action_data: ActionDataProjectShow[];
}

export type ShowProjectResponse = ResponseWithParams<ResponseStatus, ShowProjectResponseData>;

export default async ({ projectSlug }: ShowProjectRequest): Promise<AxiosResponse<ShowProjectResponse>> =>
  http.get<ShowProjectResponse>(`/projects/${projectSlug}/show`);
