import React, { useState } from "react";
import styled from "styled-components/macro";
import { DropdownMenu, DropdownItem as BaseDropdownItem, DropdownToggle, Input, Dropdown } from "reactstrap";
import { debounce, orderBy } from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { rem } from "polished";

const SelectWithSearch = ({ teams, onSelectTeam, isRequired, isLoading, tag, disabled, placeholder, className }) => {
  const { t } = useTranslation("components");

  const [teamName, setTeamName] = useState("");
  const [filteredTeams, setFilteredTeam] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [customPlaceholder, setCustomPlaceholder] = useState(placeholder);

  const normalizeName = (name) =>
    name
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");

  const searchTeamDebounce = debounce((teamName) => {
    const teamNameNormalized = normalizeName(teamName);

    const filtered = teams.filter((team) => {
      const indexOf = normalizeName(team.name).indexOf(teamNameNormalized);
      return indexOf !== -1 && indexOf < teamName.length;
    });

    setFilteredTeam(filtered);
  }, 300);

  const searchTeam = (e) => {
    const teamName = e?.target?.value || "";
    setTeamName(teamName);
    searchTeamDebounce(teamName);
    if (!isOpen) setIsOpen(true);
  };

  const handleKeyDown = (e, team) => {
    const keyCode = e?.keyCode;

    if (keyCode === 13 && onSelectTeam) {
      onSelectTeam(team._id);
      setTeamName(team.name);
    }
  };

  const teamsList = filteredTeams.length !== 0 ? orderBy(filteredTeams, ["name"]) : orderBy(teams, ["name"]);

  return (
    <SelectContainer isOpen={isOpen} toggle={() => setIsOpen((prev) => !prev)} className={className}>
      <DropdownToggle
        type="text"
        tag={tag || Input}
        disabled={disabled}
        value={teamName}
        onChange={(e) => searchTeam(e)}
        onFocus={() => setCustomPlaceholder(t("selectWithSearch.searchByYourTime"))}
        onBlur={() => setCustomPlaceholder(placeholder)}
        placeholder={customPlaceholder}
        required={isRequired}
      />
      <List>
        {/** //TODO(Jeconias) Change "modalQuizTeam.steps.selectTeam.loading" to universal translate */}
        {isLoading && <span className="disabled">{t("modalQuizTeam.steps.selectTeam.loading")}</span>}
        {!isLoading && (
          <>
            {teamsList.map((team) => (
              <DropdownItem
                key={team._id}
                onKeyDown={(e) => handleKeyDown(e, team)}
                onClick={() => {
                  if (onSelectTeam) {
                    onSelectTeam(team._id);
                    setTeamName(team.name);
                  }
                }}
              >
                {team.name}
              </DropdownItem>
            ))}
          </>
        )}
      </List>
    </SelectContainer>
  );
};

export default SelectWithSearch;

SelectWithSearch.defaultProps = {
  isLoading: false,
};

SelectWithSearch.propTypes = {
  onSelectTeam: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
  isLoading: PropTypes.bool,
  teams: PropTypes.arrayOf({
    images: PropTypes.shape({
      logo: PropTypes.string,
    }),
    league_type: PropTypes.string,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    _id: PropTypes.string.isRequired,
  }),
};

const SelectContainer = styled(Dropdown)`
  position: relative;
  padding: 0;
  background-color: transparent;
  z-index: 1;

  & > input {
    width: 100%;
    z-index: 1;

    ::placeholder {
      color: ${({ theme }) => theme.colors.support_theme};
    }
  }
`;

const DropdownItem = styled(BaseDropdownItem)``;

const List = styled(DropdownMenu)`
  position: absolute;
  width: auto;
  min-width: 100%;
  max-width: 450px;
  overflow-y: auto;
  max-height: calc(50px * 5);
  background-color: #fff;
  list-style: none;
  padding: 0;
  transition: max-height 0.3s;
  box-shadow: rgba(13, 51, 32, 0.1) 0px 6px 20px 0px;

  ${DropdownItem} {
    min-height: 50px;
    white-space: normal;
    line-height: ${rem(18)};
    transition: 0.4s;
    cursor: pointer;
  }

  .active,
  ${DropdownItem}:focus, ${DropdownItem}:hover {
    background-color: rgb(249, 250, 252);
    outline: none;
  }

  ${DropdownItem}, .disabled {
    padding: 0 25px 0 20px;
  }

  .disabled {
    color: #bcc4de;
  }
`;
