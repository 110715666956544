import { createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import { Response, ProgramData, SubscriptionData, UserData } from "core/api/users/resumeProfile";
import { RequestableState } from "core/api/definitions";
import decoreThunk from "core/decorators/decorate";
import requestLoading from "core/decorators/requestLoading";
import { resumeProfile, showAllUserAppointmentsByAction } from "./actions";
import {
  ShowAllUserAppointmentsByActionResponse,
  registration,
} from "core/api/volunteers/showAllUserAppointmentsByAction";

interface IStateResume extends RequestableState {
  program_data: ProgramData[];
  subscription_data: SubscriptionData;
  user_data: UserData | undefined;
}

interface IStateShowAllAppointments extends RequestableState {
  registrations: registration[];
}

export const panelVolunteerResumeSlice = createSlice<IStateResume, SliceCaseReducers<IStateResume>>({
  name: "panelVolunteerResume",
  initialState: {
    loading: "idle",
    program_data: [],
    subscription_data: {
      count: 0,
      subscriptions: [],
    },
    user_data: undefined,
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, resumeProfile, [
      requestLoading(),
      {
        pending: (state: IStateResume) => {
          state.loading = "loading";
        },
        fulfilled: (state: IStateResume, action: PayloadAction<Response>) => {
          state.loading = "ok";
          state.program_data = action.payload.params?.program_data ?? [];
          state.subscription_data = action.payload.params?.subscription_data ?? { count: 0, subscriptions: [] };
          state.user_data = action.payload.params?.user_data ?? undefined;
        },
        rejected: (state: IStateResume) => {
          state.loading = "error";
        },
      },
    ]);
  },
});

export const panelVolunteerShowAllAppointmentsSlice = createSlice<
  IStateShowAllAppointments,
  SliceCaseReducers<IStateShowAllAppointments>
>({
  name: "panel/beta/showAllUserAppointmentsByAction",
  initialState: {
    loading: "idle",
    registrations: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, showAllUserAppointmentsByAction, [
      requestLoading(),
      {
        pending: (state: IStateShowAllAppointments) => {
          state.loading = "loading";
        },
        fulfilled: (
          state: IStateShowAllAppointments,
          action: PayloadAction<ShowAllUserAppointmentsByActionResponse>
        ) => {
          state.loading = "ok";
          if (action.payload.params?.registrations) {
            state.registrations = action.payload.params?.registrations;
          }
        },
        rejected: (state: IStateShowAllAppointments) => {
          state.loading = "error";
        },
      },
    ]);
  },
});
