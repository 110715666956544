import { AxiosResponse } from "axios";
import http from "config/http";
import { ResponseWithParams, ResponseStatus, ProgramImages } from "core/api/definitions";

export interface AdminProgramsData {
  _id: string;
  name: string;
  slug: string;
  images: ProgramImages;
  invite_code: string;
  invite_url: string;
  url_program: string;
  url_site: string;
  type_subscription: boolean;
}

export type ResponseAdminPrograms = ResponseWithParams<ResponseStatus, AdminProgramsData[]>;

const listProgramsAdmin = async (): Promise<AxiosResponse<ResponseAdminPrograms>> =>
  http.get<ResponseAdminPrograms>(`/admin/program`);

export default listProgramsAdmin;
