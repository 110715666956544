import routes from "routes";

const rules = {
  title: "required|string|min:3|max:60",
  action_address: "boolean",
  time_accept_remote: "required|boolean",
  description: "required|string|min:300|max:1000",
  function_id: "required|string",
  time_configurations: "required",
  "time_configurations.times": "required|array",
  "time_configurations.weekdays": "required|array",
  "time_configurations.quantity": "required|integer|min:1",
  "conditionals.interests": "required|conditionals:interest",

  /** Frontend only */
  "time_configurations.times.*.start": "required|regex:/\\d{2}:\\d{2}/",
  "time_configurations.times.*.end": "required|regex:/\\d{2}:\\d{2}/",
  "time_configurations.weekdays.*.day": "required|date",
  subscribeType: "required|in:remote,presential",
};

const createRules = {
  ...rules,
  "images_data.cover_original": "required|string",
};

const updateRules = {
  ...rules,
};

const rulesAddress = {
  "address.city": "required_if:subscribeType,presential|string",
  "address.state": "required_if:subscribeType,presential|string",
  "address.country": "required_if:subscribeType,presential|string",
  "address.coordinates": "required_if:subscribeType,presential|array",
};

const vacancyValidation = {
  rules: {
    create: createRules,
    update: updateRules,
  },
  rulesWithAddress: {
    create: {
      ...createRules,
      ...rulesAddress,
    },
    update: {
      ...updateRules,
      ...rulesAddress,
    },
  },
  routes: {
    create: [
      {
        field: ["time_accept_remote", "subscribeType"],
        route: routes.wizard.project.action.vacancy.new.selectType,
      },
      {
        field: ["title", "function_id", "conditionals.interests", "time_configurations.quantity"],
        route: routes.wizard.project.action.vacancy.new.info,
      },
      {
        field: "description",
        route: routes.wizard.project.action.vacancy.new.details,
      },
      {
        field: ["action_address", "address", "address.city", "address.state", "address.country", "address.coordinates"],
        route: routes.wizard.project.action.vacancy.new.address,
      },
      {
        field: ["images_data.cover_original", "s3helper.format-invalid"],
        route: routes.wizard.project.action.vacancy.new.cover,
      },
      { field: "time_configurations.weekdays", route: routes.wizard.project.action.vacancy.new.date },
      {
        field: "time_configurations.times",
        route: routes.wizard.project.action.vacancy.new.hour,
      },
      {
        field: "subscribe.time_start-not-allowed-be-smaller-than-action-date_start",
        route: routes.wizard.project.action.vacancy.new.date,
      },
    ],
    update: [
      {
        field: ["time_accept_remote", "subscribeType"],
        route: routes.wizard.project.action.vacancy.edit.selectType,
      },
      {
        field: ["title", "function_id", "conditionals.interests", "time_configurations.quantity"],
        route: routes.wizard.project.action.vacancy.edit.info,
      },
      {
        field: "description",
        route: routes.wizard.project.action.vacancy.edit.details,
      },
      {
        field: ["action_address", "address", "address.city", "address.state", "address.country", "address.coordinates"],
        route: routes.wizard.project.action.vacancy.edit.address,
      },
      {
        field: ["images_data.cover_original", "s3helper.format-invalid"],
        route: routes.wizard.project.action.vacancy.edit.cover,
      },
      { field: "time_configurations.weekdays", route: routes.wizard.project.action.vacancy.edit.date },
      {
        field: "time_configurations.times",
        route: routes.wizard.project.action.vacancy.edit.hour,
      },
      {
        field: "subscribe.time_start-not-allowed-be-smaller-than-action-date_start",
        route: routes.wizard.project.action.vacancy.edit.date,
      },
    ],
  },
};

export default vacancyValidation;
