import { AxiosResponse } from "axios";
import http from "config/http";
import { ResponseStatus, ResponseWithParams } from "../definitions";

export interface UnarchivedChat {
  chatID: string;
}

export type UnarchivedChatReponse = ResponseWithParams<ResponseStatus, []>;

export default async ({ chatID }: UnarchivedChat): Promise<AxiosResponse<UnarchivedChatReponse>> =>
  http.get<UnarchivedChatReponse>(`/chat/${chatID}/unarchived`);
