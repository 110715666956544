import React, { useCallback } from "react";
import styled from "styled-components/macro";
import { rgba } from "polished";
import BaseInput, { BaseInputProps } from "./BaseInput";
import { Label as BaseLabel } from "reactstrap";

interface InputProps extends BaseInputProps<boolean> {}

const CheckboxInput = ({ value, id, label, onChange, tagLabel, ...props }: InputProps): JSX.Element => {
  const onChangeCallback = useCallback(
    (e) => {
      if (onChange) onChange(e.target.checked);
    },
    [onChange]
  );

  return (
    <InputWrapper className="custom-control custom-checkbox">
      <BaseInputStyled
        id={id}
        checked={value}
        {...props}
        type={"checkbox"}
        onChange={onChangeCallback}
        className="custom-control-input"
      />
      <LabelStyled for={id} tag={tagLabel} className="custom-control-label">
        {label}
      </LabelStyled>
    </InputWrapper>
  );
};

export default CheckboxInput;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  padding: 0;
`;

const LabelStyled = styled(BaseLabel)`
  color: ${({ theme }) => theme.colors.support_text};
  margin: 0;
  cursor: pointer;
`;

const BaseInputStyled = styled(BaseInput)`
  width: auto;
  height: 24px;
  margin: 0 10px 0 0;
  position: static;

  &:focus {
    border-color: ${(props) => props.theme.colors.primary};
    box-shadow: 0 0 0 0.2rem ${(props) => rgba(props.theme.colors.primary, 0.07)};

    &:not(:checked) ~ ${LabelStyled} {
      &:before {
        border-color: ${(props) => props.theme.colors.primary};
      }
    }

    & ~ ${LabelStyled} {
      &:before {
        box-shadow: 0 0 0 0.2rem ${(props) => rgba(props.theme.colors.primary, 0.25)};
      }
    }
  }

  &:checked ~ ${LabelStyled} {
    &::before {
      background-color: ${(props) => props.theme.colors.primary};
      border-color: ${(props) => props.theme.colors.primary};
    }
  }
`;
