import api from "core/api";
import asyncThunk from "core/decorators/toolkit";

export const customizationUpdate = asyncThunk(
  "panel/beta/platform/customization/update",
  api.admin.platforms.customization.update
);

export const customizationShow = asyncThunk(
  "panel/beta/platform/customization/show",
  api.admin.platforms.customization.show
);
