import { useMemo, useEffect } from "react";
import { debounce } from "lodash";
import { storageSet, StorageType } from "utils/helpers";

const useLocalStorageWrite = <T = any>(data: T, keyLocal: string, local: StorageType = "local", debounceTime = 500) => {
  const storageSetMemorized = useMemo(
    () =>
      debounce(() => {
        storageSet<T>(keyLocal, data, local);
      }, debounceTime),
    [data]
  );

  useEffect(() => {
    storageSetMemorized();
    return () => {
      storageSetMemorized.cancel();
    };
  }, [storageSetMemorized]);
};

export default useLocalStorageWrite;
