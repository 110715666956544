import http from "config/http";
import { ResponseStatus, ResponseWithParams } from "../definitions";
import { UserDataPanelDetails } from "core/api/definitions";
import { AxiosResponse } from "axios";

export interface GetGeneralDataRequest {}

export type UserTypeType = "volunteer" | "project";

export type UniqueDocumentType = "cpf" | "cnpj" | "nif" | "rut";

export interface UniqueDocument {
  type: UniqueDocumentType;
  value: string;
}

export interface GetGeneralDataResponseData extends UserDataPanelDetails {
  unique_document?: UniqueDocument;
  /**
   * @deprecated
   */
  document_brazilian_cpf?: string;
  type: UserTypeType;
}

export type GetGeneralDataResponse = ResponseWithParams<ResponseStatus, GetGeneralDataResponseData>;

const getGeneralData = async (): Promise<AxiosResponse<GetGeneralDataResponse>> => {
  return http.get<GetGeneralDataResponse>(`/users`);
};

export default getGeneralData;
