import { AxiosResponse } from "axios";
import { httpBlog } from "config/http";
import { BlogCardPost, BlogCategory, BlogPost } from "../definitions";

export interface BlogHome {
  showcase: BlogCardPost[];
  specials_posts: BlogCardPost[];
  most_read: BlogCardPost[];
  blog_terms: BlogCategory[];
  recent_posts: {
    posts: BlogCardPost[];
    page: number;
    per_page: number;
  };
}

type QueryParams = {
  category: string;
};

const home = async ({ category }: QueryParams): Promise<AxiosResponse<BlogPost>> => {
  let queryParams = "?";
  queryParams = category ? `category=${category}` : "";

  return httpBlog.get<BlogPost>(`/home?${queryParams}`);
};

export default home;
