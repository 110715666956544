import http from "config/http";
import {
  Action,
  ODSType,
  ListResponse,
  ResponseStatus,
  ResponseWithParams,
  RequestWithForce,
} from "core/api/definitions";
import { Cause } from "pages/ListActions/DataOfFilters";
import queryString from "query-string";

interface ODSDetailsActionsRequest extends RequestWithForce {
  code: number;
  pg?: number;
}

export interface CauseData {
  description: string;
  entity: string[];
  ods: Cause[];
  slug: string;
  thumbnail: string;
  _id: string;
}

export interface ODSDataResponse {
  color_primary: string;
  description: string;
  page_description: string;
  page_title: string;
  reference: ODSType;
  slug: string;
}

export interface ODSDetailsActionsResponseData {
  actions_data: ListResponse<Action>;
  causes_data: CauseData[];
  ods_data: ODSDataResponse;
}

export type ODSDetailsActionsResponse = ResponseWithParams<ResponseStatus, ODSDetailsActionsResponseData>;

const odsDetailsActions = ({ code, pg }: ODSDetailsActionsRequest) => {
  const qs = queryString.stringify({ pg }, { arrayFormat: "bracket" });
  return http.get<ODSDetailsActionsResponse>(`/pages/ods/details/${code}/actions?${qs}`);
};

export default odsDetailsActions;
