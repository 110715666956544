import React, { useCallback, useState } from "react";
import PlacesAutocompleteOriginal, {
  PropTypes as PlacesAutocompleteOriginalProps,
  Suggestion,
} from "react-places-autocomplete";
import styled from "styled-components/macro";
import { transparentize, rem } from "polished";
import Loading from "components/Loading/Loading";
import { Address } from "core/api/definitions";
import { IconType } from "components/Icon/IconFontAwesome";
import useCountry from "hooks/useCountry";
import Icon from "components/Icon/IconFontAwesome";

/* type AddressInfoType =
  | "route"
  | "street_number"
  | "postal_code"
  | "sublocality"
  | "country"
  | "administrative_area_level_1"
  | "locality"; */

export type LocationWithAddress = Address & { address: string };

export type PlacesAutoCompleteProps = {
  inputTag?: string | React.ElementType<any>;
  value?: string;
  error?: string;
  inputProps?: any;
  border?: string;
  onChange?: (value: string) => void;
  onSelect?: (value: string, placeID: string) => void;
  onSuggest?: (suggestions: ReadonlyArray<Suggestion>) => Suggestion[];
  icon?: IconType | boolean;
} & Omit<PlacesAutocompleteOriginalProps, "children" | "value" | "onChange">;

const LocalAutocomplete = ({
  inputTag,
  border,
  value,
  onSuggest,
  onChange,
  onSelect,
  error,
  inputProps,
  icon = false,
  ...props
}: PlacesAutoCompleteProps): JSX.Element => {
  const [suggestionVisible, setSuggestionVisible] = useState(false);
  const { iso3166_1 } = useCountry();

  const onChangeCallback = useCallback(
    (address: string) => {
      if (onChange) onChange(address);
    },
    [value, onChange]
  );

  const onSelectCallback = useCallback(
    async (address, placeID: string) => {
      if (onSelect) onSelect(address, placeID);
    },
    [onSelect]
  );

  return (
    <PlacesAutocompleteOriginal
      onChange={onChangeCallback}
      onSelect={onSelectCallback}
      value={value}
      searchOptions={{
        componentRestrictions: {
          country: iso3166_1, //AQUI FILTRA SO PELO BRASIL
        },
      }}
      {...props}
    >
      {({ getInputProps, getSuggestionItemProps, loading, suggestions }) => {
        const InputComponent = inputTag || "input";

        if (onSuggest) {
          suggestions = onSuggest(suggestions);
        }

        const iProps = getInputProps();
        const disabled = iProps?.disabled || inputProps?.disabled ? true : false;

        return (
          <SearchContainer>
            <InputComponent
              disabled={disabled}
              {...Object.assign(iProps, inputProps)}
              onFocus={() => {
                setSuggestionVisible(true);
              }}
              onBlur={(e: React.FocusEvent) => {
                if (iProps.onBlur) iProps.onBlur(e);
                setSuggestionVisible(false);
              }}
              showList={value && suggestions.length !== 0}
              error={error || inputProps.error}
            />
            {(loading || (suggestions && suggestions.length > 0)) && suggestionVisible && (
              <Suggestions>
                {loading && (
                  <LoadingLi>
                    <Loading size="sm" />
                  </LoadingLi>
                )}
                {suggestions.map((element: Suggestion, key) => (
                  <Li
                    {...getSuggestionItemProps(element)}
                    key={key}
                    className={element.description === value ? "actived" : ""}
                  >
                    {element.description}
                  </Li>
                ))}
              </Suggestions>
            )}
            {!!icon && (
              <IconWrapper>
                <FontAwesomeIconStyled icon={icon as IconType} />
              </IconWrapper>
            )}
          </SearchContainer>
        );
      }}
    </PlacesAutocompleteOriginal>
  );
};

export default LocalAutocomplete;

const SearchContainer = styled.div`
  position: relative;
  width: 100%;
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
`;

const FontAwesomeIconStyled = styled(Icon)`
  width: ${rem(22)} !important;
  height: auto;
  color: #9facbd;
`;

const Suggestions = styled.ul`
  position: absolute;
  max-height: auto;
  width: 100%;
  padding: 0;
  list-style: none;
  color: ${({ theme }) => theme.colors.support_text};
  margin-top: 10px;
  border: 1px solid #bcc4de;
  border-radius: 6px;
  overflow: hidden;
  background-color: #fff;
  transition: max-height 0.7s;
  z-index: 10000;
`;

const LoadingLi = styled.li`
  padding: 15px;
`;

const Li = styled.li`
  transition: 0.4s;
  background-color: #fff;
  padding: 15px;
  cursor: pointer;

  &:hover,
  &.actived {
    background-color: ${transparentize(0.8, "#ccc")};
  }
`;
