import http from "config/http";
import { ResponseWithParams, ResponseStatus } from "core/api/definitions";
import { ProcessPublish } from "../list";
import { PlatformAddressRadius } from "./create";

export interface PlatformBasicInformationShowRequest {
  platformId: string;
}

export interface PlatformSiteSettings {
  title: string;
  urlDefault?: string;
}

export interface PlatformSocialNetwork {
  network?: string;
  url?: string;
  icon?: string;
}

export interface PlatformPrimaryData {
  language: string;
  social_networks: PlatformSocialNetwork[];
}

export interface PlatformMailer {
  fromName: string;
  notificationsTo: string;
  chatNotificationsTo: string;
}

export interface PlatformCurriculumSettings {
  name: string;
  jobRole: string;
  signatureImage: string;
  signatureDefault: boolean;
}

export interface PlatformBasicInformationShowResponseData {
  domain_main: string;
  address_radius: PlatformAddressRadius;
  siteSettings: PlatformSiteSettings;
  primary_data: PlatformPrimaryData;
  mailer: PlatformMailer;
  curriculumSettings: PlatformCurriculumSettings;
  process_publish: ProcessPublish;
}

export type PlatformBasicInformationShowResponse = ResponseWithParams<
  ResponseStatus,
  PlatformBasicInformationShowResponseData
>;

const show = ({ platformId }: PlatformBasicInformationShowRequest) =>
  http.get<PlatformBasicInformationShowResponse>(`/platform-launcher/${platformId}/basic-information`);

export default show;
