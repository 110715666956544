import asyncThunk from "core/decorators/toolkit";
import api from "core/api";
import {
  StoreNewUserResponse,
  StoreNewMatchmakerUserRequest,
  StoreNewUserRequest,
  StoreNewUserRequestBase,
} from "core/api/users/storeNewUser";
import { createSlice, SliceCaseReducers, PayloadAction } from "@reduxjs/toolkit";

export const createStoreNewUser = asyncThunk<
  StoreNewUserResponse,
  StoreNewUserRequestBase | StoreNewUserRequest | StoreNewMatchmakerUserRequest
>("wizard/create/user/new", api.users.storeNewUser);

export const createStoreNewWithDocument = asyncThunk<
  StoreNewUserResponse,
  StoreNewUserRequestBase | StoreNewUserRequest | StoreNewMatchmakerUserRequest
>("wizard/create/user/new", api.users.storeNewUserWithDocument);

export interface UserInfo extends StoreNewUserRequest, Omit<StoreNewMatchmakerUserRequest, "project_type"> {}

interface IState {
  infos?: UserInfo;
}

const userSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "wizard/create/user",
  initialState: {},
  extraReducers: (builder) => {
    builder.addCase(createStoreNewUser.fulfilled.toString(), (state) => {
      if (state?.infos && state.infos?.password) state.infos.password = "";
      if (state?.infos && state.infos?.password_confirmation) state.infos.password_confirmation = "";
    });
  },
  reducers: {
    updateUserData(state, action: PayloadAction<UserInfo>) {
      state.infos = action.payload;
    },
  },
});

export const { updateUserData } = userSlice.actions;

export default userSlice;
