import { AxiosResponse } from "axios";
import http from "config/http";
import { ActionResponse } from "core/api/definitions";

export interface createTagRequest {
  actions_id: string[];
  slugTag: string;
}

export type createTagResponse = ActionResponse;

const deleteActionOnTag = async ({
  actions_id,
  slugTag,
}: createTagRequest): Promise<AxiosResponse<createTagResponse>> => {
  return http.delete<createTagResponse>(`/admin/tags/${slugTag}/actions`, { data: { actions_id } });
};

export default deleteActionOnTag;
