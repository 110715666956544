const SBS_REQUESTED = "subscribe/create/requested";
const SBS_RECEIVED = "subscribe/create/received";
const SBS_FAILED = "subscribe/create/failed";

const SPD_REQUESTED = "support-data/requested";
const SPD_RECEIVED = "support-data/received";
const SPD_FAILED = "support-data/failed";

const SGV_REQUEST = "subscribe/getVacancies/requested";
const SGV_RECEIVED = "subscribe/getVacancies/received";
const SGV_FAILED = "subscribe/getVacancies/failed";

const SGVS_REQUEST = "subscribe/getVacancySlug/requested";
const SGVS_RECEIVED = "subscribe/getVacancySlug/received";
const SGVS_FAILED = "subscribe/getVacancySlug/failed";
const SBC_REQUESTED = "subscribe/get/requested";
const SBC_RECEIVED = "subscribe/get/received";
const SBC_FAILED = "subscribe/get/failed";

const ASB_REQUESTED = "subscribe/post/requested";
const ASB_RECEIVED = "subscribe/post/received";
const ASB_FAILED = "subscribe/post/failed";

const GSB_REQUESTED = "subscribe/get/requested";
const GSB_RECEIVED = "subscribe/get/received";
const GSB_FAILED = "subscribe/get/failed";

const EV_REQUESTED = "subscribe/edit/requested";
const EV_RECEIVED = "subscribe/edit/received";
const EV_FAILED = "subscribe/edit/failed";

const DESTROY_SUBSCRIBE_REQUESTED = "subscribe/destroy/requested";
const DESTROY_SUBSCRIBE_RECEIVED = "subscribe/destroy/received";
const DESTROY_SUBSCRIBE_FAILED = "subscribe/destroy/failed";

const CLEAN_SUBSCRIBE_DATA = "subscribe/clear";

export default {
  SBS_REQUESTED,
  SBS_RECEIVED,
  SBS_FAILED,
  SPD_REQUESTED,
  SPD_RECEIVED,
  SPD_FAILED,
  SGV_REQUEST,
  SGV_RECEIVED,
  SGV_FAILED,
  SGVS_REQUEST,
  SGVS_RECEIVED,
  SGVS_FAILED,
  SBC_REQUESTED,
  SBC_RECEIVED,
  SBC_FAILED,
  ASB_REQUESTED,
  ASB_RECEIVED,
  ASB_FAILED,
  GSB_REQUESTED,
  GSB_RECEIVED,
  GSB_FAILED,
  EV_REQUESTED,
  EV_RECEIVED,
  EV_FAILED,

  // Remove a subscribe
  DESTROY_SUBSCRIBE_REQUESTED,
  DESTROY_SUBSCRIBE_RECEIVED,
  DESTROY_SUBSCRIBE_FAILED,

  //
  CLEAN_SUBSCRIBE_DATA,
};
