import http from "config/http";
import { Interest, Skill } from "pages/ListActions/DataOfFilters";
import queryString from "qs";
import { ResponseWithAdditionals, ODSData, Project, RequestWithForce, Coordinate } from "../definitions";

export interface ProjectListResponse {
  projects: Project[];
  causes: ODSData[];
}

export type ListProjectResponse = ResponseWithAdditionals<ProjectListResponse>;

export interface ProjectListRequest extends RequestWithForce {
  term?: string;
  address?: {
    coordinates?: Coordinate;
  };
  //td?
  rm?: string;
  ci?: Interest[];
  fu?: Skill[];
  pp?: number;
  pg?: number;
}

const list = (data: ProjectListRequest) => {
  const qs = queryString.stringify(data, { arrayFormat: "brackets", encode: false, addQueryPrefix: true });
  return http.get<ListProjectResponse>(`/search/projects${qs}`);
};

export default list;
