import { AxiosResponse } from "axios";
import http from "config/http";
import {
  ImageData,
  ResponseStatus,
  ResponseWithParams,
  Conditionals,
  TimeConfigurations,
  Address,
} from "core/api/definitions";

export interface StoreNewActionRequest {
  project: string;

  //

  title: string;
  details: string;
  conditionals: Conditionals;
  address?: Address;
  images_data: {
    cover?: ImageData;
    gallery?: ImageData[];
  };
  time_configurations: TimeConfigurations;
  video_youtube_url?: string[];
  impact: {
    members: number;
  };
}

export interface StoreNewActionResponseParams {
  slug: string;
  _id: string;
}

export type StoreNewActionResponse = ResponseWithParams<ResponseStatus, StoreNewActionResponseParams>;

const storeNewAction = async ({
  project,
  ...data
}: StoreNewActionRequest): Promise<AxiosResponse<StoreNewActionResponse>> => {
  return http.post<StoreNewActionResponse>(`/projects/${project}/actions`, data);
};

export default storeNewAction;
