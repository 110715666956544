import { AxiosResponse } from "axios";
import http from "config/http";
import { ResponseStatus, ResponseWithParams } from "../definitions";

export interface ChatContacts {
  _id: string;
  chat_data: {
    _id: string;
    volunteer: string;
    owner: string;
    project: string;
    config: {
      volunteer: {
        silence: false;
        archive: false;
      };
      project: {
        silence: false;
        archive: false;
      };
    };
  };
  message_data: {
    _id: string;
    sender: string;
    receiver: string;
    chat_id: string;
    message: string;
    read: false;
    updated_at: string;
    created_at: string;
  };
  user_data: {
    _id: string;
    name: string;
    profile_image: string;
  };
}

export interface ListAllWithVolunteers {
  chat_contacts: ChatContacts[];
  groups: [];
}

export type ListAllWithVolunteersResponse = ResponseWithParams<ResponseStatus, ListAllWithVolunteers>;

export default async ({ project_id }: { project_id: string }): Promise<AxiosResponse<ListAllWithVolunteersResponse>> =>
  http.get<ListAllWithVolunteersResponse>(`/chat/list-all-conversations-volunteers?project_id=${project_id}`);
