import { RootState } from "core/state";
import { Origin } from "core/api/definitions";
import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from "redux";
import { HAND_TALK_AVATAR, HAND_TALK_TOKEN } from "utils/constants";
import { get } from "core/pages/origin";

let isInstalled = false;

const handTalk: Middleware = (store: MiddlewareAPI<Dispatch, RootState>) => (next: Dispatch<AnyAction>) => (
  action: AnyAction
) => {
  const hasHandTalkToken = !!HAND_TALK_TOKEN;

  if (
    hasHandTalkToken &&
    !isInstalled &&
    action.type === get.fulfilled.toString() &&
    (action.payload?.params as Origin)?.entity === "brasil"
  ) {
    (function (d) {
      const s = d.createElement("script");
      s.setAttribute("src", "https://plugin.handtalk.me/web/latest/handtalk.min.js");
      s.addEventListener("load", (e) => {
        const opts: any = {
          token: HAND_TALK_TOKEN,
        };
        if (HAND_TALK_AVATAR) {
          opts.avatar = HAND_TALK_AVATAR;
        }
        const ht = new (window as any).HT(opts);
      });
      d.body.appendChild(s);
      isInstalled = true;
    })(document);
  }

  return next(action);
};

export default handTalk;
