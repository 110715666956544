import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import SearchWithFilter from "v3/components/Search/SearchWithFilter";
import { OpportunityCardInterface } from "v3/components/Cards/OpportunityCard";
import Container from "v3/components/Grid/Container";
import { Footer, Header } from "v3/components/Layout";
import mqV3 from "utils/mediaQueriesV3";
import { useDispatch } from "react-redux";
import { useReduxSelector } from "hooks/useReduxSelector";
import { Cause, Interest, Option, Skill, State } from "pages/ListActions/DataOfFilters";
import { ListOpportunityRequest } from "core/api/opportunities/list";
import { debounce } from "lodash";
import { listOpportunity } from "core/api/site/opportunities";
import useTranslationV3 from "hooks/useTranslationV3";
import Loading from "components/Loading/Loading";
import { SectionFooterButtonWrapper } from "components/Section/Section";
import { reverse } from "named-urls";
import routes from "routes";
import { useLocation } from "react-router-dom";
import { default as BaseCheckbox } from "../../components/Checkbox/Checkbox";
import useGeolocation from "hooks/useGeolocation";
import { Coordinate, OpportunitiesData, OpportunitiesMaterialData } from "core/api/definitions";
import { convertLatLngToArray } from "utils/helpers";
import queryString from "query-string";
import { filtersData } from "pages/ListActions/DataOfFilters";
import FeedbackList from "v3/components/FeedbackSection/FeedbackList";
import { updateCoordinates, updateCoordinatesStatus } from "core/geolocation";
import TitlePage from "v3/components/TitlePage/TitlePage";
import { SearchType } from "v3/components/Search/Filter";
import WrapperMobile from "v3/components/Grid/WrapperMobile";
import OpportunityCardV2 from "v3/components/Cards/OpportunityCardV2";
import { Button } from "reactstrap";
import { rem } from "polished";
import { transition } from "utils/animation";

interface InitialFilterProps {
  cc?: string | string[];
  ci?: string | string[];
  fu?: string | string[];
  uf?: string | string[];
}

const getLocation = (opportunity: OpportunitiesMaterialData): string => {
  if (opportunity?.material_data?.address?.city && opportunity?.material_data?.address?.state) {
    return `${opportunity.material_data?.address?.city.trim()}, ${opportunity.material_data.address?.state}`;
  } else if (opportunity?.material_data?.delivery_way === "to_combine" && opportunity.action_data?.address) {
    return `${(opportunity?.action_data?.address?.city || "").trim()}, ${opportunity?.action_data?.address?.state}`;
  }
  return "";
};

export const handleOpportunityList = (response: OpportunitiesData[]) =>
  response?.reduce((acc: OpportunityCardInterface[], curr): OpportunityCardInterface[] => {
    if (curr.level === "materials") {
      return [
        ...acc,
        {
          title: curr?.material_data.title || "",
          location: getLocation(curr),
          project: {
            name: curr?.project_data?.name || "",
            link: reverse(routes.project.self, { slug: curr?.project_data?.slug! }),
          },
          total: curr?.material_data?.position?.total || 0,
          available: curr?.material_data?.position?.available || 0,
          type: "material",
          projectSlug: curr?.project_data?.slug,
          actionSlug: curr?.action_data?.slug,
          donationID: curr?.material_data?._id,
          isRemote: false,
          imgOpportunity: curr.subscribe_data?.images_data.cover_original,
          imgAction: curr.action_data?.images_data_action?.cover?.original,
        },
      ];
    } else if (curr.level === "subscribe") {
      return [
        ...acc,
        {
          title: curr?.subscribe_data?.title || "",
          location:
            curr?.subscribe_data?.address?.city && curr?.subscribe_data?.address?.state
              ? `${curr.subscribe_data.address?.city.trim()}, ${curr?.subscribe_data?.address?.state}`
              : "",
          project: {
            name: curr?.project_data?.name || "",
            link: reverse(routes.project.self, { slug: curr?.project_data?.slug! }),
          },
          total: curr?.subscribe_data?.position?.total || 0,
          available: curr?.subscribe_data?.position?.available || 0,
          type: "volunteer",
          projectSlug: curr?.project_data?.slug,
          actionSlug: curr?.action_data?.slug,
          vacancySlug: curr?.subscribe_data?.slug,
          isOver: curr?.subscribe_data?.status == "finished",
          isRemote: curr?.subscribe_data?.time_accept_remote,
          imgOpportunity: curr.subscribe_data.images_data.cover_original,
          imgAction: curr.action_data?.images_data_action?.cover?.original,
        },
      ];
    }
    return acc;
  }, []);

export const initialFiltersState = (data: InitialFilterProps) => {
  const causeFromQS = Array.isArray(data?.cc) ? data?.cc : typeof data?.cc === "string" ? [data?.cc] : [];
  const abilityFromQS = Array.isArray(data?.ci) ? data?.ci : typeof data?.ci === "string" ? [data?.ci] : [];
  const functionFromQS = Array.isArray(data?.fu) ? data?.fu : typeof data?.fu === "string" ? [data?.fu] : [];
  const stateFromQS = Array.isArray(data?.uf) ? data?.uf : typeof data?.uf === "string" ? [data?.uf] : [];

  const result: { cc?: string[]; ci?: string[]; fu?: string[]; uf?: string[] } = {};

  if (data?.cc) {
    const causes = filtersData.find((c) => c.id === "causes");
    if (causes) {
      result.cc = causeFromQS.reduce(
        (acc: string[], curr: string) => (causes?.options.find((opt) => opt.value === curr) ? [...acc, curr] : acc),
        []
      );
    }
  }

  if (data?.ci) {
    const abilities = filtersData.find((c) => c.id === "abilities");
    if (abilities) {
      result.ci = abilityFromQS.reduce(
        (acc: string[], curr: string) => (abilities?.options.find((opt) => opt.value === curr) ? [...acc, curr] : acc),
        []
      );
    }
  }

  if (data?.fu) {
    const functions = filtersData.find((c) => c.id === "functions");
    if (functions) {
      result.fu = functionFromQS.reduce(
        (acc: string[], curr: string) => (functions?.options.find((opt) => opt.value === curr) ? [...acc, curr] : acc),
        []
      );
    }
  }

  if (data?.uf) {
    const states = filtersData.find((c) => c.id === "states");
    if (states) {
      result.uf = stateFromQS.reduce(
        (acc: string[], curr: string) => (states?.options.find((opt) => opt.value === curr) ? [...acc, curr] : acc),
        []
      );
    }
  }

  return result;
};

const OpportunityListPage = () => {
  const { t } = useTranslationV3();
  const dispatch = useDispatch();
  const location = useLocation();

  const { opportunities_data, hasMore, pg, loading, coordinate, geolocationStatus } = useReduxSelector((state) => ({
    geolocationStatus: state.geolocation.actived,
    coordinate: state.geolocation.address.coordinate,
    opportunities_data: state.pagesNew.opportunities.list?.response.opportunities_data,
    hasMore: state.pagesNew.opportunities.list?.hasMore,
    loading: state.pagesNew.opportunities.loading,
    pg: state.pagesNew.opportunities.list?.currentPage || 1,
  }));

  const qs = queryString.parse(location.search, { arrayFormat: "bracket" });
  const initialData = useMemo(() => initialFiltersState(qs), [qs]);

  const { search: qsString } = useLocation();

  const { term: qsTerm } = queryString.parse(qsString) ?? {};

  const [term, setTerm] = useState<string>(Array.isArray(qsTerm) ? qsTerm[0] : qsTerm ?? "");
  const [cc, setCc] = useState<Cause[] | undefined>(initialData.cc as Cause[]); //Causes
  const [ci, setCi] = useState<Interest[] | undefined>(initialData.ci as Interest[]); //abilities
  const [fu, setFu] = useState<Skill[] | undefined>(initialData.fu as Skill[]); //Functions
  const [uf, setUf] = useState<State[] | undefined>(initialData.uf as State[]); //UFs
  const [availability, setAvailability] = useState<{ rm?: string; wk?: string }>();
  const [filterMaterials, setFilterMaterials] = useState(true);
  const [filterSubscribes, setFilterSubscribes] = useState(true);

  const { retrievePosition } = useGeolocation();

  const searchData = {
    term,
    cc,
    ci,
    fu,
    uf,
    pg: 1,
    filter_materials: filterMaterials,
    filter_subscribes: filterSubscribes,
    address: geolocationStatus ? { coordinates: coordinate } : undefined,
    ...availability,
  };

  const opportunityList = useMemo(() => handleOpportunityList(opportunities_data ?? []), [opportunities_data]);

  const onApplyCallback = useCallback(
    async (data?: ListOpportunityRequest, isLoadMore?: boolean) => {
      await dispatch(listOpportunity({ ...(data || searchData), isForce: true }));
    },
    [searchData]
  );

  const onSearchMemorized = useMemo(
    () =>
      debounce((value: string) => {
        setTerm(value);
        onApplyCallback({ ...searchData, term: value });
      }, 700),
    [setTerm, searchData]
  );

  const onSelectedCallback = useCallback((el: Option[], searchType: SearchType) => {
    if (searchType === "causes") setCc(el.map((elm) => elm.value as Cause));
    if (searchType === "abilities") setCi(el.map((elm) => elm.value as Interest));
    if (searchType === "functions") setFu(el.map((elm) => elm.value as Skill));
    if (searchType === "states") setUf(el.map((elm) => elm.value as State));
    if (searchType === "availability")
      setAvailability(el.reduce((acc, curr) => ({ ...acc, [curr.param!]: "true" }), {}));
  }, []);

  const onCleanCallback = useCallback(
    (searchType?: SearchType) => {
      if (searchType === "causes") setCc(undefined);
      if (searchType === "abilities") setCi(undefined);
      if (searchType === "functions") setFu(undefined);
      if (searchType === "states") setUf(undefined);
      if (searchType === "availability") setAvailability(undefined);
      if (!searchType) {
        setCc(undefined);
        setCi(undefined);
        setFu(undefined);
        setUf(undefined);
        setAvailability(undefined);
        if (cc || ci || fu || uf || availability)
          onApplyCallback({
            filter_materials: filterMaterials,
            filter_subscribes: filterSubscribes,
            address: geolocationStatus ? { coordinates: coordinate } : undefined,
          });
      }
    },
    [
      setCc,
      setCi,
      setFu,
      setUf,
      cc,
      ci,
      fu,
      uf,
      availability,
      filterMaterials,
      filterSubscribes,
      coordinate,
      geolocationStatus,
      onApplyCallback,
    ]
  );

  const handleCheckboxCallback = useCallback(
    (value: boolean, e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.name === "checkbox-volunteer") {
        if (!filterMaterials) setFilterMaterials(true);
        setFilterSubscribes(value);
        onApplyCallback({
          ...searchData,
          filter_subscribes: value,
          filter_materials: !filterMaterials ? true : filterMaterials,
        });
      } else if (e.target.name === "checkbox-material") {
        if (!filterSubscribes) setFilterSubscribes(true);
        setFilterMaterials(value);
        onApplyCallback({
          ...searchData,
          filter_materials: value,
          filter_subscribes: !filterSubscribes ? true : filterSubscribes,
        });
      }
    },
    [setFilterSubscribes, setFilterMaterials, onApplyCallback, filterSubscribes, filterMaterials, searchData]
  );

  const handleGelocationCheckbox = useCallback(
    (e: React.MouseEvent) => {
      e?.preventDefault();

      const apply = (coords?: Coordinate) => {
        onApplyCallback({
          ...searchData,
          address: !geolocationStatus ? { coordinates: coords } : undefined,
          isForce: true,
        });
      };

      if (!geolocationStatus) {
        retrievePosition()
          .then((r) => {
            const nCoords = convertLatLngToArray(r);
            dispatch(updateCoordinates(nCoords));
            apply(r.toJSON());
          })
          .catch((e) => {
            console.error("The current location could not be obtained. Error ", e);
            //TODO(Jeconias): Report to Sentry
          });
      } else {
        apply();
        dispatch(updateCoordinatesStatus(false));
      }
    },
    [dispatch, searchData, geolocationStatus, onApplyCallback]
  );

  const initialFiltersMemorized = useMemo(
    () => ({
      causes: cc,
      abilities: ci,
      functions: fu,
      states: uf,
    }),
    [] //Don't add dependencies in the array, it was used to run just in the first mount.
  );

  useEffect(() => {
    (async () => {
      const isForce = !!(cc?.length || ci?.length || fu?.length || uf?.length);

      await dispatch(
        listOpportunity({
          term,
          cc,
          ci,
          fu,
          uf,
          filter_materials: filterMaterials,
          filter_subscribes: filterSubscribes,
          address: geolocationStatus ? { coordinates: coordinate } : undefined,
          isForce,
        })
      );
    })();
  }, []); //Don't add dependencies in the array, it was used to run just in the first mount.

  return (
    <>
      <Header />
      <WrapperMobile>
        <Container>
          {" "}
          <TitlePage
            title={t("v3.pages.opportunityList.title")}
            geolocationChecked={geolocationStatus}
            onGeolocationCheckboxChange={handleGelocationCheckbox}
          />
          <SearchWithFilterStyled
            defaultTerm={term}
            onSearch={onSearchMemorized}
            onSelected={onSelectedCallback}
            onApply={onApplyCallback}
            onClean={onCleanCallback}
            initialFilters={initialFiltersMemorized}
            handleCheckbox={handleCheckboxCallback}
          >
            <Checkbox
              id="checkbox-volunteer"
              name="checkbox-volunteer"
              label={t("plain:Vaga de Voluntariado")}
              checked={filterSubscribes}
              onValueChange={handleCheckboxCallback}
            />
            <Checkbox
              id="checkbox-material"
              name="checkbox-material"
              label={t("plain:Doação de Material")}
              checked={filterMaterials}
              onValueChange={handleCheckboxCallback}
            />
          </SearchWithFilterStyled>
          <WrapperList>
            {loading !== "loading" && opportunityList?.length !== 0 && (
              <ListTitle>{t("plain:Exibindo x oportunidades", { count: opportunityList?.length || 0 })}</ListTitle>
            )}
            {loading !== "loading" && opportunityList?.length === 0 && <FeedbackList />}
            {loading !== "ok" && (!opportunityList || opportunityList.length === 0) && <Loading size="sm" />}
            {(loading === "ok" || opportunityList) && (
              <OpportunityCardList>
                <>
                  {opportunityList?.map((opportunity, k) => {
                    let to = "";
                    if (opportunity.type === "material") {
                      to = reverse(routes.opportunities.donation, {
                        actionSlug: opportunity.actionSlug || "",
                        donationID: opportunity.donationID || "",
                      });
                    } else if (opportunity.type === "volunteer") {
                      to = reverse(routes.opportunities.vacancy, {
                        actionSlug: opportunity.actionSlug || "",
                        vacancySlug: opportunity.vacancySlug || "",
                      });
                    }

                    return (
                      <OpportunityCardV2
                        img={opportunity.imgOpportunity ?? opportunity.imgAction}
                        tagContainer="li"
                        key={opportunity.donationID ?? opportunity.vacancySlug ?? k}
                        title={opportunity.title}
                        location={opportunity.location}
                        project={opportunity.project}
                        to={to}
                        total={opportunity.total}
                        available={opportunity.available}
                        type={opportunity.type}
                        isOver={opportunity.isOver}
                        isRemote={opportunity.isRemote}
                      />
                    );
                  })}
                </>
              </OpportunityCardList>
            )}
          </WrapperList>
          <CustomSectionFooterButtonWrapper>
            {loading !== "ok" && (opportunityList || [])?.length !== 0 && pg >= 1 && <Loading size="sm" />}
            {loading === "ok" && hasMore && (
              <OpportunityButton
                onClick={() => {
                  onApplyCallback({ ...searchData, pg: pg + 1 }, true);
                }}
              >
                {t("plain:Exibir mais oportunidades")}
              </OpportunityButton>
            )}
          </CustomSectionFooterButtonWrapper>
        </Container>
      </WrapperMobile>
      <Footer />
    </>
  );
};

export default OpportunityListPage;

const OpportunityButton = styled(Button)`
  font-weight: 500;
  font-size: ${({ theme }) => theme.v3.fontSize.sm};
  color: #fff;
  border: 1px solid transparent;
  border-radius: ${rem(6)};
  transition: ${transition.fast("color", "border", "background-color")};
  background-color: ${({ theme }) => theme.v3.colors.primary};
  &:hover {
    border-color: transparent;
    color: ${({ theme }) => theme.v3.colors.neutralWhite};
    background-color: ${({ theme }) => theme.v3.colors.primaryLight};
  }
`;

const SearchWithFilterStyled = styled(SearchWithFilter)`
  margin-bottom: ${({ theme }) => theme.v3.spacing.xl};

  ${mqV3.xsDown} {
    margin-bottom: ${({ theme }) => theme.v3.spacing.lg};
  }
`;

const WrapperList = styled.div``;

export const OpportunityCardList = styled.ul`
  display: flex;
  padding: 0;
  flex-wrap: wrap;
  margin: 0 -15px;
  list-style: none;
`;

const ListTitle = styled.h2`
  font-size: ${({ theme }) => theme.v3.fontSize.md};
  color: ${({ theme }) => theme.v3.colors.neutralLightness};
  font-weight: 400;
  margin-bottom: ${({ theme }) => theme.v3.spacing.md};
`;

const CustomSectionFooterButtonWrapper = styled(SectionFooterButtonWrapper)`
  margin: ${({ theme }) => theme.v3.spacing.md} 0;
  min-height: 60px;

  & > button {
    max-height: 52px;
  }
`;

const Checkbox = styled(BaseCheckbox)`
  label {
    color: #2c45cc;
    font-size: 14px;
    font-weight: 400;
  }
`;
