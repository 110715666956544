const ODS_REQUESTED = "ods/REQUESTED";
const ODS_RECEIVED = "ods/RECEIVED";
const ODS_FAILED = "ods/FAILED";
const ACTION_REQUESTED = "createAction/REQUESTED";
const ACTION_RECEIVED = "createAction/RECEIVED";
const ACTION_FAILED = "createAction/FAILED";
const CLEAN_ACTION_DATA = "clean/action";
const GAE_REQUESTED = "get/action/REQUESTED";
const GAE_RECEIVED = "get/action/RECEIVED";
const GAE_FAILED = "get/action/FAILED";
const AE_REQUESTED = "edit/action/REQUESTED";
const AE_RECEIVED = "edit/action/RECEIVED";
const AE_FAILED = "edit/action/FAILED";

const IPAP_REQUESTED = "actions/admin/byProject/initialPage/get/REQUESTED";
const IPAP_RECEIVED = "actions/admin/byProject/initialPage/get/RECEIVED";
const IPAP_FAILED = "actions/admin/byProject/initialPage/get/FAILED";

const NPAP_REQUESTED = "actions/admin/byProject/byPage/get/REQUESTED";
const NPAP_RECEIVED = "actions/admin/byProject/byPage/get/RECEIVED";
const NPAP_FAILED = "actions/admin/byProject/byPage/get/FAILED";

const IPVA_REQUESTED = "volunteers/admin/byAction/initialPage/get/REQUESTED";
const IPVA_RECEIVED = "volunteers/admin/byAction/initialPage/get/RECEIVED";
const IPVA_FAILED = "volunteers/admin/byAction/initialPage/get/FAILED";

const IPVAFO_REQUESTED = "volunteers/admin/byOwner/initialPage/get/REQUESTED";
const IPVAFO_RECEIVED = "volunteers/admin/byOwner/initialPage/get/RECEIVED";
const IPVAFO_FAILED = "volunteers/admin/byOwner/initialPage/get/FAILED";

const CHECKOUT_VOLUNTEERS_REQUESTED = "volunteers/forCheckout/get/REQUESTED";
const CHECKOUT_VOLUNTEERS_RECEIVED = "volunteers/forCheckout/get/RECEIVED";
const CHECKOUT_VOLUNTEERS_FAILED = "volunteers/forCheckout/get/FAILED";

const GET_VOLUNTEER_DETAILS_REQUESTED = "volunteers/admin/details/get/REQUESTED";
const GET_VOLUNTEER_DETAILS_RECEIVED = "volunteers/admin/details/get/RECEIVED";
const GET_VOLUNTEER_DETAILS_FAILED = "volunteers/admin/details/get/FAILED";

const VALIDATE_VOLUNTEER_REQUESTED = "volunteer/admin/validate/REQUESTED";
const VALIDATE_VOLUNTEER_RECEIVED = "volunteer/admin/validate/RECEIVED";
const VALIDATE_VOLUNTEER_FAILED = "volunteer/admin/validate/FAILED";

const REMOVE_VOLUNTEER_FROM_LIST_BY_USER = "volunteer/admin/removefromlist/BY_USER";
const REMOVE_VOLUNTEER_FROM_LIST_BY_REGISTRATION_ID = "volunteer/admin/removefromlist/BY_REGISTRATION_ID";

const CHECKOUT_VOLUNTEER_REQUESTED = "volunteer/checkout/REQUESTED";
const CHECKOUT_VOLUNTEER_RECEIVED = "volunteer/checkout/RECEIVED";
const CHECKOUT_VOLUNTEER_FAILED = "volunteer/checkout/FAILED";

const IPAA_UPDATE_STATUS = "actions/admin/all/UPDATE_STATUS";

const IPAA_REQUESTED = "actions/admin/all/get/REQUESTED";
const IPAA_RECEIVED = "actions/admin/all/get/RECEIVED";
const IPAA_FAILED = "actions/admin/all/get/FAILED";

const NPAA_REQUESTED = "actions/admin/all/byPage/get/REQUESTED";
const NPAA_RECEIVED = "actions/admin/all/byPage/get/RECEIVED";
const NPAA_FAILED = "actions/admin/all/byPage/get/FAILED";

const VAA_REQUESTED = "actions/admin/validate/REQUESTED";
const VAA_RECEIVED = "actions/admin/validate/RECEIVED";
const VAA_FAILED = "actions/admin/validate/FAILED";

const DESTROY_ACTION_REQUESTED = "actions/destroy/REQUESTED";
const DESTROY_ACTION_RECEIVED = "actions/destroy/RECEIVED";
const DESTROY_ACTION_FAILED = "actions/destroy/FAILED";

export default {
  ODS_REQUESTED,
  ODS_RECEIVED,
  ODS_FAILED,
  ACTION_REQUESTED,
  ACTION_RECEIVED,
  ACTION_FAILED,
  CLEAN_ACTION_DATA,
  GAE_REQUESTED,
  GAE_RECEIVED,
  GAE_FAILED,
  AE_REQUESTED,
  AE_RECEIVED,
  AE_FAILED,

  // Panel actions
  // =============

  DESTROY_ACTION_REQUESTED,
  DESTROY_ACTION_RECEIVED,
  DESTROY_ACTION_FAILED,

  // Initial page actions by project
  IPAP_REQUESTED,
  IPAP_RECEIVED,
  IPAP_FAILED,

  // Next page admin by project
  NPAP_REQUESTED,
  NPAP_RECEIVED,
  NPAP_FAILED,

  // Initial page of volunteers by owner
  IPVA_REQUESTED,
  IPVA_RECEIVED,
  IPVA_FAILED,

  // Initial page of volunteers by action
  IPVAFO_REQUESTED,
  IPVAFO_RECEIVED,
  IPVAFO_FAILED,

  // Get checkout volunteers
  CHECKOUT_VOLUNTEERS_REQUESTED,
  CHECKOUT_VOLUNTEERS_RECEIVED,
  CHECKOUT_VOLUNTEERS_FAILED,

  // Get volunteer details
  GET_VOLUNTEER_DETAILS_REQUESTED,
  GET_VOLUNTEER_DETAILS_RECEIVED,
  GET_VOLUNTEER_DETAILS_FAILED,

  // Validate volunteer
  VALIDATE_VOLUNTEER_REQUESTED,
  VALIDATE_VOLUNTEER_RECEIVED,
  VALIDATE_VOLUNTEER_FAILED,

  REMOVE_VOLUNTEER_FROM_LIST_BY_USER,
  REMOVE_VOLUNTEER_FROM_LIST_BY_REGISTRATION_ID,

  // Validate volunteer
  CHECKOUT_VOLUNTEER_REQUESTED,
  CHECKOUT_VOLUNTEER_RECEIVED,
  CHECKOUT_VOLUNTEER_FAILED,

  // Panel
  // =====

  IPAA_UPDATE_STATUS,

  // Fetch actions from admin
  IPAA_REQUESTED,
  IPAA_RECEIVED,
  IPAA_FAILED,

  // Fetch next page actions from admin
  NPAA_REQUESTED,
  NPAA_RECEIVED,
  NPAA_FAILED,

  // Validate actions from admin
  VAA_REQUESTED,
  VAA_RECEIVED,
  VAA_FAILED,
};
