import { useReduxSelector } from "./useReduxSelector";
import { Origin } from "core/api/definitions";
import cookie from "core/cookies";
import { Login } from "core/api/definitions";

export const useProgram = () => {
  const user = cookie.user.get() as Login;
  const { hasProgram, program } = useReduxSelector(({ pagesNew }) => ({
    hasProgram: !!(pagesNew?.origin?.params as Origin)?.program,
    program: pagesNew.program.programPage,
  }));

  return {
    hasProgram,
    program,
    programData: program?.program_data,
    isPublic: !!program?.program_data?.type_subscription,
    slug: program?.slug,
    isParticipating:
      (user?.program_data?.length || 0) > 0 && user!.program_data!.some((p: any) => p.slug === program?.slug),
  };
};
