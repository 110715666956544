import http from "config/http";
import { ListResponse, ProjectStatus } from "core/api/definitions";

export interface ProjectAuthorization {
  id: string;
  userId: string;
  permissionType: string;
  status: ProjectStatus;
  updatedAt: string;
  createdAt: string;
}

export interface ProjectData {
  _id: string;
  name: string;
  slug: string;
  images_data: {
    profile: {
      original: string;
      thumb: string;
      description: string;
    };
    cover: {
      original: string;
      thumb: string;
      description: string;
    };
  };
  status: ProjectStatus;
  authorizations: ProjectAuthorization[];
}

export type ListProjectsResponse = ListResponse<ProjectData>;

const switchProjects = () => {
  return http.get<ListProjectsResponse>(`/projects/switch-projects`);
};

export default switchProjects;
