import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import routes from "routes";
import { modalOpen, modalClose } from "core/pages/actions";
import { MODALS } from "utils/constants";
import { useReduxSelector } from "hooks/useReduxSelector";
import useReduxDispatch from "hooks/useReduxDispatch";
import steps from "components/Fans/consts";
import Fans from "components/Fans/Fans";
import ModalVolunteerVacancies from "v3/components/Modals/ModalSubscribe";
import ModalMaterials from "v3/components/Modals/ModalMaterials";

import get from "lodash/get";
import queryString from "query-string";
import ModalSignInUp from "v3/components/Modals/ModalSignInUp";
import ModalForgotPassword from "v3/components/Modals/ModalPassword/ModalForgotPassword";
import ModalResetPassword from "v3/components/Modals/ModalPassword/ModalResetPassword";

const ModalsGlobal: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const openModalByUrl = get(queryString.parse(location.search), "open");
  const tokenByUrl = get(queryString.parse(location.search), "token");
  const { modalObject, fansModal } = useReduxSelector(({ pages: { modal } }) => ({
    modalObject: modal,
    fansModal: modal.fansModal,
  }));

  const dispatch = useReduxDispatch();

  const { modal, ...modalPayload } = modalObject;

  useEffect(() => {
    if (openModalByUrl === MODALS.SIGN_IN) dispatch(modalOpen(MODALS.SIGN_IN));
    else if (openModalByUrl === MODALS.SIGN_UP) dispatch(modalOpen(MODALS.SIGN_UP));
    else if (openModalByUrl === MODALS.FANS) dispatch(modalOpen(MODALS.FANS));
    else if (openModalByUrl === MODALS.FORGOT_PASSWORD) dispatch(modalOpen(MODALS.FORGOT_PASSWORD));
    else if (tokenByUrl) dispatch(modalOpen(MODALS.NEW_PASSWORD));
  }, [tokenByUrl, openModalByUrl, dispatch]);

  return (
    <>
      <ModalSignInUp
        isOpen={MODALS.SIGN_IN === modal || MODALS.SIGN_UP === modal}
        toggle={() => {
          if (openModalByUrl === MODALS.SIGN_IN || openModalByUrl === MODALS.SIGN_UP) history.push({ search: "" });
          if (MODALS.SIGN_IN === modal || MODALS.SIGN_UP === modal) dispatch(modalClose());
        }}
        step={modal}
        redirectTo={{
          signUp: routes.wizard.toString(),
        }}
      />

      <ModalForgotPassword isOpen={MODALS.FORGOT_PASSWORD === modal} toggle={() => dispatch(modalClose())} />

      <Fans
        isOpen={MODALS.FANS === modal}
        step={fansModal?.step || steps.SELECT_TEAM}
        toggle={() => dispatch(modalClose())}
        onClose={() => {
          history.push(routes.home);
        }}
      />

      <ModalVolunteerVacancies
        isOpen={MODALS.VACANCY === modal}
        onClose={() => {
          if (MODALS.VACANCY === modal) dispatch(modalClose());
        }}
        subscribeSlug={modalPayload.slug}
      />

      <ModalMaterials
        isOpen={MODALS.DONATIONS === modal}
        onClose={() => {
          if (MODALS.DONATIONS === modal) dispatch(modalClose());
        }}
      />

      <ModalResetPassword
        token={typeof tokenByUrl === "string" ? tokenByUrl : ""}
        isOpen={MODALS.NEW_PASSWORD === modal}
        toggle={() => dispatch(modalClose())}
      />
    </>
  );
};

export default ModalsGlobal;
