import { axiosDefaultError } from "../../utils/helper";
import createReducer from "../createReducer";
import programTypes from "./types";

const INITIAL_STATE = {
  programList: {
    loading: true,
  },
  loadMoreProgram: {
    loading: false,
  },
  inviteProgram: {
    loading: true,
    message: "",
  },
  assign: {
    loading: false,
  },
  mp: {
    loading: false,
  },
};

const programReducer = createReducer(INITIAL_STATE, {
  [programTypes.PROGRAM_LIST_REQUESTED]: (state) => ({
    ...state,
    programList: {
      loading: true,
      status: "waiting",
    },
  }),
  [programTypes.PROGRAM_LIST_RECEIVED]: (state, action) => ({
    ...state,
    programList: {
      content: [...action.payload],
      loading: false,
      status: "received",
    },
  }),
  [programTypes.PROGRAM_LIST_FAILED]: (state, action) => ({
    ...state,
    programList: {
      ...INITIAL_STATE.programList,
      loading: false,
      status: "failed",
      error: {
        ...axiosDefaultError,
        ...action.payload,
      },
    },
  }),
  [programTypes.LOAD_MORE_PROGRAMS_REQUESTED]: (state) => ({
    ...state,
    loadMoreProgram: {
      loading: true,
      status: "waiting",
    },
  }),
  [programTypes.LOAD_MORE_PROGRAMS_RECEIVED]: (state, action) => ({
    ...state,
    loadMoreProgram: {
      content: [...action.payload],
      loading: false,
      status: "received",
    },
  }),
  [programTypes.LOAD_MORE_PROGRAMS_FAILED]: (state, action) => ({
    ...state,
    loadMoreProgram: {
      ...INITIAL_STATE.loadMoreProgram,
      loading: false,
      status: "failed",
      error: {
        ...axiosDefaultError,
        ...action.payload,
      },
    },
  }),

  [programTypes.ASSIGN_REQUESTED]: (state) => ({
    ...state,
    assign: {
      loading: true,
      status: "waiting",
    },
  }),
  [programTypes.ASSIGN_RECEIVED]: (state, action) => ({
    ...state,
    assignProgram: {
      response: {
        message: action.payload.message,
      },
      loading: false,
      status: "received",
      success: true,
    },
  }),
  [programTypes.ASSIGN_FAILED]: (state, action) => ({
    ...state,
    assignProgram: {
      loading: false,
      status: "failed",
      ...action.payload,
    },
  }),

  [programTypes.INVITE_PROGRAM_REQUESTED]: (state) => ({
    ...state,
    inviteProgram: {
      loading: true,
      status: "waiting",
    },
  }),
  [programTypes.INVITE_PROGRAM_RECEIVED]: (state, action) => ({
    ...state,
    inviteProgram: {
      response: {
        message: action.payload.message,
      },
      loading: false,
      status: "received",
      success: true,
    },
  }),
  [programTypes.INVITE_PROGRAM_FAILED]: (state, action) => ({
    ...state,
    inviteProgram: {
      loading: false,
      status: "failed",
      ...action.payload,
    },
  }),

  [programTypes.CLEAN_PROGRAM]: (state) => ({
    ...state,
    inviteProgram: {
      ...INITIAL_STATE.inviteProgram,
    },
  }),

  [programTypes.MP_REQUESTED]: (state) => ({
    ...state,
    mp: {
      loading: true,
      status: "waiting",
    },
  }),

  [programTypes.MP_RECEIVED]: (state, action) => ({
    ...state,
    mp: {
      ...action.payload,
      loading: false,
      status: "received",
    },
  }),
  [programTypes.MP_FAILED]: (state, action) => ({
    ...state,
    mp: {
      ...action.payload,
      loading: false,
      status: "failed",
    },
  }),
});

export default programReducer;
