import { AxiosResponse } from "axios";
import http from "config/http";
import { ActionResponse } from "../definitions";

export interface AssignRequest {
  slug: string;
  program_data_share: string;
}

export type AssignResponse = ActionResponse;

const assign = async ({ slug, program_data_share }: AssignRequest): Promise<AxiosResponse<AssignResponse>> => {
  return http.post<AssignResponse>(`/program/assign`, { slug, program_data_share });
};

export default assign;
