import styled from "styled-components/macro";

const WizardDoingWorkText = styled.p`
  color: ${({ theme }) => theme.colors.support_text};
  font-size: 2rem;
  user-select: none;
  user-zoom: none;
`;

export default WizardDoingWorkText;
