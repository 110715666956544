import http from "config/http";
import { ResponseWithParams, ResponseStatus } from "core/api/definitions";
import { AxiosResponse } from "axios";

interface response {
  platforms: {};
  report_requisites: {};
}

export type listFiltersReportVolunteersResponse = ResponseWithParams<ResponseStatus, response>;

export default async (): Promise<AxiosResponse<listFiltersReportVolunteersResponse>> => {
  return http.get<listFiltersReportVolunteersResponse>("/admin/dashboard/get-filters-volunteer-report");
};
