import http from "config/http";
import { ResponseStatus, ResponseWithParams } from "../definitions";
import { AxiosResponse } from "axios";

export interface dataRequest {
  message: string;
  receiver: string;
}

export interface SendMessageChatRequest {
  data: dataRequest;
  chatId: string;
}

export type SendMessageResponse = ResponseWithParams<ResponseStatus, { chat_id: string }>;

const SendMessage = async ({ data, chatId }: SendMessageChatRequest): Promise<AxiosResponse<SendMessageResponse>> => {
  return http.post<SendMessageResponse>(`/chat/${chatId}/message`, data);
};

export default SendMessage;
