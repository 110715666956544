import http from "config/http";
import { ResponseStatus, ResponseWithParams } from "core/api/definitions";

export interface PlatformColors {
  primary: string;
  primary_light: string;
  primary_dark: string;
  secondary: string;
  secondary_light: string;
  secondary_dark: string;
}

export interface PlatformCustomizationUpdateRequest {
  _id: string;
  logo?: string;
  favicon?: string;
  colors?: PlatformColors;
}

export interface PlataformCustomizationUpdateResponseData {
  _id: string;
  status: string;
}

export interface PlataformCustomizationUpdateResponse
  extends ResponseWithParams<ResponseStatus, PlataformCustomizationUpdateResponseData> {}

const update = ({ _id, ...data }: PlatformCustomizationUpdateRequest) =>
  http.patch<PlataformCustomizationUpdateResponse>(`/platform-launcher/${_id}/customization`, data);

export default update;
