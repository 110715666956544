import styled from "styled-components/macro";
import { rem } from "polished";

const sizes = {
  lg: 15,
  xg: 22,
};

const Label = styled.span<{ size?: "lg" | "xg" }>`
  color: ${({ theme }) => theme.colors.support_text};
  font-size: ${({ size }) => rem((size && sizes[size]) || 9)};
  line-height: ${({ size }) => rem((size && sizes[size] && sizes[size] + 1) || 10)};
  text-transform: uppercase;
`;

export default Label;
