import React from "react";
import styled from "styled-components/macro";
import { rem } from "polished";
import Button from "components/Button/Button";
import Link from "components/common/Link";
import { useReduxSelector } from "hooks/useReduxSelector";
import { Origin } from "core/api/definitions";
import { Trans, useTranslation } from "react-i18next";
import WizardTrans from "wizard/v3/components/WizardTrans/WizardTrans";
import { ValidationError } from "core/api/definitions";
import { mq } from "utils/mediaQueries";

interface ConfirmAgeProps {
  onConfirm: () => void;
  onReject: () => void;
  error?: string | ValidationError;
  disabled?: boolean;
}

const ConfirmAge = ({ onConfirm, onReject, error, disabled }: ConfirmAgeProps): JSX.Element => {
  const { t } = useTranslation("components");

  const { origin } = useReduxSelector(({ pagesNew }) => ({
    origin: pagesNew.origin.params! as Origin,
  }));

  return (
    <Container>
      <Title>
        <Trans ns="components" i18nKey="confirmAge.title" />
      </Title>
      <ButtonsWrapper hasError={!!error}>
        <ButtonStyled color="secondary" onClick={onConfirm} disabled={disabled}>
          {t("confirmAge.buttons.yes")}
        </ButtonStyled>
        <ButtonStyled color="secondary" onClick={onReject} disabled={disabled}>
          {t("confirmAge.buttons.no")}
        </ButtonStyled>
      </ButtonsWrapper>
      {error && <Error>{error}</Error>}
      <Disclaimer>
        <p>{t("confirmAge.disclaimer")}</p>
        <LinkStyled>{t("confirmAge.links.knowMoreLGPD")}</LinkStyled>
        <LinkStyled>{t("confirmAge.links.termsOfUse")}</LinkStyled>
      </Disclaimer>
      <LogoWrapper>
        <Logo src={origin.logo} title="" alt="" />
      </LogoWrapper>
    </Container>
  );
};

export default ConfirmAge;

const Container = styled.div``;

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: ${rem(46)};
  letter-spacing: ${rem(-0.47)};
  line-height: ${rem(50)};
  text-align: center;
  margin: 0 0 50px 0;
  font-weight: 400;

  ${mq.xsDown} {
    font-size: ${rem(28)};
    line-height: ${rem(34)};
  }
`;

const ButtonsWrapper = styled.div<{ hasError?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: ${({ hasError }) => (hasError ? "0" : "70px")};
  button {
    margin-bottom: 10px;
  }

  ${mq.xsDown} {
    margin-bottom: 30px;
    button {
      margin-left: auto;
      margin-right: auto;
      width: 85%;
    }
  }
`;

const ButtonStyled = styled(Button)`
  width: 190px;
  height: 50px;
`;

const Disclaimer = styled.div`
  padding: 0 50px;
  text-align: center;
  margin-bottom: 50px;

  p {
    margin: 0 0 30px 0;
    color: ${({ theme }) => theme.colors.support_text};
  }

  ${mq.xsDown} {
    padding: 0;
  }
`;

const LinkStyled = styled(Link)`
  font-weight: 500;
  display: block;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const LogoWrapper = styled.div`
  text-align: center;
`;

const Logo = styled.img`
  width: 100px;
`;

const Error = styled(WizardTrans)`
  margin-bottom: 50px;
  margin-top: 0;
`;
