import { Draft } from "@reduxjs/toolkit";
import { RequestableState, ActionHandler } from "core/api/definitions";

interface RequestLoadingProps {
  key?: string;
}

const requestLoading = <State extends RequestableState>(
  props: RequestLoadingProps | null = null
): ActionHandler<State> => {
  const key = props?.key || "loading";
  return {
    fulfilled: (state: Draft<State>) => {
      (state as any)[key] = "ok";
    },
    pending: (state: Draft<State>) => {
      (state as any)[key] = "loading";
    },
    rejected: (state: Draft<State>) => {
      (state as any)[key] = "error";
    },
  };
};

export default requestLoading;
