import api from "core/api";
import asyncThunk from "core/decorators/toolkit";

export const basicInformationsCreate = asyncThunk(
  "panel/beta/platform/basicInformations/create",
  api.admin.platforms.basicInformations.create
);

export const basicInformationsUpdate = asyncThunk(
  "panel/beta/platform/basicInformations/update",
  api.admin.platforms.basicInformations.update
);

export const basicInformationsShow = asyncThunk(
  "panel/beta/platform/basicInformations/show",
  api.admin.platforms.basicInformations.show,
  {
    condition: [
      // The information from the publishing process is necessary to build some screens and is returned only by the API.
      ({ platformId }, state) =>
        !state.panelBeta.subPlatform.basicInformation.platforms[platformId]?.process_publish?.basic_data,
    ],
  }
);

export const basicInformationsGet = asyncThunk(
  "panel/beta/platform/basicInformations/show",
  api.admin.platforms.basicInformations.show
);
