import React, { ElementType } from "react";
import classNames from "classnames";
import { isObject } from "lodash";

interface ColumnPropsObject {
  size?: boolean | number | string;
  push?: string | number;
  pull?: string | number;
  offset?: string | number;
  order?: string | number;
}

export type ColumnProps = string | boolean | number | ColumnPropsObject;

const getColumnSizeClass = (isXs: boolean, colWidth: string, colSize: boolean | string) => {
  if (colSize === true || colSize === "") {
    return isXs ? "cl" : `cl-${colWidth}`;
  } else if (colSize === "auto") {
    return isXs ? "cl-auto" : `cl-${colWidth}-auto`;
  }

  return isXs ? `cl-${colSize}` : `cl-${colWidth}-${colSize}`;
};

export interface ColProps extends React.HTMLProps<HTMLDivElement> {
  [key: string]: any;
  tag?: string | ElementType;
  xs?: ColumnProps;
  sm?: ColumnProps;
  md?: ColumnProps;
}

const colWidths = ["xs", "sm", "md"];

const Col: React.FC<ColProps> = (props) => {
  const { children, className, tag, ...attributes } = props;

  const Component = (tag || "div") as ElementType;

  const colClasses = [];

  colWidths.forEach((colWidth, i) => {
    let columnProp = props[colWidth];

    delete attributes[colWidth];

    if (columnProp) {
      const isXs = colWidth === "xs";

      if (isObject(columnProp)) {
        const clProps = columnProp as ColumnPropsObject;

        const size =
          typeof clProps.size === "boolean" || typeof clProps.size === "string"
            ? clProps.size
            : typeof clProps.size === "number"
            ? clProps.size.toString()
            : false;

        const colSizeInterfix = isXs ? "-" : `-${colWidth}-`;
        const colClass = getColumnSizeClass(isXs, colWidth, size);

        colClasses.push(
          classNames({
            [colClass]: clProps.size || clProps.size === "",
            [`ordr${colSizeInterfix}${clProps.order}`]: clProps.order || clProps.order === 0,
            [`ofst${colSizeInterfix}${clProps.offset}`]: clProps.offset || clProps.offset === 0,
          })
        );
      } else {
        const colClass = getColumnSizeClass(isXs, colWidth, columnProp);
        colClasses.push(colClass);
      }
    }
  });

  if (!colClasses.length) {
    colClasses.push("cl");
  }

  const classes = classNames(className, colClasses);

  return (
    <Component {...attributes} className={classes}>
      {children}
    </Component>
  );
};

export default Col;
