import React, { useCallback } from "react";
import { Form } from "reactstrap";
import { login } from "core/auth/actions";
import useReduxDispatch from "hooks/useReduxDispatch";
import { LoginResponse } from "core/api/auth/login";
import { PayloadAction } from "@reduxjs/toolkit";
import { LocationDescriptor } from "history";

interface SignInFormBaseProps {
  onDispatch: () => void;
  redirectOnSuccess: LocationDescriptor<any>;
  dispatchResponse: (payload: { errorType: string; hasError: boolean; success: boolean }) => void;
}

const SignInFormBase: React.FC<SignInFormBaseProps> = ({
  children,
  dispatchResponse,
  onDispatch,
  redirectOnSuccess,
}) => {
  const dispatch = useReduxDispatch();

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (onDispatch) onDispatch();

      const email = e?.target?.email?.value || "";
      const password = e?.target?.password?.value || "";

      dispatch(
        login({
          email: email.trim(),
          password,
          redirect: redirectOnSuccess,
        }) as any
      ).then(({ payload }: PayloadAction<LoginResponse>) => {
        const p = {
          errorType: "",
          isEmailError: false,
          isPasswordError: false,
          hasError: false,
          success: false,
        };

        if (payload?.success === false) {
          p.errorType = payload.response?.message || "";
          p.hasError =
            payload?.response?.message === "user.not-found" ||
            payload?.response?.message === "user.credentials-invalid";
          p.success = false;
        } else if (payload?.success === true) {
          p.hasError = false;
          p.success = true;
        }

        if (dispatchResponse) dispatchResponse(p);
      });
    },
    [onDispatch, dispatch]
  );

  return <Form onSubmit={handleSubmit}>{children}</Form>;
};

export default SignInFormBase;
