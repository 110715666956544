import React from "react";
import { Label as RSLabel, LabelProps as RSLabelProps } from "reactstrap";
import styled, { css } from "styled-components/macro";

export interface LabelProps extends RSLabelProps {
  disabled?: boolean;
  valid?: boolean;
  invalid?: boolean;
}

const Label: React.FC<LabelProps> = ({ children, ...props }) => {
  return <LabelStyled {...props}>{children}</LabelStyled>;
};

export default Label;

export const BaseLabelStyle = css<LabelProps>`
  font-size: ${({ theme }) => theme.v3.fontSize.sm};
  color: ${({ theme }) => theme.v3.colors.neutralLight};
  line-height: 1;
  margin: 0;
  padding: 0 ${({ theme }) => theme.v3.spacing.xs};

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => theme.v3.colors.neutralLightness};
    `}

  ${({ invalid }) =>
    invalid &&
    css`
      color: ${({ theme }) => theme.v3.colors.feedbackError};
    `}

  ${({ valid }) =>
    valid &&
    css`
      color: ${({ theme }) => theme.v3.colors.feedbackSuccess};
    `}

  &:after {
    content: "";
    position: absolute;
    height: 1px;
    left: 0;
    right: 0;
    background: #fff;
    display: block;
    top: calc(50% + 1px);
    z-index: -1;
  }
`;

const LabelStyled = styled(RSLabel)`
  ${BaseLabelStyle}
`;
