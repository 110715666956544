import { AxiosResponse } from "axios";
import http from "config/http";
import { ResponseStatus, ResponseWithParams } from "../definitions";

interface ShowAllUserAppointmentsByActionRequest {
  subscribe_id: string;
}

export interface registration {
  _id: string;
  day: string;
  time_start: string;
  time_end: string;
  status: string;
}

interface ShowAllUserAppointmentsByAction {
  registrations: registration[];
}

export type ShowAllUserAppointmentsByActionResponse = ResponseWithParams<
  ResponseStatus,
  ShowAllUserAppointmentsByAction
>;

export default async ({
  subscribe_id,
}: ShowAllUserAppointmentsByActionRequest): Promise<AxiosResponse<ShowAllUserAppointmentsByActionResponse>> =>
  http.get<ShowAllUserAppointmentsByActionResponse>(`volunteer/list-registrations-to-cancel/${subscribe_id}`);
