import React from "react";
import { useReduxSelector } from "./useReduxSelector";
import { Origin } from "core/api/definitions";

export const usePlatformUrl = () => {
  const { program, platform_url } = useReduxSelector(({ pagesNew }) => ({
    program: (pagesNew.origin.params as Origin)?.program,
    platform_url: (pagesNew.origin.params as Origin)?.platform_url,
  }));

  return { platformUrl: program ? platform_url : "", program };
};

export const withPlatformUrl = (Component: any) => {
  return (props: any) => {
    const { platformUrl, program } = usePlatformUrl();
    return <Component {...props} platformUrl={platformUrl} program={program} />;
  };
};
