import { combineReducers } from "redux";
import projectSlice from "./project";
import actionSlice from "./action";
import userSlice from "./user";
import subscribeSlice from "./subscribe";
import donationSlice from "./donation";

export default combineReducers({
  project: projectSlice.reducer,
  action: actionSlice.reducer,
  user: userSlice.reducer,
  subscribe: subscribeSlice.reducer,
  donation: donationSlice.reducer,
});
