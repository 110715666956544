import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit"; //PACKAGES
import { ListState, RequestableState } from "core/api/definitions"; //PACKAGES
import decoreThunk from "core/decorators/decorate"; //PACKAGES

import { ProjectData } from "core/api/projects/switchProjects"; //COMP
import { ListProject } from "./actions"; //COMP
import list from "core/decorators/list";
import { logout } from "core/auth";

interface IState extends RequestableState, ListState<ProjectData> {}

const panelAdminSwitchProjectsSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "panelAdminSwitchProejcts",
  initialState: {
    loading: "idle",
    list: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, ListProject, [list()]);

    builder.addCase(logout.toString(), (state) => {
      state.loading = "idle";
    });
  },
});

export default panelAdminSwitchProjectsSlice;
