import React, { useContext, useMemo } from "react";
import {
  CustomInput as BSCustomInput,
  Form as BSForm,
  FormGroup as BSFormGroup,
  Input as BSInput,
  InputProps,
  Label as BSLabel,
} from "reactstrap";
import styled, { css, ThemeContext } from "styled-components/macro";
import { rem, darken } from "polished";
import IconFontAwesome, { IconType } from "components/Icon/IconFontAwesome";

export const Form = styled(BSForm)``;

export const FormGroup = styled(BSFormGroup)`
  margin-bottom: 0.6rem;
`;

/**
 * @deprecated
 */
export const Label = styled(BSLabel)`
  color: ${(props) => props.theme.v3.colors.neutralLight};
  font-size: ${rem(12)};
  letter-spacing: ${rem(1.13)};
  line-height: ${rem(14)};
  text-transform: uppercase;
`;

/**
 * @deprecated
 */
export const Input = styled(BSInput)`
  padding: 11.15px 18.82px 11.45px;
  font-size: 15px;
  line-height: ${rem(18)};
  color: ${(props) => props.theme.v3.colors.neutralLight};
  border: 1px solid #bcc4de;
  border-radius: 5px;
  background-color: #f9fafc;
  height: 40.63px;

  &:focus {
    background-color: #f9fafc;
    color: ${(props) => darken(0.1, props.theme.v3.colors.neutralLight)};
  }

  &::placeholder {
    color: ${(props) => props.theme.v3.colors.neutralLight};
  }
`;

const Wrapper = styled.div<{ withIcon: boolean }>`
  position: relative;

  svg {
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 18px !important;
    cursor: pointer;
  }
  ${Input} {
    ${({ withIcon }) =>
      withIcon &&
      css`
        padding-right: 35px;
      `}
  }
`;

interface InputWithIconProps extends InputProps {
  icon?: IconType;
  color?: string;
  showPassword?: boolean;
  onClickIcon?(): void;
}

export const InputWithIcon = ({ icon, color, showPassword, onClickIcon, ...props }: InputWithIconProps) => {
  const theme = useContext(ThemeContext);

  const iconMemorized = useMemo(() => {
    if (showPassword === false) return ["far", "eye-slash"];
    if (showPassword === true) return ["far", "eye"];
  }, [showPassword]);

  return (
    <Wrapper withIcon={!!icon || showPassword !== undefined}>
      <Input {...props} type={showPassword ? "text" : props.type} />
      {((icon as any) || showPassword !== undefined) && (
        <IconFontAwesome
          icon={iconMemorized || (icon as any)}
          color={color || theme.v3.colors.neutralLight}
          onClick={onClickIcon}
        />
      )}
    </Wrapper>
  );
};

export const CustomInput = styled(BSCustomInput)`
  color: ${(props) => props.theme.v3.colors.neutralLight};
  font-size: ${rem(13)};
  line-height: ${rem(20)};

  .custom-control-input:checked ~ .custom-control-label {
    &::before {
      background-color: ${(props) => props.theme.colors.primary};
      border-color: ${(props) => props.theme.colors.primary};
    }
  }

  .custom-control-label {
    &::before,
    &::after {
      border-radius: 2px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`;
