import React from "react";
import MultiParagraph from "components/common/MultiParagraph";
import { Modal } from "components/Modal";
import CallToAction from "components/register/CallToAction";
import { Content, CustomModalBody, CustomModalHeader, FooterContainer, FooterContent } from "./styles";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import TermsOfUse from "components/TermsOfUse/TermsOfUse";
import styled from "styled-components/macro";
import { rem } from "polished";

const TermsModal = ({ terms, actionsDisabled, onClose, onConfirm }) => {
  const { t } = useTranslation("components");

  return (
    <Modal isOpen={true} toggle={onClose}>
      <CustomModalHeader centered toggle={onClose} alignedClose={false}>
        {t("termsModal.title")}
      </CustomModalHeader>
      <CustomModalBody>
        <Content fixedHeight>
          <TermsContainer>
            <TermsOfUse />
          </TermsContainer>
        </Content>
        <FooterContainer>
          <FooterContent>
            <CallToAction onClick={onConfirm} block disabled={actionsDisabled}>
              {t("termsModal.actions.accept")}
            </CallToAction>
          </FooterContent>
        </FooterContainer>
      </CustomModalBody>
    </Modal>
  );
};

TermsModal.propTypes = {
  terms: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default TermsModal;

const TermsContainer = styled.div`
  h2 {
    font-size: ${rem(18)};
    color: ${({ theme }) => theme.colors.headings.h3};
    margin-top: 20px;
  }
`;
