import React, { useMemo, useState, useCallback } from "react";
import Wizard from "wizard/v3/components/Layout/Wizard";
import { Switch, Route, matchPath, useLocation, useHistory } from "react-router-dom";
import InputAccessInfoVolunteerPage, {
  AccessInfoWithPassword,
  AccessInfoWithName,
} from "./InputAccessInfoVolunteerPage";
import routes from "routes";
import { useTranslation } from "react-i18next";
import useLocalStorageRead from "hooks/useLocalStorageRead";
import useLocalStorageWrite from "hooks/useLocalStorageWrite";
import useValidation from "wizard/v3/hooks/useValidation";
import userValidation from "core/validators/user";
import routeToError from "core/validators/routeToError";
import api from "core/api";
import { reverse } from "named-urls";
import { goto } from "utils/router";
import DoingWork from "components/Progress/DoingWork";
import WizardDoingWorkText from "wizard/v3/components/DoingWork/Text";
import ValidateEmailPage from "../common/ValidateEmailPage";
import { ValidationError, ValidationErrorSet } from "core/api/definitions";
import { createStoreNewUser } from "core/wizard/create/user";
import { showAlert } from "core/alerts/actions";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { phoneFormat } from "utils/helpers";
import ConfirmAgePage from "../common/ConfirmAgePage";
import TermsPage, { Terms } from "wizard/v3/pages/profile/AcceptTermsPage";
import { useProgram } from "hooks/useProgram";

interface CreateVolunteerPageProps {
  pathRoutes: {
    inputAccessInfo: string;
    acceptTerms: string;
    confirmAge: string;
    validateEmail: string;
  };
}

interface CreateVolunteer {
  info?: AccessInfoWithName;
  terms?: Terms;
  gt18year?: boolean;
}

export const WIZARD_CREATE_VOLUNTEER = "creating.volunteer";

const defaultLocalStorageValue = (): CreateVolunteer => ({
  info: undefined,
});

const CreateVolunteerPage = ({ pathRoutes }: CreateVolunteerPageProps): JSX.Element => {
  const { t } = useTranslation();
  const { pathname, state } = useLocation<{ from?: { pathname?: string } }>();
  const { hasProgram } = useProgram();
  const history = useHistory();
  const dispatch = useDispatch();

  const volunteerLocalStorageData = useLocalStorageRead(WIZARD_CREATE_VOLUNTEER, defaultLocalStorageValue)!;

  const [creatingUser, setCreatingUser] = useState(false);
  const [sendingEmailCode, setSendingEmailCode] = useState(false);
  const [terms, setTerms] = useState<Terms | undefined>(volunteerLocalStorageData.terms);
  const [gt18year, setGt18year] = useState(volunteerLocalStorageData.gt18year);
  const [accessInfo, setAccessInfo] = useState<AccessInfoWithPassword | undefined>({
    info: volunteerLocalStorageData.info,
    passwords: { password: "", passwordConfirm: "" },
  });
  const [loading, setLoading] = useState(false);

  const [touched, setTouched] = useState<{ [k: string]: boolean }>({
    name: false,
    email: false,
    password: false,
    password_confirmation: false,
    "contacts.0.ddd": false,
    "contacts.0.number": false,
    gt_18_years: false,
    terms: false,
  });

  const stepCurrent = useMemo(() => {
    if (matchPath(pathname, pathRoutes.inputAccessInfo)) return 1;
    if (matchPath(pathname, pathRoutes.validateEmail)) return 2;
    return 0;
  }, [pathname]);

  useLocalStorageWrite<CreateVolunteer>(
    {
      info: accessInfo?.info,
      terms,
      gt18year,
    },
    WIZARD_CREATE_VOLUNTEER
  );

  const {
    errors: userErrors,
    setErrors: setUserErrors,
    failed: userHasErrors,
    assignErrorsToOriginal: assignUserErrorsToOriginal,
    errorsOriginal: userErrorsOriginal,
  } = useValidation(
    {
      name: accessInfo?.info?.name,
      email: accessInfo?.info?.email,
      password: accessInfo?.passwords?.password,
      password_confirmation: accessInfo?.passwords?.passwordConfirm,
      contacts: [
        {
          ddd: accessInfo?.info?.phone?.ddd,
          number: (accessInfo?.info?.phone?.number || "").replace(/[.|-]/g, ""),
        },
      ],
    },
    userValidation.rules.simple,
    touched,
    [accessInfo]
  );

  const accessInfoPageChangeCallback = useCallback((formData: AccessInfoWithPassword) => {
    if (!touched?.name && formData?.info?.name) setTouched((prev) => ({ ...prev, name: true }));
    if (!touched?.email && formData?.info?.email) setTouched((prev) => ({ ...prev, email: true }));
    if (!touched?.password && formData?.passwords?.password)
      setTouched((prev) => ({ ...prev, password: true, password_confirmation: true }));
    if (
      (!touched?.["contacts.0.ddd"] || !touched?.["contacts.0.number"]) &&
      (formData?.info?.phone?.ddd || formData?.info?.phone?.number)
    )
      setTouched((prev) => ({ ...prev, ["contacts.0.ddd"]: true, ["contacts.0.number"]: true }));
    setAccessInfo(formData);
  }, []);

  const { info } = accessInfo || { info: { name: "", email: "", phone: { ddd: "", number: "" } } };
  const sendEmailCodeCallback = useCallback(
    async (isNewCode?: boolean) => {
      if (!info?.email || !info?.name) return;
      setLoading(true);
      try {
        const response = (await api.users.sendEmailValidationCode({ email: info?.email, name: info?.name })).data;
        if (isNewCode) dispatch(showAlert("info", t("wizard.success.newCode")));
        return response;
      } catch (e) {
        throw e;
      } finally {
        setLoading(false);
      }
    },
    [info, dispatch]
  );

  const isUserDataValid = useCallback(() => {
    if (userHasErrors) {
      const r = routeToError(
        userErrorsOriginal!,
        userValidation.routes.overrideRouteByIndex([pathRoutes.inputAccessInfo])
      );
      if (r) {
        assignUserErrorsToOriginal();
        history.push({ pathname: r, state });
      }
      return false;
    }
    return true;
  }, [userHasErrors, userErrorsOriginal, state]);

  const selectAccessInfoOnContinue = useCallback(async () => {
    if (!isUserDataValid()) return;

    setSendingEmailCode(true);
    try {
      const response = await sendEmailCodeCallback();
      if (response?.success) {
        if (terms === "accepted" && gt18year) return goto(history, pathRoutes.validateEmail, state)();
        if (terms === "accepted" && !gt18year) return goto(history, pathRoutes.confirmAge, state)();

        goto(history, pathRoutes.acceptTerms, state)();
      }
    } catch (e) {
      const { response } = e;
      if (!isEmpty(response?.data?.params)) return setUserErrors(response?.data?.params || {});
      dispatch(showAlert("danger", t("wizard.error.common.codeGeneration")));
    } finally {
      setSendingEmailCode(false);
    }
  }, [history, dispatch, assignUserErrorsToOriginal, isUserDataValid, setUserErrors, terms, gt18year, state]);

  const selectTermsOnContinue = useCallback(
    (terms) => {
      setTerms(terms);
      goto(history, reverse(pathRoutes.confirmAge), state)();
    },
    [history, state]
  );

  const selectAgeOnBack = useCallback(() => {
    goto(history, reverse(pathRoutes.acceptTerms), state)();
  }, [history, state]);

  const selectAgeOnContinue = useCallback(
    (gt18year: boolean) => {
      setGt18year(gt18year);
      goto(history, reverse(pathRoutes.validateEmail), state)();
    },
    [history, state]
  );

  const validateEmailOnContinueCallback = useCallback(
    async (code: string) => {
      setCreatingUser(true);
      const response: any = await dispatch(
        createStoreNewUser({
          name: accessInfo?.info?.name || "",
          email: accessInfo?.info?.email || "",
          password: accessInfo?.passwords?.password || "",
          password_confirmation: accessInfo?.passwords?.passwordConfirm || "",
          accept_terms: true,
          receive_emails: true,
          contacts: [
            {
              type: "phone",
              country: "+55",
              value: phoneFormat(`${accessInfo?.info?.phone.ddd}${accessInfo?.info?.phone.number}`),
            },
          ],
          code,
        })
      );
      if (createStoreNewUser.fulfilled.match(response)) {
        const pathname = hasProgram ? routes.home : routes.listActions;
        history.push({ ...(state?.from || {}), pathname: state?.from?.pathname || pathname });
      } else if (createStoreNewUser.rejected.match(response)) {
        const resp = response.payload as any;

        if (resp.params && !isEmpty(resp.params)) {
          setUserErrors(resp.params);
          const r = routeToError(
            resp.params as ValidationErrorSet,
            userValidation.routes.overrideRouteByIndex([pathRoutes.inputAccessInfo])
          );
          dispatch(showAlert("danger", t("wizard.error.common.userCreation")));
          if (r) history.push({ pathname: r, state });
        } else {
          dispatch(
            showAlert(
              "danger",
              t([`wizard.error.common.${resp.response?.message}`, "wizard.error.common.userCreation"])
            )
          );
        }
        setCreatingUser(false);
      }
    },
    [accessInfo, history, hasProgram, state]
  );

  return (
    <Wizard header={{ title: t("wizard.stepsWizard.createProfile.header.title"), stepCurrent, stepMax: 2 }}>
      <DoingWork isOpen={sendingEmailCode || creatingUser}>
        {creatingUser && <WizardDoingWorkText>{t("wizard.pages.createProject.creating.userText")}</WizardDoingWorkText>}
      </DoingWork>
      <Switch>
        <Route path={pathRoutes.inputAccessInfo}>
          <InputAccessInfoVolunteerPage
            formData={accessInfo}
            onChange={accessInfoPageChangeCallback}
            onContinue={selectAccessInfoOnContinue}
            error={userErrors}
          />
        </Route>
        <Route path={pathRoutes.acceptTerms}>
          <TermsPage onBack={pathRoutes.inputAccessInfo} onContinue={selectTermsOnContinue} />
        </Route>
        <Route path={pathRoutes.confirmAge}>
          <ConfirmAgePage onBack={selectAgeOnBack} onContinue={selectAgeOnContinue} error={userErrors?.gt_18_years} />
        </Route>
        <Route path={pathRoutes.validateEmail}>
          <ValidateEmailPage
            userData={{ email: info?.email || "", name: info?.name || "" }}
            onContinue={validateEmailOnContinueCallback}
            onNewEmail={pathRoutes.inputAccessInfo}
            onRequestCode={() => sendEmailCodeCallback(true)}
            error={(userErrors?.["users.code-invalid"] || userErrors?.["code.expired"]) as ValidationError}
            loading={loading}
          />
        </Route>
      </Switch>
    </Wizard>
  );
};

export default CreateVolunteerPage;
