import { redirectReceived, blcReceived, blcFailed } from "./actions";

export function redirectUrls(dados) {
  return async (dispatch) => {
    await dispatch(redirectReceived(dados));
  };
}

export function getBrowserAddress() {
  return async (dispatch) => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        let address = { coordinates: [position.coords.latitude, position.coords.longitude] };
        let latlng = new window.google.maps.LatLng(position.coords.latitude, position.coords.longitude);
        let geocoder = new window.google.maps.Geocoder();

        geocoder.geocode({ latLng: latlng }, function(results, status) {
          if (status === window.google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              results[0].address_components.forEach((i) => {
                if (i.types.includes("locality")) {
                  address = { ...address, city: i.short_name };
                } else if (i.types.includes("administrative_area_level_1")) {
                  address = { ...address, state: i.short_name };
                } else if (i.types.includes("country")) {
                  address = { ...address, country: i.short_name };
                }
              });
              dispatch(blcReceived(address));
            }
          }
        });
      });
    } else {
      blcFailed(false);
    }
  };
}
