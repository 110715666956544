import React from "react";
import Circle from "components/Icon/Circle";
import Icon, { IconType } from "components/Icon/Icon";
import styled, { css } from "styled-components";
import { mq } from "utils/mediaQueries";
import Text from "v3/components/common/Text";

interface ClueProps {
  text: string;
  size?: string;
  icon?: IconType;
  [key: string]: any;
}

const Clue: React.FC<ClueProps> = ({ text, icon = "checkout", size, ...props }) => {
  return (
    <Flex {...props}>
      {size && (
        <FlexCentered>
          {/* <Circle>
            <Icon type={icon} />
          </Circle> */}
          <Text size="sm" color="neutralLight" align="center">
            {text}
          </Text>
        </FlexCentered>
      )}
      {!size && (
        <>
          {/* <Circle>
            <Icon type={icon} />
          </Circle> */}
          <Text size="sm" color="neutralLight" align="center">
            {text}
          </Text>
        </>
      )}
    </Flex>
  );
};

export default Clue;

const FlexCentered = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  /* align-self: flex-start; */
  ${Text} {
    padding-top: 6px;
    @media (max-width: 480px) {
      max-width: 80%;
      font-size: 14px;
    }
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  padding-top: 20px;
  justify-content: center;

  svg {
    /* color: ${({ theme }) => theme.colors.primary}; */
    color: #2e4768;
  }
`;
