import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit";
import api from "core/api";
import decoreThunk from "core/decorators/decorate";
import requestLoading from "core/decorators/requestLoading";
import asyncThunk from "core/decorators/toolkit";
import { LoadingStatus } from "core/api/definitions";
import { VolunteerLoggedPopupV2Response } from "core/api/subscribe/volunteerLoggedPopupV2";
import { logout } from "core/auth";

export const volunteerLoggedPopupV2 = asyncThunk(
  "panel/user/summary/mySubscriptions/details",
  api.subscribe.volunteerLoggedPopupV2,
  {
    condition: [
      (props, state) => {
        return !state.panel.userSummary.subscriptionDetails[props?.slug];
      },
    ],
  }
);

interface IState {
  loading: LoadingStatus;
  subscriptionDetails: { [key: string]: VolunteerLoggedPopupV2Response | undefined };
}

const userSummarySlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "panelUserSummary",
  initialState: {
    loading: "idle",
    subscriptionDetails: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, volunteerLoggedPopupV2, [
      requestLoading(),
      {
        fulfilled: (state, action) => {
          if (action.meta.arg?.slug) state.subscriptionDetails[action.meta.arg?.slug] = action.payload.params;
        },
      },
    ]);
    builder.addCase(logout.toString(), (state) => {
      state.subscriptionDetails = {};
    });
  },
});

export default userSummarySlice;
