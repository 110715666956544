import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { reverse } from "named-urls";
import routes from "routes";

type RuleFunc = (props: any) => boolean;

export interface FuncRouteProps extends RouteProps {
  omit?: boolean;
  rule: boolean | RuleFunc;
  unauthorized: (param: any) => any;
}

const FuncRoute: React.FunctionComponent<FuncRouteProps> = ({ rule, unauthorized, children, ...props }) => {
  delete props.omit;

  const isAllowed = rule instanceof Function ? rule(props) : !!rule;
  return (
    <Route
      {...props}
      render={() => {
        if (isAllowed) return children;
        const route = unauthorized
          ? unauthorized instanceof Function
            ? unauthorized(props)
            : unauthorized
          : reverse(routes.panel.forbidden);
        return <Redirect to={route} />;
      }}
    />
  );
};

export default FuncRoute;
