import React from "react";
import ButtonBase, { ButtonBaseProps } from "./ButtonBase";
import ButtonPrimary from "./ButtonPrimary";
import ButtonOutline from "./ButtonOutline";
import styled from "styled-components";

type ButtonColor = "primary" | "outline" | "outline-white";
type ButtonSize = "md" | "sm";

interface ButtonProps extends ButtonBaseProps {
  color: ButtonColor;
  size?: ButtonSize;
}

const ButtonComp: React.FC<ButtonProps> = ({ color, size, ...props }) => {
  if (color === "primary") return <ButtonPrimary {...props} size={size} />;
  if (color === "outline") return <ButtonOutline {...props} size={size} />;
  if (color === "outline-white") return <ButtonOutline {...props} size={size} outlineColor="neutralWhite" />;

  return <ButtonBase {...props} />;
};

const Button = styled(ButtonComp)`
  &:hover {
    color: #fff;
  }
`;

export default Button;
