import React, { useCallback } from "react";
import { Centered, LargeInput } from "wizard/v3/components/common/styles";
import Clue from "wizard/v3/components/Clue/Clue";
import { PageEvents, BaseProps } from "wizard/v3/pages/definitions/commonTypes";
import SupportText from "components/common/SupportText";
import ActionsFooter from "wizard/v3/components/Layout/ActionsFooter";
import FormWizard from "./FormWizard";
import { WizardContainer } from "wizard/v3/components/Layout/Wizard";
import { TitleContainer } from "wizard/v3/components/Layout/Content";
import { ValidationError } from "core/api/definitions";
import WizardTrans from "wizard/v3/components/WizardTrans/WizardTrans";
import Text from "v3/components/common/Text";
import styled from "styled-components";

export interface InputSimplePageProps extends BaseProps, PageEvents<string> {
  value?: string;
  minLength?: number;
  maxLength?: number;
}

const SimpleInputPage = ({
  title,
  description,
  placeholder,
  clue,
  value,
  error,
  onChange,
  onContinue,
  onBack,
  ...props
}: InputSimplePageProps): React.ReactElement<any, any> | null => {
  const onContinueCallback = useCallback(() => {
    if (onContinue && value) onContinue(value);
  }, [onContinue, value]);

  return (
    <FormWizard onContinue={onContinueCallback}>
      <WizardContainer>
        <TitleContainer>
          <Text tag="h1" size="lg">
            {title}
          </Text>
          {description && <SupportText size="md">{description}</SupportText>}
        </TitleContainer>
        <InputStyled
          type="text"
          value={value || ""}
          placeholder={placeholder}
          autoFocus
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = e.target;
            if (onChange) onChange(value, e);
          }}
          {...props}
        />
        <Centered>
          {error && <WizardTrans>{error as ValidationError}</WizardTrans>}
          {clue && <Clue text={clue} />}
        </Centered>
      </WizardContainer>
      <ActionsFooter continueDisabled={!value || !!error} onBack={onBack} />
    </FormWizard>
  );
};

export default SimpleInputPage;
const InputStyled = styled(LargeInput)`
  border: 1px solid ${(props) => (props.value === "" ? "red" : "#cdd6e2")};
`;
