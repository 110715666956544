import i18next from "i18next";
import { cloneDeep } from "lodash";
import { include } from "named-urls";
import * as Sentry from "@sentry/react";
import RankingOdsProjects from "panel/v3/pages/Admin/RankingOdsProjects";

export const originalRoutes = {
  home: "/",
  training: include("/trainamentos/", {
    self: "",
  }),
  ods: include("/ods/", {
    detail: ":code",
  }),
  user: "/usuario/:slug",
  action: include("/acao/:slug", {
    vacancy: "vaga/:vacancySlug",
    donation: "doacao/:donationID",
  }),
  vacancyView: "/vaga/:slug",
  invite: "/invite",
  register: {
    createOng: "/cadastro/projeto",
    //vacancies: "/vagas/:project/:action",
    //registerVacancies: "/cadastro/vaga/:project?/:action?/:back?",
    //editVacancie: "/editar/vaga/:action/:vaga/:back?",
  },
  //createAction: "/cadastro/acao/:projectSlug?",
  opportunities: include("/oportunidades/", {
    self: "",
    donation: "acao/:actionSlug/doacao/:donationID",
    vacancy: "acao/:actionSlug/vaga/:vacancySlug",
  }),
  fillProfile: "/completar-perfil",
  profileOng: "/perfil-ong/:slug",
  project: include("/projeto/:slug", {
    self: "",
    donation: "acao/:actionSlug/doacao/:donationID",
    vacancy: "acao/:actionSlug/vaga/:vacancySlug",
  }),
  about: "/sobre",
  contact: "/contato",
  terms: "/termos",
  faq: "/faq",
  // register: "/register",
  listActions: "/acoes",
  listActionsMap: "/acoes/mapa",
  listVacancyMap: "/vaga/:slugVacancy/mapa",
  listProject: "/projetos",
  userValidation: "/users/activation/:token",
  password: "/password",
  //affiliates: "/parceiros",
  customPages: include("/", {
    regulamento: "regulamento",
  }),
  help: "/ajuda",
  logout: "/logout",

  validate: include("/validacao", {
    curriculum: "curriculo/:code",
    certified: "certificado/:code",
  }),
  //listPrograms: "/programas",

  program: include("/", {
    self: "",
    volunteer: include("voluntario/", {
      inputAccessInfo: "info-acesso",
      validateEmail: "validar-email",
      acceptTerms: "termos",
      confirmAge: "confirmar-idade",
    }),
    action: include("acao/:slug", {
      self: "",
      vacancy: "vaga/:vacancySlug",
    }),
  }),

  oauth: include("/oauth/", {
    provider: include(":provider/", {
      return: "retorno",
    }),
  }),

  wizard: include("/wizard/", {
    self: "",
    selectProfileType: "",
    matchmaker: include("matchmaker/", {
      continue: "continuar",
      introduction: "introducao",
      name: "nome",
      work: "trabalho",
      abillities: "habilidades",
      causes: "causas",
      address: "endereco",
      /** @deprecated */
      confirmAge: "confirmar-idade",
      /** @deprecated */
      acceptTerms: "termos",
      /** @deprecated */
      accessMethod: "metodo-de-acesso",
      /** @deprecated */
      accessInfo: "informacoes-de-acesso",
      image: "foto",
      preparation: "preparacao",
      /** @deprecated */
      validateEmail: "validar-email",
      preferences: "preferencias",
    }),
    newProject: include("projeto/", {
      continue: "continuar",
      selectType: "tipo",
      inputName: "nome",
      inputDescription: "descricao",
      inputAddress: "endereco",
      inputProfileImage: "imagem",
      socialMedia: "redes-sociais",
      inputCoverImage: "capa",
      /** @deprecated */
      confirmAge: "confirmar-idade",
      /** @deprecated */
      acceptTerms: "termos",
      /** @deprecated */
      selectAccessMethod: "acesso",
      /** @deprecated */
      inputAccessInfo: "info-acesso",
      /** @deprecated */
      validateEmail: "validar-email",
      personalCauses: "causas",
      personalAbilities: "habilidades",
      personalWork: "trabalho",
    }),
    projectList: "projeto/lista",
    project: include(":projectSlug/", {
      selectProject: "selecione",
      socialNetworksPage: "redes-sociais",

      newAction: include("nova/", {
        continue: "continuar",
        inputName: "nome",
        selectFeatures: "modulos",
        descriptionAction: "descricao",
        causes: "causas",
        aboutAction: "sobre",
        coverImage: "imagem",
        pictures: "galeria",
      }),
      action: include(":actionSlug/", {
        infoSteps: "status",

        edit: include("editar/", {
          descriptionAction: "descricao",
          causes: "causas",
          aboutAction: "sobre",
          coverImage: "imagem",
          pictures: "galeria",
        }),

        donation: include("doacao/", {
          new: include("nova", {
            continue: "continuar",
            info: "info",
            items: "items",
            confirm: "confirm",
          }),
          edit: include(":materialId/", {
            info: "info",
            items: "items",
            confirm: "confirm",
          }),
        }),
        vacancy: include("vaga/", {
          new: include("nova/", {
            continue: "continuar",
            selectType: "tipo",
            info: "info",
            details: "detalhes",
            address: "endereco",
            cover: "imagem",
            date: "datas",
            hour: "horas",
          }),
          edit: include(":subscribeSlug?/", {
            selectType: "tipo",
            info: "info",
            details: "detalhes",
            address: "endereco",
            cover: "imagem",
            date: "datas",
            hour: "horas",
          }),
        }),

        summary: "resumo",
      }),
    }),
    profilePreparation: "preparacao-do-perfil",
    volunteer: include("voluntario/", {
      inputAccessInfo: "info-acesso",
      validateEmail: "validar-email",
      acceptTerms: "termos",
      confirmAge: "confirmar-idade",
    }),
  }),

  panel: include("/painel/", {
    self: "",
    forbidden: "acesso-negado",

    volunteer: include("voluntario", {
      self: "",
      subscribes: "inscricoes",
      curriculum: "curriculo",
      donations: "doacoes",
      detailsDonations: "doacoes/:materialRegistrationId",
      membershipCard: "carteirinha",
    }),

    reports: include("relatorios", {
      self: "",
      download: "download",
    }),

    validations: include("validacoes", {
      self: "",
      volunteers: include("voluntarios", {
        self: "",
        details: ":subscribeSlug/:volunteerId",
      }),
      projects: "projetos",
      actions: "acoes",
    }),

    donations: include("doacoes", {
      self: "",
      details: ":donationId",
    }),

    donationsDetails: ":donationId",

    checkout: include("checkouts", {
      self: "",
      details: ":actionSlug/:subscribeSlug/:volunteerId",
    }),

    certificates: include("certificados", {
      self: "",
      certificate: ":actionSlug/certificado",
    }),

    actions: include("acoes", {
      self: "",
      edit: ":actionSlug/editar",

      vaccancies: include(":actionSlug/vagas", {
        self: "",
      }),
    }),

    programs: include("programas", {
      self: "",
    }),

    tags: include("tracking", {
      self: "",
      actions: ":tag/acoes",
    }),

    summary: include("resumo/", {
      subscribeDetails: "vaga/:subscribe_slug",
    }),
    summaryEdit: ":projectSlug/:actionSlug/resumo",

    finish: ":projectSlug/:actionSlug/finalizar",

    project: include("projetos/", {
      self: "",
      edit: ":projectSlug/editar",
      validations: ":projectSlug/validacoes",

      actions: include(":projectSlug/acoes/", {
        self: "",
        edit: ":actionSlug/editar",
        //create: "nova",

        donations: include(":actionSlug/doacoes/:donationID", {
          self: "",
          edit: "editar",
        }),

        volunteers: include(":actionSlug/voluntarios", {
          self: "",
          detail: ":subscribeSlug/:volunteerId",
        }),

        vacancies: include(":actionSlug/vagas", {
          self: "",
          create: "nova",
          edit: ":vacancySlug/editar",
        }),
      }),
    }),

    settings: include("configuracoes", {
      general: "",
      preferences: "preferencias",
      notifications: "notificacoes",
      privacy: "privacidade",
      security: "seguranca",
      terms: "termos-de-uso",
      account: "conta",
    }),

    confirmAge: "confirmar-idade",
    acceptTerms: "termos-de-uso",
  }),

  panelBeta: include("/painel/beta/", {
    self: "",
    project: include("projeto/:slug/", {
      summary: "resumo",
      acoes: "acoes",
      self: "",
      edit: "editar/",
      validations: "validacoes",
      ambassador: include("ambassador", {
        self: "",
        main: "main",
      }),
      actionsOpportunities: include("acoes-e-oportunidades/", {
        self: "",
        details: "acao/:slugAction/",
        edit: "acao/:slugAction/:vacancySlug/editar",
        materialsEdit: "acao/:actionSlug/editar/materials/:donationID",
      }),
      actions: include("acoes/", {
        edit: ":actionSlug/editar",
      }),
      volunteers: "voluntarios/",
      appointments: "compromissos/",
      messages: include("mensagens/", {
        self: "",
        chat: "chat/:idMessage/",
      }),
      checkout: include("checkouts", {
        self: "",
        details: ":actionSlug/:subscribeSlug/:volunteerId",
      }),
    }),
    volunteer: include("voluntario/", {
      self: "",
      resume: "resumo",
      appointments: "compromissos/",
      certificate: "certificados",
      certificates: ":actionSlug/certificado",
      edit: "editar/",
      ambassador: include("ambassador", {
        self: "",
        main: "main",
      }),
      messages: include("mensagens/", {
        self: "",
        chat: "chat/:idMessage/",
      }),
    }),
    admin: include("admin/", {
      self: "",
      resume: "resumo",
      ambassador: include("ambassador", {
        self: "",
        manager: "manager",
        main: "main",
      }),
      tracking: include("tracking/", {
        self: "",
        details: ":slug/acoes",
      }),
      project: include("projeto/:slug/", {
        actionsOpportunities: include("acoes-e-oportunidades/", {
          self: "",
          details: "acao/:slugAction/",
          edit: "acao/:slugAction/:vacancySlug/editar",
          materialsEdit: "acao/:actionSlug/editar/materials/:donationID",
        }),
      }),
      programs: "programas",
      validations: include("validacoes/", {
        self: "",
        projectDetails: "projeto/:slug/",
        actionDetails: "acao/:slugAction/",
      }),
      platform: include("plataforma/", {
        self: "",
        register: include("cadastro/", {
          info: "informacoes/",
        }),
        edit: include("editar/:platformId/", {
          self: "",
          info: "informacoes/",
          content: "conteudo/",
          admin: "administradores/",
          customize: "personalizar/",
        }),
      }),
      reports: include("relatorios/", {
        self: "",
        reportsVolunteer: "voluntarios",
        reportsProjects: "projetos",
        reportsEngagement: "engajamento",
        reportsRanking: "ranking",
      }),
      details: include("details/", {
        self: "",
        region: include("region/", {
          self: "",
          project: include("project", {
            self: "",
          }),
          volunteers: include("volunteers", {
            self: "",
          }),
        }),
        volunteerometer: include("volunteerometer", {
          self: "",
        }),
      }),
      odsrankingprojects: include("odsrankingprojects", {
        self: "",
      }),
      odsrankingvolunteers: include("odsrankingvolunteers", {
        self: "",
      }),
      rankingprojects: include("rankingprojects", {
        self: "",
      }),
    }),
    notfound: "not-found",
  }),
  blog: include("/blog/", {
    self: "",
    post: include(":postSlug/", {
      self: "",
      donation: "acao/:actionSlug/doacao/:donationID",
      vacancy: "acao/:actionSlug/vaga/:vacancySlug",
    }),
    postPreview: "_post-preview/",
    category: "categorias/:categorySlug/",
  }),
};

let routes: typeof originalRoutes = cloneDeep(originalRoutes);

export const initRoutes = () => {
  const t = (key: string | undefined) =>
    (key ? i18next.t(`routes:${key.replaceAll(":", "--")}`) : "").replaceAll("--", ":");

  const translateRoutes = (routes: any, path?: string[]) => {
    const r = routes;
    const p: string[] = path ?? [];
    for (const key in routes) {
      if (typeof routes[key] === "object") {
        r[key] = translateRoutes(routes[key] as any, [routes[key]]);
        continue;
      }
      if (key === "toString") {
        r.toString = ((path: string) => {
          return () => {
            return path;
          };
        })(t(p.join("")));
        continue;
      }
      if (key) {
        const route = routes[key];
        if (route && key) {
          r[key] = t(route);
        } else {
          const err = new Error(`route:${key} does not exists.`);
          console.error(err);
          Sentry.captureException(err);
        }
      }
    }
    return r;
  };

  translateRoutes(routes);
};

export default routes;
