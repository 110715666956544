import React from "react";
import { rem } from "polished";
import styled, { css } from "styled-components/macro";
import Icon from "components/Icon/Icon";
import { useTranslation } from "react-i18next";
import SupportText from "./SupportText";

interface CheckProps {
  isOk: boolean;
}

export const PasswordGroup = styled.div<{ isOk: boolean }>`
  display: flex;

  svg {
    fill: #9facbd;
  }

  & > span {
    font-size: ${rem(11)};
    line-height: ${rem(18)};
    margin-left: 10px;
  }

  ${({ isOk, theme }) =>
    isOk &&
    css`
      svg {
        fill: ${theme.colors.primary};
      }
    `}
`;

export const Check = styled(({ ...props }) => <Icon type="check" {...props} />)`
  width: 10px;
`;

const MinLength: React.FC<CheckProps> = ({ isOk }) => {
  const { t } = useTranslation("components");
  return (
    <PasswordGroup isOk={isOk}>
      <Check type="check" />
      <SupportText>{t("newPasswordModal.validation.minLength")}</SupportText>
    </PasswordGroup>
  );
};

const Alphanumeric: React.FC<CheckProps> = ({ isOk }) => {
  const { t } = useTranslation("components");
  return (
    <PasswordGroup isOk={isOk}>
      <Check type="check" />
      <SupportText>{t("newPasswordModal.validation.alphanumeric")}</SupportText>
    </PasswordGroup>
  );
};

const CapitalLetter: React.FC<CheckProps> = ({ isOk }) => {
  const { t } = useTranslation("components");
  return (
    <PasswordGroup isOk={isOk}>
      <Check type="check" />
      <SupportText>{t("newPasswordModal.validation.capitalLetter")}</SupportText>
    </PasswordGroup>
  );
};

const SpecialCharacters: React.FC<CheckProps> = ({ isOk }) => {
  const { t } = useTranslation("components");
  return (
    <PasswordGroup isOk={isOk}>
      <Check type="check" />
      <SupportText>{t("newPasswordModal.validation.specialCharacter")}</SupportText>
    </PasswordGroup>
  );
};

const PasswordRules = {
  MinLength,
  Alphanumeric,
  CapitalLetter,
  SpecialCharacters,
};

const PasswordRulesTest = {
  minLength(newPassword: string): boolean {
    return newPassword.length > 7;
  },
  alphanumeric(newPassword: string): boolean {
    return /([0-9])+/.test(newPassword) && /([a-z])+/.test(newPassword);
  },
  capitalLetter(newPassword: string): boolean {
    return newPassword.match(/([A-Z])+/) !== null;
  },
  specialCharacters(newPassword: string): boolean {
    return /([!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])+/.test(newPassword);
  },
};

export default { Rules: PasswordRules, RulesTest: PasswordRulesTest };
