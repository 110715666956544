import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import styled, { withTheme, css } from "styled-components/macro";
import { rem } from "polished";
import { Colors } from "utils/types";
import mediaQueries from "utils/mediaQueries";
import { Theme } from "config/modules/styled";
import BaseModal from "components/FeedbackModal/BaseModal";
import Button from "components/Button/Button";

type WithoutLink = Exclude<Colors, "link">;

interface ModalConfirmationProps {
  title: string;
  message: ReactNode;
  buttonCancel?: string;
  buttonConfirm?: string;
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  actionsDisabled?: boolean;
  color?: WithoutLink;
  theme: Theme;
}

const ModalConfirmation: React.FC<ModalConfirmationProps> = ({
  title,
  message,
  buttonCancel,
  buttonConfirm,
  isOpen = false,
  onCancel,
  onConfirm,
  actionsDisabled,
  color,
  theme, // From styled-components
}) => {
  const { t } = useTranslation("components");

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={() => {
        if (onCancel) onCancel();
      }}
      icon={color ? "multiply" : "check"}
      color={color ? theme.colors[color] : theme.colors.primary}
    >
      <div>
        <Title color={color}>{title}</Title>
        <Message>{message}</Message>
      </div>
      <Flex>
        <Button disabled={actionsDisabled} color="tertiary" onClick={onCancel}>
          {buttonCancel || t("modalConfirmation.buttons.cancel")}
        </Button>
        <Button disabled={actionsDisabled} color={color || "primary"} onClick={onConfirm}>
          {buttonConfirm || t("modalConfirmation.buttons.confirm")}
        </Button>
      </Flex>
    </BaseModal>
  );
};

export default withTheme(ModalConfirmation);

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;

  & > button {
    width: 193px;
    height: 50px;
    font-size: ${rem(16)};
    line-height: ${rem(21)};
    font-weight: 500;
  }

  ${mediaQueries.sm(css`
    flex-direction: column-reverse;

    & > button {
      width: 100%;
      margin-bottom: 10px;
    }
  `)}
`;

const Title = styled.h1<{ color?: WithoutLink }>`
  font-size: ${rem(35)};
  color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.primary)};
  letter-spacing: ${rem(-0.37)};
  line-height: ${rem(50)};
  margin: 48px 0 10px;
`;

const Message = styled.p`
  color: ${({ theme }) => theme.colors.support_text};
  font-size: ${rem(18)};
  line-height: ${rem(22)};
`;
