import { createSlice, SliceCaseReducers, PayloadAction } from "@reduxjs/toolkit";
import { ListState, RequestableState } from "core/api/definitions";
import decoreThunk from "core/decorators/decorate";

import {
  ActionsOpportunities,
  ListActions,
  ActionData,
  CausesFilterResponse,
  StatusFilterResponse,
} from "core/api/projects/getActionsOpportunities";
import { getActionsOpportunities } from "./action";
import list from "core/decorators/list";
import requestLoading from "core/decorators/requestLoading";
import { logout } from "core/auth";

interface IState extends RequestableState {
  opportunities_data: {
    archived: Array<{
      action_data: ActionData;
      material_count: number;
      subscribe_count: number;
    }>;
    filters: {
      causes: CausesFilterResponse[];
      status: StatusFilterResponse;
    };
    no_archived: Array<{
      action_data: ActionData;
      material_count: number;
      subscribe_count: number;
    }>;
  };
}

const panelAdminActionsOpportunitiesSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "panel/projects/actionsOpportunities",
  initialState: {
    loading: "idle",
    opportunities_data: {
      archived: [],
      filters: {
        causes: [],
        status: {
          active: "",
          finished: "",
          pending: "",
          pending_project_validation: "",
          reject: "",
          reject_by_project: "",
        },
      },
      no_archived: [],
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, getActionsOpportunities, [
      requestLoading(),
      {
        pending: (state: IState) => {
          state.loading = "loading";
        },

        fulfilled: (state: IState, action: PayloadAction<ActionsOpportunities>) => {
          state.loading = "ok";
          if (action?.payload?.response?.opportunities_data) {
            state.opportunities_data = action.payload.response.opportunities_data;
          }
        },
        rejected: (state: IState) => {
          state.loading = "error";
        },
      },
    ]);

    builder.addCase(logout.toString(), (state) => {
      state.loading = "idle";
      //state.opportunities_data = {};
    });
  },
});

export default panelAdminActionsOpportunitiesSlice;
