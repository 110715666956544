import http from "config/http";
import { Contact, ResponseStatus, ResponseWithParams } from "core/api/definitions";

export interface AdministratorsUpdate {
  name: string;
  email: string;
  contacts: Contact[];
}

export interface PlatformAdministratorsUpdateRequest {
  platformId: string;
  administrators: AdministratorsUpdate[];
}
export interface PlataformAdministratorsUpdateResponseData {}

export interface PlataformAdministratorsUpdateResponse
  extends ResponseWithParams<ResponseStatus, PlataformAdministratorsUpdateResponseData> {}

const update = ({ platformId, ...data }: PlatformAdministratorsUpdateRequest) =>
  http.patch<PlataformAdministratorsUpdateResponse>(`/platform-launcher/${platformId}/administrators`, data);

export default update;
