import { AxiosResponse } from "axios";
import http from "config/http";

import { Address, Response, ResponseWithParams } from "../definitions";

export interface SubRegId {
  subscribe_registration_id: string;
}

export interface ModalDetailsVolunteer {
  action_data: {
    title: string;
    _id: string;
  };
  project_data: {
    _id: string;
  };
  subscribe_data: {
    address: Address;
    description: string;
    function_title: string;
    title: string;
    _id: string;
  };
  subscribe_registration: {
    day: string;
    time_end: string;
    time_start: string;
    _id: string;
    status: string;
  };
}

export type ListAppointmentModal = ResponseWithParams<Response, ModalDetailsVolunteer>;
export default async ({
  subscribe_registration_id,
}: {
  subscribe_registration_id: string;
}): Promise<AxiosResponse<ListAppointmentModal>> =>
  await http.get<ListAppointmentModal>(`/users/list-details-appointments/${subscribe_registration_id}`);
