import { createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import {
  PlataformAdministratorsRemoveResponse,
  PlatformAdministratorsRemoveRequest,
} from "core/api/admin/platforms/administrators/remove";
import {
  PlatformAdministratorsShowRequest,
  PlatformAdministratorsShowResponse,
  SubplatformAdministrator,
} from "core/api/admin/platforms/administrators/show";
import {
  PlataformAdministratorsUpdateResponse,
  PlatformAdministratorsUpdateRequest,
} from "core/api/admin/platforms/administrators/update";
import { RequestableState } from "core/api/definitions";
import decoreThunk from "core/decorators/decorate";

import { platformAdministratorsRemove, platformAdministratorsShow, platformAdministratorsUpdate } from "./actions";

export interface Administrators extends Partial<RequestableState> {
  administrators?: SubplatformAdministrator[];
}

interface IState extends RequestableState {
  platforms: {
    [k: string]: Administrators;
  };
}

const panelBetaAdministratorsSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "panelBetaAdministrators",
  initialState: {
    loading: "idle",
    platforms: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, platformAdministratorsShow, [
      {
        pending: (
          state,
          action: PayloadAction<PlatformAdministratorsShowResponse, string, { arg: PlatformAdministratorsShowRequest }>
        ) => {
          const { platformId } = action.meta.arg;

          if (!platformId) return;

          state.platforms![platformId] = {
            administrators: [],
            loading: "loading",
          };
        },
        fulfilled: (
          state,
          action: PayloadAction<PlatformAdministratorsShowResponse, string, { arg: PlatformAdministratorsShowRequest }>
        ) => {
          const { platformId } = action.meta.arg;

          if (!platformId) return;

          state.platforms![platformId] = {
            administrators: action.payload?.params || [],
            loading: "ok",
          };
        },
        rejected: (
          state,
          action: PayloadAction<PlatformAdministratorsShowResponse, string, { arg: PlatformAdministratorsShowRequest }>
        ) => {
          const { platformId } = action.meta.arg;

          if (!platformId) return;

          state.platforms![platformId] = {
            administrators: [],
            loading: "error",
          };
        },
      },
    ]);

    decoreThunk(builder, platformAdministratorsUpdate, [
      {
        pending: (
          state,
          action: PayloadAction<
            PlataformAdministratorsUpdateResponse,
            string,
            { arg: PlatformAdministratorsUpdateRequest }
          >
        ) => {
          const { platformId } = action.meta.arg;

          if (!platformId) return;

          state.platforms![platformId].loading = "loading";
        },
        fulfilled: (
          state,
          action: PayloadAction<
            PlataformAdministratorsUpdateResponse,
            string,
            { arg: PlatformAdministratorsUpdateRequest }
          >
        ) => {
          const { platformId } = action.meta.arg;

          if (!platformId) return;

          state.platforms![platformId].loading = "ok";
        },
        rejected: (
          state,
          action: PayloadAction<
            PlataformAdministratorsUpdateResponse,
            string,
            { arg: PlatformAdministratorsUpdateRequest }
          >
        ) => {
          const { platformId } = action.meta.arg;

          if (!platformId) return;

          state.platforms![platformId].loading = "error";
        },
      },
    ]);

    decoreThunk(builder, platformAdministratorsRemove, [
      {
        pending: (
          state,
          action: PayloadAction<
            PlataformAdministratorsRemoveResponse,
            string,
            { arg: PlatformAdministratorsRemoveRequest }
          >
        ) => {
          const { platformId } = action.meta.arg;

          if (!platformId) return;

          state.platforms![platformId].loading = "loading";
        },
        fulfilled: (
          state,
          action: PayloadAction<
            PlataformAdministratorsUpdateResponse,
            string,
            { arg: PlatformAdministratorsRemoveRequest }
          >
        ) => {
          const { platformId, administrator_id } = action.meta.arg;

          if (!platformId) return;

          const administrators = state.platforms![platformId]?.administrators || [];

          state.platforms![platformId].administrators = administrators.filter((item) =>
            item?.administrator_id ? item.administrator_id !== administrator_id : true
          );
          state.platforms![platformId].loading = "ok";
        },
        rejected: (
          state,
          action: PayloadAction<
            PlataformAdministratorsUpdateResponse,
            string,
            { arg: PlatformAdministratorsRemoveRequest }
          >
        ) => {
          const { platformId } = action.meta.arg;

          if (!platformId) return;

          state.platforms![platformId].loading = "error";
        },
      },
    ]);
  },
});

export default panelBetaAdministratorsSlice;
