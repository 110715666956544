import { createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import { ShowMaterialsResponse } from "core/api/actions/donation/showMaterial";
import { LoadingStatus, MaterialList, MetaArg } from "core/api/definitions";
import { AssignDonationRequest, AssignDonationResponse } from "core/api/donations/assign";
import { donationAssign } from "core/api/site/actions";
import { donationActions } from "core/api/site/donations/donations_actions";
import decoreThunk from "core/decorators/decorate";
import requestLoading from "core/decorators/requestLoading";

interface Map<T> {
  [key: string]: T;
}

interface IState {
  loading: LoadingStatus;
  cache: {
    materials: Map<MaterialList>;
  };
}

const cardsSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "siteCards",
  initialState: {
    loading: "idle",
    cache: {
      materials: {},
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, donationActions.showMaterials, [
      requestLoading(),
      {
        fulfilled: (state, action: PayloadAction<ShowMaterialsResponse>) => {
          const donation =
            action.payload?.params && action.payload.params?.length > 0 ? action.payload?.params[0] : undefined;
          if (!donation) return;
          state.cache.materials[donation._id] = donation;
        },
      },
    ]);
    decoreThunk(builder, donationAssign, [
      {
        fulfilled: (state, action: PayloadAction<AssignDonationResponse, string, MetaArg<AssignDonationRequest>>) => {
          if (!state.cache.materials[action.meta.arg.material_id]) return;

          state.cache.materials[action.meta.arg.material_id].items.forEach((item) => {
            if (action.meta.arg.material_list_items_id.includes(item._id)) {
              item.status = "pending";
            }
          });
        },
      },
    ]);
  },
});

export default cardsSlice;
