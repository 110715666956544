import React, { useCallback, useMemo, useEffect } from "react";
import { PageEvents } from "../definitions/commonTypes";
import { useTranslation } from "react-i18next";
import { BaseTitle } from "components/common/BaseTitle";
import SupportText from "components/common/SupportText";
import styled from "styled-components/macro";
import { Input } from "components/register/Form";
import ActionsFooter from "wizard/v3/components/Layout/ActionsFooter";
import FormWizard from "../common/FormWizard";
import SupportFeedback from "components/common/SupportFeedback";
import { Interest } from "pages/ListActions/DataOfFilters";
import { useReduxSelector } from "hooks/useReduxSelector";
import { LargeButton, Centered } from "wizard/v3/components/common/styles";
import { create } from "core/wizard/create/subscribe";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { WizardContainer } from "wizard/v3/components/Layout/Wizard";
import { TitleContainer } from "wizard/v3/components/Layout/Content";
import WizardTrans from "wizard/v3/components/WizardTrans/WizardTrans";
import { ValidationError, ValidationErrorSet } from "core/api/definitions";
import { Helmet } from "react-helmet";

export type VacancyInfo = {
  title: string;
  function: string;
  ability?: Interest;
  amount: number;
};

const VACANCY_INFO = {
  title: "",
  function: "",
  amount: 0,
};

interface VacancyInfoPageProps extends PageEvents<VacancyInfo, VacancyInfo> {
  vacancyInfo?: VacancyInfo;
  disableTitle?: boolean;
}

const VacancyInfoPage: React.FC<VacancyInfoPageProps> = ({
  vacancyInfo,
  disableTitle,
  onBack,
  onContinue,
  onChange,
  error,
}) => {
  const { t } = useTranslation();

  const { actionSlug } = useParams<{ actionSlug: string }>();

  const dispatch = useDispatch();

  const { functions, interests, isSupportDataLoading, isSupportDataError } = useReduxSelector((state) => ({
    functions: state.supportData.functions,
    interests: state.supportData.interests,
    isSupportDataLoading:
      state.supportData.functionsLoading === "loading" || state.supportData.interestsLoading === "loading",
    isSupportDataError:
      state.supportData.functionsLoading === "error" || state.supportData.interestsLoading === "error",
  }));

  // Retrieve data for creating the form.
  useEffect(() => {
    dispatch(create({ action: actionSlug }));
  }, [dispatch, actionSlug]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    if (onChange) onChange({ ...(vacancyInfo || VACANCY_INFO), [name]: value });
  };

  const canContinue = useMemo(
    () =>
      vacancyInfo &&
      vacancyInfo.ability &&
      vacancyInfo.amount > 0 &&
      vacancyInfo.function !== "" &&
      vacancyInfo.title !== "",
    [vacancyInfo]
  );

  const onContinueCallback = useCallback(() => {
    if (onContinue && vacancyInfo) onContinue(vacancyInfo);
  }, [onContinue, vacancyInfo]);

  return (
    <FormWizard onContinue={onContinueCallback}>
      <WizardContainer>
        <TitleContainer>
          <Helmet title={t("wizard.pages.vacancyInfo.title")} />
          <BaseTitle size="md">{t("wizard.pages.vacancyInfo.title")}</BaseTitle>
          <SupportText size="md">{t("wizard.pages.vacancyInfo.description")}</SupportText>
        </TitleContainer>
        {!isSupportDataError && !isSupportDataLoading && (
          <CustomFormContainer id="aboutContainer">
            <Input
              type="text"
              name="title"
              defaultValue={vacancyInfo?.title}
              disabled={disableTitle}
              autoFocus={!disableTitle}
              label={t("wizard.pages.vacancyInfo.form.vacancyTitle.label")}
              placeholder={t("wizard.pages.vacancyInfo.form.vacancyTitle.placeholder")}
              required
              invalid={!!(error as ValidationErrorSet)?.title}
              onChange={handleChange}
            />
            <WizardTrans textAlign="left">{(error as ValidationErrorSet)?.title as ValidationError}</WizardTrans>
            <Input
              type="select"
              name="function"
              defaultValue={vacancyInfo?.function || ""}
              label={t("wizard.pages.vacancyInfo.form.function.label")}
              required
              onChange={handleChange}
              autoFocus={!!disableTitle}
              invalid={!!(error as ValidationErrorSet)?.function_id}
            >
              <option value="">{t("wizard.pages.vacancyInfo.form.function.placeholder")}</option>
              {functions &&
                functions.map((opt) => (
                  <option key={opt._id} value={opt._id}>
                    {opt.title}
                  </option>
                ))}
            </Input>
            <WizardTrans textAlign="left">{(error as ValidationErrorSet)?.function_id as ValidationError}</WizardTrans>
            <Input
              type="select"
              name="ability"
              defaultValue={vacancyInfo?.ability || ""}
              label={t("wizard.pages.vacancyInfo.form.ability.label")}
              required
              onChange={handleChange}
              invalid={!!(error as ValidationErrorSet)?.["conditionals.interests"]}
            >
              <option value="">{t("wizard.pages.vacancyInfo.form.ability.placeholder")}</option>
              {interests &&
                interests.map((opt) => (
                  <option key={opt._id} value={opt.slug}>
                    {opt.description}
                  </option>
                ))}
            </Input>
            <WizardTrans textAlign="left">
              {(error as ValidationErrorSet)?.["conditionals.interests"] as ValidationError}
            </WizardTrans>
            <Input
              type="number"
              name="amount"
              defaultValue={vacancyInfo?.amount === 0 ? "" : vacancyInfo?.amount}
              min={0}
              label={t("wizard.pages.vacancyInfo.form.vacancyTotal.label")}
              placeholder={t("wizard.pages.vacancyInfo.form.vacancyTotal.placeholder")}
              required
              hideIcon={true}
              onChange={handleChange}
              invalid={!!(error as ValidationErrorSet)?.["time_configurations.quantity"]}
            />
            <WizardTrans textAlign="left">
              {(error as ValidationErrorSet)?.["time_configurations.quantity"] as ValidationError}
            </WizardTrans>
          </CustomFormContainer>
        )}
        {isSupportDataError && (
          <Centered>
            <SupportFeedback>Ocorreu um erro ao carregar o formulário.</SupportFeedback>
            <br />
            <LargeButton
              onClick={() => {
                dispatch(create({ action: actionSlug }));
              }}
            >
              Tentar novamente
            </LargeButton>
          </Centered>
        )}
      </WizardContainer>
      <ActionsFooter
        onBack={onBack}
        continueDisabled={isSupportDataError || isSupportDataLoading || !canContinue}
        onContinue={onContinueCallback}
      />
    </FormWizard>
  );
};

export default VacancyInfoPage;

const CustomFormContainer = styled.section`
  max-width: 400px;
  margin: 0 auto;

  & > span {
    margin-bottom: 20px;
  }

  & > div {
    width: 100%;

    input[type="number"] {
      padding: 20px;
    }
  }
`;
