import React, { Fragment } from "react";
import Filter, { FilterProps } from "./Filter";
import SearchBase from "./SearchBase";
interface SearchWithFilterProps extends FilterProps {
  defaultTerm?: string;
  onSearch?(value: string): void;
}

const SearchWithFilter: React.FC<SearchWithFilterProps> = ({ defaultTerm, onSearch, ...props }) => (
  <Fragment>
    <SearchBase defaultValue={defaultTerm} onSearch={onSearch} />
    <Filter {...props} />
  </Fragment>
);

export default SearchWithFilter;
