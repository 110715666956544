import { AxiosResponse } from "axios";
import http from "config/http";
import { ActionResponse } from "core/api/definitions";

export interface createTagRequest {
  actions_id: string[];
  slugTag: string;
}

export type createTagResponse = ActionResponse;

const addActionOnTag = async ({ actions_id, slugTag }: createTagRequest): Promise<AxiosResponse<createTagResponse>> => {
  return http.post<createTagResponse>(`/admin/tags/${slugTag}/actions`, { actions_id });
};

export default addActionOnTag;
