import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet";
import { mq } from "utils/mediaQueries";

interface PanelBaseProps {
  title: string;
}

const ConfirmBase: React.FC<PanelBaseProps> = ({ title, children }): JSX.Element => {
  return (
    <>
      <Container>
        <Helmet title={title} />
        <ConfirmBody>{children}</ConfirmBody>
      </Container>
    </>
  );
};

export default ConfirmBase;

export const ConfirmMain = ({ ...props }) => {
  return <Main {...props} />;
};

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
`;

const ConfirmBody = styled.main``;

const Main = styled.div`
  max-width: 560px;
  border: 1px solid #c5ceda;
  border-radius: 8px;
  padding: 70px 72px;

  ${mq.smDown} {
    padding: 70px 55px;
  }
  ${mq.xsDown} {
    padding: 70px 15px;
  }
`;
