import { AxiosResponse } from "axios";
import http from "config/http";
import { Response, ResponseStatus } from "../definitions";

interface UpdateSocialNetworksRequest {
  slug: string;
  facebook?: string;
  instagram?: string;
  pinterest?: string;
  website?: string;
  youtube?: string;
}

export type UpdateSocialNetworkResponse = Response<ResponseStatus>;

const updateSocialNetworks = async ({
  slug,
  ...socialNetworks
}: UpdateSocialNetworksRequest): Promise<AxiosResponse<UpdateSocialNetworkResponse>> =>
  http.put<UpdateSocialNetworkResponse>(`/projects/${slug}/socialNetworks`, {
    social_network: socialNetworks,
  });

export default updateSocialNetworks;
