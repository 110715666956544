import React from "react";
import styled, { css } from "styled-components/macro";
import { CardCol } from "wizard/v3/components/Card";

interface CardAnimateProps {
  selected?: boolean;
}

const CardAnimate: React.FC<CardAnimateProps> = ({ children, selected }) => {
  return (
    <Animate md={3} sm={6} xs={12} selected={selected}>
      <InnerAnimate>{children}</InnerAnimate>
    </Animate>
  );
};

const InnerAnimate = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;

  .front,
  .back {
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 30px;
    left: 0;
    right: 0;
    width: 100%;
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .back {
    color: white;
    transform: rotateY(180deg);
  }
`;

const Animate = styled(CardCol)<{ selected?: boolean }>`
  background-color: transparent;
  perspective: 1000px;
  z-index: 4;

  ${({ selected }) =>
    selected &&
    css`
      ${InnerAnimate} {
        transform: rotateY(180deg);
      }
    `}
`;

export default CardAnimate;
