import { ListWithoutPaginationResponse } from "core/api/definitions";
import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { ActionHandler, ListWithoutPaginationState } from "core/api/definitions";

// list implements handling listing by using.
const listWithoutPagination = <
  T,
  State extends ListWithoutPaginationState<T>,
  Action extends PayloadAction<ListWithoutPaginationResponse<T>> & { meta: { arg: any } }
>(): ActionHandler<State, Action> => ({
  pending: (state: Draft<State>, action: Action) => {
    state.loading = "loading";
  },
  fulfilled: (state: Draft<State>, action: Action) => {
    state.loading = "ok";
    state.list = action.payload.params as Draft<T>[];
  },
  rejected: (state: Draft<State>, action: Action) => {
    state.loading = "error";
    // TODO(Jota): How are we going to handle exceptions?
    // alert((action.payload as ErrorResponse).response?.code + ":" + (action.payload as ErrorResponse).response?.message);
  },
});

export default listWithoutPagination;
