import { AxiosResponse } from "axios";
import http from "config/http";

interface SignUpGmailRequest {
  url: string;
}

interface SignUpGmailResponse {
  url_token: string;
}

const signUpGmailUrl = async ({ url }: SignUpGmailRequest): Promise<AxiosResponse<SignUpGmailResponse>> =>
  http.post<SignUpGmailResponse>(`/auth/gmail/signup`, { url, action: "signup" });

export default signUpGmailUrl;
