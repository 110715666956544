import styled, { css } from "styled-components/macro";
import { darken } from "polished";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle as RSDropdownToggle,
  UncontrolledDropdown as RSUncontrolledDropdown,
  Dropdown as RSDropdown,
} from "reactstrap";

export { DropdownItem, DropdownMenu };

export const DropdownToggle = styled(RSDropdownToggle)`
  position: relative;
  width: 100%;
  border: 1px solid #bcc4de;
  border-radius: 5px;
  background-color: #f9fafc;
  line-height: 18px;
  font-size: 13px;
  color: ${(props) => props.theme.colors.support_text};
  text-align: left;
  height: 40px;
  padding: 11.15px 18.82px 11.45px;
  font-weight: 400;

  svg {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 12px;
    fill: #bcc4de;
  }

  &:hover {
    color: ${(props) => darken(0.1, props.theme.colors.support_text)};

    svg {
      fill: ${(props) => darken(0.1, props.theme.colors.support_text)};
    }
  }
`;

const dropdownCss = css`
  .dropdown-item {
    font-size: 15px;
    color: ${(props) => props.theme.colors.support_text};
    height: 40px;
    line-height: 24px;

    &:hover {
      background-color: #f9fafc;
    }
  }

  .dropdown-menu {
    padding: 0;
    margin: 0;
    width: 100%;
    border: 1px solid #bcc4de;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 6px 20px 0 rgba(13, 51, 32, 0.1);
  }

  &.show {
    ${DropdownToggle} {
      border-radius: 4px 4px 0 0;
      border-bottom-color: transparent;
      background-color: #ffffff;
    }
    .dropdown-menu {
      border-radius: 0 0 4px 4px;
      border-top-color: #eef0f8;
    }
  }
`;

export const Dropdown = styled(RSDropdown)`
  ${dropdownCss}
`;

export const UncontrolledDropdown = styled(RSUncontrolledDropdown)`
  ${dropdownCss}
`;
