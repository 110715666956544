import { AxiosResponse } from "axios";
import http from "config/http";
import { ResponseWithParams, Response } from "core/api/definitions";

export interface AdminOverviewV2 {
  params: {
    projects: {
      total: number;
      value: number;
      regions: {
        norte: number;
        nordeste: number;
        centro: number;
        sudeste: number;
        sul: number;
      };
    };
  };
}

export interface ResponseStatus {
  code: number;
  message: string;
}

export type AdminOverviewResponseV2 = ResponseWithParams<ResponseStatus, AdminOverviewV2>;

export default async (): Promise<AxiosResponse<AdminOverviewResponseV2>> =>
  http.get<AdminOverviewResponseV2>(`admin/dashboard/statistics`);
