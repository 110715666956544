import Loading from "components/Loading/Loading";
import usePlatformLanguage from "hooks/usePlatformLanguage";
import React, { Suspense } from "react";

const TermsOfUse = (): JSX.Element => {
  const { language } = usePlatformLanguage();

  let Component: any = () => <Loading />;

  switch (language.toLowerCase()) {
    case "pt-br":
      Component = React.lazy(() => import("./TransformaBrasil"));
      break;
    case "pt-pt":
      Component = React.lazy(() => import("./TransformaPortugal"));
      break;
    case "es-cl":
      Component = React.lazy(() => import("./TransformaChile"));
      break;
  }

  return (
    <Suspense fallback={<Loading />}>
      <Component />
    </Suspense>
  );
};

export default TermsOfUse;
