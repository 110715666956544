import useCountry from "hooks/useCountry";
import useTranslationV3 from "hooks/useTranslationV3";
import {
  Cause,
  Filter as FilterInterface,
  filtersData,
  filtersDataChile,
  Interest,
  Option,
  Skill,
  State,
} from "pages/ListActions/DataOfFilters";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { transition } from "utils/animation";
import mqV3 from "utils/mediaQueriesV3";
import ButtonBase from "v3/components/Button/ButtonBase";
import Icon, { IconProps } from "../Icons/Icon";
import Checkbox from "../Checkbox/Checkbox";
import { chunk, orderBy } from "lodash";
import { useMediaQueriesV3 } from "utils/hooks/useMediaQueriesV3";

interface Filters {
  causes?: Cause[];
  abilities?: Interest[];
  functions?: Skill[];
  states?: State[];
}

interface RotatableIconProps extends IconProps {
  isOpen: boolean;
}

export type RemoveFilterOptionType = (filters: FilterInterface[]) => FilterInterface[];

export type InitialFilters = Filters;

export interface FilterProps {
  className?: string;
  handleCheckbox?: (value: boolean, e: React.ChangeEvent<HTMLInputElement>) => void;
  onSelected(el: Option[], searchType: SearchType): void;
  onApply?(): void;
  onClean(searchType?: SearchType): void;
  removeFilterOption?: RemoveFilterOptionType;
  initialFilters?: InitialFilters;
}

export type SearchType = "causes" | "functions" | "abilities" | "availability" | "states";

export const applyInitialFilters = (data?: InitialFilters, isChile?: boolean) => {
  if (!data) {
    if (isChile) {
      return filtersData;
    } else {
      return filtersDataChile;
    }
  }

  const handleOptions = (id: string, filters?: (Cause | Interest | Skill | State)[]) => {
    const filter = filtersData.find((f) => f.id === id);
    if (filter && filters && filters?.length) {
      const opts = filter.options.map((opt) =>
        filters.find((c) => c === opt.value) ? { ...opt, checked: true } : opt
      );
      return { ...filter, options: opts };
    }

    const filterChile = filtersDataChile.find((f) => f.id === id);
    if (filterChile && filters && filters?.length) {
      const opts = filterChile.options.map((opt) =>
        filters.find((c) => c === opt.value) ? { ...opt, checked: true } : opt
      );
      return { ...filterChile, options: opts };
    }

    if (isChile) {
      return filterChile;
    } else {
      return filter;
    }

    return filter;
  };

  const newFilterData = [];

  newFilterData.push(handleOptions("causes", data.causes));
  newFilterData.push(handleOptions("abilities", data.abilities));
  newFilterData.push(handleOptions("functions", data.functions));
  newFilterData.push(handleOptions("states", data.states));
  newFilterData.push(filtersData.find((f) => f.id === "availability"));

  return newFilterData as FilterInterface[];
};

const Filter: React.FC<FilterProps> = ({
  onSelected,
  onApply,
  onClean,
  initialFilters,
  removeFilterOption,
  children,
  className,
  handleCheckbox,
}) => {
  const { t } = useTranslationV3();
  const { iso3166_1 } = useCountry();

  const initialStateMemorized = useMemo(
    () =>
      removeFilterOption
        ? removeFilterOption(applyInitialFilters(initialFilters, iso3166_1 === "cl"))
        : applyInitialFilters(initialFilters, iso3166_1 === "cl"),
    [initialFilters, removeFilterOption]
  );

  const [mostrarFiltros, setMostrarFiltros] = useState(false);

  const [filters, setFilters] = useState(initialStateMemorized);
  const [isOpenCauses, setIsOpenCauses] = useState(false);
  const [isOpenFunctions, setIsOpenFunctions] = useState(false);
  const [isOpenAbilities, setIsOpenAbilities] = useState(false);
  const [isOpenStates, setIsOpenStates] = useState(false);
  const [isOpenAvailability, setIsOpenAvailability] = useState(false);
  const [isOpenInterest, setIsOpenInterest] = useState(false);
  const [filtersIsOpen, setFiltersIsOpen] = useState<SearchType | undefined>();
  const [activeFilter, setActiveFilter] = useState<
    "causes" | "functions" | "abilities" | "availability" | "interest" | "states" | ""
  >("");

  useEffect(() => {
    setIsOpenCauses(activeFilter === "causes");
    setIsOpenFunctions(activeFilter === "functions");
    setIsOpenAbilities(activeFilter === "abilities");
    setIsOpenAvailability(activeFilter === "availability");
    setIsOpenInterest(activeFilter === "interest");
    setIsOpenStates(activeFilter === "states");
  }, [activeFilter]);

  const causesOptionsMemorized = useMemo(() => filters.find((d) => d.id === "causes"), [filtersIsOpen, filters]);
  const functionsOptionsMemorized = useMemo(() => filters.find((d) => d.id === "functions"), [filtersIsOpen, filters]);
  const abilitiesOptionsMemorized = useMemo(() => filters.find((d) => d.id === "abilities"), [filtersIsOpen, filters]);
  const statesOptionsMemorized = useMemo(() => filters.find((d) => d.id === "states"), [filtersIsOpen, filters]);
  const availabilityOptionsMemorized = useMemo(() => filters.find((d) => d.id === "availability"), [
    filtersIsOpen,
    filters,
  ]);

  const onSelectedCauses = useCallback(
    (el: Option) => {
      if (!causesOptionsMemorized) return;
      const checkeds = causesOptionsMemorized?.options.filter(
        (opt) => (opt.checked && opt.value !== el.value) || (opt.checked && el?.param !== opt?.param)
      );
      const opts = causesOptionsMemorized?.options.filter((opt) => opt.value !== el.value || opt?.param !== el?.param);
      const isChecked = !el.checked;
      const newChecked = { ...el, checked: isChecked };

      if (isChecked) {
        onSelected([...(checkeds || []), newChecked], filtersIsOpen!);
      } else {
        onSelected(checkeds, filtersIsOpen!);
      }

      setFilters((prev) => {
        const nFilters = prev.filter((f) => f.id !== filtersIsOpen);
        return [...nFilters, { ...causesOptionsMemorized, options: [...opts, newChecked] }];
      });
    },
    [causesOptionsMemorized, filtersIsOpen, onSelected]
  );

  const onSelectedFunctions = useCallback(
    (el: Option) => {
      if (!functionsOptionsMemorized) return;
      const checkeds = functionsOptionsMemorized?.options.filter(
        (opt) => (opt.checked && opt.value !== el.value) || (opt.checked && el?.param !== opt?.param)
      );
      const opts = functionsOptionsMemorized?.options.filter(
        (opt) => opt.value !== el.value || opt?.param !== el?.param
      );
      const isChecked = !el.checked;
      const newChecked = { ...el, checked: isChecked };

      if (isChecked) {
        onSelected([...(checkeds || []), newChecked], filtersIsOpen!);
      } else {
        onSelected(checkeds, filtersIsOpen!);
      }

      setFilters((prev) => {
        const nFilters = prev.filter((f) => f.id !== filtersIsOpen);
        return [...nFilters, { ...functionsOptionsMemorized, options: [...opts, newChecked] }];
      });
    },
    [functionsOptionsMemorized, filtersIsOpen, onSelected]
  );

  const onSelectedAvailability = useCallback(
    (el: Option) => {
      if (!availabilityOptionsMemorized) return;
      const checkeds = availabilityOptionsMemorized?.options.filter(
        (opt) => (opt.checked && opt.value !== el.value) || (opt.checked && el?.param !== opt?.param)
      );
      const opts = availabilityOptionsMemorized?.options.filter(
        (opt) => opt.value !== el.value || opt?.param !== el?.param
      );
      const isChecked = !el.checked;
      const newChecked = { ...el, checked: isChecked };

      if (isChecked) {
        onSelected([...(checkeds || []), newChecked], filtersIsOpen!);
      } else {
        onSelected(checkeds, filtersIsOpen!);
      }

      setFilters((prev) => {
        const nFilters = prev.filter((f) => f.id !== filtersIsOpen);
        return [...nFilters, { ...availabilityOptionsMemorized, options: [...opts, newChecked] }];
      });
    },
    [availabilityOptionsMemorized, filtersIsOpen, onSelected]
  );

  const onSelectedAbilities = useCallback(
    (el: Option) => {
      if (!abilitiesOptionsMemorized) return;
      const checkeds = abilitiesOptionsMemorized?.options.filter(
        (opt) => (opt.checked && opt.value !== el.value) || (opt.checked && el?.param !== opt?.param)
      );
      const opts = abilitiesOptionsMemorized?.options.filter(
        (opt) => opt.value !== el.value || opt?.param !== el?.param
      );
      const isChecked = !el.checked;
      const newChecked = { ...el, checked: isChecked };

      if (isChecked) {
        onSelected([...(checkeds || []), newChecked], filtersIsOpen!);
      } else {
        onSelected(checkeds, filtersIsOpen!);
      }

      setFilters((prev) => {
        const nFilters = prev.filter((f) => f.id !== filtersIsOpen);
        return [...nFilters, { ...abilitiesOptionsMemorized, options: [...opts, newChecked] }];
      });
    },
    [abilitiesOptionsMemorized, filtersIsOpen, onSelected]
  );

  const onSelectedStates = useCallback(
    (el: Option) => {
      if (!statesOptionsMemorized) return;
      const checkeds = statesOptionsMemorized?.options.filter(
        (opt) => (opt.checked && opt.value !== el.value) || (opt.checked && el?.param !== opt?.param)
      );
      const opts = statesOptionsMemorized?.options.filter((opt) => opt.value !== el.value || opt?.param !== el?.param);
      const isChecked = !el.checked;
      const newChecked = { ...el, checked: isChecked };

      if (isChecked) {
        onSelected([...(checkeds || []), newChecked], filtersIsOpen!);
      } else {
        onSelected(checkeds, filtersIsOpen!);
      }

      setFilters((prev) => {
        const nFilters = prev.filter((f) => f.id !== filtersIsOpen);
        return [...nFilters, { ...statesOptionsMemorized, options: [...opts, newChecked] }];
      });
    },
    [statesOptionsMemorized, filtersIsOpen, onSelected]
  );

  const countMemorized = useMemo(
    () => ({
      causes: filters.find((el) => el.id === "causes")?.options.filter((opt) => opt.checked).length,
      functions: filters.find((el) => el.id === "functions")?.options.filter((opt) => opt.checked).length,
      abilities: filters.find((el) => el.id === "abilities")?.options.filter((opt) => opt.checked).length,
      states: filters.find((el) => el.id === "states")?.options.filter((opt) => opt.checked).length,
      availability: filters.find((el) => el.id === "availability")?.options.filter((opt) => opt.checked).length,
    }),
    [filters]
  );

  const onCleanCallback = useCallback(() => {
    let cleanFilter = filtersData;
    if (iso3166_1 === "cl") cleanFilter = filtersDataChile;

    setFilters(cleanFilter);
    onClean();
  }, [onClean]);

  const { isSM, isXS } = useMediaQueriesV3();

  const resizeCallback = useCallback(
    (lengthOfArray: number) => {
      if (lengthOfArray < 10) return lengthOfArray;
      if (isSM) return Math.ceil(lengthOfArray / 2);
      if (isXS) return lengthOfArray / 1;
      return Math.ceil(lengthOfArray / 3);
    },
    [isSM, isXS]
  );
  const onApplyCallback = useCallback(() => {
    if (onApply) onApply();
    setFiltersIsOpen(undefined);
  }, [onApply, filtersIsOpen]);

  const chunkSizeCauses = resizeCallback(causesOptionsMemorized?.options.length || 0);
  const optionsCauses = chunk(orderBy(causesOptionsMemorized?.options || [], ["label"]), chunkSizeCauses);

  const chunkSizeFunctions = resizeCallback(functionsOptionsMemorized?.options.length || 0);
  const optionsFunctions = chunk(orderBy(functionsOptionsMemorized?.options || [], ["label"]), chunkSizeFunctions);

  const chunkSizeAbilities = resizeCallback(abilitiesOptionsMemorized?.options.length || 0);
  const optionsAbilities = chunk(orderBy(abilitiesOptionsMemorized?.options || [], ["label"]), chunkSizeAbilities);

  const chunkSizeStates = resizeCallback(statesOptionsMemorized?.options.length || 0);
  const optionsStates = chunk(orderBy(statesOptionsMemorized?.options || [], ["label"]), chunkSizeStates);

  const chunkSizeAvailability = resizeCallback(availabilityOptionsMemorized?.options.length || 0);
  const optionsAvailability = chunk(
    orderBy(availabilityOptionsMemorized?.options || [], ["label"]),
    chunkSizeAvailability
  );

  console.log(optionsStates);

  return (
    <>
      <FilterCustom size="sm" onClick={() => setMostrarFiltros(!mostrarFiltros)}>
        {t("plain:Filtros")}
        <Icon icon="filter" color="neutralWhite" />
      </FilterCustom>

      {mostrarFiltros && (
        <>
          <FiltersContainer className={className}>
            <CategoryContainer>
              <div>
                <ButtonFilter
                  size="sm"
                  onClick={() => {
                    setIsOpenInterest((prev) => !prev);
                    setActiveFilter("interest");
                  }}
                >
                  <ButtonContent>
                    <span>{t("plain:Interesses")}</span>
                    <RotatableIcon icon="chevronDown" color="primary" size="sm" isOpen={isOpenInterest} />
                  </ButtonContent>
                  <ActionsContainer show={isOpenInterest}>
                    <CheckboxContent>
                      <CheckboxContainer>{children}</CheckboxContainer>
                    </CheckboxContent>
                  </ActionsContainer>
                </ButtonFilter>

                <ButtonFilter
                  size="sm"
                  onClick={() => {
                    setIsOpenCauses((prev) => !prev);
                    setFiltersIsOpen("causes");
                    setActiveFilter("causes");
                  }}
                >
                  <ButtonContent>
                    <span>{t("plain:Causas")}</span>
                    <RotatableIcon icon="chevronDown" color="primary" size="sm" isOpen={isOpenCauses} />
                  </ButtonContent>
                  <ActionsContainer show={isOpenCauses}>
                    <CheckboxContent>
                      {optionsCauses.map((subArr, key) => (
                        <div key={key}>
                          {subArr.map((el, subKey) => (
                            <CheckboxCustom
                              id={`${subKey}-${el.value}`}
                              key={`${subKey}-${el.value}`}
                              label={el.label}
                              checked={el.checked}
                              onValueChange={() => {
                                if (onSelectedCauses) onSelectedCauses(el);
                              }}
                            />
                          ))}
                        </div>
                      ))}
                    </CheckboxContent>
                  </ActionsContainer>
                </ButtonFilter>

                <ButtonFilter
                  size="sm"
                  onClick={() => {
                    setIsOpenFunctions((prev) => !prev);
                    setFiltersIsOpen("functions");
                    setActiveFilter("functions");
                  }}
                >
                  <ButtonContent>
                    <span>{t("plain:Funções")}</span>
                    <RotatableIcon icon="chevronDown" color="primary" size="sm" isOpen={isOpenFunctions} />
                  </ButtonContent>
                  <ActionsContainer show={isOpenFunctions}>
                    <CheckboxContent>
                      {optionsFunctions.map((subArr, key) => (
                        <div key={key}>
                          {subArr.map((el, subKey) => (
                            <CheckboxCustom
                              id={`${subKey}-${el.value}`}
                              key={`${subKey}-${el.value}`}
                              label={el.label}
                              checked={el.checked}
                              onValueChange={() => {
                                if (onSelectedFunctions) onSelectedFunctions(el);
                              }}
                            />
                          ))}
                        </div>
                      ))}
                    </CheckboxContent>
                  </ActionsContainer>
                </ButtonFilter>

                <ButtonFilter
                  size="sm"
                  onClick={() => {
                    setIsOpenAbilities((prev) => !prev);
                    setFiltersIsOpen("abilities");
                    setActiveFilter("abilities");
                  }}
                >
                  <ButtonContent>
                    <span>{t("plain:Habilidades")}</span>
                    <RotatableIcon icon="chevronDown" color="primary" size="sm" isOpen={isOpenAbilities} />
                  </ButtonContent>
                  <ActionsContainer show={isOpenAbilities}>
                    <CheckboxContent>
                      {optionsAbilities.map((subArr, key) => (
                        <div key={key}>
                          {subArr.map((el, subKey) => (
                            <CheckboxCustom
                              id={`${subKey}-${el.value}`}
                              key={`${subKey}-${el.value}`}
                              label={el.label}
                              checked={el.checked}
                              onValueChange={() => {
                                if (onSelectedAbilities) onSelectedAbilities(el);
                              }}
                            />
                          ))}
                        </div>
                      ))}
                    </CheckboxContent>
                  </ActionsContainer>
                </ButtonFilter>

                <ButtonFilter
                  size="sm"
                  onClick={() => {
                    setIsOpenStates((prev) => !prev);
                    setFiltersIsOpen("states");
                    setActiveFilter("states");
                  }}
                >
                  <ButtonContent>
                    <span>Estados/UF</span>
                    <RotatableIcon icon="chevronDown" color="primary" size="sm" isOpen={isOpenStates} />
                  </ButtonContent>
                  <ActionsContainer show={isOpenStates}>
                    <CheckboxContent>
                      {optionsStates.map((subArr, key) => (
                        <div key={key}>
                          {subArr.map((el, subKey) => (
                            <CheckboxCustom
                              id={`${subKey}-${el.value}`}
                              key={`${subKey}-${el.value}`}
                              label={el.label}
                              checked={el.checked}
                              onValueChange={() => {
                                if (onSelectedStates) onSelectedStates(el);
                              }}
                            />
                          ))}
                        </div>
                      ))}
                    </CheckboxContent>
                  </ActionsContainer>
                </ButtonFilter>

                <ButtonFilter
                  size="sm"
                  onClick={() => {
                    setIsOpenAvailability((prev) => !prev);
                    setFiltersIsOpen("availability");
                    setActiveFilter("availability");
                  }}
                >
                  <ButtonContent>
                    <span>{t("plain:Disponibilidade")}</span>
                    <RotatableIcon icon="chevronDown" color="primary" size="sm" isOpen={isOpenAvailability} />
                  </ButtonContent>
                  <ActionsContainer show={isOpenAvailability}>
                    <CheckboxContent>
                      {optionsAvailability.map((subArr, key) => (
                        <div key={key}>
                          {subArr.map((el, subKey) => (
                            <CheckboxCustom
                              id={`${subKey}-${el.value}`}
                              key={`${subKey}-${el.value}`}
                              label={el.label}
                              checked={el.checked}
                              onValueChange={() => {
                                if (onSelectedAvailability) onSelectedAvailability(el);
                              }}
                            />
                          ))}
                        </div>
                      ))}
                    </CheckboxContent>
                  </ActionsContainer>
                </ButtonFilter>
              </div>
            </CategoryContainer>
            <ButtonContainer>
              <ButtonCancel onClick={onCleanCallback}>Limpar</ButtonCancel>
              <ButtonApply type="button" onClick={onApplyCallback}>
                Aplicar filtro
              </ButtonApply>
            </ButtonContainer>
          </FiltersContainer>
        </>
      )}
    </>
  );
};

export default Filter;

const FiltersContainer = styled.div``;

const CheckboxContent = styled.div`
  padding-left: 20px;
`;

const ButtonContent = styled.div`
  height: 42px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-size: 15px;
    color: #2c45cc;
    font-weight: 500;
  }
`;

const ButtonApply = styled.button`
  width: 165px;
  height: 51px;
  border-radius: 5px;
  background: #2c45cc;
  color: #fff;
`;

const ButtonCancel = styled.button`
  width: 165px;
  height: 51px;
  border-radius: 5px;
  background: transparent;
  color: rgb(118, 118, 118);
  border: 1px solid rgb(118, 118, 118);
`;

const ButtonCSS = css`
  border: 1px solid ${({ theme }) => theme.v3.colors.neutralLightest};
  color: ${({ theme }) => theme.v3.colors.neutralLight};
  box-sizing: border-box;
  border-radius: 5px;

  :focus {
    outline: none;
    border-color: ${({ theme }) => theme.v3.colors.feedbackSupport};
  }
`;

const ButtonFilter = styled(ButtonBase)`
  width: 100%;
  background-color: #495eec40;
  margin-bottom: 20px;
  ${ButtonCSS};

  :hover {
    background-color: #495eec40;
  }

  svg {
    margin-left: ${({ theme }) => theme.v3.spacing.xs};
    color: ${({ theme }) => theme.v3.colors.neutralLightness};
  }
`;

const FilterCustom = styled(ButtonFilter)`
  background-color: #2c45cc;
  width: 120px;
  margin-top: 30px;
  color: #fff;

  :hover {
    background-color: #2c45cc;
  }
`;

const ActionsContainer = styled.div<{ show?: boolean }>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 -${({ theme }) => theme.v3.spacing.xs};
  padding: 0;
  height: 0;
  overflow: hidden;
  ${transition.normal("transition")};

  ${({ show }) =>
    show &&
    css`
      height: 100%;
      padding: ${({ theme }) => theme.v3.spacing.md} 0;
      overflow: visible;
    `}

  .filterOptions > button {
    margin: ${({ theme }) => theme.v3.spacing.xs};
  }

  ${mqV3.smDown} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const CategoryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.v3.spacing.md} 0;

  & > div:last-child {
    margin-left: auto;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;

  & > div {
    margin-right: ${({ theme }) => theme.v3.spacing.md};
    margin: 0 12px ${({ theme }) => theme.v3.spacing.xs} 12px;
  }

  ${mqV3.smDown} {
    flex-direction: column;
    align-items: flex-start;

    & > div {
      margin: 0 ${({ theme }) => theme.v3.spacing.xxs} ${({ theme }) => theme.v3.spacing.sm};
    }
  }
`;

const RotatableIcon = styled(Icon)<RotatableIconProps>`
  transition: transform 0.3s ease;
  transform: ${(props) => (props.isOpen ? "rotate(180deg)" : "rotate(0deg)")};
`;

const CheckboxCustom = styled(Checkbox)`
  margin-bottom: 15px;
  vertical-align: middle;
  label {
    color: #2c45cc;
    font-size: 14px;
    font-weight: 400;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
`;
