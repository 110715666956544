import { AxiosResponse } from "axios";
import http from "config/http";
import { ResponseWithParams, ResponseStatus } from "core/api/definitions";
import { SubscribeDataShort } from "core/api/definitions";
import { UserDataFull } from "core/api/definitions";

export interface GetVolunteerProfileDetailsRequest {
  subscribeSlug: string;
  volunteerId: string;
}

export interface VolunteerProfileDetails {
  _id: string;
  registration_data: {
    action_slug: string;
    action_title: string;
    registration_data: {
      day: string;
      slot_id: string;
      time_start: string;
      time_end: string;
      time_in_seconds: number;
    };
    subscribe_data: SubscribeDataShort;
  }[];
  user_data: UserDataFull;
}

export type GetVolunteerProfileDetailsResponse = ResponseWithParams<ResponseStatus, VolunteerProfileDetails>;

export default async ({
  subscribeSlug,
  volunteerId,
}: GetVolunteerProfileDetailsRequest): Promise<AxiosResponse<GetVolunteerProfileDetailsResponse>> =>
  http.post<GetVolunteerProfileDetailsResponse>(
    `/subscribe/${subscribeSlug}/volunteer/${volunteerId}/profile/details`,
    {}
  );
