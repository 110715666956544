import { createAction } from "@reduxjs/toolkit";

export enum AnalyticTool {
  FACEBOOK_PIXEL = "facebook_pixel",
  HOTJAR = "hotjar",
  SENTRY = "sentry",
  ENHANCEMENT = "enhancement",
}
export interface TermsPayload {
  analytic: AnalyticTool | "ALL";
}

export const acceptTerms = createAction("terms/accept", (data: TermsPayload) => ({ payload: data }));

export const revokeTerms = createAction("terms/revoke", (data: TermsPayload) => ({ payload: data }));
