import { AxiosResponse } from "axios";
import http from "config/http";
import { ResponseStatus, ResponseWithParams } from "../definitions";
import { ProjectData } from "../definitions";

interface EditRequest {
  project: string;
}

export type EditResponse = ResponseWithParams<ResponseStatus, ProjectData>;

export default async ({ project }: EditRequest): Promise<AxiosResponse<EditResponse>> =>
  http.get<EditResponse>(`/projects/${project}/edit`);
