import TermsOfUse from "components/TermsOfUse/TermsOfUse";
import useTranslationV3 from "hooks/useTranslationV3";
import { rem } from "polished";
import React from "react";
import { ModalBody, ModalFooter, ModalHeader as RSModalHeader, ModalProps } from "reactstrap";
import styled from "styled-components/macro";
import Button from "../Button/Button";
import ModalBaseContainer from "./ModalBase";

interface ModalTermsProps extends ModalProps {}

const ModalTerms = ({ isOpen, toggle, onClose, onConfirm, disabled }: ModalTermsProps) => {
  const { t } = useTranslationV3();

  return (
    <ModalBaseContainer isOpen={isOpen} toggle={toggle} onClosed={onClose}>
      <ModalHeader centered alignedClose={false}>
        {t("plain:Termos de Responsabilidade")}
      </ModalHeader>
      <ModalBody>
        <Content>
          <TermsContainer>
            <TermsOfUse />
          </TermsContainer>
        </Content>
        <ModalFooter>
          <CustomButton color="primary" size="md" onClick={onConfirm} disabled={disabled}>
            {t("actions.acceptTerms.label")}
          </CustomButton>
        </ModalFooter>
      </ModalBody>
    </ModalBaseContainer>
  );
};

export default ModalTerms;

const TermsContainer = styled.div`
  h2 {
    font-size: ${({ theme }) => theme.v3.fontSize.md};
    color: ${({ theme }) => theme.v3.colors.neutralBase};
    margin-top: ${({ theme }) => theme.v3.spacing.md};
  }
`;

const Content = styled.div`
  max-height: 70vh;
  overflow: auto;
  padding: ${({ theme }) => theme.v3.spacing.md};
  color: ${({ theme }) => theme.v3.colors.neutralLight};
  font-size: ${({ theme }) => theme.v3.fontSize.sm};
  line-height: ${rem(25)};

  p:last-child {
    margin-bottom: 0;
  }
`;

const ModalHeader = styled(RSModalHeader)`
  color: ${({ theme }) => theme.v3.colors.neutralBase};
`;

const CustomButton = styled(Button)`
  width: 100%;
`;
