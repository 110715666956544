import FeedbackModal from "components/FeedbackModal/FeedbackModal";
import { showAlert } from "core/alerts/actions";
import { LoadingStatus } from "core/api/definitions";
import { emailCheckIdentity, resendEmailValidationCode } from "core/panel/user/settings/validation";
import { useUser } from "hooks/useUser";
import ModalRequestValue from "panel/components/Modal/ModalRequestValue";
import React, { useCallback, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import styled, { css } from "styled-components/macro";
import mediaQueries from "utils/mediaQueries";
import { InputLabeledCodeStyled } from "wizard/v3/components/common/styles";

const UserTopBarValidation = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation("components");

  const [showCodeRequest, setShowCodeRequest] = useState(false);
  const [hasError, setHasError] = useState<string>();
  const [loading, setLoading] = useState<LoadingStatus>("idle");

  const { user } = useUser();

  const isValidationPending = user?.status_validation === "pending_email_validation";

  const getNewCodeToValidateUser = useCallback(async () => {
    const resp: any = await dispatch(resendEmailValidationCode({ name: user?.user_name!, email: user?.email! }));
    if (resendEmailValidationCode.fulfilled.match(resp)) {
      setShowCodeRequest(true);
      setHasError(undefined);
    } else {
      setShowCodeRequest(false);
      setHasError(
        t([`wizard.error.common.${resp.payload?.response?.message}`, "wizard.error.default"], {
          ns: "translation",
        })
      );
    }
  }, [dispatch, setShowCodeRequest, setHasError, t, user]);

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      setLoading("loading");

      const code: string | undefined = (e.target as any)?.code?.value;
      if (!code || code.length !== 4) return;

      const resp: any = await dispatch(emailCheckIdentity({ code, email: user?.email! }));
      if (emailCheckIdentity.fulfilled.match(resp)) {
        setShowCodeRequest(false);
        setHasError(undefined);
        dispatch(showAlert("info", t("userTopBarValidation.success.message")));
      } else {
        setHasError(resp?.payload?.response?.message);
      }
      setLoading("ok");
    },
    [dispatch, setShowCodeRequest, setLoading, t, user]
  );

  if (!isValidationPending) return null;

  return (
    <>
      {/** @ts-ignore */}
      <FeedbackModal
        onDismiss={!!hasError && !showCodeRequest}
        success={false}
        title={"Ops!"}
        message={hasError}
        onClose={() => setHasError(undefined)}
      />
      <CustomModalRequestValue
        isOpen={showCodeRequest}
        size="sm"
        centered={false}
        formID="codeRequestForm"
        disabled={loading === "loading"}
        onClose={() => setShowCodeRequest(false)}
        modalInfos={{
          title: t("userTopBarValidation.modal.title"),
          description: t("userTopBarValidation.modal.description"),
        }}
      >
        <form id="codeRequestForm" onSubmit={handleSubmit}>
          <InputLabeledCodeStyled
            type="tel"
            name="code"
            autoFocus
            required
            maxLength={4}
            minLength={4}
            placeholder="0000"
            wrapperClassName="codeRequestInput"
            disabled={loading === "loading"}
            onChange={() => {
              if (hasError) setHasError(undefined);
            }}
            error={hasError}
          />
        </form>
      </CustomModalRequestValue>
      <Div>
        <p>
          <Trans
            t={t}
            i18nKey="userTopBarValidation.description"
            components={[<span onClick={getNewCodeToValidateUser} />]}
          />
        </p>
      </Div>
    </>
  );
};

export default UserTopBarValidation;

const CustomModalRequestValue = styled(ModalRequestValue)`
  .codeRequestInput {
    margin-top: 16px;

    input {
      margin: 0;
    }
  }
`;

const Div = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 100%;
  background-color: #f5f7ff;

  p {
    margin: 0;
    color: #8798ad;
    font-size: 15px;
    line-height: 22px;
  }

  span {
    color: ${({ theme }) => theme.colors.secondary};
    font-weight: 500;
    cursor: pointer;
  }

  ${mediaQueries.md(css`
    padding: 10px;
    height: auto;
  `)}
`;
