import React from "react";
import { IconType } from "icons";
import { Control, Controller } from "react-hook-form";
import FormFeedback from "../FormFeedback";
import FormGroup from "../FormGroup";
import InputIcon from "../InputIcon";
import Label from "../Label";
import Input from "./Input";

interface FormGroupWrapperProps {
  name: string;
  type?: string;
  placeholder?: string;
  label: string;
  invalid?: boolean;
  control: Control;
  message?: string;
  tag?: React.ElementType;
  mask?: string;
  defaultValue?: string;
  icon?: IconType;
  className?: string;
  disabled?: boolean;
  onClickIcon?(): void;
}

const InputController = ({
  tag: Tag = FormGroup,
  label,
  invalid,
  icon,
  message,
  onClickIcon,
  className,
  mask,
  placeholder,
  type,
  disabled,
  ...props
}: FormGroupWrapperProps) => (
  <Tag className={className}>
    <Label invalid={invalid}>{label}</Label>
    <Controller
      {...props}
      render={({ ref, ...rProps }) => (
        <Input
          type={type}
          innerRef={ref}
          {...rProps}
          invalid={invalid}
          mask={mask}
          placeholder={placeholder}
          disabled={disabled}
        />
      )}
    />
    {icon && <InputIcon icon={icon} onClick={onClickIcon} color="neutralLightness" />}
    {message && <FormFeedback invalid={invalid}>{message}</FormFeedback>}
  </Tag>
);

export default InputController;
