import api from "core/api";
import asyncThunk from "core/decorators/toolkit";

export const listFiltersReportVolunteers = asyncThunk(
  "panel/beta/subPlatform/administrators/listFiltersReportVolunteers",
  api.admin.subPlataform.listFiltersReportVolunteers
);

export const listFiltersReportProjects = asyncThunk(
  "panel/beta/subPlatform/administrators/listFiltersReportProjects",
  api.admin.subPlataform.listFiltersReportProjects
);

export const exportReportVolunteers = asyncThunk(
  "panel/beta/subPlatform/administrators/exportReportVolunteers",
  api.admin.subPlataform.exportReportVolunteers
);

export const exportReportProjects = asyncThunk(
  "panel/beta/subPlatform/administrators/exportReportProjects",
  api.admin.subPlataform.exportReportProjects
);

export const exportReportEngagement = asyncThunk(
  "panel/beta/subPlatform/administrators/exportReportEngagement",
  api.admin.subPlataform.exportReportEngagement
);

export const listFiltersReportRanking = asyncThunk(
  "panel/beta/subPlatform/administrators/listFiltersReportRanking",
  api.admin.subPlataform.listFiltersReportRanking
);

export const exportReportRanking = asyncThunk(
  "panel/beta/subPlatform/administrators/exportReportRanking",
  api.admin.subPlataform.exportReportRanking
);
