import http from "config/http";
import { Contact, SubscribeTimeConfigurationsTime } from "core/api/definitions";
import { ResponseStatus, ResponseWithParams } from "../definitions";

export interface Slot {
  _id: string;
  times: SubscribeTimeConfigurationsTime[];
}

export interface SubscribeAssignRequest {
  subscribeSlug: string;
  slots: Slot[];
  accept_terms: "true" | "false";
  contacts?: Contact[];
}

export interface SubscribeAssignResponseParams {}

export type SubscribeAssignResponse = ResponseWithParams<ResponseStatus, SubscribeAssignResponseParams>;

const assign = async ({ subscribeSlug, ...props }: SubscribeAssignRequest) =>
  http.post<SubscribeAssignResponse>(`/subscribe/${subscribeSlug}/assign`, { ...props });

export default assign;
