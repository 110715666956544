import { createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import { ListPlatformRequest, ListPlatformResponse } from "core/api/admin/platforms/list";
import { RequestableState, WithCurrentPage } from "core/api/definitions";
import { ListPlatform } from "./actions";

type Platform = Partial<WithCurrentPage<ListPlatformResponse & RequestableState>>;

type IState = {
  platform: Platform;
};

const panelBetaListPlatformSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "panelBetaListPlatform",
  initialState: {
    platform: {
      loading: "idle",
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      ListPlatform.pending.toString(),
      (state, action: PayloadAction<unknown, string, { arg: ListPlatformRequest }>) => {
        if (state.platform.currentPage !== 1 && action.meta.arg.pg === 1)
          state.platform.currentPage = action.meta.arg.pg;
        state.platform!.loading = "loading";
      }
    );
    builder.addCase(
      ListPlatform.fulfilled.toString(),
      (state, action: PayloadAction<Platform, string, { arg: ListPlatformRequest }>) => {
        const pg = action.meta.arg.pg;
        if (!pg || pg === 1) {
          state.platform = { ...action.payload, currentPage: 1, loading: "ok" };
        } else {
          state.platform = {
            ...action.payload,
            response: [...(state.platform.response || []), ...(action.payload.response || [])],
            currentPage: pg,
            loading: "ok",
          };
        }
      }
    );
    builder.addCase(ListPlatform.rejected.toString(), (state, action) => {
      state.platform!.loading = "error";
    });
  },
});

export default panelBetaListPlatformSlice;
