import { createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import {
  PlatformCustomizationUpdateRequest,
  PlataformCustomizationUpdateResponse,
} from "core/api/admin/platforms/customization/update";
import {
  PlatformCustomizationShowResponse,
  PlatformCustomizationShowRequest,
  PlatformCustomizationShowResponseData,
} from "core/api/admin/platforms/customization/show";
import { RequestableState } from "core/api/definitions";
import decoreThunk from "core/decorators/decorate";

import { isEmpty, merge, mergeWith } from "lodash";
import { customizationShow, customizationUpdate } from "./actions";

type Customization = Partial<PlatformCustomizationShowResponseData & RequestableState>;

interface IState extends RequestableState {
  platforms: {
    [k: string]: Customization;
  };
}

const preparePlatformCustomization = (
  action: PayloadAction<PlataformCustomizationUpdateResponse, string, { arg: PlatformCustomizationUpdateRequest }>
): Partial<Customization> => {
  return {
    logo: action.meta.arg.logo,
    favicon: action.meta.arg.favicon,
    colors: {
      primary: action.meta.arg.colors?.primary || "",
      primary_dark: action.meta.arg.colors?.primary_dark || "",
      primary_light: action.meta.arg.colors?.primary_light || "",
      secondary: action.meta.arg.colors?.secondary || "",
      secondary_dark: action.meta.arg.colors?.secondary_dark || "",
      secondary_light: action.meta.arg.colors?.secondary_light || "",
    },
  };
};

const panelBetaCustomizationSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "panelBetaCustomization",
  initialState: {
    loading: "idle",
    platforms: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, customizationShow, [
      {
        pending: (
          state,
          action: PayloadAction<PlatformCustomizationShowResponse, string, { arg: PlatformCustomizationShowRequest }>
        ) => {
          if (!action.meta.arg.platformId) return;
          state.platforms[action.meta.arg.platformId] = {
            loading: "loading",
          };
        },
        fulfilled: (
          state,
          action: PayloadAction<PlatformCustomizationShowResponse, string, { arg: PlatformCustomizationShowRequest }>
        ) => {
          if (!action.meta.arg.platformId) return;
          state.platforms![action.meta.arg.platformId]!.loading = "ok";

          state.platforms![action.meta.arg.platformId]! = merge(
            state.platforms![action.meta.arg.platformId],
            action.payload.params
          );
        },
        rejected: (
          state,
          action: PayloadAction<PlatformCustomizationShowResponse, string, { arg: PlatformCustomizationShowRequest }>
        ) => {
          state.platforms![action.meta.arg.platformId]!.loading = "error";
        },
      },
    ]);

    decoreThunk(builder, customizationUpdate, [
      {
        pending: (
          state,
          action: PayloadAction<
            PlataformCustomizationUpdateResponse,
            string,
            { arg: PlatformCustomizationUpdateRequest }
          >
        ) => {
          if (!action.meta.arg._id) return;
          state.platforms![action.meta.arg._id].loading = "loading";
        },
        fulfilled: (
          state,
          action: PayloadAction<
            PlataformCustomizationUpdateResponse,
            string,
            { arg: PlatformCustomizationUpdateRequest }
          >
        ) => {
          if (!action.meta.arg._id) return;

          state.platforms![action.meta.arg._id].loading = "ok";
          state.platforms![action.meta.arg._id] = mergeWith(
            preparePlatformCustomization(action),
            state.platforms![action.meta.arg._id],
            (objValue, objSource) => (isEmpty(objValue) ? objSource : objValue)
          );
        },
        rejected: (
          state,
          action: PayloadAction<
            PlataformCustomizationUpdateResponse,
            string,
            { arg: PlatformCustomizationUpdateRequest }
          >
        ) => {
          if (!action.meta.arg._id) return;

          state.platforms![action.meta.arg._id].loading = "error";
        },
      },
    ]);
  },
});

export default panelBetaCustomizationSlice;
