import React, { useCallback, useState, useMemo } from "react";
import { useHistory, useParams, matchPath, useLocation } from "react-router-dom";
import routes from "routes";
import { reverse } from "named-urls";
import { goto } from "utils/router";
import InputDonationPage, { DonationData } from "./InputDonationPage";
import { storeNewListMaterial, listMaterials } from "core/wizard/create/donation";
import { useDispatch } from "react-redux";
import { PayloadAction } from "@reduxjs/toolkit";
import { StoreNewListMaterialResponse } from "core/api/actions/donation/storeListMaterial";
import { AddMaterialItemResponse } from "core/api/actions/donation/addMaterialItem";
import useLocalStorageRead from "hooks/useLocalStorageRead";
import useLocalStorageWrite from "hooks/useLocalStorageWrite";
import { MaterialListInfo } from "./DonationListInfoPage";
import { MaterialItemInfo } from "./DonationListItems";
import Wizard from "wizard/v3/components/Layout/Wizard";
import { useTranslation } from "react-i18next";
import useValidation from "wizard/v3/hooks/useValidation";
import donation from "core/validators/donation";
import routeToError from "core/validators/routeToError";
import { isEmpty } from "lodash";
import { showAlert } from "core/alerts/actions";
import DoingWork from "components/Progress/DoingWork";
import WizardDoingWorkText from "wizard/v3/components/DoingWork/Text";
import { WizardLocationStateReturnTo } from "core/api/definitions";

export const WIZARD_CREATE_DONATION = "creating.donation";

interface DonationStorageData {
  info?: MaterialListInfo;
  items?: MaterialItemInfo[];
}

interface CreateDonationPageProps {
  onBack: string | (() => void);
  onContinue: string | (() => void);
}

const CreateDonationPage = ({ onBack, onContinue }: CreateDonationPageProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const locationState = history.location.state as any;

  const [creatingDonation, setCreatingDonation] = useState(false);

  const { actionSlug, projectSlug } = useParams<{ actionSlug: string; projectSlug: string }>();

  const routeData = useMemo(
    () => ({
      actionSlug,
      projectSlug,
    }),
    [actionSlug, projectSlug]
  );

  const donationDataStorage = useLocalStorageRead<DonationData>(WIZARD_CREATE_DONATION);

  const [info, setInfo] = useState(() => {
    if (!donationDataStorage?.info) return undefined;
    return { ...donationDataStorage.info };
  });
  const [items, setItems] = useState<MaterialItemInfo[] | undefined>(donationDataStorage?.items);

  const onAddItemCallback = useCallback((item: MaterialItemInfo) => {
    setItems((prev) => [...(prev || []), item]);
  }, []);

  const onChangeItemCallback = useCallback((itemUpdated: MaterialItemInfo, idxUpdated: number) => {
    setItems((prev) => (prev || []).map((item, idx) => (idx === idxUpdated ? itemUpdated : item)));
  }, []);

  const onRemoveItemCallback = useCallback((_: MaterialItemInfo, idxRemoved: number) => {
    setItems((prev) => (prev || []).filter((_, idx) => idx !== idxRemoved));
  }, []);

  useLocalStorageWrite<DonationStorageData>(
    {
      info,
      items,
    },
    WIZARD_CREATE_DONATION
  );

  const stepCurrent = useMemo(() => {
    if (matchPath(pathname, routes.wizard.project.action.donation.new.info)) return 1;
    if (matchPath(pathname, routes.wizard.project.action.donation.new.items)) return 2;
    return 0;
  }, [pathname]);

  const [touched, setTouched] = useState<{ [k: string]: boolean }>({
    title: false,
    delivery_date: false,
    delivery_way: false,
    ["locations.city"]: false,
    ["locations.coordinates"]: false,
    ["locations.state"]: false,
    ["locations.country"]: false,
  });

  const { title, delivery_date, delivery_way, address, action_address } = info || {
    title: undefined,
    delivery_date: undefined,
    delivery_way: undefined,
    address: undefined,
    items: undefined,
    action_address: undefined,
  };

  const { errors, setErrors, failed: donationHasError, assignErrorsToOriginal, errorsOriginal } = useValidation(
    { title, delivery_date, delivery_way, locations: address, items },
    delivery_way === "to_combine" || info?.action_address ? donation.rules : donation.rulesWithAddress,
    touched,
    [title, delivery_date, delivery_way, address, items]
  );

  const updateTouchedCallback = useCallback(
    (key: string) => {
      const hasKey = Object.keys(touched).includes(key);
      if (hasKey) setTouched((prev) => ({ ...prev, [key]: true }));
    },
    [touched]
  );

  const resetForm = useCallback(() => {
    setInfo(undefined);
    setItems([]);
  }, []);

  const inputDonationPageOnContinueCallback = useCallback(
    async (_) => {
      if (donationHasError) {
        const r = routeToError(errorsOriginal!, donation.routes.create);
        console.error(errorsOriginal);
        if (r) {
          assignErrorsToOriginal();
          return history.push(reverse(r, routeData));
        }
      }

      // TODO(Jota): To create donation list.
      setCreatingDonation(true);
      try {
        const responseMaterial: any = ((await dispatch(
          storeNewListMaterial({
            action: actionSlug,
            //
            title: title!,
            delivery_date: delivery_date!,
            delivery_way: delivery_way || "to_combine",
            address: delivery_way === "to_combine" || info?.action_address ? undefined : address!,
            action_address,
            items: items || [],
          })
        )) as any) as PayloadAction<StoreNewListMaterialResponse>;

        if (storeNewListMaterial.rejected.match(responseMaterial)) {
          const resp = responseMaterial.payload as StoreNewListMaterialResponse;

          if (resp.params && !isEmpty(resp.params)) {
            setErrors(resp.params);
            const r = routeToError(resp?.params! as any, donation.routes.create);
            dispatch(showAlert("danger", t("wizard.error.common.donationCreation")));

            if (r) return history.push(reverse(r, routeData));
          } else {
            dispatch(
              showAlert("danger", t([`error.${resp.response?.message}`, "wizard.error.common.donationCreation"]))
            );
          }
          //TODO(Jeconias): Report error to Sentry.
          return;
        }

        await dispatch(
          listMaterials({
            action: actionSlug,
            force: true,
          })
        );

        localStorage.removeItem(WIZARD_CREATE_DONATION);

        if (locationState) goto(history, (locationState as WizardLocationStateReturnTo).returnTo)();
        else if (typeof onContinue === "string") goto(history, reverse(onContinue, routeData))();
        else onContinue();
      } catch (e) {
        console.error(e);
      } finally {
        setCreatingDonation(false);
      }
    },
    [
      dispatch,
      history,
      actionSlug,
      projectSlug,
      routeData,
      title,
      delivery_date,
      delivery_way,
      address,
      items,
      donationHasError,
      errorsOriginal,
      locationState,
      action_address,
    ]
  );

  return (
    <Wizard header={{ title: t("wizard.stepsWizard.createDonation.header.title"), stepCurrent, stepMax: 2 }}>
      <DoingWork isOpen={creatingDonation}>
        <WizardDoingWorkText>{t("wizard.pages.createDonation.creating.text")}</WizardDoingWorkText>
      </DoingWork>
      <InputDonationPage
        routeInfo={routes.wizard.project.action.donation.new.info}
        routeItems={routes.wizard.project.action.donation.new.items}
        info={info}
        onChangeInfo={(info) => setInfo(info)}
        items={items}
        onAddItem={onAddItemCallback}
        onChangeItem={onChangeItemCallback}
        onRemoveItem={onRemoveItemCallback}
        onContinue={inputDonationPageOnContinueCallback}
        onBack={onBack}
        error={errors}
        onTouched={updateTouchedCallback}
        resetForm={resetForm}
      />
    </Wizard>
  );
};

export default CreateDonationPage;
