import { AxiosResponse } from "axios";
import http from "config/http";
import { ResponseWithParams, ResponseStatus } from "core/api/definitions";

export interface ValidateActionByAdminRequest {
  actionSlug?: string;
  status: "active" | "reject";
  message?: string;
}

export type ValidateActionByAdminResponse = ResponseWithParams<ResponseStatus, {}>;

const validateActionByAdmin = async ({
  actionSlug,
  message = "default",
  ...data
}: ValidateActionByAdminRequest): Promise<AxiosResponse<ValidateActionByAdminResponse>> =>
  await http.post<ValidateActionByAdminResponse>(`admin/actions/validate/${actionSlug}`, { message, ...data });

export default validateActionByAdmin;
