import { createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import api from "core/api";
import { ActionData } from "core/api/definitions";
import { ProjectData, ProjectType } from "core/api/definitions";
import { EditResponse } from "core/api/projects/edit";
import {
  StoreNewProjectOrganizationRequest,
  StoreNewProjectPersonalRequest,
  StoreNewProjectResponse,
} from "core/api/projects/storeNewProject";
import decoreThunk from "core/decorators/decorate";
import asyncThunk from "core/decorators/toolkit";
import { LoadingStatus } from "core/api/definitions";
import { PhotoFile } from "wizard/v3/pages/definitions/commonTypes";
import { SocialNetworkData } from "wizard/v3/pages/SocialNetworksPage";

interface IState {
  profileImage?: PhotoFile;
  coverImage?: PhotoFile;
  description?: string;
  actions?: ActionData[];
  socialNetWork?: SocialNetworkData;
  project: {
    loading: LoadingStatus;
    response?: Partial<ProjectData>;
    detailed?: boolean;
  };
}

export const createStoreNewProject = asyncThunk<
  StoreNewProjectResponse,
  StoreNewProjectOrganizationRequest | StoreNewProjectPersonalRequest
>("wizard/create/project/storeNew", api.project.storeNewProject);

export const getProjectDetails = asyncThunk("wizard/create/project/details", api.project.edit, {
  condition: [
    (props, state) =>
      (state.wizard.create.project.project.loading !== "ok" &&
        state.wizard.create.project.project.loading !== "loading") ||
      !state.wizard.create.project.project.detailed ||
      props.project !== state.wizard.create.project.project.response?.slug,
  ],
});

export const getProjectDetailsWithoutConditions = asyncThunk("wizard/create/project/details", api.project.edit, {});

export const updateProjectOrganization = asyncThunk(
  "wizard/update/project/organization",
  api.project.updateProjectOrganization
);

const projectSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "wizard/create/project",
  initialState: {
    project: {
      loading: "idle",
    },
  },
  reducers: {
    updateDescription(state, action: PayloadAction<string>) {
      state.description = action.payload;
    },
    updateProfileImage(state, action: PayloadAction<PhotoFile>) {
      state.profileImage = action.payload;
    },
    updateCoverImage(state, action: PayloadAction<PhotoFile>) {
      state.coverImage = action.payload;
    },
    updateSocialNetwork(state, action: PayloadAction<SocialNetworkData>) {
      const payload = typeof action.payload === "object" && !Array.isArray(action.payload) ? action.payload : {};
      state.socialNetWork = { ...state.socialNetWork, ...payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      createStoreNewProject.fulfilled.toString(),
      (
        state,
        {
          payload,
          meta,
        }: PayloadAction<
          StoreNewProjectResponse,
          string,
          undefined | { arg?: StoreNewProjectOrganizationRequest | StoreNewProjectPersonalRequest }
        >
      ) => {
        const project: Partial<ProjectData> = {
          _id: payload.params?._id,
          slug: payload.params?.slug,
          type: meta!.arg!.type as ProjectType,
        };
        if (meta?.arg?.type === "personal") {
          // TODO(Jota): To get it from user.
          // const personalProject = (meta.arg as StoreNewProjectPersonalRequest);
          // project.name = state.;
        } else {
          const orgProject = meta!.arg! as StoreNewProjectOrganizationRequest;
          project.name = orgProject.name;
          project.description = orgProject.description;
        }
        state.project.loading = "idle";
        state.project.response = project;
        state.project.detailed = false;
      }
    );
    decoreThunk(builder, getProjectDetails, [
      {
        pending: (state, _) => {
          state.project.loading = "loading";
        },
        fulfilled: (state, { payload }: PayloadAction<EditResponse>) => {
          state.project.loading = "ok";
          state.project.response = payload.params as Partial<ProjectData>;
          state.project.detailed = true;
        },
        rejected: (state, _) => {
          state.project.loading = "error";
        },
      },
    ]);
    decoreThunk(builder, updateProjectOrganization, [
      {
        fulfilled: (state, action) => {
          state.project.response = { ...state.project.response, ...action.meta.arg };
        },
      },
    ]);
    builder.addCase(createStoreNewProject.toString(), (state) => {
      state.coverImage = undefined;
      state.profileImage = undefined;
    });
  },
});

export const { updateProfileImage, updateCoverImage, updateDescription, updateSocialNetwork } = projectSlice.actions;

export default projectSlice;
