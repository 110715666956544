import http from "config/http";
import { Response, ResponseStatus } from "core/api/definitions";
import { RegistrationStatus } from "../definitions";

export type CheckoutVolunteerStatus = Extract<RegistrationStatus, "present" | "absent">;

export type CheckoutVolunteerRequest = {
  subscribeSlug: string;
  volunteerId: string;
  status: CheckoutVolunteerStatus;
  evaluation_message?: string;
  evaluation_rating?: number;
  slot_id?: string;
  registration_id?: string;
};

export type CheckoutVolunteerResponse = Response<ResponseStatus>;

export default ({ subscribeSlug, volunteerId, ...props }: CheckoutVolunteerRequest) =>
  http.post<CheckoutVolunteerResponse>(`subscribe/${subscribeSlug}/checkout/volunteer/${volunteerId}/make`, {
    ...props,
  });
