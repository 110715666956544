import { createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import { RequestableState } from "core/api/definitions";
import decoreThunk from "core/decorators/decorate";

import { getSubscribeDetails } from "./actions";

import requestLoading from "core/decorators/requestLoading";
import { SubscribeValidationParams, VolunteerSubscribeResponse } from "core/api/users/listDetailsRegistrationModal";

interface IState extends RequestableState, SubscribeValidationParams {}

const panelBetaVolunteerSubscribeDetailsModalSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "panelBetaVolunteerSubscribeDetailsModalSlice",
  initialState: {
    loading: "idle",
    subscribe_registrations: {
      action_title: "",
      registrations: [],
      subscribe_title: "",
      _id: "",
    },
    volunteer_data: {
      counters: {
        actions_count: 0,
        entity: [],
        evaluation_rating_total: 0,
        rating: 0,
        subscribe_count_total: 0,
        volunteer_count: 0,
      },
      general: {
        locations: {},
        name: "",
        profile_image: "",
        _id: "",
      },
      preferences: {
        conditionals: {
          causes: [],
          functions: [],
          interests: [],
          skills: [],
        },
        _id: "",
      },
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, getSubscribeDetails, [
      requestLoading(),
      {
        pending: (state: IState) => {
          state.loading = "loading";
        },
        fulfilled: (state: IState, action: PayloadAction<VolunteerSubscribeResponse>) => {
          state.loading = "ok";
          if (action?.payload?.params) {
            state.subscribe_registrations = action.payload.params.subscribe_registrations;
            state.volunteer_data = action.payload.params.volunteer_data;
          }
        },
        rejected: (state: IState) => {
          state.loading = "error";
        },
      },
    ]);
  },
});

export default panelBetaVolunteerSubscribeDetailsModalSlice;
