import http from "config/http";
import { ResponseWithParams, ResponseStatus } from "../definitions";
import queryString from "query-string";
import { AxiosResponse } from "axios";

interface getListAppointmentsByDateInterface {
  date: string;
}

export interface AppointmentsByDate {
  appointments: {
    _id: string;
    registrations: {
      registration_data: {
        day: string;
        status: string;
        slot_id: string;
        _id: string;
        time_end: string;
        time_start: string;
      };
      subscribe_data: {
        _id: string;
        action_id: string;
        slug: string;
        title: string;
        time_accept_remote: boolean;
      };
      user_data: {
        _id: string;
        name: string;
        email: string;
        thumbnail: string;
      };
      action_data: {
        _id: string;
        slug: string;
        title: string;
      };
    }[];
  }[];
}

export type getListAppointmentsByDateReponse = ResponseWithParams<ResponseStatus, AppointmentsByDate>;

export default async ({
  slugProject,
  args,
}: {
  slugProject: string;
  args: getListAppointmentsByDateInterface;
}): Promise<AxiosResponse<getListAppointmentsByDateReponse>> => {
  const qs = queryString.stringify(args);
  return await http.get(`/projects/${slugProject}/next-appointments-by-day?${qs}`);
};
