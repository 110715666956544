import { AxiosResponse } from "axios";
import http from "config/http";
import { ResponseWithParams } from "../definitions";

type ActionData = {
  _id: string;
  title: string;
  slug: string;
};

type RegistrationData = {
  _id: string;
  day: string;
  slot_id: string;
  status: string;
  time_end: string;
  time_start: string;
  user_data: {
    _id: string;
    name: string;
    email: string;
    thumbnail: string;
  };
};

type SubscribeData = {
  _id: string;
  title: string;
  slug: string;
  images_data: {
    cover_original: string;
    thumb: string;
  };
};

export type SubscriptionData = {
  count: number;
  subscriptions: {
    action_data: ActionData;
    registration_data: RegistrationData;
    subscribe_data: SubscribeData;
    _id: string;
  }[];
};

export type ProgramData = {
  url_program: string;
  name: string;
  slug: string;
  images: {
    logo: string;
    cover: string;
  };
  subscribe_data: any[];
};

export type AddressCoordinates = [number, number];

export interface Address {
  street?: string;
  number?: string;
  complement?: string;
  district?: string;
  city?: string;
  state?: string;
  country?: string;
  zipcode?: string;
  coordinates?: AddressCoordinates;
}

export type Contacts = {
  _id: string;
  type: string;
  country: string;
  value: string;
  description: string | null;
}[];

export type uniqueDocument = {
  type: string;
  value: string;
};

export type UserData = {
  _id: string;
  name: string;
  email: string;
  description?: string;
  unique_document?: uniqueDocument;
  locations?: Address;
  contacts?: Contacts;
  profile_image?: string;
  document_brazilian_cpf?: string;
  counters: {
    _id: string;
    actions_count: number;
    evaluation_rating_total: number;
    rating: number;
    subscribe_count_total: number;
    volunteer_count: number;
  };
};

export type ResumeResponse = {
  program_data: ProgramData[];
  subscription_data: SubscriptionData;
  user_data: UserData;
};

export type Response = ResponseWithParams<any, ResumeResponse>;

const resumeProfile = async (): Promise<AxiosResponse<Response>> => {
  return http.get<Response>(`/v3/users/profile`);
};

export default resumeProfile;
