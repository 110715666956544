import React from "react";
import styled from "styled-components/macro";

export type SpinnerSize = "sm" | "md" | "lg";

const spinnerSizes = {
  sm: 40,
  md: 60,
  lg: 100,
};

const chaseSizes = {
  sm: "25%",
  md: "15%",
  lg: "25%",
};

interface SpinnerProps {
  color?: string;
  size?: SpinnerSize;
}

const Spinner = (props: SpinnerProps): JSX.Element => (
  <SpinnerStyled {...props}>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </SpinnerStyled>
);

/**
 * Thanks to Tobias Sahlin (https://tobiasahlin.com/spinkit/).
 */
const SpinnerStyled = styled.div<SpinnerProps>`
  width: ${({ size }) => spinnerSizes[size || "md"]}px;
  height: ${({ size }) => spinnerSizes[size || "md"]}px;
  position: relative;
  animation: spinner-chase 2.5s infinite linear both;

  > div {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    animation: spinner-chase-dot 2s infinite ease-in-out both;

    &:before {
      content: "";
      display: block;
      width: ${({ size }) => chaseSizes[size || "md"]};
      height: ${({ size }) => chaseSizes[size || "md"]};
      background-color: ${({ color, theme }) => color || theme.colors?.secondary || "#dfdfdf"};
      border-radius: 50%;
      animation: spinner-chase-dot-before 2s infinite ease-in-out both;
    }

    &:nth-child(1) {
      animation-delay: -1.1s;
    }
    &:nth-child(2) {
      animation-delay: -1s;
    }
    &:nth-child(3) {
      animation-delay: -0.9s;
    }
    &:nth-child(4) {
      animation-delay: -0.8s;
    }
    &:nth-child(5) {
      animation-delay: -0.7s;
    }
    &:nth-child(6) {
      animation-delay: -0.6s;
    }
    &:nth-child(1):before {
      animation-delay: -1.1s;
    }
    &:nth-child(2):before {
      animation-delay: -1s;
    }
    &:nth-child(3):before {
      animation-delay: -0.9s;
    }
    &:nth-child(4):before {
      animation-delay: -0.8s;
    }
    &:nth-child(5):before {
      animation-delay: -0.7s;
    }
    &:nth-child(6):before {
      animation-delay: -0.6s;
    }
  }

  @keyframes spinner-chase {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes spinner-chase-dot {
    80%,
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes spinner-chase-dot-before {
    50% {
      transform: scale(0.4);
    }
    100%,
    0% {
      transform: scale(1);
    }
  }
`;

export default Spinner;
