import React from "react";
import { FormFeedback as RSFormFeedback, FormFeedbackProps as RSFormFeedbackProps } from "reactstrap";
import styled, { css } from "styled-components/macro";

export interface FormFeedbackProps extends RSFormFeedbackProps {
  invalid?: boolean;
}

const FormFeedbackComp: React.FC<FormFeedbackProps> = ({ children, ...props }) => {
  return (
    <RSFormFeedback {...props} valid={undefined}>
      {children}
    </RSFormFeedback>
  );
};

export const FormFeedbackStyle = css<FormFeedbackProps>`
  background: none;

  ${({ invalid, valid }) =>
    (invalid || valid) &&
    css`
      display: block;
    `};

  ${({ invalid }) =>
    invalid &&
    css`
      color: ${({ theme }) => theme.v3.colors.feedbackError};
    `}

  ${({ valid }) =>
    valid &&
    css`
      color: ${({ theme }) => theme.v3.colors.feedbackSuccess};
    `}
`;

const FormFeedback = styled(FormFeedbackComp)`
  ${FormFeedbackStyle}
`;

export default FormFeedback;
