import React, { useEffect, useState, lazy, Suspense } from "react";

import { useReduxSelector } from "hooks/useReduxSelector";
import { Origin } from "core/api/definitions";
import { LoadingStatus } from "core/api/definitions";
import Loading from "./Loading/LoadingRequest";

const HomeHandle: React.FC = () => {
  const { ladingPageSlug, program } = useReduxSelector((state) => ({
    program: (state.pagesNew?.origin?.params as Origin)?.program,
    ladingPageSlug: (state.pagesNew?.origin?.params as Origin)?.landing_page_info?.slug,
  }));

  const [loading, setLoading] = useState<LoadingStatus>("idle");
  const [Component, setComponent] = useState<any>();

  useEffect(() => {
    if (ladingPageSlug === "fundo") {
      setComponent(() => lazy(() => import("LandingPages/TBDoacoes")));
      setLoading("ok");
    } else if (program) {
      setComponent(() => lazy(() => import("program/App")));
      setLoading("ok");
    } else {
      setComponent(() => lazy(() => import("App")));
      setLoading("ok");
    }
  }, [program, ladingPageSlug]);

  return loading === "ok" ? (
    <Suspense fallback={<Loading />}>
      <Component />
    </Suspense>
  ) : (
    <Loading />
  );
};

export default HomeHandle;
