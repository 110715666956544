import projects from "./projects";
import actions from "./actions";
import platforms from "./platforms";
import tags from "./tags";
import programs from "./programs";
import validation from "./validation";
import subPlataform from "./subPlataform";

export default {
  projects,
  actions,
  platforms,
  tags,
  programs,
  validation,
  subPlataform,
};
