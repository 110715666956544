import styled from "styled-components";
import { ModalBody, ModalHeader } from "./ModalBase";

export const ModalHeaderRegister = styled(ModalHeader)`
  border-bottom: 0;
`;

export const ModalBodyRegister = styled(ModalBody)`
  padding: ${({ theme }) => `${theme.v3.spacing.xl} ${theme.v3.spacing.lg} ${theme.v3.spacing.lg}`};
`;
