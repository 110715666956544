const PROGRAM_LIST_REQUESTED = "program/listagem/REQUESTED";
const PROGRAM_LIST_RECEIVED = "program/listagem/RECEIVED";
const PROGRAM_LIST_FAILED = "program/listagem/FAILED";

const LOAD_MORE_PROGRAMS_REQUESTED = "carregarMais/program/REQUESTED";
const LOAD_MORE_PROGRAMS_RECEIVED = "carregarMais/program/RECEIVED";
const LOAD_MORE_PROGRAMS_FAILED = "carregarMais/program/FAILED";

const ASSIGN_REQUESTED = "program/assign/REQUESTED";
const ASSIGN_RECEIVED = "program/assign/RECEIVED";
const ASSIGN_FAILED = "program/assign/FAILED";

const INVITE_PROGRAM_REQUESTED = "invite/program/REQUESTED";
const INVITE_PROGRAM_RECEIVED = "invite/program/RECEIVED";
const INVITE_PROGRAM_FAILED = "invite/program/FAILED";

const CLEAN_PROGRAM = "clean/program";

export default {
  PROGRAM_LIST_REQUESTED,
  PROGRAM_LIST_RECEIVED,
  PROGRAM_LIST_FAILED,
  LOAD_MORE_PROGRAMS_REQUESTED,
  LOAD_MORE_PROGRAMS_RECEIVED,
  LOAD_MORE_PROGRAMS_FAILED,

  ASSIGN_REQUESTED,
  ASSIGN_RECEIVED,
  ASSIGN_FAILED,

  INVITE_PROGRAM_REQUESTED,
  INVITE_PROGRAM_RECEIVED,
  INVITE_PROGRAM_FAILED,

  CLEAN_PROGRAM,
};
