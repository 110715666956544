import React, { useCallback, useState } from "react";
import { Suggestion, geocodeByAddress, geocodeByPlaceId } from "react-places-autocomplete";
import { useTranslation } from "react-i18next";
import PlacesAutoComplete, { PlacesAutoCompleteProps } from "./LocalAutocomplete";
import { Address } from "core/api/definitions";
import { formatGeocoderResult } from "utils/addresses";

export type LocationWithAddress = Address & { address: string };

export interface PlacesAutoCompleteResolvedProps
  extends Omit<PlacesAutoCompleteProps, "value" | "onChange" | "onSelect"> {
  inputTag?: any;
  value?: LocationWithAddress;
  inputProps: any;
  onChange?: (value: LocationWithAddress) => void;
  onSelect?: (value: LocationWithAddress) => void;
  onSelectRaw?: (value: string, placeID: string) => boolean;
  onSuggest?: (suggestions: ReadonlyArray<Suggestion>) => Suggestion[];
  border?: string;
}

export const initialLocation: LocationWithAddress = {
  address: "",
  street: "",
  city: "",
  state: "",
  country: "",
  zipcode: "",
  coordinates: [NaN, NaN],
};

const LocalAutoCompleteResolved = ({
  value,
  border,
  error,
  onChange,
  onSelect,
  onSelectRaw,
  ...props
}: PlacesAutoCompleteResolvedProps) => {
  const { t } = useTranslation();

  const [hasError, setHasError] = useState<string>();

  const onChangeCallback = useCallback(
    (address: string) => {
      if (onChange) {
        onChange({ ...initialLocation, address });
      }
    },
    [value, onChange]
  );

  const onSelectCallback = useCallback(
    async (address, placeID: string) => {
      if (onChange) onChange({ ...(value || initialLocation), address });
      try {
        if (onSelectRaw && !onSelectRaw(address, placeID)) return;
        const response = await (placeID !== "" ? geocodeByPlaceId(placeID) : geocodeByAddress(address));
        if (response.length >= 1) {
          const location = formatGeocoderResult(response[0]);
          const result = !location.city || !location.state || !location.country;

          if (result) {
            setHasError(t(`wizard.pages.simpleInputCep.error.location`));
          } else {
            if (hasError) setHasError("");
          }
          if (!result && onChange) onChange(location);
          if (onSelect) onSelect({ ...location, address });
        }
      } catch (e) {
        setHasError(t("error.genericDefault"));
      }
    },
    [value, onChange, onSelect, hasError, t]
  );

  return (
    <PlacesAutoComplete
      onChange={onChangeCallback}
      onSelect={onSelectCallback}
      value={value?.address || ""}
      error={hasError || error}
      border={border}
      {...props}
    />
  );
};

export default LocalAutoCompleteResolved;
