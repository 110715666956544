import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit";
import api from "core/api";
import { GetHomeStructureResponseParams } from "core/api/site/getHomeStructure";
import decoreThunk from "core/decorators/decorate";
import requestLoading from "core/decorators/requestLoading";
import asyncThunk from "core/decorators/toolkit";
import { RequestableState } from "core/api/definitions";
import utilsTypes from "core/utils/types";
import { GetHomeStructureRequest } from "core/api/site/getHomeStructure";

export const getHomeStructure = asyncThunk("pages/home", (data: GetHomeStructureRequest) => {
  if (window.$home && !data.coordinate) {
    return new Promise((resolve, _) =>
      resolve({
        data: window.$home,
        status: 200,
        statusText: "ok",
        headers: [],
        config: {},
      } as any)
    );
  }
  return api.site.getHomeStructure(data);
});

type IState = RequestableState & {
  force?: boolean;
  structure?: GetHomeStructureResponseParams;
};

const homeSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "pageHome",
  initialState: {
    loading: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, getHomeStructure, [
      requestLoading(),
      {
        fulfilled: (state, action) => {
          state.structure = action.payload?.params?.home;
          state.force = false;
        },
      },
    ]);
    //TODO(Jeconias): Move this action to new structure of the redux
    builder.addCase(utilsTypes.BLC_RECEIVED, (state) => {
      state.force = true;
    });
  },
});

export default homeSlice;
