import React, { ElementType } from "react";
import styled from "styled-components/macro";
import mqV3 from "utils/mediaQueriesV3";

export interface WrapperMobileProps {
  [key: string]: any;
  tag?: string | ElementType;
  className?: string;
}

const WrapperMobileComp = React.forwardRef<HTMLDivElement, WrapperMobileProps>(({ tag, ...props }, ref) => {
  const Component = (tag || "div") as ElementType;
  return <Component ref={ref} {...props} />;
});

/**
 * WrapperMobile wraps WrapperMobileComp to enable `${WrapperMobile}` to be included into styled-component rules.
 */
const WrapperMobile = styled(WrapperMobileComp)`
  width: 100%;

  ${mqV3.xsDown} {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export default WrapperMobile;
