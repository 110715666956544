// import { modalOpen } from "core/pages/actions";
import { STORE_KEYS } from "utils/constants";
import { AnyAction, MiddlewareAPI, Middleware } from "redux";
import { Dispatch } from "react";
import { updateDashProfile, changeDashProfile } from "core/auth";
import { createStoreNewProject } from "core/wizard/create/project";
import { AppDispatch, RootState } from "core/state";

const handleDashProfileChange: Middleware = (store: MiddlewareAPI<AppDispatch, RootState>) => (
  next: Dispatch<AnyAction>
) => (action: AnyAction) => {
  if (createStoreNewProject.fulfilled.match(action)) {
    const { login } = store.getState();

    if (!login.params?.dash_profile) {
      // Not logged
      return next(action);
    }

    if (login.params?.dash_profile === "volunteer") {
      // If the current dash_profile is not both and the received one is both...
      localStorage.setItem(STORE_KEYS.INFO.DASH_PROFILE_CHANGE, "true");
      next(updateDashProfile("both"));
    }
  }

  switch (action.type) {
    case changeDashProfile.toString():
      localStorage.setItem(STORE_KEYS.DASHPROFILE, action.payload);
      break;
  }

  return next(action);
};

export default handleDashProfileChange;
