import http from "config/http";
import { ResponseStatus, ResponseWithParams } from "core/api/definitions";

export interface PublishProductionRequest {
  platformId: string;
}

export interface PublishProductionResponseData {
  //
}

export interface PublishProductionResponse extends ResponseWithParams<ResponseStatus, PublishProductionResponseData> {}

const publish = ({ platformId }: PublishProductionRequest) =>
  http.get<PublishProductionResponse>(`/platform-launcher/${platformId}/publish/production/create`);

export default publish;
