import React from "react";
import styled, { css } from "styled-components/macro";
import { darken, rgba, mix } from "polished";
import BaseButton from "./BaseButton";
import get from "lodash/get";
import Icon from "components/Icon/Icon";
import { Colors, Size, Weight } from "utils/types";
import { ThemeProps } from "config/modules/styled.d";
import { InputProps } from "reactstrap";
import { IconType } from "components/Icon/Icon";

const sizes = {
  xs: 9, //TODO(Sabrina) check which pass value used in the component(ActionsSection)
  sm: 9,
  md: 12,
  lg: 16,
};

interface ButtonProps extends Omit<InputProps, "tag"> {
  icon?: IconType;
  color?: Colors;
  size?: Size;
  outline?: boolean;
  style?: any;
  actived?: boolean;
  onClick?(e?: React.MouseEvent): void;
  type?: string;
  disabled?: boolean;
  block?: boolean;
  href?: string;
  className?: string;
  inverted?: boolean;
  to?: string;
  weight?: Weight;
  form?: string;
}

/**
 * @deprecated
 */
const Button: React.FunctionComponent<ButtonProps> = ({ icon, children, ...props }) => (
  <ButtonStyled {...props}>
    {icon && (
      <Icon type={icon} color={props.color} width={sizes[props.size || "md"]} height={sizes[props.size || "md"]} />
    )}
    {children}
  </ButtonStyled>
);

export default Button;

const darkenBy = (darkenValue: number) => ({ color, theme: { colors } }: ButtonProps & ThemeProps) => {
  if (color === "link") {
    return "transparent";
  }

  return darken(darkenValue, get(colors, color as any, colors.secondary));
};

const secondaryColor = ({ color, theme: { colors } }: ButtonProps & ThemeProps) =>
  get(colors, color as any, colors.secondary);

const fgColor = ({ color, inverted, theme: { colors } }: ButtonProps & ThemeProps) =>
  inverted ? get(colors, color as any, colors.secondary) : get(colors, `${color}_text`, colors.secondary_text);

const bgColor = ({ color, inverted, theme: { colors } }: ButtonProps & ThemeProps) => {
  if (color === "link") {
    return "transparent";
  }

  if (color === "tertiary") return colors.tertiary;
  if (color === "quaternary") return colors.quaternary;

  return inverted ? get(colors, `${color}_text`, colors.secondary_text) : get(colors, color as any, colors.secondary);
};

const fontWeight = ({ weight }: ButtonProps) => {
  return weight;
};

const ButtonStyled = styled(({ ...props }: ButtonProps) => <BaseButton {...props} />)`
  ${({ outline, inverted }) =>
    !outline &&
    css`
      color: ${fgColor};
      background: ${bgColor};
      border-color: ${bgColor};

      &:hover {
        color: ${inverted ? bgColor : fgColor};
        svg {
          path {
            fill: ${inverted ? bgColor : fgColor};
          }
        }
        background: ${(props) => darken(0.075, (inverted ? fgColor : bgColor)(props))};
        border-color: ${darkenBy(0.1)};
      }

      &.disabled,
      &:disabled {
        color: ${fgColor};
        background-color: ${secondaryColor};
        border-color: ${secondaryColor};
      }
    `}

  ${(props) =>
    props.outline &&
    css`
      color: ${secondaryColor};
      border-color: ${secondaryColor};

      &:hover {
        color: ${fgColor};
        background-color: ${secondaryColor};
        border-color: ${secondaryColor};
        svg {
          path {
            fill: ${fgColor};
          }
        }
      }

      &.disabled,
      &:disabled {
        color: ${secondaryColor};
        background-color: transparent;
      }
    `}

    ${({ color, theme: { colors } }) =>
    color !== "link" &&
    css`
      &:focus,
      &.focus {
        box-shadow: inset 0 1px 0 ${rgba("#fff", 0.15)}, 0 1px 1px ${rgba("#000", 0.075)},
          0 0 0 0.2rem ${rgba(mix(0.15, "#fff", get(colors, color as any, colors.secondary) as any), 0.5)};
      }
    `}

    ${({ color, theme: { colors } }) =>
    color === "link" &&
    css`
      &:hover {
        color: ${darken(0.15, get(colors, `${color}_text`, colors.secondary_text))};
      }

      &:focus,
      &.focus {
        box-shadow: none;
      }
    `}

      ${({ color }) =>
    color === "tertiary" &&
    css`
      color: #8798ad;
      box-shadow: none !important;

      &:hover {
        border-color: transparent;
      }

      &:focus,
      &.focus {
        box-shadow: none;
      }
    `}

    ${(props) =>
    props.block &&
    css`
      display: block;
      width: 100%;
    `}

    ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      background: #edf0f6;
      border-color: #edf0f6;
      color: #bcc4de;
    `}

      ${(props) =>
    props.weight &&
    css`
      font-weight: ${fontWeight};
    `}
`;
