import React, { ElementType } from "react";
import { Input as RSBaseInput, InputProps as RSBaseInputProps } from "reactstrap";
import styled, { css } from "styled-components/macro";
import { rem } from "polished";
import InputMask from "react-input-mask";

export type BaseInputValue = string | string[] | number | boolean;

export interface BaseInputProps<T = BaseInputValue> extends Omit<RSBaseInputProps, "onChange" | "value"> {
  hasCounter?: boolean;
  tag?: string | ElementType;
  mask?: string | RegExp;
  maskChar?: string | null;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: T;
}

const getCustomProps = ({ mask, maskChar }: BaseInputProps) => {
  const customProps: BaseInputProps = {};

  if (mask) {
    customProps.tag = InputMask;
    customProps.mask = mask;

    if (!maskChar) {
      customProps.maskChar = null;
    }
  }

  return customProps;
};

const BaseInput = ({ type, onChange, mask, ...props }: BaseInputProps): JSX.Element => {
  const customProps = getCustomProps({ mask });
  return <BaseInputStyled type={type} {...props} {...customProps} onChange={onChange} />;
};

export default BaseInput;

export const BaseInputStyle = css<BaseInputProps>`
  color: ${({ theme }) => theme.v3.colors.neutralLight};
  border: 1px solid ${({ theme }) => theme.v3.colors.neutralLight};
  font-size: ${({ theme }) => theme.v3.fontSize.sm};
  padding: ${({ theme }) => theme.v3.spacing.sm};
  line-height: ${rem(16)};
  height: auto;
  border-radius: 6px;
  background-color: #fff;
  line-height: 1.5rem;

  :focus {
    outline: none;
    border-color: ${({ theme }) => theme.v3.colors.feedbackSupport};
    box-shadow: none;
  }

  :disabled,
  [readonly] {
    outline: none;
    color: ${({ theme }) => theme.v3.colors.neutralLightness};
    border-color: ${({ theme }) => theme.v3.colors.neutralLightest};
    box-shadow: none;
    background: #fff;
    cursor: not-allowed;
  }

  ::placeholder {
    color: ${({ theme }) => theme.v3.colors.neutralLightness};
  }

  ${({ invalid }) =>
    invalid &&
    css`
      border-color: ${({ theme }) => theme.v3.colors.feedbackError};
    `}

  ${({ valid }) =>
    valid &&
    css`
      border-color: ${({ theme }) => theme.v3.colors.feedbackSuccess};
    `}
    
  .input-icon ~ & {
    padding-right: 55px;
  }
`;

const BaseInputStyled = styled(({ ...props }) => <RSBaseInput {...props} valid={undefined} invalid={undefined} />)`
  ${BaseInputStyle}
`;
