import { createAsyncThunk, Draft } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { ActionHandler, ActionWithParams, Cache, CacheEntry, ListWithoutPaginationState } from "core/api/definitions";
import { AppDispatch, RootState } from "core/state";
import { cacheHasExpired } from "utils/helpers";
import { ErrorResponse, Response } from "../api/definitions";
import { ResponseErrorUnknown } from "../api/errors";

export type Condition<T = {}> = (props: T & { [index: string]: any }, state: RootState) => boolean;

type ResponseErrorType<T> = ErrorResponse<T> | Response;

type WithReturn<T> = keyof T;

export type ReducersNames = keyof RootState;

export type ThunkAPI<Extra = any, Reject = any> = {
  state: RootState & { [index: string]: ReducersNames };
  dispatch: AppDispatch;
  extra: Extra;
  rejectValue: Reject;
};

export interface AsyncThunkOptions<T> {
  condition?: Condition<T>[];
  withReturn?: WithReturn<T>[];
}

const asyncThunk = <Res, Req, ErrorResParams = {}>(
  actionName: string,
  request: (data: Req) => Promise<AxiosResponse<Res>>,
  options?: AsyncThunkOptions<Req>
) => {
  type ThunkAPIConfig = ThunkAPI<any, ResponseErrorType<ErrorResParams>>;

  return createAsyncThunk<Res, Req, ThunkAPIConfig>(
    actionName,
    async (requestData: Req, { rejectWithValue }) => {
      const requestDataResult = options?.withReturn?.reduce((acc, curr) => ({ ...acc, [curr]: requestData[curr] }), {});

      try {
        const response = await request(requestData);
        return { ...requestDataResult, ...response?.data } as Res;
      } catch (err) {
        if (err.response) {
          //return err.response.data.response.message_translated;
          return rejectWithValue((err?.response?.data as ErrorResponse<ErrorResParams>) || ResponseErrorUnknown);
        } else {
          return rejectWithValue(err);
        }
      }
    },
    {
      condition(props, { getState }) {
        if (options?.condition && options?.condition.length !== 0) {
          const state = getState();
          return options?.condition?.some((condFunc) => condFunc(props, state));
        }
        return true;
      },
    }
  );
};

interface CacheDecoratorReturn<S, A> {
  cacheDecorator: ActionHandler<S, A>;
  condition: Condition;
}

export const cacheDecoratorAsyncThunk = <T, State extends Cache<T>>(
  sliceName: ReducersNames,
  keyName = "slug"
): CacheDecoratorReturn<State, ActionWithParams<Draft<T>>> => {
  const cacheDecorator: ActionHandler<State, ActionWithParams<Draft<T>>> = {
    pending: (state: Draft<State>, action: ActionWithParams<Draft<T>>) => {
      const key = action.meta.arg[keyName] || keyName;
      state.cache[key] = { ...state.cache[key], data: {}, loading: "loading" };
    },
    fulfilled: (state: Draft<State>, action: ActionWithParams<Draft<T>>) => {
      const key = action.meta.arg[keyName] || keyName;
      state.cache[key] = { loading: "ok", data: action.payload.params, updated: new Date().getTime() };
    },
    rejected: (state: Draft<State>, action: ActionWithParams<Draft<T>>) => {
      const key = action.meta.arg[keyName] || keyName;
      state.cache[key] = { ...state.cache[key], data: {}, loading: "error" };
      state.cache[key].error = { ...action.payload };
    },
  };

  return {
    cacheDecorator,
    condition(props, state) {
      const key: string = props[keyName] || keyName;
      const cache: undefined | CacheEntry<T> = state[sliceName]?.cache[key] || undefined;
      return cache && cacheHasExpired(cache?.updated || 0) ? false : true;
    },
  };
};

export const cacheList = <T>(reduceName: ReducersNames): Condition => (_, state): boolean => {
  const slice = (state[reduceName] as ListWithoutPaginationState<T>) || { list: [] };
  return slice.list.length === 0;
};

export default asyncThunk;
