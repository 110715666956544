import { storageGet, StorageType } from "utils/helpers";

const useLocalStorageRead = <T = any>(
  key: string,
  defaultValue: T | (() => T) | undefined = undefined,
  local: StorageType = "local"
) => {
  try {
    const data = storageGet(key, local);
    if (data) return data as T;
  } catch (e) {}
  if (typeof defaultValue === "function") return (defaultValue as () => T)();
  return defaultValue;
};

export default useLocalStorageRead;
