import pagesTypes from "./types";
import { MODALS } from "utils/constants";

export const originRequested = () => ({
  type: pagesTypes.ORIGIN_REQUESTED,
});

export const originReceived = (payload) => ({
  type: pagesTypes.ORIGIN_RECEIVED,
  payload,
});

export const originFailed = (payload) => ({
  type: pagesTypes.ORIGIN_FAILED,
  payload,
});

export const homeRequested = () => ({
  type: pagesTypes.HOME_REQUESTED,
});

export const homeReceived = (payload) => ({
  type: pagesTypes.HOME_RECEIVED,
  payload,
});

export const homeFailed = (payload) => ({
  type: pagesTypes.HOME_FAILED,
  payload,
});

export const modalOpen = (payload) => ({
  type: pagesTypes.MODAL_OPEN,
  payload: typeof payload === "string" ? { modal: payload } : payload,
});

// TODO(mdb): Should load the vacancy details...
export const modalOpenVacancy = (payload) => ({
  type: pagesTypes.MODAL_OPEN,
  payload: {
    modal: MODALS.VACANCY,
    ...payload,
  },
});

export const modalOpenDonation = (payload) => ({
  type: pagesTypes.MODAL_OPEN,
  payload: {
    modal: MODALS.DONATIONS,
    ...payload,
  },
});

export const modalClose = () => ({
  type: pagesTypes.MODAL_CLOSE,
});

export const aboutRequested = () => ({
  type: pagesTypes.ABOUT_REQUESTED,
});

export const aboutReceived = (payload) => ({
  type: pagesTypes.ABOUT_RECEIVED,
  payload,
});

export const aboutFailed = (payload) => ({
  type: pagesTypes.ABOUT_FAILED,
  payload,
});

export const odsDetailsRequested = () => ({
  type: pagesTypes.ODS_DETAILS_REQUESTED,
});

export const odsDetailsReceived = (payload) => ({
  type: pagesTypes.ODS_DETAILS_RECEIVED,
  payload,
});

export const odsDetailsFailed = (payload) => ({
  type: pagesTypes.ODS_DETAILS_FAILED,
  payload,
});

export const odsMainRequested = () => ({
  type: pagesTypes.ODS_MAIN_REQUESTED,
});

export const odsMainReceived = (payload) => ({
  type: pagesTypes.ODS_MAIN_RECEIVED,
  payload,
});

export const odsMainFailed = (payload) => ({
  type: pagesTypes.ODS_MAIN_FAILED,
  payload,
});

export const actionRequested = () => ({
  type: pagesTypes.ACTION_REQUESTED,
});

export const actionReceived = (payload) => ({
  type: pagesTypes.ACTION_RECEIVED,
  payload,
});

export const actionFailed = (payload) => ({
  type: pagesTypes.ACTION_FAILED,
  payload,
});

export const userPageRequested = () => ({
  type: pagesTypes.USER_REQUESTED,
});

export const userPageReceived = (payload) => ({
  type: pagesTypes.USER_RECEIVED,
  payload,
});

export const userPageFailed = (payload) => ({
  type: pagesTypes.USER_FAILED,
  payload,
});

export const programPageRequested = () => ({
  type: pagesTypes.PROGRAM_REQUESTED,
});

export const programPageReceived = (payload, slug) => ({
  type: pagesTypes.PROGRAM_RECEIVED,
  payload,
  slug,
});

export const programPageFailed = (payload) => ({
  type: pagesTypes.PROGRAM_FAILED,
  payload,
});

export const loadMoreSubscribeProgramRequested = () => ({
  type: pagesTypes.LOAD_MORE_SUBSCRIBE_PROGRAMS_REQUESTED,
});

export const loadMoreSubscribeProgramReceived = (payload, pg) => ({
  type: pagesTypes.LOAD_MORE_SUBSCRIBE_PROGRAMS_RECEIVED,
  payload,
  pg,
});

export const loadMoreSubscribeProgramFailed = (payload) => ({
  type: pagesTypes.LOAD_MORE_SUBSCRIBE_PROGRAMS_FAILED,
  payload,
});

export const loadMoreActionProgramRequested = () => ({
  type: pagesTypes.LOAD_MORE_ACTION_PROGRAMS_REQUESTED,
});

export const loadMoreActionProgramReceived = (payload, pg) => ({
  type: pagesTypes.LOAD_MORE_ACTION_PROGRAMS_RECEIVED,
  payload,
  pg,
});

export const loadMoreActionProgramFailed = (payload) => ({
  type: pagesTypes.LOAD_MORE_ACTION_PROGRAMS_FAILED,
  payload,
});

export const listProjectsRequested = () => ({
  type: pagesTypes.LIST_PROJECTS_REQUESTED,
});

export const listProjectsReceived = (payload) => ({
  type: pagesTypes.LIST_PROJECTS_RECEIVED,
  payload,
});

export const listProjectsFailed = (payload) => ({
  type: pagesTypes.LIST_PROJECTS_FAILED,
  payload,
});

export const gdoRequested = () => ({
  type: pagesTypes.GDO_REQUESTED,
});

export const gdoReceived = (payload) => ({
  type: pagesTypes.GDO_RECEIVED,
  payload,
});

export const gdoFailed = (payload) => ({
  type: pagesTypes.GDO_FAILED,
  payload,
});

export const tagRequested = (payload) => ({
  type: pagesTypes.TAG_REQUESTED,
  payload,
});

export const tagReceived = (payload) => ({
  type: pagesTypes.TAG_RECEIVED,
  payload,
});

export const tagFailed = (payload) => ({
  type: pagesTypes.TAG_FAILED,
  payload,
});

export const gatRequested = () => ({
  type: pagesTypes.GAT_REQUESTED,
});

export const gatReceived = (payload) => ({
  type: pagesTypes.GAT_RECEIVED,
  payload,
});

export const gatFailed = (payload) => ({
  type: pagesTypes.GAT_FAILED,
  payload,
});

export const satRequested = () => ({
  type: pagesTypes.SAT_REQUESTED,
});

export const satReceived = (payload) => ({
  type: pagesTypes.SAT_RECEIVED,
  payload,
});

export const satFailed = (payload) => ({
  type: pagesTypes.SAT_FAILED,
  payload,
});

export const satMoreRequested = (tag, term, pg) => ({
  type: pagesTypes.SAT_MORE_REQUESTED,
  tag,
  term,
  pg,
});

export const satMoreReceived = (tag, term, pg, payload) => ({
  type: pagesTypes.SAT_MORE_RECEIVED,
  tag,
  term,
  pg,
  payload,
});

export const satMoreFailed = (tag, term, pg, payload) => ({
  type: pagesTypes.SAT_MORE_FAILED,
  tag,
  term,
  pg,
  payload,
});

export const lmaRequested = () => ({
  type: pagesTypes.LMA_REQUESTED,
});

export const lmaReceived = (payload) => ({
  type: pagesTypes.LMA_RECEIVED,
  payload,
});

export const lmaFailed = (payload) => ({
  type: pagesTypes.LMA_FAILED,
  payload,
});

export const ratRequested = () => ({
  type: pagesTypes.RAT_REQUESTED,
});

export const ratReceived = (payload) => ({
  type: pagesTypes.RAT_RECEIVED,
  payload,
});

export const ratFailed = (payload) => ({
  type: pagesTypes.RAT_FAILED,
  payload,
});

export const addatRequested = () => ({
  type: pagesTypes.ADDAT_REQUESTED,
});

export const addatReceived = (payload) => ({
  type: pagesTypes.ADDAT_RECEIVED,
  payload,
});

export const addatFailed = (payload) => ({
  type: pagesTypes.ADDAT_FAILED,
  payload,
});

export const grtRequested = (payload) => ({
  type: pagesTypes.GRT_REQUESTED,
  payload,
});

export const grtReceived = (payload) => ({
  type: pagesTypes.GRT_RECEIVED,
  payload,
});

export const grtFailed = (payload) => ({
  type: pagesTypes.GRT_FAILED,
  payload,
});
