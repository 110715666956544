import queryString from "query-string";
import http from "config/http";
import {
  ActionData,
  ActionStatus,
  ProjectData,
  ActionDataProjectShow,
  ResponseWithParams,
  ResponseStatus,
  ResponseWithAdditionals,
} from "core/api/definitions";
import { ListResponse } from "core/api/definitions";
import { AxiosResponse } from "axios";

export interface ListByProjectRequest {
  project: string;

  term?: string;
  status?: ActionStatus;
  pg?: number;
  pp?: number;
}

export interface ListByProjectResponseData {
  action_data: {
    title: string;
    slug: string;
    _id: string;
  };
}

export type ListByProjectResponse = ListByProjectResponseData[];

export default async ({
  project,
  status,
  term,
  pg,
  pp,
}: ListByProjectRequest): Promise<AxiosResponse<ListByProjectResponse>> => {
  const fs: any = {
    status: status || "all",
  };
  if (term) fs.term = term;
  if (pg) fs.pg = pg;
  if (pp) fs.pp = Math.min(5, pp); // 5 minimum by page.

  return http.get<ListByProjectResponse>(`/actions/${project}/all?${queryString.stringify(fs)}`);
};
