import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  STORAGE_KEY_COOKIES_TERMS,
  COOKIES_TERMS_ACCEPTED,
  STORAGE_KEY_TERMS_PREFFIX,
} from "components/CookieTerms/CookieTerms";
import { acceptTerms, AnalyticTool, revokeTerms, TermsPayload } from "core/pages/terms";
import { RootState } from "core/state";
import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from "redux";
import { SENTRY, HOTJAR_ID } from "utils/constants";
import { storageGet } from "utils/helpers";
import { Origin } from "core/api/definitions";
import { get as getOrigin } from "core/pages/origin";

const INSTALLED = {
  facebook_pixel: false,
  hotjar: false,
  sentry: false,
};

const COOKIE_KEYS: { [k: string]: string } = {
  facebook_pixel: "data-tb_facebook_pixel",
  hotjar: "data-tb_hotjar",
};

const applyFacebookPixel = (pixelID: string) => {
  (function (f: Window, b: Document, e: string, v?: string, n?: any, t?: HTMLScriptElement, s?: any) {
    if (f.fbq) return;

    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };

    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = "2.0";
    n.queue = [];
    t = b.createElement(e) as HTMLScriptElement;
    t.setAttribute(COOKIE_KEYS.facebook_pixel, "installed");
    t.async = !0;
    t.src = v || "";
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");

  if (window.fbq) {
    window.fbq("init", pixelID);
    window.fbq("track", "PageView");
    console.log("analytic::facebook_pixel::initialized");
    INSTALLED.facebook_pixel = true;
  }
};

const applyHotjar = () => {
  (function (h: any, o: Document, t: any, j?: any, a?: any, r?: HTMLScriptElement) {
    h.hj =
      h.hj ||
      function () {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid: HOTJAR_ID, hjsv: 6 };
    a = o.getElementsByTagName("head")[0];
    r = o.createElement("script");
    r.setAttribute(COOKIE_KEYS.hotjar, "installed");
    r.async = true;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
  console.log("analytic::hotjar::initialized");
  INSTALLED.hotjar = true;
};

const applySentry = () => {
  Sentry.init({
    dsn: SENTRY,
    ignoreErrors: ["Network failed", /Request failed with status code [0-9]+/],
    environment: process.env.REACT_APP_STAGE,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.5,
  });
  console.log("analytic::sentry::initialized");
  INSTALLED.sentry = true;
};

const revokeCookie = (analytic: Exclude<AnalyticTool, "enhancement">) => {
  if (!COOKIE_KEYS[analytic] || INSTALLED[analytic] === undefined) {
    console.log(`analytic::${analytic}::not-found`);
    return;
  }

  const scripts = Array.from(document.getElementsByTagName("script"));

  scripts.forEach((s) => {
    if (s.getAttribute(COOKIE_KEYS[analytic])) {
      s.remove();
      INSTALLED[analytic] = false;
      console.log(`analytic::${analytic}::revoke`);
    }
  });
};

type Payload = TermsPayload & {
  params?: Origin;
};

const handleCookie: Middleware = (_: MiddlewareAPI<Dispatch, RootState>) => (next: Dispatch<AnyAction>) => (
  action: PayloadAction<Payload>
) => {
  const cookiesTerms = storageGet(STORAGE_KEY_COOKIES_TERMS);

  const isAll = action?.payload?.analytic === "ALL" || cookiesTerms === COOKIES_TERMS_ACCEPTED;
  const analytic = action?.payload?.analytic;

  if (action.type === acceptTerms.toString() || action.type === getOrigin.fulfilled.toString()) {
    const origin = action.payload?.params;

    if (
      isAll ||
      analytic === AnalyticTool.ENHANCEMENT ||
      storageGet(`${STORAGE_KEY_TERMS_PREFFIX}_enhancement`) === COOKIES_TERMS_ACCEPTED
    ) {
      if (SENTRY && !INSTALLED.sentry) {
        applySentry();
      }

      if (HOTJAR_ID && !INSTALLED.hotjar) {
        applyHotjar();
      }
    }

    if (
      origin?.facebook_pixel?.id &&
      origin?.facebook_pixel?.active &&
      !INSTALLED.facebook_pixel &&
      (isAll ||
        analytic === AnalyticTool.FACEBOOK_PIXEL ||
        storageGet(`${STORAGE_KEY_TERMS_PREFFIX}_facebook_pixel`) === COOKIES_TERMS_ACCEPTED)
    ) {
      applyFacebookPixel(origin?.facebook_pixel.id);
    }
  } else if (action.type === revokeTerms.toString()) {
    const revokeAll = action?.payload?.analytic === "ALL";

    if (revokeAll || analytic === AnalyticTool.FACEBOOK_PIXEL) {
      revokeCookie(AnalyticTool.FACEBOOK_PIXEL);
      window.fbq = undefined;
    }

    if (revokeAll || analytic === AnalyticTool.ENHANCEMENT) {
      revokeCookie(AnalyticTool.HOTJAR);
      (window as any).hj = undefined;
      (window as any)._hjSettings = undefined;
    }

    if (revokeAll || analytic === AnalyticTool.ENHANCEMENT) {
      Sentry.close(1500).then(() => {
        console.log(`analytic::sentry::revoke`);
        INSTALLED.sentry = false;
      });
    }
  }

  return next(action);
};

export default handleCookie;
