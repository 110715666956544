import certificates from "./certificates";
import generateCertificates from "./generateCertificates";
import generateCertificateByCode from "./generateCertificateByCode";
import donation from "./donation";
import storeNewAction from "./storeNewAction";
import edit from "./edit";
import listByProject from "./listByProject";
import create from "./create";
import storeUpdateAction from "./storeUpdateAction";
import show from "./show";
import listVolunteers from "./listVolunteers";
import volunteerProfile from "./volunteerProfile";
import volunteerProfileDetails from "./volunteerProfileDetails";
import validateVolunteer from "./validateVolunteer";
import checkoutVolunteerList from "./checkoutVolunteerList";
import checkoutVolunteer from "./checkoutVolunteer";
import list from "./list";
import projectValidateVolunteer from "./projectValidateVolunteer";

export default {
  certificates,
  generateCertificates,
  generateCertificateByCode,
  donation,
  edit,
  storeNewAction,
  storeUpdateAction,
  listByProject,
  create,
  show,
  listVolunteers,
  volunteerProfile,
  volunteerProfileDetails,
  validateVolunteer,
  checkoutVolunteerList,
  checkoutVolunteer,
  list,
  projectValidateVolunteer,
};
