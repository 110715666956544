/**
 * @deprecated Please, use showFeedback.
 */
export const ALERT_SHOW = "alerts/show";

/**
 * @deprecated Please, use hideFeedback.
 */
export const ALERT_DISMISS = "alerts/dismiss";

/**
 * @deprecated
 */
export const DISPATCH_ALERT_SHOW = "dispatch/alert/show";
