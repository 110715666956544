import React, { useCallback } from "react";
import styled, { css } from "styled-components/macro";
import classNames from "classnames";
import { rem, rgba } from "polished";
import { Input as RSInput } from "reactstrap";

interface CheckboxProps {
  value?: boolean;
  id: string;
  label?: string | React.ReactElement;
  disabled?: boolean;
  isIndeterminate?: boolean;
  className?: string;
  checked?: boolean;
  name?: string;
  innerRef?: React.Ref<HTMLInputElement>;
  onValueChange?(checked: boolean, e: React.ChangeEvent<HTMLInputElement>): void;
}

const Checkbox = ({ value, id, label, onValueChange, className, ...props }: CheckboxProps): JSX.Element => {
  const onChangeCallback = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onValueChange) onValueChange(e.target.checked, e);
    },
    [onValueChange]
  );

  return (
    <InputWrapper className={classNames("custom-control custom-checkbox", className)}>
      <RSInputStyled
        {...props}
        id={id}
        type="checkbox"
        value={value}
        defaultChecked={value}
        onChange={onChangeCallback}
        className="custom-control-input"
      />
      <CheckboxLabel htmlFor={id} className="custom-control-label">
        {label}
      </CheckboxLabel>
    </InputWrapper>
  );
};

export default Checkbox;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  padding: 0;
`;

export const CheckboxLabel = styled.label`
  font-size: ${({ theme }) => theme.v3.fontSize.sm};
  color: ${({ theme }) => theme.v3.colors.neutralLight};
  margin: 0;
  cursor: pointer;

  &:before {
    border-color: ${({ theme }) => theme.v3.colors.neutralLightness};
  }
`;

//.custom-control-input:focus ~ .custom-control-label::before
const RSInputStyled = styled(({ isIndeterminate, ...props }) => <RSInput {...props} />)<{
  isIndeterminate?: boolean;
}>`
  ${({ theme, isIndeterminate, value }) => css`
    height: ${rem(24)};
    margin: 0 ${theme.v3.spacing.xs} 0 0;
    border-radius: 3px;
    position: static;

    &:focus {
      border-color: ${theme.v3.colors.primary};

      &:not(:checked) ~ ${CheckboxLabel} {
        &:before {
          border-color: ${theme.v3.colors.feedbackSupport};
        }
      }

      & ~ ${CheckboxLabel} {
        &:before {
          box-shadow: none;
        }
      }
    }

    &:not(:checked):disabled ~ ${CheckboxLabel} {
      &:before {
        background-color: ${theme.v3.colors.neutralLightest};
        border-color: ${theme.v3.colors.neutralLightest};
      }
    }

    &:checked ~ ${CheckboxLabel} {
      &::before {
        background-color: ${theme.v3.colors.primary};
        border-color: ${theme.v3.colors.primary};
      }
    }

    &:checked:disabled ~ ${CheckboxLabel} {
      &::before {
        background-color: ${theme.v3.colors.neutralLightest} !important;
        border-color: ${theme.v3.colors.neutralLightest};
      }
      &::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23${theme.v3.colors.neutralLightness.substr(
          1
        )}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
      }
    }

    &:disabled ~ ${CheckboxLabel} {
      color: ${theme.v3.colors.neutralLight};
    }

    ${isIndeterminate &&
    value === undefined &&
    css`
      & ~ ${CheckboxLabel} {
        &:before {
          background-color: ${theme.v3.colors.neutralLightest};
          border-color: ${theme.v3.colors.neutralLightest};
        }
        &:after {
          background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='minus' class='svg-inline--fa fa-minus fa-w-14' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='%23${theme.v3.colors.neutralLightness.substr(
            1
          )}' d='M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z'%3E%3C/path%3E%3C/svg%3E");
        }
      }
    `}
  `}
`;
