import React, { useCallback, useMemo } from "react";
import { ToBack } from "./Footer";
import { LargeButton } from "../common/styles";
import { Link, useLocation } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import { ContentContainer } from "./Content";
import { mq } from "utils/mediaQueries";
import Icon, { IconType } from "components/Icon/IconFontAwesome";
import * as H from "history";
import Button from "v3/components/Button/Button";
import { useMediaQueries } from "utils/hooks/useMediaQueries";

export interface ActionsFooterProps extends Omit<FooterProps, "spaceBetween"> {
  continueLabel?: string;
  continueDisabled?: boolean;
  continueIcon?: IconType | boolean;
  onContinue?: H.LocationDescriptor<any> | (() => void);
  backLabel?: string;
  backIcon?: boolean;
  onBack?: H.LocationDescriptor<any> | (() => void);
  column?: boolean;
  form?: string;
}

const ActionsFooter = ({
  backLabel,
  backIcon,
  onBack,
  continueLabel = "Continuar",
  continueDisabled,
  continueIcon = "long-arrow-right",
  onContinue,
  form,
  ...props
}: ActionsFooterProps): JSX.Element => {
  const { isXS } = useMediaQueries();
  const { state: routeState } = useLocation();
  const onBackTo = useMemo(() => {
    if (typeof onBack === "function") return undefined;

    if (typeof onBack === "string")
      return {
        pathname: onBack!,
        state: routeState,
      };

    return onBack;
  }, [onBack, routeState]);

  const onContinueButtonTag = useMemo(() => {
    if (!!onContinue && typeof onContinue !== "function") return Link;
  }, [onContinue]);

  const onContinueClickCallback = useCallback(
    (e: React.MouseEvent) => {
      if (typeof onContinue === "function") {
        e.preventDefault();
        onContinue();
      }
    },
    [onContinue]
  );

  const onContinueTo = useMemo(() => {
    return !!onContinue && typeof onContinue !== "function" ? (onContinue as H.LocationDescriptor<any>) : undefined;
  }, [onContinue]);

  return (
    <Footer {...props} spaceBetween={!!onBack}>
      {onBack ? (
        <ToBack
          to={onBackTo}
          onClick={typeof onBack === "function" ? onBack : undefined}
          text={backLabel}
          icon={backIcon}
        />
      ) : null}
      <LargeButtonStyled
        color="primary"
        size="md"
        tag={onContinueButtonTag}
        disabled={continueDisabled}
        onClick={onContinueClickCallback}
        to={onContinueTo}
        type={onContinue ? undefined : "submit"}
        form={form}
        block={isXS ? true : undefined}
      >
        {continueLabel}
        {continueIcon && (
          <ButtonArrowRightWrapper>
            <Icon icon={continueIcon as IconType} />
          </ButtonArrowRightWrapper>
        )}
      </LargeButtonStyled>
    </Footer>
  );
};

export default ActionsFooter;

interface FooterProps {
  spaceBetween: boolean;
}

const Footer: React.FC<FooterProps> = ({ children, ...props }) => {
  return (
    <FooterWrapper {...props}>
      <ContentContainer>{children}</ContentContainer>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.footer<{ spaceBetween: boolean }>`
  flex: 0 0;
  padding-bottom: 30px;

  ${ContentContainer} {
    display: flex;

    ${({ spaceBetween }) =>
      spaceBetween
        ? css`
            justify-content: space-between;
          `
        : css`
            justify-content: flex-end;
          `}

    ${mq.xsDown} {
      flex-direction: column-reverse;
      align-items: center;

      > * {
        margin-bottom: 10px;

        &:first-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;

const LargeButtonStyled = styled(Button)`
  position: relative;
  min-width: 228px;
`;

const ButtonArrowRightWrapper = styled.span`
  position: absolute;
  line-height: 1;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
`;
