import { maxBy } from "lodash";
import { RefObject, useCallback, useLayoutEffect, useEffect, useState } from "react";
import { useMediaQueriesV3 } from "utils/hooks/useMediaQueriesV3";

const DEFAULT_GAPS = {
  xs: 13,
  sm: 12,
  md: 13,
};

const DEFAULT_WIDTHS = {
  xs: 190,
  sm: 186,
  md: 190,
};

interface UseAnimateArgs {
  wrapperListRef: RefObject<HTMLUListElement>;
  parentRef: RefObject<HTMLDivElement>;
  itemsCount: number;
  gaps?: typeof DEFAULT_GAPS;
  widths?: typeof DEFAULT_WIDTHS;
}

const getGlobalOffsetLeft = (li: HTMLElement) => {
  let sum = 0;
  let parent = li;

  while (parent) {
    sum += parent.offsetLeft;
    parent = parent.offsetParent as HTMLElement;
  }
  return sum;
};

const useAnimation = ({
  wrapperListRef,
  parentRef,
  itemsCount,
  gaps = DEFAULT_GAPS,
  widths = DEFAULT_WIDTHS,
}: UseAnimateArgs) => {
  const { isSM, isXS } = useMediaQueriesV3();

  const mqSize = isSM ? "sm" : isXS ? "xs" : "md";
  const gap = gaps[mqSize];
  const cardWidth = widths[mqSize];
  // pageWidth is the <Container> width used to calculate when the cause should have its opacity changed.
  const pageWidth = wrapperListRef.current ? wrapperListRef.current!.offsetWidth - gap : 1;
  // pageWidthRounded is used to calculate the opacity of a card when they are leaving or entering the
  // "visualization" area. Before, pageWidth was used. But, when "mediaQuery XS" the card overflows to the side.
  // So, to make it to be shows as the designer wants, this variable was used.
  const pageWidthRounded = Math.ceil((pageWidth + gap) / (cardWidth + gap)) * (cardWidth + gap) - gap;
  // displayTotal represents how much causes will be seeing according to the width of the page.
  const displayTotal = Math.floor(pageWidth / cardWidth);

  const [currentPage, setCurrentPage] = useState<number | undefined>(undefined);

  const totalPages = Math.ceil((itemsCount ?? 0) / displayTotal);

  useEffect(() => {
    setCurrentPage(0);
  }, []);

  // Listens the currentAction state variable and applies the changes to DOM.
  useEffect(() => {
    if (!(wrapperListRef.current && parentRef.current)) return; // If the refs are not ready yet, don't do nothing.

    // This sets the target left that the element will be animated to.
    wrapperListRef.current.style.left = `-${(currentPage ?? 0) * ((cardWidth + gap) * displayTotal)}px`;

    let frameCallback: (x: number) => void;
    // startedAt record when the animation started.
    let startedAt = performance.now();

    // frameCallback is used to fade the cause cards.
    frameCallback = (now: number) => {
      if (!(wrapperListRef.current && parentRef.current)) return; // If the refs are not ready yet, don't do nothing.

      // Checks if passed 550 milliseconds from the start of the animation. If so, the CSS animation ended
      // and no need to continue animating.
      if (now - startedAt > 550) return;

      // causesOL is the causes UL offsetLeft.
      const causesOL = getGlobalOffsetLeft(wrapperListRef!.current) + 15;
      // containerOL is the causes UL parentNode offsetLeft.
      const containerOL = getGlobalOffsetLeft(parentRef!.current);
      // currentOL is relative offsetLeft causes UL in relation to its container. We cannot use the `style.left`
      // because it is being animated. So the `style.left` saves only the target value. We should have a better way
      // to find this value, but I could not find it.
      const currentOL = causesOL - containerOL;

      // This for goes through all causes checking its position and applying the opacity.
      for (let i = 0; i < wrapperListRef!.current!.childNodes!.length; i++) {
        const node = wrapperListRef.current?.childNodes[i] as HTMLElement;
        if (node.tagName !== "LI") continue; // If not a LI, it is not a cause.

        const li = node as HTMLLIElement;

        const liLeft = currentOL + li.offsetLeft; // Checks the position of the LI according to the `<Container>`.

        if (liLeft < 0 && liLeft > -cardWidth) {
          // Case 1: Cause entering/leaving the container from the left.
          li.style.opacity = (1 - liLeft / -cardWidth).toString();
        } else if (liLeft > pageWidthRounded - cardWidth && liLeft < pageWidthRounded) {
          // Case 2: Cause entering/leaving the container from the right.
          li.style.opacity = (1 - (liLeft - (pageWidthRounded - cardWidth)) / cardWidth).toString();
        } else if (liLeft < 0 || liLeft > pageWidthRounded) {
          // Case 3: Outside the container.
          li.style.opacity = "0";
        } else {
          // Case 4: Inside the container.
          li.style.opacity = "1";
        }
        // Don't display none, that would change the offset of the right siblings. So, the ease solution is to
        // disable the `pointerEvents`. This way is as the element is not there, not clickable.
        li.style.pointerEvents = li.style.opacity === "0" ? "none" : "";
      }
      window.requestAnimationFrame(frameCallback);
    };
    frameCallback(0);
  }, [currentPage, gap, pageWidth, pageWidthRounded, cardWidth]);

  const handleArrowsBack = useCallback(() => {
    setCurrentPage((currentPage) => Math.max(0, (currentPage ?? 0) - 1));
  }, [currentPage]);

  const handleArrowsForward = useCallback(() => {
    setCurrentPage((currentPage) => Math.min(totalPages, (currentPage ?? 0) + 1));
  }, [totalPages]);

  return {
    handleArrowsBack,
    handleArrowsForward,
    backDisabled: totalPages === 0 || (currentPage ?? 0) === 0,
    forwardDisabled: (currentPage ?? 0) + 1 >= totalPages,
    displayTotal,
    currentPage,
  };
};

export default useAnimation;
