import http from "config/http";
import { ResponseStatus, ResponseWithParams } from "../../definitions";
import { Login } from "core/api/definitions";
import { AxiosResponse } from "axios";

export interface DeleteAccountRequest {
  password: string;
}

export type DeleteAccountResponse = ResponseWithParams<ResponseStatus, Login>;

const deleteAccount = async ({ password }: DeleteAccountRequest): Promise<AxiosResponse<DeleteAccountResponse>> =>
  http.delete<DeleteAccountResponse>(`/users/account`, { data: { password } });

export default deleteAccount;
