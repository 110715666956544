import React from "react";
import styled, { css } from "styled-components/macro";
import { Size } from "utils/types";
import mediaQueries from "utils/mediaQueries";
import { transparentize } from "polished";

export interface PhotoProps {
  size?: Size;
  hiddenSm?: boolean;
}

const Photo: React.FunctionComponent<PhotoProps> = ({ size, children, ...props }) => (
  <Container size={size} {...props}>
    {children}
  </Container>
);

export default Photo;

export const Container = styled.div<{ size?: Size; hiddenSm?: boolean }>`
  border-radius: 100%;
  background-color: ${transparentize(0.8, "#e2e6f4")};
  margin-bottom: 20px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    object-fit: cover;
    object-position: center;
  }

  height: 100px;
  width: 100px;

  ${({ size }) =>
    size === "md" &&
    css`
      height: 70px;
      width: 70px;
    `}

  ${({ hiddenSm }) =>
    hiddenSm &&
    mediaQueries.sm(
      css`
        display: none;
      `
    )}

  ${({ size }) =>
    size === "sm" &&
    css`
      height: 50px;
      width: 50px;
    `}

  ${({ size }) =>
    size === "xs" &&
    css`
      height: 34px;
      width: 34px;
    `}
`;
