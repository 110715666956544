import React from "react";
import imageDefault from "assets/imgs/default-images.png";
import styled from "styled-components";

export interface ImgProps {
  src?: string;
  alt?: string;
  width?: string | number;
  height?: string | number;
  title?: string;
}

const ImgComp = React.forwardRef<HTMLImageElement, ImgProps>(({ src, ...props }, ref) => (
  <img ref={ref} src={src || imageDefault} {...props} loading="lazy" />
));

const Img = styled(ImgComp)``;

export default Img;
