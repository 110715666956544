import { AxiosResponse } from "axios";
import http from "config/http";
import { ResponseStatus, ResponseWithParams } from "../definitions";

export interface AssignDonationRequest {
  action_slug: string;
  material_id: string;

  // Data
  material_list_items_id: string[];
}

export interface AssignDonationResponseParams {
  slug: string;
  _id: string;
}

export type AssignDonationResponse = ResponseWithParams<ResponseStatus, AssignDonationResponseParams>;

const assign = async ({
  action_slug,
  material_id,
  ...data
}: AssignDonationRequest): Promise<AxiosResponse<AssignDonationResponse>> => {
  return http.post<AssignDonationResponse>(`/actions/${action_slug}/donors/materials/${material_id}/assign`, data);
};

export default assign;
