import { createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import { isEqual } from "lodash";
import { convertArraytoLatLng, convertLatLngToArray } from "utils/helpers";
import { AddressCoordinates, Coordinate } from "./api/definitions";

interface IState {
  actived?: boolean;
  address: {
    /**
     * @deprecated Should be removed when all pages use only Coordinate.
     */
    coordinates?: AddressCoordinates;
    coordinate?: Coordinate;
  };
}

const geolocationSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "geolocation",
  initialState: {
    address: {},
  },
  reducers: {
    updateCoordinatesStatus(state, action?: PayloadAction<boolean>) {
      state.actived = action?.payload;
      if (!state.actived) {
        state.address.coordinates = undefined;
        state.address.coordinate = undefined;
      }
    },
    updateCoordinates(state, action: PayloadAction<AddressCoordinates | Coordinate>) {
      //TODO(Jeconias): Remove it when all pages use only `state.address.coordinate`
      if (Array.isArray(action.payload) && !isEqual(state.address.coordinates, action.payload)) {
        const coords = convertArraytoLatLng(action.payload);

        state.address.coordinates = action.payload;
        state.address.coordinate = {
          lat: coords.lat(),
          lng: coords.lng(),
        };
        state.actived = true;
      }

      if (
        !Array.isArray(action.payload) &&
        typeof action.payload === "object" &&
        !isEqual(state.address.coordinate, action.payload)
      ) {
        state.actived = true;
        state.address.coordinate = action.payload;

        //TODO(Jeconias): Remove it when all pages use only `state.address.coordinate`
        state.address.coordinates = convertLatLngToArray(action.payload);
      }
    },
  },
});

export const { updateCoordinates, updateCoordinatesStatus } = geolocationSlice.actions;
export default geolocationSlice;
