import { darken } from "polished";
import React, { ElementType } from "react";
import { Link as LinkComponent, LinkProps } from "react-router-dom";
import styled from "styled-components/macro";
interface HybridLinkProps {
  tag?: string | React.ElementType<any>;
  to?: string;
  title?: string;
  id?: string;
  href?: string;
  target?: string;
}

const HybridLink: React.FC<HybridLinkProps> = (props) => {
  if (props.tag === "a" || (!props.tag && props.href)) {
    const { children, ...ps } = props;
    return <a {...ps}>{children}</a>;
  } else {
    return <LinkComponent to={(props as LinkProps).to} {...props} />;
  }
};

/**
 * @deprecated
 */
const Link = styled(HybridLink)<React.PropsWithChildren<HybridLinkProps>>`
  color: ${({ theme }) => theme.v3.colors.primary};

  svg {
    fill: currentColor;
  }

  &:hover {
    color: ${({ theme }) => darken(0.1, theme.v3.colors.primary)};
  }
`;

export default Link;
