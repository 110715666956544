import { rem } from "polished";
import React from "react";
import styled, { css } from "styled-components/macro";
import Icon, { IconProps } from "../Icons/Icon";

export interface InputIconProps extends IconProps {
  tabIndex?: number;
}

const InputIcon = ({ onClick, tabIndex, ...props }: InputIconProps) => {
  const isButton = !!onClick;

  return (
    <InputIconStyled
      tabIndex={tabIndex}
      onClick={onClick}
      as={isButton ? "button" : undefined}
      type={isButton ? "button" : undefined}
      className="input-icon"
    >
      <Icon {...props} />
    </InputIconStyled>
  );
};

export default InputIcon;

const InputIconStyled = styled.span`
  position: absolute;
  right: ${({ theme }) => theme.v3.spacing.xs};
  padding: ${({ theme }) => theme.v3.spacing.xs};
  // This is a fixed height because the element parent have a variable height!
  top: ${rem(13)};
  border: none;
  background: none;

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
      &:focus {
        outline: none;
      }
    `}
`;
