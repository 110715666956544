import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit";
import api from "core/api";
import { RequestableState } from "core/api/definitions";
import { ContactRequest } from "core/api/site/contact";
import decoreThunk from "core/decorators/decorate";
import requestLoading from "core/decorators/requestLoading";
import asyncThunk from "core/decorators/toolkit";

export const sendContact = asyncThunk("pages/contact", (data: ContactRequest) => api.site.contact(data));

type IState = RequestableState;

const contactSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "pageContact",
  initialState: {
    loading: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, sendContact, [requestLoading()]);
  },
});

export default contactSlice;
