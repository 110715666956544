import { rgba } from "polished";
import React from "react";
import styled, { css } from "styled-components";
import { ColorV3 } from "utils/types";

interface BadgeProps {
  color?: ColorV3;
}

const Badge: React.FC<BadgeProps> = ({ ...props }) => <BadgeStyled {...props} />;

export default Badge;

const BadgeStyled = styled.span<{ color?: ColorV3 }>`
  ${({ theme, color }) => css`
    font-size: ${theme.v3.fontSize.sm};
    color: ${color ? theme.v3.colors[color] : theme.v3.colors.feedbackSupport};
    background-color: ${rgba(color ? theme.v3.colors[color] : theme.v3.colors.feedbackSupport, 0.1)};
    border-radius: ${theme.v3.spacing.sm};
    padding: ${theme.v3.spacing.xxs} ${theme.v3.spacing.sm};
    margin: 0;
  `};
`;
