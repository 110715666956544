//TODO(Jeconias): Move it to outside API

import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit";
import { LoadingStatus, MaterialList } from "core/api/definitions";
import decoreThunk from "core/decorators/decorate";
import requestLoading from "core/decorators/requestLoading";
import { donationActions } from "./donations_actions";

interface IState {
  loading: LoadingStatus;
  list: {
    [k: string]: {
      loading: LoadingStatus;
      data?: MaterialList;
    };
  };
}

const donationsSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "siteDonations",
  initialState: {
    loading: "idle",
    list: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, donationActions.showMaterials, [
      requestLoading(),
      {
        pending: (state, action) => {
          // Set the loading for the entity...
          if (!state.list[action.meta.arg.materialId]) {
            state.list[action.meta.arg.materialId] = {
              loading: "loading",
            };
          } else {
            state.list[action.meta.arg.materialId].loading = "loading";
          }
        },
        fulfilled: (state, action) => {
          state.list[action.meta.arg.materialId].loading = "ok";
          state.list[action.meta.arg.materialId].data = action.payload.params;
        },
        rejected: (state, action) => {
          state.list[action.meta.arg.materialId].loading = "error";
        },
      },
    ]);
  },
});

export default donationsSlice;
