import React, { useContext, useMemo } from "react";
import styled, { css, ThemeContext } from "styled-components/macro";
import { LocationDescriptor } from "history";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { formatDistance } from "utils/date";
import useDateNow from "hooks/useDateNow";
import Icon from "v3/components/Icons/Icon";
import { IconType } from "icons";
import { transition } from "utils/animation";
import { ColorV3 } from "utils/types";

interface NotificationProps {
  text?: string;
  at?: Date;
  icon?: "check-circle" | "info-square"; // Types from backend
  to?: LocationDescriptor;
  onSelect(): void;
}

const Notification = ({ text, at, icon, to, onSelect }: NotificationProps) => {
  const { i18n } = useTranslation();
  const { now } = useDateNow();
  const theme = useContext(ThemeContext);

  const timeDistance = useMemo(
    () =>
      at &&
      formatDistance(at, new Date(), {
        language: i18n.language,
        addSuffix: true,
      }),
    [at, now]
  );

  const typeMemorized = useMemo((): { icon: IconType; color: ColorV3 } | undefined => {
    if (icon === "check-circle")
      return {
        icon: "validation",
        color: "feedbackSuccess",
      };
    if (icon === "info-square")
      return {
        icon: "info",
        color: "feedbackError",
      };
  }, [icon, theme]);

  return (
    <LinkStyled to={to ?? "/"} onClick={onSelect}>
      {typeMemorized?.icon && <Icon icon={typeMemorized?.icon} color={typeMemorized?.color || "neutralLight"} />}
      <Text dangerouslySetInnerHTML={{ __html: text || "" }} />
      <small>{timeDistance}</small>
    </LinkStyled>
  );
};

export default Notification;

const Text = styled.span``;

const LinkStyled = styled(Link)`
  display: block;
  font-weight: 500;
  ${({ theme }) => css`
    padding: ${theme.v3.spacing.sm};
    font-size: ${theme.v3.fontSize.sm};
    transition: ${transition.fast("background-color")};
    border: 1px solid transparent;

    &:hover {
      background-color: ${theme.v3.colors.neutralBackground};
    }

    &:focus {
      border-color: ${theme.v3.colors.feedbackSupport};
      outline: 0;
    }

    svg {
      float: right;
      margin-left: ${theme.v3.spacing.xs};
      margin-bottom: ${theme.v3.spacing.xs};
    }

    ${Text} {
      display: block;
      color: ${theme.v3.colors.neutralBase};
      margin-bottom: ${theme.v3.spacing.xs};
    }

    small {
      display: block;
      color: ${theme.v3.colors.neutralLight};
      font-size: ${theme.v3.fontSize.xs};
    }
  `}
`;
