import subscribeTypes from "./types";
import createReducer from "../createReducer";

const INITIAL_STATE = {
  sbs: {
    loading: true,
  },
  spd: {
    loading: true,
    action_data: {
      title: "",
      slug: "",
    },
    project_data: {
      name: "",
      slug: "",
      image: "",
    },
  },
  sgv: {
    loading: true,
    subscribe_data: [],
    action_data: {
      title: "",
      name: "",
    },
    project_data: {
      name: "",
      slug: "",
      images: {
        cover: "",
      },
    },
  },
  sgvs: {
    loading: true,
  },
  sbc: {
    loading: true,
  },
  asb: {},
  gsb: {
    loading: true,
  },
};

const subscribeReducer = createReducer(INITIAL_STATE, {
  [subscribeTypes.SBS_REQUESTED]: (state) => ({
    ...state,
    sbs: {
      loading: true,
      status: "waiting",
    },
  }),
  [subscribeTypes.SBS_RECEIVED]: (state, action) => ({
    ...state,
    sbs: {
      ...action.payload,
      loading: false,
      success: true,
      status: "received",
    },
  }),
  [subscribeTypes.SBS_FAILED]: (state, action) => ({
    ...state,
    sbs: {
      ...INITIAL_STATE.sbs,
      ...action.payload,
      loading: false,
      success: false,
      status: "failed",
    },
  }),

  [subscribeTypes.SPD_REQUESTED]: (state) => ({
    ...state,
    spd: {
      ...INITIAL_STATE.spd,
      loading: true,
      status: "waiting",
    },
  }),
  [subscribeTypes.SPD_RECEIVED]: (state, action) => ({
    ...state,
    spd: {
      ...action.payload,
      loading: false,
      status: "received",
    },
  }),
  [subscribeTypes.SPD_FAILED]: (state, action) => ({
    ...state,
    spd: {
      ...INITIAL_STATE.spd,
      ...action.payload,
      loading: false,
      status: "failed",
    },
  }),
  [subscribeTypes.SBC_REQUESTED]: (state) => ({
    ...state,
    sbc: {
      loading: true,
      status: "waiting",
    },
  }),
  [subscribeTypes.SBC_RECEIVED]: (state, action) => ({
    ...state,
    sbc: {
      ...action.payload,
      loading: false,
      status: "received",
    },
  }),
  [subscribeTypes.SBC_FAILED]: (state, action) => ({
    ...state,
    sbc: {
      ...INITIAL_STATE.spd,
      ...action.payload,
      loading: false,
      status: "failed",
    },
  }),

  [subscribeTypes.ASB_REQUESTED]: (state) => ({
    ...state,
    asb: {
      loading: true,
      status: "waiting",
    },
  }),
  [subscribeTypes.ASB_RECEIVED]: (state, action) => ({
    ...state,
    asb: {
      ...action.payload,
      loading: false,
      status: "received",
      success: true,
    },
  }),
  [subscribeTypes.ASB_FAILED]: (state, action) => ({
    ...state,
    asb: {
      ...INITIAL_STATE.asb,
      ...action.payload,
      loading: false,
      status: "failed",
      success: false,
    },
  }),

  [subscribeTypes.SGV_REQUESTED]: (state) => ({
    ...state,
    sgv: {
      loading: true,
      status: "waiting",
    },
  }),
  [subscribeTypes.SGV_RECEIVED]: (state, action) => ({
    ...state,
    sgv: {
      ...action.payload,
      loading: false,
      status: "received",
    },
  }),
  [subscribeTypes.SGV_FAILED]: (state, action) => ({
    ...state,
    sgv: {
      ...INITIAL_STATE.sgv,
      ...action.payload,
      loading: false,
      status: "failed",
    },
  }),

  [subscribeTypes.SGVS_REQUESTED]: (state) => ({
    ...state,
    sgvs: {
      loading: true,
      status: "waiting",
    },
  }),
  [subscribeTypes.SGVS_RECEIVED]: (state, action) => ({
    ...state,
    sgvs: {
      ...action.payload,
      loading: false,
      status: "received",
    },
  }),
  [subscribeTypes.SGVS_FAILED]: (state, action) => ({
    ...state,
    sgvs: {
      ...INITIAL_STATE.sgvs,
      ...action.payload,
      loading: false,
      status: "failed",
    },
  }),
  [subscribeTypes.GSB_REQUESTED]: (state) => ({
    ...state,
    gsb: {
      ...INITIAL_STATE.gsb,
      loading: true,
      status: "waiting",
    },
  }),
  [subscribeTypes.GSB_RECEIVED]: (state, action) => ({
    ...state,
    gsb: {
      ...action.payload,
      loading: false,
      status: "received",
    },
  }),
  [subscribeTypes.GSB_FAILED]: (state, action) => ({
    ...state,
    gsb: {
      ...INITIAL_STATE.gsb,
      ...action.payload,
      loading: false,
      status: "failed",
    },
  }),
  [subscribeTypes.EV_REQUESTED]: (state) => ({
    ...state,
    loading: true,
    status: "waiting",
  }),
  [subscribeTypes.EV_RECEIVED]: (state, action) => ({
    ...state,
    sbs: {
      ...action.payload,
      loading: false,
      status: "received",
      success: true,
    },
  }),
  [subscribeTypes.EV_FAILED]: (state, action) => ({
    ...state,
    sbs: {
      ...action.payload,
      loading: false,
      status: "failed",
    },
  }),
  [subscribeTypes.CLEAN_SUBSCRIBE_DATA]: (state) => ({
    ...state,
    sbs: {
      ...INITIAL_STATE.sbs,
    },
    sgvs: {
      ...INITIAL_STATE.sgvs,
    },
  }),
});

export default subscribeReducer;
