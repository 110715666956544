import React, { ElementType } from "react";
import { Input as RSBaseInput, InputProps as RSBaseInputProps } from "reactstrap";
import styled, { css } from "styled-components/macro";
import { rem, rgba } from "polished";
import InputMask from "react-input-mask";

export type BaseInputValue = string | string[] | number | boolean;

export interface BaseInputProps<T = BaseInputValue> extends Omit<RSBaseInputProps, "onChange" | "value"> {
  hasCounter?: boolean;
  tag?: string | ElementType;
  mask?: string | RegExp;
  maskChar?: string | null;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  //onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: T;
}

const getCustomProps = ({ mask, maskChar }: BaseInputProps) => {
  const customProps: BaseInputProps = {};

  if (mask) {
    customProps.tag = InputMask;
    customProps.mask = mask;

    if (!maskChar) {
      customProps.maskChar = null;
    }
  }

  return customProps;
};

const BaseInput = ({ type, onChange, mask, ...props }: BaseInputProps): JSX.Element => {
  const customProps = getCustomProps({ mask });
  return <BaseInputStyled type={type} {...props} {...customProps} onChange={onChange} />;
};

export default BaseInput;

export const BaseInputStyle = css<BaseInputProps>`
  box-sizing: border-box;
  height: 55px;
  border: 1px solid #bcc4de;
  border-radius: 5px;
  background-color: #fff;
  color: #495057;
  line-height: ${rem(18)};

  :focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.secondary};
    box-shadow: 0 0 0 0.2rem ${({ theme }) => rgba(theme.colors.secondary, 0.07)};
  }

  ::placeholder {
    color: #8798ad;
  }

  :disabled,
  [readonly] {
    background-color: ${rgba("#e2e6f4", 0.5)};
    border-color: ${rgba("#e2e6f4", 0.5)};
  }

  ${({ invalid, valid, hasCounter }) =>
    (invalid || valid) &&
    hasCounter &&
    css`
      background-image: none !important;
    `}

  ${({ bsSize }) =>
    bsSize === "sm" &&
    css`
      height: 40px;
    `}
`;

const BaseInputStyled = styled(RSBaseInput)`
  box-sizing: border-box;
  height: 55px;
  border: 1px solid #bcc4de;
  border-radius: 5px;
  background-color: #fff;
  color: #495057;
  line-height: ${rem(18)};

  :focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.secondary};
    box-shadow: 0 0 0 0.2rem ${({ theme }) => rgba(theme.colors.secondary, 0.07)};
  }

  ::placeholder {
    color: #8798ad;
  }

  :disabled,
  [readonly] {
    background-color: ${rgba("#e2e6f4", 0.5)};
    border-color: ${rgba("#e2e6f4", 0.5)};
  }

  ${({ invalid, valid, hasCounter }) =>
    (invalid || valid) &&
    hasCounter &&
    css`
      background-image: none !important;
    `}

  ${({ bsSize }) =>
    bsSize === "sm" &&
    css`
      height: 40px;
    `}
`;
