import SupportText from "components/common/SupportText";
import React, { useCallback, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { MainTitle } from "wizard/v3/components/common/styles";
import ActionsFooter from "wizard/v3/components/Layout/ActionsFooter";
import FormWizard from "wizard/v3/pages/common/FormWizard";
import InputAccessInfoBase, { AccessDataInfoBaseProps } from "./InputAccessInfoBase";

interface AccessDataPageProps extends AccessDataInfoBaseProps {}

/** @deprecated */
const InputAccessInfoPage: React.FC<AccessDataPageProps> = ({
  formData,
  onContinue,
  onTouched,
  onChange,
  onBack,
  onBlur,
  error,
  loading,
}) => {
  const { t } = useTranslation();

  const [hasError, setHasError] = useState<string>();

  const onContinueCallback = useCallback(() => {
    if (onContinue) onContinue(formData ?? {});
  }, [onContinue, formData]);

  const onChangeCallback = useCallback(
    (data) => {
      if (onChange) onChange(data);
    },
    [onChange]
  );

  const onTouchedCallback = useCallback(
    (touched) => {
      if (onTouched) onTouched(touched);
    },
    [onTouched]
  );

  const onErrorCallback = useCallback((error) => setHasError(error), []);

  return (
    <FormWizard onContinue={onContinueCallback}>
      <InputAccessContainer>
        <Helmet title={t("wizard.pages.accessData.title")} />
        <MainTitle size="lg">{t("wizard.pages.accessData.mainTitle")}</MainTitle>
        <SupportText size="md">{t("wizard.pages.accessData.description")}</SupportText>
        <InputsContainer>
          <InputAccessInfoBase
            formData={formData}
            onChange={onChangeCallback}
            handleError={onErrorCallback}
            onTouched={onTouchedCallback}
            error={error}
            onBlur={onBlur}
            autoFocus
          />
        </InputsContainer>
      </InputAccessContainer>
      <ActionsFooter onBack={onBack} continueDisabled={!!hasError || loading} />
    </FormWizard>
  );
};

export default InputAccessInfoPage;

export const InputAccessContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  margin: auto;

  & > span {
    margin-bottom: 20px;
  }

  input {
    padding-right: 45px;
  }

  input[type="tel"] {
    padding-right: 20px;
  }

  input + div > svg {
    cursor: pointer;
  }
`;

const InputsContainer = styled.div``;
