import { AxiosResponse } from "axios";
import http from "config/http";
import { ResponseWithParams, ResponseStatus, ActionStatus } from "core/api/definitions";

export interface ActionHistory {
  _id: string;
  description: string;
  object: string;
  project_data: { _id: string };
  subscribe_data: { _id: string };
  validated_by: { user_id: string; user_name: string };
  validation_status: ActionStatus;
  created_at: string;
  action_data: {
    _id: string;
  };
}

export type ActionHistoryResponse = ResponseWithParams<ResponseStatus, ActionHistory[]>;

export default async ({ actionID }: { actionID: string }): Promise<AxiosResponse<ActionHistoryResponse>> =>
  http.get<ActionHistoryResponse>(`/validations/actions/${actionID}`);
