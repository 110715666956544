import React from "react";
import styled, { css } from "styled-components/macro";
import { Size, Colors } from "utils/types";

type Color = Exclude<Colors, "link">;

interface CircleProps {
  size?: Size;
  iconColor?: Color;
  backgroundColor?: string;
  borderColor?: string;
}

const Circle: React.FC<CircleProps> = ({ children, ...props }) => <CircleStyle {...props}>{children}</CircleStyle>;

export default Circle;

const CircleStyle = styled.div<{ size?: Size; borderColor?: string; backgroundColor?: string; iconColor?: Color }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  padding: 5px;
  background-color: ${({ backgroundColor }) => backgroundColor || "#f9fafc"};
  margin-right: 10px;
  border: 1px solid ${({ borderColor }) => borderColor || "transparent"};

  svg {
    fill: ${({ iconColor, theme }) => iconColor && (theme.colors[iconColor] || iconColor || "#9facbd")};
    /** //TODO(Jeconias): Remove it when the icon is in the standard */
    color: ${({ iconColor, theme }) => iconColor && (theme.colors[iconColor] || iconColor || "#9facbd")};
    width: 12px;
    height: 100%;
  }

  ${({ size }) =>
    size === "md" &&
    css`
      height: 51px;
      width: 51px;

      svg {
        width: 18px;
      }
    `}
  ${({ size }) =>
    size === "sm" &&
    css`
      height: 40px;
      width: 40px;
      min-width: 40px;

      svg {
        width: 20px;
      }
    `}
`;
