import http from "config/http";
import { LoginResponse } from "./login";
import { LocationDescriptor } from "history";
import { AxiosResponse } from "axios";

interface SignInUpGmailRequest {
  token: string;
  redirect?: LocationDescriptor<any>;
}

type SignInUpGmailResponse = LoginResponse;

const signInUpGmail = async ({
  token,
  ...props
}: SignInUpGmailRequest): Promise<AxiosResponse<SignInUpGmailResponse>> => {
  return http.post<SignInUpGmailResponse>(`/auth/gmail/token`, { token });
};

export default signInUpGmail;
