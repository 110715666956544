import Button from "components/Button/Button";
import Label from "components/common/Label";
import SupportText from "components/common/SupportText";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "components/Modal";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";

interface ModalRequestValueProps {
  className?: string;

  isOpen?: boolean;
  centered?: boolean;
  size?: "sm";
  disabled?: boolean;
  formID?: string;
  onClose(): void;
  onConfirm?(): void;
  modalInfos: {
    title: string;
    description: string;
  };
}

const ModalRequestValue: React.FC<ModalRequestValueProps> = ({
  isOpen,
  modalInfos,
  disabled,
  onClose,
  onConfirm,
  formID,
  children,
  ...props
}) => {
  const { t } = useTranslation("components");

  return (
    <Modal centered isOpen={isOpen} toggle={onClose} {...props}>
      <ModalHeader centered toggle={onClose} alignedClose={false}>
        {modalInfos.title}
      </ModalHeader>
      <ModalBody>
        <SupportText size="md">{modalInfos.description}</SupportText>
        {children}
      </ModalBody>
      <ModalFooter>
        <Button
          form={formID}
          onClick={() => {
            if (onConfirm) onConfirm();
          }}
          block
          disabled={disabled}
        >
          {t("phoneModal.button")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalRequestValue;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 20px;

  ${Label} {
    margin: 6px 0;
  }
`;
