import { AxiosResponse } from "axios";
import http from "config/http";
import { Response, ResponseStatus } from "core/api/definitions";

export type ValidateVolunteerRequest = {
  subscribeSlug: string;
  volunteerId: string;
  status: "approved" | "reject";
  message: string;
} & ({ slot: string[] } | { registration_id: string[] });

export type ValidateVolunteerResponse = Response<ResponseStatus>;

export default async ({
  subscribeSlug,
  volunteerId,
  ...props
}: ValidateVolunteerRequest): Promise<AxiosResponse<ValidateVolunteerResponse>> => {
  return http.post<ValidateVolunteerResponse>(`subscribe/${subscribeSlug}/volunteer/${volunteerId}/validate`, {
    ...props,
  });
};
