import { showAlert } from "core/alerts/actions";
import api from "core/api";
import { LoadingStatus } from "core/api/definitions";
import { SubscribeAssignRequest } from "core/api/subscribe/assign";
import { login } from "core/auth/actions";
import { subscribeActions } from "core/subscribe/subscribe_actions";
import { createStoreNewUser, createStoreNewWithDocument } from "core/wizard/create/user";
import useCountry from "hooks/useCountry";
import useTranslationV3 from "hooks/useTranslationV3";
import { isEqual } from "lodash";
import queryString from "query-string";
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import routes from "routes";
import { APIParamsToString } from "utils/helpers";
import ConfirmationStep from "./Confirmation";
import FinishedStep from "./Finished";
import SignInStep from "./SignIn";
import SignUpStep from "./SignUp";
import { LocationDescriptor } from "history";
import { handleAssignError } from "../ModalSubscribe";
import { parsePhoneNumber, PhoneNumber } from "react-phone-number-input";
import { sendEmailValidationCodeRequest } from "core/api/users/sendEmailValidationCode";

export interface Base {
  onSubmit(e: React.FormEvent): void;
  toggleStep(): void;
  loading: LoadingStatus;
}

export interface SignUpFormData {
  name: string;
  email: string;
  phone: string;
  password: string;
  password_confirmation: string;
  accept_terms: boolean;
  acceptToReceiveEmails: boolean;
  document?: string;
}

type StepType = "signIn" | "signUp" | "confirmation" | "finished" | undefined;

interface SignInSignUpStepsProps {
  onLogin?: () => void;
  onSignUp?: () => void;
  setHasPersonalDocument?: (hasPersonalDocument: { has_phone: boolean; has_document: boolean }) => void;
  setStep?: Dispatch<SetStateAction<"" | "success" | "login" | "phone" | "document">>;
}

const SignInSignUpSteps = ({ onLogin, onSignUp, setHasPersonalDocument, setStep }: SignInSignUpStepsProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [documentAux, setDocumentAux] = useState<string>("");
  const [subscribeDocumentAux, setSubscribeDocumentAux] = useState<boolean>(false);
  const { t } = useTranslationV3();

  const { country } = useCountry();

  const { search, state } = useLocation<{
    from?: LocationDescriptor;
    signInUpSubscribeData?: SubscribeAssignRequest;
  }>();

  const qs = queryString.parse(search);

  const currentStep =
    Object.keys(qs).indexOf("open") !== -1 && (qs.open === "signIn" || qs.open === "signUp") ? qs.open : undefined;

  const [modalStep, setModalStep] = useState<StepType>(currentStep ?? "signIn");
  const [signUpData, setSignUpData] = useState<SignUpFormData>();
  const [loading, setLoading] = useState<LoadingStatus>("idle");

  // const redirectToLocal = state?.from || routes.opportunities.toString();

  const onSubmitLoginCallback = useCallback(
    async (data: { email: string; password: string; memorizePassword: boolean }) => {
      setLoading("loading");
      const response: any = await dispatch(login({ email: data.email, password: data.password, redirect: false }));

      if (login.rejected.match(response)) {
        dispatch(showAlert("danger", t(`${response?.payload?.response?.message_translated}`)));
        setLoading("error");
      } else {
        if (login.fulfilled.match(response)) {
          if (onLogin) onLogin();
        }
      }
    },
    [t, dispatch, setLoading]
  );

  const handleEmailValidation = async (newData: sendEmailValidationCodeRequest) => {
    await api.users.sendEmailValidationCode(newData);
    setLoading("ok");
  };

  const onSubmitRegistrationCallback = useCallback(
    async (data: SignUpFormData) => {
      if (!isEqual(data, signUpData)) setSignUpData(data);
      setLoading("loading");
      let phoneNumber = "";
      let phoneCountry = country;

      const phoneParsed = parsePhoneNumber(data.phone as any);
      phoneNumber = phoneParsed?.nationalNumber ?? "";
      if (phoneParsed?.countryCallingCode) phoneCountry = `+${phoneParsed.countryCallingCode}`;

      const newData = {
        name: data?.name || "",
        email: data?.email || "",
        contacts: [
          {
            country: phoneCountry,
            type: "phone",
            value: `${phoneNumber}`.replace(/[.|-]/g, ""),
          },
        ],
        password: data?.password || "",
        password_confirmation: data?.password_confirmation || "",
        accept_terms: data.accept_terms,
        gt_18_years: data.accept_terms,
        receive_emails: data.acceptToReceiveEmails || false,
        document_number: data.document || "",
      };
      try {
        await handleEmailValidation(newData);
        setModalStep("confirmation");
      } catch (e) {
        const params = e.response?.data?.params || {};
        dispatch(showAlert("danger", t([`errors.${APIParamsToString(params).toString()}`, "errors.default"])));
        setLoading("error");
        setModalStep("signUp");
      }
    },
    [setModalStep, signUpData, setLoading, dispatch]
  );

  const onSubmitConfirmationCallback = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const code = (e.target as any)?.code?.value || undefined;
      if (!code || code?.length !== 4 || !signUpData) return;
      setLoading("loading");

      const parsedPhone = parsePhoneNumber(signUpData.phone);
      if (!subscribeDocumentAux) {
        const response: any = await dispatch(
          createStoreNewUser({
            name: signUpData?.name,
            email: signUpData?.email,
            contacts: [
              {
                country: (parsedPhone?.countryCallingCode && `+${parsedPhone?.countryCallingCode}`) || country,
                type: "phone",
                value: `${parsedPhone?.nationalNumber}`,
              },
            ],
            password: signUpData?.password,
            password_confirmation: signUpData?.password_confirmation,
            accept_terms: signUpData.accept_terms,
            gt_18_years: signUpData.accept_terms,
            receive_emails: signUpData.acceptToReceiveEmails || false,
            code,
            document_number: documentAux,
          })
        );
        setLoading("ok");
        if (response.payload.params.user_data.has_phone && setHasPersonalDocument)
          setHasPersonalDocument({ has_phone: true, has_document: true });
        if (createStoreNewUser.fulfilled.match(response)) {
          if (onSignUp) {
            onSignUp();
          } else {
            setModalStep("finished");
          }
          setSignUpData(undefined);
        } else {
          dispatch(showAlert("danger", t([`${response.payload?.response?.message_translated}`, "errors.default"])));
          if (response.payload?.response?.message !== "users.code-invalid") {
            setModalStep("signUp");
          }
        }
      } else {
        const response: any = await dispatch(
          createStoreNewWithDocument({
            name: signUpData?.name,
            email: signUpData?.email,
            contacts: [
              {
                country: (parsedPhone?.countryCallingCode && `+${parsedPhone?.countryCallingCode}`) || country,
                type: "phone",
                value: `${parsedPhone?.nationalNumber}`,
              },
            ],
            password: signUpData?.password,
            password_confirmation: signUpData?.password_confirmation,
            accept_terms: signUpData.accept_terms,
            gt_18_years: signUpData.accept_terms,
            receive_emails: signUpData.acceptToReceiveEmails || false,
            code,
            document_number: documentAux,
          })
        );
        if (response.payload.params.has_phone && setHasPersonalDocument)
          setHasPersonalDocument({ has_phone: true, has_document: true });
        if (createStoreNewWithDocument.fulfilled.match(response)) {
          if (onSignUp) {
            setLoading("loading");
            onSignUp();
          } else {
            setModalStep("finished");
          }
          setSignUpData(undefined);
        } else {
          dispatch(showAlert("danger", t([`${response.payload?.response?.message_translated}`, "errors.default"])));
          if (response.payload?.response?.message !== "users.code-invalid") {
            setModalStep("signUp");
            setLoading("idle");
          }
        }
      }
    },
    [setModalStep, setSignUpData, dispatch, signUpData, country]
  );

  const toggleStepCallback = useCallback(() => {
    setModalStep((prev) => (prev === "signIn" ? "signUp" : "signIn"));
    setLoading("idle");
  }, []);

  const toStepSignUpCallback = useCallback(() => setModalStep("signUp"), [setModalStep]);

  return (
    <>
      {modalStep === "signIn" && (
        <SignInStep onSubmit={onSubmitLoginCallback as any} toggleStep={toggleStepCallback} loading={loading} />
      )}
      {modalStep === "signUp" && (
        <SignUpStep
          onSubmit={onSubmitRegistrationCallback as any}
          toggleStep={toggleStepCallback}
          loading={loading}
          data={signUpData}
          subscribeFlux={true}
          setSubscribeDocumentAux={setSubscribeDocumentAux}
          setDocumentAux={setDocumentAux}
        />
      )}
      {modalStep === "confirmation" && (
        <ConfirmationStep
          onSubmit={onSubmitConfirmationCallback}
          toggleStep={toStepSignUpCallback}
          loading={loading}
          data={{ email: signUpData?.email }}
          newData={signUpData}
          setModalStep={() => setModalStep}
        />
      )}
      {modalStep === "finished" && <FinishedStep />}
    </>
  );
};

export default SignInSignUpSteps;
