import { AxiosResponse } from "axios";
import http from "config/http";
import { Response, ResponseStatus } from "../definitions";

export interface sendEmailValidationCodeRequest {
  email?: string;
  name?: string;
}

export type sendEmailValidationCodeResponse = Response<ResponseStatus>;

const sendEmailValidationCode = async (
  data: sendEmailValidationCodeRequest
): Promise<AxiosResponse<sendEmailValidationCodeResponse>> => {
  return http.post<sendEmailValidationCodeResponse>(`v3/users/sendEmailValidationCode`, data);
};

export default sendEmailValidationCode;
