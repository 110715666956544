import http from "config/http";
import { Response, ResponseStatus } from "../definitions";

interface ResendEmailValidationCodeRequest {
  email: string;
  name: string;
}

type ResendEmailValidationCodeResponse = Response<ResponseStatus>;

const resendEmailValidationCode = (data: ResendEmailValidationCodeRequest) =>
  http.post<ResendEmailValidationCodeResponse>(`/users/resendEmailValidationCode`, data);

export default resendEmailValidationCode;
