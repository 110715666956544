import { AxiosResponse } from "axios";
import http from "config/http";
import { ActionResponse } from "core/api/definitions";

export interface exportReportProjectsRequest {
  date_start?: string;
  date_end?: string;
  entities: string[];
  cities?: { city: string; state: string }[];
  states?: string[];
  report_types?: string[];
  opportunity_type?: string[]; //subscribe,material
  cc?: string[]; // (CAUSAS)
}

export type exportReportProjectsResponse = ActionResponse;

const exportReportProjects = async ({
  date_start,
  date_end,
  entities,
  cities,
  states,
  report_types,
  opportunity_type,
  cc,
}: exportReportProjectsRequest): Promise<AxiosResponse<exportReportProjectsResponse>> => {
  return http.post<exportReportProjectsResponse>(`/admin/dashboard/report-project-admin`, {
    date_start,
    date_end,
    entities,
    cities,
    states,
    report_types,
    opportunity_type,
    cc,
  });
};

export default exportReportProjects;
