import { combineReducers } from "@reduxjs/toolkit";

import createReducer from "./create";
import listReducer from "./list";
import updateReducer from "./update";

const wizardReducer = combineReducers({
  create: createReducer,
  list: listReducer,
  update: updateReducer,
});

export default wizardReducer;
