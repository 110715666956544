const transitionFunc = (time: string) => {
  const r = (...s: string[]): string => s.map((prop) => `${prop} ease-out ${time}`).join(",");
  r.toString = () => time;
  r.milliseconds = parseFloat(time) * 1000;
  return r;
};

export const transition = {
  slow: transitionFunc("0.5s"),
  normal: transitionFunc("0.3s"),
  fast: transitionFunc("0.2s"),
};
