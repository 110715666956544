import styled from "styled-components/macro";
import { rem } from "polished";

/**
 * @deprecated
 */
export const Text = styled.h1`
  color: ${({ theme }) => theme.v3.colors.secondary};
  font-size: ${rem(25)};
  line-height: ${rem(35)};
  text-align: center;
  margin: 0;
`;

/**
 * @deprecated
 */
export const SubText = styled.p`
  color: ${({ theme }) => theme.v3.colors.neutralLight};
  font-size: ${rem(15)};
  line-height: ${rem(22)};
  text-align: center;
  margin: 0;
`;

/**
 * @deprecated
 */
export const ActionsContainer = styled.div`
  text-align: center;
  margin-top: 35px;
`;
