import api from "core/api";
import { SubscribeAssignRequest } from "core/api/subscribe/assign";
import { SubscribeShowRequest } from "core/api/subscribe/show";
import asyncThunk from "core/decorators/toolkit";

const get = asyncThunk("subscribe/show", (req: SubscribeShowRequest) => api.subscribe.show(req), {
  condition: [
    (req, state) => {
      return state.subscribeNew.subscribes[req.slug]?.loading !== "ok";
    },
  ],
});

const assign = asyncThunk("subscribe/assign", (req: SubscribeAssignRequest) => api.subscribe.assign(req));

export const subscribeActions = {
  get,
  assign,
};
