import createTag from "./createTag";
import listAllTags from "./listAllTags";
import searchActions from "./searchActions";
import listTag from "./listTag";
import addActionOnTag from "./addActionOnTag";
import deleteActionOnTag from "./deleteActionOnTag";
import reportTag from "./reportTag";

export default {
  listAllTags,
  createTag,
  searchActions,
  listTag,
  addActionOnTag,
  deleteActionOnTag,
  reportTag,
};
