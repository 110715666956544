import { AxiosResponse } from "axios";
import http from "config/http";
import { ResponseWithAdditionals } from "core/api/definitions";
import { isEmpty } from "lodash";
import QueryString from "qs";

export interface ActionsOpportunitiesRequest {
  idProject: string;
  pg?: number;
  pp?: number;
  status?: string;
  period?: string;
  cc?: string | CausesFilterResponse;
  term?: string;
}

export interface ActionData {
  archived: boolean;
  project_id: string;
  slug: string;
  status: string;
  title: string;
  visible: boolean;
  _id: string;
}

export interface CausesFilterResponse {
  description: string;
  slug: string;
  _id: string;
}

export interface StatusFilterResponse {
  active: string;
  finished: string;
  pending: string;
  pending_project_validation: string;
  reject: string;
  reject_by_project: string;
}

export interface ListActions {
  opportunities_data: {
    archived: {
      action_data: ActionData;
      material_count: number;
      subscribe_count: number;
    }[];
    filters: {
      causes: CausesFilterResponse[];
      status: StatusFilterResponse;
    };
    no_archived: {
      action_data: ActionData;
      material_count: number;
      subscribe_count: number;
    }[];
  };
}

export type ActionsOpportunities = ResponseWithAdditionals<ListActions>;

export default async ({
  idProject,
  ...props
}: ActionsOpportunitiesRequest): Promise<AxiosResponse<ActionsOpportunities>> =>
  http.get<ActionsOpportunities>(
    `projects/${idProject}/opportunities-by-project` + (isEmpty(props) ? "" : "?" + QueryString.stringify(props))
  );
