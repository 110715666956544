import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit";
import api from "core/api";
import { RequestableState, ListState, ListAction, LoadingStatus } from "core/api/definitions";
import { ErrorResponse } from "core/api/definitions";
import decoreThunk from "core/decorators/decorate";
import { CertificatesRequest, CertificatesResponse } from "core/api/actions/certificates";
import { GenerateCertificatesRequest, GenerateCertificatesResponse } from "core/api/actions/generateCertificates";
import { Certificate, CertificateGenerated } from "core/api/definitions";
import listWithoutPagination from "core/decorators/listWithoutPagination";
import asyncThunk, { cacheDecoratorAsyncThunk } from "core/decorators/toolkit";
import { WithCache } from "core/api/definitions";
import {
  GenerateCertificatesByCodeResponse,
  GenerateCertificatesByCodeRequest,
} from "core/api/actions/generateCertificateByCode";
import requestLoading from "core/decorators/requestLoading";

type IState = RequestableState &
  ListState<Certificate & { validatingStatus: LoadingStatus }> &
  WithCache<CertificateGenerated>;

const { cacheDecorator, condition } = cacheDecoratorAsyncThunk<CertificateGenerated, IState>("panelCertificates");

type GetCertificatesResponse = (ListAction & CertificatesResponse) | ErrorResponse;
export const getCertificates = asyncThunk<GetCertificatesResponse, CertificatesRequest>(
  "panel/certificates/list",
  api.actions.certificates
);

type GetGenerateCertificatesResponse = GenerateCertificatesResponse | ErrorResponse;
export const getCertificate = asyncThunk<GetGenerateCertificatesResponse, GenerateCertificatesRequest>(
  "panel/certificates/get",
  api.actions.generateCertificates,
  {
    condition: [condition],
  }
);

export const getCertificateByCode = asyncThunk<GenerateCertificatesByCodeResponse, GenerateCertificatesByCodeRequest>(
  "panel/certificates/bycode/get",
  api.actions.generateCertificateByCode
);

const panelCertificatesSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "panelCertificates",
  initialState: {
    loading: "idle",
    list: [],
    cache: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, getCertificates, [listWithoutPagination()]);
    decoreThunk(builder, getCertificate, [cacheDecorator]);
    decoreThunk(builder, getCertificateByCode, [
      requestLoading(),
      {
        pending: (state, action) => {
          state.cache[action.meta.arg] = { data: {}, loading: "loading" };
        },
        fulfilled: (state, action) => {
          state.cache[action.meta.arg] = {
            data: action?.payload?.params,
            loading: "ok",
            updated: new Date().getTime(),
          };
        },
        rejected: (state, action) => {
          state.cache[action.meta.arg] = {
            data: {},
            loading: "error",
            error: { success: false, response: action.payload.response },
          };
        },
      },
    ]);
  },
});

export default panelCertificatesSlice;
