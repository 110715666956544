import { createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import api from "core/api";
import { RequestableState } from "core/api/definitions";
import { ErrorResponse } from "core/api/definitions";
import decoreThunk from "core/decorators/decorate";
import { AssignResponse, AssignRequest } from "core/api/program/assign";
import requestLoading from "core/decorators/requestLoading";
import asyncThunk from "core/decorators/toolkit";
import { GetProgramRequest, GetProgramResponse } from "core/api/program/get";
import { GetProgramOpportunitiesResponse } from "core/api/program/getOpportunities";

type PostProgramResponse = AssignResponse | ErrorResponse;

export const getProgram = asyncThunk<GetProgramResponse, GetProgramRequest>("pages/program/get", api.program.get);

export const getProgramActions = asyncThunk("pages/program/getActions", api.program.getActions);

export const getProgramOpportunities = asyncThunk("pages/program/getOpportunities", api.program.getOpportunities);

export const assignToProgram = asyncThunk<PostProgramResponse, AssignRequest>(
  "pages/program/assign",
  api.program.assign
);

type IState = RequestableState &
  PostProgramResponse & {
    programPage?: GetProgramResponse;
  };

const programSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "program",
  initialState: {
    success: false,
    programPage: {} as GetProgramResponse,
    loading: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, assignToProgram, [
      requestLoading(),
      {
        fulfilled: (state, action) => {
          state.params = { assing: action.payload.params };
        },
        rejected: (state, action) => {
          state.response = action.payload.response;
        },
      },
    ]);
    decoreThunk(builder, getProgramOpportunities, [
      requestLoading(),
      {
        pending: (state, action) => {
          state.programPage!.subscribe_data!.pg = action.meta.arg?.pg;
        },
        fulfilled: (state, action) => {
          const pg = action.meta.arg?.pg;
          const payload = action.payload as any;

          state.programPage!.subscribe_data!.hasMore = payload.hasMore;
          state.programPage!.subscribe_data!.total = payload.total;

          if (pg === 1) {
            state.programPage!.subscribe_data!.response = payload?.response ?? [];
          } else {
            state.programPage!.subscribe_data!.response = [
              ...(state.programPage!.subscribe_data!.response || []),
              ...(payload?.response || []),
            ];
          }
        },
      },
    ]);
    decoreThunk(builder, getProgramActions, [
      requestLoading(),
      {
        pending: (state, action) => {
          state.programPage!.action_data!.pg = action.meta.arg?.pg;
        },
        fulfilled: (state, action) => {
          const pg = action.meta.arg?.pg;
          const payload = action.payload as any;

          state.programPage!.action_data!.hasMore = payload.hasMore;
          state.programPage!.action_data!.total = payload.total;

          if (pg === 1) {
            state.programPage!.action_data!.response = payload?.response ?? [];
          } else {
            state.programPage!.action_data!.response = [
              ...(state.programPage!.action_data!.response || []),
              ...(payload?.response || []),
            ];
          }
        },
      },
    ]);

    //getProgram
    builder.addCase(getProgram.pending.toString(), (state) => {
      state.programPage!.loading = "loading";
    });
    builder.addCase(getProgram.fulfilled.toString(), (state, action: PayloadAction<GetProgramResponse>) => {
      state.programPage = action.payload;
      state.programPage!.slug = (action as any)?.meta?.arg?.slug;
      state.programPage!.loading = "ok";
    });
    builder.addCase(getProgram.rejected.toString(), (state) => {
      state.programPage!.loading = "error";
    });
  },
});

export default programSlice;
