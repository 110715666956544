import { createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import {
  PlatformBasicInformationCreateRequest,
  PlataformBasicInformationCreateResponse,
} from "core/api/admin/platforms/basicInformations/create";
import {
  PlatformBasicInformationShowResponse,
  PlatformBasicInformationShowRequest,
  PlatformBasicInformationShowResponseData,
} from "core/api/admin/platforms/basicInformations/show";
import { PlatformBasicInformationUpdateRequest } from "core/api/admin/platforms/basicInformations/update";
import { RequestableState } from "core/api/definitions";
import decoreThunk from "core/decorators/decorate";
import requestLoading from "core/decorators/requestLoading";
import { isEmpty, merge, mergeWith } from "lodash";
import { basicInformationsCreate, basicInformationsShow, basicInformationsUpdate } from "./actions";

type BasicInformations = Partial<PlatformBasicInformationShowResponseData & RequestableState>;

interface IState extends RequestableState {
  platforms: {
    [k: string]: BasicInformations;
  };
}

const preparePlatformInfos = (
  action: PayloadAction<PlataformBasicInformationCreateResponse, string, { arg: PlatformBasicInformationCreateRequest }>
): Partial<BasicInformations> => {
  return {
    siteSettings: { title: action.meta.arg.title_platform || "" },
    domain_main: action.meta.arg.domain_platform,
    address_radius: action.meta.arg.address_radius,
    primary_data: {
      language: action.meta.arg.language || "",
      social_networks: [
        {
          network: "facebook",
          url: action.meta.arg.networks?.facebook,
        },
        {
          network: "instagram",
          url: action.meta.arg.networks?.instagram,
        },
        {
          network: "twitter",
          url: action.meta.arg.networks?.twitter,
        },
        {
          network: "youtube",
          url: action.meta.arg.networks?.youtube,
        },
      ],
    },
    mailer: {
      chatNotificationsTo: action.meta.arg.email_notify_chat || "",
      fromName: action.meta.arg.name_sender || "",
      notificationsTo: action.meta.arg.email_notify || "",
    },
    curriculumSettings: {
      jobRole: action.meta.arg.responsible_function || "",
      name: action.meta.arg.responsible_name || "",
      signatureImage: action.meta.arg.signature || "",
      signatureDefault: action.meta.arg.signature_default || false,
    },
  };
};

const panelBetaBasicInformationSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "panelBetaBasicInformation",
  initialState: {
    loading: "idle",
    platforms: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, basicInformationsCreate, [
      requestLoading(),
      {
        fulfilled: (
          state,
          action: PayloadAction<
            PlataformBasicInformationCreateResponse,
            string,
            { arg: PlatformBasicInformationCreateRequest }
          >
        ) => {
          state.platforms![action.payload.params?._id || 0] = mergeWith(
            preparePlatformInfos(action),
            state.platforms![action.payload.params?._id || 0],
            (objValue, objSource) => (isEmpty(objValue) ? objSource : objValue)
          );
        },
      },
    ]);

    decoreThunk(builder, basicInformationsShow, [
      {
        pending: (
          state,
          action: PayloadAction<
            PlatformBasicInformationShowResponse,
            string,
            { arg: PlatformBasicInformationShowRequest }
          >
        ) => {
          state.platforms[action.meta.arg.platformId] = {
            loading: "loading",
          };
        },
        fulfilled: (
          state,
          action: PayloadAction<
            PlatformBasicInformationShowResponse,
            string,
            { arg: PlatformBasicInformationShowRequest }
          >
        ) => {
          state.platforms![action.meta.arg.platformId]!.loading = "ok";

          state.platforms![action.meta.arg.platformId]! = merge(
            state.platforms![action.meta.arg.platformId],
            action.payload.params
          );
        },
        rejected: (
          state,
          action: PayloadAction<
            PlatformBasicInformationShowResponse,
            string,
            { arg: PlatformBasicInformationShowRequest }
          >
        ) => {
          state.platforms![action.meta.arg.platformId]!.loading = "error";
        },
      },
    ]);

    decoreThunk(builder, basicInformationsUpdate, [
      {
        pending: (
          state,
          action: PayloadAction<
            PlataformBasicInformationCreateResponse,
            string,
            { arg: PlatformBasicInformationUpdateRequest }
          >
        ) => {
          state.platforms![action.meta.arg._id || 0].loading = "loading";
        },
        fulfilled: (
          state,
          action: PayloadAction<
            PlataformBasicInformationCreateResponse,
            string,
            { arg: PlatformBasicInformationUpdateRequest }
          >
        ) => {
          state.platforms![action.meta.arg._id || 0].loading = "ok";

          state.platforms![action.meta.arg._id || 0] = mergeWith(
            preparePlatformInfos(action),
            state.platforms![action.meta.arg._id || 0],
            (objValue, objSource) => (isEmpty(objValue) ? objSource : objValue)
          );
        },
        rejected: (
          state,
          action: PayloadAction<
            PlataformBasicInformationCreateResponse,
            string,
            { arg: PlatformBasicInformationUpdateRequest }
          >
        ) => {
          state.platforms![action.meta.arg._id || 0].loading = "error";
        },
      },
    ]);
  },
});

export default panelBetaBasicInformationSlice;
