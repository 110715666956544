import React, { useState, useRef, useEffect, useCallback } from "react";
import { math, rem, transparentize, darken } from "polished";
import styled, { css } from "styled-components/macro";

import DatePicker, { ReactDatePickerProps, registerLocale } from "react-datepicker";
import { setDefaultLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import { Size } from "utils/types";
import { i18n } from "i18next";
import { FormGroup as FormGroupRS, Input as InputRS, Label as LabelRS } from "reactstrap";
import InputMask from "react-input-mask";
import { default as FAIcon, IconType as FAIconType } from "components/Icon/IconFontAwesome";
import Icon, { IconType, isValidIcon } from "components/Icon/Icon";
import { datePickerMixin } from "components/DatePicker/DatePicker";
import { ValidationError, ValidationErrorSet } from "core/api/definitions";
import WizardTrans from "../WizardTrans/WizardTrans";
import { BaseLabelStyle } from "v3/components/Form/Label";
import ptBR from "date-fns/locale/pt-BR";
import en from "date-fns/locale/en-US";
import es from "date-fns/locale/es";
import pt from "date-fns/locale/pt";

interface GetCustomPropsTypes extends Pick<ReactDatePickerProps, "onCalendarOpen" | "onCalendarClose"> {
  inputType?: InputType;
  mask?: string | RegExp;
  placeholder?: string;
  showPassword?: boolean;
  dateFormat?: string | string[];
  state?: StateType;
  i18n?: i18n;
  defaultValue?: string | number | string[];
  calendarSize: CalendarSize;
}

const DISTANCES = {
  label: {
    top: "-7px",
    right: "0",
    bottom: "0",
    left: "12px",
  },
};

const LINE_HEIGHT = {
  default: 22,
  lg: 50,
  md: 35,
};

export const InputWrapperChildren = styled.div`
  background: #fff;
  border: 1px solid #bcc4de;
  border-radius: ${rem(4)};
`;

type InputSize = Exclude<Size, "xs" | "sm"> | "default";

interface InputWrapperProps {
  name?: string;
  size?: InputSize;
  label?: string;
  error?: string | ValidationError | ValidationErrorSet;
  className?: string;
  centered?: boolean;
  calendarSize?: CalendarSize;
  type: InputType;
}

const InputWrapper: React.FC<InputWrapperProps> = ({
  size = "default",
  name,
  label,
  error,
  className,
  children,
  calendarSize,
  type,
}) => {
  return (
    <FormGroup size={size} className={className} calendarSize={calendarSize} type={type}>
      <InputWrapperStyled>
        <Label for={name}>{label}</Label>
        {children}
      </InputWrapperStyled>
      {/**TODO(Jeconias): This is a solution temporary before refactoring this component. */}
      {error && error !== " " && (
        <WizardTrans textAlign={label !== "senha" ? "left" : "center"}>{error as ValidationError}</WizardTrans>
      )}
    </FormGroup>
  );
};

export { InputWrapper };

const InputWrapperStyled = styled.div`
  position: relative;
`;

export const FormGroup = styled(FormGroupRS)`
  margin: 30px 0 0 0;
  position: relative;

  ${({ tag }) =>
    tag === "fieldset" &&
    css`
      margin: 0 0 40px 0;

      &:not(:last-child) {
        border-bottom: 1px solid ${transparentize(0.6, "#c9cedd")};
      }
    `};

  > [class^="col-"] {
    > label {
      left: ${math(DISTANCES.label.left.concat("+", "15px"))};
    }
  }

  ${({ size }) =>
    size === "md" &&
    css`
      ${CounterStyled} {
        bottom: ${rem(LINE_HEIGHT.md / 2 + 20 - 7)};
      }
    `};

  ${({ size }) =>
    size === "lg" &&
    css`
      ${CounterStyled} {
        bottom: ${rem(LINE_HEIGHT.lg / 2 + 20 - 7)};
      }
    `}

  ${({ type, size, theme }) =>
    type === "datepicker" &&
    css`
      .react-datepicker-popper {
        z-index: 20;

        .react-datepicker {
          font-family: inherit;
          border-color: #e2e6f4;
          border-radius: 10px;

          &__month {
            margin: 0;
          }

          &__day {
            &,
            &-name {
              margin: 0;
              width: ${size === "lg" ? 80 : 60}px;
            }

            &-name {
              color: #8798ad;
              text-transform: uppercase;
              font-size: 12px;
            }

            line-height: ${size === "lg" ? 78 : 58}px;
            font-size: ${size === "lg" ? 20 : 14}px;
            color: #18235e;
            border: 1px solid #e2e6f4;

            &--outside-month {
              color: #8798ad;
              background-color: #f9fafc;
              font-weight: 300;
            }

            &:hover {
              border-radius: 0;
              border: 1px solid ${theme.colors.secondary};
              background: initial;
            }

            &--in-selecting-range {
              &,
              &:hover {
                border-radius: 0;
                font-weight: 500;
                background-color: ${transparentize(0.5, theme.colors.secondary)};
                border: 1px solid ${transparentize(0.5, theme.colors.secondary)};
              }
            }

            &--selected,
            &--keyboard-selected,
            &--in-range {
              &,
              &:hover {
                color: #ffffff;
                border-radius: 0;
                font-weight: 500;
                background-color: ${theme.colors.secondary};
                border: 1px solid ${theme.colors.secondary};
              }
            }
            &:focus {
              outline: 0;
            }
          }

          &__week:last-child {
            > :first-child {
              border-radius: 0px 0px 0px 10px;
            }

            > :last-child {
              border-radius: 0px 0px 10px 0px;
            }
          }

          &__header {
            border-radius: 10px 10px 0 0;
            border: none;
            background-color: #f9fafc;
          }

          &__current-month {
            font-size: ${size === "lg" ? 25 : 20}px;
            font-weight: normal;
            color: #18235e;
            line-height: ${size === "lg" ? 32 : 28}px;
            margin: ${size === "lg" ? 12 : 10}px 0 ${size === "lg" ? 20 : 16}px;
          }

          &__navigation {
            top: 30px;
          }

          &__day-names {
            border-top: 1px solid #e2e6f4;
          }

          &__day-name {
            padding-top: ${size === "lg" ? 30 : 26}px;
            padding-bottom: ${size === "lg" ? 20 : 16}px;
            line-height: ${size === "lg" ? 14 : 10}px;
          }
        }

        ${datePickerMixin}
      }

      .react-datepicker-wrapper {
        display: block;
      }
    `}
`;

const CounterStyled = styled.div<{ warning?: boolean }>`
  position: absolute;
  right: 20px;
  bottom: ${rem(LINE_HEIGHT.default / 2 + 20 - 7)};
  font-size: ${rem(12)};
  line-height: ${rem(14)};
  letter-spacing: ${rem(1.13)};
  color: ${({ theme }) => theme.colors.support_text};

  ${({ warning }) =>
    warning &&
    css`
      color: ${(props) => props.theme.colors.danger};
    `};
`;

const IconWrapperStyled = styled.div`
  position: absolute;
  right: 20px;
  width: 20px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;

  path,
  svg {
    fill: ${({ theme }) => theme.colors.support_text};
    width: 100%;
  }
`;

const extractIcon = ({
  type,
  icon,
  showPassword,
}: {
  type: InputType;
  icon?: IconType;
  showPassword?: boolean;
}): IconType | FAIconType | null => {
  if (type === "select") {
    return "chevronDown";
  }

  if (type === "datepicker") {
    return "calendar";
  }

  if (type === "password" && showPassword === true) return ["far", "eye"];
  if (type === "password" && showPassword === false) return ["far", "eye-slash"];

  if (icon) {
    return icon;
  }

  return null;
};

const IconWrapper = ({
  type,
  icon,
  hideIcon,
  onClickIcon,
  showPassword,
}: {
  type: InputType;
  icon?: IconType | FAIconType;
  hideIcon: any;
  showPassword?: boolean;
  onClickIcon?(getState?: boolean): ProxyOnClickIconType;
}) => {
  const iconType = extractIcon({ type, icon: icon as IconType, showPassword });

  if (!iconType) return null;

  return (
    <IconWrapperStyled
      onClick={() => {
        if (onClickIcon) onClickIcon();
      }}
    >
      {isValidIcon(iconType) ? (
        <Icon style={{ display: hideIcon }} type={iconType as IconType} />
      ) : (
        <FAIcon icon={iconType as FAIconType} />
      )}
    </IconWrapperStyled>
  );
};

export const Counter = ({
  size,
  totalLength,
  maxLength,
  hideIcon,
}: {
  size?: number;
  totalLength?: number;
  maxLength?: number;
  hideIcon?: boolean;
}) => {
  if (maxLength && size !== undefined && totalLength !== undefined) {
    const warning = totalLength >= Math.floor(maxLength - maxLength * 0.05);
    return (
      <CounterStyled style={{ display: hideIcon ? "none" : "" }} warning={warning}>
        {totalLength} / {maxLength}
      </CounterStyled>
    );
  }
  return null;
};

const Label = styled(LabelRS)`
  ${BaseLabelStyle}

  top: ${DISTANCES.label.top};
  left: ${DISTANCES.label.left};
  position: absolute;
  z-index: 9;

  &:after {
    content: "";
    position: absolute;
    height: 1px;
    left: 0;
    right: 0;
    background: #fff;
    display: block;
    top: calc(50%);
    z-index: -1;
  }
`;

export const InputStyle = css<any>`
  margin-bottom: 0;
  height: auto;
  line-height: ${rem(LINE_HEIGHT.default)};
  border: 1px solid #e2e6f4;
  // color: ${({ theme }) => theme.colors.secondary_700};
  color: #18235e;
  padding: 20px 85px 20px 20px;
  transition: 0.15s ease-in-out all;

  ${(disabled) =>
    disabled &&
    css`
      &:disabled {
        background-color: rgb(233, 236, 239, 0.1);
      }
    `};

  ${({ hasIcon }) =>
    hasIcon &&
    css`
      padding-right: 60px;
    `};

  ${(props) =>
    props.centered &&
    css`
      text-align: center;
    `};

  ${({ size }) =>
    size === "md" &&
    css`
      font-size: ${rem(25)};
      line-height: ${rem(LINE_HEIGHT.md)};
      font-weight: 300;
    `};

  ${({ size }) =>
    size === "lg" &&
    css`
      font-size: ${rem(44)};
      line-height: ${rem(LINE_HEIGHT.lg)};
      letter-spacing: ${rem(-0.45)};
    `};

  ${({ error }) =>
    error &&
    css`
      color: ${(props) => props.theme.colors.danger} !important;
      border-color: ${({ theme }) => theme.colors.panel.disapproved} !important;
    `};

  ${({ type }) =>
    type === "textarea" &&
    css`
      resize: none;
    `};

  ${({ type }) =>
    type === "select" &&
    css`
      appearance: none;

      &:invalid {
        color: ${({ theme }) => theme.colors.support_text};
      }
    `};

  ${({ type }) =>
    type === "number" &&
    css`
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        opacity: 1;
        border: 1px solid red;
      }
    `};

  &:focus {
    // color: ${({ theme }) => theme.colors.secondary_700};
    color: #18235e;
    border: 1px solid ${darken(0.1, "#E2E6F4")};
    box-shadow: none;
  }

  &::placeholder {
    color: #cdd6e2;
  }
`;

const InputStyled = styled(({ ...props }) => {
  props.extraMarginRight = props.hasIcon = props.size = undefined;

  return <InputRS {...props} />;
})`
  ${InputStyle}
`;

const getCustomProps = ({
  inputType,
  mask,
  placeholder,
  showPassword,
  state,
  i18n,
  defaultValue,
  dateFormat,
  calendarSize,
}: GetCustomPropsTypes) => {
  const customProps: ReactDatePickerProps | any = {};
  if (["text", "tel"].indexOf(inputType!) >= 0 && mask) {
    customProps.tag = InputMask;
    customProps.mask = mask;
  } else if (inputType === "datepicker") {
    customProps.showPopperArrow = false;
    customProps.tag = DatePicker;
    customProps.dateFormat = dateFormat || "P";
    // customProps.selected = state?.value as string;
    customProps.autoComplete = "off";
    customProps.placeholderText = placeholder;
    customProps.locale = (i18n?.language || "en-US")?.split("-")[0];
    let currentLanguage = i18n?.language || "en-US";
    let language;
    switch (currentLanguage) {
      case "pt-BR":
        language = ptBR.code;
        break;
      case "es-CL":
        language = es.code;
        break;
      case "pt-PT":
        language = pt.code;
        break;
      default:
        language = en.code;
        break;
    }
    if (typeof language === "string") {
      setDefaultLocale(language);
    }

    customProps.calendarSize = calendarSize || "md";
  } else if (inputType === "textarea") {
    customProps.rows = state?.rows;
  } else if (inputType === "password" && showPassword) {
    customProps.type = "text";
  }

  if (defaultValue) {
    customProps.defaultValue = defaultValue;
  } else {
    // customProps.value = state?.value;
  }

  return customProps;
};

type InputType = "text" | "tel" | "email" | "number" | "textarea" | "select" | "datepicker" | "password" | "url";

type DatePickerPropsFilter =
  | "dateFormat"
  | "maxDate"
  | "minDate"
  | "highlightDates"
  | "startDate"
  | "endDate"
  | "shouldCloseOnSelect"
  | "monthsShown"
  | "selectsStart"
  | "selectsEnd"
  | "onCalendarOpen"
  | "onCalendarClose"
  | "customInput";
type ProxyOnClickIconType = void | boolean;

type CalendarSize = "lg" | "md" | "sm" | undefined;

export interface InputProps extends Pick<ReactDatePickerProps, DatePickerPropsFilter> {
  label?: string;
  name?: string;
  placeholder?: string;
  type?: InputType;
  size?: InputSize;
  maxLength?: number;
  adaptiveHeight?: boolean;
  error?: string | ValidationError | ValidationErrorSet;
  mask?: string | RegExp;
  icon?: IconType | FAIconType;
  hideIcon?: boolean;
  centered?: boolean;
  wrapperClassName?: string;
  onChange?(e: React.ChangeEvent, date?: Date | null): void;
  onClickIcon?(): void;
  handleFormatDate?(date: Date): void;
  totalLength?: number;
  tag?: React.ReactType;
  invalid?: boolean;
  defaultValue?: string | number | string[];
  calendarSize?: CalendarSize;
}

type StateType = {
  rows: number;
  minRows: number;
  maxRows: number;
};

const InputLabeled: React.FC<InputProps & Omit<React.HTMLProps<HTMLInputElement>, "size">> = ({
  label,
  name,
  placeholder,
  type = "text",
  size = "default",
  adaptiveHeight,
  error,
  mask,
  icon,
  hideIcon,
  centered,
  totalLength,
  wrapperClassName,
  onChange,
  onClickIcon,
  defaultValue,
  dateFormat,
  calendarSize,
  ...props
}: InputProps) => {
  const { i18n } = useTranslation();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [state, setState] = useState<StateType>({
    rows: 1,
    minRows: 1,
    maxRows: 10,
  });
  const refInput = useRef<HTMLInputElement | null>(null);

  const customProps = getCustomProps({
    inputType: type,
    mask,
    placeholder,
    showPassword,
    state,
    i18n,
    defaultValue,
    dateFormat,
    calendarSize,
  });
  const textareaLineHeight = LINE_HEIGHT[size] || LINE_HEIGHT["default"];

  const textAreaAutoHeightMemorized = useCallback(
    (elementEvent) => {
      const { minRows, maxRows } = state;
      const element = elementEvent.target || elementEvent;

      const previousRows = element.rows;
      element.rows = minRows;

      const currentRows = ~~(element.scrollHeight / textareaLineHeight) - 1;

      if (currentRows === previousRows) {
        element.rows = currentRows;
      }

      if (currentRows >= maxRows) {
        element.rows = maxRows;
        element.scrollTop = element.scrollHeight;
      }

      setState((prev) => ({ ...prev, rows: currentRows < maxRows ? currentRows : maxRows }));
    },
    [state, textareaLineHeight]
  );

  const current = refInput.current;
  useEffect(() => {
    const currentInput = refInput.current || current;
    if (currentInput && currentInput?.type === "textarea" && adaptiveHeight) {
      textAreaAutoHeightMemorized(currentInput);
    }
    return () => {
      refInput.current = null;
    };
  }, [refInput, current, adaptiveHeight, textAreaAutoHeightMemorized]);

  const hasIcon = !!extractIcon({ type, icon: icon as IconType });

  const proxyOnClickIcon = (getState?: boolean): ProxyOnClickIconType => {
    if (getState) return showPassword;
    if (type === "password") setShowPassword((prev) => !prev);
    if (onClickIcon) onClickIcon();
  };

  const onChangeCallback = useCallback(
    (dateOrEvent: React.ChangeEvent<HTMLInputElement> | Date, eventDatePicker: React.ChangeEvent<HTMLInputElement>) => {
      const isDatepicker = type === "datepicker";

      if (type === "textarea" && adaptiveHeight) {
        textAreaAutoHeightMemorized(dateOrEvent);
      }

      if (props.handleFormatDate) {
        props.handleFormatDate(dateOrEvent as Date);
      }

      if (onChange) {
        if (!isDatepicker) onChange(dateOrEvent as React.ChangeEvent<HTMLInputElement>);
        if (isDatepicker) {
          onChange(eventDatePicker, dateOrEvent as Date);
        }
      }
    },
    [type, onChange, adaptiveHeight, props.handleFormatDate, textAreaAutoHeightMemorized]
  );

  return (
    <InputWrapper
      className={wrapperClassName}
      size={size}
      name={name}
      label={label}
      error={error}
      centered={centered}
      calendarSize={customProps.calendarSize}
      type={type}
    >
      <InputStyled
        innerRef={refInput}
        type={type}
        name={name}
        id={name}
        placeholder={placeholder}
        error={error}
        size={size}
        totalLength={totalLength}
        // value={state.value}
        onChange={onChangeCallback}
        extraMarginRight={props.maxLength !== undefined}
        hasIcon={hasIcon}
        centered={centered}
        {...props}
        {...customProps}
      />

      <Counter maxLength={props.maxLength} totalLength={totalLength} size={textareaLineHeight} hideIcon={hideIcon} />
      <IconWrapper
        type={type}
        icon={icon}
        hideIcon={hideIcon}
        showPassword={showPassword}
        onClickIcon={proxyOnClickIcon}
      />
    </InputWrapper>
  );
};

export default InputLabeled;

export const Legend = styled.legend`
  font-size: ${rem(16)};
  line-height: ${rem(21)};
  font-weight: 500;
  // color: ${({ theme }) => theme.colors.secondary_700};
  color: #18235e;
  margin-bottom: 25px;
  outline-style: none;
`;
