import http from "config/http";
import { ResponseWithParams, ResponseStatus, UserDataPanelDetails } from "core/api/definitions";

export interface PlatformAdministratorsShowRequest {
  platformId: string;
}

interface UserData extends Omit<UserDataPanelDetails, "profile_image" | "locations"> {}

export interface SubplatformAdministrator {
  administrator_id: string;
  user_data: UserData;
}

export type PlatformAdministratorShowResponseData = SubplatformAdministrator[];

export type PlatformAdministratorsShowResponse = ResponseWithParams<
  ResponseStatus,
  PlatformAdministratorShowResponseData
>;

const show = ({ platformId }: PlatformAdministratorsShowRequest) =>
  http.get<PlatformAdministratorsShowResponse>(`/platform-launcher/${platformId}/administrators`);

export default show;
