import subscribes from "./generateReportSubscribe";
import generateReportSubscribe from "./generateReportSubscribe";
import generateReportSubscribeByActionAdmin from "./generateReportSubscribeByActionAdmin";
import projectResume from "./projectResume";

export default {
  subscribes,
  generateReportSubscribe,
  generateReportSubscribeByActionAdmin,
  projectResume,
};
