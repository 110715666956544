import { IconType } from "icons";
import React from "react";
import FormFeedback from "../FormFeedback";
import FormGroup from "../FormGroup";
import InputIcon from "../InputIcon";
import Label from "../Label";
import Input from "./Input";

interface InputGroupProps {
  name: string;
  id?: string;
  type?: string;
  placeholder?: string;
  label: string;
  invalid?: boolean;
  valid?: boolean;
  message?: string;
  tag?: React.ElementType;
  mask?: string;
  defaultValue?: string;
  icon?: IconType;
  className?: string;
  autoFocus?: boolean;
  required?: boolean;
  disabled?: boolean;
  onClickIcon?(): void;
}

const InputGroup = ({
  tag: Tag = FormGroup,
  label,
  invalid,
  valid,
  icon,
  message,
  onClickIcon,
  className,
  disabled,
  ...props
}: InputGroupProps) => (
  <Tag className={className}>
    <Label invalid={invalid} valid={valid} disabled={disabled}>
      {label}
    </Label>
    <Input invalid={invalid} valid={valid} disabled={disabled} {...props} />
    {icon && <InputIcon icon={icon} onClick={onClickIcon} color="neutralLightness" />}
    {message && (
      <FormFeedback invalid={invalid} valid={valid}>
        {message}
      </FormFeedback>
    )}
  </Tag>
);

export default InputGroup;
