import { css } from "styled-components/macro";

export const BaseStyleContainer = css`
  position: relative;
  height: 300px;
  width: 200px;
  min-width: 200px;
  background-color: #fff;
  padding: 0;
  border: 0;
  margin: 3px 4px;
  cursor: pointer;
`;

export const BaseStyleInternal = css`
  border: 1px solid #cdd6e2;
  border-radius: 6px;
  padding: 20px 10px;
`;

export const Circle = css`
  position: absolute;
  content: "";
  top: 20px;
  right: 20px;
  height: 20px;
  width: 20px;
  border: 1px solid #e2e6f4;
  border-radius: 100%;
  z-index: 1;
`;
