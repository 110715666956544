import http from "config/http";
import { ProgramPageData } from "../definitions";

export interface GetProgramRequest {
  slug: string;
}

export interface GetProgramResponse extends ProgramPageData {}

const getProgram = async ({ slug }: GetProgramRequest) => http.get<GetProgramResponse>(`/program/${slug}/show?pp=9`);

export default getProgram;
