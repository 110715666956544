import http from "config/http";

interface ForgotPasswordRequest {
  email: string;
}

interface ForgotPasswordResponse {}

const forgotPassword = (data: ForgotPasswordRequest) => http.post<ForgotPasswordResponse>("/auth/forgot", data);

export default forgotPassword;
