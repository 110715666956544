import CheckboxInput from "components/FormPanel/CheckboxInput";
import { MaterialList } from "core/api/definitions";
import { ValidationErrorSet, ValidationError } from "core/api/definitions";
import React, { useCallback, Fragment } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { deliveryWays, deliveryWaysChile } from "utils/constants";
import { formatDateLong, formatDateTimeForAPI } from "utils/date";
import InputLabeled from "wizard/v3/components/Input/InputLabeled";
import PlacesAutoCompleteResolved, { LocationWithAddress } from "wizard/v3/components/Input/PlacesAutocompleteResolved";
import { PageEvents } from "../definitions/commonTypes";
import useCountry from "../../../../hooks/useCountry";
import LocalAutoCompleteResolved from "wizard/components/AutocompleteLocal/LocalAutocompleteResolved";

export type MaterialListInfo = Partial<MaterialList>;

const initialInfo: MaterialListInfo = {
  title: "",
};

interface DonationListDataProps
  extends Omit<PageEvents<MaterialListInfo>, "onContinue" | "error" | "onBack" | "loading"> {
  info?: MaterialListInfo;
  onTouched?(key: string): void;
  className?: string;
  hasError?: {
    title?: string | ValidationErrorSet | ValidationError;
    delivery_date?: string | ValidationErrorSet | ValidationError;
    delivery_way?: string | ValidationErrorSet | ValidationError;
    address?: string | ValidationErrorSet | ValidationError;
  };
  disabled?: boolean;
}

const DonationListInfo: React.FC<DonationListDataProps> = ({
  info: donation,
  onChange,
  onTouched,
  hasError,
  disabled,
}) => {
  const { t } = useTranslation();
  const { iso3166_1 } = useCountry();

  const onChangeCallback = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      if (onChange) {
        const actionAddressData = name === "delivery_way" && value === "to_combine" ? { action_address: false } : {};
        onChange({ ...(donation || initialInfo), ...actionAddressData, [name]: value });
      }
      if (onTouched) onTouched(name);
    },
    [onChange, donation, onTouched]
  );

  const onPlacesAutoCompleteChangeCallback = useCallback(
    (value) => {
      if (onChange) onChange({ ...(donation || initialInfo), address: value });
      if (onTouched) onTouched("locations.city");
    },
    [onChange, donation, onTouched]
  );

  const isToCombine = donation?.delivery_way === "to_combine";

  return (
    <Fragment>
      <InputLabeled
        name="title"
        type="text"
        label={t("wizard.pages.donationListData.inputs.title")}
        placeholder={t("common.typeIt")}
        value={donation?.title}
        required
        onChange={onChangeCallback}
        autoFocus
        error={hasError?.title}
        disabled={disabled}
      />
      <InputWrapper>
        <InputLabeled
          type="datepicker"
          label={t("wizard.pages.donationListData.inputs.date")}
          placeholder={t("common.select")}
          required
          minDate={new Date()}
          calendarSize="sm"
          onChange={(_: React.ChangeEvent<HTMLInputElement>, date?: Date) => {
            if (onChange)
              onChange({
                ...(donation || initialInfo),
                delivery_date: formatDateTimeForAPI(date!),
              });
            if (onTouched) onTouched("delivery_date");
          }}
          value={donation?.delivery_date && formatDateLong(donation.delivery_date)}
          error={hasError?.delivery_date}
          disabled={disabled}
        />
        <InputLabeled
          name="delivery_way"
          label={t("wizard.pages.donationListData.inputs.delivery_way")}
          type="select"
          onChange={onChangeCallback}
          value={donation?.delivery_way}
          error={hasError?.delivery_way}
          disabled={disabled}
        >
          <option value="">{t("common.select")}</option>
          {iso3166_1 === "cl"
            ? deliveryWaysChile.map((type, index) => (
                <option value={type.value} key={index}>
                  {t("plain:" + type.label)}
                </option>
              ))
            : deliveryWays.map((type, index) => (
                <option value={type.value} key={index}>
                  {t("plain:" + type.label)}
                </option>
              ))}
        </InputLabeled>
      </InputWrapper>
      <LocalAutoCompleteResolved
        inputTag={InputLabeled}
        inputProps={{
          type: "text",
          label: t("wizard.pages.donationListData.inputs.address"),
          placeholder: isToCombine ? t("wizard.pages.donationListData.labels.toCombine") : t("common.typeIt"),
          error: hasError?.address,
          required: true,
          disabled: disabled || isToCombine || donation?.action_address,
        }}
        value={isToCombine ? { address: "" } : (donation?.address as LocationWithAddress)}
        onSelect={onPlacesAutoCompleteChangeCallback}
        onChange={onPlacesAutoCompleteChangeCallback}
      />
      {donation?.delivery_way !== "to_combine" && !isToCombine && (
        <CheckboxWrapper>
          <CheckboxInput
            id="actionAddressDonation"
            name="action_address"
            onChange={(checked) => {
              if (typeof checked === "boolean" && donation?.delivery_way !== "to_combine" && onChange)
                onChange({ ...(donation || initialInfo), action_address: checked });
            }}
            value={donation?.action_address}
            label={t("wizard.pages.donationListData.labels.actionAddress")}
            disabled={disabled}
          />
        </CheckboxWrapper>
      )}
    </Fragment>
  );
};

export default DonationListInfo;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: row wrap;
  margin: 0 -10px;

  & > div {
    flex: 1 1 calc(50% - 20px);
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
    min-width: 300px;
  }
`;

const CheckboxWrapper = styled.div`
  .custom-checkbox {
    margin: 10px 0;
  }
`;
