import { isValidNumber, isPossibleNumber, isValidNumberForRegion, parseNumber, ParsedNumber } from "libphonenumber-js";
import * as yup from "yup";
import { checkFacebook, checkInstagram, checkTwitter, checkYoutube } from "./helpers";

/**
 * TODO(Henrique):
 * Move this inside yup validator
 * This function was exported because we don't want to refact all this form, but this need to be used only by yup validator

  @deprecated
*/
export const validateCPF = function (value?: string) {
  const withoutSpecialCharacters = value ? value.replaceAll(/\.|-/g, "") : "";

  if (!value || !/^(\d{3}){2}\d{3}\d{2}$/.test(withoutSpecialCharacters)) return false;

  const length = withoutSpecialCharacters.length;

  if (length !== 11 || /\b(\d)\1+\b/g.test(withoutSpecialCharacters)) return false;

  // Get the nine first numbers
  let result = withoutSpecialCharacters.slice(0, length - 2);

  for (let i = 9; i <= 10; i++) {
    let sumAux = 0;

    for (let b = i; b >= 1; b--) {
      sumAux += (b + 1) * parseInt(result.charAt(result.length - b));
    }

    const rest = sumAux % 11;
    if (rest >= 2) {
      result = result.concat(`${11 - rest}`);
    } else {
      result = result.concat("0");
    }
  }
  return result === withoutSpecialCharacters;
};

/**
 * TODO(Jeconias):
 * Move this inside yup validator
 * This function was exported because we don't want to refact all this form, but this need to be used only by yup validator

  @deprecated
*/
export const validateNIF = (value: string | undefined) => {
  if (!value || typeof value !== "string" || value.length !== 9) return false;

  let sumAux = 0;
  for (let i = 9; i >= 2; i--) {
    sumAux += i * (parseInt(value[value.length - i]) || 0);
  }

  const module = sumAux % 11;

  // Get the eight first numbers
  const NIFwithoutLastDigit = value.slice(0, value.length - 1);

  if (module === 0 || module === 1) {
    return `${NIFwithoutLastDigit}0` === value;
  } else {
    return `${NIFwithoutLastDigit}${11 - module}` === value;
  }
};

yup.setLocale({
  mixed: {
    required: (props) => ({ key: "errors.string.required", values: props }),
    oneOf: (props) => ({ key: "errors.string.oneOf", values: props }),
  },
  string: {
    min: (props) => ({ key: "errors.string.min", values: props }),
    max: (props) => ({ key: "errors.string.max", values: props }),
    matches: (props) => ({ key: "errors.string.matches", values: props }),
    email: (props) => ({ key: "errors.string.email", values: props }),
    url: (props) => ({ key: "errors.string.url", values: props }),
  },
});

yup.addMethod(yup.string, "cpf", function () {
  return (this as any).test("validate-cpf", "errors.format-is-invalid", validateCPF);
});

yup.addMethod(yup.string, "nif", function () {
  return (this as any).test("validate-nif", "errors.format-is-invalid", validateNIF);
});

export const validatePhone = (value?: string) => {
  if (!value || !isValidNumber(value)) return false;

  const parsedPhone = parseNumber(value!) as ParsedNumber;
  if (parsedPhone.country === "BR" && !/^[0-9]{2}(9?[0-9]{8})$/.test(parsedPhone.phone as string)) {
    return false;
  }
  return isPossibleNumber(parsedPhone) && isValidNumberForRegion(parsedPhone.phone, parsedPhone!.country);
};

yup.addMethod(yup.string, "phone", function () {
  return (this as any).test("validate-phone", "errors.format-is-invalid", validatePhone);
});

(window as any).isValidNumberForRegion = isValidNumberForRegion;

const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/;
yup.addMethod(yup.string, "password", function () {
  return (this as any).test("validate-password", "errors.format-is-invalid", function (value?: string) {
    return passwordRegExp.test(value ?? "");
  });
});

yup.addMethod(yup.string, "hex", function () {
  return (this as any).test("validate-hex", "errors.format-is-invalid", (value?: string) =>
    !value ? true : /^#?([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(value)
  );
});

yup.addMethod(yup.string, "cnpj", function () {
  return (this as any).test("validate-cnpj", "errors.format-is-invalid", (value?: string) =>
    !value ? true : /^(\d{2}.?\d{3}.?\d{3}\/?\d{4}-?\d{2})$/.test(value)
  );
});

yup.addMethod(yup.string, "documentId", function (type: string) {
  if ("cnpj" === type) {
    return (this as any).test("validate-cnpj", "errors.format-is-invalid", (value?: string) => {
      if (!value) {
        return true;
      } else {
        return /^(\d{2}.?\d{3}.?\d{3}\/?\d{4}-?\d{2})$/.test(value.replace(/[^\d]/gi, ""));
      }
    });
  } else if ("cpf" === type) {
    return (this as any).test("validate-cpf", "errors.format-is-invalid", validateCPF);
  } else if ("nif" === type) {
    return (this as any).test("validate-nif", "errors.format-is-invalid", (value?: string) => {
      if (!value) {
        return true;
      } else {
        return value.replace(/[^\d]/gi, "").length === 9;
      }
    });
  }
});

yup.addMethod(yup.string, "facebook", function () {
  return (this as any).test("validate-facebook", "errors.facebook-url-is-invalid", (value?: string) =>
    value ? checkFacebook(value) : true
  );
});

yup.addMethod(yup.string, "instagram", function () {
  return (this as any).test("validate-instagram", "errors.instagram-url-is-invalid", (value?: string) =>
    value ? checkInstagram(value) : true
  );
});

yup.addMethod(yup.string, "youtube", function () {
  return (this as any).test("validate-youtube", "errors.youtube-url-is-invalid", (value?: string) =>
    value ? checkYoutube(value) : true
  );
});

yup.addMethod(yup.string, "twitter", function () {
  return (this as any).test("validate-twitter", "errors.twitter-url-is-invalid", (value?: string) =>
    value ? checkTwitter(value) : true
  );
});

export default yup;
