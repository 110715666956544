import http from "config/http";
import { ResponseStatus, ResponseWithParams } from "../definitions";
import { Interest } from "pages/ListActions/DataOfFilters";
import { Weekday, SubscribeTimeConfigurationsTime } from "core/api/definitions";
import { Address } from "core/api/definitions";
import { AxiosResponse } from "axios";

type StoreSubscribeParams = {};

export type StoreSubscribeRequest = {
  action: string;

  // Action data
  title: string;
  function_id: string;
  description: string;
  action_address: boolean; //[If is true don't need send address, the address will be set in backend]
  time_accept_remote: boolean; //[If is true, don't should to send address]
  address?: Address;
  images_data: {
    cover_original: string;
    cover_description?: string;
  };
  time_configurations: {
    times: SubscribeTimeConfigurationsTime[];
    quantity: number;
    weekdays: Weekday[];
  };
  conditionals: {
    interests: Interest[];
  };
};

export type StoreSubscribeResponse = ResponseWithParams<ResponseStatus, StoreSubscribeParams>;

const storeNewSubscribe = async ({
  action,
  ...data
}: StoreSubscribeRequest): Promise<AxiosResponse<StoreSubscribeResponse>> =>
  http.post<StoreSubscribeResponse>(`/actions/${action}/subscribe`, data);

export default storeNewSubscribe;
