import utilsTypes from "./types";
import { STORE_KEYS } from "utils/constants";

export const redirectReceived = (payload, project, action) => ({
  type: utilsTypes.REDIRECT_URL,
  payload,
  urlUpdates: {
    location: {
      pathname: payload,
    },
  },
});

export const blcReceived = (payload) => {
  localStorage.setItem(STORE_KEYS.BLC, JSON.stringify(payload));
  return {
    type: utilsTypes.BLC_RECEIVED,
    payload,
  };
};

export const blcFailed = (payload) => ({
  type: utilsTypes.BLC_FAILED,
  payload,
});
