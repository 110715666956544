import { ALERT_SHOW, ALERT_DISMISS, DISPATCH_ALERT_SHOW } from "./types";
import { createAction } from "@reduxjs/toolkit";
import { FeedbackType } from "./index";

export const showFeedback = createAction<FeedbackType>("alerts/feedback/show");

export const hideFeedback = createAction<{ id: number }>("alerts/feedback/hide");

/**
 * @deprecated Please, use showFeedback.
 */
export const showAlert: any = (type: string, message: string, { ms } = { ms: 3000 }) => {
  return {
    type: ALERT_SHOW,
    payload: {
      type,
      message,
      ms,
    },
  };
};

/**
 * @deprecated Please, use hideFeedback.
 */
export const dismissAlert = (id: any) => {
  return {
    type: ALERT_DISMISS,
    payload: {
      id,
    },
  };
};

/**
 * @deprecated
 */
export const dispatchShowAlert = (show: any) => {
  return {
    type: DISPATCH_ALERT_SHOW,
    payload: {
      showAlert: show,
    },
  };
};
