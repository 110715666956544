import { faDivide } from "@fortawesome/pro-light-svg-icons";
import React from "react";
import styled from "styled-components/macro";
import { ColorV3 } from "utils/types";
import Text from "../common/Text";

export interface FeedbackSectionProps {
  [propName: string]: any;
  title: string;
  text: string;
  titleColor?: ColorV3;
  className?: string;
  tag?: React.ElementType;
}

const FeedbackSectionComp = ({ title, text, titleColor, tag: Tag = "div", ...props }: FeedbackSectionProps) => (
  <Tag {...props}>
    <Title tag="h3" size="lg" color={titleColor ?? "neutralBase"}>
      {title} &#x1F614; {/* EMOJI CODE HEXADECIMAL */}
    </Title>
    <Text size="sm" color="neutralLight">
      {text}
    </Text>
  </Tag>
);

const Title = styled(Text)`
  font-weight: 400;
  margin-bottom: ${({ theme }) => theme.v3.spacing.sm};
`;

const FeedbackSection = styled(FeedbackSectionComp)`
  text-align: center;
  margin: ${({ theme }) => theme.v3.spacing.xxl} auto;
`;

export default FeedbackSection;
