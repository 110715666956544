import { useEffect, useState } from "react";

let listeners: ((now: Date) => void)[] = [];

setInterval(() => {
  const now = new Date();
  for (let l of listeners) {
    l(now);
  }
}, 1000);

/**
 * useDateNow is a hook that optimizes all updates that should be done at the same pace. Like seconds elapsed.
 *
 * Why not to use a timer on each component? Because if they are at the same screen, it will make things re-render at
 * different times. Take notifications as an example, if a notification arrives 1.32s after other, the "time of arrival"
 * of the notification will be updated 0.32s after the first one. May leading to confusing screens.
 */
const useDateNow = () => {
  const [now, setNow] = useState<Date>(new Date());
  useEffect(() => {
    // Adds the setter to the listeners...
    listeners.push(setNow);

    return () => {
      // When unmounting, remove the setNow from the listeners.
      listeners = listeners.filter((l) => l === setNow);
    };
  }, []);

  return { now };
};

export default useDateNow;
