import { createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import { RequestableState } from "core/api/definitions";
import decoreThunk from "core/decorators/decorate";

import requestLoading from "core/decorators/requestLoading";
import { logout } from "core/auth";

import { FilterResponse, FilterValidationResponse } from "core/api/actions/projectValidateVolunteer";
import { getProjectValidateVolunteer } from "./action";

interface IState extends RequestableState, FilterResponse {
  total: number;
  hasMore: boolean;
}

const panelBetaProjectValidateVolunteerSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "panelBetaProjectValidateVolunteerSlice",
  initialState: {
    loading: "idle",
    filter_data: [],
    result: [],
    total: 0,
    hasMore: false,
  },

  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, getProjectValidateVolunteer, [
      requestLoading(),
      {
        pending: (state: IState) => {
          state.loading = "loading";
        },
        fulfilled: (state: IState, action: PayloadAction<FilterValidationResponse>) => {
          state.loading = "ok";
          if (action?.payload?.response) {
            state.filter_data = action.payload.response.filter_data;
            state.result = action.payload.response.result;
            state.total = action.payload.total;
            state.hasMore = action.payload.hasMore;
          }
        },
        rejected: (state: IState) => {
          state.loading = "error";
        },
      },
    ]);
    builder.addCase(logout.toString(), (state) => {
      state.loading = "idle";
    });
  },
});

export default panelBetaProjectValidateVolunteerSlice;
