import { yupResolver } from "@hookform/resolvers/yup";
import useTranslationV3 from "hooks/useTranslationV3";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { PhoneNumber } from "react-phone-number-input";
import styled from "styled-components/macro";
import yup from "utils/yup";
import Button from "../Button/Button";
import Text from "../common/Text";
import InputPhone from "../Form/Input/InputPhone";
import { ModalBodyRegister } from "./common";
import useCountry from "hooks/useCountry";

//TODO(Jeconias): Move ModalSubscribe, ModalSubscriptionBase and ModalRequestPhone to a new folder
interface RequestPhoneProps {
  onSubmit({ phone }: { phone: PhoneNumber }): void;
  disabled?: boolean;
}

const schemaFormPhone = yup.object().shape({
  phone: yup.string().required("is-required").phone(),
});

const RequestPhone = ({ onSubmit, disabled }: RequestPhoneProps) => {
  const { country } = useCountry();
  const { t } = useTranslationV3();
  const { handleSubmit, control, errors } = useForm({
    resolver: yupResolver(schemaFormPhone),
    mode: "onTouched",
    defaultValues: {
      phone: "",
    },
  });

  const onSubmitPhone = ({ phone }: { phone: string }) => {
    const data = {
      phone: { number: "", countryCallingCode: country.replace("+", ""), nationalNumber: phone.replace(country, "") },
    };
    onSubmit(data);
  };

  return (
    <ModalBodyRegister>
      <FormPhone onSubmit={handleSubmit(onSubmitPhone)}>
        <Text tag="h1" size="lg">
          {t("plain:Informe o seu telefone")}
        </Text>
        <Text color="neutralBase" size="sm">
          {t("plain:Precisamos do seu telefone para conseguir se comunicar melhor com você")}
        </Text>
        <Controller
          control={control}
          name="phone"
          render={({ onChange, value, ...props }) => (
            <InputPhone
              {...props}
              value={value?.number}
              onPhoneChange={onChange}
              invalid={!!errors.phone}
              message={errors.phone?.message ? t(`errors.${errors.phone?.message}`) : undefined}
              disabled={disabled}
            />
          )}
        />
        <Button color="primary" size="md" block disabled={disabled}>
          {t("actions.confirm.label")}
        </Button>
      </FormPhone>
    </ModalBodyRegister>
  );
};

export default RequestPhone;

const FormPhone = styled.form`
  h1 {
    text-align: center;
    margin-bottom: ${({ theme }) => theme.v3.spacing.xs};
  }
  p {
    margin-bottom: ${({ theme }) => theme.v3.spacing.sm};
  }
`;
