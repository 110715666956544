import React, { useCallback } from "react";
import { Centered, LargeInput } from "wizard/v3/components/common/styles";
import SupportText from "components/common/SupportText";
import { PageEvents } from "../definitions/commonTypes";
import Clue from "wizard/v3/components/Clue/Clue";
import ActionsFooter from "wizard/v3/components/Layout/ActionsFooter";
import FormWizard from "./FormWizard";
import { WizardContainer } from "wizard/v3/components/Layout/Wizard";
import { TitleContainer } from "wizard/v3/components/Layout/Content";
import { BaseTitle } from "components/common/BaseTitle";
import WizardTrans from "wizard/v3/components/WizardTrans/WizardTrans";
import { ValidationError } from "core/api/definitions";
import styled from "styled-components/macro";
import { rem } from "polished";
import Text from "v3/components/common/Text";

type Event = React.ChangeEvent<HTMLInputElement>;

export interface MultiLineInputProps extends PageEvents<string> {
  title: string;
  description?: string;
  placeholder: string;
  clue?: string;
  required?: boolean;
  value?: string;
  maxLength?: number;
  totalLength?: number;
  continueDisabled?: boolean;
}

const MultiLineInput: React.FC<MultiLineInputProps> = ({
  title,
  description,
  placeholder,
  clue,
  value,
  required,
  onContinue,
  onChange,
  onBack,
  continueDisabled,
  error,
  children,
  maxLength,
  totalLength,
  ...props
}) => {
  const onContinueCallback = useCallback(() => {
    if (onContinue && value) onContinue(value);
  }, [onContinue, value]);

  const hasCounter = maxLength && totalLength !== undefined;

  return (
    <FormWizard onContinue={onContinueCallback}>
      <WizardContainer>
        <TitleContainer>
          <Title tag="h1" size="lg">
            {title}
          </Title>
          {description && (
            <Text size="sm" color="neutralLight">
              {description}
            </Text>
          )}
        </TitleContainer>
        <InputWrapper>
          <LargeInput
            maxLength={maxLength}
            type="textarea"
            placeholder={placeholder}
            required={required}
            value={value}
            autoFocus
            onChange={(e: Event) => {
              const { value } = e.target;
              if (onChange) onChange(value);
            }}
            {...props}
            hasCounter={hasCounter}
          />
          {hasCounter && (
            <Counter>
              {totalLength} / {maxLength}
            </Counter>
          )}
        </InputWrapper>
        <Centered>
          {error && <WizardTrans>{error as ValidationError}</WizardTrans>}
          {clue && <Clue text={clue} />}
        </Centered>
        {children}
      </WizardContainer>
      <ActionsFooter onBack={onBack} continueDisabled={continueDisabled} onContinue={onContinueCallback} />
    </FormWizard>
  );
};

export default MultiLineInput;

const InputWrapper = styled.div`
  position: relative;
`;

const Title = styled(Text)`
  margin-bottom: ${({ theme }) => theme.v3.spacing.xs};
`;

const Counter = styled.div`
  position: absolute;
  right: ${rem(20)};
  bottom: ${rem(20)};
  font-size: ${rem(12)};
  line-height: ${rem(14)};
  letter-spacing: ${rem(1.13)};
  color: #8798ad;
`;
