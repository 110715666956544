import { createSlice, SliceCaseReducers, PayloadAction } from "@reduxjs/toolkit";
import { PhotoFile } from "wizard/v3/pages/definitions/commonTypes";
import { ActionData as BaseActionData, CausesEditAction } from "core/api/definitions";
import asyncThunk from "core/decorators/toolkit";
import api from "core/api";
import { StoreNewActionResponse, StoreNewActionRequest } from "core/api/actions/storeNewAction";
import { Features } from "wizard/v3/pages/action/SelectFeaturesPage";
import { LoadingStatus } from "core/api/definitions";
import decoreThunk from "core/decorators/decorate";
import { EditResponse } from "core/api/actions/edit";
import { storeNewSubscribe } from "./subscribe";
import { ActionCreateResponse, ActionCreateResponseData } from "core/api/actions/create";
import { ImageCropper } from "components/common/ImageGalleryCropper";

interface ActionData extends BaseActionData {
  features?: Features;
}
interface IState {
  action: {
    loading: LoadingStatus;
    response?: Partial<ActionData>;
    detailed?: boolean;
  };
  causes: {
    loading: LoadingStatus;
    response?: CausesEditAction[];
  };
  images?: ImageCropper[];
  coverImage?: PhotoFile;
  support: {
    loading: LoadingStatus;
    data?: ActionCreateResponseData;
  };
}

export const WIZARD_CREATE_ACTION = "wizard.create.action";

export const create = asyncThunk<ActionCreateResponse, undefined>("wizard/create/action/create", api.actions.create, {
  condition: [
    (_, state) =>
      state.wizard.create.action.support.data?.causes === undefined ||
      state.wizard.create.action.support.data?.causes?.length === 0,
  ],
});

export const createStoreNewAction = asyncThunk<StoreNewActionResponse, StoreNewActionRequest>(
  "wizard/create/action/storeNewAction",
  api.actions.storeNewAction
);

export const getActionDetails = asyncThunk("wizard/create/action/details", api.actions.edit, {
  condition: [
    (props, state) => {
      return (
        state.wizard.create.action.action.loading !== "loading" &&
        (state.wizard.create.action.action.loading !== "ok" ||
          props.action !== state.wizard.create.action.action.response?.slug ||
          !state.wizard.create.action.action.detailed)
      );
    },
  ],
});

const actionSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "wizard/create/action",
  initialState: {
    action: {
      loading: "idle",
      response: (() => {
        const data = localStorage.getItem(WIZARD_CREATE_ACTION);
        if (data) {
          try {
            return JSON.parse(data);
          } catch (e) {
            console.error("error unserializing NEWFLOW_CREATE_ACTION from localStorage: ", e);
          }
        }
        return undefined;
      })(),
    },
    causes: {
      loading: "idle",
    },
    support: {
      loading: "idle",
    },
  },
  reducers: {
    updateImages(state, action: PayloadAction<ImageCropper[]>) {
      state.images = action.payload;
    },
    updateCoverImage(state, action: PayloadAction<PhotoFile>) {
      state.coverImage = action.payload;
    },
    updateActionFeatures: (state, { payload }: PayloadAction<{ features: Features }>) => {
      state.action.response = { ...(state.action.response || {}), features: payload.features };
    },
    updateAction: (state, { payload }: PayloadAction<Partial<ActionData>>) => {
      state.action.response = { ...(state.action.response || {}), ...payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(storeNewSubscribe.pending.toString(), (state, _) => {
      // Force getActionsDetails be updated
      //state.action.loading = "idle";
    });

    decoreThunk(builder, createStoreNewAction, [
      {
        fulfilled: (state, action: PayloadAction<StoreNewActionResponse>) => {
          state.action.response = action.payload.params;
          state.action.detailed = false;
        },
      },
    ]);

    decoreThunk(builder, getActionDetails, [
      {
        pending: (state, _) => {
          state.action.loading = "loading";
          state.action.detailed = true;

          state.causes.loading = "loading";
        },
        fulfilled: (state, { payload }: PayloadAction<EditResponse>) => {
          state.action.loading = "ok";
          state.action.response = { ...(state.action.response || {}), ...payload.params?.action_data };
          state.action.detailed = true;

          state.causes.loading = "ok";
          state.causes.response = payload.params?.causes;
        },
        rejected: (state, _) => {
          state.action.loading = "error";
          state.causes.loading = "error";
        },
      },
    ]);
    decoreThunk(builder, create, [
      {
        pending: (state, _) => {
          state.support.loading = "loading";
        },
        fulfilled: (state, action) => {
          state.support.loading = "ok";
          state.support.data = action.payload.params;
        },
        rejected: (state, _) => {
          state.support.loading = "error";
        },
      },
    ]);
    builder.addCase(createStoreNewAction.toString(), (state) => {
      state.coverImage = undefined;
      state.images = undefined;
    });
  },
});

export const { updateImages, updateCoverImage, updateActionFeatures, updateAction } = actionSlice.actions;

export default actionSlice;
