import FeedBackModal from "components/FeedbackModal/FeedbackModal";
import api from "core/api";
import { LoadingStatus } from "core/api/definitions";
import useTranslationV3 from "hooks/useTranslationV3";
import React, { useCallback, useEffect, useState } from "react";
import { APIParamsToString } from "utils/helpers";
import Button from "../../Button/Button";
import { ModalHeader, Title, FormGroup } from "./common";
import InputGroup from "../../Form/Input/InputGroup";
import ModalBaseContainer, { ModalBody } from "../ModalBase";

interface ModalForgotPassword {
  isOpen?: boolean;
  toggle(): void;
}

const ModalForgotPassword = ({ isOpen, toggle }: ModalForgotPassword) => {
  const { t } = useTranslationV3();

  const [feedbackModal, setFeedbackModal] = useState(false);
  const [hasError, setHasError] = useState<string>();
  const [loading, setLoading] = useState<LoadingStatus>("idle");

  const onSuccessCallback = useCallback(() => {
    toggle();
    setFeedbackModal(true);
  }, [toggle, setFeedbackModal]);

  const onSubmitCallback = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      const email = (e.target as any)?.email?.value;

      if (!email) return;
      setLoading("loading");

      try {
        await api.auth.forgotPassword({ email });
        onSuccessCallback();
      } catch (e) {
        setHasError(e?.response?.data?.response?.message_translated);
        setLoading("error");
      }
    },
    [setLoading, setHasError, onSuccessCallback]
  );

  useEffect(() => {
    if (!isOpen) {
      setHasError(undefined);
      setLoading("idle");
    }
  }, [isOpen]);

  return (
    <>
      <ModalBaseContainer isOpen={isOpen} toggle={toggle} centered={false} modalSize="sm">
        <ModalHeader toggle={toggle} />
        <ModalBody>
          <Title tag="h1" size="lg" color="neutralBase">
            {t("plain:Esqueci minha senha")}
          </Title>
          <form onSubmit={onSubmitCallback}>
            <InputGroup
              tag={FormGroup}
              name="email"
              type="email"
              label={t("plain:Email")}
              placeholder={t("plain:Email Placeholder")}
              invalid={!!hasError}
              message={hasError}
              disabled={loading === "loading"}
              autoFocus
              required
            />
            <Button color="primary" size="md" block disabled={loading === "loading"}>
              {t("plain:Recuperar Senha")}
            </Button>
          </form>
        </ModalBody>
      </ModalBaseContainer>
      {/** @ts-ignore */}
      <FeedBackModal
        title={t("plain:Tudo certo")}
        message={t(
          "plain:Caso o seu e-mail esteja cadastrado, você receberá um e-mail com um link para efetuar a alteração da sua senha"
        )}
        onDismiss={feedbackModal}
        success={true}
        onClose={() => setFeedbackModal(false)}
      />
    </>
  );
};

export default ModalForgotPassword;
