import { combineReducers } from "@reduxjs/toolkit";
import projectValidationVolunteersSlicer from "./projectValidation/volunteers";
import projectCheckoutVolunteersSlicer from "./projectCheckout/volunteers";
import userSummarySlice from "./user/summary";
import panelDonationsSlice from "./donations";

const panelReducer = combineReducers({
  donations: panelDonationsSlice.reducer,
  projectValidation: projectValidationVolunteersSlicer.reducer,
  projectCheckout: projectCheckoutVolunteersSlicer.reducer,
  userSummary: userSummarySlice.reducer,
});

export default panelReducer;
