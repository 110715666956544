import http from "config/http";
import { ListResponse, ListWithFilterResponse, RegistrationStatus, UserDataShort } from "core/api/definitions";
import { ActionDataShort } from "../definitions";
import { ProjectDataShort } from "../definitions";
import queryString from "query-string";
import { isEmpty } from "lodash";
import { AxiosResponse } from "axios";

export interface VolunteerListRequest {
  projectSlug?: string;
  actionSlug?: string;
  subscribeSlug?: string;
  status: string;
  pg?: number;
  pp?: number;
}

export interface FilterDataValidation {
  action_data: {
    slug: string;
    title: string;
  };
  project_data: ProjectDataShort;
  subscribe_data: {
    name: string;
    slug: string;
    _id: string;
  }[];
  _id: string;
}

export interface ResultValidation {
  action_data: {
    slug: string;
    title: string;
    _id: string;
  };
  registration_data: {
    accept_terms: boolean;
    created_at: string;
    day: string;
    entity: string[];
    evaluation_message?: any;
    evaluation_rating?: number;
    message: string;
    project_owner_id: string;
    slot_id?: string;
    status: RegistrationStatus;
    subscribe_data: {
      action_id: string;
      slug: string;
      title: string;
      _id: string;
    };
    time_end: string;
    time_in_seconds: number;
    time_start: string;
    type_checkout: "manual" | null;
    updated_at: string;
    user_data: UserDataShort;
    _id: string;
  }[];
  _id: string;
}

export interface FilterResponse {
  filter_data: FilterDataValidation[];
  result: ResultValidation[];
}

export type FilterValidationResponse = ListWithFilterResponse<ResultValidation, FilterDataValidation[]>;

export default async ({
  actionSlug,
  subscribeSlug,
  projectSlug,
  ...props
}: VolunteerListRequest): Promise<AxiosResponse<FilterValidationResponse>> => {
  return http.get(
    `/v3/projects/${projectSlug}/actions/${actionSlug || "all"}/subscribe/${subscribeSlug || "all"}/volunteers/list` +
      (isEmpty(props) ? "" : "?" + queryString.stringify(props))
  );
};
