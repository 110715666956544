import { useReduxSelector } from "./useReduxSelector";
import { Origin } from "core/api/definitions";

const useEntity = () => {
  return useReduxSelector(({ pagesNew: { origin } }) => {
    const o = origin.params! as Origin;
    return {
      entity: o?.entity,
      isPlatform: o?.entity === "brasil" || o?.entity === "portugal",
      language: o?.language.toString(),
    };
  });
};

export default useEntity;
