import React from "react";
import styled, { css } from "styled-components/macro";
import { rem, rgba, mix } from "polished";
import { mq } from "utils/mediaQueries";
import Icon from "components/Icon/IconFontAwesome";
import { ColorV3 } from "utils/types";

export const BorderFocus = css`
  border-color: ${({ theme }) => theme.v3.colors.secondary};
  box-shadow: 0 10px 20px 0 rgba(13, 29, 51, 0.07);
`;

export const TrackVertical = styled.div<{ top?: number; right?: number }>`
  position: absolute;
  top: ${({ top }) => rem(top !== undefined ? top : 25)};
  right: ${({ right }) => rem(right !== undefined ? right : 5)};
  width: ${rem(7)} !important;
  height: 100%;
  background-color: transparent;

  & > div:first-child {
    opacity: 0.3;
    background-color: ${({ theme }) => theme.v3.colors.neutralLightness} !important;
  }

  ${mq.smDown} {
    right: 7px;
  }

  ${mq.xsDown} {
    right: 0;
  }
`;

export const TrackHorizontal = styled.div<{ hidden?: boolean; bottom?: number; left?: number }>`
  position: absolute;
  bottom: ${({ bottom }) => rem(bottom !== undefined ? bottom : 25)};
  left: ${({ left }) => rem(left !== undefined ? left : 5)};
  width: 100%;
  height: ${rem(7)} !important;
  background-color: transparent;

  & > div:first-child {
    opacity: 0.3;
    background-color: ${({ theme }) => theme.v3.colors.neutralLightness} !important;
  }

  ${({ hidden }) =>
    hidden &&
    css`
      width: 0;
      height: 0;
    `}
`;

export const FocusStyle = (color?: Exclude<ColorV3, "link">) => css`
  border: 1px solid transparent;
  outline: none;
  border-radius: 3px;
  box-shadow: ${({ theme }) => `inset 0 1px 0 ${rgba("#fff", 0.15)}, 0 1px 1px ${rgba("#000", 0.075)},
            0 0 0 0.2rem ${rgba(mix(0.15, "#fff", theme.v3.colors[color || "secondary"]), 0.5)}`};
`;

export const ellipsis = css`
  display: -webkit-box;
  max-width: 200px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CloseButtonPopup = ({ color, onClick, ...props }: { color?: string; onClick(): void }): JSX.Element => (
  <CloseButtonContainer onClick={onClick} {...props}>
    <Icon icon="times" size="sm" color={color || "#000"} />
  </CloseButtonContainer>
);

const CloseButtonContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 10px;
  right: 10px;
  height: 21px;
  width: 21px;
  background-color: #fff;
  border-radius: 100%;
  cursor: pointer;
`;
