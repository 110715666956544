import styled, { css } from "styled-components/macro";
import { Container as BSContainer } from "reactstrap";
import { ModalBody, ModalHeader } from "components/Modal";

export const CustomModalBody = styled(ModalBody)`
  padding: 0;
`;

export const CustomModalHeader = styled(ModalHeader)`
  .modal-title {
    margin-top: 18px;
  }
`;

export const Container = styled(BSContainer)`
  position: relative;
  max-width: 630px;
  padding-left: 30px;
  padding-right: 30px;
  margin: 0;
  list-style: none;
`;

export const FooterContainer = styled.section`
  box-shadow: 0 0 5px 0 rgba(45, 44, 54, 0.3);
`;

export const FooterContent = styled(Container)`
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
`;

export const Content = styled.div<{ fixedHeight: boolean }>`
  ${(props) =>
    props.fixedHeight &&
    css`
      max-height: 70vh;
    `}

  overflow: auto;
  background-color: #f9fafc;
  margin-top: 15px;
  padding: 30px;
  color: ${(props) => props.theme.colors.support_text};
  font-size: 15px;
  line-height: 25px;

  p:last-child {
    margin-bottom: 0;
  }
`;
