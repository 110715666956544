import { AxiosResponse } from "axios";
import http from "config/http";
import { ResponseStatus, ResponseWithParams } from "../../definitions";

export interface UpdateEmailGetCodeRequest {
  new_email: string;
  password: string;
}

export type UpdateEmailGetCodeResponse = ResponseWithParams<ResponseStatus, any>;

const updateEmailGetCode = async (
  data: UpdateEmailGetCodeRequest
): Promise<AxiosResponse<UpdateEmailGetCodeResponse>> => {
  return http.post<UpdateEmailGetCodeResponse>(`/users/updateEmail/getCode`, data);
};

export default updateEmailGetCode;
