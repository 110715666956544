import useTheme from "hooks/useTheme";
import { useUser } from "hooks/useUser";
import { rem } from "polished";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import routes from "routes";
import styled, { css } from "styled-components/macro";
import Img from "v3/components/common/Img";
import Text from "v3/components/common/Text";
import { Centered } from "wizard/v3/components/common/styles";

const MatchmakerProfilePreparationPage = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const { user } = useUser();

  const [progress, setProgress] = useState<number>(72);
  const intervalRef = useRef<number | NodeJS.Timeout>();

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setProgress((prev) => prev + 1);
    }, 200);

    return () => clearInterval(intervalRef.current as number);
  }, []);

  useEffect(() => {
    if (progress === 100) {
      clearInterval(intervalRef.current as number);
      history.push(routes.wizard.matchmaker.preferences);
    }
  }, [progress, history]);

  return (
    <Container>
      <Centered>
        {!(progress === 100) && (
          <Text tag="span" color="neutralLight" size="sm">
            {t("common.wait")}
          </Text>
        )}
        <Helmet title={t("wizard.pages.profilePreparation.title")} />
        <Text tag="h1" size="lg">
          {t("wizard.pages.profilePreparation.title")}
        </Text>
        <ImageWrapper hasImage={!user?.user_picture}>
          <Img src={user?.user_picture || ""} alt={""} />
        </ImageWrapper>
        <Progress width={progress}>
          <Text tag="span" color="neutralLight" size="sm">{`${progress}%`}</Text>
        </Progress>
      </Centered>
    </Container>
  );
};

export default MatchmakerProfilePreparationPage;

const Container = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 0 15px;
  background-color: ${({ theme }) => theme.v3.colors.neutralWhite};

  h1 {
    margin-bottom: ${({ theme }) => theme.v3.spacing.lg};
  }

  span {
    margin-bottom: ${({ theme }) => theme.v3.spacing.xs};
  }
`;

const ImageWrapper = styled.div<{ hasImage: boolean }>`
  width: 200px;
  height: 200px;
  margin: 0 0 ${({ theme }) => theme.v3.spacing.lg};
  position: relative;

  ${({ hasImage }) =>
    hasImage &&
    css`
      background-color: #fff;
      border-radius: 100%;
    `}

  > img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    object-fit: cover;
    object-position: center;
  }

  > div {
    position: absolute;
    top: 6px;
    right: 0px;
  }
`;

const Progress = styled.div<{ width: number }>`
  width: 260px;
  position: relative;

  ::before {
    content: "";
    height: 4px;
    width: 260px;
    position: absolute;
    background-color: ${({ theme }) => theme.v3.colors.neutralLightest};
  }
  & > span {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 4px;
    text-align: right;
    width: ${({ width }) => `${width}%`};
    background-color: ${({ theme }) => theme.v3.colors.primary};
    line-height: ${rem(42)};
    transition: width 500ms ease-in;
  }
`;
