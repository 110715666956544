import api from "core/api";
import asyncThunk from "core/decorators/toolkit";

export const platformAdministratorsShow = asyncThunk(
  "panel/beta/platform/administrators/show",
  api.admin.platforms.administrators.show
);

export const platformAdministratorsUpdate = asyncThunk(
  "panel/beta/platform/administrators/update",
  api.admin.platforms.administrators.update
);

export const platformAdministratorsRemove = asyncThunk(
  "panel/beta/platform/administrators/remove",
  api.admin.platforms.administrators.remove
);
