import userTypes from "./types";
import createReducer from "../createReducer";
// import { axiosDefaultError } from "../../utils/helper";

const INITIAL_STATE = {
  cvt: {
    loading: false,
    status: "",
  },
  lgn: {
    loading: false,
    status: "",
  },
  lgt: {
    loading: false,
    status: "",
  },
  usr: {
    loading: false,
    state: "",
    logged: "",
    project_data: {
      slug: "",
    },
  },
  usd: {
    loading: true,
    causes: [],
    interests: [],
    functions: [],
  },
  use: {
    loading: false,
    status: "",
  },
  usp: {
    loading: false,
    status: "",
  },

  up: { loading: false, status: "" },

  usv: {
    loading: false,
    status: "",
  },
  usnt: {
    loading: true,
    status: "",
  },
  rpa: {
    status: "",
  },
  grp: {
    loading: false,
    status: "",
  },
  rnp: {
    loading: false,
    status: "",
  },
  gcr: {
    loading: true,
  },
};

const userReducer = createReducer(INITIAL_STATE, {
  [userTypes.CVT_REQUESTED]: (state) => ({
    ...state,
    cvt: {
      loading: true,
      status: "waiting",
    },
  }),
  [userTypes.CVT_RECEIVED]: (state, { payload }) => {
    const status = {
      loading: false,
      success: true,
      status: "received",
    };
    return {
      ...state,
      cvt: {
        ...payload,
        ...status,
      },
      lgn: {
        ...payload,
        ...status,
      },
    };
  },
  [userTypes.CVT_FAILED]: (state, action) => ({
    ...state,
    cvt: {
      ...action.payload,
      loading: false,
      status: "failed",
    },
  }),

  [userTypes.LGN_REQUESTED]: ({ lgn, ...state }) => ({
    ...state,
    lgn: {
      ...lgn,
      loading: true,
      status: "waiting",
    },
  }),
  [userTypes.LGN_RECEIVED]: ({ lgn, usr, ...state }, { payload, email }) => {
    return {
      ...state,
      lgn: {
        ...lgn,
        ...payload,
        email,
        selected_dash_profile:
          lgn.selected_dash_profile || (payload.dash_profile === "both" ? "volunteer" : payload.dash_profile),
        loading: false,
        status: "received",
        success: true,
      },
      usr: {
        ...usr,
        logged: true,
      },
    };
  },
  [userTypes.LGN_FAILED]: ({ lgn, ...state }, action) => ({
    ...state,
    lgn: {
      ...lgn,
      ...action.payload,
      loading: false,
      status: "failed",
    },
  }),

  [userTypes.LGT_REQUESTED]: (state) => ({
    ...state,
    lgt: {
      loading: true,
      status: "waiting",
    },
  }),
  [userTypes.LGT_RECEIVED]: (state, action) => ({
    ...INITIAL_STATE,
    lgt: {
      ...action.payload,
      loading: false,
      status: "received",
    },
    lgn: {
      loading: false,
      status: "",
    },
  }),
  [userTypes.LGT_FAILED]: (state, action) => ({
    ...state,
    lgt: {
      ...action.payload,
      loading: false,
      status: "failed",
    },
  }),

  [userTypes.USR_REQUESTED]: (state) => ({
    ...state,
    usr: {
      logged: state.usr.logged ? state.usr.logged : false,
      loading: true,
      status: "waiting",
    },
  }),
  [userTypes.USR_RECEIVED]: (state, action) => ({
    ...state,
    usr: {
      ...action.payload,
      logged: state.usr.logged,
      loading: false,
      status: "received",
      success: true,
    },
  }),
  [userTypes.USR_FAILED]: (state, action) => ({
    ...state,
    usr: {
      ...action.payload,
      loading: false,
      status: "failed",
    },
  }),

  [userTypes.USD_REQUESTED]: (state) => ({
    ...state,
    usd: {
      loading: true,
      status: "waiting",
    },
  }),
  [userTypes.USD_RECEIVED]: (state, action) => ({
    ...state,
    usd: {
      ...action.payload,
      loading: false,
      status: "received",
      success: true,
    },
  }),
  [userTypes.USD_FAILED]: (state, action) => ({
    ...state,
    usd: {
      ...action.payload,
      loading: false,
      status: "failed",
    },
  }),

  [userTypes.USE_REQUESTED]: (state) => ({
    ...state,
    use: {
      loading: true,
      status: "waiting",
    },
  }),
  [userTypes.USE_RECEIVED]: (state, action) => ({
    ...state,
    use: {
      ...action.payload,
      loading: false,
      status: "received",
      success: true,
    },
  }),
  [userTypes.USE_FAILED]: (state, action) => ({
    ...state,
    use: {
      ...action.payload,
      loading: false,
      status: "failed",
    },
  }),

  [userTypes.USP_REQUESTED]: (state) => ({
    ...state,
    usp: {
      loading: true,
      status: "waiting",
    },
  }),
  [userTypes.USP_RECEIVED]: (state, action) => ({
    ...state,
    usp: {
      ...action.payload,
      loading: false,
      status: "received",
      success: true,
    },
  }),
  [userTypes.USP_FAILED]: (state, action) => ({
    ...state,
    usp: {
      ...action.payload,
      loading: false,
      status: "failed",
    },
  }),

  [userTypes.UP_REQUESTED]: (state) => ({
    ...state,
    up: {
      loading: true,
      status: "waiting",
    },
  }),

  [userTypes.UP_RECEIVED]: ({ up, lgn, ...state }, { payload }) => ({
    ...state,
    up: {
      ...up,
      ...payload,
      loading: false,
      status: "received",
      success: true,
    },
    lgn: {
      ...lgn,
      dash_profile: (payload && payload.user_data && payload.user_data.dash_profile) || lgn.dash_profile,
      project_type: (payload && payload.user_data && payload.user_data.project_type) || lgn.project_type,
      personal_project: (payload && payload.user_data && payload.user_data.personal_project) || lgn.personal_project,
      has_personal_project:
        !!(payload && payload.user_data && payload.user_data.personal_project) || lgn.personal_project,
      type: lgn.type,
      user_picture: payload?.user_data?.profile_image || lgn?.user_picture,
    },
  }),

  [userTypes.UP_FAILED]: ({ up, ...state }, action) => ({
    ...state,
    up: {
      ...up,
      ...action.payload,
      loading: false,
      status: "failed",
    },
  }),

  [userTypes.CLEAN_USER_DATA]: (state) => ({
    ...state,
    usp: {
      ...INITIAL_STATE.usp,
    },
    rpa: {
      ...INITIAL_STATE.rpa,
    },
    grp: {
      ...INITIAL_STATE.grp,
    },
    rnp: {
      ...INITIAL_STATE.rnp,
    },
  }),

  [userTypes.USV_REQUESTED]: (state) => ({
    ...state,
    usv: {
      loading: true,
      status: "waiting",
    },
  }),

  [userTypes.USV_RECEIVED]: (state, action) => ({
    ...state,
    usv: {
      ...action.payload,
      loading: false,
      status: "received",
      success: true,
    },
  }),

  [userTypes.USV_FAILED]: (state, action) => ({
    ...state,
    usv: {
      ...action.payload,
      loading: false,
      status: "failed",
    },
  }),

  [userTypes.USNT_REQUESTED]: (state) => ({
    ...state,
    usnt: {
      loading: true,
      status: "waiting",
    },
  }),

  [userTypes.USNT_RECEIVED]: (state, action) => ({
    ...state,
    usnt: {
      ...action.payload,
      loading: false,
      status: "received",
    },
  }),

  [userTypes.USNT_FAILED]: (state, action) => ({
    ...state,
    usnt: {
      ...action.payload,
      loading: false,
      status: "failed",
    },
  }),

  [userTypes.RPA_REQUESTED]: (state) => ({
    ...state,
    rpa: {
      ...INITIAL_STATE.rpa,
      loading: true,
      status: "waiting",
    },
  }),

  [userTypes.RPA_RECEIVED]: (state, action) => ({
    ...state,
    rpa: {
      ...action.payload,
      loading: false,
      status: "received",
      success: true,
    },
  }),

  [userTypes.RPA_FAILED]: (state, action) => ({
    ...state,
    rpa: {
      ...action.payload,
      loading: false,
      status: "failed",
    },
  }),

  [userTypes.GRP_REQUESTED]: (state) => ({
    ...state,
    grp: {
      loading: true,
      status: "waiting",
    },
  }),

  [userTypes.GRP_RECEIVED]: (state, action) => ({
    ...state,
    grp: {
      ...action.payload,
      loading: false,
      status: "received",
      success: true,
    },
  }),

  [userTypes.GRP_FAILED]: (state, action) => ({
    ...state,
    grp: {
      ...action.payload,
      loading: false,
      status: "failed",
    },
  }),

  [userTypes.RNP_REQUESTED]: (state) => ({
    ...state,
    rnp: {
      loading: true,
      status: "waiting",
    },
  }),

  [userTypes.RNP_RECEIVED]: (state, action) => ({
    ...state,
    rnp: {
      ...action.payload,
      loading: false,
      status: "received",
      success: true,
    },
  }),

  [userTypes.RNP_FAILED]: (state, action) => ({
    ...state,
    rnp: {
      ...action.payload,
      loading: false,
      status: "failed",
    },
  }),

  [userTypes.GCR_REQUESTED]: (state) => ({
    ...state,
    gcr: {
      loading: true,
      status: "waiting",
    },
  }),

  [userTypes.GCR_RECEIVED]: (state, action) => ({
    ...state,
    gcr: {
      ...action.payload,
      loading: false,
      status: "received",
      success: true,
    },
  }),

  [userTypes.GCR_FAILED]: (state, action) => ({
    ...state,
    gcr: {
      ...action.payload,
      loading: false,
      status: "failed",
    },
  }),
});

export default userReducer;
