import Button from "components/Button/Button";
import { rgba } from "polished";
import React, { useCallback, useEffect, useState } from "react";
import { Col, FormGroup, Input, Label } from "reactstrap";
import styled from "styled-components/macro";
import { ThemeColor, ThemeColors } from "core/api/definitions";
import useTheme from "hooks/useTheme";
import { useReduxSelector } from "hooks/useReduxSelector";
import { Origin } from "core/api/definitions";
import { useDispatch } from "react-redux";
import { restoreColors, updateColor } from "core/pages/origin";
import useLocalStorageRead from "hooks/useLocalStorageRead";
import useLocalStorageWrite from "hooks/useLocalStorageWrite";
import { transition } from "utils/animation";

interface EditorRowProps {
  htmlFor: string;
  label: string;
}

const EditorRow: React.FC<EditorRowProps> = ({ htmlFor, label, children }) => {
  return (
    <FormGroup row>
      <Label htmlFor={htmlFor} xs={4}>
        {label}
      </Label>
      {children}
    </FormGroup>
  );
};

interface ResetProps {
  onClick: () => void;
}

const Reset = ({ onClick }: ResetProps) => {
  return <ResetStyled onClick={(e) => onClick()}>&times;</ResetStyled>;
};

interface ColorRowProps extends Omit<EditorRowProps, "htmlFor"> {
  id: string;
  value: string;
  originalValue: string;
  onChange: (color: string) => void;
}

const colorRegex = /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/;

const ColorRow: React.FC<ColorRowProps> = ({ value, originalValue, onChange, id, label }) => {
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const onChangeCallback = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setInternalValue(e.target.value);
    if (onChange && colorRegex.test(e.target.value)) {
      onChange(e.target.value);
    }
  }, []);

  const onResetCallback = useCallback(() => {
    if (onChange) onChange(originalValue);
  }, [onChange, originalValue]);

  return (
    <EditorRow htmlFor={id} label={label}>
      <ColorRowCol xs={8}>
        <Input id={id} value={internalValue} invalid={!colorRegex.test(internalValue)} onChange={onChangeCallback} />
        <Color value={value} />
        <Reset onClick={onResetCallback} />
      </ColorRowCol>
    </EditorRow>
  );
};

interface ThemeEditorProps {
  onChange: (colors: Partial<ThemeColors>) => void;
}

const colors: ThemeColor[] = [
  "primary",
  "primary_text",
  "secondary",
  "secondary_text",
  "tertiary",
  "quaternary",
  "link_text",
  "support_text",
  "secondary_700",
  "info",
  "success",
  "danger",
  "background_colored",
];

const ThemeEditor = ({ onChange }: ThemeEditorProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const [isEditing, setIsEditing] = useState(false);

  const originalColors = useReduxSelector((state) => (state.pagesNew.origin.originalResponse as Origin).theme.colors);

  const localStorageColors = useLocalStorageRead("themeEditor.colors");

  const [themeColors, setThemeColors] = useState<Partial<ThemeColors>>(localStorageColors || theme.colors);

  useLocalStorageWrite(themeColors, "themeEditor.colors");

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (mounted) return;
    setMounted(true);
    onChange(themeColors); // Yes I want to call it only once.
  }, [mounted, onChange]);

  const toggleEditing = useCallback(() => {
    setIsEditing((isEditing) => !isEditing);
  }, []);

  const changeThemeColor = useCallback(
    (color: ThemeColor, value: string) => {
      dispatch(
        updateColor({
          color,
          value,
        })
      );
      setThemeColors((themeColors) => {
        const d = {
          ...(themeColors || {}),
          [color]: value,
        };
        onChange(d);
        return d;
      });
    },
    [dispatch]
  );

  const clearAll = useCallback(() => {
    if (!window.confirm("Certeza? Vai perder tudo!")) return;
    dispatch(restoreColors());
    setThemeColors({});
    onChange({});
  }, [originalColors]);

  const exportCallback = useCallback(() => {
    window.prompt("Copie o código:", JSON.stringify({ ...originalColors, ...themeColors }, null, 2));
  }, [themeColors, originalColors]);

  return (
    <Container>
      <ContainerScroll>
        {isEditing && (
          <Editor>
            <Button onClick={toggleEditing}>Fechar</Button> <Button onClick={exportCallback}>Exportar</Button>{" "}
            <Button onClick={clearAll}>Limpar tudo</Button>
            <hr />
            {colors.map((color) => {
              return (
                <ColorRow
                  key={`themeEditor_${color}`}
                  id={`themeEditor_${color}`}
                  label={color}
                  value={themeColors[color] || theme?.colors[color]}
                  originalValue={originalColors[color]}
                  onChange={(value) => {
                    changeThemeColor(color, value);
                  }}
                />
              );
            })}
          </Editor>
        )}
        {!isEditing && (
          <CTA title="Edit theme" onClick={toggleEditing}>
            THEME
          </CTA>
        )}
      </ContainerScroll>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  right: 30px;
  top: 100px;
  z-index: 100000;
`;

const ContainerScroll = styled.div`
  height: 80vh;
  overflow-y: auto;
`;

const CTA = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background: green;
  border-radius: 50%;
  cursor: pointer;

  opacity: 0.2;
  transition: ${transition.slow("opacity")};

  &:hover {
    opacity: 1;
  }
`;

const Editor = styled.div`
  background: #fff;
  padding: 20px;
  box-shadow: 5px 5px 5px ${rgba("#000", 0.2)};
  border-radius: 4px;
  border: 1px solid #eee;
`;

const ColorRowCol = styled(Col)`
  display: flex;
  align-items: center;
`;

const Color = styled.div<{ value: string }>`
  flex: 0 0 22px;
  background: ${({ value }) => value || "#000"};
  border: 1px solid #eee;
  height: 22px;
  margin-left: 10px;
  border-radius: 5px;
`;

const ResetStyled = styled.a`
  cursor: pointer;
  flex: 0 0 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ddd;
  border: 1px solid #eee;
  height: 22px;
  margin-left: 10px;
  border-radius: 50%;
`;

export default ThemeEditor;
