import {
  CLASSNAME_FLOAT_NAV,
  CLASSNAME_OPEN_NAV,
  NAVBAR_PANEL_OPENED_WIDTH,
  NAVBAR_PANEL_CLOSED_WIDTH,
} from "./constants";

interface SizesIndexInterface {
  [index: string]: number;
}

const sizesMax: SizesIndexInterface = {
  xxs: 379.98,
  xs: 669.98,
  sm: 1089.98,
};

const sizesMin: SizesIndexInterface = {
  sm: 670,
  md: 1090,
};

const mqV3 = Object.keys(sizesMax).reduce((acc: any, size: string) => {
  return {
    ...acc,
    [size.toString()]: `@media (min-width: ${sizesMin[size] ?? 0}px) and (max-width: ${sizesMax[size]}px)`,
  };
}, {});

for (const size in sizesMin) {
  mqV3[size + "Up"] = `@media (min-width: ${sizesMin[size]}px)`;
}

for (const size in sizesMax) {
  mqV3[size + "Down"] = `@media (max-width: ${sizesMax[size]}px)`;
}

mqV3["supportUp"] = ({ size }: { size: number }) => `@media (min-width: ${size}px)`;
mqV3["supportDown"] = ({ size }: { size: number }) => `@media (max-width: ${size}px)`;

export const isXXS = (): boolean => window.matchMedia(`(max-width: ${sizesMax.xxs}px)`).matches;
export const isXS = (): boolean => window.matchMedia(`(max-width: ${sizesMax.xs}px)`).matches;
export const isSM = (): boolean =>
  window.matchMedia(`(min-width: ${sizesMin.sm}px) and (max-width: ${sizesMax.sm}px)`).matches;
export const isMD = (): boolean => window.matchMedia(`(min-width: ${sizesMin.md}px)`).matches;

export const navbarPanelIsOpen = () => {
  const body = document.getElementsByTagName("body");
  if (body && body.length === 1) {
    const isOpen = body[0].classList.contains(CLASSNAME_OPEN_NAV);
    const isFloat = body[0].classList.contains(CLASSNAME_FLOAT_NAV);
    return { isOpen, size: isFloat ? 0 : isOpen ? NAVBAR_PANEL_OPENED_WIDTH : NAVBAR_PANEL_CLOSED_WIDTH };
  }
  return { isOpen: undefined, size: 0 };
};

export const isXSPanel = (): boolean => isXS();

export const isSMPanel = (): boolean => {
  const panelIsOpen = navbarPanelIsOpen();
  return window.matchMedia(
    `(min-width: ${sizesMin.sm + panelIsOpen.size}px) and (max-width: ${sizesMax.sm + panelIsOpen.size}px)`
  ).matches;
};

export const isMDPanel = (): boolean => {
  const panelIsOpen = navbarPanelIsOpen();
  return window.matchMedia(`(min-width: ${sizesMin.md + panelIsOpen.size}px)`).matches;
};

export default mqV3;
