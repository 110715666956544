import api from "core/api";
import asyncThunk from "core/decorators/toolkit";

export const listAllTags = asyncThunk("panel/beta/admin/getAllActions", api.admin.tags.listAllTags);

export const searchActions = asyncThunk("panel/beta/admin/searchActions", api.admin.tags.searchActions);

export const listTag = asyncThunk("panel/beta/admin/getAction", api.admin.tags.listTag);

export const createTag = asyncThunk("panel/beta/admin/createTag", api.admin.tags.createTag);

export const addActionOnTag = asyncThunk("panel/beta/admin/addActionOnTag", api.admin.tags.addActionOnTag);

export const deleteActionOnTag = asyncThunk("panel/beta/admin/deleteActionOnTag", api.admin.tags.deleteActionOnTag);

export const reportTag = asyncThunk("panel/beta/admin/reportTag", api.admin.tags.reportTag);
