import { createSlice, SliceCaseReducers, PayloadAction } from "@reduxjs/toolkit";
import { RequestableState, Subscribe, Action, ActionData } from "core/api/definitions";
import decoreThunk from "core/decorators/decorate";
import { listAllTags, listTag, searchActions } from "./actions";
import requestLoading from "core/decorators/requestLoading";
import { logout } from "core/auth";
import { ListTagResponse, ListTagActionInterface } from "core/api/admin/tags/listTag";
import { ListAllTagsInterface, ListAllTagsResponse } from "core/api/admin/tags/listAllTags";
import { SearchActionResponse, SearchActionResponseInterface } from "core/api/admin/tags/searchActions";

interface IStateListAllTags extends RequestableState {
  response: ListAllTagsInterface[];
}

interface IStateListTag extends RequestableState {
  actions_data: ListTagActionInterface[];
  tag_data: {
    created_at: string;
    description: string;
    slug: string;
    status: string;
    updated_at: string;
    _id: string;
  };
}

interface IStateSearchAction extends RequestableState {
  response: SearchActionResponseInterface[];
  hasMore: boolean;
}

export const panelAdminGetActionSlice = createSlice<IStateListTag, SliceCaseReducers<IStateListTag>>({
  name: "panel/beta/admin/getAction",
  initialState: {
    loading: "idle",
    actions_data: [],
    tag_data: {
      created_at: "",
      description: "",
      slug: "",
      status: "",
      updated_at: "",
      _id: "",
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, listTag, [
      requestLoading(),
      {
        pending: (state: IStateListTag) => {
          state.loading = "loading";
        },

        fulfilled: (state: IStateListTag, action: PayloadAction<ListTagResponse>) => {
          state.loading = "ok";
          if (action?.payload?.response?.tag_data) {
            state.tag_data = action.payload.response.tag_data;
            state.actions_data = action.payload.response.actions_data;
          }
        },
        rejected: (state: IStateListTag) => {
          state.loading = "error";
        },
      },
    ]);

    builder.addCase(logout.toString(), (state) => {
      state.loading = "idle";
    });
  },
});

export const panelAdminGetAllActionsSlice = createSlice<IStateListAllTags, SliceCaseReducers<IStateListAllTags>>({
  name: "panel/beta/admin/getAllActions",
  initialState: {
    loading: "idle",
    response: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, listAllTags, [
      requestLoading(),
      {
        pending: (state: IStateListAllTags) => {
          state.loading = "loading";
        },

        fulfilled: (state: IStateListAllTags, action: PayloadAction<ListAllTagsResponse>) => {
          state.loading = "ok";
          if (action?.payload?.response) {
            state.response = action.payload.response;
          }
        },

        rejected: (state: IStateListAllTags) => {
          state.loading = "error";
        },
      },
    ]);

    builder.addCase(logout.toString(), (state) => {
      state.loading = "idle";
    });
  },
});

export const panelAdminSearchActionsSlice = createSlice<IStateSearchAction, SliceCaseReducers<IStateSearchAction>>({
  name: "panel/beta/admin/searchActions",
  initialState: {
    loading: "idle",
    response: [],
    hasMore: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, searchActions, [
      requestLoading(),
      {
        pending: (state: IStateSearchAction) => {
          state.loading = "loading";
        },

        fulfilled: (state: IStateSearchAction, action: PayloadAction<SearchActionResponse>) => {
          state.loading = "ok";
          if (action?.payload?.response) {
            state.response = action.payload.response;
          }
        },

        rejected: (state: IStateSearchAction) => {
          state.loading = "error";
        },
      },
    ]);

    builder.addCase(logout.toString(), (state) => {
      state.loading = "idle";
    });
  },
});
