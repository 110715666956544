import Loading from "components/Loading/Loading";
import { listOpportunityMatchmaker } from "core/api/site/opportunities";
import { useReduxSelector } from "hooks/useReduxSelector";
import useTheme from "hooks/useTheme";
import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import styled, { css } from "styled-components";
import WrapperMobile from "v3/components/Grid/WrapperMobile";
import MatchmakerPreferencesOpportunitiesSlide from "v3/components/MatchmakerPreferencesOpportunitiesSlide/MatchmakerPreferencesOpportunitiesSlide";
import { handleOpportunityList } from "../Opportunity/OpportunityListPage";

interface MatchmakerPreferencesSectionProps {
  className?: string;
}

const MatchmakerPreferencesSection = ({ className }: MatchmakerPreferencesSectionProps) => {
  const dispatch = useDispatch();

  const theme = useTheme();

  const { loading, list } = useReduxSelector((state) => ({
    loading: state.pagesNew.opportunities.loading,
    list: state.pagesNew.opportunities.listMatchmaker?.response,
  }));

  useEffect(() => {
    (async () => {
      await dispatch(listOpportunityMatchmaker({}));
    })();
  }, []);

  const listMemorized = useMemo(() => handleOpportunityList(list ?? []), [list]);

  return (
    <ContainerMatchmaker className={className}>
      {loading !== "ok" && <Loading color={theme.v3.colors.neutralWhite} />}
      {loading === "ok" && <MatchmakerPreferencesOpportunitiesSlide listItems={listMemorized} />}
    </ContainerMatchmaker>
  );
};

export default MatchmakerPreferencesSection;

const ContainerMatchmaker = styled(WrapperMobile)(
  ({ theme }) => css`
    padding: ${theme.v3.spacing.xxl} 0;
    background-color: ${theme.v3.colors.secondary};
    overflow-x: hidden;
    min-height: 600px;
  `
);
