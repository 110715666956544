import history from "utils/history";

const historyMiddleware = () => (next) => (action) => {
  const { urlUpdates } = action;

  if (!urlUpdates) {
    return next(action);
  }

  const { location = history.location, queryParams = {}, replace = false } = urlUpdates;

  const historyDispatch = replace ? history.replace : history.push;

  setTimeout(() => {
    historyDispatch(
      typeof location === "string"
        ? location
        : {
            ...location,
            search: mergeQueryString(location.search, queryParams),
          }
    );
  }, 500);

  return next(action);
};

export default historyMiddleware;

const mergeQueryString = (currentSearch, queryParams) => {
  const currentQueries = new URLSearchParams(currentSearch);
  Object.entries(queryParams).forEach(([key, value]) => {
    currentQueries.set(key, value);
  });
  return currentQueries.toString();
};
