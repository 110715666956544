import createReducer from "../createReducer";
import utilsTypes from "./types";
import { STORE_KEYS } from "utils/constants";

const INITIAL_STATE = {
  loading: false,
  bcl: (() => {
    try {
      return JSON.parse(localStorage.getItem(STORE_KEYS.BLC)) || false;
    } catch {
      return false;
    }
  })(),
};

const utilsReducer = createReducer(INITIAL_STATE, {
  [utilsTypes.REDIRECT_URL]: (state, action) => ({
    ...state,
    redirect: {
      ...action.payload,
    },
  }),

  [utilsTypes.BLC_RECEIVED]: (state, action) => ({
    ...state,
    bcl: action.payload,
  }),

  [utilsTypes.BLC_FAILED]: (state, action) => ({
    ...state,
    bcl: action.payload,
  }),
});

export default utilsReducer;
