import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import styled from "styled-components/macro";
import { PageEvents } from "../definitions/commonTypes";
import MultiLineInput from "../common/MultilineInputPage";
import InputLabeled from "wizard/v3/components/Input/InputLabeled";
import { ValidationErrorSet } from "core/api/definitions";
import { Helmet } from "react-helmet";

export interface ActionDescriptionBenefited {
  description: string;
  benefited: number;
}

interface DescriptionActionProps extends PageEvents<ActionDescriptionBenefited> {
  data?: ActionDescriptionBenefited;
}

const initialData: ActionDescriptionBenefited = {
  description: "",
  benefited: 0,
};

const DescriptionActionPage: React.FC<DescriptionActionProps> = ({ data, error, onChange, onContinue, onBack }) => {
  const { t } = useTranslation();

  const onDescriptionChangeCallback = useCallback(
    (description) => {
      if (onChange) onChange({ ...(data || initialData), description });
    },
    [onChange]
  );

  const onBenefitedChangeCallback = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      if (onChange) onChange({ ...(data || initialData), benefited: parseInt(value) });
    },
    [onChange]
  );

  const onContinueCallback = useCallback(
    (description) => {
      if (onContinue) onContinue({ ...(data || initialData), description });
    },
    [onContinue, data]
  );

  return (
    <>
      <Helmet title={t("wizard.pages.action.description.title")} />
      <MultiLineInput
        title={t("wizard.pages.action.description.title")}
        description={t("wizard.pages.action.description.subtitle")}
        placeholder={""}
        value={data?.description}
        onChange={onDescriptionChangeCallback}
        onContinue={onContinueCallback}
        onBack={onBack}
        error={(error as ValidationErrorSet)?.details}
        continueDisabled={
          !!(error as ValidationErrorSet)?.details ||
          !!(error as ValidationErrorSet)?.["impact.members"] ||
          !(data?.description && data?.benefited)
        }
        maxLength={1000}
        totalLength={data?.description.length}
      >
        <Row className="justify-content-center">
          <Col md="5">
            <CustomInput
              required
              name="benefited"
              label={t("wizard.pages.action.description.labels.benefited")}
              type="number"
              placeholder="00"
              value={data?.benefited}
              min={0}
              onChange={onBenefitedChangeCallback}
              error={(error as ValidationErrorSet)?.["impact.members"]}
            />
          </Col>
        </Row>
      </MultiLineInput>
    </>
  );
};

export default DescriptionActionPage;

const CustomInput = styled(InputLabeled)`
  padding: 20px;
`;
