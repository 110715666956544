import http from "config/http";
import { Response, ResponseStatus } from "../definitions";

interface EmailCheckIdentityRequest {
  code: string;
  email: string;
}

type EmailCheckIdentityResponse = Response<ResponseStatus>;

const emailCheckIdentity = (data: EmailCheckIdentityRequest) =>
  http.post<EmailCheckIdentityResponse>(`/users/emailCheckIdentity`, data);

export default emailCheckIdentity;
