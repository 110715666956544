import layers from "components/Layers";
import React, { useCallback, useEffect } from "react";
import styled, { css } from "styled-components/macro";
import Button from "../Button/Button";
import Notification from "./Notification";
import { LoadingStatus, NotificationType } from "core/api/definitions";
import { convertDateApiToLocal } from "utils/date";
import useTranslationV3 from "hooks/useTranslationV3";
import { useDispatch } from "react-redux";
import { markAsRead } from "core/pages/notifications";
import FeedbackNotification from "../FeedbackSection/FeedbackNotification";
import mqV3 from "utils/mediaQueriesV3";
import { motion, useAnimation } from "framer-motion";

interface NotificationContainerProps {
  isOpen: boolean;
  notifications?: NotificationType[];
  hasMore?: boolean;
  loading?: LoadingStatus;
  onLoadMore(): void;
}

const removeDomain = (link?: string) => {
  if (!(typeof link === "string")) return "";
  const r = link.match(/(?:\.[\w]*\/(\S+))/);
  if (r && r?.length > 1) return `/${r[1]}`;
  return "";
};

const NotificationContainer = ({ isOpen, notifications, onLoadMore, hasMore, loading }: NotificationContainerProps) => {
  const { t } = useTranslationV3();
  const dispatch = useDispatch();
  const notificationControls = useAnimation();

  const onSelectNotification = useCallback(async (notificationID: string) => {
    if (!notificationID) return;
    await dispatch(markAsRead({ notification_id: [notificationID] }));
  }, []);

  const onLoadMoreCallback = useCallback(() => onLoadMore(), [onLoadMore]);

  useEffect(() => {
    notificationControls.start(
      isOpen ? { y: 0, transition: { velocity: 200 } } : { y: "-100vh", transition: { velocity: 150 } }
    );
  }, [notificationControls, isOpen]);

  return (
    <NotificationContainerStyled initial={{ y: "-100vh" }} animate={notificationControls}>
      <Header>{t("plain:Notificações")}</Header>
      {(notifications || [])?.length === 0 && <FeedbackNotification />}
      {(notifications || [])?.length !== 0 && (
        <Content>
          {notifications?.map((n) => (
            <li key={n._id}>
              <Notification
                text={n.message}
                to={removeDomain(n.parameters.redirect)}
                icon={n.metadata.icon}
                at={convertDateApiToLocal(n.created_at)}
                onSelect={() => onSelectNotification(n._id)}
              />
            </li>
          ))}
        </Content>
      )}
      {hasMore && (
        <Footer>
          <Button color="primary" size="sm" onClick={onLoadMoreCallback} disabled={loading === "loading"}>
            {t("plain:Carregar mais")}
          </Button>
        </Footer>
      )}
    </NotificationContainerStyled>
  );
};

export default NotificationContainer;

const NotificationContainerStyled = styled(motion.div)`
  ${({ theme }) => css`
    position: absolute;
    box-shadow: ${theme.v3.boxShadow.l3};
    border: 1px solid ${theme.v3.colors.neutralLightest};
    width: 314px;
    right: 0;
    z-index: ${layers.Notifications};
    background-color: ${theme.v3.colors.neutralWhite};
    border-radius: 6px;
    top: calc(100% + 3px);

    ${mqV3.xsDown} {
      position: fixed;
      width: 95%;
      top: 65px;
      right: 0;
      left: 0;
      margin: 0 auto;
    }
  `}
`;

const Header = styled.header`
  ${({ theme }) => css`
    font-size: ${theme.v3.fontSize.xs};
    color: ${theme.v3.colors.neutralLight};
    text-transform: uppercase;
    border-bottom: 1px solid ${theme.v3.colors.neutralLightest};
    padding: ${theme.v3.spacing.sm};
    font-weight: 500;
  `}
`;

const Content = styled.ul`
  overflow-y: auto;
  max-height: 344px;
  padding: 0;
  margin: 0;
`;

const Footer = styled.footer`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.v3.colors.neutralLightest};
    padding: ${theme.v3.spacing.sm};
    text-align: center;
  `}
`;
