import { createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import { RequestableState, WithCurrentPage } from "core/api/definitions";
import { ListVolunteers } from "./actions";
import { ListVolunteersResponse, ListVolunteersRequest } from "core/api/admin/platforms/listVolunteers";

type Volunteers = Partial<WithCurrentPage<ListVolunteersResponse & RequestableState>>;

type IState = {
  volunteers: Volunteers;
};

const panelBetaListVolunteersSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "panelBetaListVolunteers",
  initialState: {
    volunteers: {
      loading: "idle",
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      ListVolunteers.pending.toString(),
      (state, action: PayloadAction<unknown, string, { arg: ListVolunteersRequest }>) => {
        if (state.volunteers.currentPage !== 1 && action.meta.arg.pg === 1)
          state.volunteers.currentPage = action.meta.arg.pg;
        state.volunteers!.loading = "loading";
      }
    );
    builder.addCase(
      ListVolunteers.fulfilled.toString(),
      (state, action: PayloadAction<Volunteers, string, { arg: ListVolunteersRequest }>) => {
        const pg = action.meta.arg.pg;
        if (!pg || pg === 1) {
          state.volunteers = { ...action.payload, currentPage: 1, loading: "ok" };
        } else {
          state.volunteers = {
            ...action.payload,
            response: [...(action.payload.response || [])],
            total: action.payload.total,
            hasMore: action.payload.hasMore,
            currentPage: pg,
            loading: "ok",
          };
        }
      }
    );
    builder.addCase(ListVolunteers.rejected.toString(), (state, action) => {
      state.volunteers!.loading = "error";
    });
  },
});

export default panelBetaListVolunteersSlice;
