import http from "config/http";
import { ResponseStatus, ResponseWithParams } from "../definitions";
import { Contact } from "../definitions";
import { Conditionals } from "../definitions";
import { Login } from "core/api/definitions";
import { Address } from "core/api/definitions";
import { AxiosResponse } from "axios";

export interface StoreNewUserRequestBase {
  name: string;
  email: string;
  password: string;
  password_confirmation: string;
  contacts: Contact[];
  profile_image?: string;
  code: string;
  indicated_by?: string;
}

export interface StoreNewUserRequest extends StoreNewUserRequestBase {
  program_invite_code?: string;
  receive_emails?: boolean;
  accept_terms: boolean;
  soccer_team_id?: string;
  match_maker?: boolean;
  gt_18_years?: boolean;
  document_number?: string;
}

export interface StoreNewMatchmakerUserRequest extends StoreNewUserRequest {
  locations: Address;
  conditionals: Conditionals;
}

type ResponseParamsUser = Login;

export type StoreNewUserResponse = ResponseWithParams<ResponseStatus, ResponseParamsUser>;

const storeNewUser = async (
  data: StoreNewUserRequestBase | StoreNewUserRequest | StoreNewMatchmakerUserRequest
): Promise<AxiosResponse<StoreNewUserResponse>> => await http.post<StoreNewUserResponse>(`/users`, data);

export default storeNewUser;
