import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { SimpleContainer, Step, Title, Text, BaseButton } from "../commonStyles";

const Summary = () => {
  const { t } = useTranslation("components");

  return (
    <SimpleContainer>
      <Step>{t("modalQuizTeam.steps.summary.currentStep")}</Step>
      <Title>{t("modalQuizTeam.steps.summary.title")}</Title>
      <Text>{t("modalQuizTeam.steps.summary.text")}</Text>
      <BaseButton href="https://futebol.transformabrasil.com.br/?open=futebol-onboarding">
        {t("modalQuizTeam.buttonContinue")}
      </BaseButton>
    </SimpleContainer>
  );
};

Summary.propTypes = {
  nextStep: PropTypes.func,
};

export default Summary;
