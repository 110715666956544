import { httpSubscribe } from "config/http";
import { ResponseStatus, ResponseWithParams } from "../definitions";
import { Contact } from "../definitions";
import { Conditionals } from "../definitions";
import { Login } from "core/api/definitions";
import { Address } from "core/api/definitions";
import { AxiosResponse } from "axios";

export interface storeNewUserSubscribeRequestBase {
  name: string;
  email: string;
  password: string;
  password_confirmation: string;
  contacts: Contact[];
  profile_image?: string;
  code: string;
}

export interface storeNewUserSubscribeRequest extends storeNewUserSubscribeRequestBase {
  program_invite_code?: string;
  receive_emails?: boolean;
  accept_terms: boolean;
  soccer_team_id?: string;
  match_maker?: boolean;
  gt_18_years?: boolean;
  document_number?: string;
}

export interface StoreNewMatchmakerUserRequest extends storeNewUserSubscribeRequest {
  locations: Address;
  conditionals: Conditionals;
}

type ResponseParamsUser = Login;

export type storeNewUserSubscribeResponse = ResponseWithParams<ResponseStatus, ResponseParamsUser>;
const storeNewUserWithDocument = async (
  data: storeNewUserSubscribeRequestBase | storeNewUserSubscribeRequest | StoreNewMatchmakerUserRequest
): Promise<AxiosResponse<storeNewUserSubscribeResponse>> =>
  await httpSubscribe.post<storeNewUserSubscribeResponse>(`/users`, data);

export default storeNewUserWithDocument;
