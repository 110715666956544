//TODO(Jeconias): Move it to outside API

import { createSlice, SliceCaseReducers, PayloadAction } from "@reduxjs/toolkit";
import api from "core/api";
import { ShowActionRequest, ShowActionResponse } from "core/api/actions/show";
import decoreThunk from "core/decorators/decorate";
import requestLoading from "core/decorators/requestLoading";
import asyncThunk from "core/decorators/toolkit";
import { LoadingStatus, OpportunityMaterialData } from "core/api/definitions";
import { AssignDonationResponse, AssignDonationRequest } from "core/api/donations/assign";
import { ListActionResponse } from "core/api/actions/list";
import { updateCoordinates } from "core/geolocation";

export const showAction = asyncThunk<ShowActionResponse, ShowActionRequest>("site/actions/show", api.actions.show, {
  condition: [
    (slug: string, state) => {
      return !(state.pagesNew.actions.actionBySlug && state.pagesNew.actions.actionBySlug[slug]);
    },
  ],
});

export const listAction = asyncThunk("site/actions/list", api.actions.list, {
  condition: [({ isForce }, state) => !state.pagesNew.actions.list?.response?.length || !!isForce],
});

export const donationAssign = asyncThunk<AssignDonationResponse, AssignDonationRequest>(
  "site/donations/assign",
  api.donations.assign
);

interface ActionsMap {
  [key: string]: ShowActionResponse;
}

interface IState {
  loading: LoadingStatus;
  actionBySlug: ActionsMap;
  list?: ListActionResponse & { currentPage?: number };
}

const actionsSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "siteActions",
  initialState: {
    loading: "idle",
    actionBySlug: {},
  },
  reducers: {
    updateActionMaterialsItems(state, action: PayloadAction<AssignDonationRequest>) {
      const actionPayload = state.actionBySlug && state.actionBySlug[action.payload.action_slug];

      if (!actionPayload) {
        return;
      }

      const materialDataFiltered = actionPayload?.opportunities_data?.find(
        (m) => m._id === action.payload.material_id
      ) as OpportunityMaterialData | undefined;
      if (!materialDataFiltered) return;

      materialDataFiltered.items = (materialDataFiltered?.items || []).map((i) =>
        action.payload.material_list_items_id && action.payload.material_list_items_id.includes(i._id)
          ? { ...i, status: "pending" }
          : i
      );

      actionPayload!.opportunities_data = actionPayload?.opportunities_data.map((opportunity) =>
        opportunity._id === action.payload.material_id ? { ...materialDataFiltered, level: "materials" } : opportunity
      );

      state.actionBySlug![action.payload.action_slug] = actionPayload;
    },
  },
  extraReducers: (builder) => {
    decoreThunk(builder, showAction, [
      requestLoading(),
      {
        fulfilled: (state, action) => {
          const params = action?.payload?.params;

          try {
            const { slug } = params?.action_data;

            state.actionBySlug![slug] = params;
          } catch (error) {
            console.log(error);
          }
        },
      },
    ]);
    decoreThunk(builder, listAction, [
      requestLoading(),
      {
        fulfilled: (state, action: PayloadAction<ListActionResponse>) => {
          if (
            (action as any).meta.arg?.pg <= 1 ||
            !(action as any).meta.arg?.pg ||
            action.payload?.response?.length === 0
          ) {
            state.list = action.payload;
            state.list.currentPage = 1;
          } else {
            state.list = {
              ...state.list,
              ...action.payload,
              response: [...(state.list?.response || []), ...(action.payload?.response || [])],
            };
            state.list.currentPage = (action as any).meta.arg?.pg;
          }
        },
      },
    ]);
    builder.addCase(updateCoordinates.toString(), (state) => {
      state.list = undefined;
    });
  },
});

export default actionsSlice;

export const { updateActionMaterialsItems } = actionsSlice.actions;
