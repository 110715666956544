import { createSlice, SliceCaseReducers, PayloadAction } from "@reduxjs/toolkit";
import { ListState, RequestableState } from "core/api/definitions";
import decoreThunk from "core/decorators/decorate";

import { AdminOverviewResponse } from "core/api/admin/actions/overview";
import { getAdminOverview } from "./action";
import requestLoading from "core/decorators/requestLoading";
import { logout } from "core/auth";

interface IState extends RequestableState {
  counters: {
    projects_registered: {
      label: string;
      value: number;
      percentage: number;
      description: string;
    };
    projects_available: {
      label: string;
      value: number;
      percentage: number;
      description: string;
    };
    projects_active: {
      label: string;
      value: number;
      percentage: number;
      description: string;
    };
    actions_registered: {
      label: string;
      value: number;
      percentage: number;
      description: string;
    };
    actions_available: {
      label: string;
      value: number;
      percentage: number;
      description: string;
    };
    actions_opened: {
      label: string;
      value: number;
      percentage: number;
      description: string;
    };
    subscribes_registered: {
      label: string;
      value: number;
      percentage: number;
      description: string;
    };
    subscribes_opened: {
      label: string;
      value: number;
      percentage: number;
      description: string;
    };
    active_volunteers: {
      label: string;
      value: number;
      percentage: number;
      description: string;
    };
    total_volunteer_hours: {
      label: string;
      value: number;
      percentage: number;
      description: string;
    };
  };
  last_counters_updated: string;
}

const panelAdminGetOverviewSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "panel/beta/admin/getAdminOverview",
  initialState: {
    loading: "idle",
    counters: {
      projects_registered: {
        label: "",
        value: 0,
        percentage: 0,
        description: "",
      },
      projects_available: {
        label: "",
        value: 0,
        percentage: 0,
        description: "",
      },
      projects_active: {
        label: "",
        value: 0,
        percentage: 0,
        description: "",
      },
      actions_registered: {
        label: "",
        value: 0,
        percentage: 0,
        description: "",
      },
      actions_available: {
        label: "",
        value: 0,
        percentage: 0,
        description: "",
      },
      actions_opened: {
        label: "",
        value: 0,
        percentage: 0,
        description: "",
      },
      subscribes_registered: {
        label: "",
        value: 0,
        percentage: 0,
        description: "",
      },
      subscribes_opened: {
        label: "",
        value: 0,
        percentage: 0,
        description: "",
      },
      active_volunteers: {
        label: "",
        value: 0,
        percentage: 0,
        description: "",
      },
      total_volunteer_hours: {
        label: "",
        value: 0,
        percentage: 0,
        description: "",
      },
    },
    last_counters_updated: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, getAdminOverview, [
      requestLoading(),
      {
        pending: (state: IState) => {
          state.loading = "loading";
        },

        fulfilled: (state: IState, action: PayloadAction<AdminOverviewResponse>) => {
          state.loading = "ok";
          if (action?.payload?.params) {
            state.counters = action.payload.params.counters;
            state.last_counters_updated = action.payload.params.last_counters_updated;
          }
        },
        rejected: (state: IState) => {
          state.loading = "error";
        },
      },
    ]);

    builder.addCase(logout.toString(), (state) => {
      state.loading = "idle";
      //state.opportunities_data = {};
    });
  },
});

export default panelAdminGetOverviewSlice;
