import { AxiosResponse } from "axios";
import http from "config/http";
import { ResponseWithParams, ResponseStatus } from "core/api/definitions";
import { SubscribeData } from "core/api/definitions";
import { ActionData } from "../definitions";
import { ProjectData } from "../definitions";

export interface ListByActionRequest {
  project: string;
  action: string;
}

export interface ListByActionResponseParams {
  action_data: ActionData;
  project_data: ProjectData;
  subscribe_data: SubscribeData[];
}

export type ListByActionResponse = ResponseWithParams<ResponseStatus, ListByActionResponseParams>;

export default async ({ project, action }: ListByActionRequest): Promise<AxiosResponse<ListByActionResponse>> => {
  return http.get<ListByActionResponse>(`/projects/${project}/actions/${action}/subscribe`);
};
