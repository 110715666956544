import React from "react";
import styled from "styled-components/macro";
import Spinner from "./Spinner";

type LoadingProps = React.HTMLAttributes<HTMLDivElement>;

const Loading: React.FC<LoadingProps> = (props) => (
  <Container {...props}>
    <Spinner />
  </Container>
);

export default Loading;

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
`;
