import React, { useCallback } from "react";
import BaseInput, { BaseInputProps, BaseInputValue } from "./BaseInput";
import { IconType } from "../../../../icons";

export interface InputProps extends Omit<BaseInputProps<BaseInputValue>, "onChange"> {
  icon?: IconType;
  onIconClick?: () => void;
  onChange?(value: string): void;
}

const Input = ({ value, type, ...props }: InputProps): JSX.Element => {
  const { onChange } = props;

  // For using only text input type
  const onChangeBaseInputCallback = useCallback(
    (e) => {
      if (onChange) onChange(e.target.value);
    },
    [onChange]
  );

  switch (type) {
    default:
      return <BaseInput type={type} value={value as BaseInputValue} {...props} onChange={onChangeBaseInputCallback} />;
  }
};

export default Input;
