import { createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import { GeneralCount, ListOverview } from "core/api/admin/actions/reports/projectResume";
import { RequestableState } from "core/api/definitions";

import decoreThunk from "core/decorators/decorate";
import requestLoading from "core/decorators/requestLoading";

import { GeneralCountList } from "./action";

interface IState extends RequestableState, GeneralCount {}

const panelAdminProjectResumeSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "panelAdminProjectResume",
  initialState: {
    loading: "idle",
    project_data: {
      _id: "",
      name: "",
      slug: "",
      updated_at: "",
    },
    project_status: {
      status: "",
      description: "",
    },
    statistics: {
      action_count: 0,
      volunteer_active_count: 0,
      volunteer_count: 0,
    },
  },

  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, GeneralCountList, [
      requestLoading(),
      {
        pending: (state: IState) => {
          state.loading = "loading";
        },
        fulfilled: (state: IState, action: PayloadAction<ListOverview>) => {
          state.loading = "ok";
          if (action.payload.params?.project_data) {
            state.project_data = action.payload.params?.project_data;
            state.project_status = action.payload.params?.project_status;
            state.statistics = action.payload.params?.statistics;
          }
        },
        rejected: (state: IState) => {
          state.loading = "error";
        },
      },
    ]);
  },
});

export default panelAdminProjectResumeSlice;
