import http from "config/http";
import { ResponseStatus, ResponseWithParams } from "core/api/definitions";

export interface PublishHomologationRequest {
  platformId: string;
}

export interface PublishHomologationResponseData {
  url_homologation: string;
}

export interface PublishHomologationResponse
  extends ResponseWithParams<ResponseStatus, PublishHomologationResponseData> {}

const publish = ({ platformId }: PublishHomologationRequest) =>
  http.get<PublishHomologationResponse>(`/platform-launcher/${platformId}/publish/homologation/create`);

export default publish;
