import http from "config/http";

interface MarkAsReadRequest {
  notification_id: string[];
}

interface MarkAsReadResponse {}

const markAsRead = async (data: MarkAsReadRequest) => http.put<MarkAsReadResponse>(`/notifications/markAsRead`, data);

export default markAsRead;
