import { AxiosResponse } from "axios";
import http from "config/http";

interface SignInGmailRequest {
  url: string;
}

interface SignInGmailResponse {
  url_token: string;
}

const signInGmailUrl = async ({ url }: SignInGmailRequest): Promise<AxiosResponse<SignInGmailResponse>> =>
  http.post<SignInGmailResponse>(`/auth/gmail/sign`, { url, action: "sign" });

export default signInGmailUrl;
