import { AxiosResponse } from "axios";
import http from "config/http";
import { ResponseStatus, ResponseWithParams } from "../definitions";

export interface ArchiveChat {
  chatID: string;
}

export type ArchiveChatReponse = ResponseWithParams<ResponseStatus, []>;

export default async ({ chatID }: ArchiveChat): Promise<AxiosResponse<ArchiveChatReponse>> =>
  http.get<ArchiveChatReponse>(`/chat/${chatID}/archive`);
