import React from "react";
import { useTranslation } from "react-i18next";
import { isDateEqual } from "utils/date";
import ActionsFooter from "wizard/v3/components/Layout/ActionsFooter";
import { WizardContainer } from "wizard/v3/components/Layout/Wizard";
import InputVacancyDateBase from "../common/InputVacancyDateBase";
import { PageEvents } from "../definitions/commonTypes";

import { subDays } from "date-fns";

interface VacancyDatePageProps extends PageEvents<Date[]> {
  dates: Date[];
  actionDateEnd?: Date | null;
  actionDateStart?: Date | null;
}

const dt = new Date();

const InputVacancyDatePage: React.FC<VacancyDatePageProps> = ({
  dates,
  actionDateEnd,
  actionDateStart,
  onBack,
  onContinue,
  onChange,
  error,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <WizardContainer>
        <InputVacancyDateBase
          helmet={t("wizard.pages.vacancyDate.title")}
          title={t("wizard.pages.vacancyDate.title")}
          description={t("wizard.pages.vacancyDate.description")}
          error={error}
          datePickerProps={{
            inline: true,
            showPopperArrow: false,
            minDate: actionDateStart ? new Date(Math.max(subDays(actionDateStart, 30).getTime(), dt.getTime())) : dt,
            maxDate: actionDateEnd,
            selected: dates && dates.length > 0 ? dates[0] : dt,
            highlightDates: dates,
            openToDate: actionDateStart ? actionDateStart : undefined,
            onSelect: (date) => {
              if (date && onChange) {
                const datesResult = dates.filter((d) => !isDateEqual(d, date));
                onChange(datesResult.length !== dates.length ? datesResult : [...dates, date]);
              }
            },
            onChange: () => ({}),
          }}
        />
      </WizardContainer>
      <ActionsFooter
        onBack={onBack}
        continueDisabled={dates.length === 0}
        onContinue={() => {
          if (onContinue) onContinue(dates);
        }}
      />
    </>
  );
};

export default InputVacancyDatePage;
