import { Origin } from "core/api/definitions";
import { UniqueDocumentType } from "core/api/users/getGeneralData";
import { useEffect, useState } from "react";
import { getCountryCallingCode as getCountryCallingCodePhoneNumber } from "react-phone-number-input";
import { useReduxSelector } from "./useReduxSelector";

const personalDocumentTypes: {
  [E: string]: string;
} = {
  pt: "nif",
  br: "cpf",
  cl: "rut",
};

const useCountry = () => {
  const [countryData, setCountryData] = useState<{
    country: string;
    iso3166_1: string;
    isInternationalPhone: boolean;
    personalDocumentType: UniqueDocumentType;
    juridicDocumentType: UniqueDocumentType;
  }>();

  const countryDataFromRedux = useReduxSelector(({ pagesNew: { origin } }) => {
    const code = (origin.params! as Origin)["country_code_iso_3166-1"] || "br";
    const isInternational = (origin.params! as Origin)["is_international"] || false;

    return {
      country: `+${(origin.params! as Origin).country_code}`,
      iso3166_1: code,
      isInternationalPhone: isInternational,
      personalDocumentType: personalDocumentTypes[code] as UniqueDocumentType,
      juridicDocumentType: (code === "pt" ? "nif" : "cnpj") as UniqueDocumentType,
    };
  });

  useEffect(() => {
    setCountryData(countryDataFromRedux);
  }, []); //Empty dependency because should it happen only once

  return {
    ...(countryData || countryDataFromRedux),
    getCountryCallingCode(country: string) {
      if (!country) return;
      const code = getCountryCallingCodePhoneNumber(country);
      setCountryData((prev) => (prev ? { ...prev, country: `+${code}`, iso3166_1: country } : prev));
    },
  };
};

export default useCountry;
