import { createSlice, SliceCaseReducers, PayloadAction } from "@reduxjs/toolkit";
import { ConditionalFunction, ConditionalInterestDescription } from "./api/definitions";
import { create } from "./wizard/create/subscribe";
import { CreateResponse } from "./api/subscribe/create";
import { LoadingStatus } from "./api/definitions";

interface IState {
  functions?: ConditionalFunction[];
  functionsLoading: LoadingStatus;
  interests?: ConditionalInterestDescription[];
  interestsLoading: LoadingStatus;
}

const supportDataSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "supportData",
  initialState: {
    functionsLoading: "idle",
    interestsLoading: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(create.pending.toString(), (state, action: PayloadAction<CreateResponse>) => {
      state.functionsLoading = "loading";
      state.interestsLoading = "loading";
    });
    builder.addCase(create.fulfilled.toString(), (state, action: PayloadAction<CreateResponse>) => {
      state.functions = action.payload.params?.functions.sort((a, b) => (a.title > b.title ? 1 : -1));
      state.functionsLoading = "ok";
      state.interests = action.payload.params?.interests.sort((a, b) => (a.description > b.description ? 1 : -1));
      state.interestsLoading = "ok";
    });
    builder.addCase(create.rejected.toString(), (state, action: PayloadAction<CreateResponse>) => {
      state.functionsLoading = "error";
      state.interestsLoading = "error";
    });
  },
});

export default supportDataSlice;

// export const {  } = supportDataSlice.actions;
