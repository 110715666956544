import React from "react";
import { Modal, ModalBody } from "components/Modal";
import styled from "styled-components/macro";
import Icon from "components/Icon/Icon";
import { rem } from "polished";
import { ThemeColor } from "core/api/definitions";
import { IconType } from "components/Icon/Icon";
import { ModalButtonClose } from "panel/components/Modal/BaseModal";

export interface BaseModalProps {
  color?: ThemeColor | string | undefined;
  icon?: IconType;
  isOpen?: boolean;
  onClose?: () => void;
  className?: string;
}

const BaseModal: React.FC<BaseModalProps> = ({ className, children, isOpen, onClose, icon, color }) => {
  const hasIcon = !!icon;
  return (
    <CustomModal className={className} isOpen={isOpen} toggle={onClose} size="sm">
      <CustomModalBody>
        <CustomActions>
          {hasIcon && icon && (
            <IconWrapper color={color}>
              <Icon type={icon} color="black" />
            </IconWrapper>
          )}
          <CustomToggler onClick={onClose} id="modalClose">
            <Icon type="multiply" />
          </CustomToggler>
        </CustomActions>
        {children}
      </CustomModalBody>
    </CustomModal>
  );
};

export default BaseModal;

const CustomModal = styled(Modal)`
  @media (min-width: 576px) {
    max-width: 500px;
  }
`;

const CustomToggler = styled(ModalButtonClose)`
  top: 40px;
  right: 40px;
`;

const CustomModalBody = styled(ModalBody)`
  padding: 40px 40px 48px;
`;

const IconWrapper = styled.div<{ color?: string }>`
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: ${({ color }) => color || "#21D170"};

  svg {
    height: 50%;
    fill: #ffffff;
    color: #ffffff;
  }
`;

const CustomActions = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.div<{ color?: ThemeColor | string }>`
  font-size: ${rem(36)};
  color: ${({ theme, color }) => (color && theme.colors[color as ThemeColor]) || color || theme.colors.primary};
  letter-spacing: ${rem(-0.37)};
  line-height: ${rem(50)};
  margin: 19px 0 10px;
`;

export const Message = styled.div<{ color?: ThemeColor | string }>`
  color: ${({ theme, color }) => (color && theme.colors[color as ThemeColor]) || color || theme.colors.support_text};
  font-size: ${rem(18)};
  line-height: ${rem(22)};
`;
