import { Origin } from "core/api/definitions";
import { useReduxSelector } from "hooks/useReduxSelector";
import useTranslationV3 from "hooks/useTranslationV3";
import React, { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { Direction } from "reactstrap/lib/Dropdown";
import styled, { css } from "styled-components/macro";
import { transition } from "utils/animation";
import Icon from "../Icons/Icon";

export type ShareButtonColorStyle = "default" | "white";

interface ShareComponentProps {
  isOpen: boolean;
  toggle: () => void;
  url?: string;
  colorStyle?: ShareButtonColorStyle;
  direction?: Direction;
}

const ShareComponent = ({ url: relativeUrl, direction, colorStyle, ...props }: ShareComponentProps): JSX.Element => {
  const { t } = useTranslationV3();
  const platformUrl = useReduxSelector((state) => (state.pagesNew.origin.params as Origin).platform_url);
  const location = useLocation();

  const url = `${platformUrl}${relativeUrl ?? location.pathname}`;

  const clipboard = useCallback(() => {
    if (!url) return;
    const tmpInput = document.createElement("input");
    tmpInput.setAttribute("style", "opacity: 0;");
    tmpInput.setAttribute("value", url);

    document.body.appendChild(tmpInput);
    tmpInput.select();

    document.execCommand("copy");
    document.body.removeChild(tmpInput);
  }, [url]);

  return (
    <ButtonDropdown {...props} direction={direction}>
      <ButtonShare colorStyle={colorStyle}>
        <Icon icon="share" color={colorStyle && colorStyle === "white" ? "neutralWhite" : "primary"} />
      </ButtonShare>
      <CustomDropdownMenu tag="ul">
        <DropdownItemHeader header>{t("plain:Compartilhe")}</DropdownItemHeader>
        <DropdownItemStyled tag="li">
          <WhatsappShareButton url={url}>
            <Icon icon="whatsapp" />
            WhatsApp
          </WhatsappShareButton>
        </DropdownItemStyled>
        <DropdownItemStyled tag="li">
          <FacebookShareButton url={url}>
            <Icon icon="facebook" />
            Facebook
          </FacebookShareButton>
        </DropdownItemStyled>
        <DropdownItemStyled tag="li">
          <TwitterShareButton url={url}>
            <Icon icon="twitter" />
            Twitter
          </TwitterShareButton>
        </DropdownItemStyled>
        <DropdownItemStyled tag="li">
          <LinkedinShareButton url={url}>
            <Icon icon="linkedin" />
            Linkedin
          </LinkedinShareButton>
        </DropdownItemStyled>
        <DropdownItemStyled tag="li">
          <SimpleButton onClick={clipboard}>
            <Icon icon="copy" />
            {t("actions.copyLink.label")}
          </SimpleButton>
        </DropdownItemStyled>
      </CustomDropdownMenu>
    </ButtonDropdown>
  );
};

export default ShareComponent;

const ButtonShare = styled(({ colorStyle, ...props }) => <DropdownToggle {...props} />)`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -${({ theme }) => theme.v3.spacing.xs};
  right: -${({ theme }) => theme.v3.spacing.xs};
  background: none;
  transition: ${transition.fast("background")};

  &:hover {
    background: ${({ theme, colorStyle }) =>
      colorStyle && colorStyle === "white" ? "transparent" : theme.v3.colors.neutralBackground};
  }

  &:focus {
    border-color: ${({ theme, colorStyle }) =>
      colorStyle && colorStyle === "white" ? "transparent" : theme.v3.colors.feedbackSupport};
    box-shadow: none;
    outline: none;
  }
`;

const CustomDropdownMenu = styled(DropdownMenu)`
  width: 200px;
  padding: ${({ theme }) => theme.v3.spacing.md};
  overflow: hidden;
  border-radius: 6px;
  ${({ theme }) => css`
    border: 1px solid ${theme.v3.colors.neutralLightest};
    box-shadow: ${theme.v3.boxShadow.l3};
  `}
`;

const DropdownItemHeader = styled(DropdownItem)`
  font-size: ${({ theme }) => theme.v3.fontSize.sm};
  color: ${({ theme }) => theme.v3.colors.neutralBase};
  font-weight: 500;
  padding: 0;
  margin-bottom: ${({ theme }) => theme.v3.spacing.md};
`;

const SimpleButton = styled.button`
  font-size: ${({ theme }) => theme.v3.fontSize.sm};
  color: ${({ theme }) => theme.v3.colors.neutralLight};
  padding: 0;
  border: 0;
  background: transparent;
`;

const DropdownItemStyled = styled(DropdownItem)`
  font-size: ${({ theme }) => theme.v3.fontSize.sm};
  color: ${({ theme }) => theme.v3.colors.neutralLight};
  padding: 0;
  transition: ${transition.fast("color")};
  border: 1px solid transparent;

  svg {
    margin-right: ${({ theme }) => theme.v3.spacing.sm};
  }

  &:focus,
  ${SimpleButton}:focus {
    border-color: ${({ theme }) => theme.v3.colors.feedbackSupport};
    outline: none;
  }

  &:hover,
  &:focus,
  ${SimpleButton}:hover, ${SimpleButton}:focus {
    background: none;
    color: ${({ theme }) => theme.v3.colors.neutralBase};
  }

  :not(:last-child) {
    margin-bottom: ${({ theme }) => theme.v3.spacing.sm};
  }

  & > button {
    width: 100%;
    text-align: left;
  }
`;
