import { createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import {
  ValidateMultipleVolunteerRequest,
  ValidateMultipleVolunteersResponse,
} from "core/api/subscribe/checkoutValidateMultiple";
import decoreThunk from "core/decorators/decorate";
import { postCheckoutMultiple } from "./action";

type IState = {
  loading: "idle" | "ok" | "loading";
  success: boolean;
  response: {
    code: number;
    message: string;
  };
  params: ValidateMultipleVolunteerRequest;
};

export const panelBetaCheckoutMultipleVolunteerSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "panelBetaCheckoutMultipleVolunteerSlice",
  initialState: {
    loading: "idle",
    success: false,
    response: {
      code: 0,
      message: "",
    },
    params: {
      status: "present",
      registration_id: [""],
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, postCheckoutMultiple, [
      {
        pending: (state: any) => {
          state.loading = "loading";
        },
        fulfilled: (state: IState, action: PayloadAction<ValidateMultipleVolunteersResponse>) => {
          state.loading = "ok";
          if (action?.payload.params) {
            state.params = action.payload.params;
          }
        },
        rejected: (state: any) => {
          state.loading = "error";
        },
      },
    ]);
  },
});
