import { createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import api from "core/api";
import { RetrieveNotificationsResponse } from "core/api/commonUser/notification/retrieveNotifications";
import { RequestableState } from "core/api/definitions";
import decoreThunk from "core/decorators/decorate";
import requestLoading from "core/decorators/requestLoading";
import asyncThunk from "core/decorators/toolkit";

export const retrieveNotifications = asyncThunk(
  "site/notifications/list",
  api.commonUser.notifications.retrieveNotifications
);

export const markAsRead = asyncThunk("site/notifications/update", api.commonUser.notifications.markAsRead);

type IState = {
  list?: RetrieveNotificationsResponse & { currentPage?: number };
} & RequestableState;

const notificationsSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "siteNotifications",
  initialState: {
    loading: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, retrieveNotifications, [
      requestLoading(),
      {
        fulfilled: (state, action: PayloadAction<RetrieveNotificationsResponse>) => {
          const pg = (action as any)?.meta?.arg?.pg;
          if (!pg || pg <= 1) {
            state.list = { ...action.payload, currentPage: 1 };
          } else {
            state.list = {
              ...action.payload,
              response: [...(state.list?.response || []), ...(action.payload?.response || [])],
              currentPage: pg,
            };
          }
        },
      },
    ]);
    decoreThunk(builder, markAsRead, [
      {
        fulfilled: (state, action) => {
          const notificationsID = (action as any)?.meta?.arg.notification_id as string[];
          if (notificationsID && Array.isArray(notificationsID) && state.list) {
            const nNotifications = state.list?.response?.map((n) => {
              const hasNotification = notificationsID?.find((o) => o === n._id);
              if (hasNotification) return { ...n, read: true };
              return n;
            });
            state.list!.response = nNotifications;
          }
        },
      },
    ]);
  },
});

export default notificationsSlice;
