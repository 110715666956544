import { yupResolver } from "@hookform/resolvers/yup";
import { Origin } from "core/api/definitions";
import { modalOpen } from "core/pages/actions";
import { useReduxSelector } from "hooks/useReduxSelector";
import useTranslationV3 from "hooks/useTranslationV3";
import { IconType } from "icons";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Trans } from "react-i18next";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { DEFAULT_PASSWORD, DEFAULT_USER, MODALS } from "utils/constants";
import yup from "utils/yup";
import Button from "v3/components/Button/Button";
import Text from "v3/components/common/Text";
import WrapperIcon from "v3/components/common/Wrappers";
import FormFeedback from "v3/components/Form/FormFeedback";
import Input from "v3/components/Form/Input/Input";
import InputController from "v3/components/Form/Input/InputController";
import InputIcon from "v3/components/Form/InputIcon";
import Label from "v3/components/Form/Label";

import { Base } from ".";
import Checkbox from "../../Checkbox/Checkbox";
import Icon from "../../Icons/Icon";
import Link from "../../Link/Link";
import { ModalBodyRegister } from "../common";
import { ModalFooter } from "../ModalBase";
import { CustomLink, Form, FormGroup, Info } from "./common";

interface SignInProps extends Base {}

const schemaSignIn = yup.object().shape({
  email: yup.string().required().email(),
  password: yup.string().required().min(5),
});

const SignInStep = ({ onSubmit, toggleStep, loading }: SignInProps) => {
  const { t } = useTranslationV3();
  const dispatch = useDispatch();

  const environment = useReduxSelector(({ pagesNew: { origin } }) => (origin.params as Origin)?.environment);

  const [passwordIcon, setPasswordIcon] = useState<IconType>("eyeClosed");

  const forgotPassword = useCallback(() => dispatch(modalOpen(MODALS.FORGOT_PASSWORD)), [dispatch]);
  const [apiLatLog, setApiLatLog] = useState(false);
  const [getByAddress, setGetByAddress] = useState({
    address: "",
    street: "",
    number: "",
    complement: "",
    district: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
    coordinates: [NaN, NaN],
  });

  const togglePasswordIconCallback = useCallback(
    () => setPasswordIcon((prev) => (prev === "eye" ? "eyeClosed" : "eye")),
    [setPasswordIcon]
  );

  const { handleSubmit, control, errors } = useForm({
    resolver: yupResolver(schemaSignIn),
    mode: "onTouched",
    defaultValues: {
      email: DEFAULT_USER,
      password: DEFAULT_PASSWORD,
      memorizePassword: false,
    },
  });

  useEffect(() => {
    if (apiLatLog == false) {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(function (position) {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          const apiKey = "AIzaSyBYB9ICpRm9n_0-vs7vrkx4p8Gf-GmrU-0";
          const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

          fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
              if (data.results.length > 0) {
                const addressComponents = data.results[0].address_components;

                let country = "";
                let state = "";
                let city = "";

                for (const component of addressComponents) {
                  if (component.types.includes("country")) {
                    country = component.short_name;
                  }
                  if (component.types.includes("administrative_area_level_1")) {
                    state = component.short_name;
                  }
                  if (component.types.includes("administrative_area_level_2")) {
                    city = component.short_name;
                  }
                }

                const addressconcat = `${city}, ${state}, ${country}`;

                setGetByAddress({
                  address: addressconcat,
                  street: "",
                  number: "",
                  complement: "",
                  district: "",
                  city: city,
                  state: state,
                  country: country,
                  zipcode: "",
                  coordinates: [Number(latitude), Number(longitude)],
                });

                setApiLatLog(true);
                console.log("conect api");
              } else {
                console.error("Nenhum dado preenchido");
              }
            })
            .catch((error) => {
              console.error("Nenhum endereço retornado", error);
            });
        });
      } else {
        console.error("não permitido a geolocalização");
      }
    }
  }, [apiLatLog]);

  return (
    <>
      <ModalBodyRegister>
        <Info>
          <Text size="lg" tag="h1" color="neutralBase">
            {t("plain:Acesse Sua Conta")}
          </Text>
          <Text size="sm" color="neutralLight">
            <Trans
              t={t}
              ns="plain"
              i18nKey="Insira seus dados para continuar ou crie sua conta"
              components={[<CustomLink tag="button" onClick={toggleStep} />]}
            />
          </Text>
        </Info>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <InputController
            tag={FormGroup}
            control={control}
            name="email"
            type="email"
            label={t("plain:Email")}
            placeholder={t("plain:Email Placeholder")}
            invalid={!!errors?.email}
            message={errors?.email?.message && t(errors?.email?.message)}
            defaultValue=""
          />
          <FormGroup>
            <Label invalid={!!errors?.password}>{t("plain:Senha")}</Label>
            <Controller
              control={control}
              name="password"
              defaultValue=""
              render={(props) => (
                <Input
                  type={passwordIcon === "eyeClosed" ? "password" : "text"}
                  placeholder={t("plain:Senha Placeholder")}
                  invalid={!!errors?.password}
                  {...props}
                />
              )}
            />
            <InputIcon
              tabIndex={-1}
              icon={passwordIcon}
              onClick={togglePasswordIconCallback}
              color="neutralLightness"
            />
            {errors?.password?.message && (
              <FormFeedback invalid={!!errors?.password}>{t(errors?.password?.message)}</FormFeedback>
            )}
          </FormGroup>
          <ActionsContainer>
            {(environment === "homologation" || environment === "development") && (
              <Controller
                control={control}
                name="memorizePassword"
                render={({ onChange, ...props }) => (
                  <Checkbox
                    {...props}
                    id="memorizePassword"
                    label={t("plain:Memorizar senha")}
                    onValueChange={onChange}
                  />
                )}
              />
            )}
            <Link tag="button" onClick={forgotPassword}>
              {t("actions.recoverPassword.label")}
            </Link>
          </ActionsContainer>
          <CustomButtonPrimary
            color="primary"
            size="md"
            disabled={loading === "loading" || !!Object.keys(errors).length}
          >
            {t("actions.enter.label")}
          </CustomButtonPrimary>
        </Form>
      </ModalBodyRegister>
      {environment === "development" && (
        <CustomModalFooter>
          <Text size="sm" color="neutralLight">
            {t("plain:Acesso rápido através de sua conta")}
          </Text>
          <SocialNetwork>
            <CustomWrapperIcon tag="div">
              <Icon icon="google" />
            </CustomWrapperIcon>
            <CustomWrapperIcon>
              <Icon icon="facebook" />
            </CustomWrapperIcon>
            <CustomWrapperIcon>
              <Icon icon="linkedin" />
            </CustomWrapperIcon>
          </SocialNetwork>
        </CustomModalFooter>
      )}
    </>
  );
};

export default SignInStep;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.v3.spacing.lg};
`;

const CustomButtonPrimary = styled(Button)`
  // margin-bottom: ${({ theme }) => theme.v3.spacing.xs};
`;

const CustomModalFooter = styled(ModalFooter)`
  justify-content: center;
  padding: ${({ theme }) => theme.v3.spacing.lg};
`;

const SocialNetwork = styled.div`
  display: flex;
  padding-top: ${({ theme }) => theme.v3.spacing.sm};
`;

const CustomWrapperIcon = styled(WrapperIcon)`
  margin: 0 ${({ theme }) => theme.v3.spacing.xs};
`;
