import http from "config/http";
import { ResponseWithParams, ResponseStatus } from "../definitions";
import { DonationStatusFilter, MaterialDataToCheckout } from "core/api/definitions";
import queryString from "query-string";
import { UserDataPanelDetails } from "core/api/definitions";
import { ActionDataToCheckout } from "../definitions";
import { AxiosResponse } from "axios";

export interface ListToCheckoutDetailRequest {
  status?: DonationStatusFilter;
  donationId: string;
}

export interface ListToCheckoutDetail {
  _id: string;
  material_data: MaterialDataToCheckout;
  user_data: UserDataPanelDetails;
  action_data: ActionDataToCheckout;
}

export type ListToCheckoutDetailResponse = ResponseWithParams<ResponseStatus, ListToCheckoutDetail>;

export default async ({
  donationId,
  ...props
}: ListToCheckoutDetailRequest): Promise<AxiosResponse<ListToCheckoutDetailResponse>> => {
  if (!props.status) props.status = "all";

  return http.get<ListToCheckoutDetailResponse>(
    `/donors/materials/listToCheckout/detail/${donationId}?` + queryString.stringify(props)
  );
};
