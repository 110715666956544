import { useReduxSelector } from "./useReduxSelector";
import { Origin } from "core/api/definitions";
import { formatLanguageToISO } from "utils/helpers";

const usePlatformLanguage = () => {
  return useReduxSelector(({ pagesNew: { origin } }) => {
    const o = origin.params! as Origin;
    return { language: formatLanguageToISO(o.language) || "pt-BR" };
  });
};

export default usePlatformLanguage;
