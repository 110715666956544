import useTranslationV3 from "hooks/useTranslationV3";
import React from "react";
import FeedbackSection from "./FeedbackSection";

const FeedbackList = () => {
  const { t } = useTranslationV3();
  return (
    <FeedbackSection
      title={t("plain:Desculpa, nenhum resultado encontrado")}
      text={t("plain:Tente pesquisar por um outro termo ou revise os filtros e tente novamente")}
    />
  );
};

export default FeedbackList;
