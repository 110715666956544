import { useEffect, useCallback, useState } from "react";
import { isXS, isSM, isMD, isXXS } from "utils/mediaQueriesV3";

const getScreenSizesArray = () => ({
  isXXS: isXXS(),
  isXS: isXS(),
  isSM: isSM(),
  isMD: isMD(),
});

export const useMediaQueriesV3 = () => {
  const size = window?.innerWidth || 0;
  const [mode, setMode] = useState({
    ...getScreenSizesArray(),
    isBetween: (start: number, end: number) => start <= size && end >= size,
    size,
  });

  const handleScreenResize = useCallback((e) => {
    const windowSize = e?.currentTarget?.innerWidth || 0;
    const isBetween = (start: number, end: number) => start <= windowSize && end >= windowSize;

    setMode({ ...getScreenSizesArray(), isBetween, size: windowSize });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleScreenResize);

    return () => {
      window.removeEventListener("resize", handleScreenResize);
    };
  }, [handleScreenResize]);

  return mode;
};
