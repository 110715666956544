import { AxiosResponse } from "axios";
import http from "config/http";
import { ResponseWithParams, Response } from "core/api/definitions";

export interface AdminOverview {
  counters: {
    projects_registered: {
      label: string;
      value: number;
      percentage: number;
      description: string;
    };
    projects_available: {
      label: string;
      value: number;
      percentage: number;
      description: string;
    };
    projects_active: {
      label: string;
      value: number;
      percentage: number;
      description: string;
    };
    actions_registered: {
      label: string;
      value: number;
      percentage: number;
      description: string;
    };
    actions_available: {
      label: string;
      value: number;
      percentage: number;
      description: string;
    };
    actions_opened: {
      label: string;
      value: number;
      percentage: number;
      description: string;
    };
    subscribes_registered: {
      label: string;
      value: number;
      percentage: number;
      description: string;
    };
    subscribes_opened: {
      label: string;
      value: number;
      percentage: number;
      description: string;
    };
    active_volunteers: {
      label: string;
      value: number;
      percentage: number;
      description: string;
    };
    total_volunteer_hours: {
      label: string;
      value: number;
      percentage: number;
      description: string;
    };
  };
  last_counters_updated: string;
}

export interface ResponseStatus {
  code: number;
  message: string;
}

export type AdminOverviewResponse = ResponseWithParams<ResponseStatus, AdminOverview>;

export default async (): Promise<AxiosResponse<AdminOverviewResponse>> =>
  http.get<AdminOverviewResponse>(`v3/admin/dashboard/overview`);
