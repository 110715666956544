import React, { FunctionComponent } from "react";
import styled from "styled-components/macro";
import SupportText from "components/common/SupportText";
import Spinner, { SpinnerSize } from "./Spinner";

interface LoadingProps {
  size?: SpinnerSize;
  text?: string;
  color?: string;
  className?: string;
}

const Loading: FunctionComponent<LoadingProps> = ({ className, text, size, color }) => {
  return (
    <Container className={className}>
      <Spinner size={size} color={color} />
      {text && <SupportText size="md">{text}</SupportText>}
    </Container>
  );
};

export default Loading;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;

  & > span {
    margin-top: 10px;
  }
`;
