import { combineReducers } from "@reduxjs/toolkit";

import homeSlice from "./home";
import postSlice from "./posts";

const blogReducer = combineReducers({
  home: homeSlice.reducer,
  post: postSlice.reducer,
});

export default blogReducer;
