import { combineReducers } from "@reduxjs/toolkit";
import panelBetaCustomizationSlice from "./customization";
import panelBetaListPlatformSlice from "./list";
import panelBetaBasicInformationSlice from "./basicInformations";
import panelBetaContentPlatformSlice from "./content";
import panelBetaAdministratorsSlice from "./administrators";
import panelAdminActionsOpportunitiesSlice from "./actionsOpportunities";
import panelAdminGetOverviewSlice from "./administrators/overview";
import panelAdminProjectResumeSlice from "./projectResume";
import panelAdminSwitchProjectsSlice from "./projects";
import { panelVolunteerResumeSlice, panelVolunteerShowAllAppointmentsSlice } from "./volunteers";
import panelBetaListVolunteersSlice from "./listVolunteers";
import {
  panelListProjectAppointmentsByMonthSlice,
  panelListProjectAppointmentsByDateSlice,
} from "./listProjectAppointments";
import panelBetaListVolunteersAppointmentsSlice from "./listVolunteersAppointments";
import panelBetaProjectValidateVolunteerSlice from "./projectValidateVolunteer";
import panelBetaListVolunteersAppointmentsModalSlice from "./listDetailVolunteerAppointmentsModal";
import panelBetaVolunteerSubscribeDetailsModalSlice from "./users/listDetailsRegistrationModal";
import panelBetaValidateSelectedVolunteersSlice from "./validateSelectedVolunteers";
import panelBetaListDetailProjectVolunteersAppointmentModal from "./listDetailsProjectVolunteerAppointmentModal";
import {
  panelAdminGetActionSlice,
  panelAdminGetAllActionsSlice,
  panelAdminSearchActionsSlice,
} from "./administrators/tags";
import panelBetaAdminProgramsSlice from "./administrators/programs";
import {
  panelBetaFilterProjectsContacts,
  panelBetaFilterVolunteersContacts,
  panelBetaListAllChatWithProjectsSlice,
  panelBetaListAllChatWithVolunteersSlice,
  panelBetaShowConversationSlice,
  panelBetaStartNewChat,
} from "./chat";
import { panelAdminListActionsValidation, panelAdminListProjectsValidation } from "./administrators/validation";
import {
  panelBetaSubPlatformListFiltersReportVolunteers,
  panelBetaSubPlatformListFiltersReportRanking,
  panelBetaSubPlatformListFiltersReportProjects,
} from "./administrators/subPlataform";

import { panelBetaCheckoutMultipleVolunteerSlice } from "./checkoutMultipleVolunteer";

const panelReducer = combineReducers({
  subPlatform: combineReducers({
    customization: panelBetaCustomizationSlice.reducer,
    list: panelBetaListPlatformSlice.reducer,
    basicInformation: panelBetaBasicInformationSlice.reducer,
    content: panelBetaContentPlatformSlice.reducer,
    administrators: panelBetaAdministratorsSlice.reducer,
    listVolunteers: panelBetaListVolunteersSlice.reducer,
  }),
  actionsOpportunities: panelAdminActionsOpportunitiesSlice.reducer,
  getAdminOverview: panelAdminGetOverviewSlice.reducer,
  projectResume: panelAdminProjectResumeSlice.reducer,
  volunteerResume: panelVolunteerResumeSlice.reducer,
  volunterShowAllAppointmentsByAction: panelVolunteerShowAllAppointmentsSlice.reducer,
  switchProjects: panelAdminSwitchProjectsSlice.reducer,
  projectAppointmentsByMonth: panelListProjectAppointmentsByMonthSlice.reducer,
  projectAppointmentsByDate: panelListProjectAppointmentsByDateSlice.reducer,
  listVolunteersAppointment: panelBetaListVolunteersAppointmentsSlice.reducer,
  projectValidateVolunteer: panelBetaProjectValidateVolunteerSlice.reducer,
  listVolunteersAppointmentModal: panelBetaListVolunteersAppointmentsModalSlice.reducer,
  volunteerSubscribeDetailsModal: panelBetaVolunteerSubscribeDetailsModalSlice.reducer,
  validateSelectedVolunteersSlice: panelBetaValidateSelectedVolunteersSlice.reducer,
  listDetailProjectVolunteersAppointmentModal: panelBetaListDetailProjectVolunteersAppointmentModal.reducer,
  adminGetAction: panelAdminGetActionSlice.reducer,
  adminGetAllActions: panelAdminGetAllActionsSlice.reducer,
  adminTagSearchActions: panelAdminSearchActionsSlice.reducer,
  listAdminPrograms: panelBetaAdminProgramsSlice.reducer,
  listAllChatWithProjects: panelBetaListAllChatWithProjectsSlice.reducer,
  listAllChatWithVolunteers: panelBetaListAllChatWithVolunteersSlice.reducer,
  showConversation: panelBetaShowConversationSlice.reducer,
  filterProjectsContacts: panelBetaFilterProjectsContacts.reducer,
  filterVolunteersContacts: panelBetaFilterVolunteersContacts.reducer,
  startNewChat: panelBetaStartNewChat.reducer,
  listAdminActionsValidate: panelAdminListActionsValidation.reducer,
  listAdminProjectsValidate: panelAdminListProjectsValidation.reducer,
  listFiltersReportVolunteers: panelBetaSubPlatformListFiltersReportVolunteers.reducer,
  listFiltersReportRanking: panelBetaSubPlatformListFiltersReportRanking.reducer,
  listFiltersReportProjects: panelBetaSubPlatformListFiltersReportProjects.reducer,
  checkoutValidateMultipleVolunteers: panelBetaCheckoutMultipleVolunteerSlice.reducer,
});
export default panelReducer;
