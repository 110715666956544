import { createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import api from "core/api";
import { RequestableState } from "core/api/definitions";
import { AboutResponse, AboutResponseWithParams } from "core/api/site/about";
import decoreThunk from "core/decorators/decorate";
import requestLoading from "core/decorators/requestLoading";
import asyncThunk from "core/decorators/toolkit";

export const getAboutStructure = asyncThunk("pages/about", api.site.about, {
  condition: [(_, state) => !state.pagesNew.about.response],
});

type IState = RequestableState & {
  response?: AboutResponse;
};

const aboutSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "pageAbout",
  initialState: {
    loading: "idle",
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, getAboutStructure, [
      requestLoading(),
      {
        fulfilled: (state, action: PayloadAction<AboutResponseWithParams>) => {
          state.response = action.payload?.params;
        },
      },
    ]);
  },
});

export default aboutSlice;
