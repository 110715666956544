import React, { useState, useEffect } from "react";
import styled from "styled-components";

interface Props {
  setModalStep: (prev: void) => void | undefined;
  timer: number;
}

const StopWatch = ({ setModalStep, timer }: Props) => {
  const [time, setTime] = useState<string>(`0${timer}:00`);

  useEffect(() => {
    const duration = 60 * timer;
    startTimer(duration);
  }, []);

  useEffect(() => {
    if (time === "00:00") {
      setModalStep();
    }
  }, [time]);

  const startTimer = (duration: number) => {
    let timer = duration,
      minutes,
      seconds;
    setInterval(() => {
      minutes = parseInt((timer / 60) as any, 10);
      seconds = parseInt((timer % 60) as any, 10);
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      setTime(`${minutes + ":" + seconds}`);
      if (--timer < 0) {
        timer = duration;
      }
    }, 1000);
  };

  return <StyledStopWatch>Seu código expira em : {time}</StyledStopWatch>;
};

export default StopWatch;

const StyledStopWatch = styled.div`
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #274264;
  margin: 20px 0;
`;
