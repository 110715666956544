import queryString from "query-string";
import http from "config/http";
import { ProjectStatus, ListResponse } from "core/api/definitions";
import { Certificate } from "crypto";
import { AxiosResponse } from "axios";

export interface CertificatesRequest {
  term?: string;
  status?: ProjectStatus;
  pg?: number;
  pp?: number;
}

export type CertificatesResponse = ListResponse<Certificate>;

export default async ({ term, status, pg, pp }: CertificatesRequest = {}): Promise<
  AxiosResponse<CertificatesResponse>
> => {
  // Compose filters
  const fs: any = {};
  if (status) fs.status = status;
  if (term) fs.term = term;
  if (pg) fs.pg = pg;
  if (pp) fs.pp = Math.max(5, pp); // 5 minimum by page.

  return http.get<CertificatesResponse>(`/actions/certificates?${queryString.stringify(fs)}`);
};
