import list from "./list";
import basicInformations from "./basicInformations";
import customization from "./customization";
import administrators from "./administrators";
import content from "./content";
import publish from "./publish";
import listVolunteers from "./listVolunteers";
import exportListVolunteers from "./exportListVolunteers";
import exportActionsAndOpportunities from "./exportActionsAndOpportunities";

export default {
  list,
  basicInformations,
  customization,
  administrators,
  content,
  publish,
  listVolunteers,
  exportListVolunteers,
  exportActionsAndOpportunities,
};
