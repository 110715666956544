import http from "config/http";
import { ListResponse, ResponseStatus, ResponseWithParams, Subscribe, RequestWithForce } from "core/api/definitions";
import { CauseData, ODSDataResponse } from "./detailsActions";
import queryString from "query-string";

interface ODSDetailsSubscribeRequest extends RequestWithForce {
  code: number;
  pg?: number;
}

export interface ODSDetailsSubscribeResponseData {
  subscribe_data: ListResponse<Subscribe>;
  causes_data: CauseData[];
  ods_data: ODSDataResponse;
}

export type ODSDetailsSubscribeResponse = ResponseWithParams<ResponseStatus, ODSDetailsSubscribeResponseData>;

const odsDetailsSubscribe = ({ code, pg }: ODSDetailsSubscribeRequest) => {
  const qs = queryString.stringify({ pg }, { arrayFormat: "bracket" });
  return http.get<ODSDetailsSubscribeResponse>(`/pages/ods/details/${code}/subscribe?${qs}`);
};

export default odsDetailsSubscribe;
