import React, { useContext } from "react";
import styled, { css, ThemeContext } from "styled-components/macro";
import Text from "v3/components/common/Text";
import { Link } from "react-router-dom";
import Circle from "components/Icon/Circle";
import mediaQueries, { mq } from "utils/mediaQueries";
import { useTranslation } from "react-i18next";
import { FocusStyle } from "components/common/style";
import * as History from "history";
import Icon from "v3/components/Icons/Icon";
import { IconType } from "icons";

export interface FooterProps {
  className?: string;
  centered?: boolean;
  column?: boolean;
}

const Footer: React.FunctionComponent<FooterProps> = ({ className, centered, column, children }) => (
  <Container className={className} centered={centered} column={column}>
    {children}
  </Container>
);

export default Footer;

const Container = styled.footer<{ centered?: boolean; column?: boolean }>`
  position: absolute;
  top: calc(100vh - 135px);
  width: 100%;
  left: 0;
  display: flex;
  justify-content: ${({ centered }) => (centered ? "center" : "space-between")};
  align-items: center;
  padding: 0 15px 30px;

  ${({ column }) =>
    column &&
    css`
      flex-direction: column-reverse;
      top: calc(100vh - 200px);
      min-height: 200px;
    `}

  ${mediaQueries.sm(css`
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    min-height: 120px;
    padding-bottom: 0;
  `)}

  ${mediaQueries.md(css`
    position: relative;
    top: 0;
    margin: 25px 0;
  `)}
`;

export interface ToBackProps {
  to?: History.LocationDescriptor<any> | ((location: History.Location<any>) => History.LocationDescriptor<any>);
  icon?: IconType | boolean;
  text?: string;
  isStyled?: boolean;
  onClick?(): void;
}

export const ToBack: React.FunctionComponent<ToBackProps> = ({ to, icon, text, isStyled, ...props }) => {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);
  const tText = text ? text : t("common.toBack");

  if (icon === undefined) {
    icon = "arrowLeft";
  }

  return (
    <BaseToBack to={to as any} {...props} title={tText} styled={isStyled}>
      {!!icon && (
        <Circle backgroundColor={theme.v3.colors.neutralBackground}>
          <Icon id="footerModernArrowIcon" color="neutralBase" icon={icon as IconType} />
        </Circle>
      )}
      <Text tag="span" size="sm">
        {tText}
      </Text>
    </BaseToBack>
  );
};

const BaseToBack = styled(Link)<{ styled?: boolean }>`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  transition: 0.4s;
  border: 1px solid transparent;
  border-radius: 3px;
  cursor: pointer;
  color: ${({ theme }) => theme.v3.colors.neutralBase};
  min-width: 130px;
  @media (max-width: 574px) {
    width: 100%;
    justify-content: center;
    color: white;
    white-space: nowrap;
    font-weight: 500;
  }
  ${({ styled }) =>
    styled &&
    css`
      border: solid 1px #495eec;
      height: 47.79px;
      padding: 7px 32px 7px 32px;
      border-radius: 4px;
      color: #495eec;
    `}

  svg {
    width: 15px;
  }

  :hover {
    ${({ theme }) => css`
      &,
      span {
        color: ${theme.v3.colors.neutralLightness};
        border-color: ${theme.v3.colors.neutralLightness};
      }

      .svg-fill {
        fill: ${theme.v3.colors.neutralLightness};
      }
    `};
  }
  :focus {
    ${FocusStyle()};
  }

  /* ${mq.xsDown} {
    margin-right: auto;
    padding-left: 0;
  } */
`;
