import React, { useEffect } from "react";
import { Modal as BSModal, ModalProps as BSModalProps } from "reactstrap";
import styled from "styled-components/macro";

interface ModalProps extends BSModalProps {}

const Modal: React.FunctionComponent<ModalProps> = ({ ...props }) => {
  useEffect(() => {
    const body = document.body;
    if (props.isOpen && !body.classList.contains("modal-open")) {
      body.classList.add("modal-open");
      body.style.paddingRight = "15px";
    }
    return () => {
      const body = document.body;
      if (props.isOpen && body.classList.contains("modal-open")) {
        body.classList.remove("modal-open");
        body.style.paddingRight = "";
      }
    };
  }, [props.isOpen]);

  return <ModalStyled {...props} />;
};

const ModalStyled = styled(BSModal)`
  .modal-content {
    border: 0;
    border-radius: 8px;
  }
`;

export default Modal;
