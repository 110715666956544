import getHomeStructure from "./getHomeStructure";
import contact from "./contact";
import about from "./about";
import ods from "./ods";

export default {
  getHomeStructure,
  contact,
  about,
  ods,
};
