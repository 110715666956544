import { Footer } from "components/Errors/Body";
import Loading from "components/Loading/Loading";
import { Origin } from "core/api/definitions";
import { useReduxSelector } from "hooks/useReduxSelector";
import { TFunction } from "i18next";
import ModalConfirmation from "panel/components/Modal/ModalConfirmation";
import React, { useCallback, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components/macro";

interface ContinueRegistrationPageProps {
  title?: string;
  message: string | JSX.Element;
  rule: (() => boolean) | boolean;
  onContinue: (() => void) | string;
  onUnregister(): void;
  loading?: boolean;
}

export const continuePageMessage = (step: string, t: TFunction): JSX.Element => (
  <Trans
    t={t}
    i18nKey="wizard.stepsWizard.continueRegistration.message"
    values={{ step: t(`wizard.stepsWizard.continueRegistration.steps.${step}`) }}
    components={[<b />]}
  />
);

const ContinueRegistrationPage = ({
  title,
  message,
  rule,
  onContinue,
  onUnregister,
  loading,
}: ContinueRegistrationPageProps): JSX.Element => {
  const { t } = useTranslation();
  const origin = useReduxSelector((state) => state.pagesNew.origin.params);
  const history = useHistory();
  const { state: routeState } = useLocation();

  const ruleIsTrue = typeof rule === "function" ? rule() : rule;

  const onContinueCallback = useCallback(() => {
    if (typeof onContinue === "function") onContinue();
    if (typeof onContinue === "string") history.replace(onContinue, routeState);
  }, [history, onContinue, routeState]);

  useEffect(() => {
    if (!ruleIsTrue && !loading) onContinueCallback();
  }, [onContinueCallback, loading, ruleIsTrue]);

  return (
    <Container>
      {loading ? (
        <Loading />
      ) : (
        <ModalConfirmation
          isOpen={ruleIsTrue}
          title={title || t("wizard.pages.continueRegistration.modal.title")}
          message={message}
          onCancel={onUnregister}
          onConfirm={() => {
            if (typeof onContinue === "function") onContinue();
            if (typeof onContinue === "string") history.replace(onContinue, routeState);
          }}
          buttonConfirm={t("common.continue")}
          buttonCancel={t("wizard.pages.continueRegistration.modal.actions.restart")}
          color="secondary"
        />
      )}
      <Footer>
        <img src={(origin as Origin)?.logo || ""} alt={(origin as Origin)?.title || ""} />
      </Footer>
    </Container>
  );
};

export default ContinueRegistrationPage;

const Container = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 100;
`;
