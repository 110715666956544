const ORIGIN_REQUESTED = "origin/REQUESTED";
const ORIGIN_RECEIVED = "origin/RECEIVED";
const ORIGIN_FAILED = "origin/FAILED";

const HOME_REQUESTED = "home/REQUESTED";
const HOME_RECEIVED = "home/RECEIVED";
const HOME_FAILED = "home/FAILED";

const MODAL_OPEN = "modal/OPEN";
const MODAL_CLOSE = "modal/CLOSE";

const ABOUT_REQUESTED = "about/REQUESTED";
const ABOUT_RECEIVED = "about/RECEIVED";
const ABOUT_FAILED = "about/FAILED";

const ODS_DETAILS_REQUESTED = "odsDetails/REQUESTED";
const ODS_DETAILS_RECEIVED = "odsDetails/RECEIVED";
const ODS_DETAILS_FAILED = "odsDetails/FAILED";

const ODS_MAIN_REQUESTED = "odsMain/REQUESTED";
const ODS_MAIN_RECEIVED = "odsMain/RECEIVED";
const ODS_MAIN_FAILED = "odsMain/FAILED";

const ACTION_REQUESTED = "action/REQUESTED";
const ACTION_RECEIVED = "action/RECEIVEED";
const ACTION_FAILED = "action/FAILED";

const USER_REQUESTED = "user/page/REQUESTED";
const USER_RECEIVED = "user/page/RECEIVED";
const USER_FAILED = "user/page/FAILED";

const PROGRAM_REQUESTED = "progam/page/REQUESTED";
const PROGRAM_RECEIVED = "program/page/RECEIVED";
const PROGRAM_FAILED = "program/page/FAILED";

const LIST_PROJECTS_REQUESTED = "projetos/listagem/page/REQUESTED";
const LIST_PROJECTS_RECEIVED = "projetos/listagem/page/RECEIVED";
const LIST_PROJECTS_FAILED = "projetos/listagem/page/FAILED";

const GDO_REQUESTED = "admin/dashboard/overview/REQUESTED";
const GDO_RECEIVED = "admin/dashboard/overview/RECEIVED";
const GDO_FAILED = "admin/dashboard/overview/FAILED";

const LOAD_MORE_SUBSCRIBE_PROGRAMS_REQUESTED = "carregarMais/subscribe/program/REQUESTED";
const LOAD_MORE_SUBSCRIBE_PROGRAMS_RECEIVED = "carregarMais/subscribe/program/RECEIVED";
const LOAD_MORE_SUBSCRIBE_PROGRAMS_FAILED = "carregarMais/subscribe/program/FAILED";

const LOAD_MORE_ACTION_PROGRAMS_REQUESTED = "carregarMais/action/program/REQUESTED";
const LOAD_MORE_ACTION_PROGRAMS_RECEIVED = "carregarMais/action/program/RECEIVED";
const LOAD_MORE_ACTION_PROGRAMS_FAILED = "carregarMais/action/program/FAILED";

const TAG_REQUESTED = "tag/REQUESTED";
const TAG_RECEIVED = "tag/RECEIVED";
const TAG_FAILED = "tag/FAILED";

const GAT_REQUESTED = "list/actions/tagged/REQUESTED";
const GAT_RECEIVED = "list/actions/tagged/RECEIVED";
const GAT_FAILED = "list/actions/tagged/FAILED";

const SAT_REQUESTED = "search/actions/term/REQUESTED";
const SAT_RECEIVED = "search/actions/term/RECEIVED";
const SAT_FAILED = "search/actions/term/FAILED";

const SAT_MORE_REQUESTED = "searchMore/actions/term/REQUESTED";
const SAT_MORE_RECEIVED = "searchMore/actions/term/RECEIVED";
const SAT_MORE_FAILED = "searchMore/actions/term/FAILED";

const LMA_REQUESTED = "loadMore/actions/REQUESTED";
const LMA_RECEIVED = "loadMore/actions/RECEIVED";
const LMA_FAILED = "loadMore/actions/FAILED";

const RAT_REQUESTED = "delete/action/tag/REQUESTED";
const RAT_RECEIVED = "delete/action/tag/RECEIVED";
const RAT_FAILED = "delete/action/tag/FAILED";

const ADDAT_REQUESTED = "add/action/tag/REQUESTED";
const ADDAT_RECEIVED = "add/action/tag/RECEIVED";
const ADDAT_FAILED = "add/action/tag/FAILED";

const GRT_REQUESTED = "generate/report/tag/REQUESTED";
const GRT_RECEIVED = "generate/report/tag/RECEIVED";
const GRT_FAILED = "generate/report/tag/FAILED";

export default {
  HOME_REQUESTED,
  HOME_RECEIVED,
  HOME_FAILED,

  ORIGIN_REQUESTED,
  ORIGIN_RECEIVED,
  ORIGIN_FAILED,

  MODAL_OPEN,
  MODAL_CLOSE,

  ABOUT_RECEIVED,
  ABOUT_REQUESTED,
  ABOUT_FAILED,

  ODS_DETAILS_REQUESTED,
  ODS_DETAILS_RECEIVED,
  ODS_DETAILS_FAILED,

  ODS_MAIN_REQUESTED,
  ODS_MAIN_RECEIVED,
  ODS_MAIN_FAILED,

  ACTION_REQUESTED,
  ACTION_RECEIVED,
  ACTION_FAILED,

  USER_REQUESTED,
  USER_RECEIVED,
  USER_FAILED,

  PROGRAM_REQUESTED,
  PROGRAM_RECEIVED,
  PROGRAM_FAILED,

  LOAD_MORE_SUBSCRIBE_PROGRAMS_REQUESTED,
  LOAD_MORE_SUBSCRIBE_PROGRAMS_RECEIVED,
  LOAD_MORE_SUBSCRIBE_PROGRAMS_FAILED,

  LOAD_MORE_ACTION_PROGRAMS_REQUESTED,
  LOAD_MORE_ACTION_PROGRAMS_RECEIVED,
  LOAD_MORE_ACTION_PROGRAMS_FAILED,

  LIST_PROJECTS_REQUESTED,
  LIST_PROJECTS_RECEIVED,
  LIST_PROJECTS_FAILED,

  GDO_REQUESTED,
  GDO_RECEIVED,
  GDO_FAILED,

  TAG_REQUESTED,
  TAG_RECEIVED,
  TAG_FAILED,

  GAT_REQUESTED,
  GAT_RECEIVED,
  GAT_FAILED,

  SAT_REQUESTED,
  SAT_RECEIVED,
  SAT_FAILED,

  SAT_MORE_REQUESTED,
  SAT_MORE_RECEIVED,
  SAT_MORE_FAILED,

  LMA_REQUESTED,
  LMA_RECEIVED,
  LMA_FAILED,

  RAT_REQUESTED,
  RAT_RECEIVED,
  RAT_FAILED,

  ADDAT_REQUESTED,
  ADDAT_RECEIVED,
  ADDAT_FAILED,

  GRT_REQUESTED,
  GRT_RECEIVED,
  GRT_FAILED,
};
