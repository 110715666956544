import { AxiosResponse } from "axios";
import http from "config/http";
import { ResponseWithParams, ResponseStatus } from "core/api/definitions";
import { MaterialList } from "core/api/definitions";

export interface ShowMaterialsRequest {
  actionSlug: string;
  materialId: string;
}

export type ShowMaterialsResponseParams = MaterialList[];

export type ShowMaterialsResponse = ResponseWithParams<ResponseStatus, ShowMaterialsResponseParams>;

export default async ({
  actionSlug,
  materialId,
}: ShowMaterialsRequest): Promise<AxiosResponse<ShowMaterialsResponse>> => {
  return http.get<ShowMaterialsResponse>(`/actions/${actionSlug}/donors/materials/${materialId}`);
};
