import React, { useEffect, useState, FunctionComponent } from "react";
import { withTheme } from "styled-components/macro";
import http from "config/http";
import NProgress from "nprogress";
import { Fragment } from "react";
import { ThemeProps } from "config/modules/styled";

interface TopLoadBarProps extends ThemeProps {}

const TopLoadBar: FunctionComponent<TopLoadBarProps> = () => {
  const [requestCount, setRequestCount] = useState(0);

  // Sets up the http interceptor
  useEffect(() => {
    http.interceptors.request.use(
      (config) => {
        setRequestCount((oldRequestCount) => oldRequestCount + 1);
        return config;
      },
      (error) => {
        setRequestCount((oldRequestCount) => oldRequestCount - 1);
        return Promise.reject(error);
      }
    );

    http.interceptors.response.use(
      (response) => {
        setRequestCount((oldRequestCount) => oldRequestCount - 1);
        return response;
      },
      (error) => {
        setRequestCount((oldRequestCount) => oldRequestCount - 1);
        return Promise.reject(error);
      }
    );
  }, []);

  useEffect(() => {
    if (requestCount > 0) {
      NProgress.start();
    } else {
      NProgress.done();
    }
  }, [requestCount]);

  return <Fragment />;
};

export default withTheme(TopLoadBar);
