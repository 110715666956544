import http from "config/http";
import { Cause, Interest, Skill } from "pages/ListActions/DataOfFilters";
import { AddressWithCoordinates, ListResponse } from "../definitions";
import queryString from "qs";

export interface GetProgramOpportunitiesRequest {
  slug: string;
  cc?: Cause[];
  fu?: Skill[];
  ci?: Interest[];
  rm?: string;
  wk?: string;
  pg?: number;
  term?: string;
  filter_subscribes?: boolean;
  filter_materials?: boolean;
  address?: AddressWithCoordinates;
}

export type GetProgramOpportunitiesResponse = ListResponse<any>;

const getProgramOpportunities = async ({ slug, ...data }: GetProgramOpportunitiesRequest) => {
  const qs = queryString.stringify(data, { arrayFormat: "brackets", encode: false, addQueryPrefix: false });
  return http.get<GetProgramOpportunitiesResponse>(`/program/${slug}/getSubscribe?pp=9&${qs}`);
};

export default getProgramOpportunities;
