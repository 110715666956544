import React, { ElementType } from "react";
import classNames from "classnames";
import styled from "styled-components/macro";

export interface ContainerProps {
  [key: string]: any;
  tag?: string | ElementType;
  fluid?: boolean | string;
  className?: string;
}

const ContainerComp = React.forwardRef<HTMLDivElement, ContainerProps>(({ children, tag, fluid, ...props }, ref) => {
  const Component = (tag || "div") as ElementType;

  props.className = classNames(props.className, fluid ? "ctnr-fld" : "ctnr", "tb-nav-panel-container");

  return (
    <Component ref={ref} {...props}>
      {children}
    </Component>
  );
});

/**
 * Container wraps ContainerComp to enable `${Container}` to be included into styled-component rules.
 */
const Container = styled(ContainerComp)``;

export default Container;
