import {
  faHomeHeart,
  faBallPile,
  faTimes,
  faChevronLeft,
  faChevronDown,
  faLongArrowRight,
  faCalendar,
  faPeopleCarry,
  faSmile,
  faBoxFull,
  faCheck,
  faMapMarkerSmile,
  faLaptopHouse,
  faUserCircle,
  faPersonSign,
  faExclamationTriangle,
  faHeart,
  faCaretDown,
  faMinus,
} from "@fortawesome/pro-solid-svg-icons";

const FasIcons = [
  faHomeHeart,
  faBallPile,
  faTimes,
  faChevronLeft,
  faLongArrowRight,
  faChevronDown,
  faCalendar,
  faPeopleCarry,
  faSmile,
  faBoxFull,
  faCheck,
  faMapMarkerSmile,
  faLaptopHouse,
  faUserCircle,
  faPersonSign,
  faExclamationTriangle,
  faHeart,
  faCaretDown,
  faMinus,
];

export default FasIcons;
