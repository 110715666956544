import http from "config/http";
import { ListWithFilterResponse } from "core/api/definitions";
import queryString from "query-string";
import { isEmpty } from "lodash";
import { ActionDataShort } from "../definitions";
import { ProjectDataShort } from "../definitions";
import { RegistrationData } from "../definitions";
import { AxiosResponse } from "axios";

export interface CheckoutVolunteerListRequest {
  actionSlug?: string;
  pg?: number;
  pp?: number;
}

export type CheckoutVolunteerListResponseFilter = {
  action_data: ActionDataShort;
  project_data: ProjectDataShort;
}[];

export interface CheckoutVolunteer {
  _id: string;
  action_slug: string;
  action_title: string;
  registration_data: RegistrationData;
}

export type CheckoutVolunteerListResponse = ListWithFilterResponse<
  CheckoutVolunteer,
  CheckoutVolunteerListResponseFilter
>;

export default async ({
  actionSlug,
  ...props
}: CheckoutVolunteerListRequest): Promise<AxiosResponse<CheckoutVolunteerListResponse>> =>
  http.get<CheckoutVolunteerListResponse>(
    `/actions/${actionSlug || "all"}/checkout/volunteers/list` +
      (isEmpty(props) ? "" : "?" + queryString.stringify(props))
  );
