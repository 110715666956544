import http from "config/http";
import { Contact, ResponseStatus, ResponseWithParams } from "core/api/definitions";

export interface PlatformAdministratorsRemoveRequest {
  platformId: string;
  administrator_id: string;
}
export interface PlataformAdministratorsRemoveResponseData {}

export interface PlataformAdministratorsRemoveResponse
  extends ResponseWithParams<ResponseStatus, PlataformAdministratorsRemoveResponseData> {}

const remove = ({ platformId, administrator_id }: PlatformAdministratorsRemoveRequest) =>
  http.post<PlataformAdministratorsRemoveResponse>(`/platform-launcher/${platformId}/administrators/remove`, {
    administrator_id,
  });

export default remove;
