import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit";
import asyncThunk from "core/decorators/toolkit";
import api from "core/api";
import decoreThunk from "core/decorators/decorate";
import { LoadingStatus } from "core/api/definitions";
import { ListMaterialsResponse, ListMaterialsRequest } from "core/api/actions/donation/listMaterials";
import { MaterialList, MaterialItem } from "core/api/definitions";

type MaterialItemScreen = MaterialItem & {
  loading: LoadingStatus;
  editStatus?: "updated" | "deleted";
};

type MaterialListScreen = Omit<MaterialList, "items"> & {
  items: MaterialItemScreen[];
};

type List = MaterialListScreen[];

interface IState {
  materials: {
    loading: LoadingStatus;
    response?: List;
    entities: {
      [k: string]: {
        loading: LoadingStatus;
        data?: MaterialList;
      };
    };
    actionSlug?: string;
  };
}

export const storeNewListMaterial = asyncThunk(
  "wizard/create/donation/materials/storeNewList",
  api.actions.donation.storeNewListMaterial
);

export const showMaterials = asyncThunk("wizard/create/donation/materials/edit", api.actions.donation.showMaterials);

export const updateMaterial = asyncThunk(
  "wizard/create/donation/materials/update",
  api.actions.donation.updateMaterial
);

export const addItem = asyncThunk("wizard/create/donation/materials/addItem", api.actions.donation.addMaterialItem);

export const updateItem = asyncThunk(
  "wizard/create/donation/materials/updateItem",
  api.actions.donation.updateMaterialItem
);

export const removeItem = asyncThunk(
  "wizard/create/donation/materials/removeItem",
  api.actions.donation.removeMaterialItem
);

export const listMaterials = asyncThunk<ListMaterialsResponse, ListMaterialsRequest & { force?: boolean }>(
  "wizard/create/donation/materials/list",
  api.actions.donation.listMaterials,
  {
    condition: [
      (props, state) =>
        props.force ||
        state.wizard.create.donation.materials.loading !== "ok" ||
        state.wizard.create.donation.materials.actionSlug !== props.action,
    ],
  }
);

const donationSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "wizard/create/donation/materials",
  initialState: {
    materials: {
      loading: "idle",
      entities: {},
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, listMaterials, [
      {
        pending: (state, _) => {
          state.materials.loading = "loading";
        },
        fulfilled: (state, action) => {
          state.materials.loading = "ok";
          state.materials.response = action.payload.params;
          if (Array.isArray(action.payload.params)) {
            action.payload.params.forEach((m) => {
              state.materials.entities[m._id] = {
                loading: "ok",
                data: m,
              };
            });
          }
          state.materials.actionSlug = action.meta.arg.action;
        },
        rejected: (state, _) => {
          state.materials.loading = "error";
        },
      },
    ]);

    decoreThunk(builder, showMaterials, [
      {
        pending: (state, action) => {
          // Set the loading for the entity...
          if (!state.materials.entities[action.meta.arg.materialId]) {
            state.materials.entities[action.meta.arg.materialId] = {
              loading: "loading",
            };
          } else {
            state.materials.entities[action.meta.arg.materialId].loading = "loading";
          }
        },
        fulfilled: (state, action) => {
          state.materials.entities[action.meta.arg.materialId].loading = "ok";
          state.materials.entities[action.meta.arg.materialId].data = action.payload.params;
        },
        rejected: (state, action) => {
          state.materials.entities[action.meta.arg.materialId].loading = "error";
        },
      },
    ]);

    decoreThunk(builder, updateMaterial, [
      {
        fulfilled: (state, action) => {
          state.materials.response = state.materials.response?.map((material) => {
            if (material._id !== action.meta.arg.materialId) {
              return material;
            }
            return {
              ...material,
              title: action.meta.arg.title,
              delivery_date: action.meta.arg.delivery_date,
              delivery_way: action.meta.arg.delivery_way,
              address: action.meta.arg.address,
            };
          });
        },
      },
    ]);
  },
});

export const { updateCoverImage } = donationSlice.actions;

export default donationSlice;
