import routes from "routes";
import { ValidationFieldSet } from "core/api/definitions";

const rules = {
  name: "required|string|min:2|max:50",

  "conditionals.causes": "required|conditionals:causes",
  "conditionals.interests": "required|conditionals:interest",
  "conditionals.skills": "required|conditionals:skill",

  "locations.country": "required|string",
  "locations.state": "required|string",
  "locations.city": "required|string",
};

const createRoutes: ValidationFieldSet<typeof rules> = [
  {
    field: "name",
    route: routes.wizard.matchmaker.name,
  },
  {
    field: ["conditionals.skills"],
    route: routes.wizard.matchmaker.work,
  },
  {
    field: ["conditionals.interests"],
    route: routes.wizard.matchmaker.abillities,
  },
  {
    field: ["conditionals.causes"],
    route: routes.wizard.matchmaker.causes,
  },
  {
    field: ["locations.country", "locations.state", "locations.city"],
    route: routes.wizard.matchmaker.address,
  },
];

const userMatchMakerValidation = {
  rules: rules,
  routes: {
    create: createRoutes,
  },
};

export default userMatchMakerValidation;
