import http from "config/http";
import { ListWithFilterResponse } from "core/api/definitions";
import queryString from "query-string";
import { isEmpty } from "lodash";
import { ActionDataShort } from "../definitions";
import { SubscribeDataShort } from "../definitions";
import { ProjectDataShort } from "../definitions";
import { RegistrationData } from "../definitions";
import { AxiosResponse } from "axios";

type VolunteerStatus = "approved" | "rejected" | "pending";

export interface ListVolunteerRequest {
  actionSlug?: string;
  subscribeSlug?: string;
  status?: VolunteerStatus;
  pg?: number;
  pp?: number;
}

export type ListVolunteerResponseFilter = {
  action_data: ActionDataShort;
  subscribe_data: SubscribeDataShort[];
  project_data: ProjectDataShort;
}[];

export interface ValidationVolunteer {
  _id: string;
  action_data: ActionDataShort;
  registration_data: RegistrationData[];
}

export type ListVolunteerResponse = ListWithFilterResponse<ValidationVolunteer, ListVolunteerResponseFilter>;

export default async ({
  actionSlug,
  subscribeSlug,
  ...props
}: ListVolunteerRequest): Promise<AxiosResponse<ListVolunteerResponse>> => {
  return http.get<ListVolunteerResponse>(
    `/actions/${actionSlug || "all"}/subscribe/${subscribeSlug || "all"}/volunteers/list` +
      (isEmpty(props) ? "" : "?" + queryString.stringify(props))
  );
};
