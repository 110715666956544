import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit";
import asyncThunk from "core/decorators/toolkit";
import api from "core/api";
import { StoreUpdateActionResponse, StoreUpdateActionRequest } from "core/api/actions/storeUpdateAction";

interface IState {}

export const updateStoreAction = asyncThunk<StoreUpdateActionResponse, StoreUpdateActionRequest>(
  "wizard/update/action/updateStoreAction",
  api.actions.storeUpdateAction
);

const actionSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "wizard/update/action",
  initialState: {},
  reducers: {},
});

export default actionSlice;
