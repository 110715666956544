import axios from "axios";
import cookies from "core/cookies";
import { API_URL, API_BLOG_URL } from "utils/constants";
import routes from "routes";
import history from "utils/history";

let headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

const headersSubscribe = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "X-Is-Assign-Store": "true",
};

let http = axios.create({
  baseURL: API_URL,
  headers,
  proxy: false,
});

http.interceptors.request.use(
  (config) => {
    if (cookies.user.get()) {
      try {
        let { access, secure } = JSON.parse(cookies.user.getString());
        config.headers.common["Auth-Access-Token"] = access;
        config.headers.common["Auth-Secure-Token"] = secure;
      } catch (e) {
        console.error(e);
        cookies.user.remove();
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

http.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.request?.status === 401 && error?.response?.data?.response?.message === "session.undefined") {
      history.push({ pathname: routes.logout, search: "?open=signIn" });
    }
    return Promise.reject(error);
  }
);

export default http;

const httpBlog = axios.create({
  baseURL: API_BLOG_URL,
  headers,
});

export { httpBlog };

const httpSubscribe = axios.create({
  baseURL: API_URL,
  headers: headersSubscribe,
  proxy: false,
});

export { httpSubscribe };
