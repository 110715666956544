import React from "react";
import styled from "styled-components/macro";
import { CONTAINER_BASE } from "utils/constants";
import { Centered } from "../common/styles";

export const ContentContainer = styled.div`
  width: ${CONTAINER_BASE};
  margin: 0 auto;

  @media (max-width: ${parseInt(CONTAINER_BASE) + 60 /* margin */}px) {
    width: 100%;
  }
`;

export const TitleContainer: React.FC = ({ children, ...props }) => {
  return (
    <>
      <CustomCentered {...props}>{children}</CustomCentered>
    </>
  );
};

const CustomCentered = styled(Centered)`
  margin-bottom: ${({ theme }) => theme.v3.spacing.lg};
`;
