import HomeHandle from "components/HomeHandle";
import TopLoadBar from "components/Loading/TopLoadBar";
import ErrorCatcher from "pages/ErrorCatcher";
import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { Router, Switch } from "react-router-dom";
import ScrollToTop from "react-router-scroll-top";
import history from "utils/history";
import Loading from "./components/Loading/LoadingRequest";
import { store } from "./core";
import CTAValidateUser from "./pages/UserActivation/UserTopBarValidation";
import AlertManager from "./providers/AlertManager";
import AppProvider from "./providers/AppProvider";
import Robots from "components/Robots/Robots";

const App: React.FC = () => (
  <Suspense fallback={<Loading />}>
    <Provider store={store}>
      <AppProvider>
        <Robots />
        <TopLoadBar />
        <AlertManager />
        <Router history={history}>
          <CTAValidateUser />
          <ScrollToTop>
            <ErrorCatcher>
              <Switch>
                <HomeHandle />
              </Switch>
            </ErrorCatcher>
          </ScrollToTop>
        </Router>
      </AppProvider>
    </Provider>
  </Suspense>
);

if (window.Cypress) window.__store__ = store;

export default App;
