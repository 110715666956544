import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components/macro";
import { Modal, ModalBody } from "reactstrap";
import SelectTeam from "./steps/SelectTeam";
import Summary from "./steps/Summary";
import steps from "./consts";
import Member from "./steps/Member";
import FansLogin from "./steps/FansLogin";
import Register, { AgreeCheckbox } from "./steps/Register";
import { BaseFansContainer, Step, TitleStep, BaseButton } from "./commonStyles";
import { useTranslation } from "react-i18next";
import mediaQueries from "utils/mediaQueries";
import { connect } from "react-redux";
import FansLogged from "./steps/FansLogged";
import { getUserLogged } from "core/user/operations";
import { bindActionCreators } from "redux";
import cookies from "core/cookies";

const Fans = ({ isOpen, step, toggle, resetStepOnclose, onClose, getUserLogged }) => {
  const { t } = useTranslation("components");
  const isLogged = cookies.user.get();
  const [currentStep, setCurrentStep] = useState(step);
  const [teamSelectedID, setTeamSelectedID] = useState("");

  const nextStep = (step) => setCurrentStep(step);

  const runStep = (step) => setCurrentStep(step);

  const reset = () => {
    setCurrentStep(steps.SELECT_TEAM);
    setTeamSelectedID("");
  };

  const closeProxy = () => {
    if (resetStepOnclose) setCurrentStep(resetStepOnclose);
    if (onClose) onClose();
    reset();
  };

  useEffect(() => {
    if (step) setCurrentStep(step);
  }, [step]);

  const hasRegister = currentStep === steps.MEMBER && isLogged;

  return (
    <Container>
      <CustomModal isOpen={isOpen} toggle={() => toggle()} onClosed={closeProxy} centered>
        <ModalBody>
          <BaseFansContainer
            onClose={() => {
              toggle();
              getUserLogged(); // TODO(Jota): Instead of making a new call, we should update the state instead.
            }}
          >
            {currentStep === steps.SELECT_TEAM && (
              <SelectTeam
                nextStep={() => nextStep(steps.MEMBER)}
                currentTeam={teamSelectedID}
                selectTeam={(team) => setTeamSelectedID(team)}
              />
            )}
            {currentStep === steps.SUMMARY && <Summary />}
            {currentStep === steps.LOGIN && !isLogged && (
              <FansLogin runStep={() => runStep(steps.REGISTER)} closeModal={toggle} />
            )}
            {currentStep === steps.LOGIN && isLogged && <FansLogged teamID={teamSelectedID} />}
            {currentStep === steps.REGISTER && (
              <CustomRegister runStep={() => runStep(steps.LOGIN)} closeModal={toggle} teamID={teamSelectedID} />
            )}
            {currentStep === steps.MEMBER && (
              <Member>
                <Step>{t("modalQuizTeam.steps.fansMember.currentStep")}</Step>
                <CustomTitle>
                  {t(`modalQuizTeam.steps.fansMember.${hasRegister ? "isMember.title" : "title"}`)}
                </CustomTitle>
                <CustomButtom onClick={() => nextStep(steps.LOGIN)}>
                  {t(`modalQuizTeam.steps.fansMember.${hasRegister ? "isMember.actions.accept" : "buttonLogin"}`)}
                </CustomButtom>
                <CustomButtom
                  onClick={() => {
                    if (hasRegister) {
                      toggle();
                    } else {
                      nextStep(steps.REGISTER);
                    }
                  }}
                >
                  {t(`modalQuizTeam.steps.fansMember.${hasRegister ? "isMember.actions.cancel" : "buttonRegister"}`)}
                </CustomButtom>
              </Member>
            )}
          </BaseFansContainer>
        </ModalBody>
      </CustomModal>
    </Container>
  );
};

Fans.defaultProps = {
  /** When is false, the modal will continue in the last step when reopening.
   * You can set the step (SELECT_TEAM, SUMMARY...) when reopening.
   */
  resetStepOnclose: false,
  onClose: () => {},
  toggle: () => {},
};

Fans.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  step: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  /** Set step to jump or false for not reset */
  resetStepOnclose: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onClose: PropTypes.func,
};

const mapActionsToProps = (dispatch) => bindActionCreators({ getUserLogged }, dispatch);

export default connect(null, mapActionsToProps)(Fans);

const Container = styled.div`
  position: relative;
`;

const CustomModal = styled(Modal)`
  justify-content: center;

  ${mediaQueries.sm(css`
    max-width: auto;
  `)}

  .modal-content {
    max-width: 415px;
    border: 0;
    background-color: transparent;
  }
`;

const CustomButtom = styled(BaseButton)`
  margin: 20px auto;
`;

const CustomTitle = styled(TitleStep)`
  margin-bottom: 15px;
`;

const CustomRegister = styled(Register)`
  ${AgreeCheckbox} {
    label {
      color: #00a032;
    }
  }
`;
