import React from "react";
import Photo, { PhotoProps } from "../Photo/Photo";
import styled, { withTheme, css } from "styled-components/macro";
import { Theme } from "config/modules/styled";
import Icon, { IconType } from "components/Icon/IconFontAwesome";
import { Size } from "utils/types";

export type CardIconType = IconType;

interface CardIconProps extends PhotoProps {
  icon?: CardIconType;
  invertedColor?: boolean;
  theme: Theme;
}

const CardIcon = ({ invertedColor, icon, theme, size, ...props }: CardIconProps) => {
  return (
    <PhotoStyled invertedColor={invertedColor} size={size} {...props}>
      {icon && (
        <FontAwesomeIconStyled
          customSize={size || "lg"}
          icon={icon}
          color={invertedColor ? "#fff" : theme.v3.colors.secondary}
        />
      )}
    </PhotoStyled>
  );
};

export default withTheme(CardIcon);

const PhotoStyled = styled(Photo)<{ invertedColor?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ invertedColor, theme }) =>
    invertedColor &&
    css`
      background: ${theme.colors.secondary};
    `}
`;

const FontAwesomeIconStyled = styled(Icon)<{ customSize?: Size }>`
  height: auto !important;

  ${({ customSize }) =>
    customSize === "lg" &&
    css`
      width: 50px !important;
    `}

  ${({ customSize }) =>
    customSize === "md" &&
    css`
      width: 40px !important;
    `}
`;
