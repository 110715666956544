import { AxiosResponse } from "axios";
import http from "config/http";
import { Response, ResponseWithParams } from "core/api/definitions";

export interface GeneralCount {
  project_data: {
    _id: string;
    name: string;
    slug: string;
    updated_at: string;
  };
  project_status: {
    status: string;
    description: string;
  };
  statistics: {
    action_count: number;
    volunteer_active_count: number;
    volunteer_count: number;
  };
}

export type ListOverview = ResponseWithParams<Response, GeneralCount>;

const projectResume = async (project_slug: string): Promise<AxiosResponse<ListOverview>> =>
  http.post<ListOverview>(`/v3/projects/profile`, { project_slug: project_slug });

export default projectResume;
