import React, { useState, useCallback, Fragment, useEffect } from "react";
import { Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components/macro";
import { PageEvents, PhotoFile } from "wizard/v3/pages/definitions/commonTypes";
import SupportText from "components/common/SupportText";
import ActionsFooter from "wizard/v3/components/Layout/ActionsFooter";
import { handleRejectFile, croppedImg, FileErrorType } from "utils/helpers";

import { WizardContainer } from "wizard/v3/components/Layout/Wizard";
import { TitleContainer } from "wizard/v3/components/Layout/Content";
import { BaseTitle } from "components/common/BaseTitle";
import WizardTrans from "wizard/v3/components/WizardTrans/WizardTrans";
import { ValidationError } from "core/api/definitions";
import ImageCoverCropper, { CropperWrapper } from "components/common/ImageCoverCropper";
import { Helmet } from "react-helmet";
import { CROP_SIZE_COVER } from "utils/constants";
import { CropInfo } from "components/Cropper";

export interface SimpleImageCoverPageProps extends PageEvents<PhotoFile | undefined> {
  title: string;
  clue?: string;
  file?: PhotoFile;
  skippable?: boolean;
  continueLabel?: string;
  onCropComplete?: (cropInfo: CropInfo) => void;
}

const SimpleImageCoverPage = ({
  title,
  file,
  skippable,
  onChange,
  onContinue,
  onBack,
  error: genericError,
  loading,
  continueLabel,
  onCropComplete,
}: SimpleImageCoverPageProps): JSX.Element => {
  const { t } = useTranslation();
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<CropInfo>({ height: 0, width: 0, x: 0, y: 0 });
  const [error, setError] = useState<string>("");
  const [touched, setTouched] = useState(false);

  const validateImageCallback = (file: HTMLImageElement) => {
    if (file.naturalWidth < 790 || file.naturalHeight < 290) {
      setError(t("wizard.error.image.resolution", { width: 790, height: 290 }));
      return false;
    }
    return true;
  };

  const onRejectedFileCallback = (rejectedFiles: File[], errorType: FileErrorType) => {
    if (errorType === "none") setError("");
    handleRejectFile(rejectedFiles, (errorType) => {
      if (errorType === "size") setError(t("wizard.error.image.size"));
      if (errorType === "type") setError(t("wizard.error.image.type"));
    });
  };

  const onChangeCallback = useCallback(
    (data?: PhotoFile) => {
      setTouched(true);

      if (onChange) onChange(data);
    },
    [onChange]
  );

  const onCropCompleteCallback = useCallback(
    (cropInfo: CropInfo) => {
      if (onCropComplete) onCropComplete(cropInfo);

      setCroppedAreaPixels(cropInfo);
    },
    [onCropComplete]
  );

  const showCroppedImage = useCallback(async () => {
    if (!file?.source) {
      if (skippable) {
        if (onContinue) onContinue(file);
      }
      return;
    }

    if (!touched && onContinue) {
      onContinue(undefined);
      return;
    }

    try {
      const croppedImage = await croppedImg(file.source, croppedAreaPixels, "BASE64");
      if (croppedImage && onContinue && file) onContinue({ ...file, cropped: croppedImage });
    } catch (e) {
      setError(t("wizard.error.image.crop"));
    }
  }, [croppedAreaPixels, onContinue, file, t]);

  return (
    <Fragment>
      <WizardContainer>
        <TitleContainer>
          <Helmet title={title} />
          <BaseTitle size="md">{title}</BaseTitle>
        </TitleContainer>
        <Container file={file}>
          <ImageCoverCropper
            file={file}
            onChange={onChangeCallback}
            validateImage={validateImageCallback}
            onRejectedFile={onRejectedFileCallback}
            onCropComplete={onCropCompleteCallback}
            hideCropper={!touched}
          />
          {(genericError || error) && <WizardTrans>{error || (genericError as ValidationError)}</WizardTrans>}
        </Container>
        <Row className="justify-content-center">
          <SupportText size="sm">{t("wizard.common.infoImage")}</SupportText>
        </Row>
      </WizardContainer>
      <ActionsFooter
        continueDisabled={(!file?.cropped && !skippable) || loading}
        continueLabel={continueLabel}
        onContinue={showCroppedImage}
        onBack={onBack}
      />
    </Fragment>
  );
};

export default SimpleImageCoverPage;

export const Container: React.FC<{ file?: PhotoFile }> = (props) => {
  const container = React.createRef<HTMLDivElement>();
  const [height, setHeight] = useState(0);
  useEffect(() => {
    const listener = () => {
      if (!container.current) return;
      setHeight(container.current.offsetWidth / CROP_SIZE_COVER.ratio);
    };

    window.addEventListener("resize", listener);
    listener();
    return () => {
      window.removeEventListener("resize", listener);
    };
  }, [container]);
  return <ContainerStyled ref={container} {...props} height={height} />;
};

const ContainerStyled = styled.section<{ height: number; file?: PhotoFile }>`
  margin: 40px 0;
  height: ${({ height }) => height}px;
  border-radius: 6px;
  border: 1px solid #9facbd;
  background-color: #f9fafc;

  ${({ file }) => file && file.source && css``}

  ${CropperWrapper} {
    border-radius: 6px;
  }
`;
