import { createAction, createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import { LoginResponse } from "core/api/auth/login";
import { DashProfile, Login, RequestableState } from "core/api/definitions";
import decoreThunk from "core/decorators/decorate";
import requestLoading from "core/decorators/requestLoading";
import { acceptTermsAndAge } from "core/panel/user/settings/actions";
import { login } from "./actions";
import cookies from "core/cookies";

export const loginUpdate = createAction<Partial<Login>>("login/loginUpdate");

type IState = RequestableState & LoginResponse;

const loginSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "login",
  initialState: {
    success: false,
    loading: "idle",
  },
  reducers: {
    loginData(state, action: PayloadAction<Login>) {
      state.params = action.payload;
      state.loading = "ok";
      state.success = true;

      if (!state.params?.selected_dash_profile) {
        state.params!.selected_dash_profile =
          state.params!.dash_profile === "both" ? "volunteer" : state.params!.dash_profile;
      }
    },
    logout(state) {
      state.loading = "idle";
      state.success = false;
      state.params = undefined;
      state.response = undefined;
    },
    updateDashProfile: (state, { payload }: PayloadAction<DashProfile>) => {
      state.params!.dash_profile = payload;
    },
    changeDashProfile: (state, { payload }: PayloadAction<DashProfile>) => {
      state.params!.selected_dash_profile = payload;
    },
  },
  extraReducers: (builder) => {
    decoreThunk(builder, login, [
      requestLoading(),
      {
        fulfilled: (state, { payload }) => {
          state.success = true;
          state.params = payload.params;
          if (!state.params?.selected_dash_profile) {
            state.params!.selected_dash_profile =
              payload.params!.dash_profile === "both" ? "volunteer" : payload.params!.dash_profile;
          }
          state.response = undefined;
        },
      },
    ]);
    builder.addCase(acceptTermsAndAge.fulfilled.toString(), (state) => {
      if (state.params) {
        state.params!.needs_accept_terms = false;
        state.params!.needs_gt_18_years = false;
      }
    });
    builder.addCase(loginUpdate.toString(), (state, action: PayloadAction<Partial<Login>>) => {
      state.params = { ...(state?.params || {}), ...(action.payload as Login) };
    });
  },
});

export default loginSlice;

export const { loginData, logout, updateDashProfile, changeDashProfile } = loginSlice.actions;
