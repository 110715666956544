import React from "react";
import Icon from "./Icon";

interface IconOpportunityProps {
  icon?: "volunteer" | "material" | "collection";
}

const IconOpportunity = ({ icon }: IconOpportunityProps) => {
  return (
    <>
      <Icon icon={icon ?? "volunteer"} color={icon === "volunteer" ? "feedbackImportant" : "feedbackSupport"} />
    </>
  );
};
export const IconOpportunityV2 = ({ icon }: IconOpportunityProps) => {
  return (
    <>
      {icon === "volunteer" ? (
        <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0 32.0794C0 49.7974 14.3648 64.1589 32.0794 64.1589C49.7941 64.1589 64.1589 49.7974 64.1589 32.0794C64.1589 14.3614 49.7975 0 32.0794 0C14.3614 0 0 14.3614 0 32.0794Z"
            fill="white"
          />
          <path
            d="M2.2002 32.0803C2.2002 48.5842 15.5788 61.9629 32.0827 61.9629C48.5867 61.9629 61.9653 48.5842 61.9653 32.0803C61.9653 15.5764 48.5833 2.20117 32.0793 2.20117C15.5754 2.20117 2.2002 15.5798 2.2002 32.0803Z"
            fill="#5986c0"
          />
          <path
            d="M21.4473 22.3737C22.5866 35.1605 18.6621 47.3421 25.0692 51.2564C29.2895 53.9022 37.6044 53.1472 40.6413 47.6958C43.6782 42.2444 42.2601 40.3093 43.3891 37.5139C44.5182 34.7185 47.0551 30.2362 45.6404 29.1786C44.2257 28.121 42.4743 30.1478 40.8861 32.9228C39.298 35.6945 38.4852 36.5515 38.4852 36.5515L38.1043 16.742C38.1043 16.742 38.4546 14.5893 36.8834 14.4465C35.3123 14.3036 35.3191 16.4155 35.1763 19.3062C35.0368 22.2002 34.4349 30.5729 34.122 30.6681C33.8091 30.7634 33.8465 13.5623 33.8465 13.5623C33.8465 13.5623 34.0404 11.3994 32.5441 11.3926C31.0477 11.3892 30.9423 14.3342 30.9423 14.3342L29.5174 31.4605L28.9903 14.9668C28.9903 14.9668 29.1025 13.1066 27.647 13.212C26.1914 13.3174 26.1846 16.0788 26.1846 16.0788L25.0318 32.4977L24.3312 21.1902C24.3312 21.1902 24.4604 19.2382 22.675 19.5306C21.2569 19.6939 21.4508 22.3771 21.4508 22.3771L21.4473 22.3737Z"
            fill="white"
          />
          <path
            d="M27.4772 36.8984C25.4164 36.8984 23.75 38.5682 23.75 40.6291C23.75 41.1154 23.8452 41.5779 24.0153 42.003C25.0593 45.2473 29.7319 49.0596 31.1399 49.199C32.5478 49.0596 37.2204 45.2473 38.2645 42.003C38.4345 41.5779 38.5297 41.1154 38.5297 40.6291C38.5297 38.5648 36.86 36.8984 34.8025 36.8984C33.5272 36.8984 32.4016 37.5378 31.7282 38.5104C31.7282 38.5104 31.5003 38.8505 31.1399 38.8505C30.7794 38.8505 30.5515 38.5104 30.5515 38.5104C29.8782 37.5378 28.7525 36.8984 27.4772 36.8984Z"
            fill="#F13452"
          />
        </svg>
      ) : (
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_3487_11850)">
            <path
              d="M62.8074 31.0373C62.8074 48.1053 48.9695 61.9398 31.9048 61.9398C14.8401 61.9398 0.999023 48.1053 0.999023 31.0373C0.999023 13.9693 14.8369 0.134766 31.9048 0.134766C48.9728 0.134766 62.8074 13.9693 62.8074 31.0373Z"
              fill="white"
            />
            <path
              d="M52.0939 51.2256C63.2448 40.0747 63.2448 21.9956 52.0939 10.8447C40.943 -0.306214 22.8638 -0.306211 11.7129 10.8447C0.562045 21.9956 0.562044 40.0747 11.7129 51.2256C22.8638 62.3765 40.943 62.3765 52.0939 51.2256Z"
              fill="#274264"
            />
            <path
              d="M21.4113 39.2075C19.0984 37.1436 18.4399 35.3713 17.3916 35.8627C15.6488 36.6817 19.7405 41.0224 20.5235 41.5204C21.3064 42.0183 22.1615 43.5843 22.1615 43.5843C21.4146 43.3353 20.7364 43.3353 16.4678 42.3394C12.1992 41.3435 9.0673 37.4287 9.20817 36.0757C9.34904 34.7227 8.95919 33.4418 7.85518 33.7628C6.75117 34.0839 7.00014 39.1355 9.59801 42.6572C12.1959 46.1789 14.8265 47.8726 18.2663 48.4688C21.1426 48.9667 22.797 50.3427 23.3998 50.9487C23.8322 51.3812 22.9903 55.617 22.476 57.9954C24.7102 58.7751 27.0689 59.2861 29.5194 59.4893V51.7776C29.5194 51.7776 32.1173 49.8807 29.9125 46.1101C27.7078 42.3394 23.7209 41.2714 21.408 39.2075H21.4113Z"
              fill="white"
            />
            <path
              d="M42.3974 39.2075C44.7103 37.1436 45.3688 35.3713 46.4171 35.8627C48.1599 36.6817 44.0682 41.0224 43.2852 41.5204C42.5023 42.0183 41.6472 43.5843 41.6472 43.5843C42.3942 43.3353 43.069 43.3353 47.3409 42.3394C51.6096 41.3435 54.7414 37.4287 54.6005 36.0757C54.4597 34.7227 54.8495 33.4418 55.9535 33.7628C57.0575 34.0839 56.8086 39.1355 54.2107 42.6572C51.6128 46.1789 48.9822 47.8726 45.5424 48.4688C42.6661 48.9667 41.0117 50.3427 40.4089 50.9487C39.9765 51.3812 40.8184 55.617 41.3327 57.9954C39.0985 58.7751 36.7398 59.2861 34.2893 59.4893V51.7776C34.2893 51.7776 31.6915 49.8807 33.8962 46.1101C36.101 42.3394 40.0878 41.2714 42.4007 39.2075H42.3974Z"
              fill="white"
            />
            <path
              d="M40.0098 12.916C37.1859 12.916 34.6962 14.3345 33.2056 16.4901C33.2056 16.4901 32.7011 17.2436 31.905 17.2436C31.1089 17.2436 30.6044 16.4901 30.6044 16.4901C29.1138 14.3345 26.6241 12.916 23.7969 12.916C19.2367 12.916 15.5381 16.6113 15.5381 21.1781C15.5381 22.2526 15.751 23.2813 16.1245 24.2248C18.4373 31.4058 28.7862 39.8546 31.9017 40.1625C35.0172 39.8579 45.3661 31.4058 47.679 24.2248C48.0557 23.2813 48.2654 22.2559 48.2654 21.1781C48.2654 16.6113 44.57 12.916 40.0066 12.916H40.0098Z"
              fill="#F13452"
            />
          </g>
          <defs>
            <clipPath id="clip0_3487_11850">
              <rect width="64" height="64" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )}
    </>
  );
};
export default IconOpportunity;
