import http from "config/http";
import queryString from "query-string";
import { ListResponse, OpportunitiesData, RequestWithForce } from "../definitions";

export type ListOpportunityMatchmakerResponse = Omit<ListResponse<unknown>, "response"> & {
  response: OpportunitiesData[];
};

interface ListOpportunityMatchmakerRequest extends RequestWithForce {
  pg?: number;
}

const matchmaker = (data: ListOpportunityMatchmakerRequest) => {
  const qs = queryString.stringify(data, { arrayFormat: "bracket" });
  return http.get<ListOpportunityMatchmakerResponse>(`/search/searchMatchmaker${qs ? `?${qs}` : ""}`);
};

export default matchmaker;
