import http from "config/http";
import { ResponseStatus, ResponseWithParams } from "../../definitions";
import { Login } from "core/api/definitions";
import { AxiosResponse } from "axios";

export interface AcceptTermsAndAgeRequest {
  accept_terms?: boolean;
  gt_18_years?: boolean;
}

export type AcceptTermsAndAgeResponse = ResponseWithParams<ResponseStatus, Login>;

const acceptTermsAndAge = async (data: AcceptTermsAndAgeRequest): Promise<AxiosResponse<AcceptTermsAndAgeResponse>> =>
  http.post<AcceptTermsAndAgeResponse>(`/users/account/acceptTerms`, data);

export default acceptTermsAndAge;
