import api from "core/api";
import asyncThunk from "core/decorators/toolkit";

export const resumeProfile = asyncThunk("panel/beta/volunteerProfile", api.users.resumeProfile);

export const cancelAppointments = asyncThunk("panel/beta/volunteer", api.volunteers.cancelAppointment);

export const showAllUserAppointmentsByAction = asyncThunk(
  "panel/beta/showAllUserAppointmentsByAction",
  api.volunteers.showAllUserAppointmentsByAction
);
