import { format } from "utils/date";
import { ptBR } from "date-fns/locale";
import Loading from "components/Loading/Loading";
import { Address, DeliveryWay, LoadingStatus, ODSDataRef } from "core/api/definitions";
import { LocationDescriptor } from "history";
import useTranslationV3 from "hooks/useTranslationV3";
import { IconType } from "icons";
import { rem } from "polished";
import React, { useCallback, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import styled, { css } from "styled-components/macro";
import { addressToFullString } from "utils/helpers";
import { useMediaQueriesV3 } from "utils/hooks/useMediaQueriesV3";
import mqV3 from "utils/mediaQueriesV3";
import Share from "v3/components/Share/Share";
import Text from "../common/Text";
import IconOpportunity, { IconOpportunityV2 } from "../Icons/IconOpportunity";

import Link from "../Link/Link";
import ODSIcon from "../ODSIcon/ODSIcon";
import ModalBaseContainer, { ModalBody, ModalHeader } from "./ModalBase";
import { capitalize } from "lodash";

type SubscriptionType = Extract<IconType, "volunteer" | "material">;
interface ModalSubscriptionBaseProps {
  loading: LoadingStatus;
  title: string;
  shortTitle?: string;
  subTitle: string;
  subscriptionType: SubscriptionType;

  isOpen: boolean;

  valid?: boolean;
  working?: boolean;
  onAction?: () => void;
}

const ModalSubscriptionBase: React.FC<ModalSubscriptionBaseProps> = ({
  loading,

  isOpen,

  children,
}): JSX.Element => {
  /* const [scrolled, setScrolled] = useState(false);

  const handleOnScroll = useCallback((e: any) => {
    const currentScrollTop = e?.target?.scrollTop ?? 0;
    if (currentScrollTop >= 25) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  }, []); */

  return (
    <CustomModalBaseContainer isOpen={isOpen}>
      {loading === "loading" && <CustomLoading />}
      {loading === "ok" && (
        <>
          <>{children}</>
        </>
      )}
    </CustomModalBaseContainer>
  );
};

export default ModalSubscriptionBase;

interface ModalHeaderOpportunityProps {
  toggle: () => void;

  title: string;
  shortTitle?: string;
  subTitle: string;
  subscriptionType: SubscriptionType;
}

export const ModalHeaderOpportunity = ({
  toggle,
  subscriptionType,
  shortTitle,
  title,
  subTitle,
}: ModalHeaderOpportunityProps) => {
  const { isXS } = useMediaQueriesV3();

  return (
    <ModalHeaderStyled toggle={toggle}>
      <ModalHeaderLeft>
        <IconOpportunityV2 icon={subscriptionType} />
        <TypeModalTitle tag="h3" size="xs" color="neutralLight">
          {isXS ? shortTitle ?? title : title}
        </TypeModalTitle>
      </ModalHeaderLeft>
      <Text tag="span" size="sm" color="neutralLight">
        {subTitle}
      </Text>
    </ModalHeaderStyled>
  );
};

interface SubjectInfoProps {
  className?: string;

  subscriptionType: SubscriptionType;
  subjectTitle?: string;
  subjectDescription?: string;
  subjectAddress?: Address;
  subjectIsRemote?: boolean;
  subjectFunction?: string;
  subjectMapLink?: string;
  deliveryDate?: string | undefined;
  deliveryWay?: DeliveryWay;

  shareableUrl: string;

  ods?: ODSDataRef[];

  actionTitle?: string;
  actionLink?: LocationDescriptor;
}

export const SubjectInfo = ({
  className,
  ods,

  shareableUrl,

  subscriptionType,

  subjectTitle,
  subjectDescription,
  subjectAddress,
  subjectIsRemote,
  subjectFunction,
  subjectMapLink,
  deliveryDate,
  deliveryWay,

  actionTitle,
  actionLink,
}: SubjectInfoProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslationV3();

  const deliveryDateFormated = deliveryDate
    ? format(new Date(deliveryDate), "dd MMM yyyy").split(" ")[0] +
      " " +
      t("plain:" + format(new Date(deliveryDate), "dd MMM yyyy").split(" ")[1]) +
      " " +
      format(new Date(deliveryDate), "dd MMM yyyy").split(" ")[2]
    : " -- ";

  const [isShareOpen, setIsShareOpen] = useState(false);
  const toggleShareDropdpwn = useCallback(() => setIsShareOpen((prev) => !prev), []);

  const { isXS } = useMediaQueriesV3();

  return (
    <SubjectInfoStyled className={className}>
      <Row>
        <ColLeft>
          <PopupTitle>{subjectTitle}</PopupTitle>
          <Link to={actionLink}>{actionTitle}</Link>
        </ColLeft>
        {isXS && (
          <ColRight>
            <Share isOpen={isShareOpen} toggle={toggleShareDropdpwn} url={shareableUrl} />
          </ColRight>
        )}
        {!isXS && (
          <ColRight>
            {ods && (
              <ODSList>
                {ods.map((ods) => (
                  <ODSIcon key={ods._id} icon={ods.reference as any} tag="li" />
                ))}
              </ODSList>
            )}
            <Share isOpen={isShareOpen} toggle={toggleShareDropdpwn} url={shareableUrl} />
          </ColRight>
        )}
      </Row>
      <Row>
        <ColLeft>
          {subjectDescription && (
            <>
              <TitleSection tag="h3" size="xs" color="neutralLight">
                {t("plain:Descrição")}
              </TitleSection>
              <InfoText>{subjectDescription}</InfoText>
            </>
          )}
          {deliveryWay === "to_combine" && deliveryDateFormated && (
            <>
              <TitleSection tag="h3" size="xs" color="neutralLight">
                {t("plain:Entrega")}
              </TitleSection>
              <InfoText>
                {t("plain:A entrega deve ser combinada e tem como prazo final dia X", { date: deliveryDateFormated })}
              </InfoText>
            </>
          )}
          {!subjectIsRemote && !subjectDescription && subjectAddress && deliveryWay !== "to_combine" && (
            <>
              <TitleSection tag="h3" size="xs" color="neutralLight">
                {t("plain:Endereço")}
              </TitleSection>
              <InfoText>{addressToFullString(subjectAddress)}</InfoText>
            </>
          )}
        </ColLeft>
        <ColRight>
          {subjectFunction && (
            <InfosWrapper>
              <TitleSection tag="h3" size="xs" color="neutralLight">
                {subscriptionType === "volunteer" ? t("plain:Função") : t("plain:Prazo")}
              </TitleSection>
              <InfoText>{subjectFunction}</InfoText>
            </InfosWrapper>
          )}
          {!subjectIsRemote && deliveryWay !== "to_combine" && subjectDescription && subjectAddress && (
            <InfosWrapper>
              <TitleSection tag="h3" size="xs" color="neutralLight">
                {t("plain:Endereço")}
              </TitleSection>
              <AddressWrapper>
                <InfoText marginBotton>{addressToFullString(subjectAddress)}</InfoText>
                {subjectMapLink && (
                  <AncorLink href={subjectMapLink} target="_blank">
                    {t("actions.seeMap.label")}
                  </AncorLink>
                )}
              </AddressWrapper>
            </InfosWrapper>
          )}
        </ColRight>
      </Row>
    </SubjectInfoStyled>
  );
};

const CustomLoading = styled(Loading)`
  margin: ${({ theme }) => theme.v3.spacing.huge};
`;

const ModalHeaderStyled = styled(ModalHeader)`
  padding-top: ${({ theme }) => theme.v3.spacing.xs};
  padding-bottom: ${({ theme }) => theme.v3.spacing.xs};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ModalHeaderLeft = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: ${({ theme }) => theme.v3.spacing.sm};
  }
`;

const CustomModalBaseContainer = styled(ModalBaseContainer)`
  ${ModalBody} {
    padding: 0;
  }
`;

const AncorLink = styled.a`
  ${({ theme }) => css`
    padding: 0 ${theme.v3.spacing.xxs};
    font-size: ${theme.v3.fontSize.sm};
    color: ${theme.v3.colors.primary};

    :focus {
      outline: 1px solid ${theme.v3.colors.feedbackSupport};
      box-shadow: none;
    }

    :hover {
      color: ${theme.v3.colors.primaryLight};
    }

    :active {
      color: ${theme.v3.colors.primaryDark};
    }
  `}
`;

/**
 * @deprecated
 */
export const TitleSection = styled(Text)`
  line-height: 1;
  text-transform: uppercase;
  font-weight: 500;
  margin: 0 0 ${({ theme }) => theme.v3.spacing.xs} 0;
`;

const TypeModalTitle = styled(TitleSection)`
  margin: 0;
`;

/**
 * By subject we mean either subscribe or material (and donation in the future).
 */
const SubjectInfoStyled = styled.div(
  ({ theme }) => css`
    position: relative;
    border-bottom: 1px solid ${theme.v3.colors.neutralLightest};
    padding: ${theme.v3.spacing.md};
    margin: 0 -${theme.v3.spacing.md};
  `
);

const ColLeft = styled.div`
  flex: 0 0 62.5%;
  max-width: 62.5%;
  padding-right: ${({ theme }) => theme.v3.spacing.sm};
  position: relative;

  ${mqV3.xsDown} {
    padding-right: 0;
  }
`;

const ColRight = styled.div`
  flex: 0 0 37.5%;
  max-width: 37.5%;
  padding-left: ${({ theme }) => theme.v3.spacing.sm};
  position: relative;

  ${mqV3.xsDown} {
    padding-left: 0;
  }
`;

const Row = styled.div`
  display: flex;
  margin: 0 0 ${({ theme }) => theme.v3.spacing.md} 0;

  ${mqV3.xsDown} {
    &:first-of-type {
      & > ${ColLeft} {
        max-width: 90%;
        flex: 90%;
      }

      & > ${ColRight} {
        flex: 10%;
        max-width: 10%;
      }
    }

    &:last-of-type {
      flex-wrap: wrap;

      & > ${ColLeft} {
        flex: 100%;
        max-width: 100%;
      }

      & > ${ColRight} {
        flex: 100%;
        max-width: 100%;
      }
    }
  }
`;

const PopupTitle = styled.h2`
  font-size: ${({ theme }) => theme.v3.fontSize.lg};
  color: ${({ theme }) => theme.v3.colors.neutralBase};
  font-weight: 400;
  line-height: ${rem(32)};
  margin: 0 0 ${({ theme }) => theme.v3.spacing.xs} 0;

  ${mqV3.xsDown} {
    font-size: ${({ theme }) => theme.v3.fontSize.md};
    line-height: ${rem(22)};
    margin-right: ${({ theme }) => theme.v3.spacing.xl};
  }
`;

const ODSList = styled.ul(
  ({ theme }) => css`
    list-style: none;
    // display: flex;
    margin: 0 calc(-${theme.v3.spacing.xxs});
    padding: 0 45px 0 0;

    li {
      margin: 0 ${theme.v3.spacing.xxs} ${theme.v3.spacing.xs};
      display: inline-block;
    }
  `
);

const InfoText = styled.p<{ marginBotton?: boolean }>`
  font-size: ${({ theme }) => theme.v3.fontSize.sm};
  line-height: ${rem(20)};
  color: ${({ theme }) => theme.v3.colors.neutralLight};
  margin: 0;
  text-align: justify;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  width: 100%;
  word-wrap: break-word;

  ${({ marginBotton }) =>
    marginBotton &&
    css`
      margin-bottom: ${({ theme }) => theme.v3.spacing.xs};
    `}

  ${mqV3.xsDown} {
    margin-bottom: ${({ theme }) => theme.v3.spacing.md};
  }
`;

const InfosWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.v3.spacing.sm};
`;

const AddressWrapper = styled.address`
  margin: 0;

  ${mqV3.xsDown} {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    ${InfoText} {
      margin: 0 ${({ theme }) => theme.v3.spacing.xl} 0 0;
    }

    a {
      white-space: nowrap;
    }
  }
`;

// /*<{ showShadow: boolean }>*/
export const SubscriptionContentWrapper = styled.div`
  width: 100%;
  padding-top: ${({ theme }) => theme.v3.spacing.md};

  ${TitleSection} {
    margin: 0 0 ${({ theme }) => theme.v3.spacing.md} 0;
  }
`;

/**
 * @deprecated
 */
export const SubscriptionContentScrollable = styled.div`
  padding: ${({ theme }) => `${theme.v3.spacing.md} ${theme.v3.spacing.md} 0 0`};
`;

/**
 * @deprecated
 */
export const ScrollbarsStyled = styled(Scrollbars)`
  height: 100%;
`;

/**
 * @deprecated
 */
export const TrackVertical = styled.div`
  right: 0;
  bottom: 0;
  top: 0;
  overflow: hidden;
`;

/**
 * @deprecated
 */
export const ThumbVertical = styled.div`
  background: #e2e6f4;
`;
