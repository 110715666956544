import { AxiosResponse } from "axios";
import http from "config/http";
import { ResponseStatus, ResponseWithParams } from "../definitions";

export interface SilentChat {
  chatID: string;
}

export type SilentChatReponse = ResponseWithParams<ResponseStatus, []>;

export default async ({ chatID }: SilentChat): Promise<AxiosResponse<SilentChatReponse>> =>
  http.get<SilentChatReponse>(`/chat/${chatID}/silent`);
