const CONTACT_PAGE_REQUESTED = "contac/REQUESTED";
const CONTACT_PAGE_RECEIVED = "contac/RECEIVED";
const CONTACT_PAGE_FAILED = "contac/FAILED";
const CONTACT_PAGE_CLEAN = "contac/CLEAN";

export default {
  CONTACT_PAGE_REQUESTED,
  CONTACT_PAGE_RECEIVED,
  CONTACT_PAGE_FAILED,
  CONTACT_PAGE_CLEAN,
};
