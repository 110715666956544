import http from "config/http";

export interface ResetPasswordRequest {
  password: string;
  password_confirmation: string;
  token: string;
}

export interface ResetPasswordResponse {}

const resetPassword = (data: ResetPasswordRequest) => http.post<ResetPasswordResponse>("/auth/reset_password", data);

export default resetPassword;
