import { isArray } from "lodash";
import { useCallback } from "react";
import { Namespace, useTranslation, UseTranslationOptions } from "react-i18next";
import { TFunction as TFunctionOriginal, TFunctionResult } from "i18next";
import Icon from "components/Icon/IconFontAwesome";

interface TFunctionParam {
  key: string | string[];
  values: any;
}
interface TFunction extends TFunctionOriginal {
  (obj: TFunctionParam): TFunctionResult;
}

const useTranslationV3 = (ns?: Namespace, props?: UseTranslationOptions) => {
  const namespaces = ["v3", "plain", "components"];

  if (typeof ns === "string") {
    namespaces.push(ns);
  } else if (isArray(ns)) {
    namespaces.concat(ns);
  }

  const { t, ...trans } = useTranslation(namespaces, props);

  const newT: TFunction = useCallback(
    (...args: any[]) => {
      if (args.length > 0 && args[0]?.key) {
        const values = args[0]?.values;
        const secondArgument = args.length > 1 && typeof args[1] === "object" ? args[1] : {};

        if (values) {
          return t(args[0]?.key, {
            ...values,
            label: t(`forms.labels.${secondArgument?.i18nKey ?? values.label ?? values.path}`),
          });
        }

        return t(args[0]?.key);
      }

      return t.apply(null, args as any);
    },
    [t]
  );

  return {
    t: newT,
    ...trans,
  };
};

export default useTranslationV3;
