import React, { useMemo } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components/macro";
import Icon from "components/Icon/Icon";
import { useTranslation } from "react-i18next";
import { rem } from "polished";

const Checklist = ({ rules, value, colors, className }) => {
  const { t } = useTranslation("components");

  const computedRules = useMemo(
    () => rules.map(({ description, validator }) => ({ description, isValid: validator(value) })),
    [rules, value]
  );

  return (
    <Container className={className}>
      {computedRules.map(({ description, isValid }) => (
        <Rule key={description} isValid={isValid} colors={colors}>
          <RuleIcon type="check" />
          <RuleDescription>{t(description)}</RuleDescription>
        </Rule>
      ))}
    </Container>
  );
};

Checklist.defaultProps = {
  colors: {
    fill: "",
    color: "",
  },
};

Checklist.propTypes = {
  colors: PropTypes.shape({
    fill: "",
    color: "",
  }),
  value: PropTypes.string.isRequired,
  rules: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
      validator: PropTypes.func.isRequired,
    })
  ).isRequired,
};

export default Checklist;

const Container = styled.div``;

const RuleIcon = styled(Icon)`
  width: 12px;
  fill: #bfc5d2;
`;

const RuleDescription = styled.div``;

const Rule = styled.div`
  display: flex;
  align-items: center;
  color: #bfc5d2;
  font-size: ${rem(11)};
  line-height: ${rem(18)};

  ${RuleIcon} {
    margin-right: 10px;
  }

  ${(props) => props.isValid && validRuleMixin}
`;

const validRuleMixin = css`
  ${RuleIcon} {
    fill: ${(props) => props?.colors?.fill || props.theme.colors.primary};
  }

  ${RuleDescription} {
    color: ${(props) => props?.colors?.color || props.theme.colors.secondary_700};
  }
`;
