import { AxiosResponse } from "axios";
import http from "config/http";
import { Response } from "../../definitions";

export interface Activity {
  created_at: string;
  remote_ip: string;
}

export interface MyActivitiesRequest {}

export type MyActivitiesResponse = Response<Activity[]>;

const myActivities = async ({}: MyActivitiesRequest = {}): Promise<AxiosResponse<MyActivitiesResponse>> =>
  http.get<MyActivitiesResponse>(`users/security/myActivits`);

export default myActivities;
