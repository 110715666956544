import http from "config/http";
import { ResponseWithParams, ResponseStatus } from "core/api/definitions";
import { AxiosResponse } from "axios";

interface response {
  platforms: {};
  report_requisites: {};
  causes: {};
  opportunity_types: {};
}

export type listFiltersReportProjectsResponse = ResponseWithParams<ResponseStatus, response>;

export default async (): Promise<AxiosResponse<listFiltersReportProjectsResponse>> => {
  return http.get<listFiltersReportProjectsResponse>("/admin/dashboard/get-filters-report-project-admin");
};
