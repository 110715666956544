import React, { useState, useEffect, Fragment, useCallback, useMemo } from "react";
import styled, { css } from "styled-components";
import SupportText from "components/common/SupportText";
import { Trans } from "react-i18next";
import { Link as BaseLink } from "react-router-dom";
import Button from "components/Button/Button";
import { storageGet, storageSet } from "utils/helpers";
import { rem } from "polished";
import { useDispatch } from "react-redux";
import { acceptTerms, AnalyticTool, revokeTerms } from "core/pages/terms";
import routes from "routes";
import Icon from "v3/components/Icons/Icon";
import useTranslationV3 from "hooks/useTranslationV3";
import ModalBaseContainer, { ModalBody, ModalHeader, ModalFooter } from "v3/components/Modals/ModalBase";
import Text from "v3/components/common/Text";
import ToggleBase from "v3/components/Toggle/ToggleBase";
import mqV3 from "utils/mediaQueriesV3";
import { useReduxSelector } from "hooks/useReduxSelector";
import { Origin } from "core/api/definitions";
import Axios from "axios";
import { API_METADATA, ENV } from "utils/constants";

export const STORAGE_KEY_TERMS_PREFFIX = "cookies_terms";

export const STORAGE_KEY_COOKIES_TERMS = `${STORAGE_KEY_TERMS_PREFFIX}_all`;

export const COOKIES_TERMS_ACCEPTED = "ACCEPTED";

const COOKIES_TERMS_REVOKED = "REVOKED";

const COOKIES = [AnalyticTool.FACEBOOK_PIXEL, AnalyticTool.ENHANCEMENT];

const CookieTerms = (): JSX.Element => {
  const { t } = useTranslationV3();
  const dispatch = useDispatch();
  const cookieTerms = storageGet(STORAGE_KEY_COOKIES_TERMS);

  const [shouldShow, setShouldShow] = useState(false);
  const [shouldShowManager, setShouldShowManager] = useState(false);

  const origin = useReduxSelector((state) => state.pagesNew?.origin?.params as Origin);
  const entity = origin?.entity || null;
  const hasFBPixel = origin?.facebook_pixel?.active && origin?.facebook_pixel?.id;

  const handleManagerToggle = useCallback(() => setShouldShowManager((prev) => !prev), []);

  const cookiesMemorized = useMemo(() => {
    if (hasFBPixel) return COOKIES;
    return COOKIES.filter((c) => c !== AnalyticTool.FACEBOOK_PIXEL);
  }, [hasFBPixel]);

  const handleMetadataRequest = useCallback(async () => {
    const cNavigator = navigator as any;
    const connection = cNavigator?.connection;
    const cookieEnabled = navigator.cookieEnabled;
    const credentials = navigator.credentials;
    const date = new Date().toISOString();
    const deviceMemory = cNavigator?.deviceMemory;
    const doNotTrack = navigator.doNotTrack;
    const language = navigator.language;
    const onLine = navigator.onLine;
    const platform = navigator.platform;
    const product = navigator.product;
    const productSub = navigator.productSub;
    const vendor = navigator.vendor;
    const vendorSub = navigator.vendorSub;
    const webdriver = navigator.webdriver;

    const data = {
      entity,
      environment: ENV,
      connection,
      cookieEnabled,
      credentials,
      date,
      deviceMemory,
      doNotTrack,
      language,
      onLine,
      platform,
      product,
      productSub,
      url: window.location.href,
      vendor,
      vendorSub,
      webdriver,
    };

    try {
      await Axios.post(API_METADATA, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (e) {
      console.error(e);
    }
  }, [entity]);

  const handleAcceptCookie = useCallback(
    ({ checked, analytic }: { checked: boolean; analytic: AnalyticTool }) => {
      if (checked) {
        dispatch(acceptTerms({ analytic }));
        storageSet(`${STORAGE_KEY_TERMS_PREFFIX}_${analytic}`, COOKIES_TERMS_ACCEPTED);
        if (analytic === AnalyticTool.FACEBOOK_PIXEL && hasFBPixel) handleMetadataRequest();
      } else {
        dispatch(revokeTerms({ analytic }));
        storageSet(`${STORAGE_KEY_TERMS_PREFFIX}_${analytic}`, COOKIES_TERMS_REVOKED);
      }
    },
    [dispatch, handleMetadataRequest, hasFBPixel]
  );

  const handleAcceptAll = useCallback(() => {
    if (hasFBPixel) handleMetadataRequest();
    cookiesMemorized.forEach((c) => {
      storageSet(`${STORAGE_KEY_TERMS_PREFFIX}_${c}`, COOKIES_TERMS_ACCEPTED);
    });
  }, [dispatch, handleMetadataRequest, hasFBPixel, cookiesMemorized]);

  useEffect(() => {
    let timeID: number | NodeJS.Timeout = 0;
    timeID = setTimeout(() => {
      if (!shouldShow) setShouldShow(cookieTerms !== COOKIES_TERMS_ACCEPTED);
    }, 6000);
    return () => {
      if (timeID) clearTimeout(timeID as number);
    };
  }, [cookieTerms, shouldShow]);

  const acceptedAll = cookieTerms === COOKIES_TERMS_ACCEPTED;

  return (
    <Fragment>
      <CookieContainer shouldShow={shouldShow}>
        <section>
          <button onClick={() => setShouldShow(false)}>
            <Icon icon="close" />
          </button>
          <Text tag="h2" size="md">
            {t("plain:Sobre o uso de Cookies")}
          </Text>
          <SupportText size="md" tag="p">
            <Trans
              t={t}
              i18nKey="plain:Utilizamos cookies para otimizar nossos serviços"
              components={[<Link size="md" tag={BaseLink} to={routes.terms} key="cookiesTerms.text.link" />]}
            />
          </SupportText>
        </section>
        <Actions>
          <Button
            type="button"
            block
            color="primary"
            onClick={() => {
              storageSet(STORAGE_KEY_COOKIES_TERMS, COOKIES_TERMS_ACCEPTED);
              handleAcceptAll();
              dispatch(acceptTerms({ analytic: "ALL" }));
              setShouldShow(false);
            }}
          >
            {t("actions.acceptAll.label")}
          </Button>
          <Button type="button" block color="primary" outline onClick={handleManagerToggle}>
            {t("actions.cookiesManage.label")}
          </Button>
        </Actions>
      </CookieContainer>
      <ModalBaseContainer isOpen={shouldShowManager} toggle={handleManagerToggle} centered={false} size="sm">
        <ModalHeader toggle={handleManagerToggle}>
          <Text tag="h2" size="lg">
            {t("plain:Sobre o uso de Cookies")}
          </Text>
        </ModalHeader>
        <CustomModalBody>
          <Text size="sm" color="neutralLight">
            <Trans
              t={t}
              i18nKey="plain:Utilizamos cookies para otimizar nossos serviços"
              components={[
                <Link
                  size="md"
                  tag={BaseLink}
                  to={routes.terms}
                  key="cookiesTerms.text.link"
                  onClick={handleManagerToggle}
                />,
              ]}
            />
          </Text>
          <ul>
            {cookiesMemorized.map((c) => {
              const isAccepted =
                storageGet(`${STORAGE_KEY_TERMS_PREFFIX}_${c}`) === COOKIES_TERMS_ACCEPTED ||
                (storageGet(`${STORAGE_KEY_TERMS_PREFFIX}_${c}`) !== COOKIES_TERMS_REVOKED && acceptedAll);

              return (
                <li key={c}>
                  <div>
                    <p>{t(`cookies.${c}`)}</p>
                    <ToggleBase
                      id={`tb_${c}`}
                      onValueChange={(checked: boolean) => handleAcceptCookie({ checked, analytic: c })}
                      checked={isAccepted}
                    />
                  </div>
                  <div>
                    <Text size="sm" color="neutralLight">
                      {c === AnalyticTool.FACEBOOK_PIXEL && t("plain:FacebookPixel_Terms")}
                      {c === AnalyticTool.ENHANCEMENT && t("plain:Enhancement_Terms")}
                    </Text>
                  </div>
                </li>
              );
            })}
          </ul>
        </CustomModalBody>
        <CustomModalFooter>
          <Button type="button" color="primary" outline onClick={handleManagerToggle}>
            {t("actions.cancel.label")}
          </Button>
          <Button
            type="button"
            color="primary"
            onClick={() => {
              storageSet(STORAGE_KEY_COOKIES_TERMS, COOKIES_TERMS_ACCEPTED);
              handleAcceptAll();
              dispatch(acceptTerms({ analytic: "ALL" }));
              handleManagerToggle();
              setShouldShow(false);
            }}
          >
            {t("actions.acceptAll.label")}
          </Button>
        </CustomModalFooter>
      </ModalBaseContainer>
    </Fragment>
  );
};

export default CookieTerms;

const CookieContainer = styled.div<{ shouldShow?: boolean }>(
  ({ theme, shouldShow }) => css`
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 10px;
    right: 0;
    bottom: 10px;
    width: 90%;
    max-width: 350px;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #cdd6e2;
    padding: ${theme.v3.spacing.md};
    box-shadow: 0 10px 20px 0 rgba(13, 29, 51, 0.07);
    transform: translateY(120%);
    transition: transform 500ms;
    z-index: 1000;

    h1,
    p {
      color: ${({ theme }) => theme.colors.support_text};
    }

    h1 {
      margin-bottom: ${rem(8)};
    }

    p {
      margin: 0;
    }

    & > section {
      position: relative;
      margin-bottom: ${theme.v3.spacing.md};

      ${Text} {
        margin-bottom: ${theme.v3.spacing.sm};
      }

      & > button:first-child {
        position: absolute;
        right: 0;
        background-color: transparent;
        border: 0;
        margin: 0;

        :hover {
          .svg-fill {
            fill: ${theme.v3.colors.feedbackError};
          }
        }
      }
    }

    ${mqV3.xsDown} {
      left: 0;
      margin: 0 auto;
      max-width: 100%;
    }

    ${shouldShow === true &&
    css`
      transform: translateY(0);
    `}
  `
);

const Link = styled(SupportText)<{ to?: string }>`
  color: ${({ theme }) => theme.colors.secondary};
`;

const Actions = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    & > button {
      margin: ${theme.v3.spacing.xs} 0;
    }
  `
);

const CustomModalBody = styled(ModalBody)(
  ({ theme }) => css`
    padding-top: ${theme.v3.spacing.sm};

    > ${Text} {
      margin-bottom: ${theme.v3.spacing.md};
    }

    > ul {
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        display: flex;
        flex-direction: column;
        margin: ${theme.v3.spacing.sm} 0;

        > div:first-child {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: ${theme.v3.spacing.xs};

          p {
            margin: 0;
            color: ${theme.v3.colors.neutralBase};
            font-size: ${theme.v3.fontSize.md};
          }
        }
      }
    }
  `
);

const CustomModalFooter = styled(ModalFooter)(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
  `
);
