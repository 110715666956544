import admin from "./admin";
import commonUser from "./commonUser";
import origin from "./origin";
import actions from "./actions";
import users from "./users";
import auth from "./auth";
import program from "./program";
import project from "./projects";
import subscribe from "./subscribe";
import donations from "./donations";
import site from "./site";
import opportunities from "./opportunities";
import blog from "./blog";
import volunteers from "./volunteers";
import chat from "./chat";

const api = {
  admin,
  auth,
  commonUser,
  origin,
  actions,
  opportunities,
  users,
  program,
  project,
  subscribe,
  donations,
  site,
  blog,
  volunteers,
  chat,
};

export default api;
