import React from "react";
import { FormGroup as RSFormGroup } from "reactstrap";
import styled from "styled-components/macro";

//TODO: Write a interface

const FormGroup: React.FC = ({ children, ...props }) => {
  return <FormGroupStyled {...props}>{children}</FormGroupStyled>;
};

export default FormGroup;

const FormGroupStyled = styled(RSFormGroup)`
  position: relative;
  padding-top: ${({ theme }) => theme.v3.spacing.xs};

  label {
    position: absolute;
    top: 0;
    left: ${({ theme }) => theme.v3.spacing.xs};
    z-index: 2;
  }
`;
