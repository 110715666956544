import http from "config/http";
import queryString from "query-string";
import { AxiosResponse } from "axios";
import { ResponseWithParams, ResponseStatus } from "core/api/definitions";

export interface CertificateRequest {
  pg?: number;
  pp?: number;
}

export interface CertificateResponse {
  certificates: {
    action_data: {
      _id: string;
      title: string;
      slug: string;
    };
    subscribe_data: {
      _id: string;
      title: string;
      slug: string;
      logo: string;
    };
    volunteer_participation: {
      hours: string;
      minutes: string;
    };
  }[];
}

export type ListResponse = ResponseWithParams<ResponseStatus, CertificateResponse>;

export default async ({ pp, pg }: CertificateRequest): Promise<AxiosResponse<ListResponse>> => {
  const qs: CertificateRequest = {};

  if (pg) qs.pg = pg;
  if (pp) qs.pp = Math.max(8, pp);

  return http.get<ListResponse>(`/v3/subscribe/certificates?${queryString.stringify(qs)}`);
};
