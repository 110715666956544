import React, { useState, useMemo, useCallback } from "react";
import styled, { withTheme } from "styled-components/macro";
import Button from "components/Button/Button";
import PropTypes from "prop-types";
import { Form, FormFeedback } from "reactstrap";
import {
  SimpleContainer as BaseContainer,
  Infos,
  CurrentStep,
  TitleStep,
  BaseInput,
  Text,
  LinkStep,
} from "../commonStyles";
import { useTranslation, Trans } from "react-i18next";
import Checklist from "components/SignUpModal/Checklist";
import SignUpFormBase, { passwordRules } from "components/SignUpModal/SignUpFormBase";
import { isEmpty } from "lodash";
import { PROFILE_OPTIONS } from "components/SignUpModal/SignUpFormBase";
import { dontPaste } from "utils/helpers";
import TermsModal from "components/VacancyView/TermsModal";
import { CustomInput as BaseCheckbox } from "components/Form";
import Divider from "components/common/Divider";
import { default as BaseInputMask } from "react-input-mask";
import Link from "components/common/Link";
import { darken } from "polished";

const InitFormData = {
  name: "",
  email: "",
  emailConfirmation: "",
  phone: "",
  password: "",
  passwordConfirmation: "",
  accept_terms: false,
};

const Register = ({ teamID, onSuccess, theme, runStep, className }) => {
  const { t } = useTranslation("components");

  const [dataFormRequest, setDataFormRequest] = useState({});
  const [wasSubmmited, setWasSubmmited] = useState(false);
  const [wasRegistered, setWasRegistered] = useState(false);
  const [openTerm, setOpenTerm] = useState(false);
  const [response, setResponse] = useState({});
  const [formData, setFormData] = useState(InitFormData);

  const resetForm = () => setFormData(InitFormData);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Static attributes
    const tmp = {
      ...formData,
      receive_emails: false,
      project_type: "none",
      program_invite_code: "futebol",
      profile: PROFILE_OPTIONS.volunteer,
      soccer_team_id: teamID ? teamID : undefined,
      phone: formData.phone.replace(/\D/g, ""),
    };

    setWasSubmmited(true);
    setDataFormRequest(tmp);
  };

  const handleResponseMemorized = useCallback(
    (response) => {
      if (response?.success === true && !isEmpty(dataFormRequest)) {
        resetForm();
        setDataFormRequest({});
        setWasRegistered(true);
        return;
      }
      setWasSubmmited(false);
      setWasRegistered(false);
      setResponse(response);
    },
    [dataFormRequest]
  );

  const handleChange = (e) => {
    const name = e?.target?.name || "";
    const value = e?.target?.value || "";

    setFormData((prev) => ({ ...prev, [name]: value }));
    if (!isEmpty(response)) setResponse({});
  };

  const isNameInvalid = response?.inputs?.name?.isInvalid;
  const isEmailInvalid = response?.inputs?.email?.isInvalid;
  const isPhoneInvalid = response?.inputs?.phone?.isInvalid;
  const isPasswordInvalid = response?.inputs?.password?.isInvalid;

  const hasError = isNameInvalid || isEmailInvalid || isPhoneInvalid || isPasswordInvalid;

  const passwordRulesMemorized = useMemo(() => passwordRules.some(({ validator }) => !validator(formData.password)), [
    formData,
  ]);

  return (
    <Container className={className}>
      {wasRegistered ? (
        <>
          <TitleStep>{t("modalQuizTeam.steps.register.registered.title")}</TitleStep>
          <Text>{t("modalQuizTeam.steps.register.registered.message")}</Text>
        </>
      ) : (
        <>
          <Infos>
            <CurrentStep>{t("modalQuizTeam.step", { current: 3, last: 3 })}</CurrentStep>
            <TitleStep>{t("modalQuizTeam.steps.register.title")}</TitleStep>
          </Infos>
          <SignUpFormBase
            dispatchRequest={dataFormRequest}
            dispatchResponse={handleResponseMemorized}
            redirectOnSuccess={null} //TODO(Jeconias) Add link
          >
            <CustomForm onSubmit={handleSubmit}>
              <div>
                <BaseInput
                  type="text"
                  name="name"
                  placeholder={t("signUpModal.form.name.placeholder")}
                  invalid={isNameInvalid}
                  onChange={(e) => handleChange(e)}
                  value={formData.name}
                  disabled={wasSubmmited}
                  required
                />
                <FormFeedback>
                  {isNameInvalid && t(`apiResponses.createUser.error.${response.inputs.name.description}`)}
                </FormFeedback>
              </div>
              <div>
                <BaseInput
                  type="email"
                  name="email"
                  placeholder={t("signUpModal.form.email.placeholder")}
                  autoComplete="off"
                  onChange={(e) => handleChange(e)}
                  value={formData.email}
                  disabled={wasSubmmited}
                  required
                />
              </div>
              <div>
                <BaseInput
                  type="email"
                  name="emailConfirmation"
                  autoComplete="off"
                  placeholder={t("signUpModal.form.emailConfirmation.placeholder")}
                  invalid={isEmailInvalid}
                  onChange={(e) => handleChange(e)}
                  onKeyDown={(e) => dontPaste(e)}
                  value={formData.emailConfirmation}
                  disabled={wasSubmmited}
                  required
                />
                <FormFeedback>
                  {isEmailInvalid && t(`apiResponses.createUser.error.${response.inputs.email.description}`)}
                </FormFeedback>
              </div>
              <div>
                <InputMask
                  mask="(99) 999999999"
                  type="tel"
                  name="phone"
                  placeholder={t("signUpModal.form.phone.placeholder")}
                  autoComplete="off"
                  invalid={isPhoneInvalid}
                  onChange={(e) => handleChange(e)}
                  value={formData.phone}
                  disabled={wasSubmmited}
                />
                <FormFeedback>
                  {isPhoneInvalid && t(`apiResponses.createUser.error.${response.inputs.phone.description}`)}
                </FormFeedback>
              </div>
              <Row>
                <div>
                  <BaseInput
                    type="password"
                    name="password"
                    placeholder={t("signUpModal.form.password.placeholder")}
                    invalid={isPasswordInvalid}
                    onChange={(e) => handleChange(e)}
                    value={formData.password}
                    disabled={wasSubmmited}
                    required
                  />
                  <FormFeedback>
                    {isPasswordInvalid && t(`apiResponses.createUser.error.${response.inputs.password.description}`)}
                  </FormFeedback>
                </div>
                <div>
                  <BaseInput
                    type="password"
                    name="passwordConfirmation"
                    placeholder={t("signUpModal.form.passwordConfirm.placeholder")}
                    onChange={(e) => handleChange(e)}
                    onKeyDown={(e) => dontPaste(e)}
                    value={formData.passwordConfirmation}
                    disabled={wasSubmmited}
                    required
                  />
                </div>
              </Row>
              <CustomChecklist
                colors={{ fill: theme.colors.secondary, color: theme.colors.secondary }}
                value={formData.password}
                rules={passwordRules}
              />

              <Divider />

              <AgreeCheckbox
                id="agreement"
                name="agreement"
                type="checkbox"
                label={
                  <Trans
                    ns="components"
                    i18nKey={"signUpModal.form.agreement.label"}
                    components={[<CustomLink href="#" onClick={() => setOpenTerm(true)} rel="noopener noreferrer" />]}
                  />
                }
                checked={formData.accept_terms}
                onChange={() => setFormData((prev) => ({ ...prev, accept_terms: !prev.accept_terms }))}
                disabled={wasSubmmited}
              />
              {openTerm && (
                <TermsModal
                  terms={[
                    t("vacancyView.term.paragraph"),
                    t("vacancyView.term.paragraphOne"),
                    t("vacancyView.term.paragraphTwo"),
                    t("vacancyView.term.paragraphThree"),
                    t("vacancyView.term.paragraphFour"),
                    t("vacancyView.term.paragraphFive"),
                    t("vacancyView.term.paragraphSix"),
                    t("vacancyView.term.paragraphSeven"),
                    t("vacancyView.term.paragraphEight"),
                    t("vacancyView.term.paragraphNine"),
                    t("vacancyView.term.paragraphTen"),
                    t("vacancyView.term.paragraphEleven"),
                  ]}
                  onClose={() => setOpenTerm(false)}
                  onConfirm={() => {
                    setOpenTerm(false);
                    setFormData((prev) => ({ ...prev, accept_terms: true }));
                  }}
                />
              )}
              <ButtonContainer>
                <CustomButton disabled={wasSubmmited || !formData.accept_terms || passwordRulesMemorized || hasError}>
                  {t("modalQuizTeam.steps.register.form.submit")}
                </CustomButton>
              </ButtonContainer>
            </CustomForm>
            <LinkStep runStep={runStep} text={"signUpModal.actions.signIn"} />
          </SignUpFormBase>
        </>
      )}
    </Container>
  );
};

Register.defaultProps = {};

Register.propTypes = {
  /** Close modal for the last step */
  closeModal: PropTypes.func.isRequired,
  teamID: PropTypes.string.isRequired,
};

export default withTheme(Register);

const Container = styled(BaseContainer)`
  max-width: 360px;
`;

const CustomForm = styled(Form)``;

const Row = styled.div`
  display: flex;

  div:first-child {
    padding-right: 5px;
  }
  div:last-child {
    padding-left: 5px;
  }
`;

const CustomButton = styled(Button)`
  margin-top: 30px;
  padding: 14px 40px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const CustomChecklist = styled(Checklist)`
  padding-top: 20px;

  & > div {
    color: #fff;
    font-weight: 500;

    svg {
      fill: #fff;
    }
  }
`;

export const AgreeCheckbox = styled(BaseCheckbox)`
  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: ${({ theme }) => theme.colors.secondary};
    border-color: ${({ theme }) => theme.colors.secondary};
  }
  label {
    color: #fff;
  }
`;

const InputMask = ({ ...props }) => <BaseInput {...props} tag={BaseInputMask} />;

const CustomLink = styled(Link)`
  color: ${({ theme }) => theme.colors.secondary};

  &:hover {
    color: ${({ theme }) => darken(0.1, theme.colors.secondary)};
  }
`;
