import { AxiosResponse } from "axios";
import { httpBlog } from "config/http";
import { BlogPost } from "../definitions";
import queryString from "query-string";

export interface PostRequest {
  slug?: string;
  post_id?: string;
  post_status?: string;
}

const post = async ({ slug, ...data }: PostRequest): Promise<AxiosResponse<BlogPost>> => {
  if (slug) {
    return httpBlog.get<BlogPost>(`/posts/${slug}`);
  }

  const qs = queryString.stringify(data, { arrayFormat: "bracket" });

  return httpBlog.get<BlogPost>(`/posts/_post-preview/?${qs}`);
};

export default post;
