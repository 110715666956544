import { Origin } from "core/api/definitions";
import { useReduxSelector } from "hooks/useReduxSelector";
import useTranslationV3 from "hooks/useTranslationV3";
import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import Text from "v3/components/common/Text";
import Icon from "../../Icons/Icon";
import { ModalBodyRegister } from "../common";
import { ModalFooter as BaseModalFooter } from "../ModalBase";

interface FinishedStepProps {
  title?: string;
  url?: string;
}

const FinishedStep: React.FC<FinishedStepProps> = ({ title, url: relativeUrl, children }) => {
  const { t } = useTranslationV3();

  const platformUrl = useReduxSelector((state) => (state.pagesNew.origin.params as Origin).social_networks);
  const url = `${platformUrl}${relativeUrl}`;
  return (
    <ModalBodyRegister>
      <FlexFinished>
        <Icon icon="check" />
        <Text tag="h1" size="xl" color="neutralBase">
          {title}
        </Text>
        <Text size="sm" color={"neutralLight"}>
          {children}
        </Text>
      </FlexFinished>
      {url && (
        <ModalFooter>
          <Text size="sm" color="neutralBase">
            {t("plain:Compartilhe com seus amigos")}
          </Text>
          <Icons>
            {platformUrl.map((socialNetworks) => {
              return (
                <Styled>
                  <Link
                    to="route"
                    target="_blank"
                    onClick={(event) => {
                      event.preventDefault();
                      window.open(socialNetworks.url);
                    }}
                  >
                    <Img src={socialNetworks.icon} alt="redes-sociais" />
                  </Link>
                </Styled>
              );
            })}
          </Icons>
        </ModalFooter>
      )}
    </ModalBodyRegister>
  );
};

export default FinishedStep;

const ModalFooter = styled(BaseModalFooter)`
  display: flex;
  flex-direction: column;
`;

const FlexFinished = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: ${theme.v3.spacing.xl} ${theme.v3.spacing.xl} 0 ${theme.v3.spacing.xl};

    & > svg {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background: ${theme.v3.colors.primary};
      margin-top: ${theme.v3.spacing.lg};
      margin-bottom: calc(${theme.v3.spacing.xl} + ${theme.v3.spacing.xs});
    }

    & > h1 {
      font-weight: 400;
      margin-bottom: ${theme.v3.spacing.sm};
    }

    & > p {
      margin-bottom: calc(${theme.v3.spacing.xl} + ${theme.v3.spacing.md});
    }

    .svg-fill {
      fill: ${theme.v3.colors.neutralWhite};
    }

    & ~ .modal-footer {
      margin: 0 -33px;
      padding: ${theme.v3.spacing.xl};
      padding-bottom: ${theme.v3.spacing.xs};
      justify-content: center;

      & > p {
        margin-bottom: ${theme.v3.spacing.sm};
        font-weight: 500;
      }
    }
  `};
`;

const Icons = styled.div`
  display: flex;
  margin: 0 -${({ theme }) => theme.v3.spacing.sm};

  svg {
    width: 40px;
    height: 40px;
    margin: 0 ${({ theme }) => theme.v3.spacing.xs};
  }
`;

const Img = styled.img`
  max-width: 20px;
  width: 100%;
  object-fit: contain;
  cursor: pointer;
`;

const Styled = styled.div`
  background: ${({ theme }) => theme.v3.colors.primary};
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
