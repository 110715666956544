import React from "react";
import styled, { css } from "styled-components/macro";
import { transition } from "utils/animation";
import { ColorV3 } from "utils/types";
import Icon from "v3/components/Icons/Icon";

const ArrowButton = styled.button(
  ({ theme, color, disabled }) => css`
    background: transparent;
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0 4px;
    border: 1px solid transparent;
    outline: 0;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;

    :focus {
      outline: 0;
      border-color: ${theme.v3.colors.feedbackSupport};
    }

    svg {
      width: 22px;
      height: 22px;
    }

    .svg-fill {
      fill: ${disabled
        ? theme.v3.colors.neutralLightness
        : color
        ? theme.v3.colors[color as ColorV3]
        : theme.v3.colors.primary};
      transition: ${transition.fast("fill")};
    }

    &:hover {
      .svg-fill {
        fill: ${disabled ? theme.v3.colors.neutralLightness : theme.v3.colors.primaryLight};
      }
    }

    &:active {
      .svg-fill {
        fill: ${disabled ? theme.v3.colors.neutralLightness : theme.v3.colors.primaryDark};
      }
    }

    :first-child {
      margin-left: 0;
    }

    :last-child {
      margin-right: 0;
    }
  `
);

interface ArrowButtonsProps {
  className?: string;
  backDisabled?: boolean;
  forwardDisabled?: boolean;
  onBack?: () => void;
  onForward?: () => void;
  color?: ColorV3;
}

const ArrowButtonsComp = ({
  className,
  backDisabled,
  forwardDisabled,
  onBack,
  onForward,
  color,
}: ArrowButtonsProps) => (
  <div className={className}>
    <ArrowButton disabled={backDisabled} onClick={onBack} color={color}>
      <Icon icon="arrowLeft" />
    </ArrowButton>
    <ArrowButton disabled={forwardDisabled} onClick={onForward} color={color}>
      <Icon icon="arrowRight" />
    </ArrowButton>
  </div>
);

export const ArrowButtons = styled(ArrowButtonsComp)``;
