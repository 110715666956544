import React, { useRef, useState } from "react";
import Icon from "../Icons/Icon";
import styled from "styled-components/macro";
import { transition } from "utils/animation";
import useTranslationV3 from "hooks/useTranslationV3";
import useEntity from "hooks/useEntity";

export function MoreMenu() {
  const menuRef = useRef(null);
  const buttonRef = useRef(null);
  const { entity } = useEntity();

  const [isActive, setIsActive] = useState(false);
  const onClick = () => {
    setIsActive(!isActive);
  };

  window.addEventListener("click", (e) => {
    if (e.target !== menuRef.current && e.target !== buttonRef.current) {
      setIsActive(false);
    }
  });
  const { t } = useTranslationV3();

  return (
    <Container>
      <Navbar>
        <ul>
          <li className="li-p">
            <span ref={buttonRef} onClick={onClick}>
              Mais
              <Icon icon="chevronDown" size="sm" color="primary" />
            </span>

            {isActive && (
              <ul ref={menuRef} className="ul-sub">
                <li className="li-sub">
                  <NavLink href="https://ebook.transformabrasil.com.br/" target="_blank">
                    E-books
                  </NavLink>
                </li>
                <li className="li-sub">
                  {entity === "brasil" && (
                    <NavLink href="https://eventos.transformabrasil.com.br/" target="_blank">
                      {t("plain:Eventos")}
                    </NavLink>
                  )}
                </li>
                <li className="li-sub">
                  {entity === "brasil" && (
                    <NavLink href="https://transformabrasil.com.br/blog" target="_blank">
                      Blog
                    </NavLink>
                  )}
                </li>
                <li className="li-sub">
                  <NavLink href="/faq" target="_blank">
                    FAQ
                  </NavLink>
                </li>
              </ul>
            )}
          </li>
        </ul>
      </Navbar>
    </Container>
  );
}

export const Container = styled.div`
  ul {
    list-style: none;
    padding: 0;
  }
`;

export const Navbar = styled.nav`
  height: 50px;

  span {
    padding: 0 15px;
    display: flex;
    align-items: center;
  }

  .li-p {
    cursor: pointer;
    line-height: 50px;
  }

  .ul-sub {
    background-color: #fff;
    border-radius: 6px;
    border: 1.5px solid #e6eaf6;
    box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
  }

  .li-sub {
    line-height: 30px;
  }

  .li-sub:first-child {
    border-bottom: 1.5px solid #e6eaf6;
  }
  .li-sub {
    border-top: 1.5px solid #e6eaf6;
  }

  ${Icon} {
    margin-left: 6.6px;
    z-index: -99 !important;
  }
`;

export const NavLink = styled.a`
  display: block;
  text-align: center;
  padding: 0 12px;

  color: ${({ theme }) => theme.v3.colors.neutralBase};
  font-weight: 500;
  font-size: ${({ theme }) => theme.v3.fontSize.sm};
  transition: ${transition.fast("color")};

  &:hover {
    color: ${({ theme }) => theme.v3.colors.primary};
  }
`;
