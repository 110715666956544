import http from "config/http";
import { Cause, Interest, Skill } from "pages/ListActions/DataOfFilters";
import { AddressWithCoordinates, ProgramPageData } from "../definitions";
import queryString from "qs";

export interface GetProgramActionsRequest {
  slug: string;
  cc?: Cause[];
  fu?: Skill[];
  ci?: Interest[];
  rm?: string;
  wk?: string;
  pg?: number;
  term?: string;
  filter_subscribes?: boolean;
  filter_materials?: boolean;
  address?: AddressWithCoordinates;
}

interface GetProgramActionsResponse extends ProgramPageData {}

const getProgramActions = async ({ slug, ...data }: GetProgramActionsRequest) => {
  const qs = queryString.stringify(data, { arrayFormat: "brackets", encode: false, addQueryPrefix: false });
  return http.get<GetProgramActionsResponse>(`/program/${slug}/getActions?&pp=9&${qs}`);
};

export default getProgramActions;
