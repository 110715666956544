import { createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import { RequestableState } from "core/api/definitions";
import decoreThunk from "core/decorators/decorate";

import { getListVolunteersAppointment } from "./action";

import requestLoading from "core/decorators/requestLoading";
import { ListAppointment, ListAppointmentDetails } from "core/api/users/listVolunteersNextAppointments";
import { logout } from "core/auth";

interface IState extends RequestableState, ListAppointmentDetails {
  total: number;
  hasMore: boolean;
}

const panelBetaListVolunteersAppointmentsSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "panelBetaListVolunteersAppointmentsSlice",
  initialState: {
    loading: "idle",
    list: [],
    total: 0,
    hasMore: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, getListVolunteersAppointment, [
      requestLoading(),
      {
        pending: (state: IState) => {
          state.loading = "loading";
        },
        fulfilled: (state: IState, action: PayloadAction<ListAppointment>) => {
          state.loading = "ok";
          if (action?.payload?.response) {
            state.total = action.payload.total;
            state.hasMore = action.payload.hasMore;
            state.list = action.payload.response;
          }
        },
        rejected: (state: IState) => {
          state.loading = "error";
        },
      },
    ]);
    builder.addCase(logout.toString(), (state) => {
      state.loading = "idle";
      //state.opportunities_data = {};
    });
  },
});

export default panelBetaListVolunteersAppointmentsSlice;
