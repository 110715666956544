import { createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import { hideFeedback, showFeedback } from "./actions";
import { ALERT_DISMISS, ALERT_SHOW } from "./types";

export type FeedbackType = {
  type: "info" | "danger";
  message: string;
  ms?: number;
};

interface IState {
  currentId: number;
  feedbacks: ({ id: number } & FeedbackType)[];
}

const alertsSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "alerts",
  initialState: {
    feedbacks: [],
    currentId: 0,
  },
  reducers: {},
  extraReducers: {
    [showFeedback.toString()]: (state, action: PayloadAction<FeedbackType>) => {
      const newID = state.currentId + 1;
      state.feedbacks.push({
        id: newID,
        type: action.payload.type,
        message: action.payload.message,
        ms: action.payload.ms,
      });

      state.currentId = newID;
    },
    [hideFeedback.toString()]: (state, action) => {
      state.feedbacks = state.feedbacks.filter((alert) => alert.id !== action.payload.id) || [];
    },
    /**
     * @deprecated
     */
    [ALERT_SHOW]: (state, action) => {
      const newID = state.currentId + 1;
      state.feedbacks.push({
        id: newID,
        type: action.payload.type,
        message: action.payload.message,
        ms: action.payload.ms,
      });

      state.currentId = newID;
    },
    /**
     * @deprecated
     */
    [ALERT_DISMISS]: (state, action) => {
      state.feedbacks = state.feedbacks.filter((alert) => alert.id !== action.payload.id) || [];
    },
  },
});

export default alertsSlice;
