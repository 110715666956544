import { AxiosResponse } from "axios";
import http from "config/http";
import { ResponseWithParams, ResponseStatus } from "core/api/definitions";
import { Cause } from "pages/ListActions/DataOfFilters";
import { ODSData } from "../definitions";

export interface ActionCreateResponseData {
  causes: {
    _id: string;
    slug: Cause;
    description: string;
    thumbnail: string;
    ods_data: ODSData[];
  }[];
}

export type ActionCreateResponse = ResponseWithParams<ResponseStatus, ActionCreateResponseData>;

const create = async (): Promise<AxiosResponse<ActionCreateResponse>> =>
  await http.get<ActionCreateResponse>("/loadSupportData");

export default create;
