import { AxiosResponse } from "axios";
import http from "config/http";
import { ResponseStatus, ResponseWithParams } from "../definitions";
import { ProjectData } from "../definitions";

interface CancelAppointmentsRequest {
  subscribe_id: string;
  registration_id: string[];
}

export type CancelAppointmentsResponse = ResponseWithParams<ResponseStatus, ProjectData>;

export default async ({
  subscribe_id,
  registration_id,
}: CancelAppointmentsRequest): Promise<AxiosResponse<CancelAppointmentsResponse>> =>
  http.post<CancelAppointmentsResponse>(`/volunteer/cancel-registration/${subscribe_id}`, { registration_id });
