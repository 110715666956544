import { Login } from "core/api/definitions";
import cookies from "core/cookies";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import routes from "routes";
import { useReduxSelector } from "./useReduxSelector";

export const volunteerRule = (lgn?: Login | null): boolean =>
  lgn?.type === "volunteer" &&
  (lgn?.dash_profile === "volunteer" || (lgn?.dash_profile === "both" && lgn?.selected_dash_profile === "volunteer"));

export const organizationRule = (lgn?: Login | null) =>
  lgn?.type === "volunteer" &&
  (lgn?.dash_profile === "project" || (lgn?.dash_profile === "both" && lgn?.selected_dash_profile === "project"));

export const useUser = () => {
  const login = useReduxSelector(({ login }) => {
    return login;
  });

  const lgn = login?.params;
  const usr = cookies.user.get() as Login;

  const isAdmin = lgn?.type === null;
  const isVolunteer = volunteerRule(lgn);
  const isOrganization = organizationRule(lgn);

  return {
    userType: lgn?.type,
    user: !!login?.success && lgn ? lgn : null,
    userCookie: !!login?.success && lgn ? usr : null,
    email: lgn?.email,
    isAdmin,
    lastContextAccess: lgn?.last_context_access,
    isOrganization: isOrganization, // TODO(Jota): To refactor this to `isProject`
    isVolunteer: isVolunteer,
    hasPersonalProject: lgn?.has_personal_project, // TODO(Jota): Rename this to has_personal_project.
    // status: lgn?.status,
    dash_profile: lgn?.dash_profile,
    selected_dash_profile: lgn?.selected_dash_profile,
    project_type: lgn?.project_type,
    ambassador: lgn?.ambassador,
    isValidated: lgn?.status_validation === "active",
    isReadOnly: lgn?.status_validation === "suspended" || lgn?.status_validation === "suspended_by_exclusion",
  };
};

/**
 * @deprecated Please, don't use this hook!
 */
export const useCreateActionPerformer = () => {
  const { user } = useUser();
  const { search } = useLocation();
  const history = useHistory();

  return () => {
    if (!user) return history.push({ search: `${search}&open=signIn`, state: { from: routes.wizard.projectList } });
    history.push({ pathname: routes.wizard.projectList });
  };
};

// TODO(Jota): Fix this definition
export const withUser = (Component: any) => {
  // TODO(Jota): Fix this definition
  return function WrappedComponent(props: any) {
    const typeUser = useUser();
    return <Component {...props} typeUser={typeUser} />;
  };
};
