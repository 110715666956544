import { AxiosResponse } from "axios";
import http from "config/http";
import { ResponseStatus, ResponseWithParams } from "../../definitions";

interface UpdateUserPrivacyRequest {
  objectID: string;
  intern_search_data: boolean;
}

interface UpdateUserPrivacyResponseData {}

type UpdateUserPrivacyResponse = ResponseWithParams<ResponseStatus, UpdateUserPrivacyResponseData>;

const updateUserPrivacy = async ({
  objectID,
  intern_search_data,
}: UpdateUserPrivacyRequest): Promise<AxiosResponse<UpdateUserPrivacyResponse>> =>
  await http.patch<UpdateUserPrivacyResponse>(`/users/privacy/${objectID}`, { intern_search_data });

export default updateUserPrivacy;
