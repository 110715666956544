import { AxiosResponse } from "axios";
import http from "config/http";
import {
  ImageData,
  RegistrationData as RegistrationDataBase,
  ProjectSocialNetwork,
  Contact,
  Address,
} from "core/api/definitions";

export interface RegistrationDataSimple
  extends Pick<RegistrationDataBase, "slot_id" | "status" | "day" | "time_end" | "time_start" | "time_in_seconds"> {}

export interface VolunteerLoggedPopupV2Response {
  action_data: {
    _id: string;
    slug: string;
    title: string;
    cover: ImageData;
    gallery: ImageData[];
    address?: Address;
  };
  project_data: {
    _id: string;
    name: string;
    slug: string;
    contacts: Contact[];
    images_data: {
      cover: ImageData;
      profile: ImageData;
    };
    social_network: ProjectSocialNetwork;
  };
  subscribe_data: {
    _id: string;
    slug: string;
    title: string;
    action_id: string;
  };
  registration_data: RegistrationDataSimple[];
}

interface VolunteerLoggedPopupV2Request {
  slug: string;
  registration_id?: string[];
}

const volunteerLoggedPopupV2 = ({
  slug,
  registration_id,
}: VolunteerLoggedPopupV2Request): Promise<AxiosResponse<VolunteerLoggedPopupV2Response>> =>
  http.post<VolunteerLoggedPopupV2Response>(`/subscribe/${slug}/volunteer/profile`, { registration_id });

export default volunteerLoggedPopupV2;
