import React from "react";
import { ModalHeader as BSModalHeader, ModalHeaderProps as BaseModalHeaderProps } from "reactstrap";
import styled, { css } from "styled-components/macro";
import { rem } from "polished";
import Icon from "components/Icon/Icon";

interface ModalHeaderProps extends BaseModalHeaderProps {
  alignedClose?: boolean;
}

const ModalHeader: React.FC<ModalHeaderProps> = ({ toggle, centered, alignedClose = true, ...props }) => {
  const closeBtn = (
    <Toggler onClick={toggle} aligned={alignedClose}>
      <Icon type="multiply" />
    </Toggler>
  );

  return <CustomModalHeader centered={centered} toggle={toggle} close={closeBtn} {...props} />;
};

ModalHeader.defaultProps = {
  alignedClose: true,
};

export default ModalHeader;

const CustomModalHeader = styled(({ ...props }) => <BSModalHeader {...props} />)`
  border: none;
  padding: 22px 30px 18px;
  position: relative;

  .modal-title {
    flex: 1;
    font-weight: 300;
    color: ${(props) => props.theme.colors.secondary_700};
    font-size: ${rem(25)};
    font-weight: 300;
    line-height: ${rem(35)};

    ${(props) =>
      props.centered &&
      css`
        text-align: center;
      `}
  }
`;

export const Toggler = styled.span<{ aligned?: boolean }>`
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s ease;

  ${(props) => props.aligned && alignedTogglerMixin}

  svg {
    width: 13px;
    fill: #454746;
  }

  &:hover {
    opacity: 1;
  }
`;

const alignedTogglerMixin = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
`;
