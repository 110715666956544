import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit";
import api from "core/api";
import { AllRequest, AllResponse } from "core/api/admin/projects/all";
import { ValidateRequest, ValidateResponse } from "core/api/admin/projects/validate";
import { Project } from "core/api/definitions";
import { RequestableState, ListState, ListAction, LoadingStatus } from "core/api/definitions";
import { ErrorResponse } from "core/api/definitions";
import list from "core/decorators/list";
import decoreThunk from "core/decorators/decorate";
import asyncThunk from "core/decorators/toolkit";

type GetAllResponse = (ListAction & AllResponse) | ErrorResponse;

export const getAll = asyncThunk<GetAllResponse, AllRequest>("panel/validation/projects/all", api.admin.projects.all, {
  withReturn: ["pg"],
});

type PostValidateResponse = ValidateResponse | ErrorResponse;

export const postValidate = asyncThunk<PostValidateResponse, ValidateRequest>(
  "panel/validation/projects/validate",
  api.admin.projects.validate
);

type IState = RequestableState & ListState<Project & { validatingStatus: LoadingStatus }>;

const panelValidationProjectSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "panelValidationProject",
  initialState: {
    loading: "idle",
    list: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, getAll, [list()]);
    decoreThunk(builder, postValidate, [
      {
        pending: (state, action) => {
          const project = state.list.find((project) => project.project_data.slug === action.meta.arg.project);
          if (project) project.validatingStatus = "loading";
          // TODO(Jota): Handle the case of not finding the project.
        },
        fulfilled: (state, action) => {
          const project = state.list.find((project) => project.project_data.slug === action.meta.arg.project);
          if (project) {
            project.validatingStatus = "ok";
            project.project_data.status = action.meta.arg.status === "reject" ? "rejected" : action.meta.arg.status;
          }
          // TODO(Jota): Handle the case of not finding the project.
        },
        rejected: (state, action) => {
          const project = state.list.find((project) => project.project_data.slug === action.meta.arg.project);
          if (project) project.validatingStatus = "error";
          // TODO(Jota): Handle the case of not finding the project.
        },
      },
    ]);
  },
});

export default panelValidationProjectSlice;
