import { combineReducers } from "@reduxjs/toolkit";

import originSlice from "./origin";
import programSlice from "./program";
import actionsSlice from "core/api/site/actions";
import projectSlice from "./project";
import homeSlice from "./home";
import opportunitiesSlice from "core/api/site/opportunities";
import cards from "../cards";
import donationsSlice from "core/api/site/donations";
import notificationsSlice from "./notifications";
import odsSlice from "./ods";
import aboutSlice from "./about";
import contactSlice from "./contact";

const pagesReducer = combineReducers({
  origin: originSlice.reducer,
  program: programSlice.reducer,
  actions: actionsSlice.reducer,
  project: projectSlice.reducer,
  opportunities: opportunitiesSlice.reducer,
  donations: donationsSlice.reducer,
  cards: cards.reducer,
  home: homeSlice.reducer,
  notifications: notificationsSlice.reducer,
  ods: odsSlice.reducer,
  about: aboutSlice.reducer,
  contact: contactSlice.reducer,
});

export default pagesReducer;
