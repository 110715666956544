import { createSlice, SliceCaseReducers, PayloadAction } from "@reduxjs/toolkit";
import api from "core/api";
import { RequestableState, ListWithFilterState, LoadingStatus } from "core/api/definitions";
import decoreThunk from "core/decorators/decorate";
import asyncThunk from "core/decorators/toolkit";

import {
  ListVolunteerRequest,
  ListVolunteerResponse,
  ListVolunteerResponseFilter,
  ValidationVolunteer,
} from "core/api/actions/listVolunteers";
import { logout } from "core/auth";
import {
  VolunteerProfile,
  GetVolunteerProfileRequest,
  GetVolunteerProfileResponse,
} from "core/api/actions/volunteerProfile";
import { ValidateVolunteerResponse, ValidateVolunteerRequest } from "core/api/actions/validateVolunteer";
import {
  GetVolunteerProfileDetailsRequest,
  GetVolunteerProfileDetailsResponse,
  VolunteerProfileDetails,
} from "core/api/actions/volunteerProfileDetails";

type IState = RequestableState &
  ListWithFilterState<ValidationVolunteer & { loading?: LoadingStatus }, ListVolunteerResponseFilter> & {
    volunteers: {
      [id: string]: Partial<VolunteerProfile> & { loading: LoadingStatus };
    };
    volunteersDetails: {
      [id: string]: Partial<VolunteerProfileDetails> & { loading: LoadingStatus };
    };
  };

export const listVolunteersForValidation = asyncThunk<ListVolunteerResponse, Omit<ListVolunteerRequest, "status">>(
  "panel/validation/volunteers/list",
  (data) => api.actions.listVolunteers({ status: "pending", ...data })
);

export const getVolunteerProfile = asyncThunk<GetVolunteerProfileResponse, GetVolunteerProfileRequest>(
  "panel/validation/volunteers/profile/get",
  api.actions.volunteerProfile
);

export const getVolunteerValidationProfile = asyncThunk<
  GetVolunteerProfileDetailsResponse,
  GetVolunteerProfileDetailsRequest
>("panel/validation/volunteers/validation-profile/get", api.actions.volunteerProfileDetails);

export const validateVolunteer = asyncThunk<ValidateVolunteerResponse, ValidateVolunteerRequest>(
  "panel/validation/volunteers/validate",
  api.actions.validateVolunteer
);

const volunteerValidationSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "panel/validation/volunteers",
  initialState: {
    loading: "idle",
    list: [],
    filter_data: [],
    volunteers: {},
    volunteersDetails: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    // Clear state when restart.
    builder.addCase(logout, (state) => {
      state.list = [];
    });

    decoreThunk(builder, listVolunteersForValidation, [
      {
        pending: (state: IState) => {
          state.loading = "loading";
        },
        // listWithFilter<, ListVolunteerResponseFilter>(),
        fulfilled: (state: IState, action: PayloadAction<ListVolunteerResponse>) => {
          state.loading = "ok";
          state.filter_data = action.payload.response?.filter_data ?? [];
          state.list = action.payload.response?.result ?? [];
          state.total = action.payload.total ?? 0;
        },
        rejected: (state: IState) => {
          state.loading = "error";
        },
      },
    ]);

    decoreThunk(builder, getVolunteerProfile, [
      {
        pending: (state, action) => {
          if (state.list[action.meta.arg.volunteerId]) {
            state.volunteers[action.meta.arg.volunteerId].loading = "loading";
          } else {
            state.volunteers[action.meta.arg.volunteerId] = {
              loading: "loading",
            };
          }
        },
        fulfilled: (
          state,
          action: PayloadAction<GetVolunteerProfileResponse, string, { arg: GetVolunteerProfileRequest }>
        ) => {
          state.volunteers[action.meta.arg.volunteerId] = {
            loading: "ok",
            ...action.payload.params,
          };
        },
        rejected: (state, action) => {
          state.volunteers[action.meta.arg.volunteerId] = {
            loading: "error",
          };
        },
      },
    ]);

    decoreThunk(builder, getVolunteerValidationProfile, [
      {
        pending: (state, action) => {
          if (state.list[action.meta.arg.volunteerId]) {
            state.volunteersDetails[action.meta.arg.volunteerId].loading = "loading";
          } else {
            state.volunteersDetails[action.meta.arg.volunteerId] = {
              loading: "loading",
            };
          }
        },
        fulfilled: (
          state,
          action: PayloadAction<GetVolunteerProfileDetailsResponse, string, { arg: GetVolunteerProfileDetailsRequest }>
        ) => {
          state.volunteersDetails[action.meta.arg.volunteerId] = {
            loading: "ok",
            ...action.payload.params,
          };
        },
        rejected: (state, action) => {
          state.volunteersDetails[action.meta.arg.volunteerId] = {
            loading: "error",
          };
        },
      },
    ]);

    const userInTheListByAction = (
      action: PayloadAction<ValidateVolunteerResponse, string, { arg: ValidateVolunteerRequest }>
    ) => (item: ValidationVolunteer) =>
      item.registration_data[0].subscribe_data.slug === action.meta.arg.subscribeSlug &&
      item.registration_data[0].user_data._id === action.meta.arg.volunteerId;

    decoreThunk(builder, validateVolunteer, [
      {
        pending: (
          state,
          action: PayloadAction<ValidateVolunteerResponse, string, { arg: ValidateVolunteerRequest }>
        ) => {
          const user = state.list.find(userInTheListByAction(action));
          if (user) {
            user.loading = "loading";
          }
        },
        fulfilled: (
          state,
          action: PayloadAction<ValidateVolunteerResponse, string, { arg: ValidateVolunteerRequest }>
        ) => {
          const user = state.list.find(userInTheListByAction(action));
          if (user) {
            user.loading = "ok";
            switch (action.meta.arg.status) {
              case "approved":
                user.registration_data[0].status = "approved";
                break;
              case "reject":
                user.registration_data[0].status = "reject";
                break;
            }
          }
        },
        rejected: (state, action) => {
          const user = state.list.find(userInTheListByAction(action));
          if (user) {
            user.loading = "error";
          }
        },
      },
    ]);
  },
});

export default volunteerValidationSlice;
