import styled, { css } from "styled-components/macro";
import { rem, margin } from "polished";

type DividerType = string | number;

const Divider = styled.div<{ before?: DividerType; after?: DividerType; opacity?: number }>`
  width: 100%;
  color: ${(props) => props.theme.colors.support_text};
  font-size: ${rem(13)};
  line-height: ${rem(20)};
  text-align: center;
  display: flex;
  align-items: center;
  ${(props) => margin(props.before || 24, 0, props.after || 14)}

  &::before,
  &::after {
    content: "";
    flex: 1;
    border-bottom: ${(props) =>
      props.opacity ? `1px solid rgb(226, 230, 244, ${props.opacity})` : "1px solid #e2e6f4"};
  }

  ${(props) => !!props.children && withChildrenMixin}
`;

const withChildrenMixin = css`
  &::before {
    margin-right: 20px;
  }

  &::after {
    margin-left: 20px;
  }
`;

export default Divider;
