import http from "config/http";
import { ActionDataToCheckout } from "../definitions";
import { ResponseStatus, ResponseWithParams } from "../definitions";
import { MaterialDataToCheckout } from "core/api/definitions";
import { UserDataPanelDetails } from "core/api/definitions";
import { ProjectDataToCheckout } from "../definitions";
import { AxiosResponse } from "axios";

export interface ListMaterialsRegistrationsByUserDetailRequest {
  materialId?: string;
}

export interface ListMaterialsRegistrationsByUserDetailResponseData {
  _id: string;
  material_data: MaterialDataToCheckout;
  user_data: UserDataPanelDetails;
  action_data: ActionDataToCheckout;
  project_data: ProjectDataToCheckout;
}

export type ListMaterialsRegistrationsResponse = ResponseWithParams<
  ResponseStatus,
  ListMaterialsRegistrationsByUserDetailResponseData
>;

export default async ({
  materialId,
}: ListMaterialsRegistrationsByUserDetailRequest): Promise<AxiosResponse<ListMaterialsRegistrationsResponse>> => {
  return http.get<ListMaterialsRegistrationsResponse>(`/donors/materials/users/detail/${materialId}`);
};
