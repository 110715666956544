let standardFormatter: Intl.NumberFormat;

let defaultLanguage: string;

export const initNumberFormatters = (language: string) => {
  defaultLanguage = language;
  standardFormatter = new Intl.NumberFormat(language);
};

interface Options {
  precision: number;
}

export const formatNumber = (value: number, options?: Options) => {
  if (!options) return standardFormatter.format(value);
  return new Intl.NumberFormat(defaultLanguage, {
    maximumFractionDigits: options.precision,
  }).format(value);
};
