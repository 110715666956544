import http from "config/http";
import { Conditional, Conditionals, Contact, ResponseStatus, ResponseWithParams } from "../definitions";
import { Address } from "core/api/definitions";
import { AxiosResponse } from "axios";

interface ListVolunteerAppointmentResquest {
  project_id: string;
  volunteer_id: string;
}

export interface VolunteerDetailModal {
  general: {
    _id: string;
    name: string;
    email: string;
    profile_image: string;
    locations: Address;
    primary: string;
    phone: Contact;
  };
  preferences: {
    conditionals: {
      causes: Conditional[];
      functions: Conditional[];
      interests: Conditional[];
      skills: Conditional[];
    };
    _id: string;
  };
  counters: {
    rating?: number;
    actions_count: number;
    evaluation_rating_total: number;
    subscribe_count_total: number;
    volunteer_count: number;
  };
}

export interface SubscribeRegistration {
  _id: string;
  subscribe_title: string;
  action_title: string;
  subscribe_accept_remote: boolean;
}

export interface ListAppointmentResponse {
  volunteer: VolunteerDetailModal;
  subscribe_registration: SubscribeRegistration[];
}

export type ListVolunteerAppointmentResponse = ResponseWithParams<ResponseStatus, ListAppointmentResponse>;

const listProjectVolunteerAppointmentsByProject = async ({
  project_id,
  volunteer_id,
}: ListVolunteerAppointmentResquest): Promise<AxiosResponse<ListVolunteerAppointmentResponse>> =>
  http.post(`/projects/${project_id}/volunteer/${volunteer_id}`);

export default listProjectVolunteerAppointmentsByProject;
