import Logo from "components/Logo/Logo";
import { Origin } from "core/api/definitions";
import { useReduxSelector } from "hooks/useReduxSelector";
import useTranslationV3 from "hooks/useTranslationV3";
import React from "react";
import routes from "routes";
import styled, { css } from "styled-components";
import { Container } from "v3/components/Grid";
import { default as LinkV3 } from "v3/components/Link/Link";
import { default as MatchmakerPreferencesSectionBase } from "v3/pages/Home/MatchmakerPreferencesSection";
import { Link } from "react-router-dom";
import ModalMaterials from "v3/components/Modals/ModalMaterials";
import { MODALS } from "utils/constants";
import { modalClose } from "core/pages/actions";
import { useDispatch } from "react-redux";
import ModalVolunteerVacancies from "v3/components/Modals/ModalSubscribe";

const MatchmakerPreferencesPage = () => {
  const dispatch = useDispatch();

  const { t } = useTranslationV3();
  const { origin, modal } = useReduxSelector((state) => ({
    origin: state.pagesNew.origin.params as Origin,
    modal: state.pages.modal,
  }));

  return (
    <>
      <ModalVolunteerVacancies
        isOpen={MODALS.VACANCY === modal?.modal}
        onClose={() => {
          if (MODALS.VACANCY === modal?.modal) dispatch(modalClose());
        }}
        subscribeSlug={modal?.slug}
      />
      <ModalMaterials
        isOpen={MODALS.DONATIONS === modal?.modal}
        onClose={() => {
          if (MODALS.DONATIONS === modal?.modal) dispatch(modalClose());
        }}
      />
      <Wrapper>
        <Container>
          <header>
            <Link to={routes.home}>
              <Logo src={origin.logoIcon} alt={origin.title} />
            </Link>
            <LinkV3 to={routes.home}>{t("actions.goToPlatform.label")}</LinkV3>
          </header>
        </Container>
        <MatchmakerPreferencesSection />
      </Wrapper>
    </>
  );
};

export default MatchmakerPreferencesPage;

const MatchmakerPreferencesSection = styled(MatchmakerPreferencesSectionBase)`
  height: 100%;
`;

const Wrapper = styled.div(
  ({ theme }) => css`
    background-color: ${theme.v3.colors.neutralWhite};
    height: calc(100vh - 80px);

    ${Container} > header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: ${theme.v3.colors.neutralWhite};
      min-height: 80px;
    }
  `
);
