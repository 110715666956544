import {
  faTimes,
  faGlobe,
  faEye,
  faPlus,
  faHandsHeart,
  faBoxHeart,
  faDonate,
  faBell,
  faCommentDots,
  faSearch,
  faEyeSlash,
  faChevronRight,
  faChevronLeft,
  faGlobeAmericas,
} from "@fortawesome/pro-regular-svg-icons";

const FarIcons = [
  faTimes,
  faGlobe,
  faEye,
  faPlus,
  faEyeSlash,
  faHandsHeart,
  faBoxHeart,
  faDonate,
  faBell,
  faCommentDots,
  faSearch,
  faChevronRight,
  faChevronLeft,
  faGlobeAmericas,
];

export default FarIcons;
