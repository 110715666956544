import storeNewProject from "./storeNewProject";
import updateSocialNetworks from "./updateSocialNetworks";
import edit from "./edit";
import show from "./show";
import updateProjectOrganization from "./updateProjectOrganization";
import { getProjectByOwner } from "./getProjectByOwner";
import getActionsOpportunities from "./getActionsOpportunities";
import list from "./list";
import listAppointmentsByMonth from "./listAppointmentsByMonth";
import listAppointmentsByDate from "./listAppointmentsByDate";
import switchProjects from "./switchProjects";
import listProjectVolunteerAppointmentsByProject from "./listProjectVolunteerAppointmentsByProject";

export default {
  edit,
  storeNewProject,
  updateSocialNetworks,
  getProjectByOwner,
  updateProjectOrganization,
  show,
  list,
  getActionsOpportunities,
  switchProjects,
  listProjectVolunteerAppointmentsByProject,
  listAppointmentsByMonth,
  listAppointmentsByDate,
};
