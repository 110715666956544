import http from "config/http";
import { ResponseStatus, ResponseWithParams } from "../definitions";

export interface AboutResponse {
  body: {
    text: string;
    subtitle: string;
    images: string[];
  };
  volunteer_section: {
    actions_created: number;
    count: number;
    subscribes_opened: number;
  };
  description: string;
  image_cover: string;
  title: string;
}

export interface AboutResponseWithParams extends ResponseWithParams<ResponseStatus, AboutResponse> {}

const about = () => http.get<AboutResponseWithParams>("/pages/about");

export default about;
