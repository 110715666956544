export const HEADER = 5;
export const NOTIFICATIONS = HEADER + 1;
export const POPUP = 10;
export const ALERT = 15;
export const OVERLAY = 30;

const layers = {
  Header: HEADER,
  Popup: POPUP,
  Notifications: NOTIFICATIONS,
  Alert: ALERT,
  Overlay: OVERLAY,
};

export default layers;
