import React from "react";
import ToggleBase, { ToggleBaseProps } from "./ToggleBase";
import styled from "styled-components";

interface ToogleLabeledProps extends ToggleBaseProps {
  label: string;
}

const ToggleLabeledComp = ({ label, ...props }: ToogleLabeledProps) => {
  return <ToggleBase {...props}>{label}</ToggleBase>;
};

const ToggleLabeled = styled(ToggleLabeledComp)`
  display: flex;
`;

export default ToggleLabeled;
