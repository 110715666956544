import { TFunction } from "i18next";
import React from "react";
import styled from "styled-components/macro";
import Text from "v3/components/common/Text";
import Icon from "v3/components/Icons/Icon";

interface CheckProps {
  isOk: boolean;
  t: TFunction;
}

const PasswordGroup = styled.div`
  display: flex;
  align-items: center;
`;

const MinLength: React.FC<CheckProps> = ({ isOk, t }) => (
  <PasswordGroup>
    <Icon icon="check" color={isOk ? "primary" : "neutralLightest"} />
    <Text size="xs" tag="span" color={isOk ? "neutralBase" : "neutralLight"}>
      {t("plain:Mínimo 8 caracteres")}
    </Text>
  </PasswordGroup>
);

const Alphanumeric: React.FC<CheckProps> = ({ isOk, t }) => (
  <PasswordGroup>
    <Icon icon="check" color={isOk ? "primary" : "neutralLightest"} />
    <Text size="xs" tag="span" color={isOk ? "neutralBase" : "neutralLight"}>
      {t("plain:Insira letras e números")}
    </Text>
  </PasswordGroup>
);

const CapitalLetter: React.FC<CheckProps> = ({ isOk, t }) => (
  <PasswordGroup>
    <Icon icon="check" color={isOk ? "primary" : "neutralLightest"} />
    <Text size="xs" tag="span" color={isOk ? "neutralBase" : "neutralLight"}>
      {t("plain:Ao menos 1 letra maiúscula")}
    </Text>
  </PasswordGroup>
);

const SpecialCharacters: React.FC<CheckProps> = ({ isOk, t }) => (
  <PasswordGroup>
    <Icon icon="check" color={isOk ? "primary" : "neutralLightest"} />
    <Text size="xs" tag="span" color={isOk ? "neutralBase" : "neutralLight"}>
      {t("plain:Ao menos 1 caractere especial")}
    </Text>
  </PasswordGroup>
);

const PasswordRules = {
  MinLength,
  Alphanumeric,
  CapitalLetter,
  SpecialCharacters,
};

const PasswordRulesTest = {
  minLength(newPassword: string | null | undefined): boolean {
    return !!newPassword && newPassword?.length > 7;
  },
  alphanumeric(newPassword: string | null | undefined): boolean {
    return !!newPassword && /([0-9])+/.test(newPassword) && /([a-z])+/.test(newPassword);
  },
  capitalLetter(newPassword: string | null | undefined): boolean {
    return !!newPassword && newPassword.match(/([A-Z])+/) !== null;
  },
  specialCharacters(newPassword: string | null | undefined): boolean {
    return !!newPassword && /([!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])+/.test(newPassword);
  },
};

export default { Rules: PasswordRules, RulesTest: PasswordRulesTest };
