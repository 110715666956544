import { rem, readableColor } from "polished";
import { get, isNil } from "lodash";
import styled, { css } from "styled-components/macro";
import mediaQueries from "utils/mediaQueries";
import { Size } from "utils/types";

export const Section = styled.section`
  width: 100%;
  padding-top: 50px 0 60px 0;

  ${mediaQueries.sm(css`
    padding-top: 30px;
  `)}
`;

export const SectionHeader = styled.header`
  margin-bottom: 30px;
`;

export const SectionFooter = styled.footer`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
`;

export const SectionFooterButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;

  > a {
    position: relative;

    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 1500px;
      height: 1px;
      background-color: #e2e6f4;
      top: 50%;
    }

    &:before {
      left: calc(100% + 20px);
    }

    &:after {
      right: calc(100% + 20px);
    }
  }

  ${mediaQueries.xs(css`
    > a {
      &:before,
      &:after {
        display: none;
      }
    }
  `)};
`;

export const SectionContent = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;

export interface SectionTitleProps {
  size?: Size;
  color?: string;
}

export const SectionTitle = styled.h2<SectionTitleProps>`
  font-size: ${({ size }) => (size === "lg" ? rem(44) : rem(25))};
  font-weight: ${({ size }) => (size === "lg" ? 400 : 300)};
  line-height: ${({ size }) => (size === "lg" ? rem(50) : rem(35))};
  margin: 0;
  color: ${({ theme, color }) => {
    return !theme.page || isNil(theme.page.colors.background_colored)
      ? get(theme.colors, color ?? theme.colors.secondary_700, theme.colors.secondary_700)
      : readableColor(theme.page.colors.background_colored, theme.colors.secondary_700, "#fff");
  }};

  strong {
    font-weight: 400;
  }

  ${mediaQueries.md(css<SectionTitleProps>`
    font-size: ${({ size }) => (size === "lg" ? rem(36) : rem(30))};
    line-height: ${rem(44)};
  `)};

  ${mediaQueries.sm(css<SectionTitleProps>`
    font-size: ${({ size }) => (size === "lg" ? rem(26) : rem(20))};
    line-height: ${rem(34)};
    text-align: center;
  `)};
`;

export const SectionArgument = styled.p`
  font-size: ${rem(25)};
  font-weight: 300;
  line-height: ${rem(35)};
  margin: 0;

  ${mediaQueries.md(css<SectionTitleProps>`
    font-size: ${({ size }) => (size === "lg" ? rem(22) : rem(18))};
    line-height: ${({ size }) => (size === "lg" ? rem(30) : rem(25))};
  `)};

  ${mediaQueries.sm(css<SectionTitleProps>`
    font-size: ${({ size }) => (size === "lg" ? rem(20) : rem(15))};
  `)};
`;

export const SectionAction = styled.div`
  margin-top: 50px;
`;
