import http from "config/http";
import { ResponseStatus, ResponseWithParams } from "../../definitions";
import { ProgramDataShare } from "core/api/definitions";
import { AxiosResponse } from "axios";

interface UpdateUserPrivacyProgramRequest {
  objectID: string;
  program_data_share: Omit<ProgramDataShare, "name">[];
}

interface UpdateUserPrivacyResponseProgramData {}

type UpdateUserPrivacyProgramResponse = ResponseWithParams<ResponseStatus, UpdateUserPrivacyResponseProgramData>;

const updateUserPrivacyProgram = async ({
  objectID,
  program_data_share,
}: UpdateUserPrivacyProgramRequest): Promise<AxiosResponse<UpdateUserPrivacyProgramResponse>> =>
  await http.patch<UpdateUserPrivacyProgramResponse>(`/users/privacy/programShareData/${objectID}`, {
    program_data_share,
  });

export default updateUserPrivacyProgram;
