import contactTypes from "./types";
import createReducer from "../createReducer";

const INITIAL_STATE = {
  contact: {
    loading: true,
  },
};

const contactReducer = createReducer(INITIAL_STATE, {
  [contactTypes.CONTACT_PAGE_REQUESTED]: (state) => ({
    ...state,
    contact: {
      loading: true,
      status: "waiting",
    },
  }),
  [contactTypes.CONTACT_PAGE_RECEIVED]: (state, action) => ({
    ...state,
    contact: {
      ...action.payload,
      success: true,
      loading: false,
      status: "received",
    },
  }),
  [contactTypes.CONTACT_PAGE_FAILED]: (state, action) => ({
    ...INITIAL_STATE,
    contact: {
      ...action.payload,
      loading: false,
      status: "failed",
    },
  }),
  [contactTypes.CONTACT_PAGE_CLEAN]: () => ({
    contact: {},
  }),
});

export default contactReducer;
