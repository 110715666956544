import { DropdownItem as RSDropdownItem, DropdownMenu as RSDropdownMenu } from "reactstrap";
import React, { ElementType } from "react";
import styled, { css } from "styled-components/macro";
import { Link } from "react-router-dom";
import { usePlatformUrl } from "hooks/usePlatformUrl";
import { transition } from "utils/animation";

const DropdownMenu = styled(RSDropdownMenu)`
  width: 240px;
  padding: 0;
  overflow: hidden;
  border-radius: 6px;

  ${({ theme }) => css`
    border: 1px solid ${theme.v3.colors.neutralLightest};
    box-shadow: ${theme.v3.boxShadow.l3};
  `}
`;

interface CustomDropdownItemProps {
  to?: string;
  href?: string;
  className?: string;
  onClick?: () => void;
  active?: boolean;
  ignoreProgram?: boolean;
}

interface LinkProps {
  to?: string;
  href?: string;
  target?: string;
}

const DropdownItem: React.FC<CustomDropdownItemProps> = ({ className, to, children, ignoreProgram, ...props }) => {
  const { platformUrl, program } = usePlatformUrl();
  const LinkComponent: string | ElementType = program && !ignoreProgram ? "a" : Link;
  let linkProps: LinkProps = {};
  if (to) {
    linkProps =
      program && !ignoreProgram
        ? {
            href: `${platformUrl}${to}`,
            target: "_blank",
          }
        : {
            to,
          };
  } else if (!props?.href) {
    linkProps.href = "#";
  }

  return (
    <CustomDropdownMenuListItem className={className}>
      <DropdownItemStyled tag={LinkComponent} {...props} {...linkProps}>
        {children}
      </DropdownItemStyled>
    </CustomDropdownMenuListItem>
  );
};

export { DropdownMenu, DropdownItem };

const CustomDropdownMenuListItem = styled.li`
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.v3.colors.neutralLightest};
  }
`;

const DropdownItemStyled = styled(({ ...props }) => <RSDropdownItem {...props} active={undefined} />)`
  font-size: ${({ theme }) => theme.v3.fontSize.sm};
  color: ${({ theme }) => theme.v3.colors.neutralBase};
  padding: ${({ theme }) => theme.v3.spacing.sm};
  transition: ${transition.fast("all")};

  &:hover {
    background: ${({ theme }) => theme.v3.colors.neutralBackground};
    color: ${({ theme }) => theme.v3.colors.neutralBase};
  }

  &:focus {
    outline: none;
    color: ${({ theme }) => theme.v3.colors.neutralBase};
  }

  ${({ active }) =>
    active &&
    css`
      font-size: ${({ theme }) => theme.v3.fontSize.sm};
      font-weight: 500;
      padding: ${({ theme }) => theme.v3.spacing.sm};
      color: ${({ theme }) => theme.v3.colors.neutralBase};
    `}
`;
