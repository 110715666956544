import { createSlice, SliceCaseReducers, PayloadAction } from "@reduxjs/toolkit";
import api from "core/api";
import { RequestableState, ListWithFilterState, LoadingStatus } from "core/api/definitions";
import decoreThunk from "core/decorators/decorate";
import asyncThunk from "core/decorators/toolkit";

import { logout } from "core/auth";
import listWithFilter from "core/decorators/listWithFilter";
import {
  VolunteerProfile,
  GetVolunteerProfileRequest,
  GetVolunteerProfileResponse,
} from "core/api/actions/volunteerProfile";
import {
  CheckoutVolunteerListResponse,
  CheckoutVolunteerListRequest,
  CheckoutVolunteer,
  CheckoutVolunteerListResponseFilter,
} from "core/api/actions/checkoutVolunteerList";
import { CheckoutVolunteerResponse, CheckoutVolunteerRequest } from "core/api/actions/checkoutVolunteer";

type IState = RequestableState &
  ListWithFilterState<CheckoutVolunteer & { loading?: LoadingStatus }, CheckoutVolunteerListResponseFilter> & {
    volunteers: {
      [id: string]: Partial<VolunteerProfile> & { loading: LoadingStatus };
    };
  };

export const listVolunteersForCheckout = asyncThunk<CheckoutVolunteerListResponse, CheckoutVolunteerListRequest>(
  "panel/checkout/volunteers/list",
  api.actions.checkoutVolunteerList
);

export const getVolunteerProfile = asyncThunk<GetVolunteerProfileResponse, GetVolunteerProfileRequest>(
  "panel/checkout/volunteers/profile/get",
  api.actions.volunteerProfile
);

export const checkoutVolunteer = asyncThunk<CheckoutVolunteerResponse, CheckoutVolunteerRequest>(
  "panel/checkout/volunteers/checkout",
  api.actions.checkoutVolunteer
);

const volunteerCheckoutSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "panel/checkout/volunteers",
  initialState: {
    loading: "idle",
    list: [],
    filter_data: [],
    volunteers: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    // Clear state when restart.
    builder.addCase(logout, (state) => {
      state.list = [];
    });

    decoreThunk(builder, listVolunteersForCheckout, [
      listWithFilter<CheckoutVolunteer, CheckoutVolunteerListResponseFilter>(),
    ]);

    decoreThunk(builder, getVolunteerProfile, [
      {
        pending: (state, action) => {
          if (state.list[action.meta.arg.volunteerId]) {
            state.volunteers[action.meta.arg.volunteerId].loading = "loading";
          } else {
            state.volunteers[action.meta.arg.volunteerId] = {
              loading: "loading",
            };
          }
        },
        fulfilled: (
          state,
          action: PayloadAction<GetVolunteerProfileResponse, string, { arg: GetVolunteerProfileRequest }>
        ) => {
          state.volunteers[action.meta.arg.volunteerId] = {
            loading: "ok",
            ...action.payload.params,
          };
        },
        rejected: (state, action) => {
          state.volunteers[action.meta.arg.volunteerId] = {
            loading: "error",
          };
        },
      },
    ]);

    const userInTheListByAction = (
      action: PayloadAction<CheckoutVolunteerListResponse, string, { arg: CheckoutVolunteerRequest }>
    ) => (item: CheckoutVolunteer) =>
      item.registration_data.subscribe_data.slug === action.meta.arg.subscribeSlug &&
      item.registration_data.user_data._id === action.meta.arg.volunteerId &&
      (item.registration_data._id === action.meta.arg.registration_id ||
        item.registration_data.slot_id === action.meta.arg.slot_id);

    decoreThunk(builder, checkoutVolunteer, [
      {
        pending: (
          state,
          action: PayloadAction<CheckoutVolunteerListResponse, string, { arg: CheckoutVolunteerRequest }>
        ) => {
          const user = state.list.find(userInTheListByAction(action));
          if (user) {
            user.loading = "loading";
          }
        },
        fulfilled: (
          state,
          action: PayloadAction<CheckoutVolunteerListResponse, string, { arg: CheckoutVolunteerRequest }>
        ) => {
          const user = state.list.find(userInTheListByAction(action));
          if (user) {
            user.loading = "ok";
            switch (action.meta.arg.status) {
              case "present":
                user.registration_data.status = "present";
                break;
              case "absent":
                user.registration_data.status = "absent";
                break;
            }
          }
        },
        rejected: (state, action) => {
          const user = state.list.find(userInTheListByAction(action));
          if (user) {
            user.loading = "error";
          }
        },
      },
    ]);
  },
});

export default volunteerCheckoutSlice;
