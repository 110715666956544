import routes from "routes";
import { ValidationFieldSet } from "core/api/definitions";
import Validator from "validatorjs";
import { causes, functions, abilities } from "pages/ListActions/DataOfFilters";
import { errorMessages } from "wizard/v3/hooks/useValidation";

const after: Validator.RegisterCallback = (value: string | number | boolean, args?: string) => {
  try {
    if ((typeof value !== "string" && !((value as any) instanceof Date)) || !args) return false;
    if (new Date(value as any).getTime() > new Date(args).getTime()) return true;
  } catch (e) {
    console.error(e);
    throw e;
  }
  return false;
};

const afterToday: Validator.RegisterCallback = (value: string | number | boolean) =>
  after(value, new Date().toString(), "");

Validator.register(
  "conditionals",
  (value, requirement) => {
    if (!Array.isArray(value) || (requirement !== "causes" && requirement !== "skill" && requirement !== "interest"))
      return false;

    if (requirement === "causes")
      return value.filter((v) => !!causes.find((c) => c.value === v)).length === value.length;
    if (requirement === "skill")
      return value.filter((v) => !!functions.find((c) => c.value === v)).length === value.length;
    if (requirement === "interest")
      return value.filter((v) => !!abilities.find((c) => c.value === v)).length === value.length;

    return false;
  },
  "is-required"
);

Validator.register("after", after, "after_date");
Validator.register("after_today", afterToday, "after_today");

const updateRules = {
  details: "required|string|max:1000",
  "conditionals.causes": "required|conditionals:causes", //support:slug,causes
  //"conditionals.skill": "conditionals:skill",
  //"conditionals.interest": "conditionals:interest",
  "address.city": "required|string",
  "address.state": "required|string",
  "address.country": "required|string",
  "address.coordinates": "required|array",
  "images_data.cover.original": "required",
  "images_data.gallery": "array",
  video_youtube_url:
    "regex:/^(?:https?://)?(?:www.)?(?:youtu.be/|youtube.com/(|v/|watch?v=|watch?.+&v=))((w|-){11})?$/",
  "impact.members": "required|integer|min:1",
  time_configurations: "required",

  /** Frontend only */
  "time_configurations.date_start": "required|date",
  "time_configurations.date_end": "required|date",
  "time_configurations.time_start": ["regex:/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/"],
  "time_configurations.time_end": ["regex:/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/"],
  "images_data.*.gallery": "string",
  "action.not-allowed-date-start-be-smaller-than-today": `required|date|after_today`,
};

const createRules = {
  ...updateRules,
  title: "required|string|min:3|max:50",

  video_youtube_url:
    "regex:/^(?:https?://)?(?:www.)?(?:youtu.be/|youtube.com/(|v/|watch?v=|watch?.+&v=))((w|-){11})?$/",

  /** Frontend only */
  features: "required|accepted",
};

const updateRoute: ValidationFieldSet<typeof updateRules> = [
  {
    field: "details",
    route: routes.wizard.project.action.edit.descriptionAction,
  },
  {
    field: "conditionals.causes",
    route: routes.wizard.project.action.edit.causes,
  },
  {
    field: [
      "address.city",
      "address.state",
      "address.country",
      "address.coordinates",
      "time_configurations",
      "time_configurations.date_start",
      "time_configurations.date_end",
      "time_configurations.time_start",
      "time_configurations.time_end",
      "action.not-allowed-date-start-be-smaller-than-today",
    ],
    route: routes.wizard.project.action.edit.aboutAction,
  },
  { field: "images_data.cover.original", route: routes.wizard.project.action.edit.coverImage },
  {
    field: ["images_data.gallery", "images_data.*.gallery"],
    route: routes.wizard.project.action.edit.pictures,
  },
];

const createRoutes: ValidationFieldSet<typeof createRules> = [
  {
    field: "title",
    route: routes.wizard.project.newAction.inputName,
  },
  {
    field: "features",
    route: routes.wizard.project.newAction.selectFeatures,
  },
  {
    field: "details",
    route: routes.wizard.project.newAction.descriptionAction,
  },
  {
    field: "conditionals.causes",
    route: routes.wizard.project.newAction.causes,
  },
  {
    field: [
      "address.city",
      "address.state",
      "address.country",
      "address.coordinates",
      "time_configurations",
      "time_configurations.date_start",
      "time_configurations.date_end",
      "time_configurations.time_start",
      "time_configurations.time_end",
      "action.not-allowed-date-start-be-smaller-than-today",
    ],
    route: routes.wizard.project.newAction.aboutAction,
  },
  { field: "images_data.cover.original", route: routes.wizard.project.newAction.coverImage },
  {
    field: ["images_data.gallery", "images_data.*.gallery"],
    route: routes.wizard.project.newAction.pictures,
  },
];

const actionValidation = {
  rules: {
    create: createRules,
    update: updateRules,
  },
  routes: {
    update: updateRoute,
    create: createRoutes,
  },
  mapErrorType: (error: string) => {
    switch (error) {
      case "action.not-allowed-date-start-be-smaller-than-today":
        return { [error]: [errorMessages.after_today] };
      default:
        return [];
    }
  },
};

export default actionValidation;
