import http from "config/http";
import { ResponseStatus, ResponseWithParams } from "../definitions";
import { AxiosResponse } from "axios";

interface FilterVolunteersContactsRequest {
  term: string;
  project_id: string;
}

export interface FilterVolunteersContactsInterface {
  name: string;
  photo: string;
  user_id: string;
}

export type FilterVolunteersContactsResponse = ResponseWithParams<ResponseStatus, FilterVolunteersContactsInterface[]>;

export default async ({
  term,
  project_id,
}: FilterVolunteersContactsRequest): Promise<AxiosResponse<FilterVolunteersContactsResponse>> => {
  return await http.get(`/chat/filter-volunteers?text=${term}&project_id=${project_id}`);
};
