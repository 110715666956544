import { AxiosResponse } from "axios";
import http from "config/http";
import { ResponseWithParams, ResponseStatus } from "../definitions";

export interface GetAccountDetailsRequest {}

export interface GetAccountDetailsResponseDate {
  exportDataScheduled?: "not_requested" | "pending";
}

export type GetAccountDetailsResponse = ResponseWithParams<ResponseStatus, GetAccountDetailsResponseDate>;

const getAccountDetails = async (): Promise<AxiosResponse<GetAccountDetailsResponse>> => {
  return http.get<GetAccountDetailsResponse>(`/users/account`);
};

export default getAccountDetails;
