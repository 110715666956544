import {
  listTeamsRequested,
  listTeamsFailed,
  listTeamsReceived,
  assignTeamsRequested,
  assignTeamsReceived,
  assignTeamsFailed,
} from "./index";
import http from "config/http";
import cookies from "core/cookies";

export function listTeams() {
  return async (dispatch) => {
    dispatch(listTeamsRequested());

    try {
      const response = await http.get(`/teams`);
      const customResponse = { status: "received", loading: false, data: response?.data || {} };
      dispatch(listTeamsReceived(customResponse));

      return customResponse;
    } catch (error) {
      dispatch(listTeamsFailed(error?.response || {}));
      throw error;
    }
  };
}

export function teamAssign(teamID) {
  return async (dispatch) => {
    dispatch(assignTeamsRequested());

    try {
      const response = await http.post(`/teams/program`, { program_invite_code: "futebol", soccer_team_id: teamID });
      const customResponse = { status: "received", loading: false, data: response?.data || {} };
      dispatch(assignTeamsReceived(customResponse));
      const user = cookies.user.get();
      cookies.user.set({ ...user, program_data: [...(user.program_data || []), { slug: "futebol" }] });

      return customResponse;
    } catch (error) {
      dispatch(assignTeamsFailed(error?.response || {}));
    }
  };
}
