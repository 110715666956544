import { em, rem } from "polished";
import React from "react";
import { ButtonProps } from "reactstrap";
import styled, { css } from "styled-components/macro";
import { transition } from "utils/animation";
import { Size } from "utils/types";

export type ButtonSize = Extract<Size, "sm" | "md">;

const fontSizes: Record<ButtonSize, number> = {
  sm: 14,
  md: 14,
};

const lineHeight: Record<ButtonSize, number> = {
  sm: 20,
  md: 16.59,
};

const paddings: Record<ButtonSize, { x: number; y: number }> = {
  sm: {
    x: 15,
    y: 4,
  },
  md: {
    x: 23,
    y: 14,
  },
};

const borderRadius: Record<ButtonSize, number> = {
  sm: 5,
  md: 6,
};

export interface ButtonBaseProps extends Omit<ButtonProps, "size"> {
  size?: ButtonSize;
  tag?: React.ElementType;
  block?: boolean;
}

const ButtonBase: React.FC<ButtonBaseProps> = ({ children, ...props }) => {
  return <CustomButton {...props}>{children}</CustomButton>;
};

export default ButtonBase;

const CustomButton = styled(({ size, tag: Tag, block, ...props }: ButtonBaseProps) =>
  Tag ? <Tag {...props} /> : <button {...props} />
)`
  display: inline-block;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: ${rem(2)} solid transparent;
  transition: ${transition.fast("color", "background-color", "border-color", "box-shadow")};

  ${({ block }) =>
    block &&
    css`
      width: 100%;
    `}

  ${({ size = "md" }) =>
    css`
      font-size: ${rem(fontSizes[size])};
      padding: ${rem(paddings[size]["y"])} ${rem(paddings[size]["x"])};
      line-height: ${rem(lineHeight[size])};
      border-radius: ${rem(borderRadius[size])};
    `}
`;
