import React, { useState, useCallback } from "react";
import styled from "styled-components/macro";
import Button from "components/Button/Button";
import PropTypes from "prop-types";
import { SimpleContainer as Container, CurrentStep, TitleStep, BaseInput, Infos, LinkStep } from "../commonStyles";
import { FormFeedback } from "reactstrap";
import { useTranslation } from "react-i18next";
import SignInFormBase from "components/SignInModal/SignInFormBase";
import { rem } from "polished";
import { modalOpen } from "core/pages/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { MODALS } from "utils/constants";

const FansLogin = ({ runStep, ...props }) => {
  const { t } = useTranslation("components");

  const { modalOpen } = props;
  const [wasSubmmited, setWasSubmmited] = useState(false);
  const [response, setResponse] = useState({
    errorType: "",
    isEmailError: false,
    isPasswordError: false,
    hasError: false,
    success: false,
  });

  const removeErros = () => {
    if (response.hasError)
      setResponse((prev) => ({ ...prev, isEmailError: false, isPasswordError: false, hasError: false }));
  };

  const handleResponseMemorized = useCallback((response) => {
    if (response.success !== true) {
      setWasSubmmited(false);
      setResponse(response);
    }
  }, []);

  return (
    <Container>
      <Infos>
        <CurrentStep>{t("modalQuizTeam.step", { current: 3, last: 3 })}</CurrentStep>
        <TitleStep>{t("modalQuizTeam.steps.login.title")}</TitleStep>
      </Infos>
      <CustomForm dispatchResponse={handleResponseMemorized} onDispatch={() => setWasSubmmited(true)}>
        <div>
          <BaseInput
            type="email"
            name="email"
            placeholder={t("signInModal.form.email.placeholder")}
            onChange={removeErros}
            disabled={wasSubmmited}
            invalid={response.isEmailError}
            required
          />
          <FormFeedback>{(response.hasError && t(`apiResponses.${response.errorType}`)) || ""}</FormFeedback>
        </div>
        <div>
          <BaseInput
            type="password"
            name="password"
            placeholder={t("signInModal.form.password.placeholder")}
            onChange={removeErros}
            disabled={wasSubmmited}
            invalid={response.isPasswordError}
            required
          />
          <FormFeedback>{(response.hasError && t(`apiResponses.${response.errorType}`)) || ""}</FormFeedback>
        </div>
        <ForgotPassword onClick={() => modalOpen(MODALS.FORGOT_PASSWORD)}>
          {t("signInModal.actions.recoverPassword")}
        </ForgotPassword>
        <ButtonContainer>
          <CustomButton disabled={wasSubmmited}>{t("modalQuizTeam.steps.login.form.submit")}</CustomButton>
        </ButtonContainer>
        {/** //TODO(Jeconias) Implements a way to restore the last modal when FORGOT_PASSWORD MODAL is closed. */}
        <LinkStep runStep={runStep} text={"signInModal.actions.signUp"} />
      </CustomForm>
    </Container>
  );
};

FansLogin.defaultProps = {
  onSuccess: () => {},
};

FansLogin.propTypes = {
  /** Close modal for the last step */
  closeModal: PropTypes.func.isRequired,
};

export default connect(null, (dispatch) => bindActionCreators({ modalOpen }, dispatch))(FansLogin);

const CustomForm = styled(SignInFormBase)`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const CustomButton = styled(Button)`
  margin-top: 30px;
  width: 65%;
  padding: 14px 0;
`;

const ForgotPassword = styled.span`
  display: block;
  margin-top: 12px;
  width: 100%;
  color: #00a032;
  font-size: ${rem(13)};
  letter-spacing: 0;
  line-height: ${rem(10)};
  text-align: right;
  cursor: pointer;
`;
