import http from "config/http";
import { ListResponse, NotificationType } from "core/api/definitions";
import queryString from "query-string";

interface RetrieveNotificationsRequest {
  pp?: number;
  pg?: number;
  dt?: string;
}

export interface RetrieveNotificationsResponse extends ListResponse<NotificationType> {}

const retrieveNotifications = async (data: RetrieveNotificationsRequest) => {
  const qs = queryString.stringify(data);
  return http.get<RetrieveNotificationsResponse>(`/notifications${qs ? `?${qs}` : ""}`);
};

export default retrieveNotifications;
