import { createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import { PlatformContentShowRequest, PlatformContentShowResponse } from "core/api/admin/platforms/content/show";
import { UpdatePlatformContentResponse } from "core/api/admin/platforms/content/updateContent";
import { Ignored, PayloadActionSupport, RequestableState, ResponseWithParams } from "core/api/definitions";
import { showContent, updateContent } from "./action";

type IState = {
  update: UpdatePlatformContentResponse & RequestableState;
  show: {
    [index: string]: Partial<PlatformContentShowResponse & RequestableState>;
  };
};

type ActionType = PayloadActionSupport<PlatformContentShowRequest, PlatformContentShowResponse>;

const panelBetaContentPlatformSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "panelBetaContentPlatform",
  initialState: {
    update: {
      loading: "idle",
    },
    show: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateContent.pending.toString(), (state) => {
      state.update.loading = "loading";
    });
    builder.addCase(updateContent.fulfilled.toString(), (state) => {
      state.update.loading = "ok";
    });
    builder.addCase(updateContent.rejected.toString(), (state) => {
      state.update.loading = "error";
    });

    // Show
    builder.addCase(showContent.pending.toString(), (state, action: ActionType) => {
      if (!action.meta.arg.platformId) return;

      if (state.show[action.meta.arg.platformId]) {
        state.show[action.meta.arg.platformId].loading = "loading";
      } else {
        state.show = {
          ...state.show,
          [action.meta.arg.platformId]: {
            loading: "loading",
          },
        };
      }
    });
    builder.addCase(
      showContent.fulfilled.toString(),
      (
        state,
        action: PayloadActionSupport<
          PlatformContentShowRequest,
          ResponseWithParams<Ignored, PlatformContentShowResponse>
        >
      ) => {
        if (!action.meta.arg.platformId) return;

        if (state.show[action.meta.arg.platformId]) {
          state.show[action.meta.arg.platformId] = {
            ...state.show[action.meta.arg.platformId],
            ...action.payload.params,
            loading: "ok",
          };
        } else {
          state.show = {
            ...state.show,
            [action.meta.arg.platformId]: {
              loading: "ok",
            },
          };
        }
      }
    );
    builder.addCase(showContent.rejected.toString(), (state, action: ActionType) => {
      if (!action.meta.arg.platformId) return;

      if (state.show[action.meta.arg.platformId]) {
        state.show[action.meta.arg.platformId].loading = "error";
      } else {
        state.show = {
          ...state.show,
          [action.meta.arg.platformId]: {
            loading: "error",
          },
        };
      }
    });
  },
});

export default panelBetaContentPlatformSlice;
