import React, { useEffect } from "react";
import { SimpleContainer, Text, TitleStep } from "../commonStyles";
import { teamAssign } from "core/teams/operations";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Loading from "components/Loading/LoadingRequest";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

const FansLogged = ({ teamID, teamAssign, assigned }) => {
  const { t } = useTranslation("components");
  const isLoading = assigned.loading && assigned.status !== "received";
  const isRegistered = assigned?.data?.response?.message === "program.user.already_registered";

  useEffect(() => {
    (async () => {
      try {
        await teamAssign(teamID);
      } catch (error) {}
    })();
  }, [teamID, teamAssign]);

  return (
    <SimpleContainer>
      <TitleStep>{t("modalQuizTeam.steps.fansLogged.title")}</TitleStep>
      {isLoading && (
        <>
          <Text>{t("modalQuizTeam.steps.fansLogged.loading")}</Text>
          <CustomLoading />
        </>
      )}
      {!isLoading && assigned.status === "received" && <Text>{t("modalQuizTeam.steps.fansLogged.success")}</Text>}
      {!isLoading &&
        assigned.status === "failed" &&
        (isRegistered ? (
          <Text>{t("modalQuizTeam.steps.fansLogged.isRegistered.yes")}</Text>
        ) : (
          <Text>{t("modalQuizTeam.steps.fansLogged.error")}</Text>
        ))}
    </SimpleContainer>
  );
};

FansLogged.defaultProps = {};

FansLogged.propTypes = {
  teamID: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  assigned: state?.teams?.assign || { loading: false, status: "failed", data: {} },
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ teamAssign }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FansLogged);

const CustomLoading = styled(Loading)`
  min-height: auto;

  div:first-child {
    width: 50px;
    height: 50px;
  }
`;
