import React from "react";
import styled, { css } from "styled-components/macro";
import mediaQueries from "utils/mediaQueries";

interface LogoProps {
  isAdmin?: boolean;
  isSticky?: boolean;
  isSimpleMode?: boolean;
  title?: string;
  src: string;
  alt: string;
  width?: string;
  height?: string;
}

const Logo: React.FC<LogoProps> = ({ isAdmin, isSticky, isSimpleMode, src, title, alt, ...props }) => (
  <Img isAdmin={isAdmin} isSticky={isSticky} isSimpleMode={isSimpleMode} src={src} title={title} alt={alt} {...props} />
);

export default Logo;

const Img = styled.img<{ isSticky?: boolean; isSimpleMode?: boolean; isAdmin?: boolean }>`
  transition: height 0.3s ease;
  max-height: 45px;

  ${(props) =>
    (props.isSticky || props.isSimpleMode) &&
    css`
      max-height: 50px;
    `}

  ${mediaQueries.sm(css`
    width: auto;
    height: 33px;
  `)}

  ${({ isSticky }) =>
    isSticky &&
    css`
      ${mediaQueries.md(
        css`
          width: 50px;
          height: auto;
        `,
        true
      )}
    `}

    ${(props) =>
    props.isAdmin
      ? css`
          width: auto;
          height: 29px;
        `
      : null}
`;
