import http from "config/http";
import { ResponseStatus, ResponseWithParams } from "../definitions";
import { Contact } from "../definitions";
import { AddressCoordinates } from "core/api/definitions";
import { AxiosResponse } from "axios";
import { UniqueDocument } from "./getGeneralData";

export interface UserDataLocationUpdateGeneralDataRequest {
  street?: string;
  number?: string;
  complement?: string;
  district?: string;
  state: string;
  city: string;
  country: string;
  zipcode?: string;
  coordinates: AddressCoordinates;
}

export interface UpdateGeneralDataRequest {
  name?: string;
  description?: string;
  locations?: UserDataLocationUpdateGeneralDataRequest;
  profile_image?: string;
  contacts?: Contact[];
  unique_document?: UniqueDocument;
}

export type UpdateGeneralDataResponse = ResponseWithParams<ResponseStatus, any>;

const updateGeneralData = async (data: UpdateGeneralDataRequest): Promise<AxiosResponse<UpdateGeneralDataResponse>> => {
  return http.patch<UpdateGeneralDataResponse>(`/v3/users`, data);
};

export default updateGeneralData;
