import { AxiosResponse } from "axios";
import http from "config/http";
import { Response, ResponseStatus } from "../definitions";

export interface emailCheckRequest {
  email: string;
}

export type emailCheckResponse = Response<ResponseStatus>;

const emailCheck = async ({ email }: emailCheckRequest): Promise<AxiosResponse<emailCheckResponse>> => {
  return http.post<emailCheckResponse>(`/users/emailCheck`, {
    email,
  });
};

export default emailCheck;
