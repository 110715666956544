import { createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import { LoadingStatus, Meta, MetaArg } from "core/api/definitions";
import { SubscribeShowData, SubscribeShowRequest, SubscribeShowResponse } from "core/api/subscribe/show";
import decoreThunk from "core/decorators/decorate";
import { subscribeActions } from "./subscribe_actions";

interface SubscribeSliceState {
  subscribes: {
    [slug: string]: {
      loading: LoadingStatus;
      data?: SubscribeShowData;
    };
  };
}

const subscribeSlice = createSlice<SubscribeSliceState, SliceCaseReducers<SubscribeSliceState>>({
  name: "subscribe",
  initialState: {
    subscribes: {},
  },
  reducers: {},
  extraReducers(builder) {
    decoreThunk(builder, subscribeActions.get, [
      {
        pending: (state, action: PayloadAction<any, string, MetaArg<SubscribeShowRequest>>) => {
          if (!state.subscribes[action.meta.arg.slug]) {
            state.subscribes[action.meta.arg.slug] = {
              loading: "loading",
            };
          } else {
            state.subscribes[action.meta.arg.slug].loading = "loading";
          }
        },
        fulfilled: (state, action: PayloadAction<SubscribeShowResponse, string, MetaArg<SubscribeShowRequest>>) => {
          state.subscribes[action.payload.params!.subscribe_data.slug] = {
            loading: "ok",
            data: action.payload.params,
          };
        },
        rejected: (state, action: PayloadAction<any, string, MetaArg<SubscribeShowRequest>>) => {
          if (!state.subscribes[action.meta.arg.slug]) {
            state.subscribes[action.meta.arg.slug] = {
              loading: "error",
            };
          } else {
            state.subscribes[action.meta.arg.slug].loading = "error";
          }
        },
      },
    ]);
  },
});

export default subscribeSlice;
