import styled, { css } from "styled-components/macro";
import mediaQueries from "utils/mediaQueries";

export const ListContainer = styled.div`
  position: relative;
  margin-top: ${({ theme }) => theme.v3.spacing.lg};
  height: 400px;
  width: 100%;
  border: 1px solid #e6eaf6;
  border-radius: 6px;
  background-color: #ffffff;
  padding: 0;
  padding: 30px 0 20px;
  list-style: none;
  overflow-x: hidden;

  ul {
    padding: 0;
  }
`;

export const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid #e6eaf6;
  max-width: 90%;
  margin: 0 auto;

  ${mediaQueries.sm(css`
    max-width: 85%;
  `)}
  ${mediaQueries.xs(css`
    padding: 10px 0;
    height: auto;
  `)}
`;
