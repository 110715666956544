import socialCurriculum from "./socialCurriculum";
import emailCheck from "./emailCheck";
import storeNewUser from "./storeNewUser";
import storeNewUserWithDocument from "./storeNewUserWithDocument";
import sendEmailValidationCode from "./sendEmailValidationCode";
import validateCurriculum from "./validateCurriculum";
import getGeneralData from "./getGeneralData";
import updateGeneralData from "./updateGeneralData";
import listMaterialsRegistrations from "./listMaterialsRegistrations";
import listMaterialsRegistrationsByUserDetail from "./listMaterialsRegistrationsByUserDetail";
import getAllPreferences from "./getAllPreferences";
import updatePreferences from "./updatePreferences";
import privacy from "./privacy";
import account from "./account";
import general from "./general";
import resumeProfile from "./resumeProfile";
import security from "./security";
import getAccountDetails from "./getAccountDetails";
import update from "./update";
import resendEmailValidationCode from "./resendEmailValidationCode";
import emailCheckIdentity from "./emailCheckIdentity";
import listVolunteersAppointment from "./listVolunteersNextAppointments";
import listDetailVolunteerAppointmentsModal from "./listDetailVolunteerAppointmentsModal";
import listDetailsRegistrationModal from "./listDetailsRegistrationModal";
import validateSelectedVolunteers from "./validateSelectedVolunteers";
import updateDocument from "./updateDocument";

export default {
  sendEmailValidationCode,
  socialCurriculum,
  storeNewUser,
  storeNewUserWithDocument,
  emailCheck,
  getGeneralData,
  updateGeneralData,
  getAllPreferences,
  updatePreferences,
  validateCurriculum,
  listMaterialsRegistrations,
  listMaterialsRegistrationsByUserDetail,
  privacy,
  account,
  general,
  resumeProfile,
  security,
  getAccountDetails,
  update,
  resendEmailValidationCode,
  emailCheckIdentity,
  listVolunteersAppointment,
  listDetailVolunteerAppointmentsModal,
  listDetailsRegistrationModal,
  validateSelectedVolunteers,
  updateDocument,
};
