import icons, { IconType } from "icons";
import React from "react";
import styled, { css } from "styled-components/macro";
import { transition } from "utils/animation";

import { ColorV3 } from "utils/types";

type IconColor = ColorV3;

const iconSizes = {
  sm: 16,
  md: 24,
};

type IconSize = keyof typeof iconSizes;

export interface IconProps {
  className?: string;
  id?: string;
  icon: IconType;
  color?: IconColor;
  size?: IconSize;
  onClick?(): void;
}

const IconComp = ({ icon, className, onClick }: IconProps) => {
  const Tag = icons[icon];
  return <Tag className={className} onClick={onClick} />;
};

const Icon = styled(IconComp)(
  ({ theme, color, size }) =>
    css`
      .svg-fill {
        fill: ${theme.v3.colors[color ?? "neutralLight"]};
        transition: ${transition.fast("fill")};
      }

      ${size &&
      css`
        width: ${iconSizes[size]}px;
        height: ${iconSizes[size]}px;
      `}
    `
);

export default Icon;
