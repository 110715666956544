import queryString from "query-string";
import http from "config/http";
import { Project, ListResponse, Subscribe, Action, ActionData, ProjectData } from "core/api/definitions";
import { AxiosResponse } from "axios";

export interface SearchActionRequest {
  pg?: number;
  pp?: number;
  slugTag: string;
  term: string;
}

export interface SearchActionResponseInterface {
  action_data: ActionData;
  created_at: string;
  level: string;
  project_data: ProjectData;
  subscribe: Subscribe;
  updated_at: string;
  visible: boolean;
}

export type SearchActionResponse = ListResponse<SearchActionResponseInterface>;

export default async ({ pg, pp, slugTag, term }: SearchActionRequest): Promise<AxiosResponse<SearchActionResponse>> => {
  const fs: any = {};
  if (pg) fs.pg = pg;
  if (pp) fs.pp = Math.max(5, pp);
  if (term) fs.term = term;

  return http.get<SearchActionResponse>(`/admin/tags/${slugTag}/actions/nonTagged?${queryString.stringify(fs)}`);
};
