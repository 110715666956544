import React, { useState, useEffect, useRef, SyntheticEvent } from "react";
import styled, { css } from "styled-components/macro";

interface LazyImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {}

type ImageState = "loaded" | "error";

const placeHolder =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQYV2N4//79fwAJawPNY7nVIAAAAABJRU5ErkJggg==";

const LazyImage = ({ src, ...props }: LazyImageProps): JSX.Element => {
  const [imageSrc, setImageSrc] = useState(placeHolder);
  const [imageState, setImageState] = useState<ImageState | undefined>(undefined);
  const imageRef = useRef<HTMLImageElement>(null);

  const onLoad = () => setImageState("loaded");

  const onError = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    //TODO: Send do senty
    console.error(e);
    setImageState("error");
  };

  useEffect(() => {
    let observer: IntersectionObserver;
    let didCancel = false;
    const image = imageRef.current;

    if (image && imageSrc !== src) {
      if (IntersectionObserver) {
        observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (image && !didCancel && (entry.intersectionRatio > 0 || entry.isIntersecting) && src) {
                setImageSrc(src);
                observer.unobserve(image);
              }
            });
          },
          {
            threshold: 0.01,
            rootMargin: "75%",
          }
        );
        observer.observe(image);
      } else {
        // Old browsers fallback
        setImageSrc(src || "");
      }
    }
    return () => {
      didCancel = true;
      // on component cleanup, we remove the listner
      if (image && observer && observer.unobserve) {
        observer.unobserve(image);
      }
    };
  }, [src, imageSrc, imageRef]);
  return <Image ref={imageRef} src={imageSrc} onLoad={onLoad} onError={onError} {...props} state={imageState} />;
};

export default LazyImage;

const Image = styled.img<{ state?: ImageState }>`
  @keyframes loaded {
    0% {
      opacity: 0.1;
    }
    100% {
      opacity: 1;
    }
  }

  ${({ state }) =>
    state &&
    state === "loaded" &&
    css`
      animation: loaded 300ms ease-in-out;
    `}

  ${({ state }) =>
    state &&
    state === "error" &&
    css`
      content: url(${placeHolder});
    `}
`;
