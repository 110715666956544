import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components/macro";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { SimpleContainer, Step, BaseButton, TitleStep } from "../commonStyles";
import { listTeams } from "core/teams/operations";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SelectWithSearch from "components/Select/SelectWithSearch";
import { Arrow, BaseSelect } from "panel/components/Select/Select";

const SelectTeam = ({ nextStep, selectTeam, currentTeam, ...props }) => {
  const { t } = useTranslation("components");
  const { listTeams } = props;

  const [teamsResponse, setTeamsResponse] = useState({});
  const [isRequired, setIsRequired] = useState(false);
  const [loading, setLoading] = useState(true);

  const teamsResponseData = teamsResponse?.data?.params || [];

  useEffect(() => {
    let cancelEffect = false;
    (async () => {
      try {
        const response = await listTeams();
        if (!cancelEffect) setTeamsResponse(response);
      } catch (error) {
        if (!cancelEffect) setTeamsResponse({ status: "failed", loading: false, data: {} });
      } finally {
        setLoading(false);
      }
    })();
    return () => {
      cancelEffect = true;
    };
  }, [listTeams]);

  return (
    <SimpleContainer>
      <Step>{t("modalQuizTeam.steps.selectTeam.currentStep")}</Step>
      <CustomTitle>{t("modalQuizTeam.steps.selectTeam.title")}</CustomTitle>
      <SelectWithSearchStyled
        isRequired={isRequired}
        isLoading={loading}
        teams={teamsResponseData}
        onSelectTeam={(teamID) => selectTeam(teamID)}
        placeholder={t("modalQuizTeam.steps.selectTeam.placeholder")}
      />
      {isRequired && <Message>{t("modalQuizTeam.steps.selectTeam.messages.required")}</Message>}
      {teamsResponse?.status === "failed" && <Message>{t("modalQuizTeam.steps.selectTeam.messages.error")}</Message>}
      <CustomButton
        onClick={() => {
          const wasSelected = teamsResponseData.find((team) => team._id === currentTeam) === undefined;
          setIsRequired(wasSelected);
          if (!wasSelected) nextStep();
        }}
        disabled={loading}
      >
        {t("modalQuizTeam.buttonContinue")}
      </CustomButton>
    </SimpleContainer>
  );
};

SelectTeam.propTypes = {
  nextStep: PropTypes.func.isRequired,
  selectTeam: PropTypes.func.isRequired,
  currentTeam: PropTypes.string.isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ listTeams }, dispatch);

export default connect(null, mapDispatchToProps)(SelectTeam);

const SelectContainer = styled.div`
  position: relative;
  margin-bottom: 40px !important;
  padding: 0 !important;
  select {
    padding: 8px 25px 8px 20px;
    height: 58px;
  }
`;

const CustomSelect = styled(({ className, isRequired, children, ...props }) => (
  <SelectContainer className={className}>
    <Arrow />
    <BaseSelect {...props}>{children}</BaseSelect>
  </SelectContainer>
))`
  margin: 0 !important;
  padding: 15px 85px 15px 20px;

  div:first-child {
    ${({ isRequired }) =>
      isRequired &&
      css`
        opacity: 0;
      `}
  }
`;

const CustomTitle = styled(TitleStep)`
  padding: 0 55px 40px 55px;
`;

const CustomButton = styled(BaseButton)`
  margin-top: 30px;
`;

const Message = styled.p`
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
`;

const SelectWithSearchStyled = styled(SelectWithSearch)`
  & > input {
    padding: 8px 25px 8px 20px;
    height: 58px;
  }

  & > ul {
    top: 58px;
  }
`;
