import React, { ErrorInfo } from "react";
import * as Sentry from "@sentry/browser";
import { SubText, Text } from "components/Errors/Title";
import { withRouter } from "react-router-dom";
import styled from "styled-components/macro";
import { RouteComponentProps } from "react-router";
import Button from "v3/components/Button/Button";

interface ErrorCatcherProps extends RouteComponentProps<{}> {
  className?: string;
}

interface ErrorCatcherState {
  hasError?: boolean;
  error?: Error;
  errorInfo?: ErrorInfo;
}

class ErrorCatcher extends React.Component<ErrorCatcherProps, ErrorCatcherState> {
  constructor(props: ErrorCatcherProps) {
    super(props);

    this.state = {};
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo as any);
      Sentry.captureException(error);
      this.setState({ error, errorInfo });
    });
  }

  render() {
    const { hasError } = this.state;
    const { history, children, className } = this.props;

    if (hasError) {
      return (
        <ErrorCatcherContainer className={className}>
          <Text>Ocorreu um erro ao exibir esta página.</Text>
          <SubText>Um relatório com o erro foi enviado automaticamente para a Transforma Brasil.</SubText>
          <Actions>
            <Button color="primary" onClick={() => history.goBack()}>
              Voltar
            </Button>{" "}
            <Button color="primary" onClick={() => window.location.reload()}>
              Tentar novamente
            </Button>
          </Actions>
        </ErrorCatcherContainer>
      );
    }
    return <>{children}</>;
  }
}

const ErrorCatcherContainer = styled.div`
  padding: 60px 0;
`;

const Actions = styled.div`
  margin-top: 30px;
  text-align: center;
`;

export default withRouter(ErrorCatcher);
