import http from "config/http";
import { ResponseStatus, ResponseWithParams } from "../definitions";
import { ConditionalFunction, ConditionalInterestDescription } from "../definitions";
import { TimeConfigurations } from "core/api/definitions";
import { AxiosResponse } from "axios";

export interface CreateRequest {
  action: string;
}

export interface CreateResponseParams {
  functions: ConditionalFunction[];
  interests: ConditionalInterestDescription[];
  action_data: {
    slug: string;
    title: string;
    time_configurations: TimeConfigurations;
  };
  project_data: {
    image: string | null;
    name: string;
    slug: string;
  };
}

export type CreateResponse = ResponseWithParams<ResponseStatus, CreateResponseParams>;

const create = async ({ action }: CreateRequest): Promise<AxiosResponse<CreateResponse>> =>
  http.get<CreateResponse>(`/actions/${action}/subscribe/create`);

export default create;
