import Alert from "components/Alert/Alert";
import React, { useCallback, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { animated, Transition } from "react-spring/renderprops";
import styled from "styled-components/macro";
import { Z_INDEX } from "utils/constants";
import { useReduxSelector } from "hooks/useReduxSelector";
import useReduxDispatch from "hooks/useReduxDispatch";
import { hideFeedback } from "core/alerts/actions";

const AlertManager: React.FC = () => {
  const dispatch = useReduxDispatch();

  const container = useRef(document.createElement("div"));

  const { alerts } = useReduxSelector((state) => ({
    alerts: state.alerts.feedbacks,
  }));

  useEffect(() => {
    const el = container.current;

    el.id = "alert-manager-root";
    document.body.appendChild(el);

    return () => {
      document.body.removeChild(el);
    };
  }, []);

  const handleDismiss = useCallback(
    (id: number) => {
      dispatch(hideFeedback({ id }));
    },
    [dispatch]
  );

  useEffect(() => {
    const firstAlert = alerts.length > 0 ? alerts[0] : undefined;
    let timeID: NodeJS.Timeout;

    if (firstAlert && firstAlert.ms !== 0) {
      timeID = setTimeout(
        () => {
          handleDismiss(firstAlert.id);
        },
        typeof firstAlert.ms !== "number" ? 3000 : firstAlert.ms
      );
    }

    return () => {
      clearTimeout(timeID);
    };
  }, [alerts, handleDismiss]);

  return (
    <>
      {ReactDOM.createPortal(
        <Container>
          <Transition
            items={alerts}
            from={{ overflow: "hidden", height: 0, opacity: 0 }}
            enter={{ height: 50, opacity: 1 }}
            leave={{ height: 0, opacity: 0 }}
            trail={200}
            keys={(item) => item.id}
          >
            {({ id, type, message }) => (styles) => (
              <Animated style={styles}>
                <Alert type={type} message={message} onDismiss={() => handleDismiss(id)} />
              </Animated>
            )}
          </Transition>
        </Container>,
        container.current
      )}
    </>
  );
};

export default AlertManager;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${Z_INDEX.ALERT};
`;

const Animated = styled(animated.div)`
  overflow: hidden;
`;
