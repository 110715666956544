import userTypes from "./types";

// Create Volunteer
export const cvtRequested = () => ({
  type: userTypes.CVT_REQUESTED,
});

export const cvtReceived = (payload) => ({
  type: userTypes.CVT_RECEIVED,
  payload,
});

export const cvtFailed = (payload) => ({
  type: userTypes.CVT_FAILED,
  payload,
});

// Login
export const lgnRequested = () => ({
  type: userTypes.LGN_REQUESTED,
});

export const lgnReceived = (payload, email) => {
  let r = {
    type: userTypes.LGN_RECEIVED,
    payload,
    email,
  };
  let location;
  if (typeof payload.redirect === "string") {
    location = { pathname: payload.redirect };
  } else {
    location = payload.redirect;
  }
  if (payload.redirect) r = { ...r, urlUpdates: { location } };
  return r;
};

export const lgnFailed = (payload) => ({
  type: userTypes.LGN_FAILED,
  payload,
});

// Get logged user
export const usrRequested = () => ({
  type: userTypes.USR_REQUESTED,
});

export const usrReceived = (payload) => ({
  type: userTypes.USR_RECEIVED,
  payload,
});

export const usrFailed = (payload) => ({
  type: userTypes.USR_FAILED,
  payload,
});

// Getting support data
export const usdRequested = () => ({
  type: userTypes.USD_REQUESTED,
});

export const usdReceived = (payload) => ({
  type: userTypes.USD_RECEIVED,
  payload,
});

export const usdFailed = (payload) => ({
  type: userTypes.USD_FAILED,
  payload,
});

// User to edit
export const useRequested = () => ({
  type: userTypes.USE_REQUESTED,
});

export const useReceived = (payload) => ({
  type: userTypes.USE_RECEIVED,
  payload,
});

export const useFailed = (payload) => ({
  type: userTypes.USE_FAILED,
  payload,
});

// user Put
export const uspRequested = () => ({
  type: userTypes.USP_REQUESTED,
});

export const uspReceived = (payload) => ({
  type: userTypes.USP_RECEIVED,
  payload,
});

export const uspFailed = (payload) => ({
  type: userTypes.USP_FAILED,
  payload,
});

// User profile
export const upRequested = () => ({
  type: userTypes.UP_REQUESTED,
});

export const upReceived = (payload) => ({
  type: userTypes.UP_RECEIVED,
  payload,
});

export const upFailed = (payload) => ({
  type: userTypes.UP_FAILED,
  payload,
});

export const cleanUserData = () => ({
  type: userTypes.CLEAN_USER_DATA,
});

export const usvRequested = () => ({
  type: userTypes.USV_REQUESTED,
});

export const usvReceived = (payload) => ({
  type: userTypes.USV_RECEIVED,
  payload,
});

export const usvFailed = (payload) => ({
  type: userTypes.USV_FAILED,
  payload,
});

// Getting new user token
export const usntRequested = () => ({
  type: userTypes.USNT_REQUESTED,
});

export const usntReceived = (payload) => ({
  type: userTypes.USNT_RECEIVED,
  payload,
});

export const usntFailed = (payload) => ({
  type: userTypes.USNT_FAILED,
  payload,
});

export const rpaRequested = () => ({
  type: userTypes.RPA_REQUESTED,
});

export const rpaReceived = (payload) => ({
  type: userTypes.RPA_RECEIVED,
  payload,
});

export const rpaFailed = (payload) => ({
  type: userTypes.RPA_FAILED,
  payload,
});

export const grpRequested = () => ({
  type: userTypes.GRP_REQUESTED,
});

export const grpReceived = (payload) => ({
  type: userTypes.GRP_RECEIVED,
  payload,
});

export const grpFailed = (payload) => ({
  type: userTypes.GRP_FAILED,
  payload,
});

export const rnpRequested = () => ({
  type: userTypes.RNP_REQUESTED,
});

export const rnpReceived = (payload) => ({
  type: userTypes.RNP_RECEIVED,
  payload,
});

export const rnpFailed = (payload) => ({
  type: userTypes.RNP_FAILED,
  payload,
});
