import login from "./login";
import signUpGmailUrl from "./SignUpGmailUrl";
import signInGmailUrl from "./SignInGmailUrl";
import signInUpGmailToken from "./SignInUpGmailToken";
import forgotPassword from "./forgotPassword";
import resetPassword from "./resetPassword";

export default {
  login,
  signUpGmailUrl,
  signInGmailUrl,
  signInUpGmailToken,
  forgotPassword,
  resetPassword,
};
