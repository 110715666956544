import { AxiosResponse } from "axios";
import http from "config/http";
import { ResponseWithParams, ResponseStatus, FunctionData, ODSDataRef } from "core/api/definitions";
import { SubscribeData } from "core/api/definitions";
import { ActionData } from "../definitions";
import { ProjectData } from "../definitions";

export interface SubscribeShowRequest {
  slug: string;
}

export interface SubscribeShowData {
  action_data: ActionData;
  project_data: ProjectData;
  subscribe_data: SubscribeData;
  function_data: FunctionData;
  ods_data: ODSDataRef[];
}

export type SubscribeShowResponse = ResponseWithParams<ResponseStatus, SubscribeShowData>;

export default ({ slug }: SubscribeShowRequest) => {
  return http.get<SubscribeShowResponse>(`/subscribe/${slug}/show`);
};
