import { AxiosResponse } from "axios";
import http from "config/http";
import { ActionResponse } from "core/api/definitions";
import { CertificateGenerated } from "../definitions";

export type GenerateCertificatesByCodeRequest = string;
export type GenerateCertificatesByCodeResponse = ActionResponse<CertificateGenerated[]>;

export default async (
  code: GenerateCertificatesByCodeRequest
): Promise<AxiosResponse<GenerateCertificatesByCodeResponse>> =>
  http.get<GenerateCertificatesByCodeResponse>(`/validation/certificate/${code}`);
