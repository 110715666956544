import http from "config/http";
import { AxiosResponse } from "axios";
import { ResponseStatus, ResponseWithParams } from "../definitions";

export interface DeleteRequest {
  slug: string;
}

export interface DeleteResponseParams {}

export type DeleteResponse = ResponseWithParams<ResponseStatus, DeleteResponseParams>;

const destroy = async ({ slug }: DeleteRequest): Promise<AxiosResponse<DeleteResponse>> =>
  http.delete<DeleteResponse>(`/subscribe/${slug}/destroyItem`);

export default destroy;
