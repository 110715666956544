import actionTypes from "./types";
import createReducer from "../createReducer";
import { axiosDefaultError } from "../../utils/helper";

const INITIAL_STATE = {
  ods: {
    loading: true,
    causes: [],
  },
  crt: {},
  gae: {
    loading: true,
    project_data: {
      title: "",
      images: {
        profile: "",
      },
    },
    action_data: {
      title: "",
      slug: "",
      details: "",
      description: "",
      images: {
        logo: "",
        cover: "",
        gallery: [],
      },
      video_youtube_url: [],
      social_network: {
        facebook: "",
        instagram: "",
        pinterest: "",
        website: "",
      },
      conditionals: {
        causes: [],
        occupation_area: [],
        objectives: [],
        interests: [],
      },
      impact: {
        members: 0,
      },
      locations: {
        street: "",
        name: "",
        coordinates: [],
        city: "",
        state: "",
        country: "",
      },
      time_configurations: {
        date_start: "",
        date_end: "",
      },
    },
  },
  actions: {
    loading: true,
    status: "",
  },
  volunteers: {
    loading: false,
    status: "",
  },
  volunteersBO: {
    loading: false,
    status: "",
  },
  volunteersCO: {
    loading: false,
    status: "",
  },
  volunteerDetails: {},
  adminActions: {
    loading: false,
    status: "",
  },
  validateAction: {
    loading: false,
    status: "",
  },
};

const actionReducer = createReducer(INITIAL_STATE, {
  [actionTypes.ODS_REQUESTED]: (state) => ({
    ...state,
    ods: {
      loading: true,
      status: "waiting",
    },
  }),
  [actionTypes.ODS_RECEIVED]: (state, action) => ({
    ...state,
    ods: {
      ...action.payload,
      loading: false,
      status: "received",
    },
  }),
  [actionTypes.ODS_FAILED]: (state, action) => ({
    ...state,
    ods: {
      ...INITIAL_STATE.ods,
      loading: false,
      status: "failed",
      error: {
        ...axiosDefaultError,
        ...action.payload,
      },
    },
  }),
  [actionTypes.ACTION_REQUESTED]: (state) => ({
    ...state,
    crt: {
      loading: true,
      status: "waiting",
    },
  }),

  [actionTypes.ACTION_RECEIVED]: (state, action) => ({
    ...state,
    crt: {
      ...action.payload,
      loading: false,
      status: "received",
      success: true,
    },
  }),
  [actionTypes.ACTION_FAILED]: (state, action) => ({
    ...state,
    crt: {
      loading: false,
      status: "failed",
      success: false,
      ...axiosDefaultError,
      ...action.payload,
    },
  }),
  [actionTypes.GAE_REQUESTED]: (state) => ({
    ...state,
    gae: {
      ...INITIAL_STATE.gae,
      loading: true,
      status: "waiting",
    },
  }),

  [actionTypes.GAE_RECEIVED]: (state, action) => ({
    ...state,
    gae: {
      ...action.payload,
      loading: false,
      status: "received",
      success: true,
    },
  }),
  [actionTypes.GAE_FAILED]: (state, action) => ({
    ...state,
    gae: {
      loading: false,
      status: "failed",
      success: false,
      ...axiosDefaultError,
      ...action.payload,
    },
  }),
  [actionTypes.AE_REQUESTED]: (state) => ({
    ...state,
    crt: {
      loading: true,
      status: "waiting",
    },
  }),

  [actionTypes.AE_RECEIVED]: (state, action) => ({
    ...state,
    crt: {
      ...action.payload,
      loading: false,
      status: "received",
      success: true,
    },
  }),
  [actionTypes.AE_FAILED]: (state, action) => ({
    ...state,
    crt: {
      loading: false,
      status: "failed",
      success: false,
      ...axiosDefaultError,
      ...action.payload,
    },
  }),
  [actionTypes.CLEAN_ACTION_DATA]: (state) => ({
    ...state,
    crt: {
      ...INITIAL_STATE.crt,
    },
  }),

  // Panel actions
  // =============

  // Actions by Project
  // ------------------

  // IPAP Actions
  [actionTypes.IPAP_REQUESTED]: (state) => ({
    ...state,
    actions: {
      loading: true,
      status: "waiting",
    },
  }),
  [actionTypes.IPAP_RECEIVED]: (state, action) => ({
    ...state,
    actions: {
      ...action.payload,
      success: true,
      loading: false,
      status: "received",
    },
  }),
  [actionTypes.IPAP_FAILED]: (state, action) => ({
    ...state,
    actions: {
      ...action.payload,
      loading: false,
      status: "failed",
    },
  }),

  // NPAP Actions

  [actionTypes.NPAP_REQUESTED]: (state) => ({
    ...state,
    actions: {
      ...state.actions,
      loadingNP: true,
      status: "waiting",
    },
  }),
  [actionTypes.NPAP_RECEIVED]: (state, { payload: { response, ...payload } }) => ({
    ...state,
    actions: {
      ...payload,
      response: state.actions.response.concat(response),
      success: true,
      loadingNP: false,
      status: "received",
    },
  }),
  [actionTypes.NPAP_FAILED]: (state, action) => ({
    ...state,
    actions: {
      ...action.payload,
      loadingNP: false,
      status: "failed",
    },
  }),

  // Volunteers by Action
  // --------------------

  // Initial Page for listing volunteers by owner
  [actionTypes.IPVA_REQUESTED]: (state) => ({
    ...state,
    volunteers: {
      loading: true,
      status: "waiting",
    },
  }),
  [actionTypes.IPVA_RECEIVED]: (state, action) => ({
    ...state,
    volunteers: {
      ...action.payload,
      success: true,
      loading: false,
      status: "received",
    },
  }),
  [actionTypes.IPVA_FAILED]: (state, action) => ({
    ...state,
    volunteers: {
      ...action.payload,
      loading: false,
      status: "failed",
    },
  }),

  // Initial Page for listing volunteers by action
  [actionTypes.IPVAFO_REQUESTED]: (state) => ({
    ...state,
    volunteersBO: {
      loading: true,
      status: "waiting",
    },
  }),
  [actionTypes.IPVAFO_RECEIVED]: (state, action) => ({
    ...state,
    volunteersBO: {
      ...action.payload,
      success: true,
      loading: false,
      status: "received",
    },
  }),
  [actionTypes.IPVAFO_FAILED]: (state, action) => ({
    ...state,
    volunteersBO: {
      ...action.payload,
      loading: false,
      status: "failed",
    },
  }),

  // Initial Page for listing volunteers for checkout
  [actionTypes.CHECKOUT_VOLUNTEERS_REQUESTED]: (state) => ({
    ...state,
    volunteersCO: {
      loading: true,
      status: "waiting",
    },
  }),
  [actionTypes.CHECKOUT_VOLUNTEERS_RECEIVED]: (state, action) => ({
    ...state,
    volunteersCO: {
      ...action.payload,
      success: true,
      loading: false,
      status: "received",
    },
  }),
  [actionTypes.CHECKOUT_VOLUNTEERS_FAILED]: (state, action) => ({
    ...state,
    volunteersCO: {
      ...action.payload,
      loading: false,
      status: "failed",
    },
  }),

  // Get volunteer details for a subscription
  [actionTypes.GET_VOLUNTEER_DETAILS_REQUESTED]: ({ volunteerDetails, ...state }, { actionSlug, volunteerId }) => ({
    ...state,
    volunteerDetails: {
      ...volunteerDetails,
      // Sets the record to loading.
      loading: true,
      status: "",
    },
  }),
  [actionTypes.GET_VOLUNTEER_DETAILS_RECEIVED]: (
    { volunteerDetails, ...state },
    { actionSlug, volunteerId, payload }
  ) => ({
    ...state,
    volunteerDetails: {
      ...volunteerDetails,
      ...payload,
      success: true,
      loading: false,
      status: "received",
    },
  }),
  [actionTypes.GET_VOLUNTEER_DETAILS_FAILED]: ({ volunteerDetails, ...state }, { actionSlug, volunteerId }) => ({
    ...state,
    volunteerDetails: {
      ...volunteerDetails,
      success: false,
      loading: false,
      status: "failed",
    },
  }),
  [actionTypes.REMOVE_VOLUNTEER_FROM_LIST_BY_USER]: (state, { userId, isVolunteerScreen, subscribeSlug }) => {
    if (isVolunteerScreen) {
      const k = isVolunteerScreen === "co" ? "volunteersCO" : "volunteersBO";
      switch (k) {
        case "volunteersCO":
          return {
            ...state,
            [k]: {
              ...state[k],
              response: {
                ...state[k].response,
                result: ((state[k] && state[k].response && state[k].response.result) || []).filter(
                  (volunteer) =>
                    (volunteer.user_data && volunteer.user_data._id) !== userId.toString() &&
                    (!subscribeSlug || (volunteer.subscribe_data && subscribeSlug === volunteer.subscribe_data.slug))
                ),
              },
            },
          };
        case "volunteersBO":
          return {
            ...state,
            [k]: {
              ...state[k],
              response: {
                ...state[k].params,
                result: (state[k]?.params?.result || []).map((action) =>
                  action.subscribe_data.slug === subscribeSlug
                    ? {
                        ...action,
                        registration_data: action.registration_data.filter(
                          (registrationData) => registrationData?.user_data?._id !== userId
                        ),
                      }
                    : action
                ),
              },
            },
          };
        default:
      }
    }
  },
  [actionTypes.REMOVE_VOLUNTEER_FROM_LIST_BY_REGISTRATION_ID]: (state, { registrationId, isVolunteerScreen }) => {
    if (isVolunteerScreen) {
      const k = isVolunteerScreen === "co" ? "volunteersCO" : "volunteersBO";
      return {
        ...state,
        [k]: {
          ...state[k],
          response: {
            ...state[k].response,
            result: ((state[k] && state[k].response && state[k].response.result) || []).filter(
              (volunteer) =>
                (volunteer.registration_data && volunteer.registration_data._id) !== registrationId.toString()
            ),
          },
        },
      };
    }
  },

  [actionTypes.DESTROY_ACTION_REQUESTED]: ({ gae, ...state }, { actionSlug }) => ({
    ...state,
    gae: {
      ...gae,
      // Sets the record for being excluded.
      destroying: true,
      destroyingStatus: "requested",
    },
  }),
  [actionTypes.DESTROY_ACTION_RECEIVED]: ({ gae, ...state }, { actionSlug }) => ({
    ...state,
    gae: {
      ...gae,
      // Sets the record for being excluded.
      destroying: false,
      destroyingStatus: "received",
    },
  }),
  [actionTypes.DESTROY_ACTION_FAILED]: ({ gae, ...state }, { actionSlug }) => ({
    ...state,
    gae: {
      ...gae,
      // Sets the record to loading.
      destroying: false,
      destroyingStatus: "failed",
    },
  }),

  // Panel for Admin
  // ===============

  // Actions
  // --------

  // Finds a action by its slug and updates its status.
  [actionTypes.IPAA_UPDATE_STATUS]: ({ adminActions, ...state }, { actionSlug, status }) => ({
    ...state,
    adminActions: {
      ...adminActions,
      response: (adminActions.response || []).map(({ action_data, ...action }) => ({
        // Iterates over all listed actions
        ...action,
        action_data: {
          ...action_data,
          status: action_data.slug === actionSlug ? status : action_data.status, // If the right action, update its status.
        },
      })),
    },
  }),

  // Initial page actions for admin
  [actionTypes.IPAA_REQUESTED]: (state) => ({
    ...state,
    adminActions: {
      loading: true,
      status: "waiting",
    },
  }),
  [actionTypes.IPAA_RECEIVED]: (state, action) => ({
    ...state,
    adminActions: {
      ...action.payload,
      success: true,
      loading: false,
      status: "received",
    },
  }),
  [actionTypes.IPAA_FAILED]: (state, action) => ({
    ...state,
    adminActions: {
      ...action.payload,
      loading: false,
      status: "failed",
    },
  }),

  // Next page actions for admin
  [actionTypes.NPAA_REQUESTED]: (state) => ({
    ...state,
    adminActions: {
      ...state.adminActions,
      loadingNP: true,
      status: "waiting",
    },
  }),
  [actionTypes.NPAA_RECEIVED]: (state, action) => ({
    ...state,
    adminActions: {
      ...action.payload,
      success: true,
      loadingNP: false,
      status: "received",
    },
  }),
  [actionTypes.NPAA_FAILED]: (state, action) => ({
    ...state,
    adminActions: {
      ...action.payload,
      response: state.adminActions.response,
      loadingNP: false,
      status: "failed",
    },
  }),

  // Validate actions by admin
  [actionTypes.VPA_REQUESTED]: ({ adminActions, validateAction, ...state }, { actionSlug }) => ({
    ...state,
    adminActions: {
      ...adminActions,
      response: (adminActions.response || []).map((action) => ({
        ...action,
        updating: (action.action_data && action.action_data.slug) === actionSlug ? true : action.updating || false, // Set the updating flag to true if the action is being approved or rejected.
      })),
    },
    validateAction: {
      ...validateAction,
      loading: true,
      status: "waiting",
    },
  }),
  [actionTypes.VPA_RECEIVED]: ({ adminActions, validateAction, ...state }, { actionSlug, payload }) => ({
    ...state,
    adminActions: {
      ...adminActions,
      response: (adminActions.response || []).map((action) => ({
        ...action,
        updating: (action.action_data && action.action_data.slug) === actionSlug ? false : action.updating || false, // Set the updating flag to false for the action that had its status updated.
      })),
    },
    validateAction: {
      ...state.validateAction,
      success: true,
      status: "received",
    },
  }),
  [actionTypes.VPA_FAILED]: ({ adminActions, validateAction, ...state }, { actionSlug, payload }) => ({
    ...state,
    adminActions: {
      ...adminActions,
      response: (adminActions.response || []).map((action) => ({
        ...action,
        updating: (action.action_data && action.action_data.slug) === actionSlug ? false : action.updating || false, // Set the updating flag to false for the action that had its status updated.
      })),
    },
    validateAction: {
      ...state.validateAction,
      loading: false,
      status: "failed",
    },
  }),
});

export default actionReducer;
