import createReducer from "../createReducer";

/**
 * Types
 */

export const Types = {
  LIST_TEAMS_REQUESTED: "teams/LIST/REQUESTED",
  LIST_TEAMS_RECEIVED: "teams/LIST/RECEIVED",
  LIST_TEAMS_FAILED: "teams/LIST/FAILED",

  ASSIGN_TEAMS_REQUESTED: "teams/ASSIGN/REQUESTED",
  ASSIGN_TEAMS_RECEIVED: "teams/ASSIGN/RECEIVED",
  ASSIGN_TEAMS_FAILED: "teams/ASSIGN/FAILED",
};

const INITIAL_STATE = {
  list: {
    loading: false,
    status: "",
  },
};

/**
 * Reducer
 */
const teamsReducer = createReducer(INITIAL_STATE, {
  [Types.LIST_TEAMS_REQUESTED]: (state) => {
    return {
      ...state,
      list: {
        loading: true,
        status: "waiting",
      },
    };
  },
  [Types.LIST_TEAMS_RECEIVED]: (state, action) => {
    return {
      ...state,
      list: {
        ...(action?.payload || {}),
        loading: false,
        status: "received",
      },
    };
  },
  [Types.LIST_TEAMS_FAILED]: (state, action) => {
    return {
      ...state,
      list: {
        ...(action?.payload || {}),
        loading: false,
        status: "failed",
      },
    };
  },
  [Types.ASSIGN_TEAMS_REQUESTED]: (state) => {
    return {
      ...state,
      assign: {
        loading: true,
        status: "waiting",
      },
    };
  },
  [Types.ASSIGN_TEAMS_RECEIVED]: (state, action) => {
    return {
      ...state,
      assign: {
        ...(action?.payload || {}),
        loading: false,
        status: "received",
      },
    };
  },
  [Types.ASSIGN_TEAMS_FAILED]: (state, action) => {
    return {
      ...state,
      assign: {
        ...(action?.payload || {}),
        loading: false,
        status: "failed",
      },
    };
  },
});

/**
 * Actions
 */

export const listTeamsRequested = () => ({ type: Types.LIST_TEAMS_REQUESTED });
export const listTeamsReceived = (payload) => ({ type: Types.LIST_TEAMS_RECEIVED, payload });
export const listTeamsFailed = (payload) => ({ type: Types.LIST_TEAMS_FAILED, payload });

export const assignTeamsRequested = () => ({ type: Types.ASSIGN_TEAMS_REQUESTED });
export const assignTeamsReceived = (payload) => ({ type: Types.ASSIGN_TEAMS_RECEIVED, payload });
export const assignTeamsFailed = (payload) => ({ type: Types.ASSIGN_TEAMS_FAILED, payload });

export default teamsReducer;
