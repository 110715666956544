import http from "config/http";
import { ProjectImages, ResponseStatus, ResponseWithParams } from "../definitions";
import { Conditionals } from "../definitions";
import { ProjectType, ProjectSocialNetwork } from "../definitions";
import { Address } from "core/api/definitions";
import { Contact } from "../definitions";
import { AxiosResponse } from "axios";
import { UniqueDocumentType } from "../users/getGeneralData";

interface BaseUpdateProject {
  type?: ProjectType;
  slug: string;
  description?: string;
  document_id?: string;
  document_type?: UniqueDocumentType;
  social_network?: ProjectSocialNetwork;
  locations?: Address;
  images_data?: ProjectImages;
  contacts?: Contact[];
  approval_token?: string;
}

export interface UpdateProjectOrganizationRequest extends BaseUpdateProject {}

export interface UpdateProjectPersonalRequest extends BaseUpdateProject {
  conditionals: Conditionals;
}

export type UpdateProjectOrganizationResponse = ResponseWithParams<
  ResponseStatus,
  { slug: string; _id: string; approval_token?: string }
>;

const updateProjectOrganization = async ({
  slug,
  ...data
}: UpdateProjectOrganizationRequest): Promise<AxiosResponse<UpdateProjectOrganizationResponse>> =>
  http.put<UpdateProjectOrganizationResponse>(`/projects/${slug}`, data);

export default updateProjectOrganization;
