import React from "react";
import styled from "styled-components/macro";

interface FormWizardProps {
  id?: string;
  onContinue(): void;
  onSubmit?(e?: React.FormEvent): void;
}

const FormWizard: React.FC<FormWizardProps> = ({ onSubmit, onContinue, ...props }) => (
  <FormWizardStyled
    onSubmit={(e: React.FormEvent) => {
      e.preventDefault();
      if (onSubmit) onSubmit(e);
      onContinue();
    }}
    {...props}
  />
);

const FormWizardStyled = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export default FormWizard;
