import { AxiosResponse } from "axios";
import http from "config/http";
import { ResponseWithParams, ResponseStatus } from "core/api/definitions";
import { SubscribeData } from "core/api/definitions";
import { ActionData } from "../definitions";
import { ProjectData } from "../definitions";

export interface EditRequest {
  slug: string;
}

export interface EditResponseParams extends SubscribeData {
  action_data: ActionData;
  project_data: ProjectData;
}

export type EditResponse = ResponseWithParams<ResponseStatus, EditResponseParams>;

export default ({ slug }: EditRequest): Promise<AxiosResponse<EditResponse>> => {
  return http.get<EditResponse>(`/subscribe/${slug}/edit`);
};
