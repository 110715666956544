import { createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import { ProgramImages, RequestableState } from "core/api/definitions";
import decoreThunk from "core/decorators/decorate";

import requestLoading from "core/decorators/requestLoading";

import { ResponseAdminPrograms, AdminProgramsData } from "core/api/admin/programs/listProgramsAdmin";
import { getListProgramsAdmin } from "./actions";

interface IState extends RequestableState {
  params: AdminProgramsData[];
}

const panelBetaAdminProgramsSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "panel/beta/administrators/programs",
  initialState: {
    loading: "idle",
    params: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, getListProgramsAdmin, [
      requestLoading(),
      {
        pending: (state: IState) => {
          state.loading = "loading";
        },
        fulfilled: (state: IState, action: PayloadAction<ResponseAdminPrograms>) => {
          state.loading = "ok";
          if (action?.payload?.params) {
            state.params = action.payload.params;
          }
        },
        rejected: (state: IState) => {
          state.loading = "error";
        },
      },
    ]);
  },
});

export default panelBetaAdminProgramsSlice;
