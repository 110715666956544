import i18next from "i18next";
import XHR from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import numeral from "numeral";
import { get } from "core/pages/origin";
import { Dispatch, AnyAction } from "redux";
import { format as dateFormat } from "utils/date";
import { PREVENT_DEPLOY_CACHE } from "utils/constants";
import { Origin } from "core/api/definitions";
import { formatLanguageToISO } from "utils/helpers";
import { initNumberFormatters } from "utils/number";
import { initRoutes } from "routes";

const initOrigin = () => (next: Dispatch<AnyAction>) => (action: AnyAction) => {
  if (action.type === get.fulfilled.toString()) {
    const language = (action.payload.params as Origin)?.language;
    // const language = "pt-PT";
    i18next
      .use(XHR)
      .use(initReactI18next)
      .init({
        // debug: process.env.NODE_ENV === "development",
        lng: formatLanguageToISO(language),
        // lng: navigator.language,
        fallbackLng: "pt-BR",

        // files to (pre)load
        ns: ["translation", "components", "routes", "v3", "plain", "errors"],

        // default namespace (needs no prefix on calling t)
        defaultNS: "translation",

        // fallback, can be a string or an array of namespaces
        fallbackNS: "translation",

        interpolation: {
          //format
          format: (value, format) => {
            if (value instanceof Date) return dateFormat(value, format);
            if (format === "lowercase") return value.toLowerCase();
            return value;
          },
        },
        backend: {
          loadPath: "/locales/{{lng}}/{{ns}}.json?" + PREVENT_DEPLOY_CACHE,
        },
      })
      .then(() => {
        initRoutes();

        const lang = language.toLowerCase();

        initNumberFormatters(language);
        const lsplit = lang.split("-");
        const langs = lsplit.length > 1 ? [lang, lsplit[0]] : [lang];
        for (const l of langs) {
          try {
            require(`numeral/locales/${l}`);
            numeral.locale(formatLanguageToISO(l));
          } catch (err) {
            continue;
          }
          break;
        }

        next(action);
      });
    return;
  }

  return next(action);
};

export default initOrigin;
