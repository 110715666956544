import React, { useCallback, useState } from "react";
import PhoneInput, { getCountryCallingCode, parsePhoneNumber, PhoneNumber } from "react-phone-number-input";
import styled, { css } from "styled-components";
import { BaseInputStyle } from "./BaseInput";
import "react-phone-number-input/style.css";
import FormGroup from "../FormGroup";
import FormFeedback from "../FormFeedback";
import Label from "../Label";
import useTranslationV3 from "hooks/useTranslationV3";
import useCountry from "hooks/useCountry";

interface InputPhoneProps {
  value?: string;
  error?: string;
  defaultCountry?: string;
  country?: string;
  invalid?: boolean;
  valid?: boolean;
  message?: string;
  disabled?: boolean;
  tag?: React.ElementType;
  className?: string;
  label?: string;
  placeholder?: string;
  onCountryChange?: (countryCode?: string) => void;
  onPhoneChange?: (value: string) => void;
}

const InputPhoneComp = ({
  onPhoneChange,
  onCountryChange,
  defaultCountry,
  country,
  invalid,
  valid,
  message,
  disabled,
  className,
  tag: Tag = FormGroup,
  label,
  ...props
}: InputPhoneProps) => {
  const { t } = useTranslationV3();
  const { iso3166_1: baseCountry, isInternationalPhone } = useCountry();

  const handlePhoneChange = useCallback(
    (value: string | React.ChangeEvent<HTMLInputElement>) => {
      if (!onPhoneChange) return;
      if (typeof value === "string" || value === undefined) {
        onPhoneChange(value);
      } else if (value.target) {
        onPhoneChange(value.target.value);
      }
    },
    [onPhoneChange]
  );

  return (
    <Tag className={className}>
      <Label invalid={invalid} valid={valid} disabled={disabled}>
        {label ? label : t("plain:Telefone")}
      </Label>
      <PhoneInputStyled
        {...props}
        isInternationalPhone={isInternationalPhone}
        countryCallingCodeEditable={isInternationalPhone}
        defaultCountry={defaultCountry ?? baseCountry.toUpperCase()}
        onChange={handlePhoneChange}
        disabled={disabled}
        invalid={invalid}
        valid={valid}
        international={isInternationalPhone}
        limitMaxLength
      />
      {message && (
        <FormFeedback invalid={invalid} valid={valid}>
          {message}
        </FormFeedback>
      )}
    </Tag>
  );
};

const InputPhone = styled(InputPhoneComp)`
  ${FormFeedback} {
    display: block;
  }
`;

export default InputPhone;

const PhoneInputStyled = styled(({ isInternationalPhone, ...props }) => <PhoneInput {...props} />)<{
  isInternationalPhone?: boolean;
}>`
  position: relative;

  .PhoneInputCountry {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 18px;
    margin: auto 0;

    ${({ isInternationalPhone }) =>
      !isInternationalPhone &&
      css`
        display: none;
      `};
  }

  .PhoneInputInput {
    ${BaseInputStyle}

    ${({ theme, isInternationalPhone }) =>
      isInternationalPhone &&
      css`
        padding-left: calc(${theme.v3.spacing.sm} + ${theme.v3.spacing.xl});
      `};
  }
`;
