import React, { ElementType } from "react";
import classNames from "classnames";
import styled from "styled-components";

export interface RowProps extends React.HTMLProps<HTMLElement> {
  [key: string]: any;
  className?: string;
  tag?: string | ElementType;
  noGutters?: boolean;
}

const RowComp: React.FC<RowProps> = ({ children, tag, ...props }) => {
  const Component = (tag || "div") as ElementType;

  const classes = classNames(props.className, props.noGutters ? "no-gutters" : null, "rw");

  return (
    <Component {...props} className={classes}>
      {children}
    </Component>
  );
};

/**
 * Row wraps RowComp to enable including `${Row}` inside of styled rules ;).
 */
const Row = styled(RowComp)``;

export default Row;
