import { initial } from "lodash";
import { rem } from "polished";
import React from "react";
import styled, { css } from "styled-components";
import { transition } from "utils/animation";
import { ColorV3, Size } from "utils/types";

export interface TextProps {
  size?: Size | "xl" | "xxl";
  color?: ColorV3;
  tag?: React.ElementType;
  to?: string;
  title?: string;
  [propName: string]: any;
  align?: "left" | "right" | "center" | "justify" | "initial" | "inherit";
}

const Text = styled<React.FC<TextProps>>(({ tag: Tag, size, color, align, ...props }) =>
  Tag ? <Tag {...props} /> : <p {...props} />
)<TextProps>`
  margin: 0;
  padding: 0;
  color: ${({ color, theme }) => theme.v3.colors[color ?? "neutralBase"]};
  font-size: 23px;
  line-height: ${rem(22)};
  text-align: left;
  font-weight: 500;
  ${transition.normal("transition color")};
  ${({ align }) => {
    return css`
      text-align: ${align};
    `;
  }}
  ${({ size, theme }) => {
    if (size === "xxl")
      return css`
        font-size: ${theme.v3.fontSize.xxl};
        line-height: ${rem(48)};
      `;

    if (size === "xl")
      return css`
        font-size: ${theme.v3.fontSize.xl};
        line-height: ${rem(37)};
      `;

    if (size === "lg")
      return css`
        font-size: ${theme.v3.fontSize.lg};
        line-height: ${rem(32)};
      `;

    if (size === "sm")
      return css`
        font-size: ${theme.v3.fontSize.sm};
        line-height: ${rem(20)};
      `;

    if (size === "xs")
      return css`
        font-size: ${theme.v3.fontSize.xs};
        line-height: ${rem(15)};
      `;
  }};

  :hover {
    ${({ color, theme }) =>
      (color === "primary" || color === "secondary") &&
      css`
        color: ${theme.v3.colors[color === "primary" ? "primaryDark" : "secondaryDark"]};
      `};
  }
`;

export default Text;
