import { Helmet } from "react-helmet";
import React from "react";

const Robots = (): JSX.Element => {
  if (process.env.NODE_ENV !== "production")
    return (
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
    );
  return <></>;
};

export default Robots;
