import { Response } from "./definitions";

export const ResponseErrorUnknown: Response = {
  success: false,
  response: {
    code: 501,
    message: "Not implemented",
  },
};

export const ResponseUserCredentialsInvalid: Response = {
  success: false,
  response: {
    code: 403,
    message: "user.credentials-invalid",
  },
};

export const ResponseErrorDataWasInvalid: Response = {
  success: false,
  response: {
    code: 422,
    message: "the-given-data-was-invalid",
  },
};

export const ResponseErrorCertificateNotFound: Response = {
  success: false,
  response: {
    code: 422,
    message: "actions.certificate-not-found",
  },
};

export const ResponseErrorUserNoCurriculumData: Response = {
  success: false,
  response: {
    code: 422,
    message: "user.no-curriculum-data",
  },
};

export const ResponseErrorDocumentIsRequired: Response = {
  success: false,
  response: {
    code: 422,
    message: "users.document-is-required",
  },
};

export const ResponseErrorActionCpfFormatInvalid: Response = {
  success: false,
  response: {
    code: 422,
    message: "actions.document-brazilian-cpf-format-invalid",
  },
};

export const ResponseErrorWithMessage = (message: string): Response => {
  return {
    success: false,
    response: {
      code: -1,
      message: message,
    },
  };
};
