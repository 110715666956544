import { TrackVertical } from "components/common/style";
import SupportText from "components/common/SupportText";
import Icon from "components/Icon/Icon";
import { MaterialItem } from "core/api/definitions";
import { rem } from "polished";
import React, { useCallback, useState, useEffect } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components/macro";
import mediaQueries from "utils/mediaQueries";
import SupportFeedback from "components/common/SupportFeedback";
import { keys, pickBy } from "lodash";

export type MaterialItemInfo = Partial<MaterialItem> & {
  edit?: "removed" | "updated";
};

export interface DonationListDataProps {
  items?: MaterialItemInfo[];
  onAddItem: (item: MaterialItemInfo) => void;
  onEditItem?: (item: MaterialItemInfo, idx?: number) => void;
  onRemoveItem: (item: MaterialItemInfo, idx: number) => void;

  itemError?: number;
  className?: string;
  autoFocus?: boolean;
  disabled?: boolean;
}

const DonationListItems: React.FC<DonationListDataProps> = ({
  items,
  onAddItem,
  onEditItem,
  onRemoveItem,
  itemError,
  className,
  autoFocus,
  disabled,
}) => {
  const { t } = useTranslation();
  const refFirstInput = React.createRef<HTMLInputElement>();

  const [title, setTitle] = useState("");
  const [quantity, setQuantity] = useState<number | undefined>(undefined);
  const [errorsQuantity, setErrorsQuantity] = useState<number[]>([]);
  const [errorsTitle, setErrorsTitle] = useState<number[]>([]);

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      if (quantity && title && onAddItem) {
        onAddItem({
          quantity,
          title,
        });
        setQuantity(undefined);
        setTitle("");
        if (refFirstInput.current) refFirstInput.current.focus();
      }
    },
    [refFirstInput, quantity, title, onAddItem]
  );

  useEffect(() => {
    setErrorsQuantity(keys(pickBy(items, (i) => !(i.quantity && i.quantity > 0))).map(Number));
    setErrorsTitle(keys(pickBy(items, (i) => !(i.title && i.title?.length >= 3 && i.title?.length <= 60))).map(Number));
  }, [items]);

  const validateQuantity = (quantity: string): number | undefined => {
    const q = parseInt(quantity);
    if (!Number.isNaN(q) && q > 0) return q;
    return undefined;
  };

  const titleOnChangeCallback = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setTitle(value);
  }, []);

  const quantityOnChangeCallback = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (validateQuantity(value)) setQuantity(parseInt(value));
    if (value === "") setQuantity(undefined);
  }, []);

  return (
    <CustomContainer className={className}>
      <Scrollbars renderTrackVertical={() => <CustomTrackVertical top={0} right={35} />}>
        <List>
          <form onSubmit={handleSubmit}>
            <Table>
              <thead>
                <tr>
                  <th>{t("wizard.pages.donationListDataItem.table.quantity")}</th>
                  <th>{t("wizard.pages.donationListDataItem.table.item")}</th>
                </tr>
              </thead>
              <tbody>
                {!disabled && (
                  <tr>
                    <td>
                      <input
                        ref={refFirstInput}
                        type="number"
                        name="quantity"
                        placeholder="0"
                        value={quantity || ""}
                        onChange={quantityOnChangeCallback}
                        autoFocus={autoFocus}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        name="title"
                        placeholder={t("wizard.pages.donationListDataItem.table.placeholder")}
                        value={title}
                        onChange={titleOnChangeCallback}
                      />
                    </td>
                    <td>
                      <SupportText
                        tag="button"
                        type="submit"
                        size="md"
                        color="secondary"
                        withWeight={500}
                        disabled={!(quantity && title) || title.length < 3 || title.length > 60}
                      >
                        {t("common.add")}
                      </SupportText>
                    </td>
                  </tr>
                )}
                {items &&
                  items.map((item, index) => (
                    <CustomItem
                      key={index}
                      removed={item.edit === "removed"}
                      invalid={errorsQuantity.includes(index) || errorsTitle.includes(index)}
                    >
                      <td>
                        {!disabled ? (
                          <input
                            type="tel"
                            value={item.quantity || 0}
                            autoComplete="off"
                            required
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              const { value } = e.target;
                              if (onEditItem) onEditItem({ ...item, quantity: validateQuantity(value) }, index);
                            }}
                          />
                        ) : (
                          item.quantity
                        )}
                      </td>
                      <td>
                        {!disabled ? (
                          <input
                            type="text"
                            value={item.title}
                            autoComplete="off"
                            required
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              const { value } = e.target;
                              if (onEditItem) onEditItem({ ...item, title: value || "" }, index);
                            }}
                          />
                        ) : (
                          item.title
                        )}
                      </td>
                      <td>
                        {!disabled && (
                          <button onClick={() => onRemoveItem(item, index)}>
                            <Icon type="trash" />
                          </button>
                        )}
                        {/**TODO(Jeconias): Add warning icon when the title is invalid */}
                        {itemError === index && <></>}
                      </td>
                    </CustomItem>
                  ))}
              </tbody>
            </Table>
          </form>
        </List>
      </Scrollbars>
      {errorsQuantity.length > 0 && (
        <SupportFeedback>{t("wizard.pages.donationListDataItem.errors.quantity")}</SupportFeedback>
      )}
      {errorsTitle.length > 0 && (
        <SupportFeedback>{t("wizard.pages.donationListDataItem.errors.title", { min: 3, max: 60 })}</SupportFeedback>
      )}
    </CustomContainer>
  );
};

export default DonationListItems;

const CustomContainer = styled.section`
  box-sizing: border-box;
  height: 400px;
  max-width: 830px;
  border: 1px solid #9facbd;
  border-radius: 6px;
`;

const List = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px 80px;

  ${mediaQueries.sm(css`
    padding: 30px 20px;
  `)}
`;

const Table = styled.table`
  width: 100%;
  color: #9facbd;
  margin-bottom: 60px;

  thead,
  th {
    font-size: ${rem(13)};
    letter-spacing: 0;
    line-height: ${rem(20)};
    font-weight: 400;
  }

  tbody {
    tr {
      color: #657d9b;
      font-size: ${rem(15)};
      font-weight: 500;
      letter-spacing: 0;
      line-height: ${rem(22)};
      height: 55px;

      :not(:last-child) {
        border-bottom: 1px solid #e2e6f4;
      }
    }

    td:nth-child(1) {
      max-width: 70px;
    }

    td:nth-child(3) {
      text-align: center;
    }

    input {
      width: 100%;
      border: none;
      outline: none;
      color: #657d9b;
      font-weight: 500;
      ::placeholder {
        color: #cdd6e2;
      }
      ::-webkit-inner-spin-button {
        appearance: none;
      }
    }

    button {
      border: none;
      background: transparent;
    }

    svg {
      width: 15px;
      color: #e2e6f4;
      :hover {
        color: #cdd6e2;
        transform: scale(1.2);
      }
    }
  }
`;

const CustomTrackVertical = styled(TrackVertical)`
  background: transparent;
`;

const CustomItem = styled.tr<{
  removed?: boolean;
  invalid?: boolean;
}>`
  ${({ invalid, theme }) =>
    invalid &&
    css`
      border-bottom: 1px solid ${theme.colors.quaternary} !important;
    `}

  ${({ removed }) =>
    removed &&
    css`
      text-decoration: line-through;
      opacity: 0.5;
    `}
`;
