import { BaseTitle } from "components/common/BaseTitle";
import SupportText from "components/common/SupportText";
import SubscribeHours, { SubscribeHour } from "components/SubscribeHours/SubscribeHours";

import React from "react";
import { Helmet } from "react-helmet";
import { Col } from "reactstrap";
import styled from "styled-components/macro";

import { containerLayout } from "./InputVacancyDateBase";
import { PageEvents } from "../definitions/commonTypes";

interface InputVacancyHourBaseProps extends Pick<PageEvents, "error"> {
  hours: SubscribeHour[];
  helmet?: string;
  title: string;
  formTitle?: string;
  description: string;
  disabled?: boolean;
  autoFocus?: boolean;
  onChange(hours: SubscribeHour[]): void;
  classNameSubscribeHours?: string;
}

const InputVacancyHourBase: React.FC<InputVacancyHourBaseProps> = ({
  hours,
  autoFocus,
  helmet,
  title,
  formTitle,
  description,
  onChange,
  error,
  disabled,
  classNameSubscribeHours,
}) => (
  <VacancyHourContainer className="vacancyHourContainer">
    <CustomCol tag="section" className="col-md-5 col-sm-12">
      {helmet && <Helmet title={helmet} />}
      <BaseTitle size="md">{title}</BaseTitle>
      <SupportText size="md">{description}</SupportText>
    </CustomCol>
    <Col tag="section" className={classNameSubscribeHours}>
      <SupportText size="md">{formTitle}</SupportText>
      <SubscribeHours
        autoFocus={autoFocus}
        hours={hours}
        onChange={(data) => onChange(data)}
        error={error}
        disabled={disabled}
      />
    </Col>
  </VacancyHourContainer>
);

export default InputVacancyHourBase;

const VacancyHourContainer = styled.div`
  ${containerLayout};
  & > section:nth-child(2) {
    justify-content: flex-start;
  }
`;

const CustomCol = styled(Col)`
  padding-top: 32px;
`;
