import api from "core/api";
import asyncThunk from "core/decorators/toolkit";

export const ArchiveChat = asyncThunk("panel/beta/chat/archiveChat", api.chat.ArchiveChat);

export const UnarchiveChat = asyncThunk("panel/beta/chat/unarchiveChat", api.chat.UnarchivedChat);

export const ListAllChatWithProjects = asyncThunk(
  "panel/beta/chat/listAllChatWithProjects",
  api.chat.ListAllChatWithProjects
);

export const ListAllChatWithVolunteers = asyncThunk(
  "panel/beta/chat/listAllChatWithVolunteers",
  api.chat.ListAllChatWithVolunteers
);

export const MarkReadChat = asyncThunk("panel/beta/chat/markRead", api.chat.MarkReadChat);

export const UnmarkReadChat = asyncThunk("panel/beta/chat/markRead", api.chat.MarkUnreadChat);

export const ShowConversationChat = asyncThunk("panel/beta/chat/showConversationChat", api.chat.ShowConversationChat);

export const SilentChat = asyncThunk("panel/beta/chat/silentChat", api.chat.SilentChat);

export const UnsilentChat = asyncThunk("panel/beta/chat/unsilentChat", api.chat.UnsilentChat);

export const FilterProjectsContacts = asyncThunk(
  "panel/beta/chat/filterProjectsContacts",
  api.chat.FilterProjectsContacts
);

export const FilterVolunteersContacts = asyncThunk(
  "panel/beta/chat/filterVolunteersContacts",
  api.chat.FilterVolunteersContacts
);

export const StartNewChat = asyncThunk("panel/beta/chat/startNewChat", api.chat.StartNewChat);

export const SendMessageChat = asyncThunk("panel/beta/chat/sendMessageChat", api.chat.SendMessageChat);
