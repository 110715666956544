import searchTypes from "./types";
import createReducer from "../createReducer";
import { axiosDefaultError } from "../../utils/helper";

const INITIAL_STATE = {
  actions: {
    loading: true,
    response: [],
  },
  subscribe: {
    loading: true,
    response: [],
  },
  acm: {
    loading: false,
  },
  sbm: {
    loading: false,
  },
  sal: {
    loading: false,
    response: [],
  },
  sad: {
    loading: false,
  },
  listProjectPage: {
    loading: true,
  },
  lp: {
    loading: false,
  },
};

const searchReducer = createReducer(INITIAL_STATE, {
  [searchTypes.ACTIONS_REQUESTED]: (state) => ({
    ...state,
    actions: {
      loading: true,
      status: "waiting",
    },
  }),
  [searchTypes.ACTIONS_RECEIVED]: (state, action) => ({
    ...state,
    actions: {
      ...action.payload,
      success: true,
      loading: false,
      status: "received",
    },
  }),
  [searchTypes.ACTIONS_FAILED]: (state, action) => ({
    ...state,
    actions: {
      ...INITIAL_STATE.actions,
      loading: false,
      status: "failed",
      error: {
        ...axiosDefaultError,
        ...action.payload,
      },
    },
  }),
  [searchTypes.SUBSCRIBE_REQUESTED]: (state) => ({
    ...state,
    subscribe: {
      loading: true,
      status: "waiting",
    },
  }),
  [searchTypes.SUBSCRIBE_RECEIVED]: (state, action) => ({
    ...state,
    subscribe: {
      ...action.payload,
      success: true,
      loading: false,
      status: "received",
    },
  }),
  [searchTypes.SUBSCRIBE_FAILED]: (state, action) => ({
    ...state,
    subscribe: {
      ...INITIAL_STATE.subscribe,
      loading: false,
      status: "failed",
      error: {
        ...axiosDefaultError,
        ...action.payload,
      },
    },
  }),
  [searchTypes.ACM_REQUESTED]: (state) => ({
    ...state,
    acm: {
      loading: true,
      status: "waiting",
    },
  }),
  [searchTypes.ACM_RECEIVED]: (state, action) => ({
    ...state,
    acm: {
      ...action.payload,
      success: true,
      loading: false,
      status: "received",
    },
  }),
  [searchTypes.ACM_FAILED]: (state, action) => ({
    ...INITIAL_STATE,
    acm: {
      ...action.payload,
      loading: false,
      status: "failed",
    },
  }),
  [searchTypes.SBM_REQUESTED]: (state) => ({
    ...state,
    sbm: {
      loading: true,
      status: "waiting",
    },
  }),
  [searchTypes.SBM_RECEIVED]: (state, action) => ({
    ...state,
    sbm: {
      ...action.payload,
      success: true,
      loading: false,
      status: "received",
    },
  }),
  [searchTypes.SBM_FAILED]: (state, action) => ({
    ...INITIAL_STATE,
    sbm: {
      sal: {
        ...action.payload,
        loading: false,
        status: "failed",
      },
    },
  }),
  [searchTypes.SAL_REQUESTED]: (state) => ({
    ...state,
    sal: {
      loading: true,
      status: "waiting",
    },
  }),
  [searchTypes.SAL_RECEIVED]: (state, action) => ({
    ...state,
    sal: {
      ...action.payload,
      success: true,
      loading: false,
      status: "received",
    },
  }),
  [searchTypes.SAL_FAILED]: (state, action) => ({
    ...INITIAL_STATE,
    sal: {
      ...action.payload,
      loading: false,
      status: "failed",
    },
  }),
  [searchTypes.SAD_REQUESTED]: (state) => ({
    ...state,
    sad: {
      loading: true,
      status: "waiting",
    },
  }),
  [searchTypes.SAD_RECEIVED]: (state, action) => ({
    ...state,
    sad: {
      ...action.payload,
      success: true,
      loading: false,
      status: "received",
    },
  }),
  [searchTypes.SAD_FAILED]: (state, action) => ({
    ...INITIAL_STATE,
    sad: {
      ...action.payload,
      loading: false,
      status: "failed",
    },
  }),
  [searchTypes.LIST_PROJECTS_REQUESTED]: (state) => ({
    ...state,
    listProjectPage: {
      loading: true,
      status: "waiting",
    },
  }),
  [searchTypes.LIST_PROJECTS_RECEIVED]: (state, action) => ({
    ...state,
    listProjectPage: {
      ...action.payload,
      loading: false,
      status: "received",
    },
  }),
  [searchTypes.LIST_PROJECTS_FAILED]: (state, action) => ({
    ...state,
    listProjectPage: {
      ...INITIAL_STATE.listProjectPage,
      loading: false,
      status: "failed",
      error: {
        ...axiosDefaultError,
        ...action.payload,
      },
    },
  }),
  [searchTypes.LP_REQUESTED]: (state) => ({
    ...state,
    lp: {
      loading: true,
      status: "waiting",
    },
  }),
  [searchTypes.LP_RECEIVED]: (state, action) => ({
    ...state,
    lp: {
      ...action.payload,
      loading: false,
      status: "received",
    },
  }),
  [searchTypes.LP_FAILED]: (state, action) => ({
    ...state,
    lp: {
      ...INITIAL_STATE.lp,
      loading: false,
      status: "failed",
      error: {
        ...axiosDefaultError,
        ...action.payload,
      },
    },
  }),
});

export default searchReducer;
