import { AxiosResponse } from "axios";
import http from "config/http";
import { ResponseStatus, ResponseWithParams } from "../definitions";

export interface MarkRead {
  chatID: string;
}

export type MarkReadReponse = ResponseWithParams<ResponseStatus, []>;

export default async ({ chatID }: MarkRead): Promise<AxiosResponse<MarkReadReponse>> =>
  http.get<MarkReadReponse>(`/chat/${chatID}/read`);
