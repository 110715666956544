import { AxiosResponse } from "axios";
import http from "config/http";
import { ResponseStatus, ResponseWithParams } from "../definitions";

export interface ChatContacts {
  _id: string;
  chat_data: {
    _id: string;
    volunteer: string;
    owner: string;
    project: string;
    config: {
      volunteer: {
        silence: false;
        archive: false;
      };
      project: {
        silence: false;
        archive: false;
      };
    };
  };
  message_data: {
    _id: string;
    sender: string;
    receiver: string;
    chat_id: string;
    message: string;
    read: false;
    updated_at: string;
    created_at: string;
  };
  project_data: {
    _id: string;
    name: string;
    profile_image: string;
  };
}

export interface ListAllWithProjects {
  chat_contacts: ChatContacts[];
  groups: [];
}

export type ListAllWithProjectsResponse = ResponseWithParams<ResponseStatus, ListAllWithProjects>;

export default async (): Promise<AxiosResponse<ListAllWithProjectsResponse>> =>
  http.get<ListAllWithProjectsResponse>(`/chat/list-all-conversations-projects`);
