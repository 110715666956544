import Link from "components/common/Link";
import { Origin } from "core/api/definitions";
import { useReduxSelector } from "hooks/useReduxSelector";
import { position, rem } from "polished";
import React, { useMemo } from "react";
import { LinkProps } from "react-router-dom";
import {
  Navbar as BSNavbar,
  NavbarBrand as RSNavbarBrand,
  NavItem as BSNavItem,
  NavLink as BSNavLink,
} from "reactstrap";
import routes from "routes";
import styled, { css } from "styled-components/macro";
import { transition } from "utils/animation";
import { COPYRIGHT_YEAR } from "utils/constants";
import { useMediaQueriesV3 } from "utils/hooks/useMediaQueriesV3";
import mqV3 from "utils/mediaQueriesV3";
import { Col, Container, Row } from "../Grid";
import { NavLinkStyled } from "./Header";
import SocialNetworks from "./SocialNetworks";
import LazyImage from "components/LazyImage/LazyImage";

import imgForumEstudante from "assets/imgs/logos/pt/footer_forumestudante.png";
import imgTransformaBrasil from "assets/imgs/logos/pt/footer_tbr.png";
import imgUCTransforma from "assets/imgs/logos/pt/footer_UCTransforma_COR.png";

import imgUC from "assets/imgs/logos/pt/UC_V_FundoClaro-negro.png";
import useEntity from "hooks/useEntity";
import WrapperMobile from "../Grid/WrapperMobile";
import FloatingWhatsappIcon from "../common/FloatingWhatsappIcon";

interface NavItemProps extends Partial<Pick<LinkProps, "to">> {
  active?: boolean;
  tag?: React.ElementType;
  href?: string;
  target?: string;
}

const MainNavItemLinks: React.FC<NavItemProps> = ({ to, children, active }) => {
  return (
    <MainNavItemStyled active={active}>
      <NavLinkStyled tag={Link} to={to}>
        {children}
      </NavLinkStyled>
    </MainNavItemStyled>
  );
};

const MainNavItemStyled = styled(BSNavItem)`
  margin: 0 ${({ theme }) => theme.v3.spacing.xxs};
  height: 100%;
  display: flex;
  align-items: center;
`;

const MenuItemLinks: React.FC<NavItemProps> = ({ to, children, active, tag, href, target }) => {
  const linkProp = to && !tag ? { to } : { href, target };

  return (
    <MenuItemStyled active={active}>
      <MenuLinkStyled tag={tag ?? Link} {...linkProp}>
        {children}
      </MenuLinkStyled>
    </MenuItemStyled>
  );
};

const MenuItemStyled = styled(BSNavItem)`
  ${({ theme }) => css`
    flex: 1;
    margin: 0 ${theme.v3.spacing.sm} ${theme.v3.spacing.xs};
    min-width: 200px;
    height: 100%;
    display: flex;
    align-items: left;
    line-height: ${theme.v3.spacing.md};
    white-space: nowrap;

    ${mqV3.xs} {
      margin-right: ${theme.v3.spacing.sm};
    }
  `}
`;

const MenuLinkStyled = styled(BSNavLink)`
  color: ${({ theme }) => theme.v3.colors.neutralLight};
  font-weight: 400;
  font-size: ${({ theme }) => theme.v3.fontSize.xs};
  padding: 0;
  transition: ${transition.fast("color")};

  &:hover {
    color: ${({ theme }) => theme.v3.colors.neutralBase};
  }
`;

const Footer = () => {
  const { origin } = useReduxSelector((state) => ({
    origin: state.pagesNew.origin.params,
  }));

  const { isXS } = useMediaQueriesV3();
  const { entity } = useEntity();

  const { title, logoIcon, theme, footer } = origin as Origin;

  const menuLinks = useMemo(() => {
    return footer.filter((el) => el.type === "menu");
  }, [footer]);

  const footerLinks = useMemo(() => {
    return footer.filter((el) => el.type !== "menu");
  }, [footer]);

  const hasFooterLogos = entity === "coimbra" || entity === "portugal";

  return (
    <FooterStyled>
      <FooterNavMain>
        <WrapperMobile>
          <NavMainContainer>
            {/* <Row>
              {entity == "betterfly" ? (
                <Col xs={4} sm={3}>
                  <NavbarBrand tag={Link} to={routes.home}>
                    <NavbarBrandLogo src={theme.brand.logo_footer} title={title} alt={title} height="40" width="auto" />
                  </NavbarBrand>
                </Col>
              ) : (
                <Col xs={3} sm={2}>
                  <NavbarBrand tag={Link} to={routes.home}>
                    <NavbarBrandLogo src={logoIcon} title={title} alt={title} height="40" width="auto" />
                  </NavbarBrand>
                </Col>
              )}
              {!isXS && (
                <CustomCol sm={5} md={6}>
                  <Ul>
                    {menuLinks.map((item, k) => {
                      return (
                        <MainNavItemLinks key={k} to={item?.link}>
                          {item.label}
                        </MainNavItemLinks>
                      );
                    })}
                  </Ul>
                </CustomCol>
              )}
              <Col xs={9} sm={4} md={3}>
                <SocialNetworksStyled />
              </Col>
            </Row> */}
            <ContainerFooter>
              {entity == "betterfly" ? (
                <div>
                  <NavbarBrand tag={Link} to={routes.home}>
                    <NavbarBrandLogo src={theme.brand.logo_footer} title={title} alt={title} height="40" width="auto" />
                  </NavbarBrand>
                </div>
              ) : (
                <div>
                  <NavbarBrand tag={Link} to={routes.home}>
                    <NavbarBrandLogo src={logoIcon} title={title} alt={title} height="40" width="auto" />
                  </NavbarBrand>
                </div>
              )}
              {!isXS && (
                <div>
                  <Ul>
                    {menuLinks.map((item, k) => {
                      return (
                        <MainNavItemLinks key={k} to={item?.link}>
                          {item.label}
                        </MainNavItemLinks>
                      );
                    })}
                  </Ul>
                </div>
              )}
              <div>
                <SocialNetworksStyled />
              </div>
            </ContainerFooter>
          </NavMainContainer>
        </WrapperMobile>
      </FooterNavMain>
      <WrapperMobile>
        <NavMenuContainer>
          <CustomRow>
            {hasFooterLogos && (
              <Col md={{ size: 2 }}>
                {entity === "coimbra" && (
                  <>
                    <a href="https://transformabrasil.com.br" target="_blank" rel="noopener noreferrer">
                      <CustomLazyImageCoimbra src={imgTransformaBrasil} alt={"Transforma Brasil"} />
                    </a>
                    <a href="https://www.uc.pt/" rel="noopener noreferrer">
                      <CustomLazyImageCoimbra src={imgUC} alt={"UC Transforma"} />
                    </a>
                    <CustomLazyImageCoimbra src={imgForumEstudante} alt={"Forum Estudante"} />
                  </>
                )}
                {entity === "portugal" && (
                  <>
                    <CustomLazyImageCoimbra src={imgForumEstudante} alt={"Forum Estudante"} />
                    <CustomLazyImageCoimbra src={imgTransformaBrasil} alt={"Transforma Brasil"} />
                    <CustomLazyImageCoimbra src={imgUCTransforma} alt={"UC Transforma"} />
                  </>
                )}
              </Col>
            )}
            <Col
              md={{
                size: 7,
                offset: hasFooterLogos ? 0 : 2,
              }}
            >
              <ListFooter>
                {footerLinks.map((item, k) => {
                  const tag = item?.target ? Link : undefined;
                  const props = tag ? { href: item?.link, target: item?.target } : { to: item?.link };
                  return (
                    <MenuItemLinks key={k} tag={tag} {...props}>
                      {item.label}
                    </MenuItemLinks>
                  );
                })}
              </ListFooter>
            </Col>
          </CustomRow>
          <CopyText>
            © {COPYRIGHT_YEAR} {theme.brand.name}
          </CopyText>
        </NavMenuContainer>
      </WrapperMobile>
    </FooterStyled>
  );
};

export default Footer;

const ContainerFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 862px;
  width: 100%;
  @media (max-width: 670px) {
    justify-content: space-around;
  }
`;

const FooterStyled = styled.footer`
  border-top: ${rem(1)} solid ${({ theme }) => theme.v3.colors.neutralLightest};
  border-bottom: ${rem(10)} solid ${({ theme }) => theme.v3.colors.secondary};
`;

const FooterNavMain = styled(BSNavbar)`
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.v3.colors.neutralLightest};
  padding: ${({ theme }) => theme.v3.spacing.xl} 0;
`;

const NavMainContainer = styled(Container)`
  display: flex;
  justify-content: space-around;
`;

const NavbarBrand = styled(RSNavbarBrand)`
  padding: 0;
`;

const NavbarBrandLogo = styled.img`
  height: 40px;
`;

const SocialNetworksStyled = styled(SocialNetworks)`
  align-items: center;
  height: 100%;
  justify-content: flex-end;
`;

const NavMenuContainer = styled(Container)(
  ({ theme }) => css`
    padding-top: ${theme.v3.spacing.xl};
    padding-bottom: ${theme.v3.spacing.xl};
    position: relative;
  `
);

const CopyText = styled.span`
  font-size: ${({ theme }) => theme.v3.fontSize.xs};
  color: ${({ theme }) => theme.v3.colors.neutralLightness};
  position: absolute;
  bottom: 50px;
  right: 15px;

  ${mqV3.smDown} {
    display: none;
  }
`;

const CustomLazyImageCoimbra = styled(LazyImage)`
  max-height: 50px;
  margin: 15px;

  &:last-of-type {
    max-height: auto;
    width: 120px;
  }
`;

const Ul = styled.ul`
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0 -4px;
`;

const ListFooter = styled.ul`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0 -${theme.v3.spacing.sm};
    padding: 0;
  `}
`;

const CustomCol = styled(Col)`
  display: flex;
`;

const CustomRow = styled(Row)`
  ${mqV3.smDown} {
    flex-direction: column-reverse;
  }
`;
