import projectTypes from "./types";
import createReducer from "../createReducer";
import { axiosDefaultError } from "../../utils/helper";

const INITIAL_STATE = {
  loading: false,

  gpr: {
    loading: true,
    project_data: {
      name: "",
      resume: "",
      description: "",
      images: {
        profile: "",
        cover: "",
        gallery: [],
      },
      social_network: {
        facebook: "",
        instagram: "",
        pinterest: "",
        website: "",
      },
      conditionals: {
        causes: [],
        objectives: [],
        skills: [],
      },
      locations: {},
      contacts: [],
      video_youtube_url: [],
      created_at: "",
    },
    action_data: [],
    ods_data: [],
  },
  gps: {
    name: "",
    slug: "",
    resume: "",
    description: "",
    document_id: "",
    images: {
      logo: "",
      profile: "",
      cover: "",
    },
    video_youtube_url: [],
    social_network: {
      facebook: "",
      instagram: "",
      pinterest: "",
      website: "",
    },
    conditionals: {
      causes: [],
      interests: [],
      objectives: [],
      skills: [],
    },
    impact: {
      members: 0,
    },
    locations: [],
    contacts: [],
    type: "",
    loading: true,
  },
  pea: {
    loading: true,
    status: "",
    response: "",
  },
  npa: {
    loading: false,
    status: "",
  },
  adminProjects: {
    loading: false,
    status: "",
  },
  validateProject: {
    loading: false,
    status: "",
  },
  adminActions: {
    loading: false,
    status: "",
  },
  validateAction: {
    loading: false,
    status: "",
  },
};

const projectReducer = createReducer(INITIAL_STATE, {
  [projectTypes.PROJECT_REQUESTED]: (state) => ({
    ...state,
    loading: true,
    status: "waiting",
  }),
  [projectTypes.PROJECT_RECEIVED]: (state, action) => ({
    ...state,
    ...action.payload,
    success: true,
    loading: false,
    status: "received",
  }),
  [projectTypes.PROJECT_FAILED]: (state, action) => ({
    ...INITIAL_STATE,
    ...action.payload,
    loading: false,
    status: "failed",
  }),

  [projectTypes.GET_PROJECT_REQUESTED]: (state) => ({
    ...state,
    gpr: {
      loading: true,
      status: "waiting",
    },
  }),
  [projectTypes.GET_PROJECT_RECEIVED]: (state, action) => ({
    ...state,
    gpr: {
      ...action.payload,
      success: true,
      loading: false,
      status: "received",
    },
  }),
  [projectTypes.GET_PROJECT_FAILED]: (state, action) => ({
    ...state,
    gpr: {
      ...INITIAL_STATE.gpr,
      loading: false,
      status: "failed",
      error: {
        ...axiosDefaultError,
        ...action.payload,
      },
    },
  }),
  [projectTypes.CLEAN_PROJECT_DATA]: (state) => ({
    ...INITIAL_STATE,
    adminProjects: {
      ...state.adminProjects,
    },
  }),

  [projectTypes.PEA_REQUESTED]: (state) => ({
    ...state,
    pea: {
      loading: true,
      status: "waiting",
    },
  }),
  [projectTypes.PEA_RECEIVED]: (state, action) => ({
    ...state,
    pea: {
      ...action.payload,
      success: true,
      loading: false,
      status: "received",
    },
  }),
  [projectTypes.PEA_FAILED]: (state, action) => ({
    ...INITIAL_STATE,
    pea: {
      ...action.payload,
      loading: false,
      status: "failed",
    },
  }),

  [projectTypes.NPA_REQUESTED]: (state) => ({
    ...state,
    npa: {
      loading: true,
      status: "waiting",
    },
  }),
  [projectTypes.NPA_RECEIVED]: ({ pea, ...state }, { payload }) => ({
    ...state,
    npa: {
      ...payload,
      success: true,
      loading: false,
      status: "received",
    },
    pea: {
      ...pea,
      hasMore: payload.hasMore,
      response: [...pea.response, ...payload.response],
    },
  }),
  [projectTypes.NPA_FAILED]: (state, action) => ({
    ...INITIAL_STATE,
    npa: {
      ...action.payload,
      loading: false,
      status: "failed",
    },
  }),

  // Panel actions
  // =============

  // Projects
  // --------

  // Finds a project by its slug and updates its status.
  [projectTypes.IPPA_UPDATE_STATUS]: ({ adminProjects, gps, ...state }, { projectSlug, status }) => ({
    ...state,
    gps: {
      ...gps,
      projectStatus: gps.slug === projectSlug ? status : gps.projectStatus,
    },
    adminProjects: {
      ...adminProjects,
      response: (adminProjects.response || []).map(({ project_data, ...project }) => ({
        // Iterates over all listed projects
        ...project,
        project_data: {
          ...project_data,
          status: project_data.slug === projectSlug ? status : project_data.status, // If the right project, update its status.
        },
      })),
    },
  }),

  // Initial page projects for admin
  [projectTypes.IPPA_REQUESTED]: (state) => ({
    ...state,
    adminProjects: {
      loading: true,
      status: "waiting",
    },
  }),
  [projectTypes.IPPA_RECEIVED]: (state, action) => ({
    ...state,
    adminProjects: {
      ...action.payload,
      success: true,
      loading: false,
      status: "received",
    },
  }),
  [projectTypes.IPPA_FAILED]: (state, action) => ({
    ...state,
    adminProjects: {
      ...action.payload,
      loading: false,
      status: "failed",
    },
  }),

  // Next page projects for admin
  [projectTypes.NPPA_REQUESTED]: (state) => ({
    ...state,
    adminProjects: {
      ...state.adminProjects,
      loadingNP: true,
      status: "waiting",
    },
  }),
  [projectTypes.NPPA_RECEIVED]: (state, action) => ({
    ...state,
    adminProjects: {
      ...action.payload,
      success: true,
      loadingNP: false,
      status: "received",
    },
  }),
  [projectTypes.NPPA_FAILED]: (state, action) => ({
    ...state,
    adminProjects: {
      ...action.payload,
      response: state.adminProjects.response,
      loadingNP: false,
      status: "failed",
    },
  }),

  // Validate projects by admin
  [projectTypes.VPA_REQUESTED]: ({ adminProjects, validateProject, ...state }, { projectSlug }) => ({
    ...state,
    adminProjects: {
      ...adminProjects,
      response: (adminProjects.response || []).map((project) => ({
        ...project,
        updating:
          (project.project_data && project.project_data.slug) === projectSlug ? true : project.updating || false, // Set the updating flag to true if the project is being approved or rejected.
      })),
    },
    validateProject: {
      ...validateProject,
      loading: true,
      status: "waiting",
    },
  }),
  [projectTypes.VPA_RECEIVED]: ({ adminProjects, validateProject, ...state }, { projectSlug, payload }) => ({
    ...state,
    adminProjects: {
      ...adminProjects,
      response: (adminProjects.response || []).map((project) => ({
        ...project,
        updating:
          (project.project_data && project.project_data.slug) === projectSlug ? false : project.updating || false, // Set the updating flag to false for the project that had its status updated.
      })),
    },
    validateProject: {
      ...state.validateProject,
      success: true,
      status: "received",
    },
  }),
  [projectTypes.VPA_FAILED]: ({ adminProjects, validateProject, ...state }, { projectSlug, payload }) => ({
    ...state,
    adminProjects: {
      ...adminProjects,
      response: (adminProjects.response || []).map((project) => ({
        ...project,
        updating:
          (project.project_data && project.project_data.slug) === projectSlug ? false : project.updating || false, // Set the updating flag to false for the project that had its status updated.
      })),
    },
    validateProject: {
      ...state.validateProject,
      loading: false,
      status: "failed",
    },
  }),

  [projectTypes.GPS_REQUESTED]: (state) => ({
    ...state,
    gps: {
      loading: true,
      status: "waiting",
    },
  }),
  [projectTypes.GPS_RECEIVED]: (state, { payload: { status, ...payload } }) => ({
    ...state,
    gps: {
      ...payload,
      projectStatus: status,
      success: true,
      loading: false,
      status: "received",
    },
  }),
  [projectTypes.GPS_FAILED]: (state, action) => ({
    ...state,
    gps: {
      ...INITIAL_STATE.gpr,
      loading: false,
      status: "failed",
      error: {
        ...axiosDefaultError,
        ...action.payload,
      },
    },
  }),

  [projectTypes.PE_REQUESTED]: ({ gps }) => ({
    ...INITIAL_STATE,
    gps: gps,
    loading: true,
    status: "waiting",
  }),
  [projectTypes.PE_RECEIVED]: (state, action) => ({
    ...state,
    ...action.payload,
    success: true,
    loading: false,
    status: "received",
  }),
  [projectTypes.PE_FAILED]: (state, action) => ({
    ...state,
    loading: false,
    status: "failed",
    ...action.payload,
  }),

  // Profile
  [projectTypes.PROFILE_REQUESTED]: (state) => ({
    ...state,
    profile: {
      loading: true,
      status: "waiting",
    },
  }),
  [projectTypes.PROFILE_RECEIVED]: ({ profile, ...state }, action) => ({
    ...state,
    profile: {
      ...profile,
      ...action.payload,
      success: true,
      loading: false,
      status: "received",
    },
  }),
  [projectTypes.PROFILE_FAILED]: ({ profile, ...state }, action) => ({
    ...state,
    profile: {
      ...profile,
      loading: false,
      status: "failed",
      ...action.payload,
    },
  }),

  // Project activation/deactivation
  [projectTypes.TOGGLE_PROJECT_ACTIVATION_REQUESTED]: ({ gps, pea, ...state }, { projectSlug }) => ({
    ...state,
    gps: {
      ...gps,
      updatingStatus: gps.slug === projectSlug ? true : gps.updatingStatus,
    },
    pea: {
      ...pea,
      response:
        (pea &&
          pea.response &&
          pea.response.map((project) => {
            if (project.slug === projectSlug) {
              return { ...project, updatingStatus: true, updatingStatusStatus: "requested" };
            }
            return project;
          })) ||
        [],
    },
  }),
  [projectTypes.TOGGLE_PROJECT_ACTIVATION_RECEIVED]: ({ gps, pea, ...state }, { projectSlug, status, payload }) => ({
    ...state,
    gps:
      gps.slug === projectSlug // If the GPS is being edited is the same ... it should get its status updated.
        ? {
            ...gps,
            projectStatus:
              status === "active"
                ? "pending" /* If the it was activated by the user, it should pass through the admin review */
                : status,
            updatingStatus: false,
            udpatingStatusStatus: "received",
          }
        : gps,
    pea: {
      ...pea,
      response:
        (pea &&
          pea.response &&
          pea.response.map((project) => {
            if (project.slug === projectSlug) {
              return {
                ...project,
                projectStatus:
                  status === "active"
                    ? "pending" /* If the it was activated by the user, it should pass through the admin review */
                    : status,
                updatingStatus: false,
                updatingStatusStatus: "received",
              };
            }
            return project;
          })) ||
        [],
    },
  }),
  [projectTypes.TOGGLE_PROJECT_ACTIVATION_FAILED]: ({ gps, pea, ...state }, { projectSlug }) => ({
    ...state,
    gps: {
      ...gps,
      updatingStatus: gps.slug === projectSlug ? false : gps.updatingStatus,
    },
    pea: {
      ...pea,
      response:
        (pea &&
          pea.response &&
          pea.response.map((project) => {
            if (project.slug === projectSlug) {
              return { ...project, updatingStatus: false, updatingStatusStatus: "failed" };
            }
            return project;
          })) ||
        [],
    },
  }),
});
export default projectReducer;
