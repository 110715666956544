import { createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import { RequestableState } from "core/api/definitions";
import decoreThunk from "core/decorators/decorate";

import { getListDetailVolunteerAppointmentsModal } from "./action";

import requestLoading from "core/decorators/requestLoading";
import { logout } from "core/auth";

import { ListAppointmentModal, ModalDetailsVolunteer } from "core/api/users/listDetailVolunteerAppointmentsModal";

interface IState extends RequestableState, ModalDetailsVolunteer {}

const panelBetaListVolunteersAppointmentsModalSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "panelBetaListVolunteersAppointmentsModalSlice",
  initialState: {
    loading: "idle",
    action_data: {
      title: "",
      _id: "",
    },
    project_data: {
      _id: "",
    },
    subscribe_data: {
      address: {
        street: "",
        number: "",
        complement: "",
        district: "",
        city: "",
        state: "",
        country: "",
        zipcode: "",
        coordinates: [0, 0],
      },
      description: "",
      function_title: "",
      title: "",
      _id: "",
    },
    subscribe_registration: {
      day: "",
      time_end: "",
      time_start: "",
      _id: "",
      status: "",
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, getListDetailVolunteerAppointmentsModal, [
      requestLoading(),
      {
        pending: (state: IState) => {
          state.loading = "loading";
        },
        fulfilled: (state: IState, action: PayloadAction<ListAppointmentModal>) => {
          state.loading = "ok";
          if (action?.payload?.params) {
            state.action_data = action.payload.params.action_data;
            state.project_data = action.payload.params.project_data;
            state.subscribe_data = action.payload.params.subscribe_data;
            state.subscribe_registration = action.payload.params.subscribe_registration;
          }
        },
        rejected: (state: IState) => {
          state.loading = "error";
        },
      },
    ]);
    builder.addCase(logout.toString(), (state) => {
      state.loading = "idle";
    });
  },
});

export default panelBetaListVolunteersAppointmentsModalSlice;
