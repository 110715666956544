import { AxiosResponse } from "axios";
import http from "config/http";
import { ResponseStatus, ResponseWithParams } from "../definitions";

export interface UnsilentChat {
  chatID: string;
}

export type UnsilentChatReponse = ResponseWithParams<ResponseStatus, []>;

export default async ({ chatID }: UnsilentChat): Promise<AxiosResponse<UnsilentChatReponse>> =>
  http.get<UnsilentChatReponse>(`/chat/${chatID}/unsilent`);
