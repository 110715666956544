import { createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import { RequestableState } from "core/api/definitions";
import decoreThunk from "core/decorators/decorate";
import {
  ListAllChatWithProjects,
  ListAllChatWithVolunteers,
  ShowConversationChat,
  FilterProjectsContacts,
  FilterVolunteersContacts,
  StartNewChat,
} from "./actions";
import requestLoading from "core/decorators/requestLoading";
import { logout } from "core/auth";
import { ListAllWithProjects, ListAllWithProjectsResponse } from "core/api/chat/ListAllChatWithProjects";
import { ListAllWithVolunteers, ListAllWithVolunteersResponse } from "core/api/chat/ListAllChatWithVolunteers";
import { ShowConversation, ShowConversationResponse } from "core/api/chat/ShowConversationChat";
import { FilterProjectsContactsInterface, FilterProjectsContactsResponse } from "core/api/chat/FilterProjectsContacts";
import {
  FilterVolunteersContactsInterface,
  FilterVolunteersContactsResponse,
} from "core/api/chat/FilterVolunteersContacts";
import { StartNewChatResponse } from "core/api/chat/StartNewChat";

interface IStateListAllChatWithProjects extends RequestableState, ListAllWithProjects {}
interface IStateListAllChatWithVolunteers extends RequestableState, ListAllWithVolunteers {}
interface IStateShowConversationChat extends RequestableState, ShowConversation {}
interface IStateFilterProjectsContacts extends RequestableState {
  params: FilterProjectsContactsInterface[];
}
interface IStateFilterVolunteersContacts extends RequestableState {
  params: FilterVolunteersContactsInterface[];
}
interface IStateStartNewChat extends RequestableState {
  params: {
    chat_id: string;
  };
}

export const panelBetaListAllChatWithProjectsSlice = createSlice<
  IStateListAllChatWithProjects,
  SliceCaseReducers<IStateListAllChatWithProjects>
>({
  name: "panel/beta/chat/listAllChatWithProjects",
  initialState: {
    loading: "idle",
    chat_contacts: [],
    groups: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, ListAllChatWithProjects, [
      requestLoading(),
      {
        pending: (state: IStateListAllChatWithProjects) => {
          state.loading = "loading";
        },
        fulfilled: (state: IStateListAllChatWithProjects, action: PayloadAction<ListAllWithProjectsResponse>) => {
          state.loading = "ok";
          if (action?.payload?.params) {
            state.chat_contacts = action.payload.params.chat_contacts;
            state.groups = action.payload.params.groups;
          }
        },
        rejected: (state: IStateListAllChatWithProjects) => {
          state.loading = "error";
        },
      },
    ]);
    builder.addCase(logout.toString(), (state) => {
      state.loading = "idle";
    });
  },
});

export const panelBetaListAllChatWithVolunteersSlice = createSlice<
  IStateListAllChatWithVolunteers,
  SliceCaseReducers<IStateListAllChatWithVolunteers>
>({
  name: "panel/beta/chat/listAllChatWithVolunteers",
  initialState: {
    loading: "idle",
    chat_contacts: [],
    groups: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, ListAllChatWithVolunteers, [
      requestLoading(),
      {
        pending: (state: IStateListAllChatWithVolunteers) => {
          state.loading = "loading";
        },
        fulfilled: (state: IStateListAllChatWithVolunteers, action: PayloadAction<ListAllWithVolunteersResponse>) => {
          state.loading = "ok";
          if (action?.payload?.params) {
            state.chat_contacts = action.payload.params.chat_contacts;
            state.groups = action.payload.params.groups;
          }
        },
        rejected: (state: IStateListAllChatWithVolunteers) => {
          state.loading = "error";
        },
      },
    ]);
    builder.addCase(logout.toString(), (state) => {
      state.loading = "idle";
    });
  },
});

export const panelBetaShowConversationSlice = createSlice<
  IStateShowConversationChat,
  SliceCaseReducers<IStateShowConversationChat>
>({
  name: "panel/beta/chat/showConversation",
  initialState: {
    loading: "idle",
    chat: {
      _id: "",
      user_id: "",
      owner_id: "",
      project_id: "",
      action_id: null,
      receiver_data: {
        name: "",
        photo: "",
      },
    },
    messages: [
      {
        _id: "",
        data: [
          {
            _id: "",
            sender: "",
            receiver: "",
            message: "",
            read: false,
            created_at: "",
            name: "",
          },
        ],
      },
    ],
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, ShowConversationChat, [
      requestLoading(),
      {
        pending: (state: IStateShowConversationChat) => {
          state.loading = "loading";
        },
        fulfilled: (state: IStateShowConversationChat, action: PayloadAction<ShowConversationResponse>) => {
          state.loading = "ok";
          if (action?.payload?.params?.response) {
            state.chat = action.payload.params.response?.chat;
            state.messages = action.payload.params.response.messages;
          }
        },
        rejected: (state: IStateShowConversationChat) => {
          state.loading = "error";
        },
      },
    ]);
    builder.addCase(logout.toString(), (state) => {
      state.loading = "idle";
    });
  },
});

export const panelBetaFilterProjectsContacts = createSlice<
  IStateFilterProjectsContacts,
  SliceCaseReducers<IStateFilterProjectsContacts>
>({
  name: "panel/beta/chat/filterProjectsContacts",
  initialState: {
    loading: "idle",
    params: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, FilterProjectsContacts, [
      requestLoading(),
      {
        pending: (state: IStateFilterProjectsContacts) => {
          state.loading = "loading";
        },
        fulfilled: (state: IStateFilterProjectsContacts, action: PayloadAction<FilterProjectsContactsResponse>) => {
          state.loading = "ok";
          if (action?.payload?.params) {
            state.params = action.payload.params;
          }
        },
        rejected: (state: IStateFilterProjectsContacts) => {
          state.loading = "error";
        },
      },
    ]);
    builder.addCase(logout.toString(), (state) => {
      state.loading = "idle";
    });
  },
});

export const panelBetaFilterVolunteersContacts = createSlice<
  IStateFilterVolunteersContacts,
  SliceCaseReducers<IStateFilterVolunteersContacts>
>({
  name: "panel/beta/chat/filterVolunteersContacts",
  initialState: {
    loading: "idle",
    params: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, FilterVolunteersContacts, [
      requestLoading(),
      {
        pending: (state: IStateFilterVolunteersContacts) => {
          state.loading = "loading";
        },
        fulfilled: (state: IStateFilterVolunteersContacts, action: PayloadAction<FilterVolunteersContactsResponse>) => {
          state.loading = "ok";
          if (action?.payload?.params) {
            state.params = action.payload.params;
          }
        },
        rejected: (state: IStateFilterVolunteersContacts) => {
          state.loading = "error";
        },
      },
    ]);
    builder.addCase(logout.toString(), (state) => {
      state.loading = "idle";
    });
  },
});

export const panelBetaStartNewChat = createSlice<IStateStartNewChat, SliceCaseReducers<IStateStartNewChat>>({
  name: "panel/beta/chat/startNewChat",
  initialState: {
    loading: "idle",
    params: {
      chat_id: "",
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, StartNewChat, [
      requestLoading(),
      {
        pending: (state: IStateStartNewChat) => {
          state.loading = "loading";
        },
        fulfilled: (state: IStateStartNewChat, action: PayloadAction<StartNewChatResponse>) => {
          state.loading = "ok";
          if (action?.payload?.params) {
            state.params = action.payload.params;
          }
        },
        rejected: (state: IStateStartNewChat) => {
          state.loading = "error";
        },
      },
    ]);
    builder.addCase(logout.toString(), (state) => {
      state.loading = "idle";
    });
  },
});
