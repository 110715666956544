import { AxiosResponse } from "axios";
import http from "config/http";
import { ActionResponse } from "core/api/definitions";
import { CertificateGenerated } from "../definitions";
import { UniqueDocument } from "../users/getGeneralData";

export interface GenerateCertificatesRequest {
  slug: string;
  unique_document?: UniqueDocument;
}

export type GenerateCertificatesResponse = ActionResponse<CertificateGenerated[]>;

export default async ({
  slug,
  unique_document,
}: GenerateCertificatesRequest): Promise<AxiosResponse<GenerateCertificatesResponse>> =>
  http.get<GenerateCertificatesResponse>(
    `/v3/actions/${slug}/generate-certificates${
      unique_document
        ? `?unique_document[type]=${unique_document?.type || ""}&unique_document[value]=${unique_document?.value || ""}`
        : ""
    }`
  );
