import React from "react";
import styled, { css } from "styled-components/macro";
import { CONTAINER_BASE } from "utils/constants";
import mediaQueries from "utils/mediaQueries";

const BaseCentralized: React.FunctionComponent = ({ children, ...props }) => (
  <Container {...props} id="page-content">
    <InnerContainer>{children}</InnerContainer>
  </Container>
);

export default BaseCentralized;

const Container = styled.main`
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InnerContainer = styled.div`
  max-width: ${CONTAINER_BASE};

  ${mediaQueries.md(css`
    padding-left: 15px;
    padding-right: 15px;
  `)}
`;
