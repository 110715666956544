import React, { useCallback } from "react";
import styled, { css } from "styled-components";
import IconFontAwesome from "components/Icon/IconFontAwesome";
import { Input as BSInput } from "components/Form/index";
import { InputProps } from "reactstrap";
import { useTranslation } from "react-i18next";
import mqV3 from "utils/mediaQueriesV3";

interface SearchBaseProps extends InputProps {
  onSearch?(value: string): void;
}

const SearchBase = ({ className, placeholder, onSearch, onChange, ...props }: SearchBaseProps) => {
  const { t } = useTranslation("components");

  const onChangeCallback = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onSearch) onSearch(e.target.value);
      if (onChange) onChange(e);
    },
    [onSearch, onChange]
  );

  return (
    <Wrapper className={className}>
      <Input {...props} placeholder={placeholder || t("searchBase.placeholder")} onChange={onChangeCallback} />
      <IconFontAwesome icon={["far", "search"]} />
    </Wrapper>
  );
};

export default SearchBase;

const Wrapper = styled.div`
  position: relative;

  svg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: ${({ theme }) => theme.v3.spacing.md};
    margin: auto 0;
    color: ${({ theme }) => theme.v3.colors.neutralLightness};
  }
`;

//TODO(Jeconias): Add the focus and placeholder style
const Input = styled(BSInput)`
  width: 100%;
  height: auto;
  border-radius: 6px;

  ${({ theme }) => css`
    padding: ${theme.v3.spacing.md};
    padding-left: 56px;
    background-color: ${theme.v3.colors.neutralWhite};
    border: 1px solid ${theme.v3.colors.neutralLightness};
    box-shadow: ${theme.v3.boxShadow.l2};

    &,
    ::placeholder {
      ${mqV3.xsDown} {
        font-size: ${theme.v3.fontSize.sm};
      }
      font-size: ${theme.v3.fontSize.md};
      color: ${theme.v3.colors.neutralLight};
    }
  `}
`;
