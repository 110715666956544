import http from "config/http";
import { ResponseStatus, ResponseWithParams } from "core/api/definitions";
import { ActionsOpportunitiesRequest } from "core/api/projects/getActionsOpportunities";

const exportActionsAndOpportunities = ({ idProject, ...data }: ActionsOpportunitiesRequest) => {
  return http.post<ResponseWithParams<ResponseStatus, undefined>>(
    `/projects/${idProject}/export-opportunities-by-project`,
    data
  );
};

export default exportActionsAndOpportunities;
