import http from "config/http";

type Merchandise = {
  name: string;
  link?: string;
  logo?: string;
};
export interface UpdatePlatformContentRequest {
  id: string;
  highlight_text?: string;
  highlight_description?: string;
  has_sponsor?: boolean;
  image_cover?: string;
  url?: string;

  title?: string;
  description?: string;
  merchandising_section?: {
    title: string;
    items: Merchandise[];
  };
  about?: {
    title?: string;
    description?: string;
    image_cover?: string;
    body: {
      subtitle?: string;
      text?: string;
      images?: string[];
      images_excluded?: string[];
    };
  };
}

export interface UpdatePlatformContentResponse {}

const updateContent = ({ id, ...data }: UpdatePlatformContentRequest) =>
  http.patch<UpdatePlatformContentResponse>(`/platform-launcher/${id}/content`, data);

export default updateContent;
