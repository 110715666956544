import { ProjectType } from "core/api/definitions";
import { Origin, ProjectTypeData } from "core/api/definitions";
import { useCallback, useMemo } from "react";
import { useReduxSelector } from "./useReduxSelector";

export type ProjectTypeInfo<T = ProjectType> = { value: T; label: string };

const joinProjectType = (
  projectData?: ProjectTypeData
): { organization: ProjectTypeInfo[]; others: ProjectTypeInfo[] } => {
  const orgKeys = Object.keys(projectData?.organization || {});
  const ortherKeys = Object.keys(projectData?.others || {});
  return {
    organization: orgKeys.map((o) => ({
      value: o as ProjectType,
      label: ((projectData?.organization || {}) as any)[o] as string,
    })),
    others: ortherKeys.map((o) => ({
      value: o as ProjectType,
      label: ((projectData?.others || {}) as any)[o] as string,
    })),
  };
};

const useProjectType = () => {
  const raw = useReduxSelector((state) => (state.pagesNew.origin.params as Origin)?.project_type_data ?? {});

  const { organizationTypes, othersTypes, projectTypeJoined, projectTypes } = useMemo(() => {
    const result = joinProjectType(raw);
    const projectTypeJoined = [...result.organization, ...result.others];

    return {
      organizationTypes: result.organization,
      othersTypes: result.others,
      projectTypeJoined,
      projectTypes: projectTypeJoined.reduce((acc, curr) => ({ ...acc, [curr.value]: curr.label }), {}) as {
        [k: string]: ProjectType;
      },
    };
  }, [raw]);

  return {
    raw,
    organizationTypes,
    othersTypes,
    projectTypeJoined,
    projectTypes,
  };
};

export default useProjectType;
