const CVT_REQUESTED = "user/createVolunteer/REQUESTED";
const CVT_RECEIVED = "user/createVolunteer/RECEIVED";
const CVT_FAILED = "user/createVolunteer/FAILED";

const LGN_REQUESTED = "user/login/REQUESTED";
const LGN_RECEIVED = "user/login/RECEIVED";
const LGN_FAILED = "user/login/FAILED";

const LGT_REQUESTED = "user/logout/REQUESTED";
const LGT_RECEIVED = "user/logout/RECEIVED";
const LGT_FAILED = "user/logout/FAILED";

const USR_REQUESTED = "user/logged/REQUESTED";
const USR_RECEIVED = "user/logged/RECEIVED";
const USR_FAILED = "user/logged/FAILED";

const USE_REQUESTED = "user/get/to-edit/REQUESTED";
const USE_RECEIVED = "user/get/to-edit/RECEIVED";
const USE_FAILED = "user/get/to-edit/FAILED";

const USP_REQUESTED = "user/put/REQUESTED";
const USP_RECEIVED = "user/put/RECEIVED";
const USP_FAILED = "user/put/FAILED";

const USD_REQUESTED = "user/loadSupportData/REQUESTED";
const USD_RECEIVED = "user/loadSupportData/RECEIVED";
const USD_FAILED = "user/loadSupportData/FAILED";

// User profile
const UP_REQUESTED = "user/profile/REQUESTED";
const UP_RECEIVED = "user/profile/RECEIVED";
const UP_FAILED = "user/profile/FAILED";

const USV_REQUESTED = "user/validation/post/REQUESTED";
const USV_RECEIVED = "user/validation/post/RECEIVED";
const USV_FAILED = "user/validation/post/FAILED";

const USNT_REQUESTED = "user/validation/get/new-token/REQUESTED";
const USNT_RECEIVED = "user/validation/get/new-token/RECEIVED";
const USNT_FAILED = "user/validation/get/new-token/FAILED";

const RPA_REQUESTED = "user/reset/password/REQUESTED";
const RPA_RECEIVED = "user/reset/password/RECEIVED";
const RPA_FAILED = "user/reset/password/FAILED";

const GRP_REQUESTED = "user/get/recover/password/REQUESTED";
const GRP_RECEIVED = "user/get/recover/password/RECEIVED";
const GRP_FAILED = "user/get/recover/password/FAILED";

const RNP_REQUESTED = "user/new/password/REQUESTED";
const RNP_RECEIVED = "user/new/recover/password/RECEIVED";
const RNP_FAILED = "user/new/recover/password/FAILED";

const CLEAN_USER_DATA = "clean/user";

export default {
  CVT_REQUESTED,
  CVT_RECEIVED,
  CVT_FAILED,
  LGN_REQUESTED,
  LGN_RECEIVED,
  LGN_FAILED,
  LGT_REQUESTED,
  LGT_RECEIVED,
  LGT_FAILED,
  USR_REQUESTED,
  USR_RECEIVED,
  USR_FAILED,
  USD_REQUESTED,
  USD_RECEIVED,
  USD_FAILED,
  USE_REQUESTED,
  USE_RECEIVED,
  USE_FAILED,
  USP_REQUESTED,
  USP_RECEIVED,
  USP_FAILED,

  // User profile
  UP_REQUESTED,
  UP_RECEIVED,
  UP_FAILED,

  CLEAN_USER_DATA,
  USV_REQUESTED,
  USV_RECEIVED,
  USV_FAILED,
  USNT_REQUESTED,
  USNT_RECEIVED,
  USNT_FAILED,
  RPA_REQUESTED,
  RPA_RECEIVED,
  RPA_FAILED,
  GRP_REQUESTED,
  GRP_RECEIVED,
  GRP_FAILED,
  RNP_REQUESTED,
  RNP_RECEIVED,
  RNP_FAILED,
};
