import { createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import api from "core/api";
import { LoadingStatus, RequestableState } from "core/api/definitions";
import { ODSDetailsActionsResponse, ODSDetailsActionsResponseData } from "core/api/site/ods/detailsActions";
import { ODSDetailsSubscribeResponse, ODSDetailsSubscribeResponseData } from "core/api/site/ods/detailsSubscribe";
import decoreThunk from "core/decorators/decorate";
import requestLoading from "core/decorators/requestLoading";
import asyncThunk from "core/decorators/toolkit";

export const odsDetailsActions = asyncThunk("pages/ods/details/actions", api.site.ods.detailsActions, {
  condition: [({ isForce }, state) => !state.pagesNew.ods.action.response || !!isForce],
});

export const odsDetailsSubscribe = asyncThunk("pages/ods/details/subscribe", api.site.ods.detailsSubscribe, {
  condition: [({ isForce }, state) => !state.pagesNew.ods.subscribe.response || !!isForce],
});

type IState = RequestableState & {
  action: {
    pg?: number;
    loading: LoadingStatus;
    response?: ODSDetailsActionsResponseData;
  };
  subscribe: {
    pg?: number;
    loading: LoadingStatus;
    response?: ODSDetailsSubscribeResponseData;
  };
};

const odsSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "ods",
  initialState: {
    loading: "idle",
    action: {
      loading: "idle",
    },
    subscribe: {
      loading: "idle",
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, odsDetailsActions, [
      {
        pending: (state, action) => {
          state.action.loading = "loading";
          if (!(action as any).meta.arg?.pg) state.loading = "loading";
        },
        fulfilled: (state, action: PayloadAction<ODSDetailsActionsResponse>) => {
          state.loading = "ok";
          state.action.loading = "ok";

          if (
            (action as any).meta.arg?.pg <= 1 ||
            !(action as any).meta.arg?.pg ||
            action.payload.params?.actions_data.response?.length === 0
          ) {
            state.action.response = action.payload.params;
          } else {
            state.action.pg = (action as any).meta.arg?.pg;
            state.action.response = {
              ...((action.payload.params as any) ?? {}),
              actions_data: {
                ...(action.payload.params?.actions_data ?? {}),
                response: [
                  ...(state.action.response?.actions_data?.response ?? []),
                  ...(action.payload.params?.actions_data?.response ?? []),
                ],
              },
            };
          }
        },
        rejected: (state, _) => {
          state.action.loading = "error";
        },
      },
    ]);

    decoreThunk(builder, odsDetailsSubscribe, [
      {
        pending: (state, action) => {
          state.subscribe.loading = "loading";
          if (!(action as any).meta.arg?.pg) state.loading = "loading";
        },
        fulfilled: (state, action: PayloadAction<ODSDetailsSubscribeResponse>) => {
          state.loading = "ok";
          state.subscribe.loading = "ok";

          if (
            (action as any).meta.arg?.pg <= 1 ||
            !(action as any).meta.arg?.pg ||
            action.payload.params?.subscribe_data.response?.length === 0
          ) {
            state.subscribe.response = action.payload.params;
          } else {
            state.subscribe.pg = (action as any).meta.arg?.pg;
            state.subscribe.response = {
              ...((action.payload.params as any) ?? {}),
              subscribe_data: {
                ...(action.payload.params?.subscribe_data ?? {}),
                response: [
                  ...(state.subscribe.response?.subscribe_data.response ?? []),
                  ...(action.payload.params?.subscribe_data?.response ?? []),
                ],
              },
            };
          }
        },
        rejected: (state, _) => {
          state.subscribe.loading = "error";
        },
      },
    ]);
  },
});

export default odsSlice;
