import Cookies from "js-cookie";
import { Login } from "core/api/definitions";

const isLocal = /\.local\.transform\.click$/;
const isHomologation = /\.transform\.click$/;
const isProduction = /([^.]+)\.com\.br$/;

interface LoginWithEmail extends Login {
  email?: string;
}

function domain(): string {
  const shareableDomain = (window as any).$shareable_domain;
  if (shareableDomain) {
    return shareableDomain;
  }

  let match;
  const h = window.location.hostname;
  if (h.match(isLocal)) return ".local.transform.click";
  else if (h.match(isHomologation)) return ".transform.click";
  else if ((match = h.match(isProduction))) {
    return `.${match[1]}.com.br`;
  } else return h;
}

export default {
  remove(): void {
    Cookies.remove("user", {
      domain: domain(),
    });
    /* safeguard for the transition period */
    Cookies.remove("user");
    Cookies.remove("user", {
      domain: ".transform.click",
    });
    Cookies.remove("user", {
      domain: ".transformabrasil.com.br",
    });
    /* / safeguard for the transition period */
  },
  set(login: Login): void {
    Cookies.set("user", login, {
      expires: new Date(login.expires_at),
      domain: domain(),
    });
  },
  update(login: Partial<Login>): boolean {
    const user = this.get();
    if (user) {
      this.set({ ...user, ...login });
      return true;
    }
    return false;
  },
  get(): LoginWithEmail | undefined {
    const usr = this.getString();
    if (!usr) return undefined;
    try {
      return JSON.parse(usr!) as LoginWithEmail;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  },
  getString(): string | undefined {
    return Cookies.get("user");
  },
};
