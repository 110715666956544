import * as Sentry from "@sentry/browser";
import { DashProfile, Login } from "core/api/definitions";
import { loginData, loginUpdate, logout, updateDashProfile } from "core/auth";
import { login, signInUpGmailToken } from "core/auth/actions";
import cookies from "core/cookies";
import { get as getOrigin } from "core/pages/origin";
import { getGeneralData, updateEmail, updateGeneralData } from "core/panel/user/settings";
import { acceptTermsAndAge } from "core/panel/user/settings/actions";
import { emailCheckIdentity } from "core/panel/user/settings/validation";
import userTypes from "core/user/types";
import { createStoreNewUser } from "core/wizard/create/user";
import { updateStoreUser } from "core/wizard/update/user";
import { AnyAction, Dispatch, Middleware } from "redux";
import { STORE_KEYS } from "utils/constants";
import history from "utils/history";

const handleLogin: Middleware = (state) => (next: Dispatch<AnyAction>) => (action: AnyAction) => {
  if (action?.payload?.$login) {
    cookies.user.set({ ...action?.payload?.$login });
    next(loginUpdate(action?.payload?.$login));
  }

  switch (action.type) {
    case getOrigin.fulfilled.toString():
      {
        const usr = cookies.user.get() as Login;
        if (usr) {
          const lsDashProfile = localStorage.getItem(STORE_KEYS.DASHPROFILE);
          if (lsDashProfile) usr.selected_dash_profile = lsDashProfile! as DashProfile;
          next(loginData(usr));
        }
      }
      break;
    case updateDashProfile.toString():
      {
        const usr = cookies.user.get() as Login;
        if (usr) {
          usr.dash_profile = action.payload;
          cookies.user.set(usr);
        }
      }
      break;
    case login.fulfilled.toString():
    case signInUpGmailToken.fulfilled.toString():
    case acceptTermsAndAge.fulfilled.toString():
    case createStoreNewUser.fulfilled.toString():
    case updateStoreUser.fulfilled.toString():
    case updateEmail.fulfilled.toString():
    case emailCheckIdentity.fulfilled.toString():
    case userTypes.CVT_RECEIVED:
    case userTypes.USV_RECEIVED:
    case updateGeneralData.fulfilled.toString(): {
      const payload = action.type === userTypes.CVT_RECEIVED ? action.payload : action.payload?.params;
      const args: Partial<Login> = {};
      if (action.type === updateEmail.fulfilled.toString()) {
        args.email = action?.meta?.arg?.email;
      }

      const loginState = state.getState()?.login?.params || {};
      const newUserData = { ...loginState, ...payload, ...args };

      Sentry.setUser({
        id: newUserData.user_id,
        email: newUserData.email,
        username: newUserData.user_name,
      });

      cookies.user.set(newUserData);
      next(loginData(newUserData));

      const redirect = action.meta?.arg?.redirect;
      if (redirect) {
        setTimeout(() => {
          history.push(redirect);
        }, 500);
      }
      break;
    }
    case getGeneralData.fulfilled.toString():
      const params = action.payload?.params;
      const userData = { user_picture: params?.profile_image };
      cookies.user.update(userData);
      next(loginUpdate(userData));
      break;
    case logout.toString():
      cookies.user.remove();
      localStorage.removeItem(STORE_KEYS.DASHPROFILE);
      localStorage.removeItem("creating.vacancy");
      localStorage.removeItem("wizard.create.action");
      localStorage.removeItem("wizard.create.action.local");
      Sentry.setUser({});
      if (action.payload) {
        setTimeout(() => {
          history.replace(action.payload, history.location.state);
        }, 500);
      }
      break;
  }
  return next(action);
};

export default handleLogin;
