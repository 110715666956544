import { createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import api from "core/api";
import { GetProjectByOwnerResponse } from "core/api/projects/getProjectByOwner";
import decoreThunk from "core/decorators/decorate";
import asyncThunk from "core/decorators/toolkit";
import { LoadingStatus, RequestableState } from "core/api/definitions";
import { logout } from "core/auth";
import requestLoading from "core/decorators/requestLoading";
import { ShowProjectResponseData } from "core/api/projects/show";
import { ListProjectResponse } from "core/api/projects/list";
import { updateCoordinates } from "core/geolocation";

export const getProjectByOwner = asyncThunk("pages/project/getProjectByOwner", api.project.getProjectByOwner);

//TODO(Jeconias): Move to ./actions
export const listByProject = asyncThunk("pages/action/listByProject", api.actions.listByProject);

export const showProject = asyncThunk("pages/project/show", api.project.show, {
  condition: [({ projectSlug }, state) => !state.pagesNew?.project?.projects.list[projectSlug || ""]],
});

export const listProjects = asyncThunk("site/project/list", api.project.list, {
  condition: [({ isForce }, state) => !state.pagesNew.project.list?.response?.projects.length || !!isForce],
});

type IState = RequestableState & {
  list?: ListProjectResponse & { currentPage?: number };
  projects: {
    loading: LoadingStatus;
    list: { [k: string]: ShowProjectResponseData };
  };
  actionsByProject: {
    loading: LoadingStatus;
    list?: any;
  };
  byOwner: {
    loading: LoadingStatus;
    list?: GetProjectByOwnerResponse;
  };
};

const projectSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "projectActions",
  initialState: {
    loading: "idle",
    projects: {
      loading: "idle",
      list: {},
    },
    actionsByProject: {
      loading: "idle",
    },
    byOwner: {
      loading: "idle",
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, getProjectByOwner, [
      {
        pending: (state, _) => {
          state.byOwner.loading = "loading";
        },
        fulfilled: (state, action: any) => {
          state.byOwner.loading = "ok";
          state.byOwner.list = action?.payload;
        },
        rejected: (state, _) => {
          state.byOwner.loading = "error";
        },
      },
    ]);
    decoreThunk(builder, showProject, [
      requestLoading(),
      {
        pending: (state, _) => {
          state.projects.loading = "loading";
        },
        fulfilled: (state, action) => {
          const { projectSlug } = action.meta?.arg;
          const payload = action.payload?.params;

          state.projects.list![projectSlug] = payload;

          state.projects.loading = "ok";
        },
        rejected: (state, _) => {
          state.projects.loading = "error";
        },
      },
    ]);
    decoreThunk(builder, listByProject, [
      requestLoading(),
      {
        pending: (state, _) => {
          state.actionsByProject.loading = "loading";
        },
        fulfilled: (state, action) => {
          const payload = action.payload.response;
          state.actionsByProject.list = payload;
          state.actionsByProject.loading = "ok";
        },
        rejected: (state, _) => {
          state.actionsByProject.loading = "error";
        },
      },
    ]);
    decoreThunk(builder, listProjects, [
      requestLoading(),
      {
        fulfilled: (state, action: PayloadAction<ListProjectResponse>) => {
          if (
            (action as any).meta.arg?.pg <= 1 ||
            !(action as any).meta.arg?.pg ||
            action.payload?.response?.projects.length === 0
          ) {
            state.list = action.payload;
            state.list.currentPage = 1;
          } else {
            state.list = {
              ...state.list,
              ...action.payload,
              response: {
                projects: [...(state.list?.response?.projects ?? []), ...(action.payload?.response?.projects ?? [])],
                causes: [...(state.list?.response?.causes ?? []), ...(action.payload?.response?.causes ?? [])],
              },
            };
            state.list.currentPage = (action as any).meta.arg?.pg;
          }
        },
      },
    ]);
    builder.addCase(logout.toString(), (state) => {
      state.byOwner.list = undefined;
    });
    builder.addCase(updateCoordinates.toString(), (state) => {
      state.list = undefined;
    });
  },
});

export default projectSlice;
