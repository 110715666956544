import { retrieveNotifications } from "core/pages/notifications";
import { isValid } from "date-fns";
import { useReduxSelector } from "hooks/useReduxSelector";
import { useUser } from "hooks/useUser";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, ButtonProps } from "reactstrap";
import styled, { css } from "styled-components";
import { transition } from "utils/animation";
import Icon from "../Icons/Icon";
import NotificationContainer from "./Container";

interface ButtonNotificationsInterface extends ButtonProps {
  hasUnread?: boolean;
}

const Notifications = () => {
  const dispatch = useDispatch();
  const { isAdmin } = useUser();

  const [isOpen, setIsOpen] = useState(false);

  const { loading, notifications, pg, hasMore } = useReduxSelector((state) => ({
    notifications: state.pagesNew.notifications.list,
    pg: state.pagesNew.notifications.list?.currentPage || 0,
    hasMore: state.pagesNew.notifications.list?.hasMore,
    loading: state.pagesNew.notifications.loading,
  }));

  const requestNotifications = useCallback(
    async (currentPage?: number) => {
      await dispatch(retrieveNotifications({ pg: currentPage || pg + 1 }));
    },
    [pg]
  );

  useEffect(() => {
    (async () => {
      await requestNotifications(1);
    })();
  }, []);

  const notificationsMemorized = useMemo(
    () =>
      (notifications?.response || []).filter(
        (n) => isValid(new Date(n.created_at)) && !n.read && (!n.only_admin || (n.only_admin && isAdmin))
      ),
    [notifications, isAdmin]
  );

  return (
    <NotificationsStyled>
      <ButtonNotifications hasUnread={notificationsMemorized.length !== 0} onClick={() => setIsOpen((prev) => !prev)}>
        <Icon icon="bell" />
      </ButtonNotifications>
      <NotificationContainer
        isOpen={isOpen}
        notifications={notificationsMemorized || []}
        onLoadMore={requestNotifications}
        hasMore={hasMore}
        loading={loading}
      />
    </NotificationsStyled>
  );
};

export default Notifications;

const NotificationsStyled = styled.div`
  position: relative;
`;

const ButtonNotifications = styled(({ hasUnread, ...props }: ButtonNotificationsInterface) => <Button {...props} />)`
  &:hover {
    &:after {
      background: ${({ theme }) => theme.v3.colors.primaryDark};
      transition: ${transition.fast("background")};
    }
  }

  &:focus {
    box-shadow: none;
    border: 1px solid ${({ theme }) => theme.v3.colors.feedbackSupport};
  }

  ${({ hasUnread }) =>
    hasUnread &&
    css`
      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: ${({ theme }) => theme.v3.colors.primary};
        top: 6px;
        right: 13px;
      }
    `}
`;
