import React from "react";
import styled from "styled-components";

interface WrapperIconProps {
  tag?: React.ElementType;
  to?: string | Object;
}

const WrapperIcon: React.FC<WrapperIconProps> = ({ ...props }) => <WrapperIconStyled {...props} />;

export default WrapperIcon;

const WrapperIconStyled = styled(({ tag: Tag, to, ...props }: WrapperIconProps) =>
  Tag ? <Tag {...props} to={to} /> : <button {...props} />
)<WrapperIconProps>`
  background-color: ${({ theme }) => theme.v3.colors.neutralWhite};
  border: 1px solid ${({ theme }) => theme.v3.colors.neutralLightest};
  box-shadow: 0px 1px 2px #e2e6f4;
  border-radius: 8px;
  padding: ${({ theme }) => theme.v3.spacing.sm} ${({ theme }) => theme.v3.spacing.md};
  max-width: 72px;
  max-height: 56px;
`;
