import styled from "styled-components/macro";
import Button from "components/Button/Button";

const CallToAction = styled(Button)`
  box-shadow: 0 6px 20px 0 rgba(13, 51, 32, 0.1);
  padding: 0 ${(props) => (props.small ? 25 : 40)}px;
  height: ${(props) => (props.small ? 40 : 50)}px;
  line-height: ${(props) => (props.small ? 40 : 50)}px;
`;

export default CallToAction;
