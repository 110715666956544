import http from "config/http";
import {
  Action,
  AddressCoordinates,
  Coordinate,
  ListResponse,
  AddressWithCoordinates,
  RequestWithForce,
} from "../definitions";
import queryString from "query-string";
import { Cause, Interest, Skill } from "pages/ListActions/DataOfFilters";
import { convertLatLngToString } from "utils/helpers";

export type ListActionResponse = ListResponse<Action>;

export interface ListActionRequest extends RequestWithForce {
  term?: string;
  cc?: Cause[];
  fu?: Skill[];
  ci?: Interest[];
  rm?: string;
  wk?: string;
  pg?: number;
  filter_subscribes?: boolean;
  filter_materials?: boolean;
  address?: AddressWithCoordinates;
}

const list = ({ address, ...data }: ListActionRequest) => {
  const params = { ...data } as any;
  if (Array.isArray(address?.coordinates)) {
    params["address[coordinates]"] = convertLatLngToString(address?.coordinates);
  } else if (address?.coordinates) {
    params["address[coordinates][lat]"] = (address?.coordinates as Coordinate).lat;
    params["address[coordinates][lng]"] = (address?.coordinates as Coordinate).lng;
  }

  const qs = queryString.stringify(params, { arrayFormat: "bracket" });
  return http.get<ListActionResponse>(`/search/action${qs ? `?${qs}` : ""}`);
};

export default list;
