import http from "config/http";
import { ListResponse, OpportunitiesData, RequestWithForce } from "../definitions";

export type ListOpportunitiesByProjectResponse = Omit<ListResponse<unknown>, "response"> & {
  response: {
    opportunities_data: OpportunitiesData[];
  };
};

export interface ListOpportunitiesByProjectRequest extends RequestWithForce {
  projectSlug: string;
}

const listByProject = ({ projectSlug }: ListOpportunitiesByProjectRequest) =>
  http.get<ListOpportunitiesByProjectResponse>(`/projects/${projectSlug}/opportunities`);
export default listByProject;
