import { AxiosResponse } from "axios";
import http from "config/http";
import {
  AddressCoordinates,
  Conditional,
  RegistrationStatus,
  ResponseStatus,
  ResponseWithParams,
  UserDataShort,
} from "../definitions";

export interface listDetailsRequest {
  subscribeId: string;
  volunteerId: string;
}

export interface RegistrationProps {
  accept_terms: boolean;
  created_at: string;
  day: string;
  entity: string[];
  evaluation_message?: any;
  evaluation_rating?: number;
  message: string;
  project_owner_id: string;
  slot_id?: string;
  status: RegistrationStatus;
  subscribe_data: {
    action_id: string;
    slug: string;
    title: string;
    _id: string;
  };
  time_end: string;
  time_in_seconds: number;
  time_start: string;
  type_checkout: "manual" | null;
  updated_at: string;
  user_data: UserDataShort;
  _id: string;
}

export interface SubscribeValidation {
  action_title: string;
  registrations: RegistrationProps[];
  subscribe_title: string;
  _id: string;
}

export interface VolunteerValidation {
  counters: {
    actions_count: number;
    entity: string[];
    evaluation_rating_total: number;
    rating: number;
    subscribe_count_total: number;
    volunteer_count: number;
  };
  general: {
    locations: {
      city?: string;
      complement?: string;
      coordinates?: AddressCoordinates;
      country?: string;
      district?: string;
      number?: string;
      primary?: string;
      state?: string;
      street?: string;
      zipcode?: string;
    };
    contacts?: Contact[];
    name: string;
    profile_image: string;
    _id: string;
  };
  preferences: {
    conditionals: {
      causes: Conditional[];
      functions: Conditional[];
      interests: Conditional[];
      skills: Conditional[];
    };
    _id: string;
  };
}

interface Contact {
  _id: {
    $oid: string;
  };
  type: string;
  country: string;
  value: string;
  description: string | null;
  updated_at: {
    $date: {
      $numberLong: string;
    };
  };
  created_at: {
    $date: {
      $numberLong: string;
    };
  };
}
export type SubscribeValidationParams = {
  subscribe_registrations: SubscribeValidation;
  volunteer_data: VolunteerValidation;
};

export type VolunteerSubscribeResponse = ResponseWithParams<ResponseStatus, SubscribeValidationParams>;

export default async ({
  subscribeId,
  volunteerId,
}: listDetailsRequest): Promise<AxiosResponse<VolunteerSubscribeResponse>> => {
  return http.get(`/users/list-details-registration/subscribe/${subscribeId}/volunteer/${volunteerId}`);
};
