import http from "config/http";

export interface PlatformContentShowRequest {
  platformId: string;
}

export interface MerchandisingItem {
  link: string;
  logo: string;
  name?: string;
}

export interface Merchandising {
  title: string;
  items: MerchandisingItem[];
}

export interface PlatformContentShowResponse {
  _id: string;
  about: {
    title: string;
    description: string;
    image_cover: string;
    body: {
      text: string;
      subtitle: string;
      images: string[];
    };
  };
  home: {
    highlight_description: string;
    highlight_text: string;
    title: string;
    url: string;
    has_sponsor: boolean;
    description: string;
    image_cover: string;
    merchandising_section: Merchandising;
  };
}

const show = ({ platformId }: PlatformContentShowRequest) =>
  http.get<PlatformContentShowResponse>(`/platform-launcher/${platformId}/content`);

export default show;
