// Statics exports
import imageDefault from "../assets/imgs/default-images.png";

export const axiosDefaultError = {
  success: false,
  response: {
    code: 500,
    message: "contact.admin.lang",
  },
  params: [],
};

class Helper {
  static validatingFields(value, image) {
    if (value) {
      if (typeof value === "object" && value.length === 0) {
        return imageDefault;
      }
      return value;
    }

    // console.log("value", typeof value);

    if (!value && image) return imageDefault;

    switch (typeof value) {
      case "number":
        return 0;
      default:
        return "";
    }
  }

  //adaptar para mostrar error inesperados[API]
  static apiErrorsHandle(err) {
    let errors = [];
    if (err.params && err.params.length > 0) {
      for (let property in err.params) {
        errors.push(`${property}.${err.params[property]}`);
      }
      return errors;
    }
    return err;
  }

  static getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  static formatAddress(address) {
    if (address) {
      if (address.name) return address.name;

      const pieces = [];

      let st = [];

      if (address.street) st.push(address.street);
      if (address.number) st.push(address.number);

      if (st.length > 0) pieces.push(st.join(", "));

      st = [];
      if (address.district) st.push(address.district);
      if (address.city) st.push(address.city);

      if (st.length > 0) pieces.push(st.join(", "));

      st = [];
      if (address.state) st.push(address.state);
      if (address.country) st.push(address.country);
      if (st.length > 0) pieces.push(st.join(", "));

      return pieces.join(" - ");
    }

    return "";
  }

  static truncateText(text, limit) {
    if (text === null) return "";
    return text.length > limit ? text.substring(0, limit) + "..." : text;
  }
}

export default Helper;
