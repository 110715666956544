import { rem } from "polished";
import React from "react";
import styled, { css } from "styled-components/macro";
import { mq } from "utils/mediaQueries";
import WizardTrans from "wizard/v3/components/WizardTrans/WizardTrans";
import { ValidationError } from "core/api/definitions";
import { Helmet } from "react-helmet";
import DatePicker, { ReactDatePickerProps } from "wizard/v3/components/DatePicker/DatePicker";

import { BaseTitle } from "components/common/BaseTitle";
import SupportText from "components/common/SupportText";

import { Col } from "reactstrap";
import { PageEvents } from "../definitions/commonTypes";

interface InputVacancyDateBaseProps extends Pick<PageEvents<Date[]>, "error"> {
  title: string;
  description: string;
  helmet?: string;
  datePickerProps?: ReactDatePickerProps;
  classNameSubscribeDate?: string;
}

const InputVacancyDateBase = ({
  error,
  helmet,
  title,
  description,
  datePickerProps,
  classNameSubscribeDate,
}: InputVacancyDateBaseProps): JSX.Element => {
  return (
    <VacancyDateContainer className="vacancyDateContainer">
      <CustomCol tag="section" className="col-md-5 col-sm-12">
        {helmet && <Helmet title={helmet} />}
        <BaseTitle size="md">{title}</BaseTitle>
        <SupportText size="md">{description}</SupportText>
      </CustomCol>
      <Col tag="section" className={`${classNameSubscribeDate || "col-md-6 col-sm-12"}`}>
        <DatePicker onChange={() => console.info("onChanged")} {...datePickerProps} />
        {error && <WizardTrans>{error as ValidationError}</WizardTrans>}
      </Col>
    </VacancyDateContainer>
  );
};

export default InputVacancyDateBase;

export const containerLayout = css`
  display: flex;
  width: 100%;
  background-color: #f9fafc;
  padding: 50px 40px;
  border-radius: 6px;
  min-height: 448px;

  & > section:nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  ${mq.smDown} {
    padding: 40px 0;
    flex-direction: column;

    & > section:first-child {
      margin-bottom: ${rem(30)};
    }
  }
`;

const VacancyDateContainer = styled.div`
  ${containerLayout};
`;

const CustomCol = styled(Col)`
  padding-top: 32px;
`;
