import { createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import api from "core/api";
import { BlogHome } from "core/api/blog/home";
import { BlogPost, LoadingStatus, RequestableState } from "core/api/definitions";
import { decoreThunk2 } from "core/decorators/decorate";
import requestLoading from "core/decorators/requestLoading";
import asyncThunk from "core/decorators/toolkit";

export const blogHome = asyncThunk("blog/home", api.blog.home);

export const blogHomePosts = asyncThunk("blog/home/posts", api.blog.posts);

type IState = {
  data: Omit<BlogHome, "recent_posts">;
  recent_posts: {
    page?: number;
    per_page?: number;
    loading?: LoadingStatus;
    hasMore?: boolean;
    list: BlogPost[];
  };
} & RequestableState;

const homeSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "blogHome",
  initialState: {
    loading: "idle",
    data: {
      showcase: [],
      specials_posts: [],
      most_read: [],
      blog_terms: [],
    },
    recent_posts: {
      list: [],
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk2(builder, blogHome, [
      requestLoading(),
      {
        fulfilled: (state, action) => {
          const payload = action.payload?.data;
          state.data = payload;

          state.recent_posts.page = payload?.recent_posts?.page || 1;
          state.recent_posts.per_page = payload?.recent_posts?.per_page || 6;

          state.recent_posts.loading = "ok";
          state.recent_posts.list = payload?.recent_posts?.posts || [];
          state.recent_posts.hasMore = (payload?.recent_posts?.posts || []).length > 0;
        },
      },
    ]);

    decoreThunk2(builder, blogHomePosts, [
      {
        pending: (state, _) => {
          state.recent_posts.loading = "loading";
        },
        fulfilled: (state, action) => {
          const payload = action.payload?.data;
          const actualPage = state.recent_posts?.page || 0;
          const hasPost = (payload || []).length > 0;

          state.recent_posts.loading = "ok";
          state.recent_posts.list = [...(state.recent_posts.list || []), ...(payload || [])];
          state.recent_posts.hasMore = hasPost;
          state.recent_posts.page = hasPost ? actualPage + 1 : actualPage;
        },
        rejected: (state, _) => {
          state.recent_posts.loading = "error";
        },
      },
    ]);
  },
});

export default homeSlice;
