import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from "redux";
import { Loader } from "@googlemaps/js-api-loader";
import { Origin } from "core/api/definitions";
import { GOOGLE_MAPS_API_KEY } from "utils/constants";
import { RootState } from "core/state";

let googleMapsInstalled = false;

const googleMaps: Middleware = (store: MiddlewareAPI<Dispatch, RootState>) => (next: Dispatch<AnyAction>) => (
  action: AnyAction
) => {
  if (GOOGLE_MAPS_API_KEY && !googleMapsInstalled) {
    googleMapsInstalled = true;

    new Loader({
      apiKey: GOOGLE_MAPS_API_KEY,
      version: "weekly",
      libraries: ["places"],
      language: (store.getState()?.pagesNew?.origin?.params as Origin)?.language || "pt-BR",
    })
      .load()
      .then(() => console.log("GoogleMaps Loaded"))
      .catch((e) => console.error("Error on load GoogleMaps"));
  }
  return next(action);
};

export default googleMaps;
