import http from "config/http";
import { ResponseStatus, ResponseWithParams } from "../../definitions";
import { ProgramDataShare } from "core/api/definitions";
import { AxiosResponse } from "axios";

interface GetAllPrivacyRequest {}

export interface GetAllPrivacyResponseData {
  _id?: string;
  program_data_share: ProgramDataShare[];
  intern_search_data?: boolean;
}

type GetAllPrivacyResponse = ResponseWithParams<ResponseStatus, GetAllPrivacyResponseData[]>;

const getAll = async ({}: GetAllPrivacyRequest = {}): Promise<AxiosResponse<GetAllPrivacyResponse>> =>
  await http.get<GetAllPrivacyResponse>(`/users/privacy`);

export default getAll;
