import { readableColor, rem } from "polished";
import React from "react";
import styled, { css } from "styled-components/macro";
import ButtonBase, { ButtonBaseProps } from "./ButtonBase";

interface ButtonOutlineProps extends ButtonBaseProps {
  outlineColor?: "neutralWhite";
}

const ButtonOutlineComp: React.FC<ButtonOutlineProps> = ({ children, outlineColor, ...props }) => {
  return <ButtonBase {...props}>{children}</ButtonBase>;
};

/**
 * @deprecated
 */
const ButtonOutline = styled(ButtonOutlineComp)(
  ({ theme, selected, outlineColor }) => css`
    border: ${rem(1)} solid ${outlineColor ? (theme.v3.colors as any)[outlineColor!] : theme.v3.colors.primary};
    color: ${outlineColor ? (theme.v3.colors as any)[outlineColor!] : theme.v3.colors.primary};
    background: transparent;

    &:focus {
      border: ${rem(1)} solid ${theme.v3.colors.feedbackSupport};
      outline: none;
    }

    &:hover {
      border: ${rem(1)} solid ${outlineColor ? (theme.v3.colors as any)[outlineColor!] : theme.v3.colors.primaryLight};
      color: ${readableColor(
        outlineColor ? (theme.v3.colors as any)[outlineColor!] : theme.v3.colors.neutralWhite,
        theme.v3.colors.neutralWhite,
        theme.v3.colors.primary
      )};
      background-color: ${readableColor(
        outlineColor ? (theme.v3.colors as any)[outlineColor!] : theme.v3.colors.primaryLight,
        theme.v3.colors.neutralWhite,
        theme.v3.colors.primary
      )};
    }

    &:active {
      border: ${rem(1)} solid ${outlineColor ? (theme.v3.colors as any)[outlineColor!] : theme.v3.colors.primaryDark};
      color: ${outlineColor ? (theme.v3.colors as any)[outlineColor!] : theme.v3.colors.primaryDark};
    }

    &:disabled {
      background: ${theme.v3.colors.neutralBackground};
      color: ${theme.v3.colors.neutralLightness};
    }

    ${selected &&
    css`
      color: ${readableColor(
        outlineColor ? (theme.v3.colors as any)[outlineColor!] : theme.v3.colors.primary,
        theme.v3.colors.primary,
        theme.v3.colors.neutralWhite
      )};
      background-color: ${readableColor(
        outlineColor ? (theme.v3.colors as any)[outlineColor!] : theme.v3.colors.primary,
        theme.v3.colors.neutralWhite,
        theme.v3.colors.primary
      )};
    `};
  `
);

export default ButtonOutline;
