import { AxiosResponse } from "axios";
import http from "config/http";
import { DeliveryWay } from "core/api/definitions";
import { ResponseWithParams, ResponseStatus } from "core/api/definitions";
import { Address } from "core/api/definitions";
import { MaterialItemInfo } from "wizard/v3/pages/donation/DonationListItems";

export interface StoreNewListMaterialRequest {
  action: string;

  // Data
  title: string;
  delivery_date: string; //2020-06-20 00:00:00
  delivery_way: DeliveryWay;
  address?: Address;
  action_address?: boolean;
  items: MaterialItemInfo[];
}

export type StoreNewListMaterialResponse = ResponseWithParams<
  ResponseStatus,
  {
    slug: string;
    _id: string;
  }
>;

const storeNewListMaterial = ({
  action,
  ...data
}: StoreNewListMaterialRequest): Promise<AxiosResponse<StoreNewListMaterialResponse>> =>
  http.post<StoreNewListMaterialResponse>(`/actions/${action}/donors/materials`, data);

export default storeNewListMaterial;
