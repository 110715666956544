import React from "react";
import { Input } from "reactstrap";
import styled from "styled-components/macro";

const Select = ({ children, ...props }) => (
  <Container>
    <Arrow />
    <StyledSelect {...props} type="select">
      {children}
    </StyledSelect>
  </Container>
);

const Container = styled.div`
  position: relative;
`;

export const Arrow = styled.div`
  position: absolute;
  top: -6px;
  right: 15px;
  bottom: 0;
  margin: auto 0;
  height: 9px;
  width: 9px;
  border: 2px solid #bcc4de;
  border-left: 0;
  border-top: 0;
  transform: rotate(45deg);
`;

const StyledSelect = styled(Input)`
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-progress-appearance: none;
  appearance: none;
  height: 40px;
  border: 1px solid #bcc4de;
  border-radius: 4px;
  padding-right: 17px;
  color: ${({ theme }) => theme.colors.support_text};
`;

export default Select;
export { StyledSelect as BaseSelect };
