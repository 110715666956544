import { createGlobalStyle } from "styled-components";
import { transition } from "utils/animation";

const TransitionStyles = createGlobalStyle`
.fade-in-enter {
    opacity: 0;
}

.fade-in-enter-active {
    opacity: 1;
    transition: ${transition.slow("opacity")};
}

.fade-in-enter-done {
    opacity: 1;
    transition: ${transition.slow("opacity")};
}

.fade-in-exit {
    opacity: 1;
}

.fade-in-exit-active {
    opacity: 0;
    transition: ${transition.normal("opacity")};
}

.from-right-to-left-enter {
    opacity: 0;
    margin-left: 100px;

.from-right-to-left-enter-active {
    opacity: 1;
    margin-left: 0;
    transition: ${transition.normal("opacity", "margin-left")};
}

.from-right-to-left-exit {
    opacity: 1;
    margin-left: 0;
}

.from-right-to-left-exit-active {
    opacity: 0;
    margin-left: -100px;
    transition: ${transition.normal("opacity", "margin-left")};
}

.homeshowcase-enter {
  opacity: 0.01;
}

.homeshowcase-enter.homeshowcase-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.homeshowcase-leave {
  opacity: 1;
}

.homeshowcase-leave.homeshowcase-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}

@keyframes beatHeart {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.15);
  }
  40% {
    transform: scale(1);
  }
  60% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}
`;

export default TransitionStyles;
