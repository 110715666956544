import routes from "routes";

const rules = {
  title: "required|min:2|max:60|string",
  delivery_date: "required|regex:/^\\d{4}-\\d{2}-\\d{2}$/",
  delivery_way: "required|in:fisically,in_loco,to_combine",

  //Item
  items: "required|array",
  "items.*.title": "required|min:2|max:60|string",
  "items.*.quantity": "required|integer",
};

const rulesWithAddress = {
  ...rules,
  "locations.country": "required",
  "locations.state": "required",
  "locations.city": "required",
  "locations.coordinates": "required",
};

const donationValidation = {
  rules,
  rulesWithAddress,
  routes: {
    create: [
      {
        field: [
          "title",
          "delivery_date",
          "delivery_way",
          "locations.country",
          "locations.state",
          "locations.city",
          "locations.coordinates",
        ],
        route: routes.wizard.project.action.donation.new.info,
      },
      {
        field: ["items", "items.*.title", "items.*.quantity"],
        route: routes.wizard.project.action.donation.new.items,
      },
    ],
  },
};

export default donationValidation;
