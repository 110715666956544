import React, { useCallback } from "react";
import { rem } from "polished";
import styled from "styled-components/macro";
import { BaseProps, PageEvents } from "wizard/v3/pages/definitions/commonTypes";
import { Centered } from "wizard/v3/components/common/styles";
import Clue from "wizard/v3/components/Clue/Clue";
import Input from "wizard/v3/components/Input/Input";
import ActionsFooter from "wizard/v3/components/Layout/ActionsFooter";
import FormWizard from "./common/FormWizard";
import { WizardContainer } from "wizard/v3/components/Layout/Wizard";
import { TitleContainer } from "wizard/v3/components/Layout/Content";
import Text from "v3/components/common/Text";
import { mq } from "utils/mediaQueries";
import WizardTrans from "wizard/v3/components/WizardTrans/WizardTrans";
import { ValidationError } from "core/api/definitions";

interface InputSelectOptionProps<T> extends BaseProps, PageEvents<T> {
  value?: T;
}

const InputSelectOption = <T extends {}>({
  title,
  clue,
  value,
  onChange,
  onContinue,
  onBack,
  placeholder,
  children,
  error,
}: React.PropsWithChildren<InputSelectOptionProps<T>>) => {
  const onChangeCallback = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) onChange(e.target.value as any);
    },
    [onChange]
  );

  const onContinueCallback = useCallback(() => {
    if (!value) return;
    if (onContinue) onContinue(value!);
  }, [onContinue, value]);

  return (
    <FormWizard onContinue={onContinueCallback}>
      <WizardContainer>
        <Centered>
          <TitleContainer>
            <CustomText tag="h1" size="lg">
              {title}
            </CustomText>
          </TitleContainer>
          <CustomInput type="select" value={value} onChange={onChangeCallback}>
            <option value="">{placeholder}</option>
            {children}
          </CustomInput>
          {error && (
            <Centered>
              <WizardTrans>{error as ValidationError}</WizardTrans>
            </Centered>
          )}
          {clue && <Clue text={clue} />}
        </Centered>
      </WizardContainer>
      <ActionsFooter onBack={onBack} continueDisabled={!value} />
    </FormWizard>
  );
};

export default InputSelectOption;

const CustomInput = styled(Input)`
  width: 100%;
  min-width: 420px;
  text-align: center;
  text-align-last: center;
  color: #657d9b;
  font-size: ${rem(14)};
  line-height: ${rem(22)};
  letter-spacing: 0;
  padding: 10px;
`;

const CustomText = styled(Text)`
  ${mq.xsDown} {
    text-align: center;
  }
`;
