import React from "react";
import { default as BaseDatePicker, ReactDatePickerProps as BaseReactDatePickerProps } from "react-datepicker";
import styled, { css } from "styled-components/macro";
import { rem } from "polished";
import { useMediaQueries } from "utils/hooks/useMediaQueries";
import usePlatformLanguage from "hooks/usePlatformLanguage";

const BASE_SIZE = 50;

export interface ReactDatePickerProps extends BaseReactDatePickerProps {
  arrowTop?: boolean;
}

const DatePicker: React.FC<ReactDatePickerProps> = ({
  className,
  arrowTop,
  disabled,
  onSelect,
  onChange,
  ...props
}) => {
  const { isMD, isSM, isXS } = useMediaQueries();
  const isMobile = isMD || isSM || isXS;

  const datePickerProps = isMobile || arrowTop ? { arrowTop: true } : {};
  const actions = disabled ? { onChange: () => ({}), ...props } : { onSelect, onChange, ...props };
  const { language } = usePlatformLanguage();

  return (
    <DatePickerStyled className={className} {...datePickerProps} disabled={disabled}>
      <BaseDatePicker locale={language.length ? language : "pt-BR"} {...actions} />
    </DatePickerStyled>
  );
};

export default DatePicker;

const disabledStyle = css`
  color: #cdd6e2;
  background-color: #f9fafc;

  :hover {
    border-color: #e2e6f4;
    background-color: #f9fafc;
    color: #cdd6e2;
  }
`;

const DatePickerStyled = styled.div<{ arrowTop?: boolean; disabled?: boolean }>`
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .react-datepicker {
    width: 100%;
    border: 0;
    background-color: transparent;

    &__navigation {
      top: 10px;
      margin: 47% 0;
      width: 27px;
      height: 27px;
      border: 2px solid #9facbd;
      border-bottom: 0;
      border-right: 0;
      background-color: transparent;
      transition: 0.3s;

      :focus {
        outline: none;
        border-color: ${({ theme }) => theme.colors.secondary};
      }

      :hover {
        border-color: ${({ theme }) => theme.colors.secondary};
      }

      &--previous {
        left: -30px;
        transform: rotate(-45deg);
      }
      &--next {
        right: -30px;
        transform: rotate(135deg);
      }

      ${({ arrowTop }) =>
        arrowTop &&
        css`
          width: 12px;
          height: 12px;
          margin: 0;

          &--previous {
            left: 0;
          }
          &--next {
            right: 0;
          }
        `};
    }

    &__month-container {
      width: 100%;
    }

    &__header {
      ${({ arrowTop }) =>
        !arrowTop &&
        css`
          text-align: left;
        `};
      background-color: transparent;
      border: 0;
    }

    &__current-month {
      color: #657d9b;
      font-size: ${rem(15)};
      line-height: ${rem(22)};
      letter-spacing: 0;
      font-weight: 500;
      padding-bottom: 24px;
      text-transform: capitalize;
      ${({ arrowTop }) =>
        !arrowTop &&
        css`
          padding-bottom: 10px;
        `};
    }

    &__day-names {
      display: flex;
      height: ${BASE_SIZE}px;
      background-color: #fff;
      border: 2px solid #e2e6f4;
      border-bottom: 0;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      margin: 0;
    }
    &__day-name {
      width: 100%;
      color: #657d9b;
      font-size: ${rem(13)};
      line-height: ${rem(50)};
      letter-spacing: 0;
      margin: 0;
    }
    &__month {
      margin: 0;
      border: 0;
      overflow: hidden;

      & > div:first-child {
        border-top: 1px solid #e2e6f4;
      }

      & > div:last-child {
        border-bottom: 1px solid #e2e6f4;
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;

        div:first-child {
          border-bottom-left-radius: 6px;
        }
        div:last-child {
          border-bottom-right-radius: 6px;
        }
      }
    }
    &__week {
      display: flex;
      border: 1px solid #e2e6f4;
      border-top: 0;
      border-bottom: 0;
      background-color: #e2e6f4;
    }
    &__day {
      height: ${BASE_SIZE}px;
      width: 100%;
      margin: 0;
      border: 1px solid #e2e6f4;
      color: ${({ theme }) => theme.colors.support_text};
      transition: 0.3s;
      border-radius: 0;
      background-color: #fff;
      font-size: ${rem(15)};
      line-height: ${rem(22)};
      letter-spacing: 0;

      :focus {
        outline: none;
      }

      :hover {
        color: ${({ theme }) => theme.colors.secondary};
        border-color: ${({ theme }) => theme.colors.secondary};
      }

      &--today {
        color: ${({ theme }) => theme.colors.secondary};
        background-color: ${({ disabled }) => (disabled ? "#f9fafc" : "#fff")};

        &:hover {
          color: #fff;
          border-color: #e2e6f4;
          background-color: ${({ theme }) => theme.colors.secondary};
        }
      }

      &--highlighted {
        color: #fff;
        background-color: ${({ theme }) => theme.colors.secondary};

        :hover {
          color: ${({ theme }) => theme.colors.secondary};
          border-color: ${({ theme }) => theme.colors.secondary};
          background-color: transparent;
        }
      }

      ${({ disabled }) =>
        disabled &&
        css`
          ${disabledStyle};
        `}

      &--disabled {
        ${disabledStyle};
      }
    }

    &__day-name,
    &__day {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
