import { AxiosResponse } from "axios";
import http from "config/http";
import { ActionResponse, CertificateSignature } from "core/api/definitions";

export interface GenerateCertificatesRequest {
  actionSlug: string;
}

export interface CertificateGenerated {
  certificate_data: {
    _id: string;
    user: string;
    action: string;
    subscribe: string;
    unique_document: string;
    document_brazilian_cpf: string;
    date_participation: {
      start: string;
      end: string;
    };
    functions: string;
    quantity_time_in_hours: number;
  };
  signature_data: CertificateSignature;
  qrcode_image: string;
  code: string;
}

export type GenerateCertificatesResponse = ActionResponse<CertificateGenerated>;

export default async ({
  actionSlug,
}: GenerateCertificatesRequest): Promise<AxiosResponse<GenerateCertificatesResponse>> =>
  http.get<GenerateCertificatesResponse>(`/v3/actions/${actionSlug}/generate-certificates`);
