import { AxiosResponse } from "axios";
import http from "config/http";
import { ResponseStatus, ResponseWithParams } from "../definitions";
import { ActionData, CausesEditAction } from "../definitions";

interface EditRequest {
  action: string;
}

export interface EditResponseParams {
  action_data: ActionData;
  causes: CausesEditAction[];
}

export type EditResponse = ResponseWithParams<ResponseStatus, EditResponseParams>;

export default async ({ action }: EditRequest): Promise<AxiosResponse<EditResponse>> =>
  http.get<EditResponse>(`/actions/${action}/edit`);
