import { AxiosResponse } from "axios";
import http from "config/http";
import { ActionResponse } from "core/api/definitions";

export interface exportReportVolunteersRequest {
  date_start?: string;
  date_end?: string;
  entities: string[];
  cities?: { city: string; state: string }[];
  states?: string[];
  report_types?: string[];
}

export type exportReportVolunteersResponse = ActionResponse;

const exportReportVolunteers = async ({
  date_start,
  date_end,
  entities,
  cities,
  states,
  report_types,
}: exportReportVolunteersRequest): Promise<AxiosResponse<exportReportVolunteersResponse>> => {
  return http.post<exportReportVolunteersResponse>(`/admin/dashboard/export-volunteer-report`, {
    date_start,
    date_end,
    entities,
    cities,
    states,
    report_types,
  });
};

export default exportReportVolunteers;
