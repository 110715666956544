import React from "react";
import { Spinner as RSSpinner } from "reactstrap";
import styled from "styled-components/macro";
import { ColorV3 } from "utils/types";

interface SpinnerProps {
  className?: string;
  color?: ColorV3;
}

const SpinnerComponent = ({ ...props }: SpinnerProps) => {
  return <RSSpinner {...props} />;
};

const Spinner = styled(({ ...props }) => <SpinnerComponent {...props} color={undefined} />)`
  width: 20px;
  height: 20px;
  border: 2px solid ${({ theme, color }) => theme.v3.colors[color ?? "primary"]};
  border-right-color: ${({ theme }) => theme.v3.colors.neutralLightest};
`;

export default Spinner;
