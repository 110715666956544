import { createSlice, SliceCaseReducers, PayloadAction } from "@reduxjs/toolkit";
import { ListState, RequestableState } from "core/api/definitions";
import decoreThunk from "core/decorators/decorate";
import { ListAppointmentsByMonth, ListAppointmentsByDate } from "./actions";
import { AppointmentsByMonth, getListAppointmentsByMonthReponse } from "core/api/projects/listAppointmentsByMonth";
import { getListAppointmentsByDateReponse, AppointmentsByDate } from "core/api/projects/listAppointmentsByDate";
import requestLoading from "core/decorators/requestLoading";
import { logout } from "core/auth";

interface IStateByMonth extends RequestableState, AppointmentsByMonth {}
interface IStateByDate extends RequestableState, AppointmentsByDate {}

export const panelListProjectAppointmentsByMonthSlice = createSlice<IStateByMonth, SliceCaseReducers<IStateByMonth>>({
  name: "panel/beta/listAppointmentsByMonth",
  initialState: {
    loading: "idle",
    appointments: [],
    all_appointments_of_month: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, ListAppointmentsByMonth, [
      requestLoading(),
      {
        pending: (state: IStateByMonth) => {
          state.loading = "loading";
        },

        fulfilled: (state: IStateByMonth, action: PayloadAction<getListAppointmentsByMonthReponse>) => {
          state.loading = "ok";

          if (action?.payload?.params?.appointments) {
            state.appointments = action.payload.params.appointments;
          }
          if (action?.payload?.params?.all_appointments_of_month) {
            state.all_appointments_of_month = action.payload.params.all_appointments_of_month;
          }
        },
        rejected: (state: IStateByMonth) => {
          state.loading = "error";
        },
      },
    ]);

    builder.addCase(logout.toString(), (state) => {
      state.loading = "idle";
      //state.opportunities_data = {};
    });
  },
});

export const panelListProjectAppointmentsByDateSlice = createSlice<IStateByDate, SliceCaseReducers<IStateByDate>>({
  name: "panel/beta/listAppointmentsByMonth",
  initialState: {
    loading: "idle",
    appointments: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, ListAppointmentsByDate, [
      requestLoading(),
      {
        pending: (state: IStateByDate) => {
          state.loading = "loading";
        },

        fulfilled: (state: IStateByDate, action: PayloadAction<getListAppointmentsByDateReponse>) => {
          state.loading = "ok";

          if (action?.payload?.params?.appointments) {
            state.appointments = action.payload.params.appointments;
          }
        },
        rejected: (state: IStateByDate) => {
          state.loading = "error";
        },
      },
    ]);

    builder.addCase(logout.toString(), (state) => {
      state.loading = "idle";
      //state.opportunities_data = {};
    });
  },
});
