import { createSlice, SliceCaseReducers, PayloadAction } from "@reduxjs/toolkit";
import { ProjectType } from "core/api/definitions";

export type NewProject = {
  slug: string;
  title?: string;
  type?: ProjectType;
  profileImage?: string;
};

interface IState {
  new?: NewProject;
}

const projectSliceList = createSlice<IState, SliceCaseReducers<IState>>({
  name: "wizard/list/project",
  initialState: {},
  reducers: {
    listNewProject(state, action: PayloadAction<NewProject>) {
      state.new = action.payload;
    },
  },
});

export const { listNewProject } = projectSliceList.actions;

export default projectSliceList;
