import { formatDistance as _formatDistance, format as _format, formatRelative as _formatRelative } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import es from "date-fns/locale/es";
import { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt";

export const currentYear = new Date().getFullYear();
export const lastYear = currentYear - 1;

export const locales: { [k: string]: Locale } = {
  "pt-BR": ptBR,
  pt: pt,
  es: es,
  "es-CL": es,
};

Object.entries(locales).map(([key, locale]) => registerLocale(key, locale));

let defaultLng = "pt-BR";

export const setDefaultLanguage = (lng: string): void => {
  defaultLng = lng;
};

interface Options {
  language?: string;
  includeSeconds?: boolean;
  addSuffix?: boolean;
  locale?: Locale;
}

export const formatDistance = (date: Date, baseDate: Date, options?: Options) =>
  _formatDistance(date, baseDate, {
    ...options,
    locale: locales[options?.language || defaultLng],
  });

export const format = (date: Date, format?: string, options?: Options) =>
  _format(date, format || "dd/MM/yyyy", {
    ...options,
    locale: locales[options?.language || defaultLng],
  });

export const formatDateTimeForAPI = (date: Date | string, options?: Options) => {
  let v: Date;
  if (typeof date === "string") {
    v = new Date(date);
  } else {
    v = date;
  }
  return _format(v, "yyyy-MM-dd", {
    locale: locales[options?.language || defaultLng],
  });
};

export const formatDateNumericLong = (date: Date, options?: Options) =>
  _format(date, "P", {
    ...options,
    locale: locales[options?.language || defaultLng],
  });

const apiDateFormat = /^(\d{4})-(\d{2})-(\d{2})$/,
  fullDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?Z$/;

export const convertToDate = (dt: string | Date): Date | undefined => {
  if (dt instanceof Date) return dt;
  let m = dt.match(apiDateFormat);
  if (m) {
    /*
      new Date("2020-09-18"); // 17 de setembro de 2020
      new Date(2020, 9, 18); // 18 de Outubro de 2020
    */
    return new Date(parseInt(m[1]), parseInt(m[2]) - 1, parseInt(m[3]));
  }
  m = dt.match(fullDateFormat);
  if (m) {
    return new Date(dt);
  }
  return undefined;
};

export const dateFromAPI = (dt: string): Date | undefined => {
  let m = dt.match(apiDateFormat);
  if (m) {
    /*
      new Date("2020-09-18"); // 17 de setembro de 2020
      new Date(2020, 9, 18); // 18 de Outubro de 2020
    */
    return new Date(parseInt(m[1]), parseInt(m[2]) - 1, parseInt(m[3]));
  }
  m = dt.match(fullDateFormat);
  if (m) {
    return new Date(dt);
  }
  return undefined;
};

export const formatDateLong = (date: Date | string, options?: Options) =>
  _format(convertToDate(date)!, "dd' de 'LLLL' de 'yyyy", {
    ...options,
    locale: locales[options?.language || defaultLng],
  });

export const formatYear = (date: Date | string, options?: Options) =>
  _format(convertToDate(date)!, "yyyy", {
    ...options,
    locale: locales[options?.language || defaultLng],
  });

export const formatFullMonthYear = (date: Date | string, options?: Options) =>
  _format(convertToDate(date)!, "MMMM yyyy", {
    ...options,
    locale: locales[options?.language || defaultLng],
  });

export const formatDayAndFullMonth = (date: Date | string, options?: Options) =>
  _format(convertToDate(date)!, "dd MMM", {
    ...options,
    locale: locales[options?.language || defaultLng],
  });

export const formatTimeShort = (date: Date | string, options?: Options) => {
  return _format(convertToDate(date)!, "HH:mm", {
    ...options,
    locale: locales[options?.language || defaultLng],
  });
};

export const formatRelative = (date: Date, baseDate: Date, options?: Options) =>
  _formatRelative(date, baseDate, {
    ...options,
    locale: locales[options?.language || defaultLng],
  });

export const isDateEqual = (first: Date, second: Date) =>
  first.getDate() === second.getDate() &&
  first.getMonth() === second.getMonth() &&
  first.getFullYear() === second.getFullYear();

export const convertDateApiToLocal = (apiDate: string | Date, convertTime?: boolean) => {
  const apiDateConverted = new Date(apiDate);

  if (!convertTime)
    return new Date(apiDateConverted.getUTCFullYear(), apiDateConverted.getUTCMonth(), apiDateConverted.getUTCDate());

  return new Date(
    apiDateConverted.getUTCFullYear(),
    apiDateConverted.getUTCMonth(),
    apiDateConverted.getUTCDate(),
    apiDateConverted.getUTCHours(),
    apiDateConverted.getUTCMinutes()
  );
};

export const timeShortToTodayDate = (time: string, useLocal?: boolean) => {
  const timeSplit = time.split(":");
  const date = new Date();

  if (timeSplit.length === 2) {
    if (!useLocal) {
      date.setUTCHours(parseInt(timeSplit[0]));
      date.setUTCMinutes(parseInt(timeSplit[1]));
      date.setUTCSeconds(0);
    } else {
      date.setHours(parseInt(timeSplit[0]));
      date.setMinutes(parseInt(timeSplit[1]));
      date.setSeconds(0);
    }
  }

  return date;
};

export const secondsToHours = (seconds: number) => {
  return Math.round(seconds / 60 / 60);
};

export const formatDate = (date: Date) => {
  return `${date.getDate().toString().padStart(2, "0")}/${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${date.getFullYear()}`;
};

// Função para converter a data legível por humanos em formato YYYY-MM-DD

//entrada: 30 de setembro de 2023
//saída:  2023-09-30
export const convertReadableDate = (date: string): string => {
  const months: { [key: string]: string } = {
    janeiro: "01",
    fevereiro: "02",
    março: "03",
    abril: "04",
    maio: "05",
    junho: "06",
    julho: "07",
    agosto: "08",
    setembro: "09",
    outubro: "10",
    novembro: "11",
    dezembro: "12",
  };

  const parts = date.split(" ");
  const day = parts[0].padStart(2, "0");
  const month = months[parts[2]?.toLowerCase() as string];
  const year = parts[4];
  return `${year}-${month}-${day}`;
};

export const formatFullDayMonthYear = (date: Date | string, options?: Options) =>
  _format(convertToDate(date)!, "dd/MM/yyyy", {
    ...options,
    locale: locales[options?.language || defaultLng],
  });

export const formatFullDayMonthYearToAPI = (date: Date | string, options?: Options) => {
  let v: Date;

  if (typeof date === "string" && date.includes("/")) {
    const [day, month, year] = date.split("/").map((part) => parseInt(part, 10));
    v = new Date(year, month - 1, day);
  } else if (typeof date === "string") {
    v = new Date(date);
  } else {
    v = date;
  }

  return _format(v, "yyyy-MM-dd", {
    locale: locales[options?.language || defaultLng],
  });
};

export const stringToDate = (dateString: string): Date | undefined => {
  const parts = dateString.split("/");
  if (parts.length !== 3) return undefined;

  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1;
  const year = parseInt(parts[2], 10);

  return new Date(year, month, day);
};

export const ConvertDateShowLong = (dateString: string): string => {
  const newDate = stringToDate(dateString);
  return newDate ? formatDateLong(newDate) : dateString;
};
