import { AxiosResponse } from "axios";
import http from "config/http";
import { ResponseStatus, ResponseWithParams } from "../definitions";

export interface UpdateUserPreferencesRequest {
  id: string;

  // data
  use_geolocation: boolean;
  conditionals?: {
    causes?: string[];
    interests?: string[];
    skills?: string[];
  };
}

export type UpdatePreferencesResponse = ResponseWithParams<ResponseStatus, any>;

const updatePreferences = async ({
  id,
  ...data
}: UpdateUserPreferencesRequest): Promise<AxiosResponse<UpdatePreferencesResponse>> => {
  return http.patch<UpdatePreferencesResponse>(`/users/preferences/${id}`, data);
};

export default updatePreferences;
