import { UniqueDocumentType } from "core/api/users/getGeneralData";
import useCountry from "./useCountry";

const documentsMask: {
  [E in UniqueDocumentType]: string;
} = {
  cpf: "999.999.999-99",
  cnpj: "99.999.999/9999-99",
  nif: "999999999",
  rut: "9999999**",
};

const useMask = () => {
  const { personalDocumentType, juridicDocumentType } = useCountry();

  return {
    personalDocument: documentsMask[personalDocumentType],
    juridicDocument: documentsMask[juridicDocumentType],
  };
};

export default useMask;
