import React from "react";
import styled from "styled-components/macro";
import PropTypes from "prop-types";
import { Modal } from "reactstrap";
import { rem, darken } from "polished";
import Button from "components/Button/Button";
import { Input } from "components/Form";
import { Trans } from "react-i18next";
import { CloseButtonPopup } from "components/common/style";

export const CustomFansModal = styled(Modal)`
  .modal-content {
    background-color: transparent;
    border: 0;
    overflow: hidden;
  }

  .modal-content > .modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const BaseFansContainer = ({ children, onClose, ...props }) => (
  <FansContainer {...props}>
    <CloseButtonPopup onClick={() => onClose && onClose()} />
    {children}
  </FansContainer>
);

BaseFansContainer.propTypes = {
  onClose: PropTypes.func,
};

export const FansContainer = styled.div`
  position: relative;
  padding: 0 30px;
  background-color: #ffcd00;
  border-radius: 8px;
  display: flex;
  justify-content: center;
`;

export const Step = styled.p`
  color: #00a032;
  text-align: center;
  font-size: ${rem(15)};
  line-height: ${rem(20)};
`;

export const Title = styled.h3`
  color: #00a032;
  font-weight: 500;
  text-align: center;
  font-size: ${rem(25)};
  line-height: ${rem(27)};
  letter-spacing: -0.5px;
  margin-bottom: ${({ marginBottom }) => marginBottom && marginBottom};
  margin-top: ${({ marginTop }) => marginTop && marginTop};
  width: ${({ width }) => width && width};
`;

export const Text = styled.p`
  color: #00a032;
  margin: 15px 0 30px;
  text-align: center;
  font-size: ${rem(15)};
  line-height: ${rem(20)};
  text-align: center;
`;

const CustomButton = styled(Button)`
  max-width: 230px;
  width: 100%;
  display: block;
  margin: auto;
  padding: 10px 0;
  margin-top: ${({ marginTop }) => marginTop && marginTop};
`;

export const CurrentStep = styled.span`
  display: block;
  color: #00a032;
  font-size: ${rem(15)};
  letter-spacing: 0;
  line-height: ${rem(20)};
  margin-bottom: 10px;
`;

export const TitleStep = styled.h1`
  color: #00a032;
  font-size: ${rem(25)};
  letter-spacing: ${rem(-0.5)};
  line-height: ${rem(27)};
  font-weight: 500;
  margin: 0;
  text-align: center;
`;

export const BaseInput = styled(Input)`
  border: 0;
  border-radius: 6px;
  background-color: #fff;
  min-height: 58px;
  margin-top: 10px;
  color: #8798ad;
  letter-spacing: 0;
  font-size: ${rem(15)};
  line-height: ${rem(25)};

  :focus {
    box-shadow: none;
  }
`;

export const Infos = styled.div`
  text-align: center;
  padding-bottom: 40px;
`;

export const SimpleContainer = styled.div`
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
`;

export const LinkStep = ({ text, runStep }) => (
  <CustomTrans>
    <Trans ns="components" i18nKey={text} components={[<ButtonLink onClick={() => runStep()} />]} />
  </CustomTrans>
);

const ButtonLink = styled.button`
  color: ${({ theme }) => theme.colors.secondary};
  border: none;
  font-size: ${rem(13)};
  line-height: ${rem(20)};
  background-color: transparent;
  :focus {
    outline: none;
  }
  :hover {
    color: ${({ theme }) => darken(0.1, theme.colors.secondary)};
  }
`;

const CustomTrans = styled.div`
  display: flex;
  justify-content: center;
  min-width: 320px;
  font-size: ${rem(13)};
  line-height: ${rem(20)};
  margin-top: 15px;
  color: #00a032;
`;

export { CustomButton as BaseButton };
