import { ModalFooter as BSModalFooter } from "reactstrap";
import styled from "styled-components/macro";
import { rem } from "polished";

const ModalFooter = styled(BSModalFooter)`
  display: block;
  text-align: center;
  color: ${(props) => props.theme.colors.support_text};
  font-size: ${rem(13)};
  line-height: ${rem(20)};
  border-top-color: #e2e6f4;
  padding: 18px 30px 20px;
`;

export default ModalFooter;
