import { ODSType } from "components/Icon/ODSIcon";

export type Cause =
  | "acoes-emergenciais"
  | "cidadania"
  | "cidades-inteligentes"
  | "combate-a-pobreza"
  | "consumo-consciente"
  | "criancas"
  | "cultura-e-arte"
  | "direitos-humanos"
  | "educacao"
  | "esportes"
  | "geracao-de-renda"
  | "idosos"
  | "igualdade-de-genero"
  | "inclusao"
  | "meio-ambiente"
  | "mulheres"
  | "musica"
  | "pessoas-com-cancer"
  | "pessoas-com-deficiencia"
  | "pessoas-com-doencas-raras"
  | "pessoas-em-situacao-de-rua"
  | "protecao-animal"
  | "refugiados"
  | "saude"
  | "treinamento-profissional"
  | "dependentes-quimicos";

export type CauseChile =
  | "situación de emergencia"
  | "ciudadanía"
  | "ciudades inteligentes"
  | "superación de la pobreza"
  | "consumo Consciente"
  | "niños y adolescentes"
  | "arte e cultura"
  | "derechos humanos"
  | "educación"
  | "Deportes"
  | "generación de ingreso"
  | "anciano"
  | "igualdad de género"
  | "inclusión general"
  | "medio ambiente"
  | "mujeres"
  | "canción"
  | "personas-con-cancer"
  | "personas con deficiencia"
  | "personas-con-enfermedades-raras"
  | "gente-en-la-calle-situada"
  | "protección animal"
  | "refugiados"
  | "Salud"
  | "entrenamiento profesional"
  | "dependientes químicos";

//Functions
export type Skill =
  | "assistente-social"
  | "medicoa"
  | "fonoaudiologo"
  | "massoterapeuta"
  | "professora"
  | "design-de-interior"
  | "gastronomia"
  | "mestre-de-obras"
  | "designer-grafico"
  | "advogado"
  | "terapeuta-ocupacional"
  | "nutricionista"
  | "enfermeiro"
  | "psicologoa"
  | "lider-religioso"
  | "digital-influencer"
  | "estudante"
  | "dentista"
  | "empresario"
  | "comunicacao-foto-videos-e-redes-sociais"
  | "arquiteto"
  | "webdesigner"
  | "outros"
  | "engenheiro"
  | "universitario-indicar-curso"
  | "voluntario"
  | "administrador"
  | "contabilista"
  | "chef"
  | "gestor-de-rh"
  | "musico"
  | "fotografo"
  | "veterinario"
  | "desenvolvedor-de-sistemas"
  | "jornalista"
  | "educador-fisico"
  | "esteticista"
  | "fisioterapeuta";

export type SkillChile =
  | "Asistente social"
  | "médico"
  | "logopeda"
  | "masajista"
  | "profesor"
  | "diseño de interiores"
  | "gastronomía"
  | "Maestro constructor"
  | "diseñador gráfico"
  | "abogado"
  | "terapeuta ocupacional"
  | "nutricionista"
  | "enfermera"
  | "psicólogo"
  | "líder religioso"
  | "influencer digital"
  | "estudiante"
  | "dentista"
  | "persona de negocios"
  | "comunicacion-foto-videos-y-redes-sociales"
  | "arquitecto"
  | "Diseñador web"
  | "otros"
  | "ingeniero"
  | "universidad-indicar-curso"
  | "voluntario"
  | "administrador"
  | "contador"
  | "cocinero"
  | "Gerente de Recursos Humanos"
  | "músico"
  | "fotógrafo"
  | "veterinario"
  | "Desarrollador de sistemas"
  | "periodista"
  | "educador físico"
  | "cosmetólogo"
  | "fisioterapeuta";

//abilities
export type Interest =
  | "ajudar-com-recreacao"
  | "cantar"
  | "contar-historias"
  | "cortar-cabelo"
  | "cozinhar"
  | "criar-artes-digitais"
  | "dancar"
  | "decorar"
  | "encenar-teatro"
  | "fazer-as-unhas"
  | "fazer-instalacao-eletrica"
  | "fazer-escova"
  | "fazer-massagem"
  | "fotografar"
  | "liderar-e-mobilizar"
  | "limpar"
  | "maquiar"
  | "organizar-e-arrumar"
  | "pintar"
  | "produzir-videos"
  | "restaurar-moveis"
  | "tocar-algum-instrumento"
  | "trabalhar-com-baloes"
  | "trabalhar-com-hidraulica"
  | "voluntario"
  | "outros";

export type InterestChile =
  | "ayuda-con-recreación"
  | "canta"
  | "contar historias"
  | "Corte de pelo"
  | "cocinar"
  | "crear-artes-digitales"
  | "bailar"
  | "para decorar"
  | "teatro de escenario"
  | "hacer las uñas"
  | "hacer-instalacion-electrica"
  | "cepillar"
  | "hacer masaje"
  | "fotografía"
  | "liderar y movilizar"
  | "limpio"
  | "inventar"
  | "arreglar y ordenar"
  | "pintar"
  | "producir-videos"
  | "restaurar-muebles"
  | "tocar algún instrumento"
  | "trabajo-con-globos"
  | "trabajo-con-hidráulica"
  | "voluntario"
  | "otros";

export type Objective =  // TODO: Continue the list
  | "reducao-de-desigualdades"
  | "agua-potavel-e-saneamento"
  | "energia-limpa-e-acessivel"
  | "consumo-e-producao-responsavel"
  | "acao-contra-a-mudanca-global-do-clima"
  | "trabalho-descente-e-crescimento-economico"
  | "industria-inovacao-e-infraestrutura";

export type StrBoolean = "true";

type BaseType = {
  _id: string;
  thumbnail?: string;
  slug: string;
};

interface ODS extends BaseType {
  reference: ODSType;
  description?: string;
  ods_num?: number;
  page_title?: string;
  color_primary?: string;
}

export interface Option {
  label: string;
  ODSs?: ODS[];
  value: Cause | Skill | Interest | StrBoolean | CauseChile | SkillChile | InterestChile | State;
  checked: boolean;
  param?: string;
}

export interface Filter {
  id: string;
  title: string;
  options: Option[];
}

export const causes: Option[] = [
  {
    label: "Ações Emergênciais",
    value: "acoes-emergenciais",
    checked: false,
  },
  {
    label: "Cidadania",
    value: "cidadania",
    checked: false,
  },
  {
    label: "Cidade Inteligentes",
    value: "cidades-inteligentes",
    checked: false,
  },
  {
    label: "Combate à Pobreza",
    value: "combate-a-pobreza",
    checked: false,
  },
  {
    label: "Consumo Consciente",
    value: "consumo-consciente",
    checked: false,
  },
  {
    label: "Crianças",
    value: "criancas",
    checked: false,
  },
  {
    label: "Cultura e Arte",
    value: "cultura-e-arte",
    checked: false,
  },
  {
    label: "Direitos Humanos",
    value: "direitos-humanos",
    checked: false,
  },
  {
    label: "Educação",
    value: "educacao",
    checked: false,
  },
  {
    label: "Esportes",
    value: "esportes",
    checked: false,
  },
  {
    label: "Idosos",
    value: "idosos",
    checked: false,
  },
  {
    label: "Igualdade de Gênero",
    value: "igualdade-de-genero",
    checked: false,
  },
  {
    label: "Inclusão",
    value: "inclusao",
    checked: false,
  },
  {
    label: "Meio Ambiente",
    value: "meio-ambiente",
    checked: false,
  },
  {
    label: "Mulheres",
    value: "mulheres",
    checked: false,
  },
  {
    label: "Música",
    value: "musica",
    checked: false,
  },
  {
    label: "Pessoas com Câncer",
    value: "pessoas-com-cancer",
    checked: false,
  },
  {
    label: "Pessoas com Deficiência",
    value: "pessoas-com-deficiencia",
    checked: false,
  },
  {
    label: "Pessoas com Doenças Raras",
    value: "pessoas-com-doencas-raras",
    checked: false,
  },
  {
    label: "Pessoas em Situação de Rua",
    value: "pessoas-em-situacao-de-rua",
    checked: false,
  },
  {
    label: "Proteção Animal",
    value: "protecao-animal",
    checked: false,
  },
  {
    label: "Refugiados",
    value: "refugiados",
    checked: false,
  },
  {
    label: "Saúde",
    value: "saude",
    checked: false,
  },
  {
    label: "Treinamento Profissional",
    value: "treinamento-profissional",
    checked: false,
  },
  {
    label: "Dependentes Químicos",
    value: "dependentes-quimicos",
    checked: false,
  },
];

export const causesChile: Option[] = [
  {
    label: "Situación de emergencia",
    value: "acoes-emergenciais",
    checked: false,
  },
  {
    label: "Ciudadanía",
    value: "cidadania",
    checked: false,
  },
  {
    label: "Ciudad inteligente",
    value: "cidades-inteligentes",
    checked: false,
  },
  {
    label: "Superación de la pobreza",
    value: "combate-a-pobreza",
    checked: false,
  },
  {
    label: "Consumo Consciente",
    value: "consumo-consciente",
    checked: false,
  },
  {
    label: "Niños y Adolescentes",
    value: "criancas",
    checked: false,
  },
  {
    label: "Arte y cultura",
    value: "cultura-e-arte",
    checked: false,
  },
  {
    label: "Derechos humanos",
    value: "direitos-humanos",
    checked: false,
  },
  {
    label: "Educación",
    value: "educacao",
    checked: false,
  },
  {
    label: "Deportes",
    value: "esportes",
    checked: false,
  },
  {
    label: "Adulto mayor",
    value: "idosos",
    checked: false,
  },
  {
    label: "Igualdad de género",
    value: "igualdade-de-genero",
    checked: false,
  },
  {
    label: "Inclusión general",
    value: "inclusao",
    checked: false,
  },
  {
    label: "Medio ambiente",
    value: "meio-ambiente",
    checked: false,
  },
  {
    label: "Mujeres",
    value: "mulheres",
    checked: false,
  },
  {
    label: "Canción",
    value: "musica",
    checked: false,
  },
  {
    label: "Personas con Cáncer",
    value: "pessoas-com-cancer",
    checked: false,
  },
  {
    label: "Personas con discapacidad",
    value: "pessoas-com-deficiencia",
    checked: false,
  },
  {
    label: "Personas con Enfermedades Raras",
    value: "pessoas-com-doencas-raras",
    checked: false,
  },
  {
    label: "Gente en la calle",
    value: "pessoas-em-situacao-de-rua",
    checked: false,
  },
  {
    label: "Protección animal",
    value: "protecao-animal",
    checked: false,
  },
  {
    label: "Refugiados",
    value: "refugiados",
    checked: false,
  },
  {
    label: "Salud",
    value: "saude",
    checked: false,
  },
  {
    label: "Entrenamiento profesional",
    value: "treinamento-profissional",
    checked: false,
  },
  {
    label: "Químicos dependientes",
    value: "dependentes-quimicos",
    checked: false,
  },
];
export const states: Option[] = [
  {
    label: "Acre",
    value: "AC",
    checked: false,
  },
  {
    label: "Alagoas",
    value: "AL",
    checked: false,
  },
  {
    label: "Amapá",
    value: "AP",
    checked: false,
  },
  {
    label: "Amazonas",
    value: "AM",
    checked: false,
  },
  {
    label: "Bahia",
    value: "BA",
    checked: false,
  },
  {
    label: "Ceará",
    value: "CE",
    checked: false,
  },
  {
    label: "Distrito Federal",
    value: "DF",
    checked: false,
  },
  {
    label: "Espírito Santo",
    value: "ES",
    checked: false,
  },
  {
    label: "Goiás",
    value: "GO",
    checked: false,
  },
  {
    label: "Maranhão",
    value: "MA",
    checked: false,
  },
  {
    label: "Mato Grosso",
    value: "MT",
    checked: false,
  },
  {
    label: "Mato Grosso do Sul",
    value: "MS",
    checked: false,
  },
  {
    label: "Minas Gerais",
    value: "MG",
    checked: false,
  },
  {
    label: "Pará",
    value: "PA",
    checked: false,
  },
  {
    label: "Paraíba",
    value: "PB",
    checked: false,
  },
  {
    label: "Paraná",
    value: "PR",
    checked: false,
  },
  {
    label: "Pernambuco",
    value: "PE",
    checked: false,
  },
  {
    label: "Piauí",
    value: "PI",
    checked: false,
  },
  {
    label: "Rio de Janeiro",
    value: "RJ",
    checked: false,
  },
  {
    label: "Rio Grande do Norte",
    value: "RN",
    checked: false,
  },
  {
    label: "Rio Grande do Sul",
    value: "RS",
    checked: false,
  },
  {
    label: "Rondônia",
    value: "RO",
    checked: false,
  },
  {
    label: "Roraima",
    value: "RR",
    checked: false,
  },
  {
    label: "Santa Catarina",
    value: "SC",
    checked: false,
  },
  {
    label: "São Paulo",
    value: "SP",
    checked: false,
  },
  {
    label: "Sergipe",
    value: "SE",
    checked: false,
  },
  {
    label: "Tocantins",
    value: "TO",
    checked: false,
  },
];

export type State =
  | "AC"
  | "AL"
  | "AP"
  | "AM"
  | "BA"
  | "CE"
  | "DF"
  | "ES"
  | "GO"
  | "MA"
  | "MT"
  | "MS"
  | "MG"
  | "PA"
  | "PB"
  | "PR"
  | "PE"
  | "PI"
  | "RJ"
  | "RN"
  | "RS"
  | "RO"
  | "RR"
  | "SC"
  | "SP"
  | "SE"
  | "TO";

//interest
export const abilities: Option[] = [
  {
    label: "Ajudar com Recreação",
    value: "ajudar-com-recreacao",
    checked: false,
  },
  {
    label: "Cantar",
    value: "cantar",
    checked: false,
  },
  {
    label: "Contar Histórias",
    value: "contar-historias",
    checked: false,
  },
  {
    label: "Cortar Cabelo",
    value: "cortar-cabelo",
    checked: false,
  },
  {
    label: "Cozinhar",
    value: "cozinhar",
    checked: false,
  },
  {
    label: "Criar Artes Digitais",
    value: "criar-artes-digitais",
    checked: false,
  },
  {
    label: "Dançar",
    value: "dancar",
    checked: false,
  },
  {
    label: "Decorar",
    value: "decorar",
    checked: false,
  },
  {
    label: "Encenar (Teatro)",
    value: "encenar-teatro",
    checked: false,
  },
  {
    label: "Fazer as Unhas",
    value: "fazer-as-unhas",
    checked: false,
  },
  {
    label: "Fazer Instalação Elétrica",
    value: "fazer-instalacao-eletrica",
    checked: false,
  },
  {
    label: "Fazer Escova",
    value: "fazer-escova",
    checked: false,
  },
  {
    label: "Fazer Massagem",
    value: "fazer-massagem",
    checked: false,
  },
  {
    label: "Fotografar",
    value: "fotografar",
    checked: false,
  },
  {
    label: "Liderar e Mobilizar",
    value: "liderar-e-mobilizar",
    checked: false,
  },
  {
    label: "Limpar",
    value: "limpar",
    checked: false,
  },
  {
    label: "Maquiar",
    value: "maquiar",
    checked: false,
  },
  {
    label: "Organizar e Arrumar",
    value: "organizar-e-arrumar",
    checked: false,
  },
  {
    label: "Pintar",
    value: "pintar",
    checked: false,
  },
  {
    label: "Produzir Vídeos",
    value: "produzir-videos",
    checked: false,
  },
  {
    label: "Restaurar Móveis",
    value: "restaurar-moveis",
    checked: false,
  },
  {
    label: "Tocar algum Instrumento",
    value: "tocar-algum-instrumento",
    checked: false,
  },
  {
    label: "Trabalhar com Balões",
    value: "trabalhar-com-baloes",
    checked: false,
  },
  {
    label: "Trabalhar com Hidráulica",
    value: "trabalhar-com-hidraulica",
    checked: false,
  },
  {
    label: "Voluntário",
    value: "voluntario",
    checked: false,
  },
  {
    label: "Outros",
    value: "outros",
    checked: false,
  },
];

export const abilitiesChile: Option[] = [
  {
    label: "Ayuda con la recreación",
    value: "ajudar-com-recreacao",
    checked: false,
  },
  {
    label: "Cantar",
    value: "cantar",
    checked: false,
  },
  {
    label: "Contar Historias",
    value: "contar-historias",
    checked: false,
  },
  {
    label: "Corte de pelo",
    value: "cortar-cabelo",
    checked: false,
  },
  {
    label: "Cocinar",
    value: "cozinhar",
    checked: false,
  },
  {
    label: "Crear artes digitales",
    value: "criar-artes-digitais",
    checked: false,
  },
  {
    label: "Bailar",
    value: "dancar",
    checked: false,
  },
  {
    label: "Decorar",
    value: "decorar",
    checked: false,
  },
  {
    label: "Actuar (Teatro)",
    value: "encenar-teatro",
    checked: false,
  },
  {
    label: "Hacer las uñas",
    value: "fazer-as-unhas",
    checked: false,
  },
  {
    label: "Hacer instalacion electrica",
    value: "fazer-instalacao-eletrica",
    checked: false,
  },
  {
    label: "Peinar",
    value: "fazer-escova",
    checked: false,
  },
  {
    label: "Hacer Masajes",
    value: "fazer-massagem",
    checked: false,
  },
  {
    label: "Fotografía",
    value: "fotografar",
    checked: false,
  },
  {
    label: "Liderar y Movilizar",
    value: "liderar-e-mobilizar",
    checked: false,
  },
  {
    label: "Limpio",
    value: "limpar",
    checked: false,
  },
  {
    label: "Maquillar",
    value: "maquiar",
    checked: false,
  },
  {
    label: "Organizar y Ordenar",
    value: "organizar-e-arrumar",
    checked: false,
  },
  {
    label: "Pintar",
    value: "pintar",
    checked: false,
  },
  {
    label: "Producir vídeos",
    value: "produzir-videos",
    checked: false,
  },
  {
    label: "Restaurar muebles",
    value: "restaurar-moveis",
    checked: false,
  },
  {
    label: "Tocar un instrumento",
    value: "tocar-algum-instrumento",
    checked: false,
  },
  {
    label: "Trabajar con globos",
    value: "trabalhar-com-baloes",
    checked: false,
  },
  {
    label: "Trabajando con Hidráulica",
    value: "trabalhar-com-hidraulica",
    checked: false,
  },
  {
    label: "Voluntario",
    value: "voluntario",
    checked: false,
  },
  {
    label: "Otros",
    value: "outros",
    checked: false,
  },
];

//Skills
export const functions: Option[] = [
  {
    value: "assistente-social",
    label: "Assistente Social",
    checked: false,
  },
  {
    value: "medicoa",
    label: "Médico(a)",
    checked: false,
  },
  {
    value: "fonoaudiologo",
    label: "Fonoaudiólogo",
    checked: false,
  },
  {
    value: "massoterapeuta",
    label: "Massoterapeuta",
    checked: false,
  },
  {
    value: "professora",
    label: "Professor(a)",
    checked: false,
  },
  {
    value: "design-de-interior",
    label: "Design de Interior",
    checked: false,
  },
  {
    value: "gastronomia",
    label: "Gastronomia",
    checked: false,
  },
  {
    value: "mestre-de-obras",
    label: "Mestre de Obras",
    checked: false,
  },
  {
    value: "designer-grafico",
    label: "Designer gráfico",
    checked: false,
  },
  {
    value: "advogado",
    label: "Advogado",
    checked: false,
  },
  {
    value: "terapeuta-ocupacional",
    label: "Terapeuta Ocupacional",
    checked: false,
  },
  {
    value: "nutricionista",
    label: "Nutricionista",
    checked: false,
  },
  {
    value: "enfermeiro",
    label: "Enfermeiro",
    checked: false,
  },
  {
    value: "psicologoa",
    label: "Psicologo(a)",
    checked: false,
  },
  {
    value: "lider-religioso",
    label: "Líder religioso",
    checked: false,
  },
  {
    value: "digital-influencer",
    label: "Digital Influencer",
    checked: false,
  },
  {
    value: "estudante",
    label: "Estudante",
    checked: false,
  },
  {
    value: "dentista",
    label: "Dentista",
    checked: false,
  },
  {
    value: "empresario",
    label: "Empresário",
    checked: false,
  },
  {
    value: "comunicacao-foto-videos-e-redes-sociais",
    label: "Comunicação (foto, vídeos e redes sociais)",
    checked: false,
  },
  {
    value: "arquiteto",
    label: "Arquiteto",
    checked: false,
  },
  {
    value: "webdesigner",
    label: "Webdesigner",
    checked: false,
  },
  {
    value: "outros",
    label: "Outros",
    checked: false,
  },
  {
    value: "engenheiro",
    label: "Engenheiro",
    checked: false,
  },
  {
    value: "universitario-indicar-curso",
    label: "Universitário (Indicar Curso)",
    checked: false,
  },
  {
    value: "voluntario",
    label: "Voluntário",
    checked: false,
  },
  {
    value: "administrador",
    label: "Administrador",
    checked: false,
  },
  {
    value: "contabilista",
    label: "Contabilista",
    checked: false,
  },
  {
    value: "chef",
    label: "Chef",
    checked: false,
  },
  {
    value: "gestor-de-rh",
    label: "Gestor de RH",
    checked: false,
  },
  {
    value: "musico",
    label: "Músico",
    checked: false,
  },
  {
    value: "fotografo",
    label: "Fotógrafo",
    checked: false,
  },
  {
    value: "veterinario",
    label: "Veterinário",
    checked: false,
  },
  {
    value: "desenvolvedor-de-sistemas",
    label: "Desenvolvedor de Sistemas",
    checked: false,
  },
  {
    value: "jornalista",
    label: "Jornalista",
    checked: false,
  },
  {
    value: "educador-fisico",
    label: "Educador Físico",
    checked: false,
  },
  {
    value: "esteticista",
    label: "Esteticista",
    checked: false,
  },
  {
    value: "fisioterapeuta",
    label: "Fisioterapeuta",
    checked: false,
  },
];

export const functionsChile: Option[] = [
  {
    value: "assistente-social",
    label: "Asistente social",
    checked: false,
  },
  {
    value: "medicoa",
    label: "Médico(a)",
    checked: false,
  },
  {
    value: "fonoaudiologo",
    label: "Logopeda",
    checked: false,
  },
  {
    value: "massoterapeuta",
    label: "Masajista",
    checked: false,
  },
  {
    value: "professora",
    label: "Profesor(a)",
    checked: false,
  },
  {
    value: "design-de-interior",
    label: "Diseño de interiores",
    checked: false,
  },
  {
    value: "gastronomia",
    label: "Gastronomia",
    checked: false,
  },
  {
    value: "mestre-de-obras",
    label: "Maestro de obras",
    checked: false,
  },
  {
    value: "designer-grafico",
    label: "Diseñador gráfico",
    checked: false,
  },
  {
    value: "advogado",
    label: "Abogado",
    checked: false,
  },
  {
    value: "terapeuta-ocupacional",
    label: "Terapeuta Ocupacional",
    checked: false,
  },
  {
    value: "nutricionista",
    label: "Nutricionista",
    checked: false,
  },
  {
    value: "enfermeiro",
    label: "Enfermero",
    checked: false,
  },
  {
    value: "psicologoa",
    label: "Psicologo(a)",
    checked: false,
  },
  {
    value: "lider-religioso",
    label: "Líder religioso",
    checked: false,
  },
  {
    value: "digital-influencer",
    label: "Influenciador digital",
    checked: false,
  },
  {
    value: "estudante",
    label: "Estudiante",
    checked: false,
  },
  {
    value: "dentista",
    label: "Dentista",
    checked: false,
  },
  {
    value: "empresario",
    label: "Persona de negocios",
    checked: false,
  },
  {
    value: "comunicacao-foto-videos-e-redes-sociais",
    label: "Comunicación (foto, videos y redes sociales)",
    checked: false,
  },
  {
    value: "arquiteto",
    label: "Arquitecto",
    checked: false,
  },
  {
    value: "webdesigner",
    label: "Diseñador web",
    checked: false,
  },
  {
    value: "outros",
    label: "Otros",
    checked: false,
  },
  {
    value: "engenheiro",
    label: "Ingeniero",
    checked: false,
  },
  {
    value: "universitario-indicar-curso",
    label: "Universidad (Indicar Curso)",
    checked: false,
  },
  {
    value: "voluntario",
    label: "Voluntario",
    checked: false,
  },
  {
    value: "administrador",
    label: "Administrador",
    checked: false,
  },
  {
    value: "contabilista",
    label: "Contador",
    checked: false,
  },
  {
    value: "chef",
    label: "Chef",
    checked: false,
  },
  {
    value: "gestor-de-rh",
    label: "Gerente de Recursos Humanos",
    checked: false,
  },
  {
    value: "musico",
    label: "Músico",
    checked: false,
  },
  {
    value: "fotografo",
    label: "Fotógrafo",
    checked: false,
  },
  {
    value: "veterinario",
    label: "Veterinario",
    checked: false,
  },
  {
    value: "desenvolvedor-de-sistemas",
    label: "Desarrollador de sistemas",
    checked: false,
  },
  {
    value: "jornalista",
    label: "Periodista",
    checked: false,
  },
  {
    value: "educador-fisico",
    label: "Educador Físico",
    checked: false,
  },
  {
    value: "esteticista",
    label: "Cosmetólogo",
    checked: false,
  },
  {
    value: "fisioterapeuta",
    label: "Fisioterapeuta",
    checked: false,
  },
];

interface OptionsMap {
  [k: string]: Option;
}

export const causesMap = causes.reduce<OptionsMap>((previous, current) => {
  previous[current.value] = current;
  return previous;
}, {});

export const filtersData: Filter[] = [
  {
    id: "causes",
    title: "Causas",
    options: causes,
  },
  {
    id: "abilities",
    title: "Habilidades",
    options: abilities,
  },
  {
    id: "states",
    title: "Estados",
    options: states,
  },
  {
    id: "functions",
    title: "Funções",
    options: functions,
  },
  {
    id: "availability",
    title: "Disponibilidade",
    options: [
      // {
      //   label: "Disponibilidade Recorrente",
      //   description: "Exige comprometimento por um período mais longo, durante dias, meses ou anos.",
      //   value: "disponibilidade_recorrente",
      //   checked: false,
      // },
      // {
      //   label: "Disponibilidade Pontual",
      //   description: "Exige dedicação por um curto período de tempo, em um dia ou uma semana.",
      //   value: "disponibilidade_pontual",
      //   checked: false,
      // },
      {
        label: "Presencial",
        value: "true",
        checked: false,
      },
      {
        label: "Remoto",
        param: "rm",
        value: "true",
        checked: false,
      },
      // {
      //   label: "Fins de Semana",
      //   param: "wk",
      //   value: "true",
      //   checked: false,
      // },
    ],
  },
];

export const filtersDataChile: Filter[] = [
  {
    id: "causes",
    title: "Causas",
    options: causesChile,
  },
  {
    id: "abilities",
    title: "Habilidades",
    options: abilitiesChile,
  },
  {
    id: "functions",
    title: "Funções",
    options: functionsChile,
  },
  {
    id: "availability",
    title: "Disponibilidade",
    options: [
      // {
      //   label: "Disponibilidade Recorrente",
      //   description: "Exige comprometimento por um período mais longo, durante dias, meses ou anos.",
      //   value: "disponibilidade_recorrente",
      //   checked: false,
      // },
      // {
      //   label: "Disponibilidade Pontual",
      //   description: "Exige dedicação por um curto período de tempo, em um dia ou uma semana.",
      //   value: "disponibilidade_pontual",
      //   checked: false,
      // },
      // {
      //   label: "Presencial",
      //   value: true,
      //   checked: false,
      // },
      {
        label: "Remoto",
        param: "rm",
        value: "true",
        checked: false,
      },
      {
        label: "Presencial",
        value: "true",
        checked: false,
      },
      {
        label: "Fins de Semana",
        param: "wk",
        value: "true",
        checked: false,
      },
    ],
  },
];
