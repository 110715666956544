import { useState, useMemo, useEffect, useCallback } from "react";
import Validator from "validatorjs";
import { ValidationErrorSet } from "core/api/definitions";
import { debounce } from "lodash";
import i18next from "i18next";
import { isValidPhoneNumber } from "react-phone-number-input";

interface ValidationDescriptor {
  errors?: ValidationErrorSet;
  errorsOriginal?: ValidationErrorSet;
  failed?: boolean;
}

export const errorMessages = {
  required: "is-required",
  required_if: "is-required",
  required_without: "is-required",
  after_today: "after-today",
  string: "format-is-invalid",
  confirmed: "confirmation-does-not-match",
  regex: "format-is-invalid",
  min: "does-not-minimum-character-required",
  max: "length-more-than-maximum-character-required",
  in: "option-invalid",
  date: "date-format-invalid",
  unique: "already-exists",
  email: "format-invalid",
  number: "is-not-a-number",
  accepted: "must-be-accepted",
  numeric: "is-not-a-number",
};

Validator.register(
  "phone",
  (value) => {
    return isValidPhoneNumber(value as string);
  },
  i18next.t("wizard.error.common.format-is-invalid")
);

interface TouchedInterface {
  [k: string]: boolean;
}

const useValidation = (data: any, rules: { [k: string]: string | string[] }, touched: TouchedInterface, deps?: any) => {
  const [errors, setErrors] = useState<ValidationDescriptor>();

  const check = useMemo(
    () =>
      debounce((data: any, touched: { [k: string]: boolean }) => {
        const validator = new Validator(data, rules, errorMessages);
        validator.check();

        const errs = { ...validator.errors.all() };
        for (const k in errs) {
          if (touched[k] === false) {
            delete errs[k];
          }
        }

        setErrors({
          errors: errs,
          errorsOriginal: { ...validator.errors.all() },
          failed: !!validator.fails(),
        });
      }, 500),
    [rules]
  );

  const setErrorsCallback = useCallback((value) => setErrors((prev) => ({ ...prev, errors: value })), []);
  const assignErrorsOriginalCallback = useCallback(
    () => setErrors((prev) => ({ ...prev, errors: prev?.errorsOriginal })),
    []
  );

  useEffect(() => {
    check(data, touched);
    return () => {
      check.cancel();
    };
  }, deps);

  return {
    errors: errors?.errors,
    errorsOriginal: errors?.errorsOriginal,
    setErrors: setErrorsCallback,
    assignErrorsToOriginal: assignErrorsOriginalCallback,
    failed: !!errors?.failed,
    forceCheck: (forceTouched: TouchedInterface) => check(data, forceTouched),
  };
};

export default useValidation;
