const REDIRECT_URL = "redirect/RECEIVED";

const BLC_RECEIVED = "browser-location/RECEIVED";
const BLC_FAILED = "browser-location/FAILED";

export default {
  REDIRECT_URL,
  BLC_RECEIVED,
  BLC_FAILED,
};
