import api from "core/api";
import asyncThunk from "core/decorators/toolkit";

export const listProjectsValidation = asyncThunk(
  "panel/beta/admin/listProjectsValidation",
  api.admin.validation.listProjectsValidation
);

export const listActionsValidation = asyncThunk(
  "panel/beta/admin/listActionsValidation",
  api.admin.validation.listActionsValidation
);
