import styled, { css } from "styled-components";
import { default as BaseFormGroup } from "../../Form/FormGroup";
import Text from "v3/components/common/Text";
import { ModalHeader as BaseModalHeader } from "../ModalBase";

export const ModalHeader = styled(BaseModalHeader)(
  () => css`
    border-bottom-color: transparent;
  `
);

export const Title = styled(Text)(
  ({ theme }) => css`
    margin-top: ${`-${theme.v3.spacing.xs}`};
    margin-bottom: ${theme.v3.spacing.md};
    text-align: center;
    font-weight: 400;
  `
);

export const FormGroup = styled(BaseFormGroup)(
  ({ theme }) => css`
    width: 100%;
    margin-bottom: ${theme.v3.spacing.md};
  `
);
