import http from "config/http";
import { ResponseStatus, ResponseWithParams } from "../../definitions";
import { Login } from "core/api/definitions";
import { AxiosResponse } from "axios";

export interface ReactivateAccountRequest {}

export type ReactivateAccountResponse = ResponseWithParams<ResponseStatus, Login>;

const reactivateAccount = async ({}: ReactivateAccountRequest = {}): Promise<
  AxiosResponse<ReactivateAccountResponse>
> => http.get<ReactivateAccountResponse>(`/users/account/reactivate`);

export default reactivateAccount;
