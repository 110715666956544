import { RootState } from "core/state";
import { Origin } from "core/api/definitions";
import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from "redux";
import { USER_WAY } from "utils/constants";
import { get } from "core/pages/origin";

let userWayInstalled = false;
const positionWidgetAlready = localStorage.getItem("userway-positionWidget");

if (positionWidgetAlready === null || positionWidgetAlready === "1") {
  localStorage.setItem("userway-positionWidget", "2");
}

const userWay: Middleware = (store: MiddlewareAPI<Dispatch, RootState>) => (next: Dispatch<AnyAction>) => (
  action: AnyAction
) => {
  const userWayKey = USER_WAY;

  if (
    userWayKey &&
    !userWayInstalled &&
    action.type === get.fulfilled.toString() &&
    (action.payload?.params as Origin)?.entity === "brasil"
  ) {
    (function (d) {
      const s = d.createElement("script");
      s.setAttribute("data-account", userWayKey);
      s.setAttribute("src", "https://cdn.userway.org/widget.js");
      (d.body || d.head).appendChild(s);
      userWayInstalled = true;
    })(document);
  }

  return next(action);
};

export default userWay;
