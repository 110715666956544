import { AxiosResponse } from "axios";
import http from "config/http";
import { ActionResponse } from "core/api/definitions";

export interface createTagRequest {
  description: string;
}

export type createTagResponse = ActionResponse;

const createTag = async ({ description }: createTagRequest): Promise<AxiosResponse<createTagResponse>> => {
  return http.post<createTagResponse>(`/admin/tags`, { description });
};

export default createTag;
