import useTranslationV3 from "hooks/useTranslationV3";
import React from "react";
import styled from "styled-components/macro";
import mqV3 from "utils/mediaQueriesV3";
import ToggleLabeled from "../Toggle/ToggleLabeled";

interface TitlePageProps {
  title: string;
  geolocationChecked?: boolean;
  onGeolocationCheckboxChange: (e: React.MouseEvent) => void;
}

const TitlePage = ({ title, geolocationChecked, onGeolocationCheckboxChange }: TitlePageProps) => {
  const { t } = useTranslationV3();

  return (
    <TitleContainer>
      <Title>{title}</Title>
      <CustomToggleBase
        id="geolocation"
        defaultChecked={geolocationChecked}
        checked={geolocationChecked}
        onClick={onGeolocationCheckboxChange}
        label={t("plain:Geolocalização Ativa")}
      />
    </TitleContainer>
  );
};

export default TitlePage;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: ${({ theme }) => theme.v3.spacing.xl};
  margin-bottom: ${({ theme }) => theme.v3.spacing.lg};

  ${mqV3.xsDown} {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: ${({ theme }) => theme.v3.spacing.xl};
  }
`;

const Title = styled.h1`
  font-size: ${({ theme }) => theme.v3.fontSize.xxl};
  color: ${({ theme }) => theme.v3.colors.neutralBase};
  font-weight: 400;
  margin: 0;

  ${mqV3.xsDown} {
    font-size: ${({ theme }) => theme.v3.fontSize.xl};
  }
`;

const CustomToggleBase = styled(ToggleLabeled)`
  ${mqV3.xsDown} {
    padding-top: ${({ theme }) => theme.v3.spacing.md};
  }
`;
