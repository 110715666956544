import http from "config/http";
import { ListResponse } from "../definitions";
import { Project } from "../definitions";
import queryString from "query-string";
import { AxiosResponse } from "axios";

interface GetProjectByOwnerRequest {
  status?: "all";
  pp?: number;
  pg?: number;
  term?: string;
}

export type GetProjectByOwnerResponse = ListResponse<Project>;

export const getProjectByOwner = async (
  args: GetProjectByOwnerRequest
): Promise<AxiosResponse<GetProjectByOwnerResponse>> => {
  const qs = queryString.stringify(args);
  return await http.get(`/projects/all?${qs}`);
};
