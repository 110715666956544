import http from "config/http";
import { ListResponse } from "core/api/definitions";
import queryString from "query-string";

export interface ListPlatformRequest {
  pg?: number;
  pp?: number;
  terms?: string;
}

export enum ProcessPublishState {
  COMPLETE = "complete",
  PENDING = "pending",
}

export interface ProcessPublish {
  about: ProcessPublishState;
  administrators: ProcessPublishState;
  basic_data: ProcessPublishState;
  content_data: ProcessPublishState;
  customization: ProcessPublishState;
  domain: ProcessPublishState;
  published_hom: boolean;
  published_prod: boolean;
  publish_queue_prod: boolean;
}

export interface ListPlatformResponseData {
  _id: string;
  entity: string;
  process_publish: ProcessPublish;
  title: string;
}

export interface ListPlatformResponse extends ListResponse<ListPlatformResponseData> {}

const list = (data: ListPlatformRequest) => {
  const qs = queryString.stringify(data);
  return http.get<ListPlatformResponse>(`/platform-launcher/list${qs ? `?${qs}` : ""}`);
};

export default list;
