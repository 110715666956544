import { AxiosResponse } from "axios";
import http from "config/http";
import { ActionResponse } from "core/api/definitions";

export interface exportReportRankingRequest {
  entities: string[];
  cities?: { city: string; state: string }[];
  states?: string[];
}

export type exportReportRankingResponse = ActionResponse;

const exportReportRanking = async ({
  entities,
  cities,
  states,
}: exportReportRankingRequest): Promise<AxiosResponse<exportReportRankingResponse>> => {
  return http.post<exportReportRankingResponse>(`/admin/dashboard/export-ranking-volunteer-report`, {
    entities,
    cities,
    states,
  });
};

export default exportReportRanking;
