import { AxiosResponse } from "axios";
import http from "config/http";
import { ResponseStatus, ResponseWithParams } from "../../definitions";

export interface UpdateEmailRequest {
  code: string;
  email: string;
}

export type UpdateEmailResponse = ResponseWithParams<ResponseStatus, any>;

const updateEmail = async ({ code, email }: UpdateEmailRequest): Promise<AxiosResponse<UpdateEmailResponse>> => {
  return http.get<UpdateEmailResponse>(`/users/updateEmail/${code}`, { data: email });
};

export default updateEmail;
