import { AxiosResponse } from "axios";
import http from "config/http";
import { ConditionalsWithDescription } from "../definitions";
import { ResponseStatus, ResponseWithParams } from "../definitions";

export interface GetAllPreferencesRequest {}

export interface GetAllPreferecesResponseDate {
  _id?: string;
  use_geolocation?: boolean;
  conditionals?: ConditionalsWithDescription;
}

export type GetAllPreferencesResponse = ResponseWithParams<ResponseStatus, GetAllPreferecesResponseDate>;

const getAllPreferences = async (): Promise<AxiosResponse<GetAllPreferencesResponse>> => {
  return http.get<GetAllPreferencesResponse>(`/users/preferences`);
};

export default getAllPreferences;
