import { useCallback } from "react";

const useGeolocation = () => {
  const retrievePosition = useCallback(() => {
    return new Promise<google.maps.LatLng>((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (event: Position) => {
          resolve(new google.maps.LatLng(event.coords.latitude, event.coords.longitude));
        },
        (error: PositionError) => {
          // See more: https://developer.mozilla.org/pt-BR/docs/Web/API/GeolocationPositionError
          reject(error.code);
        }
      );
    });
  }, []);

  return {
    retrievePosition,
  };
};

export default useGeolocation;
