import { ErrorResponse, ListResponse, ListWithFilterResponse } from "core/api/definitions";
import { Draft, PayloadAction } from "@reduxjs/toolkit";
import { ActionHandler, ListAction, RequestableState, ListWithFilterState } from "core/api/definitions";

export type ListResponsePg<T> = ListAction & ListResponse<T>;

export interface ListPayloadAction<T> extends PayloadAction<T | ErrorResponse> {
  meta: {
    arg: {
      pg?: number;
      pp?: number;
    };
  };
}

// list implements handling listing by using.
const listWithFilter = <
  T,
  Filter,
  State extends ListWithFilterState<T, Filter> & RequestableState = ListWithFilterState<T, Filter> & RequestableState,
  Response extends ListWithFilterResponse<T, Filter> = ListWithFilterResponse<T, Filter>
>(): ActionHandler<State, ListPayloadAction<Response>> => ({
  pending: (state: Draft<State>, action: ListPayloadAction<Response>) => {
    if (action?.meta?.arg?.pg === undefined || action?.meta?.arg?.pg === 1) {
      state.pg = 1;
    }

    if (action.meta.arg.pg) {
      state.hasMoreLoading = "loading";
      return;
    }
    state.loading = "loading";
  },
  fulfilled: (state: Draft<State>, action: ListPayloadAction<Response>) => {
    state.hasMore = (action.payload as Response).hasMore;
    state.total = (action.payload as Response).total;

    if (!!action.meta?.arg?.pg && action.meta.arg.pg > 0) {
      state.hasMoreLoading = "ok";
      state.list.push(...((action.payload.response as { result: T[] }).result as Draft<T>[]));
      state.pg = action.meta?.arg?.pg;
    } else {
      state.loading = "ok";
      state.list = (action.payload.response! as { result: T[] }).result as Draft<T>[];
    }
    state.filter_data = (action.payload.response! as { filter_data: Filter }).filter_data as Draft<Filter>;
  },
  rejected: (state: Draft<State>, action: ListPayloadAction<Response>) => {
    if (action.meta.arg.pg) {
      state.hasMoreLoading = "error";
    } else {
      state.loading = "error";
    }
  },
});

export default listWithFilter;
