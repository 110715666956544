import classNames from "classnames";
import React, { useState } from "react";
import { InputProps, Tooltip } from "reactstrap";
import { Size } from "utils/types";

export interface BaseButtonProps extends Omit<InputProps, "size"> {
  size?: Size;
  tooltip?: string;
  tag?: string | React.ReactType;
}

const BaseButton: React.FunctionComponent<BaseButtonProps> = ({
  id,
  tooltip,
  tag,
  className,
  size,
  children,
  ...props
}) => {
  const Tag = tag === undefined ? (props.href ? "a" : "button") : tag;

  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <Tag id={id} className={classNames("btn", className, { [`btn-${size}`]: !!size })} {...props}>
        {children}
      </Tag>
      {id && tooltip && (
        <Tooltip placement={"top"} isOpen={tooltipOpen} target={id} toggle={toggle}>
          {tooltip}
        </Tooltip>
      )}
    </>
  );
};

export default BaseButton;
