import useTranslationV3 from "hooks/useTranslationV3";
import { transparentize } from "polished";
import React, { useEffect, useRef } from "react";
import { Link, LinkProps } from "react-router-dom";
import { NavItem as BSNavItem, NavLink as BSNavLink } from "reactstrap";
import routes from "routes";
import styled, { css } from "styled-components/macro";
import { transition } from "utils/animation";
import { Z_INDEX } from "utils/constants";
import SocialNetworks from "./SocialNetworks";
import { useHistory } from "react-router-dom";
import { useUser } from "hooks/useUser";
import useEntity from "hooks/useEntity";

const MainMenuMobileItem: React.FC<LinkProps> = ({ to, children }) => {
  return (
    <MainNavItemStyled>
      <MainNavLinkStyled tag={Link} to={to}>
        {children}
      </MainNavLinkStyled>
    </MainNavItemStyled>
  );
};

const MainNavItemStyled = styled(BSNavItem)`
  display: flex;
  white-space: nowrap;
`;

const MainNavLinkStyled = styled(BSNavLink)`
  color: ${({ theme }) => theme.v3.colors.neutralBase};
  padding-bottom: 15px;
  font-size: 22px;
  transition: ${transition.fast("color")};
  line-height: 1;
  &:hover {
    color: ${({ theme }) => theme.v3.colors.secondary};
  }
`;

const MainNavStyled = styled.a`
  color: ${({ theme }) => theme.v3.colors.neutralBase};
  font-size: 22px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-top: 4px;
  transition: ${transition.fast("color")};
  line-height: 1;
  text-decoration: none;
  outline: none;
  display: block;

  &:hover {
    color: ${({ theme }) => theme.v3.colors.secondary};
  }
`;

const LinkStyledBS = styled(BSNavLink)`
  color: ${({ theme }) => theme.v3.colors.neutralBase};
  font-size: 22px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-top: 4px;
  transition: ${transition.fast("color")};
  line-height: 1;
  text-decoration: none;
  outline: none;
  display: block;

  &:hover {
    color: ${({ theme }) => theme.v3.colors.secondary};
  }
`;

const MainMenuMobileLogin = styled.a`
  color: ${({ theme }) => theme.v3.colors.neutralBase};
  font-size: 22px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-top: 4px;
  transition: ${transition.fast("color")};
  line-height: 1;
  text-decoration: none;
  outline: none;
  display: block;

  &:hover {
    color: ${({ theme }) => theme.v3.colors.secondary};
  }
`;

const SecondaryMenuMobileItem: React.FC<LinkProps> = ({ to, children }) => {
  return (
    <SecondaryNavItemStyled>
      <SecondaryNavLinkStyled tag={Link} to={to}>
        {children}
      </SecondaryNavLinkStyled>
    </SecondaryNavItemStyled>
  );
};

const SecondaryNavItemStyled = styled(BSNavItem)`
  display: flex;
  white-space: nowrap;
`;

const SecondaryNavLinkStyled = styled(BSNavLink)`
  color: ${({ theme }) => theme.v3.colors.neutralLight};
  font-size: 14px;
  padding-bottom: 15px;
  transition: ${transition.fast("color")};
  line-height: 1;
  &:hover {
    color: ${({ theme }) => theme.v3.colors.neutralBase};
  }
`;

interface MenuMobileProps {
  isOpen: boolean;
  onClose: () => void;
}

const MenuMobile = ({ onClose, isOpen }: MenuMobileProps) => {
  const { t } = useTranslationV3();
  const { entity } = useEntity();
  const wrapperNavMobileRef = useRef<HTMLDivElement>(null);
  const navMobileRef = useRef<HTMLDivElement>(null);
  const history = useHistory();

  useEffect(() => {
    const handleScroll = (e: Event) => {
      if (
        isOpen &&
        e.target &&
        navMobileRef.current &&
        (!navMobileRef.current.contains(e.target as any) ||
          navMobileRef.current.scrollHeight <= navMobileRef.current.offsetHeight)
      ) {
        e.stopPropagation();
        e.preventDefault();
      }
    };

    if (wrapperNavMobileRef.current) {
      wrapperNavMobileRef.current.addEventListener("mousewheel", handleScroll);
      wrapperNavMobileRef.current.addEventListener("touchmove", handleScroll);
    }

    return () => {
      if (wrapperNavMobileRef.current) {
        wrapperNavMobileRef.current.removeEventListener("mousewheel", handleScroll);
        wrapperNavMobileRef.current.removeEventListener("touchmove", handleScroll);
      }
    };
  }, [isOpen, wrapperNavMobileRef]);

  const handleSignIn = (event: React.MouseEvent) => {
    event.preventDefault();
    history.push({ search: "?open=signIn" });
    onClose();
  };
  const { user } = useUser();
  const hasUser = !!user;
  return (
    <WrapperNavMobile isOpen={isOpen} ref={wrapperNavMobileRef}>
      <NavMobile isOpen={isOpen} ref={navMobileRef}>
        <MenuMobileHeader>
          {/* <span>{t("plain:Menu")}</span> */}
          <ButtonMobileMenuClose onClick={() => onClose()}>
            <span>{t("actions.closeMenu.label")}</span>
          </ButtonMobileMenuClose>
        </MenuMobileHeader>
        <MainMenuMobile>
          {!hasUser && <MainMenuMobileLogin onClick={handleSignIn}>{t("plain:Login")}</MainMenuMobileLogin>}
          <MainMenuMobileItem to={routes.listActions}>{t("pages.actions.title")}</MainMenuMobileItem>
          <MainMenuMobileItem to={routes.opportunities.toString()}>{t("pages.opportunities.title")}</MainMenuMobileItem>
          <MainMenuMobileItem to={routes.listProject}>{t("pages.projects.title")}</MainMenuMobileItem>
          {entity === "brasil" && (
            <>
              <MainNavStyled href="https://eventos.transformabrasil.com.br/" target="_blank">
                {t("plain:Eventos")}
              </MainNavStyled>
              <MainNavStyled href="https://ebook.transformabrasil.com.br/" target="_blank">
                {t("plain:E-books")}
              </MainNavStyled>
              <LinkStyledBS tag={Link} to={routes.training.self}>
                Treinamento
              </LinkStyledBS>
            </>
          )}
          {entity === "brasil" && (
            <>
              <MainMenuMobileItem to={routes.blog.toString()}>{t("pages.blog.title")}</MainMenuMobileItem>
            </>
          )}
        </MainMenuMobile>

        <SecondaryMenuMobile>
          <SecondaryMenuMobileItem to={routes.about}>Sobre</SecondaryMenuMobileItem>
          {/* <SecondaryMenuMobileItem to={routes.affiliates}>Parceiros</SecondaryMenuMobileItem> */}
          <SecondaryMenuMobileItem to={"#"}>Causas</SecondaryMenuMobileItem>
          <SecondaryMenuMobileItem to={routes.contact}>Contato</SecondaryMenuMobileItem>
        </SecondaryMenuMobile>
        <SecondaryMenuMobile>
          {/* <SecondaryMenuMobileItem to={routes.terms}>Termos de Uso</SecondaryMenuMobileItem> */}
          {/* <SecondaryMenuMobileItem to={"#"}>Política de Privacidade</SecondaryMenuMobileItem> */}
        </SecondaryMenuMobile>
        <SocialNetworksCustom />
      </NavMobile>
    </WrapperNavMobile>
  );
};

export default MenuMobile;

const WrapperNavMobile = styled.nav<{ isOpen: boolean }>`
  width: 0;
  height: 100vh;
  background: transparent;
  z-index: ${Z_INDEX.MENU_MOBILE.WRAPPER};
  top: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  // transition: background ease-in 0.2s, width ease-in 0.2s 0.2s;
  transition: width 0.3s;
  overflow: hidden;

  ${({ isOpen }) =>
    isOpen &&
    css`
      width: 100vw;
      background: ${({ theme }) => transparentize(0.6, theme.v3.colors.neutralBase)};
      /* transition: background ease-in 0.2s; */
    `};
`;

const NavMobile = styled.div<{ isOpen: boolean }>`
  width: 0;
  height: 100vh;
  max-width: 100vw;
  background: #fff;
  top: 0;
  bottom: 0;
  position: fixed;
  overflow: hidden;
  // transition: background ease-in 0.2s, width ease-in 0.2s 0.2s;
  transition: width 0.3s;

  ${({ isOpen }) =>
    isOpen &&
    css`
      width: 100vw;
    `};
`;

const MenuMobileHeader = styled.header`
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 40px;

  span {
    font-size: ${({ theme }) => theme.v3.fontSize.xs};
    color: ${({ theme }) => theme.v3.colors.neutralLight};
    text-transform: uppercase;
  }
`;

const ButtonMobileMenuClose = styled.button`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
  margin-top: 30px;
  background: none;
  border: none;
  outline: none;
  overflow: hidden;

  &:focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.v3.colors.feedbackSupport};
  }

  span {
    display: flex;
    background: ${({ theme }) => theme.v3.colors.neutralBase};
    width: 20px;
    height: 3px;
    border-radius: 2px;
    position: relative;
    text-indent: 9999em;
    transform: rotate(45deg);

    &:after {
      content: "";
      display: flex;
      position: absolute;
      background: ${({ theme }) => theme.v3.colors.neutralBase};
      width: 20px;
      height: 3px;
      border-radius: 2px;
      transform: rotate(90deg);
    }
  }
`;

const MainMenuMobile = styled.ul`
  list-style: none;
`;

const SecondaryMenuMobile = styled.ul`
  list-style: none;
`;

const SocialNetworksCustom = styled(SocialNetworks)`
  margin-left: 50px;
  margin-top: 50px;
`;
