import { AxiosResponse } from "axios";
import http from "config/http";
import { ResponseStatus, ResponseWithParams } from "../definitions";
import { MaterialStatus } from "../definitions";

export interface CheckoutDonationRequest {
  materials_registrations_id?: string;
  material_id?: string;
  status: MaterialStatus;
  itemId?: string;
}

export interface CheckoutDonationResponseData {
  slug: string;
  _id: string;
}

export type CheckoutDonationResponse = ResponseWithParams<ResponseStatus, CheckoutDonationResponseData>;

const checkout = (data: CheckoutDonationRequest): Promise<AxiosResponse<CheckoutDonationResponse>> =>
  http.post<CheckoutDonationResponse>(`/donors/materials/checkout`, data);

export default checkout;
