import useTranslationV3 from "hooks/useTranslationV3";
import { rem } from "polished";
import React from "react";
import styled from "styled-components";
import FeedbackSection from "./FeedbackSection";

const FeedbackNotification = () => {
  const { t } = useTranslationV3();
  return (
    <CustomFeedbackSection
      title={t("plain:Nenhuma notificação")}
      text={t("plain:Nenhuma notificação nova no momento")}
    />
  );
};

export default FeedbackNotification;

const CustomFeedbackSection = styled(FeedbackSection)`
  margin: ${({ theme }) => theme.v3.spacing.xl} 0;

  h1 {
    font-size: ${({ theme }) => theme.v3.fontSize.sm};
    line-height: ${rem(16)};
    margin-bottom: 0;
  }

  p {
    font-size: ${({ theme }) => theme.v3.fontSize.xs};
  }
`;
