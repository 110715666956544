import { AxiosResponse } from "axios";
import http from "config/http";
import { MaterialList } from "core/api/definitions";
import { ActionDataToCheckout } from "core/api/definitions";
import { MaterialStatus } from "../definitions";
import { ListResponse } from "../definitions";

export interface ListMaterialsRegistrationsRequest {
  filterBy?: MaterialStatus | "all";
  pg?: number;
}

export interface ListMaterialsRegistrationsResponseData {
  _id: string;
  status: MaterialStatus;
  action_data: ActionDataToCheckout;
  material_list: MaterialList;
}

export type ListMaterialsRegistrationsResponse = ListResponse<ListMaterialsRegistrationsResponseData>;

const defaultProps: ListMaterialsRegistrationsRequest = {
  filterBy: "all",
  pg: 1,
};

const ListMaterialsRegistrations = async (
  data: ListMaterialsRegistrationsRequest = defaultProps
): Promise<AxiosResponse<ListMaterialsRegistrationsResponse>> => {
  const { filterBy, pg } = { ...defaultProps, ...data };
  return http.get<ListMaterialsRegistrationsResponse>(
    `/donors/materials/users?status=${filterBy}${pg ? `&pg=${pg}` : ""}`
  );
};

export default ListMaterialsRegistrations;
