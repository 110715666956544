import queryString from "query-string";
import http from "config/http";
import { ProjectStatus, Project, ListResponse } from "core/api/definitions";
import { AxiosResponse } from "axios";

export interface AllRequest {
  term?: string;
  status?: ProjectStatus;
  pg?: number;
  pp?: number;
}

export type AllResponse = ListResponse<Project>;

export default async ({ term, status, pg, pp }: AllRequest = {}): Promise<AxiosResponse<AllResponse>> => {
  // Compose filters
  const fs: any = {};
  if (status) fs.status = status;
  if (term) fs.term = term;
  if (pg) fs.pg = pg;
  if (pp) fs.pp = Math.max(5, pp); // 5 minimum by page.

  return http.get<AllResponse>(`/admin/projects/all?${queryString.stringify(fs)}`);
};
