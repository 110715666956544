import { AxiosResponse } from "axios";
import http from "config/http";
import { ImageData, ResponseStatus, ResponseWithParams, TimeConfigurations } from "core/api/definitions";
import { Cause, Interest } from "pages/ListActions/DataOfFilters";
import { LocationWithAddress } from "wizard/v3/components/Input/PlacesAutocomplete";

export interface StoreUpdateActionRequestData {
  description: string;
  details: string;
  conditionals: {
    causes?: Cause[];
    interests?: Interest[];
  };
  address?: LocationWithAddress;
  images_data: {
    gallery?: ImageData[];
    cover?: ImageData;
  };
  time_configurations: TimeConfigurations;
  impact: {
    members: number;
  };
  video_youtube_url?: string[];
}

export interface StoreUpdateActionRequest {
  actionSlug: string;
  data: StoreUpdateActionRequestData;
}

export interface StoreUpdateActionResponseParams {}

export type StoreUpdateActionResponse = ResponseWithParams<ResponseStatus, StoreUpdateActionResponseParams>;

const storeUpdateAction = async ({
  actionSlug,
  data,
}: StoreUpdateActionRequest): Promise<AxiosResponse<StoreUpdateActionResponse>> =>
  await http.put<StoreUpdateActionResponse>(`/actions/${actionSlug}`, data);

export default storeUpdateAction;
