import React, { useEffect, useMemo } from "react";
import { Modal as RSModal, ModalBody as RSModalBody, ModalFooter as RSModalFooter, ModalProps } from "reactstrap";
import styled, { css } from "styled-components/macro";
import { transition } from "utils/animation";
import { useMediaQueriesV3 } from "utils/hooks/useMediaQueriesV3";
import mqV3 from "utils/mediaQueriesV3";
import { Size } from "utils/types";
import Icon from "../Icons/Icon";

const ModalResolve: React.FC<ModalProps> = ({ isOpen, ...props }) => {
  useEffect(() => {
    const body = document.body;
    if (isOpen && !body.classList.contains("modal-open")) {
      body.classList.add("modal-open");
      body.style.paddingRight = "15px";
    }
    return () => {
      if (isOpen && body.classList.contains("modal-open")) {
        body.classList.remove("modal-open");
        body.style.paddingRight = "";
      } else if (isOpen) {
        body.style.paddingRight = "";
      }
    };
  }, [isOpen]);

  return <RSModal isOpen={isOpen} {...props} />;
};
interface ModalBaseContainerProps extends ModalProps {
  modalSize?: Size;
}

const ModalBaseContainer: React.FC<ModalBaseContainerProps> = ({ children, ...props }) => {
  const { isXS, isSM } = useMediaQueriesV3();

  const customPropsMemorized = useMemo((): ModalProps => (isXS || isSM ? { centered: false } : { centered: true }), [
    isXS,
    isSM,
  ]);

  return (
    <ModalStyled {...customPropsMemorized} {...props} wrapClassName="wrapper-modal-dialog">
      {children}
    </ModalStyled>
  );
};

export default ModalBaseContainer;

const ModalStyled = styled(({ modalSize, ...props }) => <ModalResolve {...props} />)<{ modalSize?: Size }>`
  max-width: 660px;

  ${({ modalSize }) =>
    modalSize === "sm" &&
    css`
      max-width: 482px;
    `};

  .modal-content {
    border: 1px solid ${({ theme }) => theme.v3.colors.neutralLightest};
  }

  ${mqV3.xsDown} {
    max-width: 100%;
    margin: 0;

    .modal-content {
      border-radius: 0;
    }
  }
`;

interface ModalHeaderProps {
  toggle(): void;
}

export const ModalHeader: React.FC<ModalHeaderProps> = ({ children, toggle, ...props }) => (
  <ModalHeaderStyled {...props}>
    {children}
    <ModalClose onClick={toggle} />
  </ModalHeaderStyled>
);

interface ModalCloseProps {
  onClick: () => void;
}

export const ModalClose = ({ onClick }: ModalCloseProps) => (
  <CustomIcon icon="close" color="neutralLight" onClick={onClick} />
);

const CustomIcon = styled(Icon)(
  ({ theme }) => css`
    position: absolute;
    top: 19px;
    right: 19px;
    transition: ${transition.fast("transform")};
    margin: auto 0;
    cursor: pointer;
    width: 24px;
    height: 24px;
    border: 0;
    background-color: transparent;
    z-index: 1;
    display: inline-block;

    :hover {
      .svg-fill {
        fill: ${theme.v3.colors.feedbackError};
      }
    }
  `
);

const ModalHeaderStyled = styled.div`
  position: relative;
  padding: ${({ theme }) => theme.v3.spacing.md};
  border-bottom: 1px solid ${({ theme }) => theme.v3.colors.neutralLightest};
  ${({ theme }) => css`
    padding-right: calc(${theme.v3.spacing.sm} + ${theme.v3.spacing.xl});
  `};
`;

export const ModalBody = styled(RSModalBody)(
  ({ theme }) => css`
    padding: 0 ${theme.v3.spacing.md} ${theme.v3.spacing.md};
  `
);

export const ModalFooter = styled(RSModalFooter)`
  margin: 0;
  padding: ${({ theme }) => theme.v3.spacing.md};

  > * {
    margin: 0;
  }
`;
