import { AxiosResponse } from "axios";
import http from "config/http";
import { ResponseWithParams, ResponseStatus, Login } from "core/api/definitions";

export interface LoginRequest {
  email: string;
  password: string;
}

export type LoginResponse = ResponseWithParams<ResponseStatus, Login>;

const login = async ({ email, password }: LoginRequest): Promise<AxiosResponse<LoginResponse>> => {
  return http.post<LoginResponse>(`/auth/login`, { email, password });
};

export default login;
