import http from "config/http";
import { ListResponse, ResponseStatus, ResponseWithAdditionals, ResponseWithParams } from "../definitions";
import { Project } from "../definitions";
import queryString from "query-string";
import { AxiosResponse } from "axios";

interface ShowConversationRequest {
  pp?: number;
  pg?: number;
  chatID: string;
}

export interface ShowConversation {
  chat: {
    _id: string;
    user_id: string;
    owner_id: string;
    project_id: string;
    action_id: null;
    receiver_data: {
      name: string;
      photo: string;
    };
  };
  messages: [
    {
      _id: string;
      data: [
        {
          _id: string;
          sender: string;
          receiver: string;
          message: string;
          read: false;
          created_at: string;
          name: string;
        }
      ];
    }
  ];
}

export type ShowConversationResponse = ResponseWithParams<ResponseStatus, ResponseWithAdditionals<ShowConversation>>;

export default async ({
  chatID,
  ...props
}: ShowConversationRequest): Promise<AxiosResponse<ShowConversationResponse>> => {
  const qs = queryString.stringify(props);
  return await http.get(`/chat/${chatID}/show-conversation?${qs}`);
};
