import { AxiosResponse } from "axios";
import http from "config/http";
import queryString from "query-string";

import { ListResponse, RegistrationStatus, SubscribeData, UserDataShort } from "../definitions";

export interface pgVolunteer {
  pg: string;
  pp: string;
}

export interface ListAppointmentDetails {
  list: {
    _id: string;
    subscribe_data: SubscribeData;
    user_data: UserDataShort;
    day: string;
    time_start: string;
    time_end: string;
    status: RegistrationStatus;
  }[];
}

export interface ListAppointmentDetailsTMP {
  _id: string;
  subscribe_data: SubscribeData;
  user_data: UserDataShort;
  day: string;
  time_start: string;
  time_end: string;
  status: RegistrationStatus;
}

export type ListAppointment = ListResponse<ListAppointmentDetailsTMP>;

const listVolunteersAppointment = async ({
  args,
}: {
  args: pgVolunteer;
}): Promise<AxiosResponse<ListAppointmentDetailsTMP>> => {
  const qs = queryString.stringify(args);
  return await http.get(`/users/all-next-appointments?${qs}`);
};

export default listVolunteersAppointment;
