import { AxiosResponse } from "axios";
import http from "config/http";
import { ResponseWithParams, ResponseStatus } from "core/api/definitions";

export interface RemoveMaterialItemRequest {
  action: string;
  materialId: string;
  itemId: string;
}

export type RemoveMaterialItemResponse = ResponseWithParams<ResponseStatus, any>;

const updateMaterialItem = ({
  action,
  materialId,
  itemId,
  ...data
}: RemoveMaterialItemRequest): Promise<AxiosResponse<RemoveMaterialItemResponse>> =>
  http.delete<RemoveMaterialItemResponse>(`/actions/materials/${materialId}/item/${itemId}`);

export default updateMaterialItem;
