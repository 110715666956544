import React, { useEffect, useMemo, useState } from "react";
// import ProgramPage from "pages/Program/ProgramPage";
import { ThemeProvider } from "styled-components/macro";
import Loading from "../components/Loading/LoadingRequest";

import { Helmet } from "react-helmet";

import { merge } from "lodash";

import { createGlobalStyle } from "styled-components/macro";
import { useReduxSelector } from "hooks/useReduxSelector";
import { get as originGet } from "core/pages/origin";
import { Origin, ThemeColors, ThemeV3, Theme } from "core/api/definitions";
import { DEFAULT_THEME, DEFAULT_THEME_V3, ENV } from "utils/constants";
import { darken, lighten, transparentize } from "polished";
import TransitionStyles from "components/Transitions/transitions";
import ThemeEditor from "components/ThemeEditor";
import qs from "query-string";
import { transition } from "utils/animation";
import { useDispatch } from "react-redux";

interface AppProviderProps {}

const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const origin = useReduxSelector((state: any) => state.pagesNew.origin);
  const dispatch = useDispatch();
  const queryParams = useMemo(() => {
    return qs.parse(window.location.search);
  }, [window.location.search]);

  const isEditorActive = ENV === "development" && queryParams?.theme_editor === "active";

  const [themeEditorColors, setThemeEditorColors] = useState<Partial<ThemeColors>>();

  useEffect(() => {
    dispatch(originGet({}));
  }, [dispatch]);

  const originParams = origin.params as Origin | undefined;

  const theme = useMemo(() => {
    if (!originParams) return DEFAULT_THEME;
    const theme = merge(DEFAULT_THEME, originParams?.theme);

    const themeV3 = merge(DEFAULT_THEME_V3, {
      brand: {
        name: theme.brand.name,
        logo_footer: theme.brand.logo_footer,
      },
      colors: {
        bg_body: theme.colors.bg_body,
        primary: theme.colors.primary,
        primaryLight: theme.colors?.primary_light || lighten(0.1, theme.colors.primary), // This should be || because sometimes primary_light is "".
        primaryDark: theme.colors?.primary_dark || darken(0.1, theme.colors.primary), // This should be || because sometimes primary_dark is "".
        secondary: theme.colors.secondary,
        secondaryLight: theme.colors?.secondary_light || lighten(0.1, theme.colors.secondary), // This should be || because sometimes secondary_light is "".
        secondaryDark: theme.colors?.secondary_dark || darken(0.1, theme.colors.secondary), // This should be || because sometimes secondary_light is "".
        feedbackSuccess: "#44BE8D",
        feedbackSupport: "#38AAFD",
        feedbackImportant: "#E29B11",
        feedbackError: "#FC5A5A",
        neutralBase: theme.colors.neutralBase,
      },
    });

    const currentTheme = {
      colors: theme.colors,
      color: theme.color,
    };

    if (!themeEditorColors) return { ...currentTheme, v3: themeV3 };
    return { ...currentTheme, v3: themeV3, colors: { ...theme.colors, ...themeEditorColors } };
  }, [themeEditorColors, originParams]);

  if (origin.loading === "loading" || origin.loading === "idle") {
    return <Loading />;
  } else if (origin.loading === "error") {
    // Renderizar pagina de erro
    return <>O site está temporariamente fora do ar!</>;
  }

  return (
    <>
      <Helmet
        htmlAttributes={{ lang: originParams?.language }}
        defaultTitle={originParams?.title}
        titleTemplate={`%s - ${originParams?.title}`}
      >
        <link rel="icon" type="image/png" sizes="16x16" href={originParams?.logoIcon} />

        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <meta name="rating" content="general" />
        <meta name="author" content="Transforma Brasil" />
        <meta httpEquiv="content-language" content="pt-br, en-US, es" />
        <meta httpEquiv="content-type" content="text/html; charset=UTF-8" />
        <meta name="copyright" content={`© ${new Date().getFullYear()} ${originParams?.title}`} />

        <meta name="theme-color" content={theme?.v3.colors.primary} />
        {originParams?.metatags.map((meta, index) => (
          <meta key={index} name={meta.name} content={meta.content} />
        ))}
      </Helmet>

      <ThemeProvider theme={theme as any}>
        <GlobalStyles />
        <TransitionStyles />
        {isEditorActive && <ThemeEditor onChange={setThemeEditorColors} />}
        {children}
      </ThemeProvider>
    </>
  );
};

const GlobalStyles = createGlobalStyle`
body {
  background: ${({ theme }) => (theme.colors.bg_body ? theme.colors.bg_body : "#fff")};
}

#nprogress {
  .bar {
    background: ${({ theme }) => theme.v3.colors.primary};
  }

  .peg {
    box-shadow: ${({ theme }) => `0 0 10px ${theme.v3.colors.primary}, 0 0 5px ${theme.v3.colors.primary}`};
  }

  .spinner-icon {
    border-top-color: ${({ theme }) => theme.v3.colors.primary};
    border-left-color: ${({ theme }) => theme.v3.colors.primary};
  }
}

:focus {
  outline-color: ${({ theme }) => transparentize(0.4)(theme.v3.colors.primary)};
}

.uwy {
  margin-top: 79px;
}

.ht-skip {
  > div > div {
    margin-top: 50px;
    transition: ${transition.fast("margin-top")};
  }
}
`;

export default AppProvider;
