import { AxiosResponse } from "axios";
import { httpBlog } from "config/http";
import { BlogCardPost, BlogPost } from "../definitions";
import queryString from "query-string";

export interface PostsRequest {
  page?: number;
  per_page?: number;
  category?: string;
}

const posts = async ({ ...data }: PostsRequest): Promise<AxiosResponse<BlogPost>> => {
  const qs = queryString.stringify(data, { arrayFormat: "bracket" });

  return httpBlog.get<BlogPost>(`/posts?${qs}`);
};

export default posts;
