import http from "config/http";
import { ResponseStatus, ResponseWithParams } from "core/api/definitions";

export interface PlatformSocialNetworks {
  instagram?: string;
  facebook?: string;
  twitter?: string;
  youtube?: string;
}

export interface AssociativeAddressCoordinates {
  lng: number;
  lat: number;
}

export interface PlatformAddressRadius {
  coordinates?: AssociativeAddressCoordinates;
  street?: string;
  number?: string;
  complement?: string;
  state?: string;
  city?: string;
  zipcode?: string;
  country?: string;
}

export interface PlatformBasicInformationCreateRequest {
  title_platform?: string;
  domain_platform?: string;
  language?: string;
  name_sender?: string;
  email_notify?: string;
  email_notify_chat?: string;
  responsible_name?: string;
  responsible_function?: string;
  signature_default?: boolean;
  signature?: string;
  networks?: PlatformSocialNetworks;
  address_radius?: PlatformAddressRadius;
}

export interface PlataformBasicInformationCreateResponseData {
  _id: string;
}

export interface PlataformBasicInformationCreateResponse
  extends ResponseWithParams<ResponseStatus, PlataformBasicInformationCreateResponseData> {}

const create = (data: PlatformBasicInformationCreateRequest) =>
  http.post<PlataformBasicInformationCreateResponse>(`/platform-launcher/basic-information`, data);

export default create;
