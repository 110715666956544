import React, { useState, useCallback, useEffect, useMemo } from "react";
import Wizard from "wizard/v3/components/Layout/Wizard";
import { Route, Switch, useHistory, useParams, matchPath, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InputActionNamePage from "./InputActionNamePage";
import routes from "routes";
import { reverse } from "named-urls";
import { goto } from "utils/router";
import SelectFeaturesPage, { Features } from "./SelectFeaturesPage";
import SelectCausesPage, { CauseSet } from "../common/SelectCausesPage";
import AboutActionPage, { AboutActionData } from "./AboutActionPage";
import PicturesPage from "./PicturesPage";
import DescriptionActionPage, { ActionDescriptionBenefited } from "./DescriptionActionPage";
import useLocalStorageWrite from "hooks/useLocalStorageWrite";
import useLocalStorageRead from "hooks/useLocalStorageRead";
import { useReduxSelector } from "hooks/useReduxSelector";
import { updateImages, updateCoverImage, updateActionFeatures, createStoreNewAction } from "core/wizard/create/action";
import { useDispatch } from "react-redux";
import { PhotoFile } from "../definitions/commonTypes";
import { Cause } from "pages/ListActions/DataOfFilters";
import { formatDateTimeForAPI } from "utils/date";
import InputCoverImagePage from "./InputCoverImagePage";
import { PayloadAction } from "@reduxjs/toolkit";
import { StoreNewActionResponse } from "core/api/actions/storeNewAction";
import useValidation from "wizard/v3/hooks/useValidation";
import actionValidation from "core/validators/action";
import routeToError from "core/validators/routeToError";
import { ImageCropper } from "components/common/ImageGalleryCropper";
import { isEmpty } from "lodash";
import { showAlert } from "core/alerts/actions";
import { ValidationErrorSet } from "core/api/definitions";
import { ResponseStatus, ResponseWithParams } from "core/api/definitions";
import DoingWork from "components/Progress/DoingWork";
import WizardDoingWorkText from "wizard/v3/components/DoingWork/Text";
import ContinueRegistrationPage, { continuePageMessage } from "../common/ContinueRegistrationPage";
import { isAllPropsEmpty, storageGet } from "utils/helpers";
import { useUser } from "hooks/useUser";
import * as H from "history";

interface Data {
  title?: string;
  features?: Features;
  description?: ActionDescriptionBenefited;
  causes?: CauseSet;
  about?: AboutActionData;
}

const defaultLocalStorageValue = (): Data => ({});

export const WIZARD_LOCAL_CREATE_ACTION = "wizard.create.action.local";

const CreateActionPage = (): JSX.Element => {
  const { t } = useTranslation();
  const { projectSlug } = useParams<{ projectSlug: string }>();
  const { pathname, state: routeState } = useLocation<{ returnTo: H.LocationDescriptor<any> }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useUser();

  const storageData = useLocalStorageRead<Data>(WIZARD_LOCAL_CREATE_ACTION, defaultLocalStorageValue)!;

  const [title, setTitle] = useState(storageData.title);
  const [features, setFeatures] = useState(storageData.features);
  const [description, setDescription] = useState<ActionDescriptionBenefited | undefined>(storageData.description);
  const [causes, setCauses] = useState(storageData.causes);
  const [about, setAbout] = useState(storageData.about);

  const [creatingAction, setCreatingAction] = useState(false);

  const stepCurrent = useMemo(() => {
    if (matchPath(pathname, routes.wizard.project.newAction.inputName)) return 1;
    if (matchPath(pathname, routes.wizard.project.newAction.selectFeatures)) return 2;
    if (matchPath(pathname, routes.wizard.project.newAction.descriptionAction)) return 3;
    if (matchPath(pathname, routes.wizard.project.newAction.causes)) return 4;
    if (matchPath(pathname, routes.wizard.project.newAction.aboutAction)) return 5;
    if (matchPath(pathname, routes.wizard.project.newAction.coverImage)) return 6;
    if (matchPath(pathname, routes.wizard.project.newAction.pictures)) return 7;
    return 0;
  }, [pathname]);

  const [touched, setTouched] = useState<{ [k: string]: boolean }>({
    title: !!title,
    features: false,
    details: false,
    "impact.members": false,
    "conditionals.causes": false,
    "time_configurations.date_start": false,
    "time_configurations.date_end": false,
    "time_configurations.time_start": false,
    "time_configurations.time_end": false,
    "action.not-allowed-date-start-be-smaller-than-today": false,
    "address.city": false,
    "address.coordinates": false,
    "address.country": false,
    "address.state": false,
    "images_data.cover.original": false,
    "images_data.gallery": false,
    hour: false,
  });

  const { pictures, coverImage } = useReduxSelector((state) => ({
    pictures: state.wizard.create.action.images,
    coverImage: state.wizard.create.action.coverImage,
  }));

  const { errors, failed: actionHasErrors, errorsOriginal, assignErrorsToOriginal } = useValidation(
    {
      title,
      features: features?.materials || features?.volunteer,
      details: description?.description,
      impact: { members: description?.benefited },
      conditionals: {
        causes: Object.keys(causes || {}).filter((k) => (causes || {})[k]),
      },
      address: {
        city: about?.address?.city,
        country: about?.address?.country,
        state: about?.address?.state,
        coordinates: about?.address?.coordinates,
      },
      images_data: {
        cover: {
          original: coverImage?.source,
        },
        gallery: pictures,
      },
      time_configurations: {
        date_start: about?.date?.start,
        date_end: about?.date?.end,
        time_start: about?.time_start,
        time_end: about?.time_end,
      },
      action: {
        "not-allowed-date-start-be-smaller-than-today": about?.date?.start,
      },
    },
    actionValidation.rules.create,
    touched,
    [title, features, description, about, causes, pictures, coverImage]
  );

  useEffect(() => {
    dispatch(
      updateActionFeatures({
        features,
      })
    );
  }, [features]);

  const setPictures = useCallback((pictures: ImageCropper[]) => {
    dispatch(updateImages(pictures));
  }, []);

  const setCoverImage = useCallback((coverImage?: PhotoFile) => {
    dispatch(updateCoverImage(coverImage));
  }, []);

  useLocalStorageWrite<Data>(
    {
      title: title,
      features,
      description,
      causes,
      about,
    },
    WIZARD_LOCAL_CREATE_ACTION
  );

  const selectNameOnContinue = useCallback(
    (name) => {
      setTitle(name);
      goto(history, reverse(routes.wizard.project.newAction.selectFeatures, { projectSlug }))();
    },
    [history, projectSlug]
  );

  const selectFeaturesOnContinue = useCallback(
    (features) => {
      setFeatures(features);
      goto(history, reverse(routes.wizard.project.newAction.descriptionAction, { projectSlug }))();
    },
    [history, projectSlug]
  );

  const selectDescriptionOnContinue = useCallback(
    (description) => {
      setDescription(description);
      goto(history, reverse(routes.wizard.project.newAction.causes, { projectSlug }))();
    },
    [history, projectSlug]
  );

  const selectCausesOnContinue = useCallback(
    (causes) => {
      setCauses(causes);
      goto(history, reverse(routes.wizard.project.newAction.aboutAction, { projectSlug }))();
    },
    [history, projectSlug]
  );

  const aboutActionPageOnContinueCallback = useCallback(
    (about) => {
      setAbout(about);
      goto(history, reverse(routes.wizard.project.newAction.coverImage, { projectSlug }))();
    },
    [history, projectSlug]
  );

  const inputCoverImageOnContinueCallback = useCallback(
    (coverImage) => {
      setCoverImage(coverImage);
      goto(history, reverse(routes.wizard.project.newAction.pictures, { projectSlug }))();
    },
    [history, projectSlug]
  );

  /** Continue page Action */
  const actionRuleMemorized = useMemo(() => {
    const actionData = storageGet(WIZARD_LOCAL_CREATE_ACTION);
    return !!actionData && !isAllPropsEmpty(actionData);
  }, []);

  const actionOnContinue = useCallback(() => {
    if (!user) return history.replace(routes.wizard.toString(), routeState);
    history.replace(reverse(routes.wizard.project.newAction.inputName, { projectSlug }), routeState);
  }, [user, history, projectSlug, routeState]);

  const actionOnUnregister = useCallback(() => {
    localStorage.removeItem(WIZARD_LOCAL_CREATE_ACTION);
    if (!user) return history.replace(routes.wizard.toString());
    setTitle(undefined);
    setFeatures(undefined);
    setDescription(undefined);
    setCauses(undefined);
    setAbout(undefined);
    history.replace(reverse(routes.wizard.project.newAction.inputName, { projectSlug }));
  }, [user, history, projectSlug]);

  const picturesPageOnContinueCallback = useCallback(
    async (pictures: ImageCropper[]) => {
      setPictures(pictures);

      if (actionHasErrors) {
        const r = routeToError(errorsOriginal!, actionValidation.routes.create);

        if (r) {
          assignErrorsToOriginal();
          return history.push(reverse(r, { projectSlug }));
        }
        return;
      }

      const causesArr: Cause[] = [];
      for (const k in causes) {
        if (causes[k]) causesArr.push(k as Cause);
      }

      setCreatingAction(true);

      try {
        const createStoreNewActionResponse = ((await dispatch(
          createStoreNewAction({
            // Which project is going to be used...
            // TODO(Jota): To specify project.
            project: projectSlug,

            // Data
            title: title!,
            details: description!.description,
            impact: {
              members: description!.benefited,
            },
            //  TODO(Jota): To break the address into pieces.
            address: about?.address,
            conditionals: {
              causes: causesArr,
            },
            images_data: {
              gallery: pictures.map<ImageCropper>((picture) => ({
                original: picture.cropped || picture.original || "",
              })),
              cover: {
                original: coverImage!.cropped,
              },
            },
            time_configurations: {
              date_start: formatDateTimeForAPI(about!.date!.start!),
              date_end: formatDateTimeForAPI(about!.date!.end!),
              time_start: about!.time_start ? about!.time_start! : undefined,
              time_end: about!.time_end ? about!.time_end! : undefined,
            },
          })
        )) as any) as PayloadAction<StoreNewActionResponse | ResponseWithParams<ResponseStatus, ValidationErrorSet>>; // TODO(Jota): WTF!??!?! That was needed due to the return not getting the right return type.

        if (
          createStoreNewAction.fulfilled.match(createStoreNewActionResponse) &&
          createStoreNewActionResponse.payload.success
        ) {
          dispatch(
            updateActionFeatures({
              features: features,
            })
          );

          goto(
            history,
            reverse(routes.wizard.project.action.infoSteps, {
              projectSlug,
              actionSlug: createStoreNewActionResponse.payload.params!.slug,
            })
          )();
        } else {
          const errorsResponse = actionValidation.mapErrorType(
            createStoreNewActionResponse?.payload.response?.message || ""
          );
          const errorsParams = isEmpty(createStoreNewActionResponse?.payload?.params)
            ? errorsResponse
            : createStoreNewActionResponse.payload?.params;

          if (!isEmpty(errorsParams)) {
            const r = routeToError(errorsParams as ValidationErrorSet, actionValidation.routes.create);
            dispatch(showAlert("danger", createStoreNewActionResponse.payload.response?.message_translated));
            if (r) {
              history.push(reverse(r, { projectSlug }));
              return;
            }
          } else {
            dispatch(showAlert("danger", createStoreNewActionResponse.payload.response?.message_translated));
          }
          // TODO(Jota): Report to sentry.
        }
      } catch (e) {
        console.error(e);
        // TODO(Jota): To report to Sentry.
      } finally {
        setCreatingAction(false);
      }
    },
    [dispatch, t, history, projectSlug, title, description, causes, pictures, coverImage, actionHasErrors]
  );

  return (
    <Wizard header={{ title: t("wizard.stepsWizard.createAction.header.title"), stepCurrent, stepMax: 7 }}>
      <DoingWork isOpen={creatingAction}>
        <WizardDoingWorkText>{t("wizard.pages.createAction.creating.text")}</WizardDoingWorkText>
      </DoingWork>
      <Switch>
        <Route path={routes.wizard.project.newAction.continue}>
          <ContinueRegistrationPage
            message={continuePageMessage("action", t)}
            rule={actionRuleMemorized}
            onContinue={actionOnContinue}
            onUnregister={actionOnUnregister}
          />
        </Route>
        <Route path={routes.wizard.project.newAction.inputName}>
          <InputActionNamePage
            name={title || ""}
            onChange={(value) => {
              setTitle(value);
              setTouched((prev) => ({ ...prev, title: true }));
            }}
            onBack={routeState?.returnTo || reverse(routes.wizard.project.selectProject, { projectSlug })}
            onContinue={selectNameOnContinue}
            error={errors?.title}
          />
        </Route>
        <Route path={routes.wizard.project.newAction.selectFeatures}>
          <SelectFeaturesPage
            features={features}
            onBack={reverse(routes.wizard.project.newAction.inputName, { projectSlug })}
            onChange={(features) => {
              setFeatures(features);
              setTouched((prev) => ({ ...prev, features: true }));
            }}
            onContinue={selectFeaturesOnContinue}
            error={errors?.features}
          />
        </Route>
        <Route path={routes.wizard.project.newAction.descriptionAction}>
          <DescriptionActionPage
            data={description}
            onBack={reverse(routes.wizard.project.newAction.selectFeatures, { projectSlug })}
            onChange={(description) => {
              setDescription(description);
              if (description.description && !touched.details) setTouched((prev) => ({ ...prev, details: true }));
              if (description.benefited <= 0 && !touched["impact.members"])
                setTouched((prev) => ({ ...prev, ["impact.members"]: true }));
            }}
            onContinue={selectDescriptionOnContinue}
            error={errors}
          />
        </Route>
        <Route path={routes.wizard.project.newAction.causes}>
          <SelectCausesPage
            title={t("wizard.pages.createAction.causes.title")}
            causes={causes || {}}
            onBack={reverse(routes.wizard.project.newAction.descriptionAction, { projectSlug })}
            onChange={(causes) => {
              setCauses(causes);
              setTouched((prev) => ({ ...prev, ["conditionals.causes"]: true }));
            }}
            onContinue={selectCausesOnContinue}
            error={errors?.["conditionals.causes"]}
          />
        </Route>
        <Route path={routes.wizard.project.newAction.aboutAction}>
          <AboutActionPage
            data={about}
            onBack={reverse(routes.wizard.project.newAction.causes, { projectSlug })}
            onContinue={aboutActionPageOnContinueCallback}
            onChange={(about) => {
              setAbout(about);
              if (about.time_start && !touched?.hour) setTouched((prev) => ({ ...prev, hour: true }));
              if (about.date?.start && !touched?.["time_configurations.date_start"])
                setTouched((prev) => ({
                  ...prev,
                  ["time_configurations.date_start"]: true,
                }));

              if (about.date?.end && !touched?.["time_configurations.date_end"])
                setTouched((prev) => ({
                  ...prev,
                  ["time_configurations.date_end"]: true,
                }));

              if (!touched?.["action.not-allowed-date-start-be-smaller-than-today"] && about?.date)
                setTouched((prev) => ({ ...prev, "action.not-allowed-date-start-be-smaller-than-today": true }));

              if (about.address?.city && !touched?.["address.city"])
                setTouched((prev) => ({ ...prev, ["address.city"]: true }));
              if (about.address?.coordinates && !touched?.["address.coordinates"])
                setTouched((prev) => ({ ...prev, ["address.coordinates"]: true }));
              if (about.address?.country && !touched?.["address.country"])
                setTouched((prev) => ({ ...prev, ["address.country"]: true }));
              if (about.address?.state && !touched?.["address.state"])
                setTouched((prev) => ({ ...prev, ["address.state"]: true }));
            }}
            error={errors}
          />
        </Route>
        <Route path={routes.wizard.project.newAction.coverImage}>
          <InputCoverImagePage
            file={coverImage}
            onBack={reverse(routes.wizard.project.newAction.aboutAction, { projectSlug })}
            onContinue={inputCoverImageOnContinueCallback}
            onChange={(coverImage) => {
              setCoverImage(coverImage);
              if (!touched?.["images_data.cover.original"])
                setTouched((prev) => ({ ...prev, ["images_data.cover.original"]: true }));
            }}
            error={errors?.["images_data.cover.original"]}
          />
        </Route>
        <Route path={routes.wizard.project.newAction.pictures}>
          <PicturesPage
            images={pictures}
            continueDisabled={creatingAction}
            onBack={reverse(routes.wizard.project.newAction.coverImage, { projectSlug })}
            onChange={(images) => {
              setPictures(images);
              if (touched?.["images_data.gallery"]) setTouched((prev) => ({ ...prev, ["images_data.gallery"]: true }));
            }}
            onContinue={picturesPageOnContinueCallback}
            error={errors?.["images_data.gallery"]}
          />
        </Route>
      </Switch>
    </Wizard>
  );
};

export default CreateActionPage;
