import { createSlice, SliceCaseReducers, PayloadAction } from "@reduxjs/toolkit";
import { RequestableState, LoadingStatus, ListWithFilterState } from "core/api/definitions";
import asyncThunk from "core/decorators/toolkit";
import api from "core/api";
import decoreThunk from "core/decorators/decorate";
import { ListToCheckout, ListToCheckoutResponseFilter } from "core/api/donations/listToCheckout";
import listWithFilter from "core/decorators/listWithFilter";
import { ListToCheckoutDetailResponse, ListToCheckoutDetailRequest } from "core/api/donations/listToCheckoutDetail";
import { findIndex } from "lodash";

type IState = RequestableState &
  ListWithFilterState<ListToCheckout & { loading?: LoadingStatus }, ListToCheckoutResponseFilter[]> & {
    details: {
      [id: string]: Partial<ListToCheckoutDetailResponse> & { loading: LoadingStatus };
    };
  };

export const listToCheckout = asyncThunk("panel/donations/listToCheckout", api.donations.listToCheckout);

export const getDetailsToCheckout = asyncThunk<ListToCheckoutDetailResponse, ListToCheckoutDetailRequest>(
  "panel/donations/listToCheckoutDetail",
  api.donations.listToCheckoutDetail,
  {
    condition: [(action, state) => !state.panel.donations.details[action.donationId]],
  }
);

export const donationCheckout = asyncThunk("panel/donations/checkout", api.donations.checkout);

const panelDonationsSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "panelDonations",
  initialState: {
    loading: "idle",
    list: [],
    filter_data: [],
    details: {},
  },
  reducers: {
    updateDonationStatus(state, action: PayloadAction<Partial<ListToCheckout>>) {
      if (!action.payload._id || !action.payload.status) return;

      const index = findIndex(state.list, { _id: action.payload._id });

      state.list.splice(index, 1, { ...state.list[index], status: action.payload.status });
    },
  },
  extraReducers: (builder) => {
    decoreThunk(builder, listToCheckout, [listWithFilter<ListToCheckout, ListToCheckoutResponseFilter[]>()]);

    decoreThunk<IState, PayloadAction<ListToCheckoutDetailResponse, string, { arg: ListToCheckoutDetailRequest }>>(
      builder,
      getDetailsToCheckout,
      [
        {
          pending: (state, action) => {
            state.details[action.meta.arg.donationId] = {
              loading: "loading",
            };
          },
          fulfilled: (state, action) => {
            state.details[action.meta.arg.donationId] = {
              loading: "ok",
              ...action.payload.params,
            };
          },
          rejected: (state, action) => {
            state.details[action.meta.arg.donationId] = {
              loading: "error",
            };
          },
        },
      ]
    );
  },
});

export default panelDonationsSlice;
export const { updateDonationStatus } = panelDonationsSlice.actions;
