import * as Sentry from "@sentry/browser";
import cookies from "core/cookies";
import { reverse } from "named-urls";
import http from "../../config/http";
import Helper from "../../utils/helper";
import {
  cvtFailed,
  cvtReceived,
  cvtRequested,
  grpFailed,
  grpReceived,
  grpRequested,
  lgnFailed,
  lgnReceived,
  lgnRequested,
  rnpFailed,
  rnpReceived,
  rnpRequested,
  rpaFailed,
  rpaReceived,
  rpaRequested,
  upFailed,
  upReceived,
  // User profile
  upRequested,
  usdFailed,
  usdReceived,
  usdRequested,
  useFailed,
  useReceived,
  useRequested,
  usntFailed,
  usntReceived,
  usntRequested,
  uspFailed,
  uspReceived,
  uspRequested,
  usrFailed,
  usrReceived,
  usrRequested,
  usvFailed,
  usvReceived,
  //
  usvRequested,
} from "./actions";

export function createUser(dados) {
  return async (dispatch) => {
    dispatch(cvtRequested());
    try {
      const request = await http.post("/users", dados);
      dispatch(cvtReceived({ ...request.data.params, email: dados.email, contacts: dados.contacts }));
      return request;
    } catch (error) {
      if (error.response) dispatch(cvtFailed(Helper.apiErrorsHandle(error.response.data)));
      throw error;
    }
  };
}

export function login(data) {
  return async (dispatch) => {
    dispatch(lgnRequested());
    try {
      let request = await http.post("/auth/login", data);

      const receivedParams = { ...request.data.params };

      if (data.redirect && typeof data.redirect === "string") receivedParams.redirect = reverse(data.redirect);
      if (data.redirect && typeof data.redirect !== "string") receivedParams.redirect = data.redirect;
      dispatch(lgnReceived(receivedParams, data.email));

      cookies.user.set({ ...request.data.params, email: data.email });
      return request;
    } catch (error) {
      if (error.response) dispatch(lgnFailed(Helper.apiErrorsHandle(error.response.data)));
      throw error;
    }
  };
}

export function getUserLogged() {
  return async (dispatch) => {
    dispatch(usrRequested());
    try {
      if (cookies.user.get()) {
        let { access, secure } = cookies.user.get();
        let request = await http.get("/users/me", {
          "Auth-Access-Token": access,
          "Auth-Secure-Token": secure,
        });
        dispatch(usrReceived({ ...request.data.params, logged: true }));
        Sentry.setUser({
          id: request.data?.params?._id,
          email: request.data?.params?.email,
          name: request.data?.params?.name,
        });
        return request;
      } else {
        dispatch(usrReceived({ logged: false }));
      }
      return false;
    } catch (error) {
      if (error.response) dispatch(usrFailed(Helper.apiErrorsHandle(error.response.data)));
      throw error;
    }
  };
}

export function getUserSupportData() {
  return async (dispatch) => {
    dispatch(usdRequested());
    try {
      let request = await http.get("/users/loadSupportData");
      dispatch(usdReceived(request.data.params));
    } catch (error) {
      if (error.response) dispatch(usdFailed(Helper.apiErrorsHandle(error.response.data)));
    }
  };
}

export function getUserToEdit() {
  return async (dispatch) => {
    dispatch(useRequested());
    try {
      let request = await http.get("/users");
      dispatch(useReceived(request.data.params));
    } catch (error) {
      if (error.response) dispatch(useFailed(Helper.apiErrorsHandle(error.response.data)));
    }
  };
}

export function editUser(data) {
  return async (dispatch) => {
    dispatch(uspRequested());
    try {
      let request = await http.put("/users", data);
      dispatch(uspReceived(request.data.params));
    } catch (error) {
      if (error.response) dispatch(uspFailed(Helper.apiErrorsHandle(error.response.data)));
    }
  };
}

// getUserProfile fetches the counters.
export function getUserProfile() {
  return async (dispatch) => {
    dispatch(upRequested());
    try {
      let request = await http.get("/users/profile");
      dispatch(upReceived(request.data.params));
      const user = cookies.user.get();
      const newUser = { ...user };
      if (request.data.params && request.data.params.user_data) {
        const params = request.data.params.user_data;
        if (params.dash_profile) newUser.dash_profile = params.dash_profile;
        if (params.project_type) newUser.project_type = params.project_type;
        if (params.personal_project) newUser.personal_project = params.personal_project;
        if (params.has_personal_project) newUser.has_personal_project = !!params.personal_project;
        if (params.type) newUser.type = params.type;
        if (params.profile_image) newUser.user_picture = params.profile_image;
      }
      let userHasChanged = false;
      for (var i in user) {
        if (user[i] !== newUser[i]) {
          userHasChanged = true;
        }
      }

      if (userHasChanged) {
        cookies.user.set(newUser);
      }
      return request;
    } catch (error) {
      if (error.response) dispatch(upFailed(Helper.apiErrorsHandle(error.response.data)));
      throw error;
    }
  };
}

export function validateUser(token) {
  return async (dispatch) => {
    dispatch(usvRequested());
    try {
      let request = await http.get(`/users/validation/${token}`);
      dispatch(usvReceived(request.data));
      cookies.user.remove("user");
      cookies.user.set(request.data.params);
      dispatch(lgnReceived(request.data.params));
    } catch (error) {
      if (error.response) dispatch(usvFailed(Helper.apiErrorsHandle(error.response.data)));
    }
  };
}

/**
 * @deprecated
 */
export function getNewTokenToValidateUser() {
  return async (dispatch) => {
    dispatch(usntRequested());
    try {
      const request = await http.get(`/users/getEmailTokenValidation`);
      dispatch(
        usntReceived({
          ...(request?.data || {}),
          success: request?.data?.success || false,
        })
      );
    } catch (error) {
      if (error.response) dispatch(usntFailed(Helper.apiErrorsHandle(error.response.data)));
    }
  };
}

export function resetPasswordAdmin(data) {
  return async (dispatch) => {
    dispatch(rpaRequested());
    try {
      let request = await http.put(`/users/password`, data);
      dispatch(rpaReceived(request.data.params));
    } catch (error) {
      if (error.response) dispatch(rpaFailed(Helper.apiErrorsHandle(error.response.data)));
    }
  };
}

/** @deprecated */
export function recoverPassword(email) {
  return async (dispatch) => {
    dispatch(grpRequested());
    try {
      let request = await http.post(`/auth/forgot`, email);
      dispatch(grpReceived(request.data.params));
    } catch (error) {
      if (error.response) dispatch(grpFailed(Helper.apiErrorsHandle(error.response.data)));
    }
  };
}

export function resetPassword(data) {
  return async (dispatch) => {
    dispatch(rnpRequested());
    try {
      let request = await http.post(`/auth/reset_password`, data);
      dispatch(rnpReceived(request.data.params));
    } catch (error) {
      if (error.response) dispatch(rnpFailed(Helper.apiErrorsHandle(error.response.data)));
    }
  };
}

export function saveUserProfile(data) {
  console.log(data);
  return async (dispatch) => {
    dispatch(uspRequested());
    try {
      let request = await http.path(`/users`, data);
      console.log(request);
      dispatch(uspReceived(request.data.params));
    } catch (error) {
      console.log(error);
    }
  };
}
