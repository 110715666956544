import queryString from "query-string";
import http from "config/http";
import { ProjectStatus, ListResponse, Project } from "core/api/definitions";
import { AxiosResponse } from "axios";

export interface AllRequest {
  term?: string;
  status?: string;
  pg?: number;
  pp?: number;
}

export type ListProjectsValidationTagsResponse = ListResponse<Project>;

export default async ({ term, status, pg, pp }: AllRequest = {}): Promise<
  AxiosResponse<ListProjectsValidationTagsResponse>
> => {
  const fs: any = {};
  if (status) fs.status = status;
  if (term) fs.term = term;
  if (pg) fs.pg = pg;
  if (pp) fs.pp = Math.max(10, pp);

  return http.get<ListProjectsValidationTagsResponse>(`admin/projects/all?${queryString.stringify(fs)}`);
};
