import { createSlice, SliceCaseReducers } from "@reduxjs/toolkit";
import api from "core/api";
import { RequestableState } from "core/api/definitions";
import { ErrorResponse } from "core/api/definitions";
import decoreThunk from "core/decorators/decorate";
import asyncThunk from "core/decorators/toolkit";
import requestLoading from "core/decorators/requestLoading";
import { ActionHistoryResponse, ActionHistory } from "core/api/admin/actions/history";

type GetActionHistory = ActionHistoryResponse | ErrorResponse;

export const getActionHistory = asyncThunk<GetActionHistory, { actionID: string }>(
  "panel/validation/actions/history",
  api.admin.actions.history
);

export const validationActionByAdmin = asyncThunk(
  "panel/validation/actions/byAdmin",
  api.admin.actions.validateActionByAdmin
);

//TODO(Jeconias): Migrate list of action validation to here
type IState = RequestableState & { history: ActionHistory[] };

const panelValidationActionListSlice = createSlice<IState, SliceCaseReducers<IState>>({
  name: "panelValidationAction",
  initialState: {
    loading: "idle",
    history: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    decoreThunk(builder, getActionHistory, [
      requestLoading(),
      {
        fulfilled: (state, action) => {
          state.history = action.payload.params;
        },
      },
    ]);
  },
});

export default panelValidationActionListSlice;
