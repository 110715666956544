import http from "config/http";
import { ListResponse, ResponseStatus, ResponseWithAdditionals, ResponseWithParams } from "../definitions";
import { AxiosResponse } from "axios";

interface FilterProjectsContactsRequest {
  term: string;
}

export interface FilterProjectsContactsInterface {
  name: string;
  photo: string;
  project_id: string;
  owner_id: string;
}

export type FilterProjectsContactsResponse = ResponseWithParams<ResponseStatus, FilterProjectsContactsInterface[]>;

export default async ({
  term,
}: FilterProjectsContactsRequest): Promise<AxiosResponse<FilterProjectsContactsResponse>> => {
  return await http.get(`/chat/filter-projects?text=${term}`);
};
