import Icon from "components/Icon/IconFontAwesome";
import { Origin, OriginSocialNetwork } from "core/api/definitions";
import { useReduxSelector } from "hooks/useReduxSelector";
import { rem } from "polished";
import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { transition } from "utils/animation";

interface SocialNetworksProps {
  networks?: OriginSocialNetwork[];
}

const mapSocialsIcon: {
  [key: string]: IconProp;
} = {
  instagram: ["fab", "instagram"],
  facebook: ["fab", "facebook-f"],
  twitter: ["fab", "twitter"],
  youtube: ["fab", "youtube"],
  website: ["far", "globe-americas"],
};

const SocialNetworks = ({ networks, ...props }: SocialNetworksProps) => {
  const [localNetworks, setLocalNetworks] = useState<OriginSocialNetwork[]>([]);

  const { origin } = useReduxSelector((state) => ({
    origin: state.pagesNew.origin.params,
  }));

  const { social_networks: socials } = origin as Origin;

  useEffect(() => {
    if (networks) {
      setLocalNetworks(networks);
      return;
    }

    setLocalNetworks(socials);
  }, [networks, socials]);

  return (
    <SocialNetworksStyled {...props}>
      {localNetworks.map(({ network, url }) => (
        <SocialNetworksItem key={`${network}`}>
          <SocialNetorksLink href={url} target="_blank">
            <span>{network}</span>
            <i>
              <Icon icon={mapSocialsIcon[network]} />
            </i>
          </SocialNetorksLink>
        </SocialNetworksItem>
      ))}
    </SocialNetworksStyled>
  );
};

export default SocialNetworks;

const SocialNetworksStyled = styled.ul`
  display: flex;
  padding: 0;
  margin: 0 -5px;
  list-style-type: none;
`;

const SocialNetworksItem = styled.li`
  background: ${({ theme }) => theme.v3.colors.primary};
  border-radius: 50%;
  width: ${rem(32)};
  min-width: ${rem(32)};
  height: ${rem(32)};
  margin: 0 5px;
`;

const SocialNetorksLink = styled.a`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: ${({ theme }) => theme.v3.fontSize.md};
  line-height: 1;

  &:focus,
  &:active {
    color: #fff;
  }

  &:hover {
    color: #fff;
    span {
      background: ${({ theme }) => theme.v3.colors.primaryDark};
      width: ${rem(32)};
      height: ${rem(32)};
      border-radius: 50%;
      margin: ${rem(-16)};
    }
  }

  span {
    border-radius: 0;
    display: block;
    height: 0;
    left: 50%;
    margin: 0;
    position: absolute;
    top: 50%;
    width: 0;
    transition: ${transition.normal("all")};
    text-indent: -9999rem;
  }

  i {
    position: relative;
  }
`;
