const ACTIONS_REQUESTED = "search/actions/requested";
const ACTIONS_RECEIVED = "search/actions/received";
const ACTIONS_FAILED = "search/actions/failed";

const ACM_REQUESTED = "search/filter/actions/requested";
const ACM_RECEIVED = "search/filter/actions/received";
const ACM_FAILED = "search/filter/actions/failed";

const SUBSCRIBE_REQUESTED = "search/subscribe/requested";
const SUBSCRIBE_RECEIVED = "search/subscribe/received";
const SUBSCRIBE_FAILED = "search/subscribe/failed";

const SBM_REQUESTED = "search/filter/subscribe/requested";
const SBM_RECEIVED = "search/filter/subscribe/received";
const SBM_FAILED = "search/filter/subscribe/failed";

const SAL_REQUESTED = "search/actions/locations-map/requested";
const SAL_RECEIVED = "search/actions/locations-map/received";
const SAL_FAILED = "search/actions/locations-map/failed";

const SAD_REQUESTED = "search/sugestions/home/requested";
const SAD_RECEIVED = "search/sugestions/home/received";
const SAD_FAILED = "search/sugestions/home/failed";

const LIST_PROJECTS_REQUESTED = "projetos/listagem/page/REQUESTED";
const LIST_PROJECTS_RECEIVED = "projetos/listagem/page/RECEIVED";
const LIST_PROJECTS_FAILED = "projetos/listagem/page/FAILED";

const LP_REQUESTED = "lp/REQUESTED";
const LP_RECEIVED = "lp/RECEIVED";
const LP_FAILED = "lp/FAILED";

export default {
  ACTIONS_REQUESTED,
  ACTIONS_RECEIVED,
  ACTIONS_FAILED,
  ACM_REQUESTED,
  ACM_RECEIVED,
  ACM_FAILED,
  SUBSCRIBE_REQUESTED,
  SUBSCRIBE_RECEIVED,
  SUBSCRIBE_FAILED,
  SBM_REQUESTED,
  SBM_RECEIVED,
  SBM_FAILED,
  SAL_REQUESTED,
  SAL_RECEIVED,
  SAL_FAILED,
  SAD_REQUESTED,
  SAD_RECEIVED,
  SAD_FAILED,
  LIST_PROJECTS_REQUESTED,
  LIST_PROJECTS_RECEIVED,
  LIST_PROJECTS_FAILED,
  LP_REQUESTED,
  LP_RECEIVED,
  LP_FAILED,
};
