import { ActionReducerMapBuilder, AnyAction, PayloadAction } from "@reduxjs/toolkit";
import { ActionThunk, ActionHandler, ActionWithParams, Meta } from "core/api/definitions";

const decoreThunk = <State, Returned extends AnyAction>(
  builder: ActionReducerMapBuilder<State>,
  thunk: ActionThunk<Returned>,
  actions: ActionHandler<State, Returned & ActionWithParams<any>>[]
) => {
  builder.addCase(thunk.pending.toString(), (state, action) => {
    for (const a of actions) {
      if (a.pending) a.pending(state, action);
    }
  });
  builder.addCase(thunk.fulfilled.toString(), (state, action) => {
    for (const a of actions) {
      if (a.fulfilled) a.fulfilled(state, action);
    }
  });
  builder.addCase(thunk.rejected.toString(), (state, action) => {
    for (const a of actions) {
      if (a.rejected) a.rejected(state, action);
    }
  });
};

export default decoreThunk;

const decoreThunk2 = <State, Returned extends AnyAction>(
  builder: ActionReducerMapBuilder<State>,
  thunk: ActionThunk<Returned>,
  actions: ActionHandler<State, Returned & PayloadAction<any> & Meta>[]
) => {
  builder.addCase(thunk.pending.toString(), (state, action) => {
    for (const a of actions) {
      if (a.pending) a.pending(state, action);
    }
  });
  builder.addCase(thunk.fulfilled.toString(), (state, action) => {
    for (const a of actions) {
      if (a.fulfilled) a.fulfilled(state, action);
    }
  });
  builder.addCase(thunk.rejected.toString(), (state, action) => {
    for (const a of actions) {
      if (a.rejected) a.rejected(state, action);
    }
  });
};

export { decoreThunk2 };
